import SiafLiteApiRepository from "../../external/siafLiteApi";

class DocumentoFreteService {
  constructor() {
    this.urlBase = "/estoque/documentos-frete";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }
}

export default DocumentoFreteService;
