import { Radio as RadioMui } from "@mui/material";

const Radio = ({
  className,
  checked,
  checkedIcon,
  children,
  classes,
  color,
  disabled,
  disableRipple,
  icon,
  id,
  inputProps,
  inputRef,
  name,
  onChange,
  ref,
  required,
  size,
  sx,
  value,
}) => {
  return (
    <RadioMui
      className={className}
      checked={checked}
      checkedIcon={checkedIcon}
      classes={classes}
      color={color}
      disabled={disabled}
      disableRipple={disableRipple}
      icon={icon}
      id={id}
      inputProps={inputProps}
      inputRef={inputRef}
      name={name}
      onChange={onChange}
      ref={ref}
      required={required}
      size={size}
      sx={sx}
      value={value}
    >
      {children}
    </RadioMui>
  );
};

export default Radio;
