import { CurrencyInput, Grid } from "../../../../../components";
import { unformatPrice } from "../../../../../utils";

const NfEntradaTributos = (props) => {
  const handleInputChange = (event) => {
    props.setNfEntradaTributos((prevState) => ({
      ...prevState,
      [event.target.name]: unformatPrice(event.target.value),
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <CurrencyInput
            id="baseIcms"
            name="baseIcms"
            label="Base ICMS"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.baseIcms || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="baseIcmsSt"
            name="baseIcmsSt"
            label="Base ICMS ST"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.baseIcmsSt || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorIcms"
            name="valorIcms"
            label="Valor ICMS"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.valorIcms || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorIcmsSt"
            name="valorIcmsSt"
            label="Valor ICMS ST"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.valorIcmsSt || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="baseIpi"
            name="baseIpi"
            label="Base IPI"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.baseIpi || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorIpi"
            name="valorIpi"
            label="Valor IPI"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.valorIpi || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorCofins"
            name="valorCofins"
            label="Valor COFINS"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.valorCofins || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorPis"
            name="valorPis"
            label="Valor PIS"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.valorPis || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="baseFcp"
            name="baseFcp"
            label="Base FCP"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.baseFcp || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="baseFcpSt"
            name="baseFcpSt"
            label="Base FCP ST"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.baseFcpSt || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorFcp"
            name="valorFcp"
            label="Valor FCP"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.valorFcp || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorFcpSt"
            name="valorFcpSt"
            label="Valor FCP ST"
            margin="normal"
            variant="outlined"
            disabled={
              props.nfEntradaId || props.nfEntradaDto?.documentoImportado
            }
            value={props.nfEntradaTributos?.valorFcpSt || ""}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NfEntradaTributos;
