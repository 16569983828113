import { Button, Grid } from "../../../../components";

const ButtonMenu = ({
  renderBaixaLote,
  setRenderBaixaLote,
  renderRenegociacaoTitulos,
  setRenderRenegociacaoTitulos,
}) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="start"
      className="m-1"
    >
      <Button
        variant={renderBaixaLote ? "contained" : "outlined"}
        disabled={renderRenegociacaoTitulos}
        onClick={() => setRenderBaixaLote(!renderBaixaLote)}
      >
        Baixa em Lote
      </Button>
      <Button
        variant={renderRenegociacaoTitulos ? "contained" : "outlined"}
        disabled={renderBaixaLote}
        sx={{ mx: "1em" }}
        onClick={() => setRenderRenegociacaoTitulos(!renderRenegociacaoTitulos)}
      >
        Renegociação de títulos
      </Button>
    </Grid>
  );
};

export default ButtonMenu;
