import React from "react";
import "./fullscreen-dialog.css";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "../../components";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, handleClose, content }) {
  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <AppBar className="appBarFullScreen">
        <Toolbar>
          <Button
            variant="outlined"
            className="m-3"
            color="padrao"
            onClick={handleClose}
          >
            <i
              className="ph-fill ph-arrow-left"
              style={{ color: "#fff", paddingRight: "0.7em" }}
            />{" "}
            <Typography variant="h6" className="titleFullScreen" color="white">
              Voltar
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      {content}
    </Dialog>
  );
}
