import { ListItemText as ListItemTextMui } from "@mui/material";

const ListItemText = ({
  children,
  classes,
  disableTypography,
  inset,
  primary,
  primaryTypographyProps,
  secondary,
  secondaryTypographyProps,
  sx,
  ref,
  style,
  className,
}) => {
  return (
    <ListItemTextMui
      classes={classes}
      disableTypography={disableTypography}
      inset={inset}
      primary={primary}
      primaryTypographyProps={primaryTypographyProps}
      secondary={secondary}
      secondaryTypographyProps={secondaryTypographyProps}
      sx={sx}
      ref={ref}
      style={style}
      className={className}
    >
      {children}
    </ListItemTextMui>
  );
};

export default ListItemText;
