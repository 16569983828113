import * as Yup from "yup";

const tranferenciaSaldocaixaValidator = Yup.object().shape({
  dataMovimentacao: Yup.date().required("Data"),
  bancoCaixaOrigem: Yup.number().required("Conta Origem"),
  bancoCaixaDestino: Yup.number().required("Conta Destino"),
  meioPagamentoId: Yup.number().required("Meio de Pagamento"),
  valor: Yup.number().required("Valor a Tranferir"),
  observacao: Yup.string().nullable(),
});

export default tranferenciaSaldocaixaValidator;
