import * as Yup from "yup";

const pagamentoValidator = Yup.object().shape({
  dataPagamento: Yup.date()
    .typeError("Data de Pagamento")
    .required("Data de Pagamento"),
  bancoCaixaId: Yup.number().required("Conta"),
  meioPagamentoId: Yup.number().required("Meio de Pagamento"),
  valor: Yup.number().required("Valor a Pagar"),
});

export default pagamentoValidator;
