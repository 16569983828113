const formatPrice = (valor, cifra = true) => {
  if (valor || valor === 0) {
    const valorTemp = valor.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return cifra ? valorTemp : valorTemp.replace(/[^0-9,.]/g, "");
  }
  return valor;
};

export default formatPrice;
