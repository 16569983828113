class ValidarEmailHelper {
  email(email) {
    if (typeof email !== "string") {
      return false;
    }
    return /^[^\s@#$*%!]+([-]?[^\s@#$*%!]+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
  }
}

export default ValidarEmailHelper;
