import { Grid, Select, MenuItem, TextField } from "../../../../components";

const FormFiltroAvancado = ({ valueInput, onChangeInput }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          id="ativado"
          name="ativado"
          value={valueInput("ativado")}
          label="Status"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value={"true"}>Ativado</MenuItem>
          <MenuItem value={"false"}>Desativado</MenuItem>
          <MenuItem value={"-"}>Ambos</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="nome"
          name="nome"
          label="Nome"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("nome")}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
