import "../modelo-carta-correcao.scss";

const Correcao = ({ data }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">CORREÇÃO</h3>
      <table className="danfe_tabelas">
        <tr style={{ height: "110px" }}>
          <td className="danfe_celula_bordas">
            <p className="danfe_celula_titulo">{data.correcao?.correcao}</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Correcao;
