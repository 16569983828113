import createAxiosConfig from "../config/createAxiosConfig";
const headers = {
  "Content-Type": "application/json",
};
const axiosConfig = createAxiosConfig(
  "https://siaf-consulta-cpf-cnpj.adsoft.com.br",
  headers
);

class SiafLiteApiRepository {
  constructor(url) {
    this.url = url;
  }

  async get() {
    return axiosConfig
      .get(this.url)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        return erro;
      });
  }

  async post(body) {
    return axiosConfig
      .post(this.url, body)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        return erro;
      });
  }
}

export default SiafLiteApiRepository;
