import * as Yup from "yup";

const gradeValidator = Yup.array()
  .min(1, "Ter ao menos um produto de grade")
  .of(
    Yup.object()
      .shape({
        codigoBarra: Yup.string().required("Código de barra"),
        descricao: Yup.string().required("Descrição"),
      })
      .required("Ter ao menos um produto na grade")
  )
  .required("Ter ao menos um produto na grade");

export default gradeValidator;
