import SiafLiteApiRepository from "../../external/siaf-lite-api-form-data";

class StorageService {
  constructor() {
    this.urlBase = "/storage";
  }

  addImage(formData) {
    return new SiafLiteApiRepository(this.urlBase).post(formData);
  }

  removeImage(nome) {
    return new SiafLiteApiRepository(this.urlBase).delete(nome);
  }
}

export default StorageService;
