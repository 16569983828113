import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  FormControl,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { CurrencyInput, TextFielDecimalNumbers } from "../";
import { responseErros, unformatPrice } from "../../utils";
import { ProdutosService, notification } from "../../services";
import { calculoCustoNfEntradaItem } from "../../views/nf-entrada/list-nf-entrada/nf-entrada-xml";

const produtosService = new ProdutosService();

const ModalPrecificacaoNfEntrada = ({
  open,
  setOpen,
  //inputErros,
  setLoading,
  //nfEntradaValidator,
  //cadastrarNfEntrada,
  nfEntrada,
  nfEntradaItens,
  setNfEntradaItens,
  initialNfEntradaItensState,
  margemCalculo,
  handleSubmit,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemModify, setItemModify] = useState(null);

  useEffect(() => {
    setItemModify(nfEntradaItens[currentPage]);
  }, [open]);

  const handleBlurCusto = (e) => {
    const valorCusto = e.target.value;
    if (parseFloat(valorCusto || 0) < parseFloat(itemModify.precoCompra)) {
      const itemTemp = {
        ...itemModify,
        precoCusto: itemModify.precoCompra,
      };
      itemTemp.precoVenda = calculoPrecoVenda(itemTemp);
      setItemModify(itemTemp);
    }
  };

  const handleBlurPrecoVenda = () => {
    if (
      parseFloat(itemModify.precoVenda) < parseFloat(itemModify.precoCompra)
    ) {
      const itemTemp = {
        ...itemModify,
        precoVenda: itemModify.precoCompra,
      };
      setItemModify(itemTemp);
    }
  };

  const handleInputLucroVenda = (event) => {
    const propriedade = event.target.name;
    const valor = unformatPrice(event.target.value);
    const vendaLucroTemp = { ...itemModify };
    if (propriedade === "precoVenda") {
      vendaLucroTemp.precoVenda = valor;
      vendaLucroTemp.lucroDesejado = calculoLucro(vendaLucroTemp) || undefined;
    }
    if (propriedade === "lucroDesejado") {
      vendaLucroTemp.lucroDesejado = valor ? valor : undefined;
      vendaLucroTemp.precoVenda =
        calculoPrecoVenda(vendaLucroTemp) || undefined;
    }
    if (vendaLucroTemp.precoVenda < vendaLucroTemp.precoCusto) {
      vendaLucroTemp.lucroDesejado = undefined;
    }
    setItemModify(vendaLucroTemp);
  };

  const handleChangeInputCusto = (event) => {
    let produtoTemp = {};
    const valor = unformatPrice(event.target.value);
    produtoTemp = calcularPrecoCusto(valor);
    setItemModify({
      ...produtoTemp,
      [event.target.name]: valor || undefined,
    });
  };

  const handleChangeInputCustoAdministrativo = (event) => {
    let produtoTemp = {
      ...itemModify,
    };
    const custoAdministrativo = parseFloat(unformatPrice(event.target.value));
    produtoTemp.precoCusto =
      parseFloat(itemModify.precoCusto) +
      (custoAdministrativo / 100) * parseFloat(itemModify.precoCusto);
    produtoTemp.precoVenda = calculoPrecoVenda(produtoTemp);
    setItemModify({
      ...produtoTemp,
      [event.target.name]: custoAdministrativo || undefined,
    });
  };

  const calcularPrecoCusto = (valueCompra) => {
    const produtoTemp = {
      ...itemModify,
      precoCusto: valueCompra,
    };
    if (valueCompra !== "") {
      produtoTemp.precoVenda = calculoPrecoVenda(produtoTemp);
      produtoTemp.custoAdministrativo = calculoCustoAdministrativo(produtoTemp);
      return produtoTemp;
    } else return itemModify;
  };

  const calculoLucro = (vendaLucroTemp) => {
    if (itemModify.precoCusto) {
      if (margemCalculo === "INVERSA") {
        const calculoLucroInverso =
          (1 -
            parseFloat(vendaLucroTemp.precoCusto) /
              parseFloat(vendaLucroTemp.precoVenda)) *
          100;
        return calculoLucroInverso >= 0 ? calculoLucroInverso : 0;
      } else {
        const calculoLucroConvencional =
          ((parseFloat(vendaLucroTemp.precoVenda) -
            parseFloat(vendaLucroTemp.precoCusto)) /
            parseFloat(vendaLucroTemp.precoCusto)) *
          100;
        return calculoLucroConvencional >= 0 ? calculoLucroConvencional : "";
      }
    }
  };

  const calculoPrecoVenda = (vendaLucroTemp) => {
    if (vendaLucroTemp.precoCusto) {
      if (margemCalculo === "INVERSA") {
        const lucro =
          parseFloat(vendaLucroTemp.lucroDesejado) >= 100
            ? (vendaLucroTemp.lucroDesejado = 99.99)
            : parseFloat(vendaLucroTemp.lucroDesejado);
        return parseFloat(vendaLucroTemp.precoCusto) / (1 - lucro / 100);
      } else {
        return (
          parseFloat(vendaLucroTemp.precoCusto) +
          parseFloat(vendaLucroTemp.precoCusto) *
            (parseFloat(vendaLucroTemp.lucroDesejado) / 100)
        );
      }
    }
  };

  const calculoCustoAdministrativo = (vendaLucroTemp) => {
    const custoInicial = calculoCustoNfEntradaItem(vendaLucroTemp);
    return (100 * (vendaLucroTemp.precoCusto - custoInicial)) / custoInicial;
  };

  const updateItemNfEntradaItens = () => {
    const updatedItems = [...nfEntradaItens];
    updatedItems[currentPage] = itemModify;
    return updatedItems;
  };

  const nextPage = () => {
    if (currentPage < nfEntradaItens.length - 1) {
      setCurrentPage(currentPage + 1);
      setItemModify(nfEntradaItens[currentPage + 1]);
      const itensUpdated = updateItemNfEntradaItens();
      setNfEntradaItens(itensUpdated);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setItemModify(nfEntradaItens[currentPage - 1]);
      const itensUpdated = updateItemNfEntradaItens();
      setNfEntradaItens(itensUpdated);
    }
  };

  const handleClose = () => {
    setCurrentPage(0);
    setNfEntradaItens(initialNfEntradaItensState);
    setOpen(false);
  };

  const handleSubmitPrecificacao = () => {
    updateProdutos();
    if (
      Number(nfEntrada.totalPagamentos.toFixed(2)) +
        Number((nfEntrada.entrada || 0).toFixed(2)) <
      Number(nfEntrada.totalCompra)
    ) {
      notification.alertaGenericos(
        "É necessário atualizar valor da(s) parcela(s)"
      );
      return false;
    }
    handleSubmit();
    //submitNfEntrada();
  };

  const updateProdutos = async () => {
    const itensUpdated = updateItemNfEntradaItens();
    setLoading(true);
    const result = await produtosService.atualizarLista(itensUpdated);
    if (!result.isAxiosError) {
      notification.alteracaoSucesso();
    } else responseErros(result);
    setLoading(false);
  };

  /* const submitNfEntrada = async () => {
    setLoading(true);
    nfEntradaValidator
      .validate(nfEntrada, { abortEarly: false })
      .then(async () => {
        if (
          Number(nfEntrada.totalPagamentos.toFixed(2)) +
            Number((nfEntrada.entrada || 0).toFixed(2)) <
          Number(nfEntrada.totalCompra)
        ) {
          notification.alertaGenericos(
            "É necessário atualizar valor da(s) parcela(s)"
          );
          setLoading(false);
          return false;
        }
        cadastrarNfEntrada(nfEntrada);
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  }; */

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        disableEscapeKeyDown
        disableScrollLock
        maxWidth={"md"}
        scroll="body"
        open={open}
      >
        <Card className="p-3">
          <CardHeader title="Precificação de Produtos" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <Autocomplete
                    id="produtoId"
                    name="produtoId"
                    options={nfEntradaItens}
                    autoHighlight
                    loadingText="Carregando"
                    noOptionsText="Digite e pressione Enter"
                    getOptionLabel={(option) =>
                      option.descricao ? option.descricao : ""
                    }
                    onChange={(e, newValue) => {
                      const selectedIndex = nfEntradaItens.findIndex(
                        (item) => item?.descricao === newValue?.descricao
                      );
                      if (selectedIndex !== -1) {
                        setItemModify(nfEntradaItens[selectedIndex]);
                        setCurrentPage(selectedIndex);
                        const itensUpdated = updateItemNfEntradaItens();
                        setNfEntradaItens(itensUpdated);
                      }
                    }}
                    value={nfEntradaItens[currentPage] || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Buscar produto"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <hr />
            {itemModify && (
              <>
                <Grid container spacing={2} className="mb-2">
                  <Grid item xs={2}>
                    <TextField
                      id="codigo"
                      name="codigo"
                      label="Cód"
                      disabled
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      value={itemModify.codigo}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="descricao"
                      name="descricao"
                      label="Descrição"
                      disabled
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      value={itemModify.descricao}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mb-2">
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="precoCompraCadastro"
                      name="precoCompraCadastro"
                      label="Preço Compra Anterior"
                      disabled
                      variant="outlined"
                      value={itemModify.precoCompraCadastro ?? "0"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="precoCompra"
                      name="precoCompra"
                      label="Preço Compra Atual"
                      variant="outlined"
                      disabled
                      value={itemModify.precoCompra ?? "0"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="precoCustoCadastro"
                      name="precoCustoCadastro"
                      label="Preço Custo Anterior"
                      disabled
                      variant="outlined"
                      value={itemModify.precoCustoCadastro ?? "0"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="precoCusto"
                      name="precoCusto"
                      label="Preço Custo Atual"
                      variant="outlined"
                      onBlur={(event) => {
                        handleChangeInputCusto(event);
                        handleBlurCusto(event);
                      }}
                      value={itemModify.precoCusto ?? "0"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="impostoValorIcmsStAliquota"
                      name="impostoValorIcmsStAliquota"
                      label="Vr. Subs Trib"
                      value={
                        itemModify.impostoValorIcmsStAliquota /
                          (itemModify.quantidade || 0) ?? "0"
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="tributosValorIpiAliquota"
                      name="tributosValorIpiAliquota"
                      label="Vr. IPI"
                      value={
                        itemModify.tributosValorIpiAliquota /
                          (itemModify.quantidade || 0) ?? "0"
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="valorFrete"
                      name="valorFrete"
                      label="Vr. Frete"
                      value={
                        itemModify.valorFrete / (itemModify.quantidade || 0) ??
                        "0"
                      }
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextFielDecimalNumbers
                      id="custoAdministrativo"
                      name="custoAdministrativo"
                      label="Custo Administrativo"
                      value={itemModify.custoAdministrativo ?? ""}
                      shrink={true}
                      onBlur={(e) => {
                        handleChangeInputCustoAdministrativo(e);
                      }}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="mb-2 d-flex justify-content-end"
                >
                  <Tooltip
                    title={
                      <>
                        Lucro deve ser menor que 100%
                        <br />
                        em cálculo de margem inversa (MI)
                      </>
                    }
                    arrow
                    placement="bottom"
                    enterTouchDelay={0}
                    enterDelay={500}
                  >
                    <Grid item xs={3}>
                      <TextFielDecimalNumbers
                        id="lucroDesejado"
                        name="lucroDesejado"
                        label="Lucro Desejado"
                        variant="outlined"
                        shrink={true}
                        value={itemModify.lucroDesejado ?? "0"}
                        onBlur={handleInputLucroVenda}
                        fullWidth
                      />
                    </Grid>
                  </Tooltip>
                  <Grid item xs={3}>
                    <CurrencyInput
                      id="precoVenda"
                      name="precoVenda"
                      label="Preço Venda"
                      value={itemModify.precoVenda ?? "0"}
                      onBlur={(e) => {
                        handleInputLucroVenda(e);
                        handleBlurPrecoVenda(e);
                      }}
                      required
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <hr />
          </CardContent>
          <CardActions className="d-flex justify-content-between">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  id="proximo"
                  className="ms-2"
                  variant="contained"
                  color="primary"
                  onClick={prevPage}
                  disabled={!currentPage}
                >
                  <i
                    className="ph-fill ph-caret-left"
                    style={{ color: "white", fontSize: 22, marginRight: 10 }}
                  ></i>
                  Voltar
                </Button>
                <Button
                  id="proximo"
                  className="ms-2"
                  variant="contained"
                  color="primary"
                  onClick={nextPage}
                  disabled={currentPage + 1 === nfEntradaItens?.length}
                >
                  Próximo
                  <i
                    className="ph-fill ph-caret-right"
                    style={{ color: "white", fontSize: 22, marginLeft: 10 }}
                  ></i>
                </Button>
              </Grid>
              <Grid item xs={6} className="mb-2 d-flex justify-content-end">
                <Button
                  className="ms-2"
                  variant="contained"
                  color="padrao"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
                <Button
                  id="submit"
                  className="ms-2"
                  variant="contained"
                  color="success"
                  onClick={handleSubmitPrecificacao}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalPrecificacaoNfEntrada;
