export class DocumentoFreteEntity {
  dataEmissao = null;
  dataSaida = null;
  dataChegada = null;
  serieDocumento = null;
  numeroDocumento = null;
  cfopId = null;
  especie = null;
  modalidadeFrete = null;
  modeloDocumento = null;
  tipoFrete = null;
  chave = null;
  naturezaFrete = null;
  transportadorId = null;
  ufId = null;
  rntrc = null;
  movimentacaoFinanceira = null;
  planoFinanceiroId = null;
  centroCustoId = null;
  valorFrete = null;
  valorSeguro = null;
  valorMercadoria = null;
  pesoBrutoTotal = null;
  listaNfe = [];
  pagamentos = [];

  constructor(documentoFrete) {
    this.dataEmissao = documentoFrete.dataEmissao;
    this.dataSaida = documentoFrete.dataSaida;
    this.dataChegada = documentoFrete.dataChegada;
    this.serieDocumento = documentoFrete.serieDocumento;
    this.numeroDocumento = documentoFrete.numeroDocumento;
    this.cfopId = documentoFrete.cfopId;
    this.especie = documentoFrete.especie;
    this.modalidadeFrete = documentoFrete.modalidadeFrete;
    this.modeloDocumento = documentoFrete.modeloDocumento;
    this.tipoFrete = documentoFrete.tipoFrete;
    this.chave = documentoFrete.chave;
    this.naturezaFrete = documentoFrete.naturezaFrete;
    this.transportadorId = documentoFrete.transportadorId;
    this.ufId = documentoFrete.ufId;
    this.rntrc = documentoFrete.rntrc;
    this.movimentacaoFinanceira = documentoFrete.movimentacaoFinanceira;
    this.planoFinanceiroId = documentoFrete.planoFinanceiroId;
    this.centroCustoId = documentoFrete.centroCustoId;
    this.valorFrete = documentoFrete.valorFrete;
    this.valorSeguro = documentoFrete.valorSeguro;
    this.valorMercadoria = documentoFrete.valorMercadoria;
    this.pesoBrutoTotal = documentoFrete.pesoBrutoTotal;
    this.listaNfe = documentoFrete.listaNfe;
    this.pagamentos = documentoFrete.pagamentos;
  }
}
