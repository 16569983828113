import SiafLiteApiRepository from "../../external/siafLiteApi";

class NfSaidaItensService {
  constructor() {
    this.urlBase = "/faturamento/nf-saida-itens";
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }
}

export default NfSaidaItensService;
