export class Empresa {
  imagemLogo = null;
  fantasia = null;
  razaoSocial = null;
  cnpjCpf = null;
  inscricaoEstadualIsento = null;
  naturezaSocial = null;
  inscricaoEstadual = null;
  registroGeral = null;
  cnae = null;
  regimeTributario = null;
  email = null;
  emailContabil = null;
  telefone = null;
  celular = null;
  enderecoCep = null;
  enderecoLogradouro = null;
  enderecoLogradouroNumero = null;
  enderecoComplemento = null;
  enderecoBairro = null;
  cidadeId = null;
  contadorAutorizarDadosXmlNfe = null;
  contadorAutorizarDownloadXmlNfe = null;
  contadorNome = null;
  contadorCpf = null;
  contadorCnpj = null;
  contadorCrc = null;
  suspenderAtividade = null;
  informacoes = null;
  codigoSegurancaContribuinte = null;
  idCodigoSegurancaContribuinte = null;
  tipoEmissaoNfce = null;
  tipoEmissaoNfe = null;
  producao = null;
  serieCertificado = null;
  emiteNfce = null;
  emiteNfe = null;
  pctContadorId = null;

  constructor(empresa) {
    this.imagemLogo = empresa.imagemLogo;
    this.fantasia = empresa.fantasia;
    this.razaoSocial = empresa.razaoSocial;
    this.cnpjCpf = empresa.cnpjCpf;
    this.inscricaoEstadualIsento = empresa.inscricaoEstadualIsento;
    this.naturezaSocial = empresa.naturezaSocial;
    this.inscricaoEstadual = empresa.inscricaoEstadual;
    this.registroGeral = empresa.registroGeral;
    this.cnae = empresa.cnae;
    this.regimeTributario = empresa.regimeTributario;
    this.email = empresa.email;
    this.emailContabil = empresa.emailContabil;
    this.telefone = empresa.telefone;
    this.celular = empresa.celular;
    this.enderecoCep = empresa.enderecoCep;
    this.enderecoLogradouro = empresa.enderecoLogradouro;
    this.enderecoLogradouroNumero = empresa.enderecoLogradouroNumero;
    this.enderecoComplemento = empresa.enderecoComplemento;
    this.enderecoBairro = empresa.enderecoBairro;
    this.cidadeId = empresa.cidadeId;
    this.contadorAutorizarDadosXmlNfe = empresa.contadorAutorizarDadosXmlNfe;
    this.contadorAutorizarDownloadXmlNfe =
      empresa.contadorAutorizarDownloadXmlNfe;
    this.contadorNome = empresa.contadorNome;
    this.contadorCpf = empresa.contadorCpf;
    this.contadorCnpj = empresa.contadorCnpj;
    this.contadorCrc = empresa.contadorCrc;
    this.suspenderAtividade = empresa.suspenderAtividade;
    this.informacoes = empresa.informacoes;
    this.codigoSegurancaContribuinte = empresa.codigoSegurancaContribuinte;
    this.idCodigoSegurancaContribuinte = empresa.idCodigoSegurancaContribuinte;
    this.tipoEmissaoNfce = empresa.tipoEmissaoNfce;
    this.tipoEmissaoNfe = empresa.tipoEmissaoNfe;
    this.producao = empresa.producao;
    this.serieCertificado = empresa.serieCertificado;
    this.emiteNfce = empresa.emiteNfce;
    this.emiteNfe = empresa.emiteNfe;
  }

  setImagemLogo(imagem) {
    this.imagemLogo = imagem;
  }

  setContadorId(contadorId) {
    this.pctContadorId = contadorId;
  }
}
