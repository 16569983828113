import { Typography } from "../../../../../../components";
import { formatCnpj, formatCpf } from "../../../../../../utils";

export default function HeaderRelatorioContas(conta) {
  const strongKey = (key, dado) => {
    return (
      <>
        <strong>{key}:</strong> {dado}
      </>
    );
  };

  function mascaraDeTelefone(telefone) {
    const isCelular = telefone.length === 11;
    if (isCelular) {
      const ddd = telefone.slice(0, 2);
      const parte1 = telefone.slice(2, 7);
      const parte2 = telefone.slice(7, 11);
      return `(${ddd}) ${parte1}-${parte2}`;
    } else {
      const ddd = telefone.slice(0, 2);
      const parte1 = telefone.slice(2, 6);
      const parte2 = telefone.slice(6, 10);
      return `(${ddd}) ${parte1}-${parte2}`;
    }
  }

  return (
    <div>
      <Typography variant="h5" className="m-1 text-uppercase font-weight-bold">
        {conta.codigoPessoa ? `${conta.codigoPessoa} - ` : null}
        {conta.nomeRazaoSocial}
        {conta?.telefone
          ? ` - Tel: ${mascaraDeTelefone(conta.telefone)}`
          : null}
        {conta?.celular ? ` - Cel: ${mascaraDeTelefone(conta.celular)}` : null}
      </Typography>
      <div className="mx-1 mt-3 mb-3">
        {strongKey("Endereço")} {conta.logradouro ?? ""}
        {conta.numeroEndereco ? `, ${conta.numeroEndereco}` : null}
        {conta.complemento ? `, ${conta.complemento}` : null}
        {" - "}
        {conta.bairro ? strongKey("Bairro", conta.bairro) : null} <br />
        {conta.cep ? strongKey("Cep", conta.cep) : null}
        {" - "}
        {conta.cidade ? strongKey("Cidade", conta.cidade) : null}{" "}
        {conta.estado ? `- ${conta.estado}` : null}
        {" - "}
        {conta.naturezaSocial === "fisica" && conta?.cnpjCpf
          ? strongKey("CPF", formatCpf(conta.cnpjCpf))
          : null}
        {conta.naturezaSocial !== "fisica" && conta?.cnpjCpf
          ? strongKey("CNPJ", formatCnpj(conta.cnpjCpf))
          : null}
      </div>
    </div>
  );
}
