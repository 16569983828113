import * as Yup from "yup";

const produtoValidator = Yup.object().shape({
  ativado: Yup.boolean().nullable(),
  imagem: Yup.string().nullable(),
  codigoBarra: Yup.string().nullable(),
  cean: Yup.number().nullable(),
  grupoProdutoId: Yup.number().required("Grupo de Produto"),
  tipo: Yup.string().required("Tipo"),
  descricao: Yup.string().required("Descrição"),
  unidadeComercialCompraId: Yup.number().required(
    "Unidade Comercial de Compra"
  ),
  unidadeComercialVendaId: Yup.number().required("Unidade Comercial de Venda"),
  conversorEntrada: Yup.number().nullable(),
  estoqueAtual: Yup.number().nullable(),
  fracionavel: Yup.boolean().nullable(),
  lerPesoPorBalanca: Yup.string().nullable(),
  precoCompra: Yup.mixed().test(
    "is-number",
    "Preço de compra",
    (value) => value && !isNaN(Number(value))
  ),
  precoCusto: Yup.mixed().test(
    "is-number",
    "Preço de custo",
    (value) => value && !isNaN(Number(value))
  ),
  precoVenda: Yup.mixed().test(
    "is-number",
    "Preço de venda",
    (value) => value && !isNaN(Number(value))
  ),
  precoMedio: Yup.number().nullable(),
  lucroDesejado: Yup.number().nullable(),
  grupoTributacaoId: Yup.number().when("tipo", (tipo) => {
    return tipo === "kit"
      ? Yup.number().nullable()
      : Yup.number().required("Grupo tributário");
  }),
  //modeloTributacaoId:Yup.number().required("Modelo de tributação"),
  tipoItemId: Yup.number().nullable(),
  ncmId: Yup.number().when("tipo", (tipo) => {
    return tipo === "kit"
      ? Yup.number().nullable()
      : Yup.number().required("NCM");
  }),
  ncmCodigo: Yup.string().when("tipo", (tipo) => {
    return tipo === "kit"
      ? Yup.string().nullable()
      : Yup.string()
          .min(8, "NCM deve ter pelo menos 8 caracteres")
          .required("NCM");
  }),
  cestId: Yup.number().nullable(),
  origemMercadoriaId: Yup.number().required("Origem da mercadoria"),
  aliquotaMva: Yup.number().nullable(),
  informacaoAdicionalNfe: Yup.string().nullable(),
  informacaoComplementar: Yup.string().nullable(),
  produtosSimilaresIds: Yup.array().required(),
  produtosKitIds: Yup.array().when("tipo", (tipo) => {
    return tipo === "kit"
      ? Yup.array().min(2, "Inserir ao menos 2 produtos ao kit")
      : Yup.array().nullable();
  }),
  fornecedores: Yup.array().when("tipo", (tipo) => {
    return tipo === "kit"
      ? Yup.array().nullable()
      : Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            codigoProduto: Yup.string(),
            quantidadeEmbalagem: Yup.number(),
          })
        );
  }),
  produtosGrade: Yup.array().when("tipo", (tipo) => {
    return tipo === "grade"
      ? Yup.array()
          .min(1, "Ter ao menos um produto na grade")
          .of(
            Yup.object()
              .shape({
                descricao: Yup.string().required("Descrição na grade"),
                barra: Yup.number().nullable(),
                eixoX: Yup.number().required("eixoX"),
                eixoY: Yup.number().nullable(),
              })
              .required("Ter ao menos um produto na grade")
          )
          .required("Ter ao menos um produto na grade")
      : Yup.array().nullable();
  }),
  /*  variacoesProdutoGrade: Yup.array().when("tipo", {
    is: "grade",
    then: Yup.array()
      .min(1, "Ter ao menos uma variação de grade")
      .of(
        Yup.object()
          .shape({
            descricaoProduto: Yup.string().required(),
            coluna: Yup.string().required(),
            valor: Yup.string().nullable(),
          })
          .required("Ter ao menos um produto na grade")
      )
      .required("Ter ao menos um produto na grade"),
  }), */
});

export default produtoValidator;
