import { useState } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Typography,
} from "../../../../components";
import PasswordStrengthBar from "react-password-strength-bar";

const SteapNovaSenha = ({ senha, setSenha, inputErros, setSenhaScore }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onChangeSenha = (e) => {
    setSenha({
      ...senha,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container spacing={2} justifyContent="center" className="py-4">
      <Grid item xs={8}>
        <TextField
          id="novaSenha"
          name="novaSenha"
          label="Nova senha"
          variant="outlined"
          onChange={onChangeSenha}
          fullWidth
          error={inputErros.get("novaSenha")}
          autoComplete="new-password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? (
                    <i
                      className="ph-fill ph-eye-slash"
                      style={{ color: "#494C62", fontSize: 18 }}
                    ></i>
                  ) : (
                    <i
                      className="ph-fill ph-eye"
                      style={{ color: "#494C62", fontSize: 18 }}
                    ></i>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography style={{ color: "#E53935", fontSize: 10 }}>
          Deve ter de 6 a 20 caracteres e no mínimo razoável
        </Typography>
        <PasswordStrengthBar
          scoreWords={[
            "senha fraca",
            "senha fraca",
            "senha razoável",
            "senha boa",
            "senha forte",
          ]}
          shortScoreWord={"senha curta"}
          password={senha?.novaSenha}
          onChangeScore={(score) => {
            setSenhaScore(score);
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="confirmarNovaSenha"
          name="confirmarNovaSenha"
          label="Confirmar senha"
          error={inputErros.get("confirmarNovaSenha")}
          variant="outlined"
          onChange={onChangeSenha}
          fullWidth
          type={showPassword ? "text" : "password"}
          autocomplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? (
                    <i
                      className="ph-fill ph-eye-slash"
                      style={{ color: "#494C62", fontSize: 18 }}
                    ></i>
                  ) : (
                    <i
                      className="ph-fill ph-eye"
                      style={{ color: "#494C62", fontSize: 18 }}
                    ></i>
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SteapNovaSenha;
