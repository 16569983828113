import { Typography as TypographyMui } from "@mui/material";

const Typography = ({
  children,
  className,
  classes,
  color,
  gutterBottom,
  noWrap,
  style,
  paragraph,
  sx,
  align,
  variant,
  variantMapping,
}) => {
  return (
    <TypographyMui
      className={className}
      sx={sx}
      color={color}
      style={style}
      classes={classes}
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variant={variant}
      variantMapping={variantMapping}
    >
      {children}
    </TypographyMui>
  );
};

export default Typography;
