import { useState, useEffect } from "react";
import {
  GrupoPessoasService,
  TipoContribuinteClienteService,
} from "../../../../services";
import {
  Grid,
  TextField,
  FormControl,
  Autocomplete,
  CurrencyInput,
  Select,
  MenuItem,
} from "../../../../components";
import { value } from "../../../../helpers";
import { cacheApi, responseErros, unformatPrice } from "../../../../utils";
import { filtrosUrl } from "../../../../helpers";

export default function FormCliente({
  clienteFormRef,
  cliente,
  setCliente,
  habilitado,
  inputErros,
  cacheDto,
  setCacheDto,
}) {
  const [listaGrupoPessoa, setListaGrupoPessoa] = useState([]);
  const [tiposContribuinte, setTiposContribuinte] = useState([]);
  const grupoPessoasService = new GrupoPessoasService();
  const tipoContribuinteClienteService = new TipoContribuinteClienteService();

  useEffect(() => {
    buscarTipoContribuinte();
    buscaGruposPessoas();
  }, []);

  const buscaGruposPessoas = async () => {
    const filtros = {
      ativado: true,
      tipoPessoa: 1,
      restritiva: true,
    };
    const label = filtrosUrl.getUrl(filtros, grupoPessoasService.urlBase);
    const cacheBody = {
      apiFunction: grupoPessoasService.getAllFiltroAvancado(filtros),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(label, cacheBody);
    if (!res.isAxiosError) {
      setListaGrupoPessoa(res.data.rows);
    } else {
      responseErros(res);
    }
  };

  const buscarTipoContribuinte = async () => {
    const cacheBody = {
      timeMinutes: 60,
      apiFunction: tipoContribuinteClienteService.getAll(),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(
      tipoContribuinteClienteService.urlBase,
      cacheBody
    );
    if (!res.isAxiosError) {
      setTiposContribuinte(res.data);
    } else {
      responseErros(res);
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    const clienteTemp = {
      ...cliente,
      [name]: value ? value.id : null,
    };
    if (
      name === "grupoPessoaId" &&
      value?.tabelaPrecoId &&
      !cliente?.tabelaPrecoPadraoId
    )
      clienteTemp.tabelaPrecoPadraoId = value.tabelaPrecoId;
    setCliente(clienteTemp);
  };

  const handleChangeInput = (event) => {
    setCliente({
      ...cliente,
      [event.target.name]: event.target.value ? event.target.value : null,
    });
  };

  const handleInputBlur = (e) => {
    setCliente((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  return (
    <form ref={clienteFormRef}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Select
            label="Consumidor Final"
            id="indicadorConsumidorFinal"
            name="indicadorConsumidorFinal"
            margin="normal"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            value={cliente?.indicadorConsumidorFinal || false}
            required
            onChange={(event) => {
              setCliente({
                ...cliente,
                [event.target.name]: event.target.value,
              });
            }}
            error={inputErros.get("indicadorConsumidorFinal")}
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="tipoContribuinteId"
              name="tipoContribuinteId"
              options={tiposContribuinte}
              noOptionsText="Sem opções"
              autoHighlight
              disabled={habilitado ? false : true}
              getOptionLabel={(option) =>
                option.descricao ? option.descricao : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo Contribuinte"
                  required
                  variant={habilitado ? "outlined" : "filled"}
                  error={inputErros.get("tipoContribuinteId")}
                />
              )}
              onChange={(e, value) =>
                handleChangeAutocomplete("tipoContribuinteId", value)
              }
              value={value.autoComplete(
                tiposContribuinte,
                cliente.tipoContribuinteId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="grupoPessoaId"
              name="grupoPessoaId"
              disabled={habilitado ? false : true}
              options={listaGrupoPessoa}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              filterSelectedOptions
              onChange={(_, value) => {
                handleChangeAutocomplete("grupoPessoaId", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={habilitado ? "outlined" : "filled"}
                  label="Grupos de Pessoas"
                />
              )}
              value={value.autoComplete(
                listaGrupoPessoa,
                cliente.grupoPessoaId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CurrencyInput
            label="Limite de Crédito"
            name="limiteCompra"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            onBlur={handleInputBlur}
            value={cliente.limiteCompra ?? ""}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label="Mensagem para mostrar na venda"
            name="mensagemVenda"
            margin="normal"
            onChange={handleChangeInput}
            value={cliente.mensagemVenda ?? ""}
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
}
