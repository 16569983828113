import { Grid, Button, TextField, DataGrid } from "../../../../components";
import { format } from "date-fns";
import { SessionService, notification } from "../../../../services";
import { formatDate } from "../../../../utils";

const Observacoes = (props) => {
  const sessionService = new SessionService();
  const colunas = [
    {
      field: "observacao",
      headerName: "Observação",
      flex: 400,
    },
    {
      field: "createdAt",
      headerName: "Data Observação",
      flex: 300,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy - HH:mm"),
    },
    {
      field: "insertUserId",
      headerName: "Responsável",
      flex: 200,
      valueGetter: (params) => params.value || sessionService.getUsuario().nome,
    },
  ];

  const handleDeleteObservacao = async (id) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir esta observação?"
    );
    if (retornoAlerta.isConfirmed) {
      props.removerObservacao(id);
    }
  };

  return (
    <form onSubmit={(e) => props.handleObservacao(e)}>
      <Grid container spacing={2} className="mt-3">
        <Grid item xs={11}>
          <TextField
            id="conteudo"
            name="conteudo"
            label="Observação"
            variant="outlined"
            required
            margin="normal"
            fullWidth
            value={props.observacao ?? ""}
            onChange={props.handleChangeObservacao}
          ></TextField>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="mt-4"
            type="submit"
          >
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 24 }}
            ></i>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <DataGrid
          rows={props.observacaoList}
          columns={colunas}
          deleteShow
          onClickDelete={handleDeleteObservacao}
        />
      </Grid>
    </form>
  );
};

export default Observacoes;
