import { formatPrice } from "../../../../../../../utils";
import "../modelo-nfe.scss";

const DuplicatasNfe = ({ duplicatas }) => {
  const duplicatasPorGrupo = [];
  for (let i = 0; i < duplicatas.length; i += 6) {
    duplicatasPorGrupo.push(duplicatas.slice(i, i + 6));
  }

  return (
    <>
      {duplicatas?.length > 0 && (
        <div>
          <h3 className="danfe_titulo_externo">Duplicatas</h3>
          <table className="danfe_tabelas">
            <tbody>
              {duplicatasPorGrupo.map((grupoDuplicatas, index) => (
                <tr key={index}>
                  {grupoDuplicatas.map((duplicata, innerIndex) => (
                    <td
                      key={innerIndex}
                      className="danfe_celula_bordas .valor_duplicata"
                    >
                      Número: {duplicata?.nDup}&nbsp;
                      <br />
                      Vencimento:&nbsp;
                      {new Date(duplicata?.dVenc).toLocaleString("pt-BR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                      &nbsp; Valor: {formatPrice(duplicata?.vDup)}&nbsp;
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DuplicatasNfe;
