import { ValidarEmailHelper } from "../../../helpers";
const validarEmailHelper = new ValidarEmailHelper();

export class Usuario {
  nome = null;
  email = null;
  senha = null;
  confirmarSenha = null;
  ativado = null;
  pctPerfilUsuarioId = null;
  padrao = false;

  constructor(usuario) {
    this.nome = usuario.nome;
    this.email = usuario.email;
    this.senha = usuario.senha;
    this.confirmarSenha = usuario.confirmarSenha;
    this.ativado = usuario.ativado;
    this.pctPerfilUsuarioId = usuario.pctPerfilUsuarioId;
    this.padrao = usuario.padrao ?? false;
  }

  verificarSenhaForte(usuario, senhaScore) {
    if (usuario?.senha && senhaScore < 2) return false;
    return true;
  }

  validarEmail() {
    return validarEmailHelper.email(this.email);
  }
}
