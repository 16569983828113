import { Tabs as TabsMui } from "@mui/material";

const Tabs = ({
  children,
  action,
  allowScrollButtonsMobile,
  ariaLabel,
  ariaLabelledby,
  centered,
  classes,
  component,
  indicatorColor,
  onChange,
  orientation,
  ScrollButtonComponent,
  scrollButtons,
  selectionFollowsFocus,
  slotProps,
  slots,
  sx,
  TabIndicatorProps,
  TabScrollButtonProps,
  textColor,
  value,
  variant,
  visibleScrollbar,
}) => {
  return (
    <TabsMui
      action={action}
      allowScrollButtonsMobile={allowScrollButtonsMobile}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      centered={centered}
      classes={classes}
      component={component}
      indicatorColor={indicatorColor}
      onChange={onChange}
      orientation={orientation}
      ScrollButtonComponent={ScrollButtonComponent}
      scrollButtons={scrollButtons}
      selectionFollowsFocus={selectionFollowsFocus}
      slotProps={slotProps}
      slots={slots}
      sx={sx}
      TabIndicatorProps={TabIndicatorProps}
      TabScrollButtonProps={TabScrollButtonProps}
      textColor={textColor}
      value={value}
      variant={variant}
      visibleScrollbar={visibleScrollbar}
    >
      {children}
    </TabsMui>
  );
};

export default Tabs;
