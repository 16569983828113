import {
  CurrencyInput,
  DatePicker,
  Grid,
  TextField,
} from "../../../../components";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  onBlurInput,
  valueDate,
  onChangeDate,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="serieDocumento"
            name="serieDocumento"
            label="Série Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("serieDocumento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="numeroDocumento"
            name="numeroDocumento"
            label="Nº Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("numeroDocumento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStart"
            name="dataEmissaoStart"
            label="Data Emissão De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoStart")}
            onChange={(date) => onChangeDate("dataEmissaoStart", date)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEnd"
            name="dataEmissaoEnd"
            label="Data Emissão Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoEnd")}
            onChange={(date) => onChangeDate("dataEmissaoEnd", date)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CurrencyInput
            id="totalStart"
            name="totalStart"
            label="Total (R$) De"
            value={valueInput("totalStart")}
            onBlur={onBlurInput}
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyInput
            id="totalEnd"
            name="totalEnd"
            label="Total (R$) Até"
            value={valueInput("totalEnd")}
            onBlur={onBlurInput}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
