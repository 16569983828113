import { TextField, Grid } from "../../../../components";

const SteapIdentificacao = ({ inputErroEmail, setEmail }) => {
  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Grid container justifyContent="center" className="py-4">
      <Grid item xs={7}>
        <TextField
          id="email"
          name="email"
          label="Informe o e-mail do usuário"
          variant="outlined"
          onChange={(event) => onChangeEmail(event)}
          error={inputErroEmail}
          fullWidth
          required
        />
      </Grid>
    </Grid>
  );
};

export default SteapIdentificacao;
