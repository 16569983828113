import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import {
  Autocomplete,
  Switch,
  FormControlLabel,
  FormControl,
  TextField,
  Grid,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Tooltip,
  Loading,
} from "../../../components";
import { GrupoProdutosService, notification } from "../../../services";
import {
  InputErros,
  value as getInputValue,
  PermisoesHelper,
} from "../../../helpers";
import { responseErros } from "../../../utils";
import grupoProdutosValidator from "./middlewares/grupoProdutos.validator";
import { GrupoProdutoEntity } from "../entities";

const AddGrupoProdutos = ({
  id,
  handleCloseDialog,
  openDialog,
  buscaGruposProdutos,
  isFullscreenDialog,
  handleCloseFullscreenDialog,
}) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("grupo-produtos-visualizar");
  const [loading, setLoading] = useState(false);
  const [grupoProdutos, setGrupoProdutos] = useState({
    ativado: true,
  });
  const [grupoSuperiorList, setGrupoSuperiorList] = useState([]);
  const [idPaiDisabled, setIdPaiDisabled] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const grupoProdutosService = new GrupoProdutosService();

  useEffect(() => {
    if (openDialog || isFullscreenDialog) {
      buscarListaGruposSuperiores({ nonPaginated: true });
      if (!id) return;
      buscarGrupoProdutos();
    }
  }, [openDialog, isFullscreenDialog]);

  const buscarGrupoProdutos = async () => {
    setLoading(true);
    const result = await grupoProdutosService.getById(id);
    if (!result.isAxiosError) {
      setGrupoProdutos(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarListaGruposSuperiores = async (filtros) => {
    const result = await grupoProdutosService.getAll(filtros);
    if (!result.isAxiosError) {
      if (id) setIdPaiDisabled(true);
      const filteredList = result.data.filter((item) => item?.id !== id);
      setGrupoSuperiorList(filteredList);
    } else responseErros(result);
  };

  const handleInputChange = (event) => {
    setGrupoProdutos((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    grupoProdutosValidator
      .validate(grupoProdutos, { abortEarly: false })
      .then(async () => {
        const grupoProdutoEntity = new GrupoProdutoEntity(grupoProdutos);
        if (id) {
          const response = await grupoProdutosService.atualizar(
            id,
            grupoProdutoEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            handleCloseDialog();
            setIdPaiDisabled(false);
            notification.alteracaoSucesso();
            buscaGruposProdutos();
          } else {
            responseErros(response);
          }
        } else {
          const response = await grupoProdutosService.cadastrar(
            grupoProdutoEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            if (!isFullscreenDialog) {
              handleCloseDialog();
              setIdPaiDisabled(false);
              notification.cadastroSucesso();
              buscaGruposProdutos();
            } else {
              handleCloseFullscreenDialog();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setGrupoProdutos(null);
    setGrupoSuperiorList([]);
    if (isFullscreenDialog) {
      handleCloseFullscreenDialog();
    } else handleCloseDialog();
  };

  return (
    <>
      <Card className="p-3">
        <CardHeader
          title={
            id ? "Editar Grupo de Produtos" : "Cadastrar Grupo de Produtos"
          }
          action={
            <FormControlLabel
              control={
                <Switch
                  checked={
                    grupoProdutos?.ativado ? grupoProdutos?.ativado : false
                  }
                  onChange={() =>
                    setGrupoProdutos((prevState) => ({
                      ...prevState,
                      ativado: !prevState?.ativado,
                    }))
                  }
                  name="ativado"
                  color="primary"
                />
              }
              label={grupoProdutos?.ativado ? "Ativado" : "Desativado"}
            />
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="descricao"
                name="descricao"
                label="Descricao"
                variant="outlined"
                value={grupoProdutos?.descricao ? grupoProdutos.descricao : ""}
                onChange={(event) => {
                  setInputErro([]);
                  handleInputChange(event);
                }}
                fullWidth
                required
                error={inputErros.get("descricao")}
              />
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title="Selecione o grupo superior deste grupo (opcional)"
                arrow
                placement="top"
              >
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    id="grupoSuperior"
                    name="grupoSuperior"
                    options={grupoSuperiorList}
                    autoHighlight
                    disabled={idPaiDisabled}
                    getOptionLabel={(option) =>
                      option?.descricao
                        ? `${option.codigo} - ${option.descricao}`
                        : ""
                    }
                    filterSelectedOptions
                    filterOptions={(options, state) =>
                      options.filter(
                        (option) =>
                          option.codigo.includes(state.inputValue) ||
                          option.descricao.includes(state.inputValue)
                      )
                    }
                    noOptionsText="Sem opções"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grupo de Produtos Pai"
                        variant="outlined"
                      />
                    )}
                    onChange={(_, newValue) => {
                      setGrupoProdutos((prevState) => ({
                        ...prevState,
                        idPai: newValue?.id,
                      }));
                    }}
                    value={getInputValue.autoComplete(
                      grupoSuperiorList,
                      grupoProdutos?.idPai
                    )}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!permissoesHelper.temPermisao("grupo-produtos-cadastrar")}
            onClick={handleSubmit}
          >
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Salvar
          </Button>
          <Button color="padrao" variant="contained" onClick={handleCancelar}>
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Card>
      <Loading loading={loading} />
    </>
  );
};

export default AddGrupoProdutos;
