export const icones = {
  dashboard: "ph-fill ph-house",
  estoque: "ph-fill ph-shopping-cart",
  faturamento: "ph-fill ph-wallet",
  financeiro: "ph-fill ph-money",
  fiscal: "ph-fill ph-calculator",
  integracao: "ph-fill ph-users",
  "painel-controle": "ph-fill ph-gear",
  pdv: "ph-fill ph-storefront",
  relatorios: "ph-bold ph-chart-line-up",
};

export const dicionario = {
  integracao: "integração",
  "painel-controle": "painel de controle",
  "meios-pagamento": "meios de pagamento",
  "condicoes-pagamento": "condições de pagamento",
  orcamentos: "orçamentos",
  "grupo-tributario-modelo-tributacao":
    "grupos tributários / modelos tributação",
  "operacoes-fiscais": "operações fiscais",
  "grupos-tributario": "grupos tributários",
  "modelos-tributacao": "modelos tributação",
  "perfis-usuarios": "perfis de usuário",
  usuarios: "usuários",
  configuracoes: "configurações",
  relatorios: "relatórios",
  visualizar: "permite acessar",
  cadastrar: "permite incluir",
  editar: "permite editar",
  excluir: "permite excluir",
  cancelar: "permite cancelar",
  "vendedor-obrigatorio": "vendedor obrigatório em",
  "preco-venda": "permite mudar preço venda item em",
  "editar-desconto": "permite editar desconto em",
};
