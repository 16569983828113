import { ListSubheader as ListSubheaderMui } from "@mui/material";

const ListSubheader = ({
  children,
  className,
  color,
  component,
  disableGutters,
  disableSticky,
  inset,
  secondary,
  sx,
}) => {
  return (
    <ListSubheaderMui
      className={className}
      color={color}
      component={component}
      disableGutters={disableGutters}
      disableSticky={disableSticky}
      inset={inset}
      secondary={secondary}
      sx={sx}
    >
      {children}
    </ListSubheaderMui>
  );
};

export default ListSubheader;
