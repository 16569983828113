import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Autocomplete,
  DatePicker,
} from "../../../../components";
import { PessoaService } from "../../../../services";
import { responseErros } from "../../../../utils";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueDate,
  onChangeDate,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const pessoaService = new PessoaService();
  const [fornecedorList, setFornecedorList] = useState([]);

  useEffect(() => {
    buscarFornecedores();
  }, []);

  const buscarFornecedores = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 2,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setFornecedorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="numeroNota"
          name="numeroNota"
          label="Nº Nota"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("numeroNota")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            id="fornecedorId"
            name="fornecedorId"
            options={fornecedorList}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) =>
              option?.nomeRazaoSocial ? option.nomeRazaoSocial : ""
            }
            getOptionSelected={(option, value) =>
              option?.nomeRazaoSocial === value?.nomeRazaoSocial
            }
            renderInput={(params) => (
              <TextField {...params} label="Fornecedor" variant="outlined" />
            )}
            onChange={(_, value) => onChangeAutocomplete("fornecedorId", value)}
            value={valueAutocomplete(fornecedorList, "fornecedorId")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataEmissaoStart"
          name="dataEmissaoStart"
          label="Data Emissão De"
          format="dd/MM/yyyy"
          value={valueDate("dataEmissaoStart")}
          onChange={(date) => onChangeDate("dataEmissaoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataEmissaoEnd"
          name="dataEmissaoEnd"
          label="Data Emissão Até"
          format="dd/MM/yyyy"
          value={valueDate("dataEmissaoEnd")}
          onChange={(date) => onChangeDate("dataEmissaoEnd", date)}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
