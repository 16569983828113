import { useState, useEffect } from "react";
import {
  CardHeader,
  Dialog,
  Stepper,
  Step,
  StepLabel,
  Box,
} from "../../../../components";
import { formatPrice, formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";
import {
  OrcamentosService,
  ProdutosService,
  PessoaService,
  MeioPagamentoService,
  CondicoesPagamentoService,
  OperacaoFiscalService,
  history,
  notification,
} from "../../../../services";
import shortid from "shortid";
import { OrcamentoStepperContent } from ".";
import { useImportContext } from "../../../../contexts/import.context";

const ImportarOrcamentoDialog = ({
  openImportarOrcamentoDialog,
  setOpenImportarOrcamentoDialog,
  setSelecionados,
  selecionados,
  setOrcamentoSelecionado,
  setLoading,
  loading,
  orcamentoSelecionado,
}) => {
  const orcamentosService = new OrcamentosService();
  const produtosService = new ProdutosService();
  const pessoaService = new PessoaService();
  const meioPagamentoService = new MeioPagamentoService();
  const condicoesPagamentoService = new CondicoesPagamentoService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const [orcamentos, setOrcamentos] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [listaMeiosPagamento, setListaMeiosPagamento] = useState([]);
  const [listaCondicoesPagamento, setListaCondicoesPagamento] = useState([]);
  const [itensQuantidade, setItensQuantidade] = useState([]);
  const { setPedidoDto } = useImportContext();
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [totalListaOrcamentos, setTotalListaOrcamentos] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [filtrosDialog, setFiltrosDialog] = useState({});
  const [itens, setItens] = useState(null);
  const steps = ["Selecionar Orçamentos", "Selecionar Itens e Importar"];
  const colunas = [
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Nº Doc",
      width: 105,
    },
    {
      field: "dataEmissao",
      headerName: "Data Emissão",
      width: 170,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      width: 250,
      valueGetter: (params) =>
        listaOperacoesFiscais.find((item) => item.id == params.value)
          ?.descricao,
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      width: 170,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "pessoaVendedorId",
      headerName: "Vendedor",
      width: 170,
      valueGetter: (params) =>
        listaVendedores.find((item) => item.id == params.value)
          ?.nomeRazaoSocial,
    },
    {
      field: "condicaoPagamentoId",
      headerName: "Condição de Pagamento",
      width: 250,
      valueGetter: (params) =>
        listaCondicoesPagamento.find((item) => item.id == params.value)
          ?.descricao,
    },
    {
      field: "meioPagamentoId",
      headerName: "Meio de Pagamento",
      width: 250,
      valueGetter: (params) =>
        listaMeiosPagamento.find((item) => item.id == params.value)?.descricao,
    },
    {
      field: "itens",
      headerName: "Total do Orçamento",
      width: 250,
      valueGetter: (params) => {
        const totalOrcamento =
          params.value
            .map((item) => item.valorUnitario * item.saldoExportar)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalOrcamento);
      },
    },
  ];

  useEffect(() => {
    if (openImportarOrcamentoDialog) {
      buscarOrcamentos();
    }
  }, [openImportarOrcamentoDialog]);

  const buscarOrcamentos = async (filtro) => {
    setLoading(true);
    const filtros = {
      ...filtro,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
    };
    const result = await orcamentosService.getAllFiltroAvancado(filtros);
    if (!result.isAxiosError) {
      setTotalListaOrcamentos(result.data.count);
      setOrcamentos(result.data.rows);
      buscarRelacionamentosOrcamento(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarOrcamentosAvancado = async (filtros) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
      restritiva: true,
    };
    if (filtrosAvancadosTemp.dataInicial && filtrosAvancadosTemp.dataFinal) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataInicial,
        ate: filtrosAvancadosTemp.dataFinal,
      });
    }
    delete filtrosAvancadosTemp.dataInicial;
    delete filtrosAvancadosTemp.dataFinal;
    const result = await orcamentosService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setOrcamentos(result.data.rows);
      setTotalListaOrcamentos(result.data.rows.length);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosOrcamento = (listaOrcamento) => {
    const idsPessoas = [];
    const idsVendedores = [];
    const idsOperacoesFiscais = [];
    const idsMeiosPagamento = [];
    const idsCondicoesPagamento = [];
    for (const objeto of listaOrcamento) {
      if (objeto.pessoaClienteId) idsPessoas.push(objeto.pessoaClienteId);
      if (objeto.pessoaVendedorId) idsVendedores.push(objeto.pessoaVendedorId);
      if (objeto.operacaoFiscalId)
        idsOperacoesFiscais.push(objeto.operacaoFiscalId);
      if (objeto.meioPagamentoId)
        idsMeiosPagamento.push(objeto.meioPagamentoId);
      if (objeto.condicaoPagamentoId)
        idsCondicoesPagamento.push(objeto.condicaoPagamentoId);
    }
    buscarPessoas([...new Set(idsPessoas)]);
    buscarVendedores([...new Set(idsVendedores)]);
    buscarOperacoesFiscais([...new Set(idsOperacoesFiscais)]);
    buscarMeiosPagamento([...new Set(idsMeiosPagamento)]);
    buscarCondicoesPagamento([...new Set(idsCondicoesPagamento)]);
  };

  const buscarOperacoesFiscais = (listaIds) => {
    const filtro = {
      id: listaIds,
      nonPaginated: true,
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = (listaIds) => {
    if (listaIds?.length) {
      const filtro = {
        id: listaIds,
        nonPaginated: true,
      };
      pessoaService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          const listaPessoas = result.data;
          setListaPessoas(listaPessoas);
        } else {
          responseErros(result);
        }
      });
    } else setListaPessoas([]);
  };

  const buscarVendedores = (listaIds) => {
    const filtro = {
      id: listaIds,
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaVendedores(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = (listaIds) => {
    const filtro = {
      id: listaIds,
      nonPaginated: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaMeiosPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCondicoesPagamento = (listaIds) => {
    const filtro = {
      id: listaIds,
      nonPaginated: true,
    };
    condicoesPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaCondicoesPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarProdutos = async (produtosId) => {
    setLoading(true);
    const filtros = {
      id: produtosId,
    };
    const result = await produtosService.getAllFiltroAvancado(filtros);
    setLoading(false);
    if (!result.isAxiosError) {
      return result.data.rows;
    } else {
      responseErros(result);
    }
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const resetarDadosImportacao = () => {
    setOrcamentoSelecionado([]);
    setActiveStep(0);
  };

  const handleCancelarImportacao = () => {
    resetarDadosImportacao();
    setOpenImportarOrcamentoDialog(false);
  };

  const handleConfirmarImportacao = () => {
    const itensFiltrados = filtrarItens();
    if (!itensFiltrados?.length) {
      return notification.alertaGenericos(
        "Selecione ao menos um produto para importar"
      );
    }
    const seriePedido = listaOperacoesFiscais.find((item) => {
      return item.id === orcamentoSelecionado.operacaoFiscalId;
    })?.serieDocumento;
    const { id, ...orcamento } = orcamentoSelecionado;
    const orcamentoSelecionadoDto = {
      ...orcamento,
      orcamentoId: id,
      serieDocumento: seriePedido,
      itens: itensFiltrados,
      informacaoAdicional:
        orcamentoSelecionado.serieDocumento &&
        orcamentoSelecionado.numeroDocumento
          ? `Importado do orçamento 
      ${orcamentoSelecionado.serieDocumento}/${orcamentoSelecionado.numeroDocumento}`
          : "",
    };
    setPedidoDto(orcamentoSelecionadoDto);
    setOpenImportarOrcamentoDialog(false);
    resetarDadosImportacao();
    history.push("/faturamento/pedidos/create");
  };

  const filtrarOrcamentos = async () => {
    let orcamentoImportacao = null;
    let itensImportacao = [];
    const orcamentosFiltrados = orcamentos.filter((orcamento) => {
      if (selecionados.indexOf(orcamento.id) > -1) {
        if (orcamentoImportacao === null) {
          orcamentoImportacao = {
            ...orcamento,
          };
        }
        for (const key in orcamento) {
          if (
            orcamentoImportacao[key] != orcamento[key] &&
            key != "id" &&
            key != "itens"
          ) {
            orcamento[key] = null;
          }
        }
        itensImportacao.push(...orcamento.itens);
        return orcamento;
      }
    });
    const orcamentoIds = orcamentosFiltrados.map((orcamento) => orcamento.id);
    const produtosId = itensImportacao.map((item) => item.produtoId);
    const produtos = await buscarProdutos(produtosId);
    const itensImportacaoFormatado = itensImportacao
      .filter((produto) => produto.saldoExportar > 0)
      .map((item) => {
        const produto = produtos.find(
          (produto) => produto.id === item.produtoId
        );
        return {
          ...item,
          id: shortid.generate(),
          totalItem: item.saldoExportar * item.valorUnitario,
          descricao: produto?.descricao,
        };
      });
    const selecaoTodosItens = itensImportacaoFormatado.map((item) => item.id);
    setItens(itensImportacaoFormatado);
    setItensQuantidade(itensImportacaoFormatado);
    setItensSelecionados(selecaoTodosItens);
    if (orcamentosFiltrados.length > 1) {
      setOrcamentoSelecionado(() => ({
        ...orcamentosFiltrados[1],
        orcamentoIds,
      }));
    } else {
      setOrcamentoSelecionado(() => ({
        ...orcamentosFiltrados[0],
        orcamentoIds,
      }));
    }
  };

  const filtrarItens = () => {
    if (itensSelecionados) {
      const itensFiltrados = itens.filter((item) => {
        if (itensSelecionados.indexOf(item.id) !== -1) {
          return item;
        }
      });
      return itensFiltrados;
    } else {
      return [];
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      delete props.filtros.status;
      buscarOrcamentos(props.filtros);
    } else {
      buscarOrcamentosAvancado(props.filtros);
    }
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={openImportarOrcamentoDialog}>
      <CardHeader title="Importar Orçamento" className="m-2" />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <OrcamentoStepperContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        handleImportarOrcamento={handleConfirmarImportacao}
        handleCancelarImportacao={handleCancelarImportacao}
        handleConfirmarImportacao={handleConfirmarImportacao}
        handleDateChange={handleDateChange}
        handleInputChange={handleInputChange}
        buscarOrcamentosAvancado={buscarOrcamentosAvancado}
        orcamentos={orcamentos}
        colunas={colunas}
        listaClientes={listaPessoas}
        totalListaOrcamentos={totalListaOrcamentos}
        itensSelecionados={itensSelecionados}
        setItensSelecionados={setItensSelecionados}
        setSelecionados={setSelecionados}
        selecionados={selecionados}
        filtrarOrcamentos={filtrarOrcamentos}
        setItens={setItens}
        itensQuantidade={itensQuantidade}
        itens={itens}
        loading={loading}
      />
    </Dialog>
  );
};

export default ImportarOrcamentoDialog;
