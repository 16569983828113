import { useState, useEffect } from "react";
import { format } from "date-fns";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import {
  history,
  OrdemServicoService,
  ClienteService,
  notification,
} from "../../../services";
import { formatDate, responseErros, formatPrice } from "../../../utils";
import { MenuAction, FormFiltroAvancado } from "./components";
import "./list-ordem-servico.css";
const ordemServicoService = new OrdemServicoService();
const clienteService = new ClienteService();

const ListOrdensServico = () => {
  const [loading, setLoading] = useState(false);
  const [ordensServico, setOrdensServico] = useState([]);
  const [clienteList, setClienteList] = useState([]);
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 300,
    },
    {
      field: "clienteId",
      headerName: "Cliente",
      flex: 300,
      valueGetter: (params) => {
        const cliente = clienteList.find((x) => x.id == params.value);
        return cliente?.pessoa?.nome;
      },
    },
    {
      field: "dataOrdemServico",
      headerName: "Data",
      flex: 300,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "totalVenda",
      headerName: "Valor",
      flex: 300,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "situacaoOperacao",
      headerName: "Situação da Operação",
      flex: 400,
    },
    {
      field: "financeiro",
      headerName: "Financeiro",
      flex: 300,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <div>
            <i
              className={`iconDatagrid ph-fill ph-currency-dollar`}
              style={{ color: "#494C62", fontSize: 18 }}
              onClick={() => {}}
            ></i>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    buscarOrdensServico();
    buscarClientes();
  }, []);

  const buscarOrdensServico = async function (filtro) {
    setLoading(true);
    const result = await ordemServicoService.getAll(filtro);
    if (!result.isAxiosError) {
      setOrdensServico(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarClientes = () => {
    clienteService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const onClickCadastrarOrdemServico = function () {
    history.push("/faturamento/ordens-servico/create");
  };

  const onClickEditOrdemServico = function (id) {
    history.push(`/faturamento/ordens-servico/${id}`);
  };

  const onClickDeleteOrdemServico = async function (id) {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir esse documento de venda?"
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await ordemServicoService.deletar(id);
      if (!result.isAxiosError) {
        buscarOrdensServico();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataOrdemServicoStart &&
      filtrosAvancadosTemp.dataOrdemServicoEnd
    ) {
      porIntervalo.push({
        coluna: "dataOrdemServico",
        de: filtrosAvancadosTemp.dataOrdemServicoStart,
        ate: filtrosAvancadosTemp.dataOrdemServicoEnd,
      });
    }
    if (filtrosAvancadosTemp.totalStart && filtrosAvancadosTemp.totalEnd) {
      porIntervalo.push({
        coluna: "totalVenda",
        de: parseFloat(filtrosAvancadosTemp.totalStart),
        ate: parseFloat(filtrosAvancadosTemp.totalEnd),
      });
    }
    delete filtrosAvancadosTemp.dataOrdemServicoStart;
    delete filtrosAvancadosTemp.dataOrdemServicoEnd;
    delete filtrosAvancadosTemp.totalStart;
    delete filtrosAvancadosTemp.totalEnd;
    const result = await ordemServicoService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setOrdensServico(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarOrdensServico(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Ordens de Serviço"
      loading={loading}
      onClickCadastrar={onClickCadastrarOrdemServico}
      ButtonMenu={<MenuAction />}
    >
      <DataGrid
        rows={ordensServico}
        columns={colunas}
        onClickEdit={onClickEditOrdemServico}
        onClickDelete={onClickDeleteOrdemServico}
        paginationMode={"server"}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListOrdensServico;
