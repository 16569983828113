import { Tooltip as TooltipMui } from "@mui/material";

const Tooltip = ({
  id,
  children,
  title,
  arrow,
  placement,
  enterDelay,
  leaveDelay,
  classes,
  className,
  disableHoverListener,
  disableTouchListener,
  followCursor,
  onClose,
  open,
  PopperProps,
  TransitionComponent,
  TransitionProps,
  ...rest
}) => {
  return (
    <TooltipMui
      id={id}
      title={title}
      arrow={arrow}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      classes={classes}
      className={className}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      followCursor={followCursor}
      onClose={onClose}
      open={open}
      PopperProps={PopperProps}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
      {...rest}
    >
      <div>{children}</div>
    </TooltipMui>
  );
};

export default Tooltip;
