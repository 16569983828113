import SiafLiteApiRepository from "../../external/siafLiteApi";

class ColaboradorSevicoService {
  constructor() {
    this.urlBase = "/integracao/colaboradores";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }
}

export default ColaboradorSevicoService;
