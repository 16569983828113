import { useState } from "react";
import { Minimal } from "..";
import {
  CardHeader,
  ListItemButton,
  ListItemText,
  Loading,
} from "../../components";
import { FiltroAvancadoDialog, VisualizarRelatorioDialog } from "./components";
import shortid from "shortid";
import { formatDate } from "../../utils";
import "./relatorios.style.scss";

const RelatoriosLayout = ({
  subgruposRelatorios,
  loading,
  filtrosAplicadosImpressao,
  dadosRelatorioResumido,
  setFiltrosAplicadosImpressao,
  filtrosAvancados,
  setFiltrosAvancados,
}) => {
  const [propriedadeRelatorio, setPropriedadeRelatorio] = useState({});
  const [openFiltroAvancadoDialog, setOpenFiltroAvancadoDialog] =
    useState(false);
  const [abrirDialogoVisualizarRelatorio, setAbrirDialogoVisualizarRelatorio] =
    useState(false);

  const onClickOpenFiltroAvancadoDialog = (propriedade) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      dataInicial: formatDate.toSend(new Date()),
      dataFinal: formatDate.toSend(new Date()),
      dataReferencia: formatDate.toSend(new Date()),
    });
    setPropriedadeRelatorio(propriedade);
    setOpenFiltroAvancadoDialog(true);
  };

  return (
    <Minimal>
      {subgruposRelatorios.map((subgrupo) => (
        <div key={shortid.generate()}>
          <CardHeader title={subgrupo.subgrupo} />
          {subgrupo.propriedades
            .filter((propriedade) => propriedade.permissao)
            .map((propriedade) => (
              <ListItemButton
                key={shortid.generate()}
                className="shadow-card-light"
                onClick={() => onClickOpenFiltroAvancadoDialog(propriedade)}
              >
                <ListItemText primary={propriedade.name} />
                <i className="ph-fill ph-funnel" style={{ fontSize: 18 }}></i>
              </ListItemButton>
            ))}
        </div>
      ))}
      <Loading loading={loading} />
      <FiltroAvancadoDialog
        filtrosAvancados={filtrosAvancados}
        setOpenFiltroAvancadoDialog={setOpenFiltroAvancadoDialog}
        openFiltroAvancadoDialog={openFiltroAvancadoDialog}
        propriedadeRelatorio={propriedadeRelatorio}
        setAbrirDialogoVisualizarRelatorio={setAbrirDialogoVisualizarRelatorio}
        relatoriosTotais={dadosRelatorioResumido}
      >
        <propriedadeRelatorio.formulario
          filtrosAvancados={filtrosAvancados}
          setFiltrosAvancados={setFiltrosAvancados}
          filtrosAplicadosImpressao={filtrosAplicadosImpressao}
          setFiltrosAplicadosImpressao={setFiltrosAplicadosImpressao}
        />
      </FiltroAvancadoDialog>
      <VisualizarRelatorioDialog
        setAbrirDialogoVisualizarRelatorio={setAbrirDialogoVisualizarRelatorio}
        abrirDialogoVisualizarRelatorio={abrirDialogoVisualizarRelatorio}
        propriedadeRelatorio={propriedadeRelatorio}
        filtrosAplicadosImpressao={filtrosAplicadosImpressao}
        dadosRelatorioResumido={dadosRelatorioResumido}
        setOpenFiltroAvancadoDialog={setOpenFiltroAvancadoDialog}
      />
    </Minimal>
  );
};

export default RelatoriosLayout;
