import {
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
} from "../../../components";
import { notification } from "../../../services";

const FiltroAvancadoDialog = ({
  children,
  filtrosAvancados,
  setOpenFiltroAvancadoDialog,
  openFiltroAvancadoDialog,
  propriedadeRelatorio,
  setAbrirDialogoVisualizarRelatorio,
}) => {
  const onClickVisualizarRelatorio = async () => {
    setOpenFiltroAvancadoDialog(false);
    const { data } = await propriedadeRelatorio.funcaoBuscarDadosRelatorio(
      filtrosAvancados
    );
    if (!verificaDados(data)) {
      return notification.alertaGenericos(
        "Dados não encontrados para o período selecionado."
      );
    }
    setAbrirDialogoVisualizarRelatorio(true);
  };

  function verificaDados(dados) {
    if (dados == null || dados == undefined) {
      return false;
    }
    const temValores = Object.values(dados).some((valor) => {
      if (typeof valor === "object") {
        return verificaDados(valor);
      } else if (Array.isArray(valor) && valor?.length) {
        return true;
      } else {
        return valor != null && valor != undefined;
      }
    });
    return temValores;
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openFiltroAvancadoDialog}
      onClose={() => setOpenFiltroAvancadoDialog(false)}
    >
      <DialogContent>
        <CardHeader
          id="cabecalhoFiltroRelatorio"
          title={`Filtro - ${propriedadeRelatorio?.name?.split("-")[1]}`}
        />
        <CardContent>{children}</CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClickVisualizarRelatorio()}
          >
            Visualizar Relatório
          </Button>
          <Button
            variant="contained"
            color="padrao"
            onClick={() => setOpenFiltroAvancadoDialog(false)}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </DialogContent>
    </Dialog>
  );
};

export default FiltroAvancadoDialog;
