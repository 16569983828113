import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Grid,
  Dialog,
  CardHeader,
  Card,
  CardContent,
  Autocomplete,
  DialogActions,
  Button,
} from "../../../../components";
import { CfopService, NcmService } from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";
import { value } from "../../../../helpers";

const ncmService = new NcmService();
const cfopService = new CfopService();

const EditarProdutoDialog = ({
  produtos,
  setProdutos,
  produtoSelecionado,
  setProdutoSelecionado,
  gruposProdutos,
  gruposTributarios,
  listaUnidadesComerciais,
  openEditarProdutoDialog,
  setOpenEditarProdutoDialog,
  inputErros,
}) => {
  const [inputRefCfop, inputRefNcm] = [useRef(), useRef()];
  const [listaNcm, setListaNcm] = useState([]);
  const [listaCfop, setListaCfop] = useState([]);

  useEffect(() => {
    if (openEditarProdutoDialog) {
      buscarCfops(produtoSelecionado?.cfop);
      buscarNcms(produtoSelecionado?.ncm);
    }
  }, [produtoSelecionado]);

  const buscarNcms = async (codigo) => {
    const filtros = {
      codigo,
      nonPaginated: true,
    };
    if (codigo?.length) {
      const result = await ncmService.getAllAvancado(filtros);
      if (!result.isAxiosError) {
        setListaNcm(result.data);
      } else {
        responseErros(result);
      }
    }
  };

  const buscarCfops = async (codigo) => {
    const filtros = {
      codigo,
      nonPaginated: true,
    };
    if (codigo?.length) {
      const result = await cfopService.getAllAvancado(filtros);
      if (!result.isAxiosError) {
        setListaCfop(result.data);
      } else {
        responseErros(result);
      }
    }
  };

  const handleEditarProduto = (event) => {
    event.preventDefault();
    const index = produtos?.findIndex(
      (item) => item.id === produtoSelecionado.id
    );
    const produtosXmlTemp = produtos.map((item, _index) => {
      if (_index === index) {
        return {
          ...item,
          ...produtoSelecionado,
        };
      } else {
        return item;
      }
    });
    inputErros.limpar();
    setProdutos(produtosXmlTemp);
    handleInitEdicao();
  };

  const handleChangeCfop = (value) => {
    setProdutoSelecionado((prevState) => ({
      ...prevState,
      cfopId: value?.id,
      cfop: value?.codigo,
    }));
  };

  const handleChangeNcm = (value) => {
    setProdutoSelecionado((prevState) => ({
      ...prevState,
      ncmId: value?.id,
      ncm: value?.codigo,
      ncmCodigo: value?.codigo,
    }));
  };

  const onInputChangeCfop = (event) => {
    if (
      event.key === "Enter" &&
      inputRefCfop?.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        timeoutBuscaAutocomplete(buscarCfops(eventValue));
      }
    }
  };

  const onInputChangeNcm = (event) => {
    if (
      event.key === "Enter" &&
      inputRefNcm?.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        timeoutBuscaAutocomplete(buscarNcms(eventValue));
      }
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    setProdutoSelecionado({
      ...produtoSelecionado,
      [name]: value?.id,
    });
  };

  const handleChangeBarra = (event) => {
    setProdutoSelecionado({
      ...produtoSelecionado,
      [event.target.name]: event.target.value
        ? event.target.value.replace(/[^\w]/g, "")
        : null,
    });
  };

  const handleInitEdicao = () => {
    setProdutoSelecionado({});
    setListaNcm([]);
    setListaCfop([]);
    setOpenEditarProdutoDialog(false);
  };

  return (
    <Dialog
      open={openEditarProdutoDialog}
      disableEscapeKeyDown
      disableClickEventBubbling
      fullWidth
      maxWidth="md"
    >
      <Card>
        <CardHeader title="Editar Produto" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                id="codigoBarra"
                name="codigoBarra"
                label="Código Barra"
                variant="outlined"
                value={value.text(produtoSelecionado?.codigoBarra)}
                onChange={handleChangeBarra}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="cfopId"
                name="cfopId"
                options={listaCfop}
                autoHighlight
                loadingText="Carregando"
                noOptionsText="Digite e pressione Enter"
                getOptionLabel={(option) => option?.descricao?.replace(".", "")}
                filterSelectedOptions
                onChange={(_, newValue) => handleChangeCfop(newValue)}
                value={listaCfop.find(
                  (cfop) => cfop?.codigo === produtoSelecionado?.cfop
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="CFOP"
                    onKeyDown={onInputChangeCfop}
                    inputRef={inputRefCfop}
                    error={inputErros.get("cfopId") || inputErros.get("cfop")}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                id="ncmId"
                name="ncmId"
                options={listaNcm}
                autoHighlight
                loadingText="Carregando"
                noOptionsText="Digite e pressione Enter"
                getOptionLabel={(option) => option?.codigo}
                filterSelectedOptions
                onChange={(_, newValue) => handleChangeNcm(newValue)}
                value={listaNcm.find(
                  (ncm) => ncm?.codigo === produtoSelecionado?.ncm
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="NCM"
                    onKeyDown={onInputChangeNcm}
                    inputRef={inputRefNcm}
                    error={inputErros.get("ncmId") || inputErros.get("ncm")}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mt-2">
            <Grid item xs={4}>
              <Autocomplete
                id="grupoProdutoId"
                name="grupoProdutoId"
                options={gruposProdutos}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) =>
                  option?.descricao ? option.descricao : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grupo de Produto"
                    error={inputErros.get("grupoProdutoId")}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => {
                  handleChangeAutocomplete("grupoProdutoId", value);
                }}
                value={value.autoComplete(
                  gruposProdutos,
                  produtoSelecionado?.grupoProdutoId
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="grupoTributacaoId"
                name="grupoTributacaoId"
                options={gruposTributarios}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option?.nome ? option.nome : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grupo Tributário"
                    error={inputErros.get("grupoTributacaoId")}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) => {
                  handleChangeAutocomplete("grupoTributacaoId", value);
                }}
                value={value.autoComplete(
                  gruposTributarios,
                  produtoSelecionado?.grupoTributacaoId
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="unidadeComercialVendaId"
                name="unidadeComercialVendaId"
                options={listaUnidadesComerciais}
                noOptionsText="Sem opções"
                autoHighlight
                disableClearable
                getOptionLabel={(option) =>
                  option?.descricao ? option.descricao : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unid. Venda"
                    error={inputErros.get("unidadeComercialVendaId")}
                    variant="outlined"
                  />
                )}
                onChange={(_, value) =>
                  handleChangeAutocomplete("unidadeComercialVendaId", value)
                }
                value={value.autoComplete(
                  listaUnidadesComerciais,
                  produtoSelecionado?.unidadeComercialVendaId
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogActions>
        <Button variant="contained" onClick={handleInitEdicao}>
          Fechar
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleEditarProduto}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditarProdutoDialog;
