import { Tab as TabMui } from "@mui/material";

const Tab = ({
  children,
  classes,
  disabled,
  disableFocusRipple,
  disableRipple,
  icon,
  iconPosition,
  label,
  sx,
  id,
  className,
  value,
  wrapped,
  ref,
  ...rest
}) => {
  return (
    <TabMui
      id={id}
      className={className}
      classes={classes}
      disabled={disabled ? true : false}
      disableFocusRipple={disableFocusRipple ? true : false}
      disableRipple={disableRipple ? true : false}
      icon={icon}
      iconPosition={iconPosition}
      label={label}
      sx={sx}
      value={value}
      wrapped={wrapped ? true : false}
      ref={ref}
      {...rest}
    >
      {children}
    </TabMui>
  );
};

export default Tab;
