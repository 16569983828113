import { notification } from "../services";
const UNAUTHORIZED = 401;
const NOTFOUND = 404;
const BAD_REQUEST = 400;

const responseErros = (res) => {
  if (
    res.response?.status != UNAUTHORIZED &&
    res.response?.status != NOTFOUND
  ) {
    if (res.response?.status == BAD_REQUEST) {
      notification.alertaGenericos(res.response?.data?.message);
    } else {
      notification.erroInternoServidor();
    }
  }
};

export default responseErros;
