import SiafLiteApiRepository from "../../external/siafLiteApi";

class TiposPessoasService {
  constructor() {
    this.urlBase = "/integracao/tipos-pessoas";
  }

  getAll() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }
}

export default TiposPessoasService;
