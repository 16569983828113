import createAxiosConfig from "../config/createAxiosConfig";
import store from "../config/store";
import { StorageHelper } from "../helpers";
import SiafLiteNfeBaseRepository from "./siaf-lite-nfe-base";
const headersCertificado = {
  "Content-Type": "multipart/form-data",
};
const axiosConfig = createAxiosConfig(store.baseUrlNfe, headersCertificado);
const storageHelper = new StorageHelper();

class SiafLiteNfeRepository extends SiafLiteNfeBaseRepository {
  constructor(url) {
    super(url);
  }

  async postCertificado(body) {
    await gerarAuthorization();
    return axiosConfig
      .post(this.url, body)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        return erro;
      });
  }
}

async function gerarAuthorization() {
  axiosConfig.interceptors.request.use(
    async (config) => {
      const localStorageToken = storageHelper.getLocal("token");
      if (localStorageToken) {
        config.headers.Authorization = `Bearer ${localStorageToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
}

export default SiafLiteNfeRepository;
