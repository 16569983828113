import SiafLiteApiRepository from "../../external/siafLiteApi";

class ContasPagarPagamentosService {
  constructor() {
    this.urlBase = "/financeiro/contas-pagar-pagamentos";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  removerPagamentos(id, body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/remover-pagamentos-contas-pagar`
    ).post(body);
  }
}

export default ContasPagarPagamentosService;
