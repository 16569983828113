import SiafLiteApiRepository from "../../external/siafLiteApi";

class ModulosService {
  constructor() {
    this.urlBase = "/painel-controle/modulos";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }
}

export default ModulosService;
