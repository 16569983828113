import {
  Button,
  CardHeader,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  TextFielDecimalNumbers,
} from "../../../../../components";
import { formatPrice } from "../../../../../utils";

const ImpostoProdutoNfEntradaDialog = ({ open, setOpen, produto }) => {
  function onClose() {
    setOpen(false);
  }

  return (
    <Dialog fullWidth maxWidth={"md"} open={open}>
      <CardHeader title="Visualizar Impostos" className="m-2" />
      <DialogContent>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextFielDecimalNumbers
                id="tributosIcmsAliquota"
                name="tributosIcmsAliquota"
                label="Aliquota de ICMS"
                variant="outlined"
                margin="none"
                disabled={true}
                value={produto?.tributosIcmsAliquota || "0"}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="tributosValorIcmsAliquota"
                name="tributosValorIcmsAliquota"
                label="Valor de ICMS"
                variant="outlined"
                disabled={true}
                value={formatPrice(produto?.tributosValorIcmsAliquota || 0)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextFielDecimalNumbers
                id="impostoIcmsStAliquota"
                name="impostoIcmsStAliquota"
                label="Alíquota de ICMS_ST"
                variant="outlined"
                disabled={true}
                margin="none"
                value={produto?.impostoIcmsStAliquota || "0"}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="impostoValorIcmsStAliquota"
                name="impostoValorIcmsStAliquota"
                label="Valor de ICMS_ST"
                variant="outlined"
                disabled={true}
                value={formatPrice(produto?.impostoValorIcmsStAliquota || 0)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextFielDecimalNumbers
                id="tributosIpiAliquota"
                name="tributosIpiAliquota"
                label="Alíquota de IPI"
                variant="outlined"
                disabled={true}
                margin="none"
                value={produto?.tributosIpiAliquota || "0"}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="tributosValorIpiAliquota"
                name="tributosValorIpiAliquota"
                label="Valor de IPI"
                variant="outlined"
                disabled={true}
                value={formatPrice(produto?.tributosValorIpiAliquota || 0)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="valorFrete"
                name="valorFrete"
                label="Valor de Frete"
                variant="outlined"
                disabled={true}
                value={formatPrice(produto?.valorFrete || 0)}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className="m-3">
        <Button onClick={onClose} color="primary" variant="contained">
          Sair
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImpostoProdutoNfEntradaDialog;
