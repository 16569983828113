import {
  Grid,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Autocomplete,
  MaskedInput,
} from "../../../../components";
import { value } from "../../../../helpers";

const CadastroEndereco = ({
  empresa,
  estados,
  cidades,
  ufid,
  inputErros,
  handleInputChange,
  handleCompletarEndereco,
  handleEstadoUfInput,
  handleAutocompleteInput,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <form onSubmit={(e) => handleCompletarEndereco(e)}>
            <MaskedInput
              id="cep"
              name="enderecoCep"
              label="CEP"
              mask="99999-999"
              value={empresa?.enderecoCep ? empresa.enderecoCep : ""}
              onChange={(e) => {
                handleInputChange(e);
              }}
              required
              error={inputErros.get("enderecoCep")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Pesquisar CEP"
                      onClick={handleCompletarEndereco}
                    >
                      <i
                        className="ph-bold ph-magnifying-glass"
                        style={{ color: "#494C62", fontSize: 18 }}
                      ></i>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="logradouro"
            name="enderecoLogradouro"
            label="Logradouro"
            variant="outlined"
            margin="normal"
            value={
              empresa?.enderecoLogradouro ? empresa.enderecoLogradouro : ""
            }
            onChange={(e) => {
              handleInputChange(e);
            }}
            fullWidth
            required
            error={inputErros.get("enderecoLogradouro")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="numero"
            name="enderecoLogradouroNumero"
            label="Número"
            variant="outlined"
            margin="normal"
            value={
              empresa?.enderecoLogradouroNumero
                ? empresa.enderecoLogradouroNumero
                : ""
            }
            onChange={(e) => {
              handleInputChange(e);
            }}
            fullWidth
            required
            error={inputErros.get("enderecoLogradouroNumero")}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="bairro"
            name="enderecoBairro"
            label="Bairro"
            variant="outlined"
            margin="normal"
            value={empresa?.enderecoBairro ? empresa.enderecoBairro : ""}
            onChange={(e) => {
              handleInputChange(e);
            }}
            fullWidth
            required
            error={inputErros.get("enderecoBairro")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="complemento"
            name="enderecoComplemento"
            label="Complemento"
            variant="outlined"
            margin="normal"
            value={
              empresa?.enderecoComplemento ? empresa.enderecoComplemento : ""
            }
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="estado"
              options={estados}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option.sigla && option.nome
                  ? `${option.sigla} - ${option.nome}`
                  : ""
              }
              renderInput={(params) => (
                <TextField {...params} label="UF*" variant="outlined" />
              )}
              onChange={(_event, { id }) => {
                handleEstadoUfInput(id);
              }}
              value={estados ? value.autoComplete(estados, ufid) : ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="cidadeId"
              name="cidadeId"
              options={cidades}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cidade"
                  variant="outlined"
                  required
                />
              )}
              onChange={(_event, newValue) => {
                handleAutocompleteInput("cidadeId", newValue);
              }}
              value={
                cidades ? value.autoComplete(cidades, empresa.cidadeId) : ""
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default CadastroEndereco;
