export class CondicaoPagamento {
  ativado = true;
  entrada = false;
  carencia = null;
  descricao = null;
  juros = null;
  parcelas = 1;
  periodo = null;
  meiosPagamentos = [];

  constructor(dadosCondicao) {
    this.ativado = dadosCondicao.ativado;
    this.entrada = dadosCondicao.entrada;
    this.carencia = dadosCondicao.carencia;
    this.descricao = dadosCondicao.descricao;
    this.juros = dadosCondicao.juros;
    this.parcelas = dadosCondicao.parcelas;
    this.periodo = dadosCondicao.periodo;
    this.meiosPagamentos = dadosCondicao.meiosPagamentos;
  }
}
