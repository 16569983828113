import * as Yup from "yup";

const orcamentoValidator = Yup.object().shape({
  operacaoFiscalId: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required("Operação Fiscal"),
  pessoaClienteId: Yup.number().nullable(),
  pessoaVendedorId: Yup.number().required("Vendedor"),
  pessoaProficionalId: Yup.number().nullable(),
  condicaoPagamentoId: Yup.number().required("Condição de Pagamento"),
  meioPagamentoId: Yup.number().required("Meio de Pagamento"),
  informacaoAdicional: Yup.string().nullable(),
  desconto: Yup.number().nullable(),
  acrescimo: Yup.number().nullable(),
  despesas: Yup.number().nullable(),
  orcamentoItens: Yup.array().min(1, "incluir ao menos 1 produto"),
  orcamentoEnderecoCliente: Yup.object().nullable(),
});

export default orcamentoValidator;
