import { history } from "../services";

export const filtrosUrl = {
  set: (filtros) => {
    const url = getUrl(filtros);
    history.replace(url);
  },
  get: () => {
    return Object.fromEntries(new URLSearchParams(window.location.search));
  },
  getUrl: (filtros, urlRequest) => {
    return getUrl(filtros, urlRequest);
  },
};

function getUrl(filtros, urlRequest) {
  const params = new URLSearchParams(filtros).toString();
  const baseUrl = urlRequest || window.location.pathname;
  return params ? `${baseUrl}?${params}` : baseUrl;
}
