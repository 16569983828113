import {
  Grid,
  Button,
  CardHeader,
  DataGrid,
  Dialog,
  DialogContent,
  DialogActions,
} from "../../../../../components";
import { useEffect } from "react";

const ProdutoSimilarDialog = ({
  produto,
  openProdutoSimilarDialog,
  setOpenProdutoSimilarDialog,
  produtosColunasSimilares,
  buscarProdutosSimilares,
  produtosSimilares,
}) => {
  useEffect(() => {
    buscarProdutosSimilares();
  }, []);

  return (
    <Dialog fullWidth maxWidth={"md"} open={openProdutoSimilarDialog}>
      <CardHeader title="Produto Similar" className="m-2" />
      <DialogContent>
        <Grid item xs={12} className="mt-1 mb-3">
          <h4 style={{ textTransform: "uppercase" }}>
            produto: {produto?.descricao}
          </h4>
        </Grid>
        <DataGrid
          rows={produtosSimilares}
          columns={produtosColunasSimilares}
          rowCount={produtosSimilares.length}
          disableAcoes
        />
      </DialogContent>
      <DialogActions className="m-3">
        <Button
          onClick={() => setOpenProdutoSimilarDialog(false)}
          color="primary"
          variant="contained"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProdutoSimilarDialog;
