export class CadastrarFornecedorXml {
  constructor(fornecedor) {
    this.nomeRazaoSocial = fornecedor.xNome;
    this.apelidoFantasia = fornecedor.xFant;
    this.naturezaSocial = "juridica";
    this.cnpjCpf = fornecedor.CNPJ;
    this.inscricaoEstadual = fornecedor.IE;
    this.enderecos = [new CadastrarEnderecoFornecedorXml(fornecedor.enderEmit)];
    this.idTiposPessoa = [2];
    this.fornecedor = { gruposPessoaIds: [] };
  }
}

class CadastrarEnderecoFornecedorXml {
  constructor(fornecedorEndereco) {
    this.cep = fornecedorEndereco.CEP;
    this.logradouro = fornecedorEndereco.xLgr;
    this.numero = fornecedorEndereco.nro;
    this.bairro = fornecedorEndereco.xBairro;
    this.cidadeId = fornecedorEndereco.cMun;
    this.descricao = "";
  }
}
