import { parcelaData } from "../../nf-saida/add-nf-saida/add-nf-saida";
import { getAtrasoDias } from "../addContaPagar/add-conta-pagar";

export function calculaMultaJurosBaixa(
  contaPagar,
  renegociacaoTitulos,
  dataPagamento = new Date()
) {
  let [multa, juros, subtotal] = [0, 0, 0];
  const atraso = getAtrasoDias(contaPagar?.dataVencimento, dataPagamento);
  subtotal = contaPagar?.saldoPagar ?? 0;
  if (atraso <= renegociacaoTitulos?.carencia) {
    return subtotal;
  }
  multa =
    atraso > 0 && renegociacaoTitulos?.multaPorcentagem
      ? contaPagar?.saldoPagar * renegociacaoTitulos.multaPorcentagem
      : 0;
  juros =
    atraso > 0 && renegociacaoTitulos?.jurosPorcentagem
      ? contaPagar?.saldoPagar * renegociacaoTitulos.jurosPorcentagem * atraso
      : 0;
  return multa + juros + subtotal;
}

export function calculaTotalAPagar(listaContasPagar, dataPagamento, baixaLote) {
  let [multa, juros, subtotal] = [0, 0, 0];
  for (const contaPagar of listaContasPagar) {
    const atraso = getAtrasoDias(contaPagar?.dataVencimento, dataPagamento);
    subtotal += contaPagar?.saldoPagar ?? 0;
    if (atraso > baixaLote?.carencia) {
      multa +=
        atraso > 0 && baixaLote?.multaPorcentagem
          ? contaPagar?.saldoPagar * baixaLote.multaPorcentagem
          : 0;
      juros +=
        atraso > 0 && baixaLote?.jurosPorcentagem
          ? contaPagar?.saldoPagar * baixaLote.jurosPorcentagem * atraso
          : 0;
    }
  }
  const pagar = subtotal + multa + juros;
  return { multa, juros, subtotal, pagar };
}

export const montaListaContasABaixar = (
  listaContasPagar,
  meioPagamento,
  baixaLote,
  renegociacao = false
) => {
  const contasPagar = listaContasPagar.map((conta) => {
    const total = calculaMultaJurosBaixa(
      conta,
      baixaLote,
      baixaLote?.dataPagamento ?? new Date().toISOString()
    );
    const pagamento = {
      recebedorId: conta?.pessoaId,
      contasPagarId: conta?.id,
      dataPagamento: baixaLote?.dataPagamento ?? new Date().toISOString(),
      bancoCaixaId: baixaLote?.bancoCaixaId ?? meioPagamento?.bancoCaixaId,
      meioPagamento,
      tipoBaixa: renegociacao ? "BAIXA_RENEGOCIACAO" : "BAIXA_NORMAL",
      meioPagamentoId: meioPagamento.id,
      valorTitulo: total,
      valor: total,
    };
    return {
      ...conta,
      status: "BAIXADO",
      pagamentos: [...conta.pagamentos, pagamento],
    };
  });
  return contasPagar;
};

export const montaListaRenegociacoes = (
  listaContasPagar,
  renegociacaoTitulos,
  meioPagamento,
  condicaoPagamento,
  totalAPagar,
  insertUserId
) => {
  const titulosRecebedores = listaContasPagar.reduce(
    (acc, currentItem) => {
      acc[0].push(currentItem?.numeroTitulo);
      acc[1].push(currentItem?.pessoaId);
      acc[2].push(currentItem?.id);
      return acc;
    },
    [[], [], []]
  );
  const bodyMask = {
    status: "ABERTO",
    origem: "RENEGOCIAÇÃO",
    pessoaId: listaContasPagar[0]?.pessoaId,
    bancoCaixaId: meioPagamento?.bancoCaixaId,
    dataLancamento: new Date().toISOString(),
    meioPagamento,
    meioPagamentoId: meioPagamento?.id,
    pagamentos: [],
    observacaoList: [
      {
        observacao: `DUPLICATA PROVENIENTE DE RENEGOCIAÇÃO: ${titulosRecebedores[0]}`,
        createdAt: new Date().toISOString(),
        insertUserId,
      },
    ],
  };
  const listaRenegociacoesTemp = [];
  for (let i = 1; i <= renegociacaoTitulos.quantidadeParcelas; i++) {
    const valor = totalAPagar.pagar / renegociacaoTitulos.quantidadeParcelas;
    const valorFormatado = Math.floor(valor * 100) / 100;
    const restoDivisao =
      totalAPagar.pagar -
      valorFormatado * renegociacaoTitulos.quantidadeParcelas;
    const calculoValorConta =
      i === renegociacaoTitulos.quantidadeParcelas
        ? valorFormatado + parseFloat(restoDivisao.toFixed(2))
        : valorFormatado;
    const body = {
      ...bodyMask,
      dataVencimento: parcelaData(
        i,
        renegociacaoTitulos.dataVencimento,
        condicaoPagamento?.periodo
      ),
      contasId: titulosRecebedores[2],
      valor: calculoValorConta,
      valorTotal: calculoValorConta,
      saldoPagar: calculoValorConta,
    };
    listaRenegociacoesTemp.push(body);
  }
  return {
    recebedores: titulosRecebedores[1],
    lista: listaRenegociacoesTemp,
  };
};
