import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import {
  history,
  CondicoesPagamentoService,
  notification,
} from "../../../services";
import { DataGrid } from "../../../components";
import { cacheApi, responseErros } from "../../../utils";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";
import { FormFiltroAvancado } from "./components";
import { useCacheContext } from "../../../contexts/cache.context";

const ListCondicoesPagamento = () => {
  const condicaoPagamentoService = new CondicoesPagamentoService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("condicoes-pagamento-visualizar");
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [condicoesPagamento, setCondicoesPagamento] = useState([]);
  const { cacheDto, setCacheDto } = useCacheContext();

  const colunas = [
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 700,
      filtrar: true,
    },
    {
      field: "ativado",
      headerName: "Status",
      flex: 500,
      valueGetter: (params) =>
        params.value == true ? "Ativado" : "Desativado",
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarCondicoesPagamento(filtros);
  }, []);

  const buscarCondicoesPagamento = async function (filter) {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: condicaoPagamentoService.getAll(filter),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setCondicoesPagamento(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarCondicaoPagamento = function () {
    history.push("/faturamento/condicoes-pagamento/create");
  };

  const onClickEditCondicaoPagamento = function (id) {
    history.push(`/faturamento/condicoes-pagamento/${id}`);
  };

  const onClickDeleteCondicaoPagamento = async function (id) {
    const data = condicoesPagamento.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.descricao}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await condicaoPagamentoService.deletar(id);
      if (!result.isAxiosError) {
        buscarCondicoesPagamento();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const label = filtrosUrl.getUrl(filter);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: condicaoPagamentoService.getAllFiltroAvancado({
        ...filtrosAvancadosTemp,
        restritiva: true,
        porIntervalo,
      }),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setCondicoesPagamento(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarCondicoesPagamento(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Condições de Pagamento"
      loading={loading}
      onClickCadastrar={onClickCadastrarCondicaoPagamento}
      permissaoButton={permissoesHelper.temPermisao(
        "condicoes-pagamento-cadastrar"
      )}
    >
      <DataGrid
        rows={condicoesPagamento}
        columns={colunas}
        onClickEdit={onClickEditCondicaoPagamento}
        onClickDelete={onClickDeleteCondicaoPagamento}
        paginationMode="server"
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
        visualizarShow={permissoesHelper.temPermisao(
          "condicoes-pagamento-visualizar"
        )}
        editShow={permissoesHelper.temPermisao("condicoes-pagamento-editar")}
        deleteShow={permissoesHelper.temPermisao("condicoes-pagamento-excluir")}
      />
    </ListLayout>
  );
};

export default ListCondicoesPagamento;
