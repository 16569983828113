import SiafLiteApiRepository from "../../external/siafLiteApi";

class CadastrosService {
  constructor() {
    this.urlBase = "/cadastros";
  }

  cadastrarEmpresa(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/empresas`).post(body);
  }

  cadastrarContador(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/contadores`).post(body);
  }

  validarEmpresaCadastrada(cnpjCpf) {
    return new SiafLiteApiRepository(`${this.urlBase}/validar-cnpj-cpf`).patch({
      cnpjCpf,
    });
  }

  validarContadorCadastrado(cnpjCpf) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/validar-contador-cadastrado`
    ).patch({
      cnpjCpf,
    });
  }

  validarUsuarioCadastrado(emailUsuario) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/validar-email-usuario`
    ).patch({
      emailUsuario,
    });
  }

  getCidades(ufid) {
    return new SiafLiteApiRepository(`${this.urlBase}/cidades/${ufid}`).get();
  }
}

export default CadastrosService;
