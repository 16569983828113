import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import { ProdutosService, history, notification } from "../../../services";
import { responseErros, formatPrice } from "../../../utils";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";
import { FormFiltroAvancado } from "./components";

const ListProdutos = () => {
  const [produtos, setProdutos] = useState([]);
  const [totalListaProdutos, setTotalListaProdutos] = useState(0);
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 150,
      valueGetter: (params) => Number(params.value),
    },
    {
      field: "codigoBarra",
      headerName: "Barra",
      sortable: false,
      flex: 250,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 750,
      filtrar: true,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      sortable: false,
      flex: 220,
    },
    {
      field: "precoVenda",
      headerName: "Preço Venda",
      sortable: false,
      flex: 350,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "estoque",
      headerName: "Estoque",
      sortable: false,
      flex: 270,
    },
  ];
  const produtosService = new ProdutosService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("produtos-visualizar");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarProdutos(filtros);
  }, []);

  const buscarProdutos = async (query) => {
    const filtros = {
      ...query,
      produtoGrade: true,
    };
    setLoading(true);
    const res = await produtosService.getAll(filtros);
    if (!res.isAxiosError) {
      setTotalListaProdutos(res.data.count);
      setProdutos(res.data.rows);
      filtrosUrl.set(filtros);
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const onClickCadastrarProduto = function () {
    history.push("/estoque/produtos/create");
  };

  const onClickEditProduto = function (id) {
    history.push(`/estoque/produtos/${id}`);
  };

  const onClickDeleteProduto = async function (id) {
    const produto = produtos.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Desativar!",
      `Tem certeza que deseja desativar o produto '${produto?.descricao}'?`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await produtosService.desativar(id);
      if (!result.isAxiosError) {
        buscarProdutos();
        notification.sucessoGenericos("Produto desativado com sucesso.");
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.precoVendaStart &&
      filtrosAvancadosTemp.precoVendaEnd
    ) {
      porIntervalo.push({
        coluna: "preco_venda",
        de: parseFloat(filtrosAvancadosTemp.precoVendaStart),
        ate: parseFloat(filtrosAvancadosTemp.precoVendaEnd),
      });
    }
    delete filtrosAvancadosTemp.estoqueStart;
    delete filtrosAvancadosTemp.estoqueEnd;
    delete filtrosAvancadosTemp.precoVendaStart;
    delete filtrosAvancadosTemp.precoVendaEnd;
    for (const key in filtrosAvancadosTemp) {
      if (filtrosAvancadosTemp[key] == null) {
        delete filtrosAvancadosTemp[key];
      }
    }
    const filters = {
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
      produtoGrade: true,
    };
    const result = await produtosService.getAllFiltroAvancado(filters);
    if (!result.isAxiosError) {
      setTotalListaProdutos(result.data.count);
      setProdutos(result.data.rows);
      filtrosUrl.set(filters);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarProdutos(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Produtos"
      loading={loading}
      onClickCadastrar={onClickCadastrarProduto}
      permissaoButton={permissoesHelper.temPermisao("produtos-cadastrar")}
    >
      <DataGrid
        rows={produtos}
        columns={colunas}
        rowCount={totalListaProdutos}
        onClickEdit={onClickEditProduto}
        onClickDelete={onClickDeleteProduto}
        paginationMode="server"
        sendServer={sendServerDatagrid}
        FormFiltroAvancado={FormFiltroAvancado}
        editShow={permissoesHelper.temPermisao("produtos-editar")}
        visualizarShow={permissoesHelper.temPermisao("produtos-visualizar")}
        deleteShow={permissoesHelper.temPermisao("produtos-excluir")}
      />
    </ListLayout>
  );
};

export default ListProdutos;
