import { addDays, addMonths } from "date-fns";

export function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const calcularTotal = (documentoFrete) => {
  const valorSeguroTemp = documentoFrete?.valorSeguro
    ? parseFloat(documentoFrete.valorSeguro)
    : 0;
  const valorFreteTemp = documentoFrete?.valorFrete
    ? parseFloat(documentoFrete.valorFrete)
    : 0;
  return valorSeguroTemp + valorFreteTemp;
};

export function totalValorMercadoria(listaNfe) {
  if (listaNfe?.length) {
    const total = listaNfe.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.valorNfe),
      0
    );
    return total;
  }
}

export function totalPeso(listaNfe) {
  if (listaNfe?.length) {
    const total = listaNfe.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.pesoNfe),
      0
    );
    return total;
  }
}

export function calculaSubtotalPagamentos(documentoFretePagamentos) {
  if (documentoFretePagamentos?.length > 0) {
    const total = documentoFretePagamentos.reduce(
      (prevVal, elem) => prevVal + parseFloat(elem.valorTitulo),
      0
    );
    return total;
  }
}

export function parcelaData(indice, data, intervaloDias) {
  const dias = indice * (intervaloDias ?? 1);
  const meses = Math.floor(dias / 30);
  const diasRestantes = dias % 30;
  const dataAtualizada = addMonths(new Date(data), meses);
  const dataFinal = addDays(dataAtualizada, diasRestantes);
  return dataFinal;
}
