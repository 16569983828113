import * as Yup from "yup";

const operacaoFiscalValidator = Yup.object().shape({
  ativado: Yup.boolean().nullable(),
  tipoOperacao: Yup.string().required("Tipo operação"),
  descricao: Yup.string().required("Descrição"),
  modeloDocumento: Yup.string().required("Modelo documento"),
  disponivelPdv: Yup.boolean().nullable(),
  movimentacaoEstoque: Yup.boolean().nullable(),
  movimentacaoFinanceira: Yup.boolean().nullable(),
  naoGerarComissao: Yup.boolean().nullable(),
  serieDocumento: Yup.mixed().when("tipoOperacao", {
    is: "SAIDA",
    then: Yup.mixed().when("modeloDocumento", {
      is: (modeloDocumento) => modeloDocumento !== "01",
      then: Yup.mixed().test(
        "is-number",
        "Série Documento Numérica",
        (value) => value && !isNaN(Number(value))
      ),
      otherwise: Yup.string().required("Série Documento"),
    }),
    otherwise: Yup.string().nullable(),
  }),
  presencaConsumidor: Yup.string().required("Presença consumidor"),
  finalidade: Yup.string().required("Finalidade"),
  //planoFinancerioId: Yup.number().nullable(),
  //centroCustoId: Yup.number().nullable(),
  naturezaOperacao: Yup.string().required("Natureza da operação"),
  informacaoComplementar: Yup.string().nullable(),
  informacaoFiscal: Yup.string().nullable(),
});

export default operacaoFiscalValidator;
