import InputMask from "react-input-mask";
import { TextField } from "@mui/material";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const MaskedInput = ({
  value,
  onChange,
  onInput,
  name,
  mask,
  id,
  label,
  required,
  InputProps,
  InputLabelProps,
  variant,
  disabled,
  error,
  margin,
  onBlur,
}) => {
  function handleChange(event) {
    if (!onChange) return;
    onChange({
      ...event,
      target: {
        ...event.target,
        name,
        value: onlyNumbers(event.target.value),
      },
    });
  }

  return (
    <InputMask
      name={name}
      mask={mask}
      value={value ?? undefined}
      disabled={disabled}
      onBlur={onBlur}
      onInput={onInput}
      onChange={(event) => handleChange(event)}
    >
      <TextField
        id={id}
        name={name}
        label={label}
        variant={variant || "outlined"}
        type="text"
        margin={margin || "normal"}
        required={required}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        fullWidth
        error={error}
      />
    </InputMask>
  );
};

export default MaskedInput;
