import * as Yup from "yup";

const empresaValidator = Yup.object().shape({
  fantasia: Yup.string().required("Nome fantasia"),
  razaoSocial: Yup.string().required("Razão social"),
  naturezaSocial: Yup.string().required(),
  cnpjCpf: Yup.string().required("CNPJ/CPF"),
  cnae: Yup.string().required("CNAE"),
  regimeTributario: Yup.string().required(),
  email: Yup.string().email().required("E-mail"),
  imagemLogo: Yup.string().nullable(),
  celular: Yup.string().required("Celular"),
  enderecoCep: Yup.string().required("CEP"),
  enderecoLogradouro: Yup.string().required("Logradouro"),
  enderecoLogradouroNumero: Yup.number().required("Número"),
  enderecoBairro: Yup.string().required("Bairro"),
  enderecoComplemento: Yup.string().nullable(),
  cidadeId: Yup.number().required("Cidade"),
  inscricaoEstadualIsento: Yup.boolean().nullable(),
  inscricaoEstadual: Yup.number().nullable(),
  registroGeral: Yup.string().nullable(),
  emailContabil: Yup.string().email().nullable(),
  telefone: Yup.string().nullable(),
  contadorAutorizarDadosXmlNfe: Yup.boolean().required(),
  contadorAutorizarDownloadXmlNfe: Yup.boolean().required(),
  contadorNome: Yup.string().nullable(),
  contadorCpf: Yup.string().nullable(),
  contadorCnpj: Yup.string().nullable(),
  contadorCrc: Yup.string().nullable(),
  suspenderAtividade: Yup.boolean().required(),
});

export default empresaValidator;
