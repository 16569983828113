export class ContaReceber {
  status = null;
  nfSaidaId = null;
  tipo = null;
  origem = null;
  acrescimo = null;
  baixaAposInclusao = null;
  bancoCaixaId = null;
  centroCustoId = null;
  dataLancamento = null;
  dataVencimento = null;
  desconto = null;
  gerarFluxoCaixa = null;
  historico = null;
  meioPagamentoId = null;
  numeroTitulo = null;
  observacaoList = null;
  pagadorId = null;
  pagamentos = null;
  planoFinanceiroId = null;
  desdobrarTitulo = null;
  saldoReceber = null;
  valor = null;
  valorTotal = null;

  constructor(body, meioPagamento, colaborador) {
    this.status = body.status;
    this.nfSaidaId = body.nfSaidaId;
    this.tipo =
      meioPagamento.nfePagamento === "fpCreditoLoja"
        ? "NOTA_CREDITO"
        : "TITULO_NORMAL";
    this.origem = body.origem;
    this.acrescimo = body.acrescimo;
    this.baixaAposInclusao = body.baixaAposInclusao;
    this.bancoCaixaId = colaborador?.bancoCaixaId
      ? colaborador.bancoCaixaId
      : body.bancoCaixaId;
    this.centroCustoId = body.centroCustoId;
    this.dataLancamento = body.dataLancamento;
    this.dataVencimento = body.dataVencimento;
    this.desconto = body.desconto;
    this.gerarFluxoCaixa = body.gerarFluxoCaixa;
    this.historico = body.historico;
    this.meioPagamentoId = body.meioPagamentoId;
    this.numeroTitulo = body.numeroTitulo;
    this.observacaoList = body.observacaoList;
    this.pagadorId = body.pagadorId;
    this.pagamentos = body.pagamentos;
    this.planoFinanceiroId = body.planoFinanceiroId;
    this.desdobrarTitulo = body.desdobrarTitulo;
    this.saldoReceber = body.saldoReceber;
    this.valor = body.valor;
    this.valorTotal = body.valorTotal;
  }

  removerObservacaoList(id) {
    if (id) {
      delete this.observacaoList;
    }
  }
}
