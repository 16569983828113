export class Orcamento {
  dataEmissao = null;
  serieDocumento = null;
  operacaoFiscalId = null;
  pessoaVendedorId = null;
  pessoaClienteId = null;
  pessoaProficionalId = null;
  orcamentoEnderecoCliente = null;
  condicaoPagamentoId = null;
  meioPagamentoId = null;
  desconto = null;
  acrescimo = null;
  despesas = null;
  informacaoAdicional = null;
  orcamentoItens = null;
  orcamentoEnderecoCliente = null;

  constructor(orcamento) {
    this.dataEmissao = orcamento.dataEmissao;
    this.serieDocumento = orcamento.serieDocumento;
    this.operacaoFiscalId = orcamento.operacaoFiscalId;
    this.pessoaVendedorId = orcamento.pessoaVendedorId;
    this.pessoaClienteId = orcamento.pessoaClienteId;
    this.pessoaProficionalId = orcamento.pessoaProficionalId;
    this.orcamentoEnderecoCliente = orcamento.orcamentoEnderecoCliente;
    this.condicaoPagamentoId = orcamento.condicaoPagamentoId;
    this.meioPagamentoId = orcamento.meioPagamentoId;
    this.desconto = orcamento.desconto;
    this.acrescimo = orcamento.acrescimo;
    this.despesas = orcamento.despesas;
    this.informacaoAdicional = orcamento.informacaoAdicional;
    this.orcamentoItens = orcamento.orcamentoItens?.length
      ? orcamento.orcamentoItens.map((item) => new OrcamentoItens(item))
      : [];
    this.orcamentoEnderecoCliente = new OrcamentoEnderecoCliente(
      orcamento.orcamentoEnderecoCliente
    );
  }
}

class OrcamentoItens {
  produtoId = null;
  unidade = null;
  quantidade = null;
  codigo = null;
  valorUnitario = null;
  ncm = null;
  tributosIcmsOrigem = null;

  constructor(orcamentoItens) {
    this.produtoId = orcamentoItens.produtoId;
    this.unidade = orcamentoItens.unidade;
    this.quantidade = orcamentoItens.quantidade;
    this.codigo = orcamentoItens.codigo;
    this.valorUnitario = orcamentoItens.valorUnitario;
    this.ncm = orcamentoItens.ncm;
    this.tributosIcmsOrigem = orcamentoItens.tributosIcmsOrigem;
  }
}

class OrcamentoEnderecoCliente {
  id = null;
  cep = null;
  logradouro = null;
  numero = null;
  bairro = null;
  complemento = null;
  descricao = null;
  padrao = null;
  pessoaId = null;
  cidadeId = null;
  int_cidade_id = null;
  int_pessoa_id = null;
  cidade = null;
  estado = null;

  constructor(orcamentoEnderecoCliente) {
    this.id = orcamentoEnderecoCliente.id;
    this.cep = orcamentoEnderecoCliente.cep;
    this.logradouro = orcamentoEnderecoCliente.logradouro;
    this.numero = orcamentoEnderecoCliente.numero;
    this.bairro = orcamentoEnderecoCliente.bairro;
    this.complemento = orcamentoEnderecoCliente.complemento;
    this.descricao = orcamentoEnderecoCliente.descricao;
    this.padrao = orcamentoEnderecoCliente.padrao;
    this.pessoaId = orcamentoEnderecoCliente.pessoaId;
    this.cidadeId = orcamentoEnderecoCliente.cidadeId;
    this.int_cidade_id = orcamentoEnderecoCliente.int_cidade_id;
    this.int_pessoa_id = orcamentoEnderecoCliente.int_pessoa_id;
    this.cidade = orcamentoEnderecoCliente.cidade;
    this.estado = orcamentoEnderecoCliente.estado;
  }
}
