import * as Yup from "yup";

const clienteValidator = Yup.object().shape({
  indicadorConsumidorFinal: Yup.boolean().required("Consumidor Final"),
  tipoContribuinteId: Yup.number().required("Tipo Contribuinte"),
  tabelaPrecoPadrao: Yup.number().nullable(),
  mensagemVenda: Yup.string().nullable(),
  limiteCompra: Yup.number().nullable(),
  grupoPessoaId: Yup.number().nullable(),
});

export default clienteValidator;
