import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import { history, TabelaPrecoService, notification } from "../../../services";
import { responseErros, formatPrice, formatPercentage } from "../../../utils";
import { FormFiltroAvancado } from "./components";
import { PermisoesHelper } from "../../../helpers";

const ListTabelaPreco = () => {
  const tabelaPrecoService = new TabelaPrecoService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("tabelas-preco-visualizar");
  const [loading, setLoading] = useState(false);
  const [tabelasPreco, setTabelasPreco] = useState([]);
  const colunas = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 700,
      filtrar: true,
    },
    {
      field: "tipoTabela",
      headerName: "Tipo de tabela",
      flex: 500,
      sortable: false,
    },
    {
      field: "aplicarDescontoEm",
      headerName: "Tipo de desconto",
      flex: 500,
      sortable: false,
    },
    {
      field: "descontoTabela",
      headerName: "Desconto",
      flex: 500,
      sortable: false,
      valueGetter: (params) => {
        const data = tabelasPreco.find(
          (tabela) => tabela.descontoTabela === params.value
        );
        if (data && data.tipoTabela === "Todos os produtos") {
          return data.aplicarDescontoEm === "Valor"
            ? formatPrice(params.value)
            : formatPercentage(params.value);
        } else {
          return "Por produto";
        }
      },
    },
  ];

  useEffect(() => {
    buscaTabelasPreco();
  }, []);

  const buscaTabelasPreco = async function (filter) {
    setLoading(true);
    const result = await tabelaPrecoService.getAll(filter);
    if (!result.isAxiosError) {
      setTabelasPreco(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarTabelaPreco = function () {
    history.push("/estoque/tabelas-preco/create");
  };

  const onClickEditTabelaPreco = function (id) {
    history.push(`/estoque/tabelas-preco/${id}`);
  };

  const onClickDeleteTabelaPreco = async function (id) {
    const data = tabelasPreco.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await tabelaPrecoService.deletar(id);
      if (!result.isAxiosError) {
        buscaTabelasPreco();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const result = await tabelaPrecoService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setTabelasPreco(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaTabelasPreco(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Tabelas de preço"
      loading={loading}
      onClickCadastrar={onClickCadastrarTabelaPreco}
      permissaoButton={permissoesHelper.temPermisao("tabelas-preco-cadastrar")}
    >
      <DataGrid
        rows={tabelasPreco}
        columns={colunas}
        onClickEdit={onClickEditTabelaPreco}
        paginationMode={"server"}
        onClickDelete={onClickDeleteTabelaPreco}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
        visualizarShow={permissoesHelper.temPermisao(
          "tabelas-preco-visualizar"
        )}
        editShow={permissoesHelper.temPermisao("tabelas-preco-editar")}
        deleteShow={permissoesHelper.temPermisao("tabelas-preco-excluir")}
      />
    </ListLayout>
  );
};

export default ListTabelaPreco;
