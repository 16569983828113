import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: ${(props) =>
      !props.scroll ? "#d0dbe3 !important" : "#656565 !important"};
  }
  
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif !important;
  }
  html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    background-color: #f5f5f5;
  }
  body, input, button {
    font: 14px 'Montserrat', sans-serif;
  }
  button {
    cursor: pointer;
  }
  [class^="ph-"], [class*=" ph-"] {
    color: #494c62;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
  .swal2-container {
    z-index: 9999;
  }
  .MuiContainer-root{
    padding: 110px 24px 0px; !important;
  }
  .MuiCardHeader-root {
    .MuiCardHeader-title {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .MuiCardHeader-action {
      padding-top: 5px;
    }
    .MuiSvgIcon-root {
      color: #333;
    }
  }

  .MuiInputLabel-outlined{
    color: #999;
    padding: 1px;
    background-color: white;
  }

  .MuiTableCell-stickyHeader {
    font-weight: bold;
    background-color: #FFF;
  }

  .MuiTab-root {
    font-weight: 800 !important;
  }

  .MuiCardActions-root {
    display: flex;
    padding: 16px !important;
  }

  .MuiTablePagination-root-555 {
    color: #fff !important;
  }

  .MuiDataGrid-root {
    letter-spacing: -0.5px !important;
    font-size: 15px !important;
  }

  .MuiDataGrid-columnSeparator {
    visibility: visible !important
  }

  .MuiAutocomplete-option[aria-selected="true"] {
    background-color: ${(props) =>
      props.themeMode === "light" ? "#dddddd" : "#11151e"};
  }

  @media (min-width: 1200px) {
    .MuiContainer-root{
      max-width: 1600px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  align-self: center;
  margin-top: 10px;
  span {
    font-weight: 300;
  }
`;
