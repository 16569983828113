import { useState } from "react";
import { Button, Loading } from "../../../components";
import { notification } from "../../../services";

const ImportarXmlActions = ({
  steps,
  activeStep,
  setActiveStep,
  handleInitStep,
  dadosXml,
}) => {
  const [loading, setLoading] = useState(false);
  const handleAvancarStep = async () => {
    try {
      setLoading(true);
      let stepAtual = activeStep;
      if (!dadosXml?.transportadora && activeStep == 1) {
        await steps[stepAtual]?.funcaoAcao(dadosXml);
        stepAtual++;
      }
      await steps[stepAtual]?.funcaoAcao(dadosXml);
      setActiveStep(stepAtual + 1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return notification.alertaGenericos(error.message);
    }
  };

  return (
    <>
      <Button variant="contained" color="padrao" onClick={handleInitStep}>
        <i
          className="ph-bold ph-arrow-counter-clockwise"
          style={{ fontSize: 20 }}
        ></i>
      </Button>
      <Button variant="contained" onClick={handleAvancarStep}>
        Próximo
        <i
          className="ph-fill ph-caret-right"
          style={{ color: "#fff", fontSize: 18 }}
        ></i>
      </Button>
      <Loading loading={loading} />
    </>
  );
};

export default ImportarXmlActions;
