import { ValidarEmailHelper } from "../../../helpers";
const validarEmailHelper = new ValidarEmailHelper();

export class BancoCaixa {
  nome = null;
  tipoConta = null;
  dataSaldoInicial = null;
  saldoInicial = null;
  bancoId = null;
  agencia = null;
  aberturaAutomatica = false;
  conta = null;
  chavePix = null;
  nomeGerente = null;
  telefoneGerente = null;
  emailGerente = null;
  ativado = true;
  responsaveisList = [];

  constructor(dadosBancoCaixa) {
    this.nome = dadosBancoCaixa.nome;
    this.tipoConta = dadosBancoCaixa.tipoConta;
    this.dataSaldoInicial = dadosBancoCaixa.dataSaldoInicial;
    this.saldoInicial = dadosBancoCaixa.saldoInicial;
    this.bancoId = dadosBancoCaixa.bancoId;
    this.agencia = dadosBancoCaixa.agencia;
    this.aberturaAutomatica = dadosBancoCaixa.aberturaAutomatica || false;
    this.conta = dadosBancoCaixa.conta;
    this.chavePix = dadosBancoCaixa.chavePix;
    this.nomeGerente = dadosBancoCaixa.nomeGerente;
    this.telefoneGerente = dadosBancoCaixa.telefoneGerente;
    this.emailGerente = dadosBancoCaixa.emailGerente;
    this.ativado = dadosBancoCaixa.ativado;
    this.responsaveisList = dadosBancoCaixa.responsaveisList;
  }

  validarEmail() {
    return validarEmailHelper.email(this.emailGerente);
  }
}
