import { TablePrintResumidoComponent } from "../../../../components";
import { format } from "date-fns";
import { formatDate, formatNomes, formatPrice } from "../../../../utils";

const PrintNfSaidaResumido = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Doc.",
      field: "serieDocumento",
      render: (value) => `${value.serieDocumento}/${value.numeroDocumento}`,
    },
    {
      header: "Data Emi.",
      field: "dataEmissao",
      render: ({ dataEmissao }) =>
        format(formatDate.received(dataEmissao), "dd/MM/yyyy"),
    },
    {
      header: "Cliente",
      field: "cliente",
      render: ({ cliente }) => cliente && formatNomes(cliente.substring(0, 30)),
    },
    {
      header: "Vendedor",
      field: "vendedor",
      render: ({ vendedor }) =>
        vendedor && formatNomes(vendedor.substring(0, 20)),
    },
    {
      header: "Valor Merc.",
      field: "valorMercadoria",
      fieldTotal: "totalValorMecadoria",
      render: ({ valorMercadoria }) => formatPrice(valorMercadoria, false),
    },
    {
      header: "Acrésc.",
      field: "acrescimos",
      fieldTotal: "totalAcrescimos",
      render: ({ acrescimos }) => formatPrice(acrescimos, false),
    },
    {
      header: "Despe.",
      field: "despesas",
      fieldTotal: "totalDespesas",
      render: ({ despesas }) => formatPrice(despesas, false),
    },
    {
      header: "Desc.",
      field: "descontos",
      fieldTotal: "totalDescontos",
      render: ({ descontos }) => formatPrice(descontos, false),
    },
    {
      header: "Total",
      field: "valorTotal",
      fieldTotal: "total",
      render: ({ valorTotal }) => formatPrice(valorTotal, false),
    },
  ];
  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.nfSaida}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={32}
    />
  );
};

export default PrintNfSaidaResumido;
