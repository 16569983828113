import { useRef, useState } from "react";
import {
  TextField,
  Grid,
  Dialog,
  CardHeader,
  Card,
  CardContent,
  FormControl,
  Autocomplete,
  DialogActions,
  Button,
} from "../../../../components";
import { notification, ProdutosService } from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";

const produtosService = new ProdutosService();

const VincularProdutoDialog = ({
  produtos,
  setProdutos,
  produtoSelecionado,
  openVincularProdutoDialog,
  setOpenVincularProdutoDialog,
}) => {
  const inputRef = useRef();
  const [produtoTroca, setProdutoTroca] = useState(null);
  const [listaProdutosTroca, setListaProdutosTroca] = useState([]);

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    produtosService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaProdutosTroca(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const handleVincularProduto = (event) => {
    event.preventDefault();
    if (!produtoTroca) {
      return notification.alertaGenericos(
        "Insira uma quantidade no item de conversão."
      );
    }
    const index = produtos?.findIndex(
      (item) => item.id === produtoSelecionado.id
    );
    const produtosXmlTemp = produtos.map((item, _index) => {
      if (_index === index) {
        const produtoTemp = {
          ...item,
          ...produtoTroca,
          codigoProduto: produtoTroca.codigoProduto,
          produtoId: produtoTroca.id,
          produto: produtoTroca.descricao,
          subtotal: item.quantidade * produtoTroca.precoCompra,
        };
        return produtoTemp;
      } else {
        return item;
      }
    });
    setProdutos(produtosXmlTemp);
    handleInitVinculo();
  };

  const onInputChangeProdutos = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value.includes(",")
        ? event.target?.value.split(",")[0]
        : event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          codigoBarra: eventValue,
          descricao: eventValue,
        };
        timeoutBuscaAutocomplete(buscarProdutos(filtros));
      }
    }
  };

  const handleInitVinculo = () => {
    setProdutoTroca(null);
    setOpenVincularProdutoDialog(false);
  };

  return (
    <Dialog
      open={openVincularProdutoDialog}
      disableEscapeKeyDown
      disableClickEventBubbling
      fullWidth
      maxWidth="md"
    >
      <Card>
        <CardHeader title="Vincular Produto" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="produtoTroca"
                  name="produtoTroca"
                  options={listaProdutosTroca}
                  autoHighlight
                  loadingText="Carregando"
                  noOptionsText="Digite e pressione Enter"
                  getOptionLabel={(option) =>
                    option ? `${option?.codigo} - ${option?.descricao}` : ""
                  }
                  filterSelectedOptions
                  onChange={(_, newValue) => setProdutoTroca(newValue)}
                  value={produtoTroca}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={"outlined"}
                      label="Buscar produto"
                      onKeyDown={onInputChangeProdutos}
                      inputRef={inputRef}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogActions>
        <Button variant="contained" onClick={handleInitVinculo}>
          Fechar
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleVincularProduto}
        >
          <i
            className="ph-fill ph-git-fork"
            style={{
              fontSize: 22,
              marginRight: 10,
              color: "white",
              transform: "rotate(180deg)",
            }}
          ></i>
          Vincular
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VincularProdutoDialog;
