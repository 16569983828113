import { useEffect, useState } from "react";
import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "../../../../components";
import {
  EnderecoService,
  GruposTributarioService,
  OperacaoFiscalService,
} from "../../../../services";
import { responseErros } from "../../../../utils";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const gruposTributarioService = new GruposTributarioService();
  const enderecoService = new EnderecoService();
  const operacaoFiscalService = new OperacaoFiscalService();

  const [grupoTributarioList, setGrupoTributarioList] = useState([]);
  const [estados, setEstados] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);

  useEffect(() => {
    buscarGruposTributarios();
    buscaEstados();
    buscarOperacoesFiscais();
  }, []);

  const buscarGruposTributarios = () => {
    const filtro = {
      nonPaginated: true,
    };
    gruposTributarioService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setGrupoTributarioList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaEstados = () => {
    enderecoService.getEstados().then((result) => {
      if (!result.isAxiosError) {
        setEstados(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarOperacoesFiscais = () => {
    const filtro = {
      nonPaginated: true,
    };
    operacaoFiscalService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          label="Tipo de Operação"
          id="tipoOperacao"
          name="tipoOperacao"
          value={valueInput("tipoOperacao")}
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value={""}>
            <p></p>
          </MenuItem>
          <MenuItem value={"ENTRADA"}>Entrada</MenuItem>
          <MenuItem value={"SAIDA"}>Saída</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="grupoTributarioId"
          name="grupoTributarioId"
          options={grupoTributarioList}
          autoHighlight
          disableClearable
          getOptionLabel={(option) => (option?.nome ? option.nome : "")}
          getOptionSelected={(option, value) => option?.nome === value?.nome}
          noOptionsText="Sem opções"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Grupo Tributário"
              margin="normal"
              required
            />
          )}
          onChange={(e, value) =>
            onChangeAutocomplete("grupoTributarioId", value)
          }
          value={valueAutocomplete(grupoTributarioList, "grupoTributarioId")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="estadoId"
          name="estadoId"
          noOptionsText="Sem opções"
          options={estados}
          autoHighlight
          getOptionLabel={(option) => (option?.nome ? option.nome : "")}
          onChange={(e, value) => onChangeAutocomplete("estadoId", value)}
          value={valueAutocomplete(estados, "estadoId")}
          renderInput={(params) => (
            <TextField {...params} label="UF Destino" margin="normal" />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="operacaoFiscalId"
          name="operacaoFiscalId"
          noOptionsText="Sem opções"
          options={operacaoFiscalList}
          autoHighlight
          getOptionLabel={(option) =>
            option?.descricao ? option?.descricao : ""
          }
          onChange={(e, value) =>
            onChangeAutocomplete("operacaoFiscalId", value)
          }
          value={valueAutocomplete(operacaoFiscalList, "operacaoFiscalId")}
          renderInput={(params) => (
            <TextField {...params} label="Operação Fiscal" margin="normal" />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
