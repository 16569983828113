import createAxiosConfig from "../config/createAxiosConfig";
import store from "../config/store";
import SiafLiteApiBaseRepository from "./siaf-lite-api-base";
const headers = {
  "Content-Type": "multipart/form-data",
};
const axiosConfig = createAxiosConfig(store.baseUrl, headers);

class SiafLiteApiFormDataRepository extends SiafLiteApiBaseRepository {
  constructor(url) {
    super(url, axiosConfig);
  }
}

export default SiafLiteApiFormDataRepository;
