import * as Yup from "yup";

const enderecoValidator = Yup.object().shape({
  cep: Yup.string().min(8, "CEP deve ter 8 digitos").required("CEP"),
  logradouro: Yup.string().required("Logradouro"),
  numero: Yup.string().required("Número"),
  bairro: Yup.string().nullable(),
  complemento: Yup.string().nullable(),
  cidadeId: Yup.number().required("Cidade"),
  estado: Yup.string().required("Estado"),
  descricao: Yup.string().nullable(),
});

export default enderecoValidator;
