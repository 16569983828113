import { useState, useEffect } from "react";
import { AddLayout } from "../../layouts";
import { Paper, Tab, Tabs, TabPanel } from "../../components";
import {
  ConfiguracaoCadastroTab,
  ConfiguracaoComprasTab,
  ConfiguracaoFinanceiroTab,
  ConfiguracaoGeralTab,
  ConfiguracaoPdvTab,
  ConfiguracaoVendaTab,
} from "./components/tabs";
import { ConfiguracoesService, notification } from "../../services";
import { responseErros, unformatPrice, voltar } from "../../utils";
import { useImportContext } from "../../contexts/import.context";
import { ConfiguracoesEntity } from "./entities";
import { configuracoesValidator } from "./middlewares";
import { InputErros, PermisoesHelper } from "../../helpers";

const Configuracoes = () => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("configuracoes-visualizar");
  const { initialCollapses } = useImportContext();
  const configuracoesService = new ConfiguracoesService();
  const [loading, setLoading] = useState(false);
  const [listaPagamentoPadrao, setListaPagamentoPadrao] = useState([]);
  const [configuracao, setConfiguracao] = useState({
    impressaoDiretaPdv: false,
  });
  const [abaValue, setAbaValue] = useState(0);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscaConfiguracoes();
  }, []);

  const buscaConfiguracoes = async () => {
    const result = await configuracoesService.get();
    if (!result.isAxiosError && result.data.count) {
      const data = result.data.rows[0];
      setConfiguracao(data);
      inserirValoresListaPagamentoPadrao(
        data.meioPagamentosPadroesConfiguracoes
      );
    } else {
      responseErros(result);
    }
  };

  const handleSubmit = () => {
    setInputErro([]);
    configuracoesValidator
      .validate(configuracao, { abortEarly: false })
      .then(() => {
        atualizar();
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  async function atualizar() {
    setLoading(true);
    const configuracoesEntity = new ConfiguracoesEntity(configuracao);
    configuracoesEntity.setPagamentosPadroes(listaPagamentoPadrao);
    const result = await configuracoesService.atualizar(
      configuracao.id,
      configuracoesEntity
    );
    setLoading(false);
    if (!result.isAxiosError) {
      buscaConfiguracoes();
      notification.alteracaoSucesso();
      initialCollapses();
      voltar();
    } else {
      responseErros(result);
    }
  }

  const onChangeSwitch = (event) => {
    const configuracaoTemp = {
      ...configuracao,
      [event.target.name]: event.target.checked,
    };
    setConfiguracao(configuracaoTemp);
  };

  const onChangeAutocomplete = (name, value) => {
    setConfiguracao({
      ...configuracao,
      [name]: value ? value.id : undefined,
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const valor = name === "carencia" ? value.replace(/\D/g, "") : value;
    setConfiguracao((prevState) => ({
      ...prevState,
      [name]: valor,
    }));
  };

  const onBlur = (event) => {
    setConfiguracao((prevState) => ({
      ...prevState,
      [event.target.name]: unformatPrice(event.target.value),
    }));
  };

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  function inserirValoresListaPagamentoPadrao(configuracaoPagamentosPadrao) {
    const idsPagamentos =
      configuracaoPagamentosPadrao?.map((pagamento) => ({
        id: pagamento.meioPagamentoId,
      })) || [];
    setListaPagamentoPadrao(idsPagamentos);
  }

  return (
    <AddLayout
      title="Editar Configurações"
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={null}
      showUpdate={permissoesHelper.temPermisao("configuracoes-editar")}
    >
      <Paper>
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="GERAL" {...tabProps(0)} />
          <Tab label="CADASTRO" {...tabProps(1)} />
          <Tab label="COMPRAS" {...tabProps(2)} />
          <Tab label="VENDAS" {...tabProps(3)} />
          <Tab label="FINANCEIRO" {...tabProps(4)} />
          <Tab label="FISCAL" {...tabProps(5)} />
          <Tab label="PDV" {...tabProps(6)} />
        </Tabs>
      </Paper>
      <TabPanel value={abaValue} index={0} className="mt-3">
        <ConfiguracaoGeralTab setLoading={setLoading} />
      </TabPanel>
      <TabPanel value={abaValue} index={1} className="mt-3">
        <ConfiguracaoCadastroTab
          configuracao={configuracao}
          onChangeSwitch={onChangeSwitch}
          onChange={onChange}
        />
      </TabPanel>
      <TabPanel value={abaValue} index={2} className="mt-3">
        <ConfiguracaoComprasTab
          configuracao={configuracao}
          onChangeAutocomplete={onChangeAutocomplete}
          onChangeSwitch={onChangeSwitch}
        />
      </TabPanel>
      <TabPanel value={abaValue} index={3} className="mt-3">
        <ConfiguracaoVendaTab
          configuracao={configuracao}
          onChangeAutocomplete={onChangeAutocomplete}
          onChangeSwitch={onChangeSwitch}
        />
      </TabPanel>
      <TabPanel value={abaValue} index={4} className="mt-3">
        <ConfiguracaoFinanceiroTab
          configuracao={configuracao}
          onChange={onChange}
          onBlur={onBlur}
          onChangeAutocomplete={onChangeAutocomplete}
        />
      </TabPanel>
      <TabPanel value={abaValue} index={6} className="mt-3">
        <ConfiguracaoPdvTab
          configuracao={configuracao}
          setConfiguracao={setConfiguracao}
          listaPagamentoPadrao={listaPagamentoPadrao}
          setListaPagamentoPadrao={setListaPagamentoPadrao}
          onChangeSwitch={onChangeSwitch}
          onChangeAutocomplete={onChangeAutocomplete}
        />
      </TabPanel>
    </AddLayout>
  );
};

export default Configuracoes;
