export class GrupoProdutoEntity {
  ativado = true;
  descricao = null;
  idPai = null;

  constructor(grupoProduto) {
    if (grupoProduto.ativado === false) {
      this.ativado = false;
    }
    this.descricao = grupoProduto.descricao;
    this.idPai = grupoProduto.idPai;
  }
}
