import { Collapse as CollapseMui } from "@mui/material";

const Collapse = ({
  addEndListener,
  children,
  classes,
  collapsedSize,
  component,
  easing,
  inn,
  orientation,
  sx,
  timeout,
  className,
  ...rest
}) => {
  return (
    <CollapseMui
      addEndListener={addEndListener}
      classes={classes}
      component={component}
      collapsedSize={collapsedSize}
      easing={easing}
      in={inn}
      orientation={orientation}
      timeout={timeout}
      sx={sx}
      className={className}
      {...rest}
    >
      {children}
    </CollapseMui>
  );
};

export default Collapse;
