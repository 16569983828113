import SiafLiteApiRepository from "../../external/siafLiteApi";

class NcmService {
  constructor() {
    this.urlBase = "/fiscal/ncm";
  }

  getAll(filtros) {
    return new SiafLiteApiRepository(this.urlBase).get(filtros);
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAllAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }
}

export default NcmService;
