import * as Yup from "yup";
import contasReceberValidator from "../../add-contas-receber/middlewares/add-contas-receber.validator";

const listaContasReceberValidator = (meioPagamento) => {
  const contasReceberValidatorService = contasReceberValidator(meioPagamento);
  return Yup.array()
    .of(contasReceberValidatorService)
    .min(1, "Selecione no mínimo uma conta a baixar");
};
export default listaContasReceberValidator;
