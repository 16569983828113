import { useState, useEffect } from "react";
import { AddLayout } from "../../../layouts";
import {
  Autocomplete,
  CurrencyInput,
  CardContent,
  DatePicker,
  FormControl,
  Grid,
  Paper,
  TabPanel,
  Tabs,
  Tab,
  TextField,
  TextFielDecimalNumbers,
} from "../../../components";
import {
  OrdemServicoService,
  ClienteService,
  ParametroService,
  PessoaService,
} from "../../../services";
import { InputErros, value } from "../../../helpers";
import {
  responseErros,
  formatDate,
  userLogs,
  unformatPrice,
} from "../../../utils";
import {
  OrdemServicoEquipamentos,
  OrdemServicoServicos,
  OrdemServicoProdutos,
  OrdemServicoPagamentos,
  OrdemServicoDetalhes,
  ResumoTotal,
} from "./components";

const ordemServicoService = new OrdemServicoService();
const clienteService = new ClienteService();
const pessoaService = new PessoaService();
const parametroService = new ParametroService();

const AddOrdemServico = ({ match }) => {
  const { id } = match.params;

  const [loading, setLoading] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [userLog, setUserLog] = useState({});
  const [parametro, setParametro] = useState(null);
  const [ordemServico, setOrdemServico] = useState({});
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [colaboradorList, setColaboradorList] = useState([]);
  const [tipoDesconto, setTipoDesconto] = useState("Valor");
  const [desconto, setDesconto] = useState(null);
  const [ordemServicoEquipamentos, setOrdemServicoEquipamentos] = useState([]);
  const [ordemServicoServicos, setOrdemServicoServicos] = useState([]);
  const [ordemServicoProdutos, setOrdemServicoProdutos] = useState([]);
  const [ordemServicoPagamentos, setOrdemServicoPagamentos] = useState([]);
  const [ordemServicoDetalhes, setOrdemServicoDetalhes] = useState([]);
  const [servicoValorComissaoVendedor, setServicoValorComissaoVendedor] =
    useState(null);
  const [
    servicoPercentualComissaoVendedor,
    setServicoPercentualComissaoVendedor,
  ] = useState(null);
  const [servicoTotalComissaoVendedor, setServicoTotalComissaoVendedor] =
    useState(null);
  const [produtoValorComissaoVendedor, setProdutoValorComissaoVendedor] =
    useState(null);
  const [
    produtoPercentualComissaoVendedor,
    setProdutoPercentualComissaoVendedor,
  ] = useState(null);
  const [produtoTotalComissaoVendedor, setProdutoTotalComissaoVendedor] =
    useState(null);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const situacaoOperacaoList = ["Orçamento", "Pedido", "Ordem de Serviço"];
  const tipoDescontoList = ["Valor", "Percentual"];

  useEffect(() => {
    buscaParametro();
    buscarClientes();
    buscarColaboradores();
    if (!id) return;
    buscarOrdemServico();
  }, [id]);

  const buscarOrdemServico = async () => {
    setLoading(true);
    const response = await ordemServicoService.getById(id);
    if (!response.isAxiosError) {
      setOrdemServico(response.data);
      setTipoDesconto(response.data?.tipoDesconto);
      setDesconto(
        response.data?.tipoDesconto == "Valor"
          ? response.data?.desconto
          : (response.data?.desconto * 100) /
              (response.data?.subtotalServicos +
                response.data?.subtotalProdutos +
                response.data?.subtotalComissaoVendedor +
                response.data?.taxaDeslocamento)
      );
      setOrdemServicoEquipamentos(response.data?.ordemServicoEquipamentos);
      setOrdemServicoServicos(response.data?.ordemServicoServicos);
      setOrdemServicoProdutos(response.data?.ordemServicoProdutos);
      setOrdemServicoPagamentos(response.data?.ordemServicoPagamentos);
      setOrdemServicoDetalhes(response.data?.ordemServicoDetalhes);
    } else {
      responseErros(response);
    }
    setLoading(false);
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(ordemServico);
    setUserLog(logs);
  }

  const buscaParametro = () => {
    parametroService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setParametro(result.data[0]);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarClientes = () => {
    clienteService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarColaboradores = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        let listaVendedores = [];
        result.data.forEach((pessoa) => {
          pessoa.colaboradores.filter((colaborador) => {
            if (colaborador.vendedor == true) {
              listaVendedores.push(pessoa);
            }
          });
        });
        setColaboradorList(result.data);
        setVendedorList(listaVendedores);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputBlur = (e) => {
    e.persist();
    setOrdemServico((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const handleDateChange = (fieldName, date) => {
    if (!date) {
      date = "Invalid Date";
    }

    setOrdemServico((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    }));
  };

  const getValorComissao = (item, itemType) => {
    if (item?.valorComissaoVendedor) {
      return item?.valorComissaoVendedor;
    }
    if (
      itemType == "serviço" &&
      servicoValorComissaoVendedor &&
      !item?.valorComissaoVendedor
    ) {
      return servicoValorComissaoVendedor;
    }
    if (
      itemType == "produto" &&
      produtoValorComissaoVendedor &&
      !item?.valorComissaoVendedor
    ) {
      return produtoValorComissaoVendedor;
    }
    return parametro?.comissaoPdv
      ? item?.precoVenda * (parametro?.percentualComissaoPdv / 100)
      : item?.percentualComissaoVendedor
      ? item?.precoVenda * (item?.percentualComissaoVendedor / 100)
      : 0.0;
  };

  const getPercentualComissao = (item, itemType) => {
    if (item?.percentualComissaoVendedor) {
      return item?.percentualComissaoVendedor;
    }
    if (
      itemType == "serviço" &&
      servicoPercentualComissaoVendedor &&
      !item?.percentualComissaoVendedor
    ) {
      return servicoPercentualComissaoVendedor;
    }
    if (
      itemType == "produto" &&
      produtoPercentualComissaoVendedor &&
      !item?.percentualComissaoVendedor
    ) {
      return produtoPercentualComissaoVendedor;
    }
    return parametro?.comissaoPdv
      ? parametro?.percentualComissaoPdv
      : item?.percentualComissaoVendedor
      ? item?.percentualComissaoVendedor
      : 0.0;
  };

  const getTotalComissao = (item, itemType, quantidade = 1) => {
    if (item?.valorComissaoVendedor) {
      return item?.valorComissaoVendedor * quantidade;
    }
    if (
      itemType == "serviço" &&
      servicoValorComissaoVendedor &&
      !item?.valorComissaoVendedor
    ) {
      return servicoValorComissaoVendedor * quantidade;
    }
    if (
      itemType == "produto" &&
      produtoValorComissaoVendedor &&
      !item?.valorComissaoVendedor
    ) {
      return produtoValorComissaoVendedor * quantidade;
    }
    return parametro?.comissaoPdv
      ? item?.precoVenda * quantidade * (parametro?.percentualComissaoPdv / 100)
      : item?.percentualComissaoVendedor
      ? item?.precoVenda * quantidade * (item?.percentualComissaoVendedor / 100)
      : 0.0;
  };

  const calculaSubtotalServicosProdutos = (lista) => {
    if (lista.length) {
      return lista.reduce((acumulador, objetoAtual) => {
        return acumulador + objetoAtual.subtotal;
      }, 0);
    } else {
      return lista.length;
    }
  };

  const calculaComissaoVendedor = (lista) => {
    if (lista.length) {
      return lista.reduce((acumulador, objetoAtual) => {
        return acumulador + objetoAtual.totalComissaoVendedor;
      }, 0);
    } else {
      return lista.length;
    }
  };

  const converteDescontoEmValor = (
    subtotalServicos,
    subtotalProdutos,
    subtotalComissaoVendedor
  ) => {
    if (desconto) {
      return tipoDesconto === "Valor"
        ? parseFloat(desconto)
        : (subtotalServicos +
            subtotalProdutos +
            subtotalComissaoVendedor +
            parseFloat(ordemServico?.taxaDeslocamento)) *
            (parseFloat(desconto) / 100);
    } else {
      return 0;
    }
  };

  const calculaTotalVenda = (
    subtotalServicos,
    subtotalProdutos,
    subtotalComissaoVendedor
  ) => {
    return tipoDesconto === "Valor"
      ? subtotalServicos +
          subtotalProdutos +
          parseFloat(subtotalComissaoVendedor) +
          parseFloat(ordemServico?.taxaDeslocamento || 0) -
          parseFloat(desconto || 0)
      : subtotalServicos +
          subtotalProdutos +
          parseFloat(subtotalComissaoVendedor) +
          parseFloat(ordemServico?.taxaDeslocamento || 0) -
          (subtotalServicos +
            subtotalProdutos +
            parseFloat(subtotalComissaoVendedor) +
            parseFloat(ordemServico?.taxaDeslocamento || 0)) *
            (parseFloat(desconto || 0) / 100);
  };

  const calculaTotalPagamentos = (lista) => {
    if (lista.length) {
      return lista.reduce((acumulador, objetoAtual) => {
        return acumulador + objetoAtual.valor;
      }, 0);
    } else {
      return lista.length;
    }
  };

  const calculaTroco = (totalPagamentos, totalVenda) => {
    const result = totalPagamentos - totalVenda;
    return result && result > 0 ? result : 0;
  };

  useEffect(() => {
    const subtotalServicos =
      calculaSubtotalServicosProdutos(ordemServicoServicos);
    const subtotalProdutos =
      calculaSubtotalServicosProdutos(ordemServicoProdutos);
    const subtotalComissaoVendedor =
      calculaComissaoVendedor(ordemServicoServicos) +
      calculaComissaoVendedor(ordemServicoProdutos);
    const valorDesconto = converteDescontoEmValor(
      subtotalServicos,
      subtotalProdutos,
      subtotalComissaoVendedor
    );
    const totalVenda = calculaTotalVenda(
      subtotalServicos,
      subtotalProdutos,
      subtotalComissaoVendedor
    );
    const totalPagamentos = calculaTotalPagamentos(ordemServicoPagamentos);
    const troco = calculaTroco(totalPagamentos, totalVenda);

    setOrdemServico((prevState) => ({
      ...prevState,
      ordemServicoEquipamentos,
      ordemServicoServicos,
      ordemServicoProdutos,
      ordemServicoPagamentos,
      ordemServicoDetalhes,
      subtotalServicos,
      subtotalProdutos,
      subtotalComissaoVendedor,
      tipoDesconto,
      desconto: valorDesconto,
      troco,
      totalVenda,
    }));
  }, [
    tipoDesconto,
    desconto,
    ordemServicoEquipamentos,
    ordemServicoServicos,
    ordemServicoProdutos,
    ordemServicoPagamentos,
    ordemServicoDetalhes,
  ]);

  const handleSubmit = async () => {
    // setLoading(true);
    // ordemServicoValidator
    //   .validate(ordemServico, { abortEarly: false })
    //   .then(async () => {
    //     if (id) {
    //       const response = await ordemServicoService.atualizar(
    //         id,
    //         ordemServico
    //       );
    //       setLoading(false);
    //       if (!response.isAxiosError) {
    //         notification.alteracaoSucesso();
    //       } else {
    //         buscarOrdemServico();
    //         responseErros(response);
    //       }
    //     } else {
    //       const response = await ordemServicoService.cadastrar(ordemServico);
    //       setLoading(false);
    //       if (!response.isAxiosError) {
    //         handleCancelar();
    //         notification.cadastroSucesso();
    //       } else {
    //         responseErros(response);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     inputErros.set(err);
    //   });
  };

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const onChangeAutocomplete = (name, value) => {
    setOrdemServico({
      ...ordemServico,
      [name]: value ? value.id : undefined,
    });
  };

  return (
    <AddLayout
      title={
        ordemServico?.id
          ? "Editar Ordem de Serviço"
          : "Cadastrar Ordem de Serviço"
      }
      onClickSalvar={handleSubmit}
      loading={loading}
      codigo={ordemServico?.id ? `Cód. Nº ${ordemServico?.codigo}` : null}
      userLog={ordemServico?.id ? userLog : null}
      onClickLog={onClickLog}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="situacaoOperacao"
              name="situacaoOperacao"
              options={situacaoOperacaoList}
              noOptionsText="Sem opções"
              autoHighlight
              disabled={
                ordemServico?.situacaoOperacao === "Situação da Operação"
                  ? true
                  : false
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status da Negociação"
                  variant="outlined"
                  error={inputErros.get("situacaoOperacao")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setInputErro([]);
                setOrdemServico((prevState) => ({
                  ...prevState,
                  situacaoOperacao: newValue,
                }));
              }}
              value={
                ordemServico?.situacaoOperacao
                  ? ordemServico.situacaoOperacao
                  : ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="clienteId"
              name="cliente"
              options={clienteList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option.pessoa ? option.pessoa.nome : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  required
                  error={inputErros.get("clienteId")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setInputErro([]);
                onChangeAutocomplete("clienteId", newValue);
              }}
              value={value.autoComplete(clienteList, ordemServico.clienteId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="vendedorId"
              name="vendedor"
              options={vendedorList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              renderInput={(params) => (
                <TextField {...params} label="Vendedor" variant="outlined" />
              )}
              onChange={(e, newValue) => {
                e.persist();
                onChangeAutocomplete("vendedorId", newValue);
              }}
              value={value.autoComplete(clienteList, ordemServico.vendedorId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="tecnicoResponsavelId"
              name="tecnicoResponsavel"
              options={colaboradorList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option.pessoa ? option.pessoa.nome : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Técnico Responsável"
                  variant="outlined"
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                onChangeAutocomplete("tecnicoResponsavelId", newValue);
              }}
              value={value.autoComplete(
                colaboradorList,
                ordemServico.tecnicoResponsavelId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <DatePicker
            id="dataOrdemServico"
            name="dataOrdemServico"
            label="Data da O.S."
            format="dd/MM/yyyy"
            margin
            required
            value={
              ordemServico?.dataOrdemServico
                ? ordemServico.dataOrdemServico
                : null
            }
            onChange={(date) => {
              setInputErro([]);
              handleDateChange("dataOrdemServico", date);
            }}
            error={inputErros.get("dataOrdemServico")}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            id="previsaoEntrega"
            name="previsaoEntrega"
            label="Previsão de Entrega"
            format="dd/MM/yyyy"
            margin
            required
            value={
              ordemServico?.previsaoEntrega
                ? ordemServico.previsaoEntrega
                : null
            }
            onChange={(date) => {
              setInputErro([]);
              handleDateChange("previsaoEntrega", date);
            }}
            error={inputErros.get("previsaoEntrega")}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="tipoDesconto"
              name="tipoDesconto"
              options={tipoDescontoList}
              noOptionsText="Sem opções"
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    tipoDesconto === "Valor"
                      ? "Tipo Desconto (R$)"
                      : "Tipo Desconto (%)"
                  }
                  variant="outlined"
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setTipoDesconto(newValue);
              }}
              value={tipoDesconto}
            />
          </FormControl>
        </Grid>
        {tipoDesconto == "Valor" ? (
          <Grid item xs={2}>
            <CurrencyInput
              id="desconto"
              name="desconto"
              label="Desconto"
              value={desconto || ""}
              onBlur={(e) => setDesconto(unformatPrice(e.target.value))}
            />
          </Grid>
        ) : (
          <Grid item xs={2}>
            <TextFielDecimalNumbers
              id="desconto"
              name="desconto"
              label="Desconto"
              value={desconto || ""}
              onBlur={(e) => setDesconto(unformatPrice(e.target.value))}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <CurrencyInput
            id="taxaDeslocamento"
            name="taxaDeslocamento"
            label="Taxa de Deslocamento"
            value={
              ordemServico?.taxaDeslocamento
                ? ordemServico.taxaDeslocamento
                : ""
            }
            onBlur={handleInputBlur}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}></Grid>
      <hr className="mt-5 mb-5" />
      <Paper>
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="EQUIPAMENTOS" {...tabProps(0)} />
          <Tab label="SERVIÇOS" {...tabProps(1)} />
          <Tab label="PRODUTOS" {...tabProps(2)} />
          <Tab label="PAGAMENTOS" {...tabProps(3)} />
          <Tab label="DETALHES" {...tabProps(4)} />
        </Tabs>
      </Paper>
      <CardContent>
        <TabPanel value={abaValue} index={0}>
          <OrdemServicoEquipamentos
            ordemServicoEquipamentos={ordemServicoEquipamentos}
            setOrdemServicoEquipamentos={setOrdemServicoEquipamentos}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={1}>
          <OrdemServicoServicos
            ordemServicoServicos={ordemServicoServicos}
            setOrdemServicoServicos={setOrdemServicoServicos}
            servicoValorComissaoVendedor={servicoValorComissaoVendedor}
            servicoPercentualComissaoVendedor={
              servicoPercentualComissaoVendedor
            }
            servicoTotalComissaoVendedor={servicoTotalComissaoVendedor}
            setServicoValorComissaoVendedor={setServicoValorComissaoVendedor}
            setServicoPercentualComissaoVendedor={
              setServicoPercentualComissaoVendedor
            }
            setServicoTotalComissaoVendedor={setServicoTotalComissaoVendedor}
            getPercentualComissao={getPercentualComissao}
            getValorComissao={getValorComissao}
            getTotalComissao={getTotalComissao}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={2}>
          <OrdemServicoProdutos
            ordemServicoProdutos={ordemServicoProdutos}
            setOrdemServicoProdutos={setOrdemServicoProdutos}
            produtoValorComissaoVendedor={produtoValorComissaoVendedor}
            produtoPercentualComissaoVendedor={
              produtoPercentualComissaoVendedor
            }
            produtoTotalComissaoVendedor={produtoTotalComissaoVendedor}
            setProdutoValorComissaoVendedor={setProdutoValorComissaoVendedor}
            setProdutoPercentualComissaoVendedor={
              setProdutoPercentualComissaoVendedor
            }
            setProdutoTotalComissaoVendedor={setProdutoTotalComissaoVendedor}
            getPercentualComissao={getPercentualComissao}
            getValorComissao={getValorComissao}
            getTotalComissao={getTotalComissao}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={3}>
          <OrdemServicoPagamentos
            ordemServicoPagamentos={ordemServicoPagamentos}
            setOrdemServicoPagamentos={setOrdemServicoPagamentos}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={4}>
          <OrdemServicoDetalhes
            ordemServicoDetalhes={ordemServicoDetalhes}
            setOrdemServicoDetalhes={setOrdemServicoDetalhes}
          />
        </TabPanel>
        <ResumoTotal ordemServico={ordemServico} />
      </CardContent>
    </AddLayout>
  );
};

export default AddOrdemServico;
