const constantes = {
  modalidadeBaseCalculoList: [
    { value: "0 - Margem Valor Agregado (%)" },
    { value: "1 - Pauta (Valor)" },
    { value: "2 - Preço Tabelado Máximo (Valor)" },
    { value: "3 - Valor da Operação" },
    { value: "4 - Lista Negativa (Valor)" },
    { value: "5 - Lista Positiva (Valor)" },
    { value: "6 - Lista Neutra (Valor)" },
  ],
  calculaSubstituicao: [
    "modalidadeBaseCalculo",
    "reducaoBaseSt",
    "aliquotaSt",
    "aliquotaMva",
    "valorDePauta",
    "ipiNaBaseSt",
    "freteNabaseSt",
    "seguroNabaseSt",
    "despesaNaBaseSt",
    "descontoNaBaseSt",
    "acrescimoNaBaseSt",
  ],
};

export default constantes;
