import SiafLiteNfeRepository from "../../external/siafLiteNfe";

class NfeService {
  constructor() {
    this.urlBase = "/nfce";
  }

  baixarXml(cnpj, ano, mes) {
    return new SiafLiteNfeRepository(
      `${this.urlBase}/${cnpj}/xml/${ano}/${mes}`
    ).getFile();
  }
}

export default NfeService;
