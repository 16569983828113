import { Box, Grid, Typography } from "../../../../components";
import { formatCnpj } from "../../../../utils";

const FornecedorXmlStep = ({ fornecedor }) => {
  return (
    <Box sx={{ pl: 2, mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="overline">
            Nome do Fornecedor:
            <h4 style={{ fontWeight: 800, margin: 0 }}>
              {fornecedor?.apelidoFantasia}
            </h4>
            <br />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="overline">
            Natureza Social:
            <h4 style={{ fontWeight: 800, margin: 0 }}>
              {fornecedor?.naturezaSocial}
            </h4>
            <br />
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="overline">
            CNPJ:
            <h4 style={{ fontWeight: 800, margin: 0 }}>
              {formatCnpj(fornecedor?.cnpjCpf)}
            </h4>
          </Typography>
        </Grid>
      </Grid>
      {fornecedor?.endereco && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">
              Endereço:
              <h4 style={{ fontWeight: 800, margin: 0 }}>
                {fornecedor.endereco?.logradouro}
              </h4>
            </Typography>
            <Typography variant="overline">
              Nº {fornecedor.endereco?.numero},&nbsp;
              {fornecedor.endereco?.bairro}, &nbsp;
              {fornecedor.endereco?.cidade}-{fornecedor.endereco?.estado},
              &nbsp;
              {fornecedor.endereco?.cep} &nbsp;
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FornecedorXmlStep;
