import {
  PessoaService,
  ProdutosService,
  history,
  notification,
} from "../../../../../services";
import nfEntradaXmlValidator from "../../middlewares/nf-entrada-xml.validator";
import { CadastroNfEntradaXml } from "../../entities";
import { responseErros } from "../../../../../utils";
import { useImportContext } from "../../../../../contexts/import.context";
import {
  FornecedorXmlStep,
  ProdutosXmlStep,
  SelecionarArquivoXmlStep,
  TransportadoraXmlStep,
} from "../steps";
import nfEntradaItensXmlValidator from "../../middlewares/nf-entrada-itens-xml.validator";

const pessoaService = new PessoaService();
const produtosService = new ProdutosService();

const ImportarXmlStepper = ({
  activeStep,
  setActiveStep,
  setOpenImportarXMLDialog,
  arquivoSelecionado,
  setArquivoSelecionado,
  dadosXML,
  setDadosXML,
  xmlFile,
  setXmlFile,
  buscarXmlData,
  buscarTransportadora,
  buscarProdutos,
  listaProdutos,
  setListaProdutos,
  produtoSelecionado,
  vincularProdutoExistente,
  setVincularProdutoExistente,
  converterEntrada,
  setConverterEntrada,
  gruposTributarios,
  gruposProdutos,
  grupoTributario,
  setGrupoTributario,
  grupoProduto,
  setGrupoProduto,
  fornecedor,
  transportadora,
  enderecoFornecedor,
  colunasProdutos,
  temProdutosNaoCadastrados,
  setTemProdutosNaoCadastrados,
  loading,
  setLoading,
  loadingAutocomplete,
  setLoadingAutocomplete,
  temTransportadora,
  pagamentos,
  inputErros,
  setFornecedor,
  formatarProdutos,
  handleCloseDialog,
  setProdutosConvertidos,
}) => {
  const { nfEntradaDto, setNfEntradaDto } = useImportContext();

  const handleXmlChange = (event) => {
    if (event.target) {
      setXmlFile(event.target.files[0]);
      setArquivoSelecionado(true);
    } else {
      setXmlFile(event[0]);
      setArquivoSelecionado(true);
    }
  };

  const initXmlClose = () => {
    setXmlFile(null);
    setGrupoTributario(null);
    setGrupoProduto(null);
    setArquivoSelecionado(false);
    setOpenImportarXMLDialog(false);
  };

  const handleImportarXml = async (e) => {
    if (!xmlFile) {
      return notification.alertaGenericos(
        "Por favor, selecione um arquivo XML"
      );
    }
    await buscarXmlData(e);
  };

  const handleAvancarStep = async () => {
    if (activeStep === 2) {
      validacaoNfEntradaXml(() => {
        setActiveStep(activeStep + 1);
        verificaProdutos(activeStep + 1);
        setLoading(false);
      });
    } else {
      setActiveStep(activeStep + 1);
      verificaProdutos(activeStep + 1);
    }
  };

  function verificaProdutos(step) {
    if ((!temTransportadora && step !== 1) || (temTransportadora && step !== 2))
      return;
    if (temProdutosNaoCadastrados && !nfEntradaDto?.usoConsumoImportado) {
      return notification.alertaGenericos(`Existem produtos não cadastrados! 
          Cadastre ou vincule a produtos existentes clicando no ícone 
          de link à direita do item`);
    }
  }

  const handleCadastrarFornecedor = async () => {
    const body = {
      ...fornecedor,
      enderecos: [enderecoFornecedor],
    };
    setLoading(true);
    const response = await pessoaService.cadastrarPessoaConfirmacao(body);
    if (!response.isAxiosError) {
      setFornecedor({ ...fornecedor, ...response.data });
      formatarProdutos(dadosXML.itens, response.data.id);
      notification.cadastroSucesso();
    } else responseErros(response);
    setLoading(false);
  };

  const handleCadastrarTransportadora = async () => {
    setLoading(true);
    const response = await pessoaService.cadastrarPessoaConfirmacao(
      transportadora
    );
    if (!response.isAxiosError) {
      buscarTransportadora(transportadora);
      notification.cadastroSucesso();
    } else responseErros(response);
    setLoading(false);
  };

  const validacaoNfEntradaXml = (callback) => {
    const body = {
      ...dadosXML,
      fornecedor: {
        ...fornecedor,
        endereco: {
          ...enderecoFornecedor,
          cep: Number(enderecoFornecedor.cep),
          pessoaId: fornecedor.id,
        },
      },
    };
    setLoading(true);
    nfEntradaXmlValidator
      .validate(body, { abortEarly: false })
      .then(callback)
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleSubmit = () => {
    validacaoNfEntradaXml(async () => {
      await cadastrarProdutos(dadosXML.itens);
      setLoading(false);
    });
  };

  const cadastrarProdutos = async (dto) => {
    const produtos = dto.filter((produto) => !produto.produtoCadastrado);
    const response = await produtosService.cadastrarListaProdutos(produtos);
    if (!response.isAxiosError) {
      setLoading(false);
      mapearProdutosCadastrados(response?.data);
    } else {
      responseErros(response);
      setLoading(false);
    }
  };

  const mapearProdutosCadastrados = (itensDoBanco) => {
    const novoDadosXML = {
      ...dadosXML,
      itens: [
        ...dadosXML.itens.filter((produto) => produto.produtoCadastrado),
        ...itensDoBanco.map((produto) => {
          const produtoExistente = dadosXML.itens.find(
            (item) =>
              item.codigoProduto === produto.fornecedores[0]?.codigoProduto &&
              parseFloat(item.precoCompra) === produto.precoCompra &&
              item.descricao === produto.descricao
          );
          const {
            ordem,
            cfop,
            cfopId,
            quantidade,
            subtotal,
            descricao,
            tributosIcmsAliquota,
            tributosIpiAliquota,
            impostoIcmsStAliquota,
            aliquotaMva,
            unidade,
          } = produtoExistente;
          return {
            ordem,
            cfop: cfop || produto?.cfop,
            cfopId: cfopId || produto?.cfopId,
            quantidade,
            produto: descricao || produto?.descricao,
            subtotal,
            ...produto,
            produtoId: produto.id,
            tributosIcmsAliquota,
            tributosIpiAliquota,
            impostoIcmsStAliquota,
            aliquotaMva,
            unidade,
            produtoCadastrado: true,
          };
        }),
      ],
    };
    setDadosXML(novoDadosXML);
    setTemProdutosNaoCadastrados(false);
    setGrupoProduto(null);
    setGrupoTributario(null);
    return notification.sucessoGenericos(
      "Cadastro de produtos realizado com sucesso!"
    );
  };

  const finalizarImportacao = async () => {
    const body = {
      ...dadosXML,
      transportadora,
      fornecedor: {
        ...fornecedor,
        endereco: {
          ...enderecoFornecedor,
          cep: Number(enderecoFornecedor.cep),
          pessoaId: fornecedor.id,
        },
      },
      pagamentos,
    };
    setLoading(true);
    nfEntradaItensXmlValidator
      .validate(body?.itens, { abortEarly: false })
      .then(async () => {
        setLoading(false);
        const nfEntradaDtoXml = new CadastroNfEntradaXml(body);
        const nfEntradaDtoTemp = {
          ...nfEntradaDto,
          ...nfEntradaDtoXml,
        };
        setNfEntradaDto(nfEntradaDtoTemp);
        history.push("/estoque/nf-entrada/create");
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  switch (activeStep) {
    case 0:
      return (
        <FornecedorXmlStep
          fornecedor={fornecedor}
          enderecoFornecedor={enderecoFornecedor}
          handleCloseDialog={handleCloseDialog}
          handleAvancarStep={handleAvancarStep}
          handleCadastrarFornecedor={handleCadastrarFornecedor}
        />
      );
    case 1:
      if (temTransportadora) {
        return (
          <TransportadoraXmlStep
            transportadora={transportadora}
            handleCloseDialog={handleCloseDialog}
            handleAvancarStep={handleAvancarStep}
            handleCadastrarTransportadora={handleCadastrarTransportadora}
          />
        );
      } else {
        return (
          <ProdutosXmlStep
            converterEntrada={converterEntrada}
            setConverterEntrada={setConverterEntrada}
            vincularProdutoExistente={vincularProdutoExistente}
            buscarProdutos={buscarProdutos}
            listaProdutos={listaProdutos}
            setListaProdutos={setListaProdutos}
            setVincularProdutoExistente={setVincularProdutoExistente}
            produtoSelecionado={produtoSelecionado}
            setTemProdutosNaoCadastrados={setTemProdutosNaoCadastrados}
            dadosXML={dadosXML}
            setDadosXML={setDadosXML}
            gruposProdutos={gruposProdutos}
            grupoProduto={grupoProduto}
            setGrupoProduto={setGrupoProduto}
            gruposTributarios={gruposTributarios}
            grupoTributario={grupoTributario}
            setGrupoTributario={setGrupoTributario}
            handleCloseDialog={handleCloseDialog}
            temProdutosNaoCadastrados={temProdutosNaoCadastrados}
            finalizarImportacao={finalizarImportacao}
            colunasProdutos={colunasProdutos}
            loading={loading}
            nfEntradaDto={nfEntradaDto}
            handleAvancarStep={handleAvancarStep}
            handleSubmit={handleSubmit}
            setProdutosConvertidos={setProdutosConvertidos}
            loadingAutocomplete={loadingAutocomplete}
            setLoadingAutocomplete={setLoadingAutocomplete}
          />
        );
      }
    case 2:
      return (
        <ProdutosXmlStep
          converterEntrada={converterEntrada}
          setConverterEntrada={setConverterEntrada}
          vincularProdutoExistente={vincularProdutoExistente}
          buscarProdutos={buscarProdutos}
          listaProdutos={listaProdutos}
          setListaProdutos={setListaProdutos}
          setVincularProdutoExistente={setVincularProdutoExistente}
          produtoSelecionado={produtoSelecionado}
          setTemProdutosNaoCadastrados={setTemProdutosNaoCadastrados}
          dadosXML={dadosXML}
          setDadosXML={setDadosXML}
          gruposProdutos={gruposProdutos}
          grupoProduto={grupoProduto}
          setGrupoProduto={setGrupoProduto}
          gruposTributarios={gruposTributarios}
          grupoTributario={grupoTributario}
          setGrupoTributario={setGrupoTributario}
          handleCloseDialog={handleCloseDialog}
          temProdutosNaoCadastrados={temProdutosNaoCadastrados}
          finalizarImportacao={finalizarImportacao}
          colunasProdutos={colunasProdutos}
          loading={loading}
          nfEntradaDto={nfEntradaDto}
          handleAvancarStep={handleAvancarStep}
          handleSubmit={handleSubmit}
          setProdutosConvertidos={setProdutosConvertidos}
          loadingAutocomplete={loadingAutocomplete}
          setLoadingAutocomplete={setLoadingAutocomplete}
        />
      );
    default:
      return (
        <SelecionarArquivoXmlStep
          arquivoSelecionado={arquivoSelecionado}
          xmlFile={xmlFile}
          handleXmlChange={handleXmlChange}
          initXmlClose={initXmlClose}
          handleImportarXml={handleImportarXml}
        />
      );
  }
};

export default ImportarXmlStepper;
