import { useState, useEffect } from "react";
import { SessionService } from "../../services";
import "./mini-print-layout.css";
import shortid from "shortid";
import {
  formatCelular,
  formatCep,
  formatCnpj,
  formatCpf,
  formatPrice,
  formatTelefone,
} from "../../utils";
import { format } from "date-fns";

const MiniPrintLayoutFaturamento = ({ data, descricaoTipoDocumento }) => {
  const sessionService = new SessionService();
  const [empresa, setEmpresa] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };
  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  return empresa && data ? (
    <div id="conteudo_mini">
      <div className="d-flex justify-content-center align-items-center">
        {empresa?.imagemLogo ? (
          <img
            src={empresa.imagemLogo}
            alt="Logo Empresa"
            onLoad={aspectLogo}
            style={{
              width: "100px",
              height: aspectRatio <= 1.5 ? "100px" : "20px",
              margin: "5px 0 5px",
            }}
          />
        ) : null}
      </div>
      <div className="text-center">
        <h6 className="text-uppercase fw-medium">{empresa.fantasia}</h6>
        <h6 className="text-uppercase">
          {empresa?.cnpjCpf ? `CNPJ: ${formatCnpj(empresa.cnpjCpf)}` : ""}
        </h6>
        <h6 className="text-uppercase">
          {empresa.enderecoLogradouro}, {empresa.enderecoLogradouroNumero},{" "}
          {empresa.enderecoBairro} - {empresa.enderecoCidade} -{" "}
          {empresa.enderecoUF}
        </h6>
      </div>
      <div className="text-center">
        <h6 className="fw-bold text-uppercase">
          {`${descricaoTipoDocumento ?? "Documento"} Nº ${
            data.numeroDocumento
          }`}
          {data?.serieDocumento ? ` Série: ${data.serieDocumento}` : ""}
          <br />
          <h6 className="text-uppercase fw-medium">
            {data?.dataEmissao
              ? format(
                  new Date(Date.parse(data.dataEmissao)),
                  "dd/MM/yyyy HH:MM"
                )
              : ""}
          </h6>
        </h6>
      </div>
      {Object.keys(data?.cliente ?? {}).length > 0 ? (
        <div>
          <div className="text-center">
            <br />
            <h6 className="fw-bold text-center text-uppercase">
              Dados do Cliente
            </h6>
          </div>
          <div className="m-1 text-center">
            <h6 className="fw-medium">{data.cliente?.nomeRazaoSocial}</h6>
            <h6 className="text-uppercase">
              {data.cliente?.naturezaSocial === "fisica"
                ? `CPF: ${formatCpf(data.cliente?.cnpjCpf)}`
                : `CNPJ: ${formatCnpj(data.cliente?.cnpjCpf)}`}
            </h6>
            <h6 className="text-uppercase">
              {data.enderecoCliente
                ? ` ${data.enderecoCliente?.logradouro}, ${data.enderecoCliente?.numero}
              ${data.enderecoCliente?.bairro} - ${data.enderecoCliente?.cidade}`
                : ""}
              {data.enderecoCliente?.estado
                ? ` - ${data.enderecoCliente.estado}`
                : ""}
              <br />
              {data.enderecoCliente?.cep
                ? `CEP - ${formatCep(String(data.enderecoCliente?.cep))}`
                : ""}
              <br />
              {data.cliente?.telefone
                ? ` TEL:${formatTelefone(data.cliente.telefone)} `
                : ""}
              {data.cliente?.celular
                ? ` CEL: ${formatCelular(data.cliente.celular)}`
                : ""}
            </h6>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <br />
        <h6 className="fw-bold text-center text-uppercase">Produtos</h6>
        <table className="table">
          <thead>
            <tr className="itens_border">
              <th className="texto_header" style={{ width: 10 }}>
                CÓD
              </th>
              <th className="texto_header">DESC</th>
              <th className="texto_header">QTD</th>
              <th className="texto_header">UN</th>
              <th className="texto_header">UNIT</th>
              <th className="texto_header">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {data?.[
              descricaoTipoDocumento == "nf-saida" ? "nfSaidaItens" : "itens"
            ]?.length
              ? data[
                  descricaoTipoDocumento == "nf-saida"
                    ? "nfSaidaItens"
                    : "itens"
                ].map((item) => {
                  return (
                    <>
                      <tr
                        style={{
                          border: 0,
                          borderColor: "white",
                        }}
                        key={item.id}
                      >
                        <td
                          className="itens_text"
                          style={{ width: 35, textAlign: "center" }}
                        >
                          {item.codigo}
                        </td>
                        <td
                          className="itens_text descricao_produto"
                          colSpan={5}
                        >
                          <p className="descricao_produto">
                            {item.descricao.substring(0, 35)}
                          </p>
                        </td>
                      </tr>
                      <tr className="itens_border">
                        <td className="itens_text" style={{ width: 35 }}></td>
                        <td className="itens_text" colSpan={1}></td>
                        <td className="itens_text" colSpan={1}>
                          {parseFloat(item.quantidade).toFixed(2)}
                        </td>
                        <td className="itens_text" colSpan={1}>
                          {item.unidade}
                        </td>
                        <td className="itens_text" colSpan={1}>
                          {formatPrice(
                            item[
                              descricaoTipoDocumento == "nf-saida"
                                ? "valor"
                                : "valorUnitario"
                            ]
                          )}
                        </td>
                        <td className="itens_text" colSpan={1}>
                          {formatPrice(
                            item[
                              descricaoTipoDocumento == "nf-saida"
                                ? "subtotal"
                                : "totalItem"
                            ]
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <div style={{ width: "100%", padding: "0.5rem 0.5rem" }}>
        <div className="list_valores">
          <h6>QTD. TOTAL DE ITENS</h6>
          <h6 className="valor_list">
            {
              data?.[
                descricaoTipoDocumento == "nf-saida" ? "nfSaidaItens" : "itens"
              ].length
            }
          </h6>
        </div>
        <div className="list_valores">
          <h6>VALOR TOTAL </h6>
          <h6 className="valor_list">
            {formatPrice(
              parseFloat(
                data?.[
                  descricaoTipoDocumento == "nf-saida"
                    ? "totalMercadoria"
                    : "totalItens"
                ].toFixed(2)
              ),
              false
            ) ?? "0,00"}
          </h6>
        </div>
        <div className="list_valores">
          <h6>Acréscimos</h6>
          <h6 className="valor_list">
            {formatPrice(data?.acrescimo, false) ?? "0,00"}
          </h6>
        </div>
        <div className="list_valores">
          <h6>Descontos </h6>
          <h6 className="valor_list">
            - {formatPrice(data?.desconto, false) ?? "0,00"}
          </h6>
        </div>
        <div className="list_valores">
          <h6>Despesas </h6>
          <h6 className="valor_list">
            {formatPrice(data?.despesas, false) ?? "0,00"}
          </h6>
        </div>
        <div className="list_valores">
          <h6 className="fw-semibold">VALOR A PAGAR </h6>
          <h6 className="fw-semibold valor_list">
            {formatPrice(
              (parseFloat(
                data?.[
                  descricaoTipoDocumento == "nf-saida"
                    ? "totalMercadoria"
                    : "totalItens"
                ].toFixed(2)
              ) ?? 0) -
                (data?.desconto ?? 0) +
                (data?.acrescimo ?? 0) +
                (data?.despesas ?? 0),
              false
            )}
          </h6>
        </div>
      </div>
      {data?.[
        descricaoTipoDocumento == "nf-saida"
          ? "nfSaidaPagamentos"
          : "pagamentos"
      ]?.length ? (
        <div style={{ width: "100%", padding: "0.5rem 0.5rem" }}>
          <div className="text-center">
            <br />
            <h6 className="fw-bold text-center text-uppercase">Pagamentos</h6>
          </div>
          <div className="list_valores">
            <h6>FORMA DE PAGAMENTO</h6>
            <h6 className="valor_list">Valor Pago</h6>
          </div>
          {data[
            descricaoTipoDocumento == "nf-saida"
              ? "nfSaidaPagamentos"
              : "pagamentos"
          ].map((pag) => {
            const pagamento = data.meioPagamentoList.find(
              (meio) => meio.id === pag?.meioPagamentoId
            );
            const descricao = pagamento ? pagamento.descricao : "";
            return (
              <div className="list_valores" key={shortid.generate()}>
                <h6>{descricao}</h6>
                <h6 className="valor_list">
                  {formatPrice(pag?.valorTitulo, false)}
                </h6>
              </div>
            );
          })}
          <div className="list_valores">
            <h6>Troco </h6>
            <h6 className="valor_list">
              {formatPrice(
                parseFloat(
                  data?.[
                    descricaoTipoDocumento == "nf-saida"
                      ? "totalMercadoria"
                      : "totalItens"
                  ].toFixed(2)
                ) -
                  (data?.desconto ?? 0) +
                  (data?.acrescimo ?? 0) +
                  (data?.despesas ?? 0) -
                  parseFloat(
                    data?.[
                      descricaoTipoDocumento == "nf-saida"
                        ? "nfSaidaPagamentos"
                        : "pagamentos"
                    ].reduce((acumulador, objetoAtual) => {
                      return (
                        acumulador + parseFloat(objetoAtual?.valorTitulo ?? 0)
                      );
                    }, 0)
                  ),
                false
              )}
            </h6>
          </div>
        </div>
      ) : (
        <></>
      )}
      {data?.informacaoAdicional && (
        <div className="text-center">
          <br />
          <h6 className="fw-bold text-center text-uppercase">Observações</h6>
          <h6>{data.informacaoAdicional || data.informacoesAdicionais}</h6>
        </div>
      )}
      <div>
        <br />
        <table className="table">
          <thead>
            <tr className="itens_border">
              <th className="texto_header"></th>
            </tr>
          </thead>
          <tbody>
            <div className="text-center">
              <h6 className="fw-bold text-center text-uppercase">
                Assinatura do Cliente
              </h6>
            </div>
          </tbody>
        </table>
      </div>
      {data?.status === "CANCELADA" && (
        <span className="danfe_cancelada">CANCELADA</span>
      )}
    </div>
  ) : (
    <div></div>
  );
};
export default MiniPrintLayoutFaturamento;
