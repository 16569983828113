import {
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from "../../../components";
import { history } from "../../../services";

const MenuConfig = ({ anchorEl, setAnchorEl }) => {
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-config-menu";

  const handleConfigClose = () => {
    setAnchorEl(null);
  };

  const handleEditarCadUser = () => {
    handleConfigClose();
    history.push("/painel-controle/usuarios");
  };

  const handleEditarConfig = () => {
    handleConfigClose();
    history.push("/painel-controle/configuracoes");
  };

  const onClickPerfilUsuario = () => {
    handleConfigClose();
    history.push("/painel-controle/perfis-usuarios");
  };

  return (
    <Menu
      anchorEl={anchorEl}
      elevation={1}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      id={menuId}
      autoFocus={false}
      open={isMenuOpen}
      onClose={handleConfigClose}
      className="m-3"
    >
      <MenuItem className="mb-1" onClick={handleEditarCadUser}>
        <ListItemIcon style={{ minWidth: 42 }}>
          <i
            className="ph-fill ph-users"
            style={{ fontSize: 23, marginRight: 16 }}
          ></i>
        </ListItemIcon>
        Cadastro de Usuários
      </MenuItem>
      <Divider className="mb-2" variant="middle" />
      <MenuItem className="mb-1" onClick={onClickPerfilUsuario}>
        <ListItemIcon style={{ minWidth: 42 }}>
          <i
            className="ph-fill ph-identification-badge"
            style={{ fontSize: 23, marginRight: 16 }}
          ></i>
        </ListItemIcon>
        Perfil de Usuários
      </MenuItem>
      <Divider className="mb-2" variant="middle" />
      <MenuItem className="mb-8" onClick={handleEditarConfig}>
        <i
          className="ph-fill ph-gear"
          style={{ fontSize: 23, marginRight: 16 }}
        ></i>
        <ListItemText>Configurações</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default MenuConfig;
