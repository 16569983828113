import { useState, useEffect } from "react";
import { Grid } from "../../../../components";
import VerificationInput from "react-verification-input";
import "./recuperar-senha.style.scss";

const SteapCodigoRecuperacao = ({
  setCodigoExpirado,
  codigoExpirado,
  setCodigoRecuperacao,
}) => {
  const [tempoExperacaoCodigo, setTempoExperacaoCodigo] = useState("10:00");

  useEffect(() => {
    contarTempoExpiracaoCodigo();
  }, []);

  function contarTempoExpiracaoCodigo() {
    let minutos = 9;
    let segundos = 60;
    const interval = setInterval(() => {
      segundos--;
      if (segundos == 0) {
        if (minutos == 0) {
          clearInterval(interval);
          setCodigoExpirado(true);
          return;
        }
        segundos = 60;
        minutos--;
      }
      const segundosFormatado = segundos < 10 ? `0${segundos}` : segundos;
      setTempoExperacaoCodigo(`${minutos}:${segundosFormatado}`);
    }, 1000);
  }

  const onCompleteCodigoValidacao = (value) => {
    setCodigoRecuperacao(Number(value));
  };

  return (
    <Grid container spacing={1} justifyContent="center" className="py-4">
      <Grid item xs={10}>
        <h5>Digite abaixo o código de recuperação enviado por email</h5>
      </Grid>
      <Grid item xs={9} className="d-flex justify-content-center">
        <VerificationInput
          autoFocus
          onComplete={onCompleteCodigoValidacao}
          classNames={{
            container: "validationInput",
            character: "validationInput-caracter",
          }}
          validChars="z0-9"
        />
      </Grid>
      <Grid item xs={9} className="d-flex justify-content-center mt-1">
        <span className="text-danger">
          {codigoExpirado
            ? "Código de recuperação expirado"
            : `Código de recuperação expira em ${tempoExperacaoCodigo}`}
        </span>
      </Grid>
    </Grid>
  );
};

export default SteapCodigoRecuperacao;
