import { useState, useRef } from "react";
import { AddLayout } from "../../../layouts";
import {
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Button,
  DataGrid,
  Loading,
  IconButton,
} from "../../../components";
import {
  SaldoInicialService,
  ProdutosService,
  notification,
} from "../../../services";
import { responseErros, userLogs, voltar } from "../../../utils";
import { useEffect } from "react";
import { PermisoesHelper } from "../../../helpers";

const AddSaldoInicialView = ({ match }) => {
  const { id } = match.params;
  const [userLog, setUserLog] = useState({});
  const [listaProdutos, setListaProdutos] = useState([]);
  const [ajusteEstoque, setAjusteEstoque] = useState({ produtos: [] });
  const [isProdutoEdit, setIsProdutoEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [produto, setProduto] = useState(null);
  const [item, setItem] = useState({});
  const inputRef = useRef();
  const produtoService = new ProdutosService();
  const saldoInicialService = new SaldoInicialService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("saldo-inicial-visualizar");
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 120,
    },
    {
      field: "descricao",
      headerName: "Produto",
      flex: 700,
    },
    {
      field: "estoque",
      headerName: "Estoque",
      flex: 200,
    },
    {
      field: "acoes",
      headerName: "Ações",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          params.row.itemNovo && (
            <div className="d-flex align-items-center">
              <IconButton
                onClick={() => {
                  handleEditarProduto(params);
                }}
              >
                <i
                  className="ph-fill ph-pencil"
                  style={{ color: "#494C62", fontSize: 18 }}
                ></i>
              </IconButton>
              <IconButton
                onClick={() => {
                  handleRemoverProduto(params);
                }}
              >
                <i
                  className="ph-fill ph-trash"
                  style={{ color: "#494C62", fontSize: 18 }}
                ></i>
              </IconButton>
            </div>
          )
        );
      },
    },
  ];

  useEffect(() => {
    buscarAjusteEstoque();
  }, []);

  async function buscarAjusteEstoque() {
    if (!id) return;
    setLoading(true);
    const result = await saldoInicialService.getById(id);
    if (!result.isAxiosError) {
      setAjusteEstoque({
        ...result.data,
        produtos: result.data.produtos.map((obj) => ({
          ...obj,
          ...obj.produto,
        })),
      });
    } else {
      responseErros(result);
    }
    setLoading(false);
  }

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(ajusteEstoque);
    setUserLog(logs);
  }

  function handleChangeAutocompleteProduto(value) {
    setProduto(value);
    setItem((prevState) => ({
      ...prevState,
      id: value?.id,
      codigo: value?.codigo,
      descricao: value?.descricao,
      itemNovo: true,
    }));
  }

  function buscarProdutos(filtros) {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    produtoService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutoComplete(false);
        setListaProdutos(result.data.rows);
      } else {
        setLoadingAutoComplete(false);
        responseErros(result);
      }
    });
  }

  const handleAdicionarProduto = async () => {
    if (!produto) {
      return notification.alertaGenericos(
        "Selecione um produto para adicionar"
      );
    }
    if (item?.editado) {
      const itemIndex = ajusteEstoque.produtos.findIndex(
        (obj) => obj.id == item.id
      );
      setAjusteEstoque({
        produtos: ajusteEstoque.produtos.map((obj, index) => {
          if (index == itemIndex) {
            return item;
          }
          return obj;
        }),
      });
    } else {
      setAjusteEstoque({
        produtos: [...ajusteEstoque.produtos, item],
      });
    }
    document.getElementById("produtoId").focus();
    setProduto(null);
    setItem({});
    setListaProdutos([]);
    setIsProdutoEdit(false);
  };

  const handleEditarProduto = (params) => {
    setIsProdutoEdit(true);
    produtoService.getById(params.row.id).then((result) => {
      if (!result.isAxiosError) {
        setProduto(result.data);
        setItem({ ...params.row, editado: true });
      } else {
        responseErros(result);
      }
    });
  };

  const handleRemoverProduto = async (params) => {
    const produtos = ajusteEstoque.produtos.filter(
      (item) => item.id !== params.row.id
    );
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${params.row.descricao}?`
    );
    if (retornoAlerta.isConfirmed) {
      setAjusteEstoque({ produtos });
    }
  };

  const handleInputChange = (e) => {
    setItem((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    if (id) {
      const produtos = ajusteEstoque.produtos.filter((item) => item.itemNovo);
      if (!produtos.length)
        return notification.alertaGenericos(
          "Insira um novo produto na lista para atualizar"
        );
      const response = await saldoInicialService.atualizar(id, { produtos });
      setLoading(false);
      if (!response.isAxiosError) {
        notification.alteracaoSucesso();
        voltar();
      } else {
        responseErros(response);
      }
    } else {
      const response = await saldoInicialService.cadastrar(ajusteEstoque);
      setLoading(false);
      if (!response.isAxiosError) {
        notification.cadastroSucesso();
        voltar();
      } else responseErros(response);
    }
  };

  function onInputChangeProdutos(event) {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          codigoBarra: eventValue,
          descricao: eventValue,
        };
        setLoadingAutoComplete(true);
        buscarProdutos(filtros);
      }
    }
  }

  return (
    <AddLayout
      id={id}
      title={id ? "Editar Saldo Inicial" : "Cadastrar Saldo Inicial"}
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={id ? userLog : null}
      onClickLog={onClickLog}
      submitSimples
      showUpdate={permissoesHelper.temPermisao("saldo-inicial-editar")}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="produtoId"
              name="produtoId"
              options={listaProdutos}
              autoHighlight
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              getOptionLabel={(option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                if (option.codigoBarra) {
                  descricaoProduto += ` - ${option.codigoBarra}`;
                }
                return option ? descricaoProduto : "";
              }}
              filterSelectedOptions
              onChange={(_, newValue) =>
                handleChangeAutocompleteProduto(newValue)
              }
              value={produto}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label="Buscar produto"
                  onKeyDown={(e) => onInputChangeProdutos(e)}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="estoque"
            name="estoque"
            label="Estoque"
            variant="outlined"
            margin="normal"
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            value={item?.estoque ? item.estoque : ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          {isProdutoEdit ? (
            <Button
              id="buttonAdicionar"
              variant="contained"
              size="small"
              className="mt-4"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto, item)}
            >
              <i
                className="ph-bold ph-arrows-clockwise"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          ) : (
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="success"
              size="small"
              className="mt-4"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto, item)}
            >
              <i
                className="ph-bold ph-plus"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={ajusteEstoque?.produtos}
            columns={colunas}
            rowCount={ajusteEstoque?.produtos?.length}
            disableAcoes
          />
        </Grid>
      </Grid>
      <Loading loading={loading} />
    </AddLayout>
  );
};

export default AddSaldoInicialView;
