import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { ImportContextProvider } from "../contexts/import.context";
import lightTheme from "../styles/light-theme";
import PrivateRoutes from "./PrivateRoutes";
import CurrentRoutes from "./CurrentRoutes";
import GlobalStyles from "../styles/global";
import {
  LoginView,
  Inicio,
  CadastrarEmpresaView,
  CadastrarContadorView,
  LoadingView,
  NotFoundView,
} from "../views";

const Routes = () => {
  const location = useLocation();
  let scroll = location.pathname.includes("relatorios");

  const getCurrentRoutes = () => CurrentRoutes;

  return (
    <ThemeProvider theme={lightTheme}>
      <ImportContextProvider>
        <GlobalStyles themeMode={lightTheme} scroll={scroll} />
        <Switch>
          <Route
            render={(matchProps) => (
              <CadastrarEmpresaView {...matchProps}></CadastrarEmpresaView>
            )}
            path="/cadastros/empresa"
          />
          <Route
            render={(matchProps) => (
              <CadastrarContadorView {...matchProps}></CadastrarContadorView>
            )}
            path="/cadastros/contador"
          />
          <Route
            exact={true}
            render={(matchProps) => <Inicio {...matchProps}></Inicio>}
            path="/"
          />
          <Route
            render={(matchProps) => <LoadingView {...matchProps}></LoadingView>}
            path="/loading"
          />
          <Route
            render={(matchProps) => <LoginView {...matchProps}></LoginView>}
            path="/login"
          />
          {getCurrentRoutes().map((route) => (
            <PrivateRoutes
              key={route.path}
              component={route.component}
              layout={route.layout}
              path={route.path}
              title={route.title}
              exact={route.exact}
            />
          ))}
          <Route render={() => <NotFoundView />} />
        </Switch>
      </ImportContextProvider>
    </ThemeProvider>
  );
};

export default Routes;
