import "./footer-totalizadores-detalhado.scss";

export default function FooterTotalizadoresRelatorioNfSaida({ totalizadores }) {
  const formatarChaveLegenda = (chave) => {
    const formatado = chave.replace(/([a-z])([A-Z])/g, "$1 $2");
    const maiusculo = formatado
      .split(" ")
      .map((chave) => chave.charAt(0).toUpperCase() + chave.slice(1))
      .join(" ");
    return `${maiusculo}:`;
  };
  return (
    <footer className="footer-relatorios">
      <table className="table table-borderless table-sm">
        <tbody>
          {Object.entries(totalizadores).map(([key, value], index) => {
            return (
              <tr key={`totalizador-${index}`}>
                <td className="footer-legenda-bold">
                  {formatarChaveLegenda(key)}
                </td>
                <td width="40" className="footer-valor-bold">
                  {parseFloat(value).toFixed(2)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </footer>
  );
}
