import { format } from "date-fns";
import {
  TablePrintDetalhadoComponent,
  Typography,
} from "../../../../components";
import { formatDate, formatPrice } from "../../../../utils";
import { FooterTotalizadoresRelatorioCaixa } from "./components";

const PrintCaixaDetalhado = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Data",
      render: ({ data }) => format(formatDate.received(data), "dd/MM/yyyy"),
      field: "data",
      fieldTotal: "totalSaida",
      renderTotal: ({ totalSaida, totalEntrada }) =>
        `Saldo: ${formatPrice(totalEntrada - totalSaida)}`,
    },
    {
      field: "categoriaMovimentacao",
      header: "Tipo",
      render: ({ categoriaMovimentacao }) => {
        switch (categoriaMovimentacao) {
          case "SUPRIMENTO":
            return "Suprimento";
          case "SANGRIA":
            return "Sangria";
          case "TRANSFERENCIA":
            return "Transferência";
          default:
            return "Título Normal";
        }
      },
    },
    {
      field: "numerotitulo",
      header: "Nº Documento",
    },
    {
      field: "clientefornecedor",
      header: "Cliente/Fornecedor",
      render: ({ clientefornecedor }) =>
        clientefornecedor ? clientefornecedor.substring(0, 15) : "",
    },
    {
      field: "meioPagamentoDescricao",
      header: "Meio Pag.",
      align: "center",
      render: ({ meioPagamentoDescricao }) =>
        meioPagamentoDescricao && meioPagamentoDescricao.toUpperCase(),
    },
    {
      field: "entrada",
      header: "Entrada",
      render: (value) => {
        if (value.modelo != "DESPESA" || value.tipo == "ENTRADA") {
          return formatPrice(value.valor, false);
        } else return "0,00";
      },
      fieldTotal: "totalEntrada",
      align: "end",
      renderTotal: ({ totalEntrada }) =>
        `Entradas: ${formatPrice(totalEntrada)}`,
    },
    {
      field: "saida",
      header: "Saída",
      render: (value) => {
        if (value.modelo == "DESPESA" || value.tipo == "SAIDA") {
          return formatPrice(value.valor, false);
        } else return "0,00";
      },
      fieldTotal: "totalSaida",
      align: "end",
      renderTotal: ({ totalSaida }) => `Saídas: ${formatPrice(totalSaida)}`,
    },
  ];

  return (
    <TablePrintDetalhadoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.caixa}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      headerRelatorio={headerRelatorio}
      footerTotalizador={
        <FooterTotalizadoresRelatorioCaixa
          totalizadores={relatoriosTotais.totalizadores}
        />
      }
    />
  );
};

function headerRelatorio(caixa) {
  return (
    <div>
      Caixa:
      <Typography variant="h4" className="mb-1 text-uppercase font-weight-bold">
        {caixa.descricao}
      </Typography>
    </div>
  );
}

export default PrintCaixaDetalhado;
