import { FormControlLabel as FormControlLabelMui } from "@mui/material";

const FormControlLabel = ({
  className,
  children,
  control,
  checked,
  classes,
  componentsProps,
  disabled,
  disableTypography,
  inputRef,
  label,
  labelPlacement,
  onChange,
  required,
  slotProps,
  sx,
  value,
}) => {
  return (
    <FormControlLabelMui
      control={control}
      checked={checked}
      classes={classes}
      componentsProps={componentsProps}
      p={disabled}
      disableTypography={disableTypography}
      inputRef={inputRef}
      className={className}
      label={label}
      labelPlacement={labelPlacement}
      onChange={onChange}
      slotProps={slotProps}
      sx={sx}
      value={value}
      required={required}
    >
      {children}
    </FormControlLabelMui>
  );
};

export default FormControlLabel;
