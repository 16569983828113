import * as Yup from "yup";

const documentoFreteNfeValidator = Yup.object().shape({
  serieNfe: Yup.number().nullable(),
  numeroNfe: Yup.number().nullable(),
  valorNfe: Yup.number()
    .min(0, "O valor da NF-e deve ser maior ou igual a 0")
    .required("Valor da NF-e é obrigatório"),
  pesoNfe: Yup.number()
    .min(0, "O peso da NF-e deve ser maior ou igual a 0")
    .required("Peso da NF-e é obrigatório"),
  chaveNfe: Yup.string()
    .max(44, "A chave da NF-e deve ter no máximo 44 caracteres")
    .nullable(),
});

export default documentoFreteNfeValidator;
