import { useState } from "react";
import { Button, ButtonGroup, Menu, MenuItem } from "../../components";
import shortid from "shortid";

const MenuButtonList = ({ botaoPrincipal, opcoes, className, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function onClick() {
    setAnchorEl(null);
  }

  return (
    <>
      <ButtonGroup size="small" className={className} disabled={disabled}>
        <Button
          size="small"
          id="button"
          aria-controls={open ? "menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          onClick={botaoPrincipal?.onClick}
        >
          {botaoPrincipal?.label}
        </Button>
        <Button variant="outlined" size="small" onClick={handleClick}>
          <i className="ph-fill ph-caret-down" style={{ fontSize: 18 }}></i>
        </Button>
      </ButtonGroup>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onClick}
      >
        {opcoes.map((option) => (
          <MenuItem
            onClick={option?.onClick}
            disabled={option?.disabled}
            key={shortid.generate()}
          >
            {option?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButtonList;
