export class MeioPagamento {
  constructor(body) {
    this.descricao = body.descricao;
    this.baixaAutomatica = body.baixaAutomatica;
    this.bancoCaixaId = body.bancoCaixaId;
    this.cartaoCreditoDebito = body.cartaoCreditoDebito;
    this.gerarCheque = body.gerarCheque;
    this.conciliacaoAutomatica = body.conciliacaoAutomatica;
    this.disponivelPdv = body.disponivelPdv;
    this.gerarBoleto = body.gerarBoleto;
    this.nfePagamento = body.nfePagamento;
    this.ativado = body.ativado;
  }

  getBancoCaixaId() {
    return this.bancoCaixaId;
  }

  setBancoCaixaId(value) {
    this.bancoCaixaId = value;
  }
}
