import { Container as ContainerMui } from "@mui/material";

const Container = ({
  className,
  style,
  children,
  fixed,
  classes,
  maxWidth,
  sx,
}) => {
  return (
    <ContainerMui
      className={className}
      style={style}
      fixed={fixed ? true : false}
      classes={classes}
      maxWidth={maxWidth}
      sx={sx}
    >
      {children}
    </ContainerMui>
  );
};

export default Container;
