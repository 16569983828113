import { useState } from "react";
import {
  CardHeader,
  Dialog,
  Button,
  DatePicker,
  Grid,
  DialogActions,
  DialogContent,
} from "../../../../components";
import { SessionService, NfeService, notification } from "../../../../services";
import { responseErros } from "../../../../utils";
import fileDownload from "js-file-download";

const BaixarXmlDialog = ({ openBaixarXmlDialog, setOpenBaixarXmlDialog }) => {
  const [data, setData] = useState(null);
  const sessionService = new SessionService();
  const nfeService = new NfeService();
  const BAD_REQUEST = 400;

  const onClickFechar = () => {
    setOpenBaixarXmlDialog(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const empresa = sessionService.getEmpresa();
    const ano = data.getFullYear();
    const mes = data.getMonth() + 1;
    const res = await nfeService.baixarXml(empresa.cnpjCpf, ano, mes);
    onClickFechar();
    if (!res.isAxiosError) {
      fileDownload(res.data, `xml-${ano}-${mes}.zip`);
    } else {
      if (res.response?.status == BAD_REQUEST) {
        notification.alertaGenericos(
          `Diretório do mês ${mes} não foi encontrado.`
        );
      } else {
        responseErros(res);
      }
    }
  };

  const onChangeDate = (date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setData(date != "Invalid Date" ? date : null);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openBaixarXmlDialog}
      onClose={onClickFechar}
    >
      <CardHeader title="Baixar XML" />
      <form onSubmit={(event) => onSubmit(event)}>
        <DialogContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <DatePicker
                required
                shrink
                label="Mês/Ano"
                placeholder="Selecione Mês e Ano"
                format="MM/yyyy"
                views={["month", "year"]}
                onChange={(date) => onChangeDate(date)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Baixar
          </Button>
          <Button
            onClick={() => onClickFechar()}
            variant="contained"
            color="inherit"
          >
            Fechar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BaixarXmlDialog;
