import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import { GrupoProdutosService, ProdutosService } from "../../../../services";
import { InputErros, value } from "../../../../helpers";
import { formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";

const grupoProdutosService = new GrupoProdutosService();

const FormFiltroAvancado = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const [grupoProdutoList, setGrupoProdutoList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const inputErros = new InputErros(inputErro, setInputErro);
  const produtoService = new ProdutosService();
  const inputRef = useRef();
  const listaTipos = [
    {
      label: "Simples",
      value: "simples",
    },
    {
      label: "Grade",
      value: "grade",
    },
    {
      label: "Kit",
      value: "kit",
    },
  ];

  useEffect(() => {
    if (filtrosAvancados?.produtoId) {
      buscarProdutos({ id: filtrosAvancados.produtoId });
    }
    buscarGruposProduto();
  }, []);

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.value ?? null,
    }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.descricao : undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Inicial", "Final")] = format(
          date,
          "dd/MM/yyyy"
        );
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Final", "Inicial")] = format(
          date,
          "dd/MM/yyyy"
        );
      }
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  const buscarGruposProduto = () => {
    const filtros = {
      nonPaginated: true,
    };
    grupoProdutosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setGrupoProdutoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onInputChangeProdutos = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          codigoBarra: eventValue,
          descricao: eventValue,
        };
        setLoadingAutoComplete(true);
        buscarProdutos(filtros);
      }
    }
  };

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    produtoService.getAllFiltroAvancado(filtro).then((result) => {
      setLoadingAutoComplete(false);
      if (!result.isAxiosError) {
        setListaProdutos(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DatePicker
          id="dataInicial"
          name="Data-Inicial"
          label="Data Inicial"
          format="dd/MM/yyyy"
          value={valueDate("dataInicial")}
          onChange={(date) => onChangeDate("dataInicial", date, "Data-Inicial")}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataFinal"
          name="Data-Final"
          label="Data Final"
          format="dd/MM/yyyy"
          value={valueDate("dataFinal")}
          onChange={(date) => onChangeDate("dataFinal", date, "Data-Final")}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" margin="normal" fullWidth>
          <Autocomplete
            id="grupoProdutoId"
            name="grupoProdutoId"
            options={grupoProdutoList}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) => (option ? option.descricao : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grupo de Produto"
                variant="outlined"
                error={inputErros.get("grupoProdutoId")}
              />
            )}
            onChange={(_, newValue) => {
              setInputErro([]);
              onChangeAutocomplete(
                "grupoProdutoId",
                newValue,
                "Grupo de Produtos"
              );
            }}
            value={valueAutocomplete(grupoProdutoList, "grupoProdutoId")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" margin="normal" fullWidth>
          <Autocomplete
            id="produtoId"
            name="produtoId"
            options={listaProdutos}
            autoHighlight
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText="Digite e pressione Enter"
            getOptionLabel={(option) =>
              option.descricao ? `${option?.codigo} - ${option?.descricao}` : ""
            }
            filterSelectedOptions
            onChange={(_, newValue) =>
              onChangeAutocomplete("produtoId", newValue, "Produto")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant={"outlined"}
                label="Produto"
                onKeyDown={(e) => onInputChangeProdutos(e)}
                inputRef={inputRef}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            value={value.autoComplete(
              listaProdutos,
              filtrosAvancados.produtoId
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Tipo"
          id="tipo"
          name="tipo"
          variant="outlined"
          value={valueInput("tipo")}
          margin="normal"
          onChange={(event) => onChangeSelect(event, "Tipo")}
        >
          <MenuItem value="">
            <p></p>
          </MenuItem>
          {listaTipos.map((tipo) => (
            <MenuItem key={tipo.value} value={tipo.value}>
              {tipo.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
