import { Dialog as DialogMui } from "@mui/material";

const Dialog = ({
  open,
  ariadescribedby,
  arialabelledby,
  children,
  classes,
  disableEscapeKeyDown,
  fullScreen,
  fullWidth,
  maxWidth,
  onClose,
  PaperComponent,
  PaperProps,
  scroll,
  sx,
  TransitionComponent,
  transitionDuration,
  TransitionProps,
}) => {
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    onClose(event, reason);
  };
  return (
    <DialogMui
      open={open ?? false}
      aria-describedby={ariadescribedby}
      aria-labelledby={arialabelledby}
      classes={classes}
      disableEscapeKeyDown={disableEscapeKeyDown ?? false}
      fullScreen={fullScreen ?? false}
      fullWidth={fullWidth ?? false}
      maxWidth={maxWidth ?? "sm"}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      PaperProps={PaperProps}
      scroll={scroll ?? "paper"}
      sx={sx}
      TransitionComponent={TransitionComponent}
      transitionDuration={transitionDuration}
      TransitionProps={TransitionProps}
    >
      {children}
    </DialogMui>
  );
};

export default Dialog;
