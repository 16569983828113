import { DialogContent as DialogContentMui } from "@mui/material";

const DialogContent = ({ children, classes, dividers = false, sx }) => {
  return (
    <DialogContentMui classes={classes} dividers={dividers ?? false} sx={sx}>
      {children}
    </DialogContentMui>
  );
};

export default DialogContent;
