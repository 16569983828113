import { TableRow as TableRowMui } from "@mui/material";

const TableRow = ({
  className,
  children,
  classes,
  component,
  hover,
  ref,
  selected,
  sx,
}) => {
  return (
    <TableRowMui
      className={className}
      classes={classes}
      component={component}
      hover={hover}
      ref={ref}
      selected={selected}
      sx={sx}
    >
      {children}
    </TableRowMui>
  );
};

export default TableRow;
