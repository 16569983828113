import SiafLiteApiRepository from "../../external/siafLiteApi";

class TipoDesoneracaoService {
  constructor() {
    this.urlBase = "/fiscal/tipo-desoneracao";
  }

  getAll() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }
}

export default TipoDesoneracaoService;
