export function validarLucro(produtoTemp, configuracoes) {
  if (
    !produtoTemp.precoCompra &&
    !produtoTemp.lucroDesejado &&
    !produtoTemp.precoCusto &&
    produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoCompra = produtoTemp.precoVenda;
    produtoTemp.precoCusto = produtoTemp.precoVenda;
  }
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    !produtoTemp.lucroDesejado
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoVenda = produtoTemp.precoCusto;
  }
  if (produtoTemp.precoCompra && produtoTemp.precoCusto) {
    produtoTemp.precoVenda = calculoPrecoVenda(produtoTemp, configuracoes);
  }
  return produtoTemp;
}

export function validarPrecoVenda(produtoTemp, configuracoes, produto) {
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    !produtoTemp.lucroDesejado &&
    !produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoVenda = produtoTemp.precoCusto;
  }
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = calculoLucro(
      produtoTemp,
      configuracoes,
      produto
    );
  }
  return produtoTemp;
}

export function calculoPrecoVenda(produtoTemp, configuracoes) {
  if (produtoTemp.precoCusto) {
    if (configuracoes?.margemCalculo === "INVERSA") {
      const lucro =
        parseFloat(produtoTemp.lucroDesejado) >= 100
          ? (produtoTemp.lucroDesejado = 99.99)
          : parseFloat(produtoTemp.lucroDesejado);
      return parseFloat(produtoTemp.precoCusto) / (1 - lucro / 100);
    } else {
      return (
        parseFloat(produtoTemp.precoCusto) +
        parseFloat(produtoTemp.precoCusto) *
          (parseFloat(produtoTemp.lucroDesejado) / 100)
      );
    }
  }
}

export function calculoLucro(produtoTemp, configuracoes, produto) {
  if (produto.precoCusto) {
    if (configuracoes?.margemCalculo === "INVERSA") {
      const calculoLucroInverso =
        (1 -
          parseFloat(produtoTemp.precoCusto) /
            parseFloat(produtoTemp.precoVenda)) *
        100;
      return calculoLucroInverso >= 0 ? calculoLucroInverso : 0;
    } else {
      const calculoLucroConvencional =
        ((parseFloat(produtoTemp.precoVenda) -
          parseFloat(produtoTemp.precoCusto)) /
          parseFloat(produtoTemp.precoCusto)) *
        100;
      return calculoLucroConvencional >= 0 ? calculoLucroConvencional : "";
    }
  }
}

export const calcularValorComissaoVendedor = (produto, percentual) => {
  const valorComissaoVendedor = (produto?.precoVenda || 0) * (percentual / 100);
  return {
    ...produto,
    valorComissaoVendedor,
  };
};

export const calcularPercentualComissaoVendedor = (produto, valor) => {
  const percentualComissaoVendedor =
    produto.precoVenda > 0 ? (valor * 100) / produto.precoVenda : 0;
  return {
    ...produto,
    percentualComissaoVendedor,
  };
};

export const calcularPrecoCusto = (produto, valueCompra, configuracoes) => {
  const produtoTemp = {
    ...produto,
    precoVenda: valueCompra,
    precoCusto: valueCompra,
  };
  if (valueCompra && valueCompra !== "") {
    produtoTemp.precoVenda = calculoPrecoVenda(produtoTemp, configuracoes);
    return produtoTemp;
  } else return produto;
};

export const calcularPrecoCompra = (produto, valueCompra) => {
  if (
    parseFloat(valueCompra) > parseFloat(produto.precoCusto) ||
    produto.precoCusto === "" ||
    produto.precoCusto === produto.precoCompra
  ) {
    return {
      ...produto,
      precoCompra: valueCompra,
      precoCusto: valueCompra,
    };
  } else {
    return produto;
  }
};
