import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { SaldoInicialService, history } from "../../../services";
import { DataGrid } from "../../../components";
import { responseErros } from "../../../utils";
import { format } from "date-fns";
import { PermisoesHelper } from "../../../helpers";

const ListSaldoInicialView = () => {
  const [loading, setLoading] = useState(false);
  const [listaAjustes, setListaAjustes] = useState([]);
  const saldoInicialService = new SaldoInicialService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("saldo-inicial-visualizar");
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 77,
    },
    {
      field: "createdAt",
      headerName: "Data cadastro",
      flex: 1000,
      valueGetter: (params) =>
        format(new Date(params.value), "dd/MM/yyyy HH:mm"),
    },
  ];

  useEffect(() => {
    buscarAjusteEstoque();
  }, []);

  async function buscarAjusteEstoque() {
    setLoading(true);
    const result = await saldoInicialService.getAll();
    if (!result.isAxiosError) {
      setListaAjustes(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  }

  function onClickCadastrarAjusteEstoque() {
    history.push("/estoque/saldo-inicial/create");
  }

  function onClickEditarAjusteEstoque(id) {
    history.push(`/estoque/saldo-inicial/${id}`);
  }

  return (
    <ListLayout
      title="Saldo Inicial"
      loading={loading}
      onClickCadastrar={onClickCadastrarAjusteEstoque}
      permissaoButton={permissoesHelper.temPermisao("saldo-inicial-cadastrar")}
    >
      <DataGrid
        rows={listaAjustes}
        columns={colunas}
        rowCount={listaAjustes.length}
        onClickEdit={onClickEditarAjusteEstoque}
        visualizarShow={permissoesHelper.temPermisao(
          "saldo-inicial-visualizar"
        )}
        editShow={permissoesHelper.temPermisao("saldo-inicial-editar")}
      />
    </ListLayout>
  );
};

export default ListSaldoInicialView;
