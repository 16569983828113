import { useState, useEffect } from "react";
import { SessionService } from "../../../../../../services";
import {
  CabecalhoNfe,
  CartaCorrecaoInfoNfe,
  CondicoesUso,
  Correcao,
  DestinatarioRemetenteNfe,
  EmitenteNfe,
  NfeInfo,
} from "./components";
import "./modelo-carta-correcao.scss";
import shortid from "shortid";

const ModeloCartaCorrecao = ({ data }) => {
  const sessionService = new SessionService();
  const [empresa, setEmpresa] = useState(null);

  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  return empresa && data ? (
    <div id="conteudo_nfe" key={shortid.generate()}>
      <CabecalhoNfe empresa={empresa} />
      <NfeInfo data={data} />
      <CartaCorrecaoInfoNfe data={data} />
      <EmitenteNfe data={data} />
      <DestinatarioRemetenteNfe data={data} />
      <CondicoesUso />
      <Correcao data={data} />
    </div>
  ) : null;
};

export default ModeloCartaCorrecao;
