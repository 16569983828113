import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  ListItemButton,
  Switch,
  Typography,
} from "../../../../components";
import { descreverPermissao, getPalavraTraduzida } from "../add-perfil-usuario";
import { icones } from "../resources/constantes";

export default function PermissoesFormComponent({
  permissoesModulos,
  setPermissoesModulos,
}) {
  const handlePermissaoChange = (idPermissao, habilitado) => {
    setPermissoesModulos((prevState) => {
      return Object.keys(prevState).reduce((acc, secao) => {
        acc[secao] = Object.keys(prevState[secao]).reduce(
          (subAcc, subSecao) => {
            subAcc[subSecao] = Object.keys(prevState[secao][subSecao]).reduce(
              (permAcc, nomePermissao) => {
                const permissao = prevState[secao][subSecao][nomePermissao];
                if (permissao.pctPermissoesModuloId === idPermissao) {
                  permAcc[nomePermissao] = { ...permissao, habilitado };
                } else {
                  permAcc[nomePermissao] = permissao;
                }
                return permAcc;
              },
              {}
            );
            return subAcc;
          },
          {}
        );
        return acc;
      }, {});
    });
  };

  const handleSecaoChange = (secao, habilitado) => {
    setPermissoesModulos((prevState) => {
      const updatedState = { ...prevState };
      Object.keys(updatedState[secao]).forEach((subSecao) => {
        Object.keys(updatedState[secao][subSecao]).forEach((permissao) => {
          updatedState[secao][subSecao][permissao].habilitado = habilitado;
        });
      });
      return updatedState;
    });
  };

  const handleSubSecaoChange = (subSecao, habilitado) => {
    setPermissoesModulos((prevState) => {
      const updatedState = { ...prevState };
      Object.keys(updatedState).forEach((secao) => {
        if (updatedState[secao] && updatedState[secao][subSecao]) {
          Object.keys(updatedState[secao][subSecao]).forEach(
            (nomePermissao) => {
              updatedState[secao][subSecao][nomePermissao] = {
                ...updatedState[secao][subSecao][nomePermissao],
                habilitado,
              };
            }
          );
        }
      });
      return updatedState;
    });
  };

  const subSecaoAtivada = (secao, subSecao) => {
    return Object.values(permissoesModulos[secao][subSecao]).some(
      (permissao) => permissao.habilitado
    );
  };

  const secaoAtivada = (secao) => {
    return Object.values(permissoesModulos[secao]).some((subSecao) =>
      Object.values(subSecao).some((permissao) => permissao.habilitado)
    );
  };

  return (
    <Grid container spacing={2}>
      {Object.entries(permissoesModulos).map(([secao, subSecoes]) => (
        <Grid item xs={12} key={secao}>
          <Card
            sx={{
              mb: "2rem",
              borderRadius: "1rem",
            }}
          >
            <Grid container spacing={2} sx={{ p: "2.5rem 3rem 1rem 4.2rem" }}>
              <ListItemButton
                sx={{ borderRadius: "1rem" }}
                disableRipple
                onClick={() => {
                  document.getElementById(`ativar-${secao}`)?.click();
                }}
              >
                <Grid
                  item
                  xs={9}
                  id={`secao-${secao}`}
                  className="d-flex align-items-center"
                >
                  <i
                    className={icones[secao]}
                    style={{
                      color: "#263238",
                      fontSize: 26,
                      marginRight: "1rem",
                    }}
                  ></i>
                  <Typography variant="h3">
                    <strong>
                      {secao === "dashboard"
                        ? "PÁGINA INICIAL"
                        : getPalavraTraduzida(secao)}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} className="d-flex justify-content-end">
                  <FormControlLabel
                    control={
                      <Switch
                        id={`ativar-${secao}`}
                        checked={secaoAtivada(secao)}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) =>
                          handleSecaoChange(secao, event.target.checked)
                        }
                      />
                    }
                  />
                </Grid>
              </ListItemButton>
            </Grid>
            <Grid container spacing={2}>
              {Object.entries(subSecoes).map(([subSecao, permissoes]) => (
                <Grid item xs={12} key={subSecao}>
                  <Card
                    sx={{
                      p: "0 1rem 0 2rem",
                      ml: "2rem",
                      borderRadius: "1rem",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2} id={`sub-secao-${subSecao}`}>
                        <ListItemButton
                          sx={{ mx: "1rem", borderRadius: "1rem" }}
                          disableRipple
                          onClick={() => {
                            document
                              .getElementById(`ativar-${secao}-${subSecao}`)
                              ?.click();
                          }}
                        >
                          <Grid item xs={9}>
                            <Typography variant="h5">
                              <strong>{getPalavraTraduzida(subSecao)}</strong>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className="d-flex justify-content-end"
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  id={`ativar-${secao}-${subSecao}`}
                                  checked={subSecaoAtivada(secao, subSecao)}
                                  onClick={(event) => event.stopPropagation()}
                                  onChange={(event) =>
                                    handleSubSecaoChange(
                                      subSecao,
                                      event.target.checked
                                    )
                                  }
                                />
                              }
                            />
                          </Grid>
                        </ListItemButton>
                      </Grid>
                      <Grid container spacing={1}>
                        {Object.entries(permissoes).map(
                          ([nomePermissao, permissao]) => (
                            <Grid
                              item
                              xs={12}
                              key={permissao.pctPermissoesModuloId}
                              className="d-flex align-items-center justify-content-center mx-3"
                              id={`permissao-${nomePermissao}`}
                            >
                              <ListItemButton
                                sx={{ borderRadius: "1rem" }}
                                onClick={() =>
                                  handlePermissaoChange(
                                    permissao.pctPermissoesModuloId,
                                    !permissao.habilitado
                                  )
                                }
                                disableRipple
                              >
                                <Grid item xs={9}>
                                  <Typography>
                                    {descreverPermissao(
                                      nomePermissao,
                                      subSecao
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  className="d-flex justify-content-end"
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={permissao.habilitado}
                                        onClick={(event) =>
                                          event.stopPropagation()
                                        }
                                        onChange={(event) =>
                                          handlePermissaoChange(
                                            permissao.pctPermissoesModuloId,
                                            event.target.checked
                                          )
                                        }
                                      />
                                    }
                                  />
                                </Grid>
                              </ListItemButton>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
