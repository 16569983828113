import "../modelo-carta-correcao.scss";

const CartaCorrecaoInfoNfe = ({ data, empresa }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">CARTA DE CORREÇÃO ELETRÔNICA</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Orgão</p>
              <p className="danfe_celula_valor">{empresa?.enderecoUF}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Ambiente</p>
              <p className="danfe_celula_valor valor_negrito">
                {empresa?.producao ? "Produção" : "Homologação"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Data do Evento</p>
              <p className="danfe_celula_valor">{data.correcao?.createdAt}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Status</p>
              <p className="danfe_celula_valor">
                {data.correcao?.status} - {data.correcao?.motivo}
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Evento</p>
              <p className="danfe_celula_valor">110110</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Descrição do Evento</p>
              <p className="danfe_celula_valor">Carta de Correcao</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Protocolo</p>
              <p className="danfe_celula_valor">{data.correcao?.protocolo}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Data do Registro</p>
              <p className="danfe_celula_valor">{data.correcao?.createdAt}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CartaCorrecaoInfoNfe;
