import SiafLiteApiRepository from "../../external/siafLiteApi";

class ClassificacaoFinanceiraService {
  constructor() {
    this.urlBase = "/financeiro/classificacoes-financeiras";
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  getAllFiltroAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }
}

export default ClassificacaoFinanceiraService;
