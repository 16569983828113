import { IconButton, TextField, InputAdornment } from "../../../components";

const Pesquisar = ({
  filtros,
  setQueryBusca,
  sendServer,
  colunas,
  busca,
  setbusca,
  setPage,
  tipoFiltro,
  setTipoFiltro,
  colunaFiltroSimples,
}) => {
  const colunaFiltroApp = colunas.find((coluna) => coluna.filtroPessoa);
  const pesquisar = (e) => {
    e.preventDefault();
    setPage(0);
    setQueryBusca({});
    setTipoFiltro("simples");
    filtros.page = "1";
    if (busca) {
      let queryBusca = {};
      if (colunaFiltroSimples) {
        queryBusca[colunaFiltroSimples.field] = busca;
      }
      setQueryBusca(queryBusca);
      const retorno = {
        tipoFiltro,
        filtros: {
          ...filtros,
          ...queryBusca,
        },
      };
      if (colunaFiltroApp) {
        retorno.filtroPessoa = busca;
      }
      sendServer(retorno);
    } else {
      setQueryBusca({});
      sendServer({ tipoFiltro, filtro: filtros });
    }
  };

  const labelPesquisar = () => {
    const label = colunaFiltroSimples
      ? `Pesquisar por ${colunaFiltroSimples.headerName}`
      : "Pesquisar";
    return colunaFiltroApp ? `${label} / ${colunaFiltroApp.headerName}` : label;
  };

  return (
    <form
      className="mb-3 mt-1 align-items-center justify-content-start"
      onSubmit={(e) => pesquisar(e)}
    >
      <TextField
        id="pesquisar"
        label={labelPesquisar()}
        fullWidth
        value={busca}
        margin="normal"
        onChange={(e) => setbusca(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={pesquisar}>
                <i
                  className="ph-bold ph-magnifying-glass"
                  style={{ color: "#494C62", fontSize: 18 }}
                ></i>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default Pesquisar;
