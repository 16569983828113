import { CfopService, notification } from "../../../services";

const cfopService = new CfopService();
export function editarCelulaCfop(params, event, setDadosXML) {
  if (event.key === "Enter") {
    let { value } = event.target;
    if (/^[4-6]/.test(value)) {
      value = Infinity;
    }
    cfopService.getAll({ codigo: value }).then((result) => {
      if (!result.isAxiosError && result.data.count && value.length === 4) {
        setDadosXML((prevState) => {
          const itensTemp = prevState.itens.map((item) => {
            if (item.id === params.row.id || item[params.field] === "") {
              return {
                ...item,
                [params.field]: value ?? "",
                cfopId: result.data.rows[0]?.id,
              };
            }
            return item;
          });
          return { ...prevState, itens: itensTemp };
        });
      } else {
        setDadosXML((prevState) => {
          const itensTemp = prevState.itens.map((item) => {
            if (item.id === params.row.id) {
              return {
                ...item,
                [params.field]: "",
              };
            } else {
              return item;
            }
          });
          return { ...prevState, itens: itensTemp };
        });
        return value == Infinity
          ? notification.alertaGenericos(
              "Apenas CFOP com inicial 1, 2 ou 3 são permitidos"
            )
          : notification.alertaGenericos("CFOP não encontrado");
      }
    });
  }
}
