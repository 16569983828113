import { useState } from "react";
import {
  Box,
  CardHeader,
  Dialog,
  Step,
  StepLabel,
  Stepper,
} from "../../../../components";
import AddGradeStepper from "./add-grade-stepper.component";
import { gradeValidator } from "../middlewares";
import { notification } from "../../../../services";

const AddGradeDialog = ({
  id,
  open,
  setOpen,
  activeStep,
  setActiveStep,
  produtosGradeListFiltrados,
  setProdutosGradeListFiltrados,
  produtosGradeList,
  setProdutosGradeList,
  produtoGradeColunas,
  produtosGradeListSelecao,
  setProdutosGradeListSelecao,
}) => {
  const steps = ["Selecionar Grade", "Inserir Codigo de Barras"];
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  function handleVoltarStep() {
    setActiveStep(0);
  }
  function handleAvancarStep() {
    const lista = produtosGradeListSelecao.filter((produto) =>
      itensSelecionados.some((id) => produto.id === id)
    );
    setProdutosGradeListFiltrados(lista);
    setActiveStep(1);
  }

  function handleInitAddGrade() {
    setOpen(false);
    setProdutosGradeListSelecao([]);
    setActiveStep(0);
  }

  function handleFormarGrade() {
    gradeValidator
      .validate(produtosGradeListFiltrados, { abortEarly: false })
      .then(() => {
        setRowModesModel({});
        if (id) {
          const resultado = produtosGradeList.reduce((acc, item) => {
            const existingItem = produtosGradeListFiltrados.find(
              (item2) => item2.id === item.id
            );
            acc.push(existingItem || item);
            if (acc.length === produtosGradeList.length && !existingItem) {
              acc.push(...produtosGradeListFiltrados);
            }
            return acc;
          }, []);
          setProdutosGradeList(resultado);
        } else setProdutosGradeList(produtosGradeListFiltrados);
        handleInitAddGrade();
      })
      .catch(() => {
        notification.alertaGenericos(
          "Todos os produtos precisam ter Código de Barra"
        );
      });
  }

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleInitAddGrade}>
      <CardHeader title="Produtos em Grade" className="m-2" />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <AddGradeStepper
        activeStep={activeStep}
        produtoGradeColunas={produtoGradeColunas}
        produtosGradeListSelecao={produtosGradeListSelecao}
        handleAvancarStep={handleAvancarStep}
        handleVoltarStep={handleVoltarStep}
        handleCancelarAddGrade={handleInitAddGrade}
        itensSelecionados={itensSelecionados}
        setItensSelecionados={setItensSelecionados}
        produtosGradeListFiltrados={produtosGradeListFiltrados}
        setProdutosGradeListFiltrados={setProdutosGradeListFiltrados}
        rowModesModel={rowModesModel}
        setRowModesModel={setRowModesModel}
        handleFormarGrade={handleFormarGrade}
      />
    </Dialog>
  );
};

export default AddGradeDialog;
