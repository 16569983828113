import * as Yup from "yup";

const nfSaidaPagamentosValidator = (meioPagamento) =>
  Yup.array()
    .of(
      Yup.object().shape({
        meioPagamentoId: Yup.number().required(),
        condicaoPagamentoId: Yup.number().required(),
        dataVencimento: Yup.date().required(),
        valorTitulo: Yup.number().required(),
        numeroTitulo: Yup.number().required(),
        numeroAutorizacao: meioPagamento?.cartaoCreditoDebito
          ? Yup.string().required(
              "Número de autorização em pagamentos via cartão"
            )
          : Yup.string().optional().nullable(),
      })
    )
    .min(1, "Adicionar ao menos um pagamento")
    .required();

export default nfSaidaPagamentosValidator;
