import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Typography } from "../../components";

const ImpressoraRelatorios = ({ layoutImpressao }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onClickImprimir = async () => {
    handlePrint();
  };

  return (
    <>
      <div className="d-none">
        <div ref={componentRef} style={{ fontSize: "10px" }}>
          {layoutImpressao}
        </div>
      </div>
      <Button
        variant="outlined"
        color="padrao"
        className="m-3"
        sx={{ width: "135px", mt: 1 }}
        onClick={onClickImprimir}
      >
        <i
          className="ph-fill ph-printer"
          style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
        ></i>
        <Typography variant="h6" className="title-fullscreen" color="white">
          Imprimir
        </Typography>
      </Button>
    </>
  );
};

export default ImpressoraRelatorios;
