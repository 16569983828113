const initState = {
  mensagens: [],
  quantidadeMensagemNaoLida: 0,
};

function notificacoes(state = initState, action) {
  switch (action.type) {
    case "SET_MENSAGENS":
      return {
        ...state,
        mensagens: action.mensagens,
        quantidadeMensagemNaoLida: action.quantidadeMensagemNaoLida,
      };

    default:
      return state;
  }
}

export default notificacoes;
