import { format } from "date-fns";
import { formatCnpj, formatCpf } from "../../../../../../../utils";
import "../modelo-nfe-espelho.scss";

const DestinatarioRemetenteNfeEspelho = ({ data }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">Destinatário/Remetente</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td>
              <table className="danfe_tabelas">
                <tbody>
                  <tr>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Nome / Razão Social</p>
                      <p className="danfe_celula_valor">{data?.clienteNome}</p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">CNPJ/CPF</p>
                      <p className="danfe_celula_valor valor_negrito">
                        {data?.clienteCnpjCpf?.length === 11
                          ? formatCpf(data?.clienteCnpjCpf)
                          : formatCnpj(data?.clienteCnpjCpf)}
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Inscrição Estadual</p>
                      <p className="danfe_celula_valor">
                        {data?.cliente?.inscricaoEstadual}
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Data emissão</p>
                      <p className="danfe_celula_valor valor_negrito">
                        {format(new Date(), "dd/MM/yyyy")}&nbsp;
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Endereço</p>
                      <p className="danfe_celula_valor">
                        {data?.nfSaidaEnderecoCliente?.logradouro}
                        {data?.nfSaidaEnderecoCliente?.numero}
                        {data?.nfSaidaEnderecoCliente?.complemento}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Bairro</p>
                      <p className="danfe_celula_valor">
                        {data?.nfSaidaEnderecoCliente?.bairro}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">CEP</p>
                      <p className="danfe_celula_valor">
                        {data?.nfSaidaEnderecoCliente?.cep}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Data saída</p>
                      <p className="danfe_celula_valor">
                        {format(new Date(), "dd/MM/yyyy")}&nbsp;
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Município</p>
                      <p className="danfe_celula_valor">
                        {data?.nfSaidaEnderecoCliente?.cidade}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">UF</p>
                      <p className="danfe_celula_valor">
                        {data?.nfSaidaEnderecoCliente?.estado}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Fone/Fax</p>
                      <p className="danfe_celula_valor">
                        {data?.enderecoDestinatario?.fone}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Hora saída</p>
                      <p className="danfe_celula_valor">
                        {format(new Date(), "HH:mm")}&nbsp;
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DestinatarioRemetenteNfeEspelho;
