import { useState, useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  TextField,
  Grid,
  Card,
  CardContent,
  Switch,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import {
  MeioPagamentoService,
  OperacaoFiscalService,
} from "../../../../services";
import { responseErros } from "../../../../utils";
import "../../configuracoes.style.css";

const ConfiguracaoPdvTab = ({
  configuracao,
  setConfiguracao,
  listaPagamentoPadrao,
  setListaPagamentoPadrao,
  onChangeSwitch,
  onChangeAutocomplete,
}) => {
  const [inputErro, setInputErro] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const meioPagamentoService = new MeioPagamentoService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscarMeiosPagamento();
    buscarOperacoesFiscais();
  }, []);

  const buscarMeiosPagamento = () => {
    const filtro = {
      nonPaginated: true,
      disponivelPdv: true,
      ativado: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
    const pagamentosFiltrados = configuracao?.pagamentosPadroes?.filter((i) => {
      return i.id;
    });
    setConfiguracao({
      ...configuracao,
      pagamentosPadroes: pagamentosFiltrados,
    });
  };

  const buscarOperacoesFiscais = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      nonPaginated: true,
      tipoOperacao: "SAIDA",
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
      restritiva: true,
      porIntervalo: [],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const getMultipleAutocompleteValues = (valores, lista) => {
    if (valores) {
      return valores.map((valor) => value.autoComplete(lista, valor.id));
    }
    return [];
  };

  return (
    <Card className="p-2">
      <CardContent>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            PDV001 - Operação fiscal padrão:
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                id="operacaoFiscalPdvPadraoId"
                name="operacaoFiscalPdvPadraoId"
                noOptionsText="Sem opções"
                options={operacaoFiscalList}
                autoHighlight
                disableClearable
                getOptionLabel={(option) => (option ? option.descricao : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operação Fiscal"
                    variant="outlined"
                    error={inputErros.get("operacaoFiscalPdvPadraoId")}
                    required
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete("operacaoFiscalPdvPadraoId", newValue);
                }}
                value={value.autoComplete(
                  operacaoFiscalList,
                  configuracao?.operacaoFiscalPdvPadraoId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            PDV002 - Operação fiscal secundária:
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                id="operacaoFiscalPdvSecundariaId"
                name="operacaoFiscalPdvSecundariaId"
                noOptionsText="Sem opções"
                options={operacaoFiscalList}
                autoHighlight
                disableClearable
                getOptionLabel={(option) => (option ? option.descricao : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operação Fiscal Sec"
                    variant="outlined"
                    error={inputErros.get("operacaoFiscalPdvSecundariaId")}
                    required
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete(
                    "operacaoFiscalPdvSecundariaId",
                    newValue
                  );
                }}
                value={value.autoComplete(
                  operacaoFiscalList,
                  configuracao?.operacaoFiscalPdvSecundariaId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            PDV003 - Meio de pagamento inicial padrão:
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                required
                id="meioPagamentoInicial"
                name="meioPagamentoInicial"
                noOptionsText="Sem opções"
                options={meioPagamentoList}
                autoHighlight
                disableClearable
                getOptionLabel={(option) => (option ? option.descricao : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meio de pagamento"
                    variant="outlined"
                    error={inputErros.get("meioPagamentoInicial")}
                    required
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete("meioPagamentoInicial", newValue);
                }}
                value={value.autoComplete(
                  meioPagamentoList,
                  configuracao?.meioPagamentoInicial
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={4}>
            PDV004 - Meios de pagamento padronizados:
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              multiple
              name="PagamentosPadroes"
              noOptionsText="Sem opções"
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao ? option.descricao : ""
              }
              getOptionDisabled={() =>
                listaPagamentoPadrao.length > 4 ? true : false
              }
              onChange={(e, newValue) => {
                e.persist();
                setListaPagamentoPadrao(newValue);
              }}
              value={getMultipleAutocompleteValues(
                listaPagamentoPadrao,
                meioPagamentoList
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Pagamentos Padrão"
                  required
                  error={inputErros.get("PagamentosPadroes")}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            PDV005 - Permitir Impressão direta no PDV
          </Grid>
          <Grid item xs={3}>
            <Switch
              id="impressaoDiretaPdv"
              name="impressaoDiretaPdv"
              checked={
                configuracao?.impressaoDiretaPdv
                  ? configuracao?.impressaoDiretaPdv
                  : false
              }
              onChange={onChangeSwitch}
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}></Grid>
      </CardContent>
    </Card>
  );
};
export default ConfiguracaoPdvTab;
