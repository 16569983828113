import { useState } from "react";
import {
  Grid,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Select,
} from "../../../../components";
import { notification, NfSaidaService } from "../../../../services";
import { InputErros, value } from "../../../../helpers";
import { responseErros } from "../../../../utils";
import { AutoSequenciaSerieEntity } from "../../entities";
import inutilizacaoDfeValidator from "../../add-nf-saida/middlewares/inutilizacao-dfe.validator";

const InutilizacaoDfeDialog = ({
  openInutilizacaoDfeDialog,
  setOpenInutilizacaoDfeDialog,
  setLoading,
}) => {
  const [inutilizacaoDfe, setInutilizacaoDfe] = useState({
    modeloDocumento: "55",
    ano: new Date().getFullYear().toString().slice(2),
  });
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const nfSaidaService = new NfSaidaService();

  function handleCloseDialog() {
    setOpenInutilizacaoDfeDialog(false);
    setInutilizacaoDfe({
      modeloDocumento: "55",
      ano: new Date().getFullYear().toString().slice(2),
    });
  }

  const onClickAlterar = () => {
    inutilizacaoDfeValidator
      .validate(inutilizacaoDfe, { abortEarly: false })
      .then(() => alterarSequencia(inutilizacaoDfe))
      .catch((err) => {
        inputErros.set(err);
      });
  };

  async function alterarSequencia(body) {
    const autoSequenciaSerieEntity = new AutoSequenciaSerieEntity(body);
    setLoading(true);
    const result = await nfSaidaService.setCodigoManual(
      autoSequenciaSerieEntity
    );
    setLoading(false);
    if (!result.isAxiosError) {
      inutilizarSequencia(body);
    } else {
      responseErros(result);
    }
  }

  async function inutilizarSequencia(body) {
    setLoading(true);
    const result = await nfSaidaService.inutilizacaoDfe(body);
    setLoading(false);
    if (!result.isAxiosError) {
      notification.alteracaoSucesso();
      handleCloseDialog();
    } else {
      responseErros(result);
    }
  }

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const inutilizacaoTemp = {
      ...inutilizacaoDfe,
      [name]: value,
    };
    if (name === "numeroInicial" || name === "numeroFinal") {
      inutilizacaoTemp[name] = Number(value);
    }
    setInutilizacaoDfe(inutilizacaoTemp);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={openInutilizacaoDfeDialog}>
      <DialogContent>
        <CardHeader title="Inutilização de DF-e" />
        <form onSubmit={(event) => onClickAlterar(event)}>
          <Grid container spacing={2} className="mt-1">
            <Grid item xs={2}>
              <Select
                id="modeloDocumento"
                name="modeloDocumento"
                label="Modelo Documento"
                value={value.text(inutilizacaoDfe?.modeloDocumento)}
                onChange={(event) => handleInputChange(event)}
                required
                error={inputErros.get("modeloDocumento")}
              >
                <MenuItem value={"55"}>55</MenuItem>
                <MenuItem value={"65"}>65</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="serieDocumento"
                name="serieDocumento"
                label="Número de Série"
                variant="outlined"
                required
                type={"number"}
                error={inputErros.get("serieDocumento")}
                onChange={(event) => handleInputChange(event)}
                value={inutilizacaoDfe?.serieDocumento}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="numeroInicial"
                name="numeroInicial"
                label="Número Inicial"
                required
                variant="outlined"
                type="number"
                error={inputErros.get("numeroInicial")}
                onChange={(event) => handleInputChange(event)}
                value={value.text(inutilizacaoDfe?.numeroInicial)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="numeroFinal"
                name="numeroFinal"
                label="Número Final"
                required
                variant="outlined"
                type="number"
                error={inputErros.get("numeroFinal")}
                onChange={(event) => handleInputChange(event)}
                value={value.text(inutilizacaoDfe?.numeroFinal)}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12}>
            <TextField
              id="justificativa"
              name="justificativa"
              variant="outlined"
              required
              label="Justificativa"
              error={inputErros.get("justificativa")}
              onChange={(event) => handleInputChange(event)}
              value={value.text(inutilizacaoDfe?.justificativa)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="m-3">
        <Button
          variant="contained"
          color="padrao"
          onClick={() => handleCloseDialog()}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onClickAlterar()}
        >
          Avançar
          <i
            className="ph-fill ph-caret-right"
            style={{ color: "#ffff", fontSize: 18 }}
          ></i>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InutilizacaoDfeDialog;
