import * as Yup from "yup";

const meuUsuarioValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  senha: Yup.string()
    .min(6, "Senha deve ter entre 6 e 20 caracteres")
    .max(20, "Senha deve ter entre 6 e 20 caracteres")
    .nullable(),
  confirmarSenha: Yup.string().when("senha", (senha, field) => {
    return senha
      ? field
          .required("Confirmar nova senha")
          .oneOf([Yup.ref("senha")], "Confirmar senha deve ser igual a senha")
      : field;
  }),
});

export default meuUsuarioValidator;
