import * as Yup from "yup";

const inutilizacaoDfeValidator = Yup.object().shape({
  ano: Yup.string().required("Ano"),
  modeloDocumento: Yup.string().required("Modelo do Documento"),
  serieDocumento: Yup.string().required("Série"),
  numeroInicial: Yup.number().required("Número Inicial"),
  numeroFinal: Yup.number().required("Número Final"),
  justificativa: Yup.string().required("Justificativa"),
});

export default inutilizacaoDfeValidator;
