import { useState, useEffect } from "react";
import { SessionService } from "../../../../../../services";
import {
  RodapeNfeEspelho,
  CabecalhoNfeEspelho,
  ReciboNfeEspelho,
  DestinatarioRemetenteNfeEspelho,
  ImpostoNfeEspelho,
  TransporteNfeEspelho,
} from "./components";
import "./modelo-nfe-espelho.scss";
import shortid from "shortid";

const ModeloNfeEspelho = ({ data }) => {
  const sessionService = new SessionService();
  const [empresa, setEmpresa] = useState(null);
  const limiteItensPrimeiraPagina = data?.duplicatas?.length > 6 ? 9 : 11;
  const limiteItensPaginasSubsequentes = 27;
  const totalItens = data?.nfSaidaItens?.length;
  const itensAposPrimeiraPagina = totalItens - limiteItensPrimeiraPagina;
  const totalPaginas =
    itensAposPrimeiraPagina > 0
      ? 1 + Math.ceil(itensAposPrimeiraPagina / limiteItensPaginasSubsequentes)
      : 1;

  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  return empresa && data ? (
    <div>
      {Array.from({ length: totalPaginas }).map((_, pageIndex) => {
        const indiceInicial =
          pageIndex === 0
            ? 0
            : limiteItensPrimeiraPagina +
              (pageIndex - 1) * limiteItensPaginasSubsequentes;
        const indiceFinal = Math.min(
          totalItens,
          pageIndex === 0
            ? limiteItensPrimeiraPagina
            : indiceInicial + limiteItensPaginasSubsequentes
        );
        return (
          <div id="conteudo_nfe" key={shortid.generate()}>
            {pageIndex == 0 ? (
              <>
                <ReciboNfeEspelho data={data} />
                <CabecalhoNfeEspelho
                  data={data}
                  empresa={empresa}
                  totalPaginas={totalPaginas}
                  paginaAtual={pageIndex + 1}
                  set
                />
                <DestinatarioRemetenteNfeEspelho data={data} />
                <ImpostoNfeEspelho data={data} />
                <TransporteNfeEspelho data={data} />
              </>
            ) : (
              <CabecalhoNfeEspelho
                data={data}
                empresa={empresa}
                totalPaginas={totalPaginas}
                paginaAtual={pageIndex + 1}
              />
            )}
            <h3 className="danfe_titulo_externo">Dados dos Produtos</h3>
            <table className="danfe_item_cabecalho_tabela">
              <tr>
                <th className="danfe_item_cabecalho">Cod</th>
                <th className="danfe_item_cabecalho" colSpan="4">
                  Descri&ccedil;&atilde;o do Produto/Servi&ccedil;o
                </th>
                <th className="danfe_item_cabecalho">NCM/SH</th>
                <th className="danfe_item_cabecalho">CSOSN</th>
                <th className="danfe_item_cabecalho">CFOP</th>
                <th className="danfe_item_cabecalho">UN</th>
                <th className="danfe_item_cabecalho">Qtde</th>
                <th className="danfe_item_cabecalho">Valor un</th>
                <th className="danfe_item_cabecalho">Valor Total</th>
                <th className="danfe_item_cabecalho">BC ICMS</th>
                <th className="danfe_item_cabecalho">Valor ICMS</th>
                <th className="danfe_item_cabecalho">Valor IPI</th>
                <th className="danfe_item_cabecalho">%ICMS</th>
                <th className="danfe_item_cabecalho">%IPI</th>
              </tr>
              {data?.nfSaidaItens
                .slice(indiceInicial, indiceFinal)
                .map((item) => {
                  return (
                    <tr className="danfe_item" key={shortid.generate()}>
                      <td>{item?.codigo}&nbsp;</td>
                      <td colSpan="4" style={{ textAlign: "start" }}>
                        {item?.descricao}&nbsp;
                      </td>
                      <td>{item?.ncm}&nbsp;</td>
                      <td>{item?.tributosIcmsCsosn}&nbsp;</td>
                      <td>{item.cfop}</td>
                      <td>{parseFloat(item?.valor ?? "0.00").toFixed(2)}</td>
                      <td>
                        {parseFloat(item?.quantidade ?? "0.00").toFixed(2)}
                      </td>
                      <td>{parseFloat(item?.valor ?? "0.00").toFixed(2)}</td>
                      <td>{parseFloat(item?.subtotal ?? "0.00").toFixed(2)}</td>
                      <td>
                        {parseFloat(
                          item?.tributosIcmsBaseCalculoValor ?? "0.00"
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(item?.tributosIcmsValor ?? "0.00").toFixed(
                          2
                        )}
                      </td>
                      <td>
                        {parseFloat(item?.tributosIpiValor ?? "0.00").toFixed(
                          2
                        )}
                      </td>
                      <td>
                        {parseFloat(
                          item?.tributosIcmsAliquota ?? "0.00"
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item?.tributosIpiAliquota ?? "0.00"
                        ).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td className="danfe_item_ultimo" colSpan="14">
                  &nbsp;
                </td>
              </tr>
            </table>
            <span id="danfe_sem_valor">SEM VALOR FISCAL</span>
            {pageIndex == 0 && (
              <RodapeNfeEspelho
                data={data}
                limiteItensPrimeiraPagina={limiteItensPrimeiraPagina}
                totalItens={totalItens}
              />
            )}
          </div>
        );
      })}
    </div>
  ) : null;
};

export default ModeloNfeEspelho;
