import shortid from "shortid";

export class CadastroNfEntradaXml {
  constructor(body) {
    this.documentoImportado = true;
    this.fornecedorId = body?.fornecedor?.id;
    this.cnpj = body?.fornecedor?.cnpjCpf;
    this.modelo = body?.modelo;
    this.serie = body.serie;
    this.numeroNota = body?.numeroNota ?? body.nNF;
    this.dataEmissao = body?.dataEmissao ? body.dataEmissao : new Date();
    this.dataChegada = body?.dataChegada ? body.dataChegada : new Date();
    this.finalidade = body?.finalidade;
    this.modalidadeFrete = body?.modalidadeFrete;
    this.nfeChave = body?.nfeChave ?? body.chNFe;
    this.subtotalDespesas = body?.subtotalDespesas;
    this.frete = body?.frete;
    this.informacoes = body.informacoes;
    this.transportadoraId = body?.transportadora && body?.transportadora?.id;
    this.nfEntradaTributos = body?.nfEntradaTributos ?? {
      baseCofins: body?.nfEntradaTributos?.baseCofins,
      baseFcp: body?.nfEntradaTributos?.baseFcp,
      baseFcpSt: body?.nfEntradaTributos?.baseFcpSt,
      baseIcms: body?.nfEntradaTributos?.baseIcms,
      baseIcmsSt: body?.nfEntradaTributos?.baseIcmsSt,
      baseIpi: body?.nfEntradaTributos?.baseIpi,
      basePis: body?.nfEntradaTributos?.basePis,
      valorCofins: body?.nfEntradaTributos?.valorCofins,
      valorFcp: body?.nfEntradaTributos?.valorFcp,
      valorFcpSt: body?.nfEntradaTributos?.valorFcpSt,
      valorIcms: body?.nfEntradaTributos?.valorIcms,
      valorIcmsSt: body?.nfEntradaTributos?.valorIcmsSt,
      valorIpi: body?.nfEntradaTributos?.valorIpi,
      valorPis: body?.nfEntradaTributos?.valorPis,
    };
    this.nfEntradaPagamentos = body?.pagamentos ? body.pagamentos : [];
    this.nfEntradaItens = body.itens?.length
      ? body.itens.map((item) => ({
          ...item,
          id: shortid.generate(),
          tipo: item?.tipo,
          codigo: item?.codigo,
          ordem: item.ordem,
          produtoId: item.produtoId,
          produto: item.produto || item.descricao,
          unidade: item.unidade,
          desconto: body?.subtotalDesconto
            ? body?.subtotalDesconto / body.itens?.length
            : 0,
          acrescimo: body?.subtotalAcrescimo
            ? body?.subtotalAcrescimo / body.itens?.length
            : 0,
          cfop: item.cfop,
          cfopId: item.cfopId,
          ncm: item.ncmCodigo,
          tributosIcmsOrigem: item.origemMercadoriaId,
          grupoTributacaoId: item.grupoTributacaoId,
          quantidade: item?.quantidade ?? 1,
          precoCompra: item.precoCompra,
          precoCusto: item,
          subtotal: item.subtotal,
        }))
      : undefined;
  }
}
