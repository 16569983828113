import { formatPrice } from "../../../../utils";
import { Grid, Typography } from "../../../../components";

const ResumoTotalContas = ({ totalAReceber }) => {
  return (
    <>
      <Grid container sx={{ mt: "1rem", ml: "2.5rem" }}>
        {Object.keys(totalAReceber).map((key) => {
          const titulo =
            key === "subtotal"
              ? `Subtotal:`
              : `Total ${key.charAt(0).toUpperCase() + key.slice(1)}:`;
          return (
            <>
              <Grid item xs={3} key={key}>
                <Typography variant="h7" className="text-secondary me-2">
                  {titulo}
                  <Typography
                    sx={{ fontSize: "1rem" }}
                    color="white"
                    variant="strong"
                    className="badge bg-primary bg-gradient bg-gradient text-wrap p-2 m-2"
                  >
                    {formatPrice(totalAReceber[key] ?? 0) || "R$ 0,00"}
                  </Typography>
                </Typography>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default ResumoTotalContas;
