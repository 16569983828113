import { TableBody as TableBodyMui } from "@mui/material";

const TableBody = ({ className, children, classes, component, ref, sx }) => {
  return (
    <TableBodyMui
      className={className}
      classes={classes}
      component={component}
      ref={ref}
      sx={sx}
    >
      {children}
    </TableBodyMui>
  );
};

export default TableBody;
