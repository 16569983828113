import { formatCnpj, formatCpf } from "../../../../../../../utils";
import "../modelo-carta-correcao.scss";

const DestinatarioRemetenteNfe = ({ data }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">Destinatário/Remetente</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Nome / Razão Social</p>
              <p className="danfe_celula_valor">
                {data.cliente?.nomeRazaoSocial}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">CNPJ/CPF</p>
              <p className="danfe_celula_valor valor_negrito">
                {data.cliente?.cnpjCpf?.length === 11
                  ? formatCpf(data.cliente?.cnpjCpf)
                  : formatCnpj(data.cliente?.cnpjCpf)}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Inscrição Estadual</p>
              <p className="danfe_celula_valor">
                {data.cliente?.inscricaoEstadual}
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Endereço</p>
              <p className="danfe_celula_valor">
                {data.cliente?.enderecoCliente?.logradouro}
                {data.cliente?.enderecoCliente?.numero}
                {data.cliente?.enderecoCliente?.complemento}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Bairro</p>
              <p className="danfe_celula_valor">
                {data.cliente?.enderecoCliente?.bairro}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">CEP</p>
              <p className="danfe_celula_valor">
                {data.cliente?.enderecoCliente?.cep}&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Município</p>
              <p className="danfe_celula_valor">
                {data.cliente?.enderecoCliente?.cidade}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">UF</p>
              <p className="danfe_celula_valor">
                {data.cliente?.enderecoCliente?.estado}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Fone/Fax</p>
              <p className="danfe_celula_valor">
                {data.cliente?.enderecoCliente?.fone}&nbsp;
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DestinatarioRemetenteNfe;
