import { formatCnpj, formatCpf } from "../../../../../../../utils";
import "../modelo-carta-correcao.scss";

const EmitenteNfe = ({ empresa }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">EMITENTE</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td className="danfe_celula_bordas" colSpan="2">
              <p className="danfe_celula_titulo">Nome / Razão Social</p>
              <p className="danfe_celula_valor">{empresa?.razaoSocial}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">CNPJ/CPF</p>
              <p className="danfe_celula_valor valor_negrito">
                {empresa?.cnpjCpf?.length === 11
                  ? formatCpf(empresa?.cnpjCpf)
                  : formatCnpj(empresa?.cnpjCpf)}
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas" colSpan="2">
              <p className="danfe_celula_titulo">Endereço</p>
              <p className="danfe_celula_valor">
                {empresa?.enderecoLogradouro} -
                {empresa?.enderecoLogradouroNumero}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Bairro</p>
              <p className="danfe_celula_valor">
                {empresa?.enderecoBairro}&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Município</p>
              <p className="danfe_celula_valor">
                {empresa?.enderecoCidade}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">UF</p>
              <p className="danfe_celula_valor">{empresa?.enderecoUF}&nbsp;</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Fone/Fax</p>
              <p className="danfe_celula_valor">{empresa?.telefone}&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmitenteNfe;
