import { Divider, Typography, MenuItem, Grid } from "../../../components";
import { history } from "../../../services";
import "../topbar.style.css";
import CurrentRoutes from "../../../routes/CurrentRoutes";
import shortid from "shortid";

const MenuSearchResultado = ({ searchTerm, setAnchorSearchEl }) => {
  const handleAcessarPagina = (path) => {
    handleSearchMenuClose();
    history.push(path);
  };

  const handleSearchMenuClose = () => {
    setAnchorSearchEl(null);
  };

  return (
    <div className="menuContainer">
      {CurrentRoutes.filter(
        (x) =>
          x.podeExibirNaBusca === true &&
          (x.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            x.descricao.toLowerCase().includes(searchTerm.toLowerCase()))
      ).map((item) => (
        <>
          <MenuItem
            key={shortid.generate()}
            onClick={() => {
              handleAcessarPagina(item.path);
            }}
            className="menuItem"
          >
            <Grid container>
              <Grid item md={12}>
                <h5>{item.title}</h5>
              </Grid>
              <Grid item md={12}>
                <Typography variant="body2" className="searchMenuDescription">
                  {item.descricao}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <Divider />
        </>
      ))}
    </div>
  );
};

export default MenuSearchResultado;
