import SiafLiteApiRepository from "../../external/siafLiteApi";

class EnquadramentoLegalService {
  constructor() {
    this.urlBase = "/fiscal/enquadramentos-legais";
  }

  getAll() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }
}

export default EnquadramentoLegalService;
