import { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  CurrencyInput,
  Loading,
  CardHeader,
} from "../../../components";
import { InputErros, value } from "../../../helpers";
import { formatPrice, responseErros, unformatPrice } from "../../../utils";
import { CaixaService, MeioPagamentoService } from "../../../services";
import { suprimentoSangriaValidator } from "../middlewares";

const SuprimentoSangriaDialog = ({
  open,
  setOpen,
  totaisCaixa,
  formCaixa,
  buscarCaixa,
}) => {
  const [loading, setLoading] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const [operacaoCaixa, setOperacaoCaixa] = useState({});
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const caixaService = new CaixaService();
  const meioPagamentoService = new MeioPagamentoService();

  useEffect(() => {
    if (open) {
      buscarMeiosPagamento();
    }
  }, [open]);

  const buscarMeiosPagamento = () => {
    const filtro = {
      nonPaginated: true,
      nfePagamento: "fpDinheiro",
      ativado: true,
      restritiva: true,
      baixaAutomatica: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setOperacaoCaixa((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleValoresChange = (e) => {
    e.persist();
    let targetValue = parseFloat(unformatPrice(e.target.value));
    setOperacaoCaixa((prevState) => ({
      ...prevState,
      [e.target.name]: targetValue < 0 ? targetValue * -1 : targetValue,
    }));
  };

  const onChangeAutocomplete = (name, value) => {
    setOperacaoCaixa({
      ...operacaoCaixa,
      [name]: value ? value.id : undefined,
    });
  };

  function onClickSuprimentoSangria(tipo) {
    const operacaoCaixaTemp = {
      ...operacaoCaixa,
      tipo,
    };
    suprimentoSangriaValidator
      .validate(operacaoCaixaTemp, { abortEarly: false })
      .then(async () => {
        confirmarSuprimentoSangria(tipo, operacaoCaixaTemp);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  }

  const confirmarSuprimentoSangria = async (tipo, operacaoCaixaTemp) => {
    setLoading(true);
    const result = await caixaService.movimentar(formCaixa?.bancoCaixaId, [
      operacaoCaixaTemp,
    ]);
    setLoading(false);
    if (!result.isAxiosError) {
      buscarCaixa(formCaixa);
      setOperacaoCaixa({});
      setOpen(false);
    } else {
      responseErros(result);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <CardHeader
          title="Suprimento e Sangria"
          action={
            <Button
              variant="contained"
              color="padrao"
              onClick={handleCloseDialog}
            >
              fechar
            </Button>
          }
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "0.5em" }}
        >
          <Grid item xs={11.5}>
            <CurrencyInput
              id="valor"
              name="valor"
              label="Valor"
              variant="outlined"
              margin="none"
              required
              value={operacaoCaixa.valor ?? 0}
              onBlur={handleValoresChange}
              fullWidth
              error={inputErros.get("valor")}
            />
          </Grid>
          <Grid item xs={11.5}>
            <TextField
              variant="outlined"
              label="Observação"
              id="observacao"
              name="observacao"
              type="text"
              fullWidth
              onChange={(event) => handleInputChange(event)}
              value={value.text(operacaoCaixa.observacao)}
              error={inputErros.get("observacao")}
            />
          </Grid>
          <Grid item xs={11.5}>
            <Autocomplete
              required
              id="meioPagamentoId"
              name="meioPagamentoId"
              options={meioPagamentoList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meio de Pagamento"
                  variant="outlined"
                  error={inputErros.get("meioPagamentoId")}
                  required
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete("meioPagamentoId", newValue);
              }}
              value={value.autoComplete(
                meioPagamentoList,
                operacaoCaixa?.meioPagamentoId
              )}
            />
          </Grid>
        </Grid>
        <h6 className="centralizar mt-3">Saldo em Caixa</h6>
        <h6 id="valor-saldo-operacao">
          {formatPrice(totaisCaixa?.saldoDia) || "R$ 0,00"}
        </h6>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className="mb-3"
        >
          <Grid item xs={5.5}>
            <Button
              color="success"
              size="large"
              variant="contained"
              fullWidth
              disableElevation
              onClick={() => onClickSuprimentoSangria("ENTRADA")}
            >
              Suprimento
            </Button>
          </Grid>
          <Grid item xs={5.5}>
            <Button
              color="error"
              size="large"
              variant="contained"
              fullWidth
              disableElevation
              onClick={() => onClickSuprimentoSangria("SAIDA")}
            >
              Sangria
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <Loading loading={loading} />
    </Dialog>
  );
};

export default SuprimentoSangriaDialog;
