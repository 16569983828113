import { useEffect, useState } from "react";
import shortid from "shortid";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Autocomplete,
  CurrencyInput,
  DataGrid,
} from "../../../../../components";
import { value } from "../../../../../helpers";
import { formatDate, unformatPrice, formatPrice } from "../../../../../utils";
import { notification } from "../../../../../services";
import { parcelaData } from "../../add-nf-saida";
import { NotasCreditoDialog } from "..";
import { useImportContext } from "../../../../../contexts/import.context";

const NfSaidaPagamentos = ({
  nfSaidaId,
  nfSaida,
  setNfSaida,
  nfSaidaEntity,
  nfSaidaPagamentos,
  setNfSaidaPagamentos,
  condicaoPagamentoList,
  meioPagamentoList,
  meioPagamentoSelecionado,
  setMeioPagamentoSelecionado,
  listaNotasCredito,
  setListaNotasCredito,
  openNotasCreditoDialog,
  setOpenNotasCreditoDialog,
  notasCredito,
  setNotasCredito,
  meioPagamentoCredito,
  buscarNotasCredito,
  setAbaValue,
}) => {
  const { nfSaidaDto } = useImportContext();
  const [inputError, setInputError] = useState(null);
  const [gerarPagamentos, setGerarPagamentos] = useState({});
  const [condicaoPagParcelaList, setCondicaoPagParcelaList] = useState([]);
  const [condicaoSelecionada, setCondicaoSelecionada] = useState([]);
  const colunas = [
    {
      field: "meioPagamentoId",
      headerName: "Meio de Pagamento",
      flex: 700,
      sortable: false,
      valueGetter: (params) =>
        value.autoComplete(meioPagamentoList, params.value).descricao,
    },
    {
      field: "valorTitulo",
      headerName: "Valor do Título",
      flex: 700,
      sortable: false,
      valueGetter: (params) =>
        params.value > 0 ? formatPrice(params.value) : "R$ 0,00",
    },
    {
      field: "dataVencimento",
      headerName: "Vencimento",
      flex: 700,
      type: "date",
      editable: !nfSaida.id ? true : false,
      sortable: false,
      valueGetter: (params) => {
        return formatDate.received(params.value);
      },
    },
    {
      field: "numeroAutorizacao",
      headerName: "Nº Autorização",
      flex: 500,
      editable: !nfSaida.id ? true : false,
      sortable: false,
      valueGetter: (params) => params.value ?? "",
    },
  ];

  useEffect(() => {
    if (nfSaida?.totalVenda != nfSaida?.totalPagamentos) {
      setGerarPagamentos({
        valorTitulo:
          parseFloat(nfSaida?.totalVenda ?? 0) -
          parseFloat(nfSaida?.totalPagamentos ?? 0),
      });
    }
  }, [nfSaida.totalVenda, nfSaida.totalPagamentos]);

  const onChangeMeioPagamento = (id, value) => {
    setInputError(null);
    const { condicoesPagamento } = value ?? [];
    const condicaoPagamentoId = condicoesPagamento?.[0]?.id ?? null;
    const quantidadeParcelas = condicoesPagamento?.[0]?.parcelas ?? null;
    setMeioPagamentoSelecionado(value);
    setGerarPagamentos({
      ...gerarPagamentos,
      [id]: value?.id ?? null,
      condicaoPagamentoId,
      quantidadeParcelas,
    });
    if (condicoesPagamento?.length > 0) {
      setCondicaoSelecionada(condicoesPagamento[0]);
    }
    setCondicaoPagParcelaList(condicoesPagamento ?? []);
  };

  const onChangeAutocomplete = (name, value) => {
    const pedidoPagamentoTemp = {
      ...gerarPagamentos,
      [name]: value ? value.id : null,
    };
    if (name === "condicaoPagamentoId") {
      setCondicaoSelecionada(value);
      pedidoPagamentoTemp.quantidadeParcelas = value?.parcelas;
    }
    setGerarPagamentos(pedidoPagamentoTemp);
  };

  const handleChangeValor = (event) => {
    const pagamentoTemp = {
      ...gerarPagamentos,
      [event.target.name]:
        parseFloat(unformatPrice(event.target.value)) || null,
    };
    setGerarPagamentos(pagamentoTemp);
  };

  const handleChangeInput = (event) => {
    const pagamentoTemp = {
      ...gerarPagamentos,
      [event.target.name]: event.target.value
        ? `${event.target.value}`.toUpperCase()
        : null,
    };
    setGerarPagamentos(pagamentoTemp);
  };

  function validarParcela() {
    if (!gerarPagamentos?.meioPagamentoId) {
      setInputError("meioPagamentoId");
      notification.erroValidacao("Meio de pagamento");
      return true;
    }
    if (!gerarPagamentos?.condicaoPagamentoId) {
      setInputError("condicaoPagamentoId");
      notification.erroValidacao("Condição de pagamento");
      return true;
    }
    if (!gerarPagamentos?.quantidadeParcelas) {
      setInputError("quantidadeParcelas");
      notification.erroValidacao("Quantidade de Parcelas");
      return true;
    }
    if (!gerarPagamentos?.valorTitulo) {
      setInputError("valorTitulo");
      notification.erroValidacao("Valor do título");
      return true;
    }
    if (
      meioPagamentoSelecionado?.cartaoCreditoDebito &&
      !gerarPagamentos?.numeroAutorizacao
    ) {
      setInputError("numeroAutorizacao");
      notification.erroValidacao("Número de Autorização");
      return true;
    }
    if (
      parseFloat(nfSaida?.totalPagamentos || 0) +
        parseFloat(gerarPagamentos.valorTitulo || 0) >
      (nfSaida?.totalVenda || 0)
    ) {
      notification.alertaGenericos(
        "Valor inserido ultrapassa total da Nota fiscal"
      );
      return true;
    }
    return false;
  }

  const handleGerarParcelas = () => {
    const parcelaIsValid = validarParcela();
    if (parcelaIsValid) return;
    if (
      nfSaidaDto.documentoDevolucaoCliente ||
      nfSaidaDto.documentoDevolucaoFornecedor
    ) {
      gerarPagamentos.quantidadeParcelas = 1;
    }
    const valorParcela =
      gerarPagamentos.valorTitulo / gerarPagamentos.quantidadeParcelas;
    const valorFormatado = Math.floor(valorParcela * 100) / 100;
    const restoDivisao =
      gerarPagamentos.valorTitulo -
      valorFormatado * gerarPagamentos.quantidadeParcelas;
    const parcelasGeradas = [];
    for (
      let contador = 1;
      contador <= gerarPagamentos?.quantidadeParcelas;
      contador++
    ) {
      const valorTitulo =
        contador === gerarPagamentos.quantidadeParcelas
          ? valorFormatado + parseFloat(restoDivisao.toFixed(2))
          : valorFormatado;
      parcelasGeradas.push({
        id: shortid.generate(),
        numeroTitulo: contador,
        condicaoPagamentoId: gerarPagamentos?.condicaoPagamentoId,
        numeroAutorizacao: gerarPagamentos?.numeroAutorizacao,
        meioPagamentoId: gerarPagamentos?.meioPagamentoId,
        meioPagamentoSelecionado,
        valorTitulo,
        dataVencimento: parcelaData(
          contador,
          nfSaida?.dataEmissao,
          condicaoSelecionada?.periodo
        ),
      });
    }

    setNfSaidaPagamentos((prevState) => [...prevState, ...parcelasGeradas]);
    nfSaidaEntity.setChangePagamentos([
      ...nfSaidaPagamentos,
      ...parcelasGeradas,
    ]);
    setNfSaida(nfSaidaEntity);
    setGerarPagamentos({
      valorTitulo:
        nfSaidaEntity?.totalVenda -
        parseFloat(nfSaidaEntity?.totalPagamentos ?? 0),
    });
    notification.sucessoGenericos("Parcelas geradas com sucesso");
  };

  const onSubmitParcelas = (event) => {
    event.preventDefault();
    handleGerarParcelas();
  };

  const onClickDeletePagamentos = async (idParcela) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir essa parcela?`
    );
    const pagamento = nfSaidaPagamentos.find(({ id }) => id == idParcela);
    if (retornoAlerta.isConfirmed) {
      if (
        pagamento?.meioPagamentoSelecionado?.nfePagamento == "fpCreditoLoja"
      ) {
        setNfSaidaPagamentos([]);
        nfSaidaEntity.setChangePagamentos([]);
        setNfSaida(nfSaidaEntity);
        setAbaValue(0);
        buscarNotasCredito();
      } else {
        const data = nfSaidaPagamentos.filter((item) => item.id !== idParcela);
        setGerarPagamentos({
          ...gerarPagamentos,
          valorTitulo:
            gerarPagamentos?.valorTitulo +
            pagamento?.valorTitulo.toFixed(2) * 1,
        });
        setNfSaidaPagamentos(data);
        nfSaidaEntity.setChangePagamentos(data);
        setNfSaida(nfSaidaEntity);
      }
    }
  };

  const onCellKeyDown = (params, event) => {
    if (event.key === "Enter") {
      const pagamentos = nfSaidaPagamentos.map((pagamento) => {
        if (params.row.meioPagamentoId == pagamento.meioPagamentoId) {
          return {
            ...pagamento,
            [params.field]: event.target.value
              ? `${event.target.value}`.toUpperCase()
              : null,
          };
        }
        return pagamento;
      });
      setNfSaidaPagamentos(pagamentos);
      nfSaidaEntity.setChangePagamentos(pagamentos);
      setNfSaida(nfSaidaEntity);
    }
  };

  return (
    <>
      <Grid container>
        <Grid container spacing={2} xs={4} className="d-block">
          <Grid item xs={12}>
            <Autocomplete
              id="meioPagamentoId"
              name="meioPagamentoId"
              disabled={nfSaidaId}
              options={
                nfSaida.documentoDevolucaoCliente ||
                nfSaida.documentoDevolucaoFornecedor
                  ? meioPagamentoList
                  : meioPagamentoList.filter(
                      (meio) => meio.nfePagamento !== "fpCreditoLoja"
                    )
              }
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao ? option.descricao : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meio de Pagamento"
                  variant="outlined"
                  margin="normal"
                  error={inputError == "meioPagamentoId"}
                  required
                />
              )}
              onChange={(e, newValue) => {
                onChangeMeioPagamento("meioPagamentoId", newValue);
              }}
              value={value.autoComplete(
                meioPagamentoList,
                gerarPagamentos?.meioPagamentoId
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                required
                id="condicaoPagamentoId"
                name="condicaoDePagamento"
                disabled={nfSaidaId}
                options={condicaoPagParcelaList}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                getOptionSelected={(option, value) =>
                  option?.descricao === value?.descricao
                }
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Condição de Pagamento"
                    variant="outlined"
                    error={inputError == "condicaoPagamentoId"}
                    required
                  />
                )}
                onChange={(e, newValue) => {
                  e.persist();
                  onChangeAutocomplete("condicaoPagamentoId", newValue);
                }}
                value={value.autoComplete(
                  condicaoPagamentoList,
                  gerarPagamentos?.condicaoPagamentoId
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CurrencyInput
              id="valorTitulo"
              name="valorTitulo"
              label="Valor do Título (R$)"
              inputVariant="outlined"
              margin="normal"
              required
              disabled={nfSaidaId}
              error={inputError == "valorTitulo"}
              value={gerarPagamentos?.valorTitulo ?? ""}
              onBlur={(event) => {
                setInputError(null);
                handleChangeValor(event);
              }}
              fullWidth
            />
          </Grid>
          {meioPagamentoSelecionado?.cartaoCreditoDebito && (
            <Grid item xs={12}>
              <TextField
                id="numeroAutorizacao"
                name="numeroAutorizacao"
                label="Número Autorização"
                inputVariant="outlined"
                margin="none"
                required
                disabled={nfSaidaId}
                error={inputError == "numeroAutorizacao"}
                value={gerarPagamentos?.numeroAutorizacao ?? ""}
                inputProps={{ min: 0 }}
                onChange={(event) => {
                  setInputError(null);
                  handleChangeInput(event);
                }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} fullWidth>
            <Button
              fullWidth
              className="mt-3 mb-3"
              size="large"
              variant="contained"
              color="primary"
              disabled={nfSaidaId}
              onClick={onSubmitParcelas}
            >
              <i
                className="ph-fill ph-plus-circle"
                style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
              ></i>
              Gerar Parcelas
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          xs={1}
          fullWidth
          className="d-flex justify-content-center"
        >
          <hr className="vr" />
        </Grid>
        <Grid container xs={7} className="d-block mt-3">
          <Grid container xs={12} fullWidth>
            <h4 style={{ fontWeight: 800, margin: 0 }}>PARCELAS</h4>
          </Grid>
          <Grid container xs={12} fullWidth className="mt-4">
            <DataGrid
              rows={nfSaidaPagamentos}
              columns={colunas}
              rowCount={nfSaidaPagamentos.length}
              deleteShow={!nfSaida?.id ? true : false}
              sortModel={[{ field: "condicaoPagamentoId", sort: "desc" }]}
              onClickDelete={onClickDeletePagamentos}
              onCellKeyDown={onCellKeyDown}
            />
            <Grid
              container
              xs={12}
              fullWidth
              className="d-flex align-items-center justify-content-end mt-3"
            >
              <Grid
                item
                xs={5}
                className="d-flex align-items-center justify-content-end"
              >
                <span className="me-2">Saldo a pagar:</span>
                <span className="badge bg-secondary bg-gradient bg-gradient text-wrap p-2">
                  {formatPrice(
                    (nfSaida?.totalVenda ?? 0) - (nfSaida?.totalPagamentos ?? 0)
                  ) || "R$ 0,00"}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <NotasCreditoDialog
        open={openNotasCreditoDialog}
        setOpen={setOpenNotasCreditoDialog}
        nfSaida={nfSaida}
        setNfSaida={setNfSaida}
        nfSaidaEntity={nfSaidaEntity}
        listaNotasCredito={listaNotasCredito}
        setListaNotasCredito={setListaNotasCredito}
        totalVenda={
          (nfSaida?.totalVenda || 0) - (nfSaida?.totalPagamentos || 0)
        }
        notasCredito={notasCredito}
        setNotasCredito={setNotasCredito}
        meioPagamentoCredito={meioPagamentoCredito}
        nfSaidaPagamentos={nfSaidaPagamentos}
        setNfSaidaPagamentos={setNfSaidaPagamentos}
        setGerarPagamentos={setGerarPagamentos}
      />
    </>
  );
};

export default NfSaidaPagamentos;
