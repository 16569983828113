import SiafLiteApiRepository from "../../external/siafLiteApi";

class CodigoServicoService {
  constructor() {
    this.urlBase = "/estoque/codigos-servicos";
  }

  getAll() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }
}

export default CodigoServicoService;
