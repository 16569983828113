import { TableHead as TableHeadMui } from "@mui/material";

const TableHead = ({ className, children, classes, component, sx, ref }) => {
  return (
    <TableHeadMui
      className={className}
      classes={classes}
      component={component}
      ref={ref}
      sx={sx}
    >
      {children}
    </TableHeadMui>
  );
};

export default TableHead;
