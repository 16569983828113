import StorageHelper from "../helpers/StorageHelper";
import { history } from "../services";
const storageHelper = new StorageHelper();
const UNAUTHORIZED = 401;
const NOTFOUND = 404;

class SiafLiteApiBaseRepository {
  constructor(url, axiosConfig) {
    this.url = url;
    this.axiosConfig = axiosConfig;
  }

  async get(filter) {
    let urlGet = this.url;
    if (filter) {
      urlGet = gerarUrlComFiltro(this.url, filter);
    }
    await this.gerarAuthorization();
    return this.axiosConfig
      .get(urlGet)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        validarRetornoErro(erro);
        return erro;
      });
  }

  async patch(body) {
    await this.gerarAuthorization();
    return this.axiosConfig
      .patch(this.url, body)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        validarRetornoErro(erro);
        return erro;
      });
  }

  async getById(id) {
    let urlGet = `${this.url}/${id}`;
    await this.gerarAuthorization();
    return this.axiosConfig
      .get(urlGet)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        validarRetornoErro(erro);
        return erro;
      });
  }

  async post(body) {
    await this.gerarAuthorization();
    return this.axiosConfig
      .post(this.url, body)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        validarRetornoErro(erro);
        return erro;
      });
  }

  async put(id, body, adicionalParametro = null) {
    let urlPut = `/${id}`;
    if (adicionalParametro) {
      urlPut = `${urlPut}/${adicionalParametro}`;
    }
    return this.putBase(urlPut, body);
  }

  async putBase(urlComplementar, body) {
    await this.gerarAuthorization();
    const urlPut = this.url + urlComplementar;
    return this.axiosConfig
      .put(urlPut, body)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        validarRetornoErro(erro);
        return erro;
      });
  }

  async delete(id) {
    await this.gerarAuthorization();
    const urlDelete = `${this.url}/${id}`;
    return this.axiosConfig
      .delete(urlDelete)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        validarRetornoErro(erro);
        return erro;
      });
  }

  async gerarAuthorization() {
    this.axiosConfig.interceptors.request.use(
      async (config) => {
        const localStorageToken = storageHelper.getLocal("token");
        if (localStorageToken) {
          config.headers.Authorization = `Bearer ${localStorageToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }
}

function gerarUrlComFiltro(url, filter) {
  let filtroString = "";
  for (const key in filter) {
    if (filter[key]) {
      const parametroFilro = filter[key]?.toString().replaceAll(/\s/g, "+");
      if (filtroString) {
        filtroString += `&${key}=${parametroFilro}`;
      } else {
        filtroString += `${key}=${parametroFilro}`;
      }
    }
  }
  return `${url}?${filtroString}`;
}

function validarRetornoErro(erro) {
  if (erro.response) {
    if (erro.response.status === UNAUTHORIZED) {
      const url = window.location.pathname.split("/");
      storageHelper.removeLocal("token");
      storageHelper.removeLocal("rotinas");
      storageHelper.removeLocal("dados");
      storageHelper.removeSession("notificacao");
      storageHelper.removeLocal("bancoCaixaIdPdv");
      if (url[1] != "pdv") {
        history.push("/login");
      }
    }
    if (erro.response.status === NOTFOUND) {
      history.push("/notfound");
    }
  }
}

export default SiafLiteApiBaseRepository;
