import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  TextFielDecimalNumbers,
  Divider,
} from "../../../../components";
import { value } from "../../../../helpers";

export default function ModeloTributacaoPis({
  situacaoPisList,
  grupoModeloTributacao,
  handleInputChange,
  onChangeAutocomplete,
  indicadorPisSt,
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="situacaoPisId"
              name="situacaoPisId"
              options={situacaoPisList}
              autoHighlight
              getOptionLabel={(option) =>
                option ? `${option?.codigo} - ${option?.descricao}` : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Situação PIS"
                  variant="outlined"
                  margin="normal"
                />
              )}
              onChange={(_, newValue) => {
                onChangeAutocomplete("situacaoPisId", newValue);
              }}
              value={value.autoComplete(
                situacaoPisList,
                grupoModeloTributacao?.situacaoPisId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextFielDecimalNumbers
            id="aliquotaPis"
            name="aliquotaPis"
            label="Alíquota PIS"
            margin="normal"
            variant="outlined"
            fullWidth
            value={grupoModeloTributacao?.aliquotaPis ?? ""}
            onBlur={handleInputChange}
          />
        </Grid>
      </Grid>
      {indicadorPisSt && (
        <>
          <Divider sx={{ margin: "5px 0", border: "3px solid grey" }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="situacaoPisStId"
                  name="situacaoPisStId"
                  options={situacaoPisList}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option ? `${option?.codigo} - ${option?.descricao}` : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.descricao === value?.descricao
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Situação PIS ST"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                  onChange={(_, newValue) => {
                    onChangeAutocomplete("situacaoPisStId", newValue);
                  }}
                  value={value.autoComplete(
                    situacaoPisList,
                    grupoModeloTributacao?.situacaoPisStId
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextFielDecimalNumbers
                id="aliquotaPisSt"
                name="aliquotaPisSt"
                label="Alíquota PIS ST"
                margin="normal"
                variant="outlined"
                fullWidth
                value={grupoModeloTributacao?.aliquotaPisSt ?? ""}
                onBlur={handleInputChange}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
