export class PerfilUsuario {
  nome = null;
  permissoes = [];

  constructor(nome, permissoesModulos) {
    this.nome = nome;
    this.permissoes = this.mapearPermissoes(permissoesModulos);
  }

  mapearPermissoes(permissoesModulos) {
    const permissoes = [];
    Object.keys(permissoesModulos).forEach((secao) => {
      Object.keys(permissoesModulos[secao]).forEach((subSecao) => {
        Object.keys(permissoesModulos[secao][subSecao]).forEach(
          (nomePermissao) => {
            permissoes.push(permissoesModulos[secao][subSecao][nomePermissao]);
          }
        );
      });
    });
    return permissoes;
  }
}
