import {
  Menu,
  Typography,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  CssBaseline,
  Fab,
  Tooltip,
  ListItemIcon,
} from "../../../components";
import "../topbar.style.css";
import { NotificacaoService } from "../../../services";
import { differenceInCalendarDays } from "date-fns";
import { buscarNotificacao, formatDate, responseErros } from "../../../utils";
import shortid from "shortid";

const MenuNotificaticao = ({
  usuarioId,
  mensagens,
  setMensagens,
  setAnchorElNotificacao,
  anchorElNotificacao,
  quantidadeMensagemNaoLida,
}) => {
  const menuNotificacaoId = "menu-notificacao";
  const isMenuNotificacaoOpen = Boolean(anchorElNotificacao);
  const notificacaoService = new NotificacaoService();

  const handleMenuNotificacaoClose = () => {
    setAnchorElNotificacao(null);
  };

  const onClickMarcarUmaComoLida = (id, visualizado) => {
    if (!visualizado) {
      notificacaoService.marcarComoLida(id).then((res) => {
        if (!res.isAxiosError) {
          buscarNotificacao(setMensagens, usuarioId);
        } else {
          responseErros(res);
        }
      });
    }
  };

  const marcarTodasComoLida = () => {
    if (quantidadeMensagemNaoLida) {
      notificacaoService.marcarTodasComoLida().then((res) => {
        if (!res.isAxiosError) {
          buscarNotificacao(setMensagens, usuarioId);
        } else {
          responseErros(res);
        }
      });
    }
  };

  const listaMensagemComponent = (listaMensagem) => {
    let subHeaderAtual = null;

    const subHeaderTitulo = (data) => {
      const quantidadeDiasPassados = differenceInCalendarDays(
        formatDate.received(),
        formatDate.received(data)
      );
      let periodo = "Outros Meses";
      if (quantidadeDiasPassados == 0) {
        periodo = "Hoje";
      } else if (quantidadeDiasPassados == 1) {
        periodo = "Ontem";
      } else if (quantidadeDiasPassados > 1 && quantidadeDiasPassados < 31) {
        periodo = "Mês Atual";
      }
      if (periodo != subHeaderAtual) {
        subHeaderAtual = periodo;
        return <ListSubheader className="subheader">{periodo}</ListSubheader>;
      } else {
        return;
      }
    };

    return (
      <>
        {listaMensagem.map(
          ({ id, titulo, descricao, createdAt, visualizado, tipo }) => (
            <div key={id}>
              {subHeaderTitulo(createdAt)}
              <Tooltip
                title={
                  <div>
                    <Typography color="inherit">{titulo}</Typography>
                    {descricao}
                  </div>
                }
              >
                <div>
                  <ListItemButton
                    selected={visualizado == true}
                    onClick={() => onClickMarcarUmaComoLida(id, visualizado)}
                  >
                    <ListItemIcon>
                      <i
                        className={
                          visualizado == true
                            ? "ph-fill ph-check-circle"
                            : tipo == "ERRO"
                            ? "ph-fill ph-bug"
                            : "ph-fill ph-warning"
                        }
                        style={{ fontSize: 22, marginRight: 10 }}
                      ></i>
                    </ListItemIcon>
                    <ListItemText
                      primary={titulo}
                      secondary={
                        descricao.length > 50
                          ? `${descricao.substr(0, 50)}...`
                          : descricao
                      }
                    />
                  </ListItemButton>
                </div>
              </Tooltip>
            </div>
          )
        )}
      </>
    );
  };

  return (
    <Menu
      anchorEl={anchorElNotificacao}
      elevation={1}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      id={menuNotificacaoId}
      autoFocus={false}
      open={isMenuNotificacaoOpen}
      onClose={handleMenuNotificacaoClose}
      className="m-3"
    >
      {[
        <div key={shortid.generate()}>
          <CssBaseline />
          <Paper square>
            <List>
              <div>
                <Fab
                  variant="extended"
                  onClick={() => marcarTodasComoLida()}
                  className="m-2"
                  color="primary"
                >
                  <i
                    className="ph-fill ph-check-circle"
                    style={{ color: "white", fontSize: 22, marginRight: 4 }}
                  ></i>
                  <span style={{ fontSize: 15 }}>marcar todas como lida</span>
                </Fab>
              </div>
              {listaMensagemComponent(mensagens)}
            </List>
          </Paper>
        </div>,
      ]}
    </Menu>
  );
};

export default MenuNotificaticao;
