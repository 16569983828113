import SiafLiteApiRepository from "../../external/siafLiteApi";

class TiposItemService {
  constructor() {
    this.urlBase = "/estoque/tipos-item";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }
}

export default TiposItemService;
