import { CabecalhoMainPrint, Grid } from "../../../../components";
import { formatNomes, formatPrice } from "../../../../utils";
import "./table-print.css";

const PrintEstoqueInventario = ({
  tituloRelatorio,
  relatoriosTotais,
  filtrosAplicadosImpressao,
  print,
}) => {
  const itensPorPagina = 31;
  const count = Math.ceil(relatoriosTotais?.length / itensPorPagina);
  const calcularSomaTotal = (relatoriosTotais, pagina) => {
    const lista = relatoriosTotais.slice(0, itensPorPagina * pagina);
    return lista.reduce((acc, item) => acc + item.total, 0);
  };

  return (
    <div>
      {Array.from({ length: count }).map((_, pageIndex) => {
        const paginacaoInicial = filtrosAplicadosImpressao?.numeroPagina
          ? Number(filtrosAplicadosImpressao.numeroPagina)
          : 0;
        const itens = relatoriosTotais.slice(
          pageIndex === 0
            ? pageIndex
            : itensPorPagina + (pageIndex - 1) * itensPorPagina,
          pageIndex === 0
            ? itensPorPagina
            : itensPorPagina + pageIndex * itensPorPagina
        );
        return (
          <div
            key={pageIndex}
            className={!print ? "report-container" : "print-container"}
          >
            <CabecalhoMainPrint
              data={{
                tituloRelatorio,
                filtrosAplicadosImpressao,
                print,
                pagina: `${pageIndex + 1 + paginacaoInicial}/${
                  count + paginacaoInicial
                }`,
              }}
            />
            <Grid item md={12} xs={12}>
              <table id="table-relatorio" className="table table-borderless">
                <thead className="table-primary">
                  <tr>
                    <th>Código</th>
                    <th>Produto</th>
                    <th>NCM</th>
                    <th>UND</th>
                    <th>Estoque</th>
                    <th>
                      {filtrosAplicadosImpressao.Tipo == "precoCompra"
                        ? "Preço Compra"
                        : "Preço Custo"}
                    </th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {itens.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>
                          <div className="cell-content">{item.codigo}</div>
                        </td>
                        <td>
                          <div className="cell-content">
                            {formatNomes(item.produto.substring(0, 50))}
                          </div>
                        </td>
                        <td>
                          <div className="cell-content">{item.ncm}</div>
                        </td>
                        <td>
                          <div className="cell-content">{item.unidade}</div>
                        </td>
                        <td>
                          <div className="cell-content">{item.estoque}</div>
                        </td>
                        <td>
                          <div className="cell-content">
                            {formatPrice(
                              filtrosAplicadosImpressao.Tipo == "precoCompra"
                                ? item.precoCompra
                                : item.precoCusto,
                              false
                            )}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="cell-content-end">
                            {formatPrice(
                              parseFloat(item.total.toFixed(2)),
                              false
                            )}
                          </div>
                        </td>
                      </tr>
                      {index + 1 == itens.length && (
                        <tr className="table-primary">
                          <td colSpan="6" style={{ textAlign: "right" }}>
                            Soma Total:
                          </td>
                          <td>
                            <strong className="content-end">
                              {formatPrice(
                                calcularSomaTotal(
                                  relatoriosTotais,
                                  pageIndex + 1
                                ),
                                false
                              )}
                            </strong>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default PrintEstoqueInventario;
