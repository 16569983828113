import { Grid, TextareaAutosize } from "../../../../../components";
import "./informacoes.styles.css";

const NfSaidaInformacoes = ({
  nfSaidaId,
  nfSaidaEntity,
  handleInputChange,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextareaAutosize
          className="text-area w-100 p-3 mt-3"
          aria-label="minimum height"
          name="informacoesAdicionais"
          id="informacoesAdicionais"
          disabled={nfSaidaId}
          onChange={handleInputChange}
          value={nfSaidaEntity?.informacoesAdicionais}
          minRows={5}
          placeholder="Informações adicionais"
        />
      </Grid>
      <Grid item xs={6}>
        <TextareaAutosize
          className="text-area w-100 p-3 mt-3"
          aria-label="minimum height"
          name="informacoesFisco"
          id="informacoesFisco"
          disabled={nfSaidaId}
          onChange={handleInputChange}
          value={nfSaidaEntity?.informacoesFisco}
          minRows={5}
          placeholder="Informações fisco"
        />
      </Grid>
    </Grid>
  );
};

export default NfSaidaInformacoes;
