import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { SessionService } from "../../services";
import { formatCnpj, formatCpf } from "../../utils";
import FiltrosMainPrint from "./filtros-main-print.component";

function CabecalhoMainPrint({ data }) {
  const sessionService = new SessionService();
  const [empresa, setEmpresa] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };
  useEffect(() => {
    const dados = sessionService.getEmpresaAndUsuario();
    setEmpresa(dados.empresa);
  }, []);

  return (
    empresa && (
      <Grid
        item
        md={12}
        xs={12}
        className="d-flex justify-content-between align-items-center mb-4"
      >
        <div className="d-flex justify-content-between align-items-center">
          {empresa?.imagemLogo ? (
            <img
              className="me-3"
              src={empresa.imagemLogo}
              alt="logo da empresa"
              onLoad={aspectLogo}
              width={100}
              height={aspectRatio <= 1.5 ? 100 : 20}
            />
          ) : null}
          <div>
            <b>{empresa?.fantasia || empresa?.razaoSocial}</b>
            <br />
            CNPJ / CPF -{" "}
            {empresa?.cnpjCpf?.length === 11
              ? formatCpf(empresa?.cnpjCpf)
              : formatCnpj(empresa?.cnpjCpf)}
            <br />
            {`${empresa?.enderecoLogradouro} - ${empresa?.enderecoLogradouroNumero} - ${empresa?.enderecoBairro} - ${empresa?.enderecoCidade}-${empresa?.enderecoUF}`}
            <br />
            <b>Relatório: {data.tituloRelatorio}</b>
          </div>
        </div>
        <FiltrosMainPrint
          filtrosAplicadosImpressao={data?.filtrosAplicadosImpressao}
          pagina={data?.pagina}
        />
      </Grid>
    )
  );
}

export default CabecalhoMainPrint;
