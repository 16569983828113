import { Grid, TextareaAutosize } from "../../../../../components";
import "./informacoes.styles.css";

const NfEntradaInformacoes = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextareaAutosize
          className="text-area bg-transparent w-100 p-3 mt-3"
          style={{ color: "#000" }}
          ariaLabel="minimum height"
          name="informacoes"
          id="informacoes"
          onChange={props.handleInputChange}
          value={props.nfEntrada?.informacoes ?? ""}
          minRows={8}
          placeholder="Informações adicionais"
        />
      </Grid>
    </Grid>
  );
};

export default NfEntradaInformacoes;
