import { Box, Grid, Typography } from "../../../../components";
import { formatCnpj } from "../../../../utils";

const TransportadoraXmlStep = ({ transportadora }) => {
  return (
    <Box sx={{ pl: 2, mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          {transportadora?.nomeRazaoSocial ? (
            <Typography variant="overline">
              Nome da Transportadora:
              <h4 style={{ fontWeight: 800, margin: 0 }}>
                {transportadora.nomeRazaoSocial}
              </h4>
              <br />
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={5}>
          {transportadora?.cnpjCpf ? (
            <Typography variant="overline">
              CNPJ:
              <h4 style={{ fontWeight: 800, margin: 0 }}>
                {formatCnpj(transportadora.cnpjCpf)}
              </h4>
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="overline">
            {transportadora?.endereco?.localEmbarque ? (
              <>
                Endereço:
                <h6 style={{ fontWeight: 800, margin: 0 }}>
                  {transportadora.endereco.localEmbarque}
                </h6>
              </>
            ) : null}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransportadoraXmlStep;
