export class CadastroContador {
  nome = null;
  senha = null;
  email = null;
  cnpjCpf = null;

  constructor(cadastroContador) {
    Object.assign(this, cadastroContador);
    this.nome = cadastroContador.nome;
    this.senha = cadastroContador.senha;
    this.email = cadastroContador.email;
    this.cnpjCpf = cadastroContador.cnpjCpf;
  }

  verificarSenhaForte(contador, senhaScore) {
    if (contador?.senha && senhaScore < 2) return false;
    return true;
  }
}
