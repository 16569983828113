import { useState } from "react";
import {
  CurrencyInput,
  Grid,
  TextFielDecimalNumbers,
  Typography,
} from "../../../../components";
import { formatPrice, unformatPrice } from "../../../../utils";
import {
  calculoDesconto,
  formatProdutosTotalizadores,
  totalCompra,
} from "../add-nf-entrada";

const ResumoTotal = ({
  nfEntradaId,
  nfEntrada,
  nfEntradaItens,
  setNfEntradaItens,
  nfEntradaTributos,
  abaValue,
  setGerarPagamentos,
}) => {
  const [gerarAcrescimos, setGerarAcrescimos] = useState([]);

  const handleInputAcrescimo = (event) => {
    const name = "acrescimo";
    const valor = Number(unformatPrice(event.target.value)) ?? 0;
    const totalNota = totalCompra(
      nfEntrada,
      nfEntradaItens,
      nfEntradaTributos,
      name
    );
    const listItensAcrescimo = formatProdutosTotalizadores(
      name,
      nfEntradaItens,
      valor,
      totalNota
    );
    setGerarAcrescimos((prevState) => ({
      ...prevState,
      [event.target.name]: valor,
    }));
    setGerarPagamentos({
      valorTitulo: totalCompra(
        nfEntrada,
        listItensAcrescimo,
        nfEntradaTributos,
        name
      ),
    });
    setNfEntradaItens(listItensAcrescimo);
  };

  const handleInputDespesas = (event) => {
    const name = "despesas";
    const valor = Number(unformatPrice(event.target.value)) ?? 0;
    const totalNota = totalCompra(
      nfEntrada,
      nfEntradaItens,
      nfEntradaTributos,
      name
    );
    const listItensDespesas = formatProdutosTotalizadores(
      name,
      nfEntradaItens,
      valor,
      totalNota
    );
    setGerarPagamentos({
      valorTitulo: totalCompra(
        nfEntrada,
        listItensDespesas,
        nfEntradaTributos,
        name
      ),
    });
    setGerarAcrescimos((prevState) => ({
      ...prevState,
      [event.target.name]: valor,
    }));
    setNfEntradaItens(listItensDespesas);
  };

  const handleInputDesconto = (event) => {
    if (nfEntradaItens?.length) {
      const name = "desconto";
      const valor = unformatPrice(event.target.value);
      const totalNota = totalCompra(
        nfEntrada,
        nfEntradaItens,
        nfEntradaTributos,
        name
      );
      const gerarAcrescimosTemp = {
        ...gerarAcrescimos,
        [event.target.name]: valor || 0,
      };
      if (event.target.name === "subtotalDesconto") {
        gerarAcrescimosTemp.subtotalDescontoPorcentagem = calculoDesconto(
          nfEntradaItens,
          gerarAcrescimosTemp,
          false
        );
        const listItensDesconto = formatProdutosTotalizadores(
          name,
          nfEntradaItens,
          valor,
          totalNota
        );
        setGerarPagamentos({
          valorTitulo: totalCompra(
            nfEntrada,
            listItensDesconto,
            nfEntradaTributos,
            name
          ),
        });
        setNfEntradaItens(listItensDesconto);
      } else {
        gerarAcrescimosTemp.subtotalDesconto = calculoDesconto(
          nfEntradaItens,
          gerarAcrescimosTemp
        );
        const listItensDesconto = formatProdutosTotalizadores(
          name,
          nfEntradaItens,
          gerarAcrescimosTemp?.subtotalDesconto,
          totalNota
        );
        setGerarPagamentos({
          valorTitulo: totalCompra(
            nfEntrada,
            listItensDesconto,
            nfEntradaTributos,
            name
          ),
        });
        setNfEntradaItens(listItensDesconto);
      }
      setGerarAcrescimos(gerarAcrescimosTemp);
    }
  };

  const nextFocusInput = (event) => {
    if (event.keyCode === 13) {
      const { form } = event.target;
      const arrayInput = Array.from(form.elements).filter(
        (element) => element.tagName == "INPUT"
      );
      const index = arrayInput.indexOf(event.target);
      let element = arrayInput[index + 1];
      if (element?.disabled) {
        element = arrayInput[index + 2];
      }
      if (element) {
        element.focus();
      } else {
        arrayInput[index].blur();
      }
      event.preventDefault();
    }
  };

  return (
    <form>
      <Grid container spacing={2} justifyContent="center" className="mx-3">
        <Grid item xs={4} className="d-flex align-items-center">
          <span className="me-2">Desconto:</span>
          <TextFielDecimalNumbers
            id="subtotalDescontoPorcentagem"
            name="subtotalDescontoPorcentagem"
            variant="outlined"
            disabled={nfEntradaId || abaValue !== 0 || !nfEntradaItens.length}
            margin="none"
            size="small"
            value={gerarAcrescimos?.subtotalDescontoPorcentagem}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
          <CurrencyInput
            id="subtotalDesconto"
            name="subtotalDesconto"
            variant="outlined"
            disabled={nfEntradaId || abaValue !== 0 || !nfEntradaItens.length}
            className="px-1"
            margin="none"
            size="small"
            value={gerarAcrescimos?.subtotalDesconto}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid item xs={3} className="d-flex align-items-center">
          <span className="me-2">Acréscimo:</span>
          <CurrencyInput
            id="subtotalAcrescimo"
            name="subtotalAcrescimo"
            variant="outlined"
            disabled={nfEntradaId || abaValue !== 0 || !nfEntradaItens.length}
            margin="none"
            size="small"
            value={gerarAcrescimos?.subtotalAcrescimo}
            onBlur={handleInputAcrescimo}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid item xs={3} className="d-flex align-items-center">
          <span className="me-2">Despesas:</span>
          <CurrencyInput
            id="subtotalDespesas"
            name="subtotalDespesas"
            variant="outlined"
            disabled={nfEntradaId || abaValue !== 0 || !nfEntradaItens.length}
            margin="none"
            size="small"
            value={gerarAcrescimos?.subtotalDespesas}
            onBlur={handleInputDespesas}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <span className="me-2">Total:</span>
          <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
            <Typography color="white" style={{ fontWeight: 700 }}>
              {formatPrice(
                totalCompra(nfEntrada, nfEntradaItens, nfEntradaTributos)
              ) || "R$ 0,00"}
            </Typography>
          </span>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResumoTotal;
