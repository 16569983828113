import { FormGroup as FormGroupMui } from "@mui/material";

const FormGroup = ({ className, children, classes, row, sx }) => {
  return (
    <FormGroupMui className={className} classes={classes} row={row} sx={sx}>
      {children}
    </FormGroupMui>
  );
};

export default FormGroup;
