const constantes = {
  profissoesList: [
    { value: "0", label: "Advogado(a)" },
    { value: "1", label: "Analista financeiro(a)" },
    { value: "2", label: "Arquiteto(a)" },
    { value: "3", label: "Azulejista" },
    { value: "4", label: "Cabeleireiro(a)" },
    { value: "5", label: "Carpinteiro(a)" },
    { value: "6", label: "Construtor(a) civil" },
    { value: "7", label: "Consultor(a) de tecnologia" },
    { value: "8", label: "Consultor(a) empresarial" },
    { value: "9", label: "Contador(a)" },
    { value: "10", label: "Dentista" },
    { value: "11", label: "Designer(a) gráfico(a)" },
    { value: "12", label: "Eletricista" },
    { value: "13", label: "Empreiteiro(a)" },
    { value: "14", label: "Encanador(a)" },
    { value: "15", label: "Engenheiro(a)" },
    { value: "16", label: "Estilista" },
    { value: "17", label: "Fisioterapeuta" },
    { value: "18", label: "Fotógrafo(a)" },
    { value: "19", label: "Gesseiro(a)" },
    { value: "20", label: "Jornalista" },
    { value: "21", label: "Ladrilheiro(a)" },
    { value: "22", label: "Maquiador(a)" },
    { value: "23", label: "Marceneiro(a)" },
    { value: "24", label: "Médico(a)" },
    { value: "25", label: "Mestre(a) de obras" },
    { value: "26", label: "Montador(a) de estruturas metálicas" },
    { value: "27", label: "Nutricionista" },
    { value: "28", label: "Paisagista" },
    { value: "29", label: "Pedreiro(a)" },
    { value: "30", label: "Personal trainer" },
    { value: "31", label: "Pintor(a)" },
    { value: "32", label: "Professor(a)" },
    { value: "33", label: "Programador(a)" },
    { value: "34", label: "Psicólogo(a)" },
    { value: "35", label: "Publicitário(a)" },
    { value: "36", label: "Serralheiro(a)" },
    { value: "37", label: "Técnico(a) em informática" },
    { value: "38", label: "Veterinário(a)" },
    { value: "39", label: "Vidraceiro(a)" },
    { value: "40", label: "Outro(a)" },
  ],
};

export default constantes;
