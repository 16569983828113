import { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CardHeader,
  DataGrid,
  DatePicker,
  Autocomplete,
  TextField,
  FormControl,
} from "../../../../components";
import { formatDate, formatPrice } from "../../../../utils";
import { InputErros, value } from "../../../../helpers";
import shortid from "shortid";

const RenegociacaoTitulosDialog = ({
  openDialogRenegociacao,
  handleSubmit,
  listaRenegociacoes,
  setListaRenegociacoes,
  sairRenegociacaoTitulos,
  listaMeiosPagamento,
  recebedoresIguais,
  recebedorList,
  pessoaId,
  setPessoaId,
}) => {
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const colunas = [
    {
      field: "meioPagamentoId",
      headerName: "Meio Pagamento",
      flex: 170,
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <div className="w-100 pt-1">
            <Autocomplete
              id="meioPagamentoId"
              name="meioPagamentoId"
              options={listaMeiosPagamento}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option?.descricao ? option.descricao : ""
              }
              disableClearable
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  variant="standard"
                  error={inputErros.get(`itens[${indiceItem}].meioPagamentoId`)}
                  disabled={params.row?.produtoCadastrado ? true : false}
                />
              )}
              onChange={(_, value) =>
                handleChangeGrid(indiceItem, "meioPagamentoId", value)
              }
              value={value.autoComplete(listaMeiosPagamento, params.value)}
            />
          </div>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 170,
      sortable: false,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "dataVencimento",
      headerName: "Data de Vencimento",
      flex: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const indiceItem = params.api.getRowIndexRelativeToVisibleRows(
          params.row.id
        );
        return (
          <div>
            <DatePicker
              id="dataVencimento"
              name="dataVencimento"
              format="dd/MM/yyyy"
              variant="standard"
              margin
              required
              disableTextfield
              error={inputErros.get(`itens[${indiceItem}].dataVencimento`)}
              fullWidth
              value={params.value}
              onChange={(value) =>
                handleChangeGrid(indiceItem, "dataVencimento", value)
              }
            />
          </div>
        );
      },
    },
  ];

  const handleChangeGrid = (indiceItem, name, value) => {
    const renegociacao = listaRenegociacoes[indiceItem];
    if (!value) return renegociacao;
    switch (name) {
      case "dataVencimento":
        renegociacao.dataVencimento = formatDate.toSend(value);
        break;
      case "meioPagamentoId":
        renegociacao.meioPagamentoId = value?.id;
        renegociacao.meioPagamento = value;
        break;
      default:
        break;
    }
    setListaRenegociacoes([
      ...listaRenegociacoes.slice(0, indiceItem),
      renegociacao,
      ...listaRenegociacoes.slice(indiceItem + 1),
    ]);
  };

  return (
    <Dialog
      maxWidth={"md"}
      open={openDialogRenegociacao}
      onClose={sairRenegociacaoTitulos}
      fullWidth
    >
      <form onSubmit={(event) => handleSubmit(event, listaRenegociacoes)}>
        <DialogContent>
          <Grid container spacing={2}>
            <CardHeader title="Parcelas - Renegociação de Títulos" />
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <Autocomplete
                  id="pessoaId"
                  name="pessoaId"
                  options={recebedorList}
                  autoHighlight
                  disabled={recebedoresIguais ? true : false}
                  getOptionLabel={(option) =>
                    option.nomeRazaoSocial
                      ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                      : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.nomeRazaoSocial === value?.nomeRazaoSocial
                  }
                  noOptionsText="Digite e pressione Enter"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Recebedor"
                      variant={recebedoresIguais ? "filled" : "outlined"}
                      required
                    />
                  )}
                  onChange={(_, value) => setPessoaId(value.id)}
                  value={value.autoComplete(recebedorList, pessoaId)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                rows={listaRenegociacoes.map((item) => ({
                  ...item,
                  id: shortid.generate(),
                }))}
                columns={colunas}
                rowCount={listaRenegociacoes.length}
                disableAcoes
                disableRowSelectionOnClick
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            overflow: "auto",
            position: "sticky",
            bottom: 0,
            margin: 1,
            backgroundColor: "white",
          }}
        >
          <Button
            margin="normal"
            type="submit"
            variant="contained"
            disableElevation
          >
            <i
              className="ph-fill ph-check-circle"
              style={{ color: "white", fontSize: 22, marginRight: 10 }}
            ></i>
            Salvar
          </Button>
          <Button
            color="padrao"
            margin="normal"
            variant="contained"
            onClick={sairRenegociacaoTitulos}
            disableElevation
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RenegociacaoTitulosDialog;
