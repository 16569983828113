import MuiButton from "./MuiButton";
import MuiIconButton from "./MuiIconButton";
import MuiSvgIcon from "./MuiSvgIcon";
import MuiPaper from "./MuiPaper";
import MuiTableCell from "./MuiTableCell";
import MuiTableHead from "./MuiTableHead";
import MuiTypography from "./MuiTypography";
import MuiSlider from "./MuiSlider";

export default {
  MuiButton,
  MuiIconButton,
  MuiSvgIcon,
  MuiPaper,
  MuiSlider,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
};
