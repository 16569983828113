import { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  DialogActions,
} from "../../../components";
import grupoTributarioValidator from "../middlewares/grupo-tributario.validator";
import { InputErros, value } from "../../../helpers";
import { SessionService } from "../../../services";

const DialogAddGrupoTributario = ({
  id,
  openDialog,
  setOpenDialog,
  grupoTributario,
  setGrupoTributario,
  atualizarGrupoTributario,
  cadastrarGrupoTributario,
  csoSimplesList,
}) => {
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const sessionService = new SessionService();
  const dadosEmpresa = sessionService.getEmpresa();
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmitGrupoTributario = (event) => {
    event.preventDefault();
    setInputErro([]);
    grupoTributarioValidator
      .validate(grupoTributario, { abortEarly: false })
      .then(() => {
        if (id) {
          atualizarGrupoTributario(id, grupoTributario);
        } else {
          cadastrarGrupoTributario(grupoTributario);
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const handleChangeInput = (event) => {
    setGrupoTributario({
      ...grupoTributario,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={dadosEmpresa?.autorizarConsultaFiscal ? "md" : "xs"}
      fullWidth
      open={openDialog}
    >
      <Card className="p-3">
        <CardHeader
          title={id ? "Editar Grupo Tributário" : "Cadastrar Grupo Tributário"}
          action={
            <FormControlLabel
              className="m-2"
              control={
                <Switch
                  checked={
                    grupoTributario?.ativado ? grupoTributario.ativado : false
                  }
                  onChange={() =>
                    setGrupoTributario((prevState) => ({
                      ...prevState,
                      ativado: !grupoTributario?.ativado,
                    }))
                  }
                  name="ativado"
                  color="primary"
                />
              }
              label={grupoTributario?.ativado ? "Ativado" : "Desativado"}
            />
          }
        />
        <form onSubmit={(event) => onSubmitGrupoTributario(event)}>
          <CardContent>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={dadosEmpresa?.autorizarConsultaFiscal ? 5 : 12}>
                <TextField
                  id="nome"
                  name="nome"
                  label="Nome"
                  fullWidth
                  required
                  error={inputErros.get("nome")}
                  variant="outlined"
                  value={grupoTributario?.nome ? grupoTributario?.nome : ""}
                  onChange={(event) => handleChangeInput(event)}
                />
              </Grid>
              {dadosEmpresa?.autorizarConsultaFiscal && (
                <Grid item xs={7}>
                  <Select
                    label="CSO"
                    id="csoSimplesNacional"
                    name="csoSimplesNacional"
                    variant="outlined"
                    error={inputErros.get("csoSimplesNacional")}
                    value={value.text(grupoTributario?.csoSimplesNacional)}
                    onChange={handleChangeInput}
                  >
                    {csoSimplesList.map((cso) => (
                      <MenuItem key={cso.codigo} value={cso.codigo}>
                        {cso.codigo} - {cso.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Salvar
            </Button>
            <Button
              onClick={() => handleCloseDialog()}
              color="padrao"
              variant="contained"
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Card>
    </Dialog>
  );
};

export default DialogAddGrupoTributario;
