import { Pagamento } from "./pagamento.entity";
export class ContaPagar {
  status = null;
  numeroTitulo = null;
  gerarFluxoCaixa = null;
  baixaAposInclusao = null;
  origem = null;
  historico = null;
  pessoaId = null;
  bancoCaixaId = null;
  dataLancamento = null;
  dataVencimento = null;
  valor = null;
  pagamentosParciais = null;
  meioPagamentoId = null;
  acrescimo = null;
  desconto = null;
  observacaoList = null;
  pagamentos = [];
  recebedorId = null;
  saldoPagar = null;
  valorTotal = null;

  constructor(body, colaborador) {
    this.status = body.status;
    this.numeroTitulo = body.numeroTitulo;
    this.gerarFluxoCaixa = body.gerarFluxoCaixa;
    this.baixaAposInclusao = body.baixaAposInclusao;
    this.origem = body.origem;
    this.historico = body.historico;
    this.pessoaId = body.pessoaId;
    this.bancoCaixaId = colaborador?.bancoCaixaId
      ? colaborador.bancoCaixaId
      : body.bancoCaixaId;
    this.dataLancamento = body.dataLancamento;
    this.dataVencimento = body.dataVencimento;
    this.valor = body.valor;
    this.pagamentosParciais = body.pagamentosParciais;
    this.meioPagamentoId = body.meioPagamentoId;
    this.acrescimo = body.acrescimo;
    this.desconto = body.desconto;
    this.observacaoList = body.observacaoList;
    this.pagamentos = body.pagamentos?.length
      ? body.pagamentos.map(
          (pagamento) => new Pagamento(pagamento, body.pagamentos, body)
        )
      : [];
    body.pagamentos;
    this.recebedorId = body.recebedorId;
    this.saldoPagar = body.saldoPagar;
    this.valorTotal = body.valorTotal;
  }

  removerObservacaoList(id) {
    if (id) {
      delete this.observacaoList;
    }
  }
}
