import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  TextFielDecimalNumbers,
  Divider,
} from "../../../../components";
import { value } from "../../../../helpers";

export default function ModeloTributacaoCofins({
  grupoModeloTributacao,
  handleInputChange,
  situacaoCofinsList,
  onChangeAutocomplete,
  indicadorCofinsSt,
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="situacaoCofinsId"
              name="situacaoCofinsId"
              options={situacaoCofinsList}
              autoHighlight
              getOptionLabel={(option) =>
                option ? `${option?.codigo} - ${option?.descricao}` : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Situação COFINS"
                  variant="outlined"
                  margin="normal"
                />
              )}
              onChange={(_, newValue) => {
                onChangeAutocomplete("situacaoCofinsId", newValue);
              }}
              value={value.autoComplete(
                situacaoCofinsList,
                grupoModeloTributacao?.situacaoCofinsId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextFielDecimalNumbers
            id="aliquotaCofins"
            name="aliquotaCofins"
            label="Alíquota COFINS"
            margin="normal"
            variant="outlined"
            fullWidth
            value={grupoModeloTributacao?.aliquotaCofins ?? ""}
            onBlur={handleInputChange}
          />
        </Grid>
      </Grid>
      {indicadorCofinsSt && (
        <>
          <Divider sx={{ margin: "5px 0", border: "3px solid grey" }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="situacaoCofinsStId"
                  name="situacaoCofinsStId"
                  options={situacaoCofinsList}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option ? `${option?.codigo} - ${option?.descricao}` : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.descricao === value?.descricao
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Situação COFINS ST"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                  onChange={(_, newValue) => {
                    onChangeAutocomplete("situacaoCofinsStId", newValue);
                  }}
                  value={value.autoComplete(
                    situacaoCofinsList,
                    grupoModeloTributacao?.situacaoCofinsStId
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextFielDecimalNumbers
                id="aliquotaCofinsSt"
                name="aliquotaCofinsSt"
                label="Alíquota COFINS ST"
                margin="normal"
                variant="outlined"
                fullWidth
                value={grupoModeloTributacao?.aliquotaCofinsSt ?? ""}
                onBlur={handleInputChange}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
