import { Grid, TextField, Autocomplete, Paper, Tabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tabProps } from "../../add-nf-saida";
import { value } from "../../../../../helpers";
import { TabPanel } from "../../../../../components";
import {
  ImpostoProdutoIcmsStTab,
  ImpostoProdutoIcmsTab,
  ImpostoProdutoIpiTab,
} from "./imposto-tabs";
import { useState } from "react";
import { unformatPrice } from "../../../../../utils";

const ImpostoProdutoForm = ({
  modeloTributacao,
  gruposTributacaoList,
  produto,
  nfSaidaId,
  nfSaida,
  ncmList,
  loadingAutoComplete,
  setLoadingAutoComplete,
  handleChangeNcm,
  produtoEditado,
  setProdutoEditado,
  onInputChangeNcm,
  inputRef,
  csoSimplesList,
  situacaoIpiList,
  enquadramentoLegalList,
  cfopList,
  setCfopList,
  buscarCfops,
}) => {
  const theme = useTheme();
  const [abaValue, setAbaValue] = useState(0);

  const onChangeAutocompleteCodigo = (name, value) => {
    setProdutoEditado({
      ...produtoEditado,
      [name]: value?.codigo ? value.codigo : undefined,
    });
  };

  const onChangeInput = (event) => {
    const name = event.target.name;
    const produtoEditadoTemp = {
      ...produtoEditado,
      [name]: unformatPrice(event.target.value) ?? 0,
    };
    /* if (name === "tributosIcmsAliquota") {
      produtoEditadoTemp.valorIcms = nfSaidaTotalTributos.calculaValorIcms(
        { ...produto, ...produtoEditado },
        nfSaida
      );
    } */
    setProdutoEditado(produtoEditadoTemp);
  };

  const impostoValueFormat = (lista, chave) => {
    if (lista) {
      return lista?.find(
        (objeto) =>
          objeto?.codigo == produto?.[chave] ||
          objeto?.codigo == produtoEditado?.[chave]
      );
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="modeloTributacaoId"
            name="modeloTributacaoId"
            label="Modelo Tributação"
            variant="outlined"
            disabled={true}
            value={modeloTributacao?.descricao || ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="grupoTributacaoId"
            name="grupoTributacaoId"
            label="Grupo Tributação"
            variant="outlined"
            disabled={true}
            value={
              value.autoComplete(
                gruposTributacaoList,
                produto?.grupoTributacaoId
              ).nome
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          {nfSaidaId ? (
            <Autocomplete
              id="ncmId"
              name="ncmId"
              options={ncmList}
              autoHighlight
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              getOptionLabel={(option) => option?.codigo ?? ""}
              filterSelectedOptions
              onChange={(_, value) => handleChangeNcm(_, value)}
              value={value.autoComplete(ncmList, produtoEditado?.ncmId)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="NCM"
                  onKeyDown={(e) => onInputChangeNcm(e)}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          ) : (
            <TextField
              id="ncmId"
              name="ncmId"
              label="NCM"
              variant="outlined"
              disabled={true}
              value={produto?.ncm}
              fullWidth
            />
          )}
        </Grid>
      </Grid>
      <Paper className="mt-4">
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="ICMS" {...tabProps(0)} />
          <Tab
            label="ICMS ST"
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            {...tabProps(1)}
          />
          <Tab label="IPI" {...tabProps(2)} />
          <Tab label="PIS" {...tabProps(3)} />
          <Tab label="COFINS" {...tabProps(4)} />
        </Tabs>
      </Paper>
      <TabPanel value={abaValue} index={0} dir={theme.direction}>
        <ImpostoProdutoIcmsTab
          produto={produto}
          produtoEditado={produtoEditado}
          csoSimplesList={csoSimplesList}
          loadingAutoComplete={loadingAutoComplete}
          setLoadingAutoComplete={setLoadingAutoComplete}
          buscarCfops={buscarCfops}
          cfopList={cfopList}
          setCfopList={setCfopList}
          impostoValueFormat={impostoValueFormat}
          onChangeAutocompleteCodigo={onChangeAutocompleteCodigo}
          onChangeInput={onChangeInput}
          nfSaida={nfSaida}
        />
      </TabPanel>
      <TabPanel value={abaValue} index={1} dir={theme.direction}>
        <ImpostoProdutoIcmsStTab
          produto={produto}
          produtoEditado={produtoEditado}
          onChangeInput={onChangeInput}
          nfSaida={nfSaida}
        />
      </TabPanel>
      <TabPanel value={abaValue} index={2} dir={theme.direction}>
        <ImpostoProdutoIpiTab
          produto={produto}
          produtoEditado={produtoEditado}
          situacaoIpiList={situacaoIpiList}
          enquadramentoLegalList={enquadramentoLegalList}
          onChangeAutocompleteCodigo={onChangeAutocompleteCodigo}
          onChangeInput={onChangeInput}
          impostoValueFormat={impostoValueFormat}
          nfSaida={nfSaida}
        />
      </TabPanel>
    </div>
  );
};

export default ImpostoProdutoForm;
