import { StepLabel as StepLabelMui } from "@mui/material";

const StepLabel = ({
  className,
  children,
  classes,
  componentsProps,
  error,
  disabled,
  icon,
  optional,
  slotProps,
  StepIconComponent,
  StepIconProps,
  sx,
}) => {
  return (
    <StepLabelMui
      className={className}
      classes={classes}
      componentsProps={componentsProps}
      error={error ? true : false}
      icon={icon}
      optional={optional}
      slotProps={slotProps}
      StepIconComponent={StepIconComponent}
      StepIconProps={StepIconProps}
      disabled={disabled ? true : false}
      sx={sx}
    >
      {children}
    </StepLabelMui>
  );
};

export default StepLabel;
