import { Chip as ChipMui } from "@mui/material";

const Chip = ({
  avatar,
  children,
  classes,
  clickable,
  color,
  component,
  deleteIcon,
  disabled,
  icon,
  label,
  onDelete,
  size,
  skipFocusWhenDisabled,
  sx,
  variant,
  style,
  className,
  key,
  ref,
}) => {
  return (
    <ChipMui
      avatar={avatar}
      classes={classes}
      clickable={clickable}
      color={color}
      component={component}
      deleteIcon={deleteIcon}
      disabled={disabled}
      icon={icon}
      label={label}
      onDelete={onDelete}
      size={size}
      skipFocusWhenDisabled={skipFocusWhenDisabled}
      sx={sx}
      variant={variant}
      style={style}
      className={className}
      key={key}
      ref={ref}
    >
      {children}
    </ChipMui>
  );
};

export default Chip;
