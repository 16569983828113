import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { PlanosFinanceirosService, notification } from "../../../services";
import AddPlanoFinanceiroView from "../add-plano-financeiro/add-plano-financeiro.view";
import { AcordeonList, Dialog, DialogContent } from "../../../components";
import { responseErros } from "../../../utils";
import { PermisoesHelper } from "../../../helpers";

const ListPlanosFinanceiros = () => {
  const [planoFinanceiro, setPlanoFinanceiro] = useState({});
  const [planosFinanceiros, setPlanosFinanceiros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState({});
  const planosFinanceirosService = new PlanosFinanceirosService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("planos-financeiros-visualizar");

  useEffect(() => {
    buscaPlanosFinanceiros();
  }, []);

  const buscaPlanosFinanceiros = async (query) => {
    const filter = {
      nonPaginated: true,
      ...query,
    };
    setLoading(true);
    const result = await planosFinanceirosService.getAll(filter);
    if (!result.isAxiosError) {
      setPlanosFinanceiros(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const cadastrarPlanosFinanceiros = async (planoFinanceiroDto) => {
    setLoading(true);
    if (planoFinanceiro.idPai) {
      planoFinanceiroDto.setIdPai(planoFinanceiro.idPai);
      planoFinanceiroDto.setCodigoPai(planosFinanceiros);
    }
    const result = await planosFinanceirosService.cadastrar(planoFinanceiroDto);
    if (!result.isAxiosError) {
      notification.cadastroSucesso();
      buscaPlanosFinanceiros();
    } else {
      responseErros(result);
    }
    setLoading(false);
    handleCloseDialog();
  };

  const atualizarPlanosFinanceiros = async (planoFinanceiroDto) => {
    setLoading(true);
    const id = planoFinanceiro.id;
    setPlanoFinanceiro({});
    const result = await planosFinanceirosService.atualizar(
      id,
      planoFinanceiroDto
    );
    if (!result.isAxiosError) {
      notification.alteracaoSucesso();
      buscaPlanosFinanceiros();
    } else {
      responseErros(result);
    }
    setLoading(false);
    handleCloseDialog();
  };

  const handleDeletePlanoFinanceiro = async (id) => {
    const existeSubordinados = planosFinanceiros.some(
      (item) => item.idPai === id
    );
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir o plano financeiro"
    );
    if (retornoAlerta.isConfirmed) {
      if (existeSubordinados) {
        return notification.alertaGenericos(
          "Precisa ser removido os planos financeiros antes do superior"
        );
      }
      setLoading(true);
      const result = await planosFinanceirosService.deletar(id);
      if (!result.isAxiosError) {
        buscaPlanosFinanceiros();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const handleEditPlanoFinanceiro = (id) => {
    const plano = planosFinanceiros.find((item) => item.id === id);
    setPlanoFinanceiro(plano);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickCadastrar = () => {
    setPlanoFinanceiro({});
    setOpenDialog(true);
  };

  return (
    <ListLayout
      title="Planos Financeiros"
      loading={loading}
      onClickCadastrar={onClickCadastrar}
      permissaoButton={permissoesHelper.temPermisao(
        "planos-financeiros-cadastrar"
      )}
    >
      <AcordeonList
        items={planosFinanceiros}
        handleEditItem={handleEditPlanoFinanceiro}
        handleDeleteItem={handleDeletePlanoFinanceiro}
        editShow={permissoesHelper.temPermisao("planos-financeiros-editar")}
        visualizarShow={permissoesHelper.temPermisao(
          "planos-financeiros-visualizar"
        )}
        deleteShow={permissoesHelper.temPermisao("planos-financeiros-excluir")}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        PaperComponent="div"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <AddPlanoFinanceiroView
            planosFinanceiros={planosFinanceiros}
            planoFinanceiro={planoFinanceiro}
            setPlanoFinanceiro={setPlanoFinanceiro}
            handleCloseDialog={handleCloseDialog}
            atualizarPlanosFinanceiros={atualizarPlanosFinanceiros}
            cadastrarPlanosFinanceiros={cadastrarPlanosFinanceiros}
          />
        </DialogContent>
      </Dialog>
    </ListLayout>
  );
};

export default ListPlanosFinanceiros;
