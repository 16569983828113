import {
  DialogContent,
  Grid,
  Typography,
  Button,
  DialogActions,
} from "../../../../../../components";
import { formatCnpj } from "../../../../../../utils";

const TransportadoraXmlStep = ({
  transportadora,
  handleCloseDialog,
  handleAvancarStep,
  handleCadastrarTransportadora,
}) => {
  const acoesTransportadoraStep = () => {
    const botaoProximo = (
      <Button variant="contained" onClick={handleAvancarStep}>
        Próximo
        <i
          className="ph-fill ph-caret-right"
          style={{ color: "#fff", fontSize: 18 }}
        ></i>
      </Button>
    );
    const botaoCadastrar = (
      <Button
        variant="contained"
        color="primary"
        onClick={handleCadastrarTransportadora}
      >
        Cadastrar
      </Button>
    );
    return transportadora?.id ? botaoProximo : botaoCadastrar;
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={7}>
            <Typography variant="overline">
              Nome da Transportadora:
              <h5 style={{ fontWeight: 800, margin: 0 }}>
                {transportadora?.nomeRazaoSocial}
              </h5>
              <br />
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="overline">
              CNPJ:
              <h5 style={{ fontWeight: 800, margin: 0 }}>
                {formatCnpj(transportadora?.cnpjCpf)}
              </h5>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mb-2">
          <Grid item xs={12}>
            <Typography variant="overline">
              {transportadora?.transportadora?.localEmbarque ? (
                <>
                  Endereço:
                  <h5 style={{ fontWeight: 800, margin: 0 }}>
                    {transportadora.transportadora.localEmbarque}
                  </h5>
                </>
              ) : (
                ""
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="d-flex align-items-center justify-content-end mb-3">
        <Button variant="contained" onClick={handleCloseDialog}>
          Fechar
        </Button>
        {acoesTransportadoraStep()}
      </DialogActions>
    </>
  );
};

export default TransportadoraXmlStep;
