import { SessionService } from "../services";
import { voltar } from "../utils";
const sessionService = new SessionService();

class PemisaoHelper {
  permisoes = null;

  constructor() {
    this.permisoes = sessionService.getPermisoes();
  }

  validarPermisao(permisaoDescricao) {
    const permitido = this.permisoes.some(
      (permisao) => permisao.nome == permisaoDescricao && permisao.habilitado
    );
    if (!permitido) {
      voltar();
    }
  }

  temPermisao(moduloNome) {
    if (Array.isArray(moduloNome)) {
      for (const nome of moduloNome) {
        const permisaoBusca = this.permisoes.some(
          (permisao) => permisao.nome === nome && permisao.habilitado
        );
        if (permisaoBusca) {
          return permisaoBusca;
        }
      }
      return false;
    } else {
      return this.permisoes.some(
        (permisao) => permisao.nome === moduloNome && permisao.habilitado
      );
    }
  }

  getPermisoesModulo(moduloNome) {
    return this.permisoes.find(
      (permisao) => permisao.nome === moduloNome && permisao.habilitado
    );
  }
}

export default PemisaoHelper;
