import { useEffect, useState } from "react";
import { Step, StepLabel, Stepper } from "../../components";
import { Minimal } from "../../layouts";
import { ImportarXmlActions, ImportarXmlStepper } from "./components";
import {
  cadastroProdutosDto,
  encontrarProduto,
  enderecoFornecedor,
  extrairDadosNFe,
  ordenarPorDescricao,
  parseXml,
} from "./importar-xml";
import { CadastroNfEntradaXml, FormatNfe, FormatXmlProdutos } from "./entities";
import { useImportContext } from "../../contexts/import.context";
import {
  CfopService,
  history,
  MeioPagamentoService,
  NcmService,
  NfEntradaService,
  notification,
  OrigemMercadoriaService,
  PessoaService,
  ProdutosService,
  UnidadeComercialService,
} from "../../services";
import { formatDate, responseErros } from "../../utils";
import { FormatCfopHelper, InputErros } from "../../helpers";
import nfEntradaItensXmlValidator from "./middlewares/nf-entrada-itens-xml.validator";
import shortid from "shortid";
import { addDays } from "date-fns";

const cfopHelper = new FormatCfopHelper();
const nfEntradaService = new NfEntradaService();
const pessoaService = new PessoaService();
const unidadeComercialService = new UnidadeComercialService();
const origemMercadoriaService = new OrigemMercadoriaService();
const produtosService = new ProdutosService();
const ncmService = new NcmService();
const cfopService = new CfopService();
const meioPagamentoService = new MeioPagamentoService();

const ImportarXmlView = () => {
  const { nfEntradaDto, setNfEntradaDto } = useImportContext();
  const [activeStep, setActiveStep] = useState(0);
  const [arquivoXml, setArquivoXml] = useState(null);
  const [dadosXml, setDadosXml] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [produtosNaoCadastrados, setProdutosNaoCadastrados] = useState([]);
  const [fornecedor, setFornecedor] = useState(null);
  const [transportadora, setTransportadora] = useState(null);
  const [listaUnidadesComerciais, setListaUnidadesComerciais] = useState([]);
  const [origensMercadorias, setOrigensMercadorias] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const steps = [
    { titulo: "Arquivo XML", funcaoAcao: buscarXmlData },
    { titulo: "Fornecedor", funcaoAcao: buscarTransportadora },
    { titulo: "Transportadora", funcaoAcao: formatarProdutos },
    { titulo: "Cadastro Produtos", funcaoAcao: cadastrarProdutos },
    { titulo: "Produtos", funcaoAcao: finalizarImportacao },
  ];

  useEffect(() => {
    buscarUnidadesComerciais();
    buscarOrigensMercadoria();
  }, []);

  const buscarUnidadesComerciais = () => {
    unidadeComercialService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setListaUnidadesComerciais(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadorias(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleFileRead = async (e) => {
    const content = e.target.result;
    const xmlData = parseXml(content.replace(/>\s+</g, "><").trim());
    const dadosExtraidos = extrairDadosNFe(xmlData, handleInitStep);
    await formatNFeData(dadosExtraidos);
  };

  function buscarXmlData() {
    if (!arquivoXml) {
      throw new Error("Por favor, inserir XML referente a NF-e");
    }
    const reader = new FileReader();
    reader.onloadend = handleFileRead;
    reader.readAsText(arquivoXml);
  }

  async function formatNFeData(data) {
    const dtoNfe = new FormatNfe({
      ...data.identificacao,
      ...data.protocolo,
      ...data.transporte,
      ...data.totais,
      fornecedor: data.fornecedor,
      informacoes: data.informacoes,
      itens: data.itens,
      pagamentos: data.pagamentos,
      duplicatas: data.duplicatas,
    });
    await buscarFornecedores(dtoNfe);
    setDadosXml(dtoNfe);
  }

  async function buscarFornecedores(xml) {
    const filtro = {
      cnpjCpf: xml?.fornecedor?.cnpjCpf,
    };
    const result = await pessoaService.getAll(filtro);
    if (!result.isAxiosError) {
      if (result.data?.rows.length) {
        const fornecedorData = result.data.rows[0];
        await buscarNfEntradaExistente(fornecedorData?.id, xml);
        setFornecedor({
          ...fornecedorData,
          endereco: enderecoFornecedor(fornecedorData?.enderecos[0]),
        });
      } else {
        try {
          await cadastrarFornecedor(xml?.fornecedor);
        } catch (error) {
          throw new Error(error.message);
        }
      }
    } else {
      throw new Error(result.response?.data?.message);
    }
  }

  async function cadastrarFornecedor(fornecedor) {
    const body = {
      ...fornecedor,
      enderecos: fornecedor?.endereco ? [fornecedor.endereco] : [],
    };
    const result = await pessoaService.cadastrarPessoaConfirmacao(body);
    if (!result.isAxiosError) {
      setFornecedor({
        ...fornecedor,
        ...result.data,
        endereco: { ...fornecedor?.endereco, pessoaId: result.data?.id },
      });
    } else {
      responseErros(result);
      throw new Error(result.response?.data?.message);
    }
  }

  async function buscarNfEntradaExistente(fornecedorId, xml) {
    if (xml?.numeroNota && xml?.serie) {
      const result = await nfEntradaService.getAllFiltroAvancado({
        fornecedorId,
        numeroNota: xml.numeroNota,
        serie: xml.serie,
        restritiva: true,
      });
      if (!result.isAxiosError) {
        if (result.data?.rows.length) {
          throw new Error(
            "Uma nota de entrada com mesmo número e série desse fornecedor já foi lançada anteriormente."
          );
        }
      } else {
        throw new Error(result.response?.data?.message);
      }
    }
  }

  async function buscarTransportadora(xml) {
    if (!xml?.transportadora) return;
    const filtro = {
      cnpjCpf: xml?.transportadora?.cnpjCpf,
    };
    const result = await pessoaService.getAll(filtro);
    if (!result.isAxiosError) {
      if (result.data?.rows.length) {
        setTransportadora({
          ...result.data.rows[0],
          endereco: result.data.rows[0].transportadoras[0],
        });
      } else {
        try {
          await cadastrarTransportadora(xml?.transportadora);
        } catch (error) {
          throw new Error(error.message);
        }
      }
    } else {
      throw new Error(result.response?.data?.message);
    }
  }

  async function cadastrarTransportadora(transportadora) {
    const body = {
      ...transportadora,
      transportadora: transportadora?.endereco,
    };
    const result = await pessoaService.cadastrarPessoaConfirmacao(body);
    if (!result.isAxiosError) {
      setTransportadora({ ...transportadora, ...result.data });
    } else {
      responseErros(result);
      throw new Error(result.response?.data?.message);
    }
  }

  async function formatarProdutos() {
    const codigosProdutos = new Set();
    const cfopsProdutos = new Set();
    dadosXml?.itens.forEach((item) => {
      codigosProdutos.add(item.prod?.NCM);
      cfopsProdutos.add(cfopHelper.contraPartida(item.prod?.CFOP));
    });
    const [listaNcm, listaCfops, produtosFornecedores] = await Promise.all([
      buscarNcms(Array.from(codigosProdutos)),
      buscarCfops(Array.from(cfopsProdutos)),
      buscarProdutosFornecedores(fornecedor?.id),
    ]);
    const [cadastrados, naoCadastrados] = [[], []];
    dadosXml?.itens.forEach((item, index) => {
      const produto = new FormatXmlProdutos(
        { ...item.prod, ...item.imposto, nItem: item.nItem },
        fornecedor?.id,
        listaNcm,
        listaCfops,
        listaUnidadesComerciais,
        origensMercadorias,
        nfEntradaDto
      );
      const itemEncontrado = encontrarProduto(produto, produtosFornecedores);
      if (itemEncontrado) {
        cadastrados.push(
          cadastroProdutosDto(
            {
              ...produto,
              ...itemEncontrado,
              precoCompra: produto?.precoCompra,
              codigoProduto: itemEncontrado?.codigoProduto,
              produtoId: itemEncontrado?.id,
              aliquotaMva: produto?.aliquotaMva,
            },
            index
          )
        );
      } else {
        naoCadastrados.push(cadastroProdutosDto(produto, index));
      }
    });
    setProdutos(ordenarPorDescricao(cadastrados));
    setProdutosNaoCadastrados(ordenarPorDescricao(naoCadastrados));
  }

  const buscarNcms = async (codigo) => {
    const filtros = {
      codigo,
      nonPaginated: true,
    };
    if (codigo.length) {
      const result = await ncmService.getAllAvancado(filtros);
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
      }
    }
  };

  const buscarCfops = async (codigo) => {
    const filtros = {
      codigo,
      nonPaginated: true,
    };
    if (codigo.length) {
      const result = await cfopService.getAllAvancado(filtros);
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
      }
    }
  };

  const buscarProdutosFornecedores = async (fornecedorId) => {
    if (!fornecedorId) return false;
    const result = await produtosService.getAllProdutosFornecedores({
      fornecedorId,
      nonPaginated: true,
    });
    if (!result.isAxiosError) {
      return result.data.map((produtoFornecedor) => ({
        ...produtoFornecedor.produto,
        codigoProduto: produtoFornecedor.codigoProduto,
      }));
    } else {
      responseErros(result);
    }
  };

  async function cadastrarProdutos() {
    if (!produtosNaoCadastrados.length) return;
    try {
      await nfEntradaItensXmlValidator.validate(produtosNaoCadastrados, {
        abortEarly: false,
      });
      const result = await produtosService.cadastrarListaProdutos(
        produtosNaoCadastrados
      );
      if (!result.isAxiosError) {
        mapearProdutosCadastrados(result?.data);
      } else {
        throw new Error(result.response?.data?.message);
      }
    } catch (err) {
      inputErros.set(err);
      responseErros(err);
      throw new Error(
        err?.errors[0] ? `${err.errors[0]} é obrigatório` : "Erro de validação"
      );
    }
  }

  const mapearProdutosCadastrados = (itensDoBanco) => {
    const produtosCadastrados = itensDoBanco.map((produto) => {
      const codigoProduto = produto.fornecedores.find(
        ({ fornecedorId }) => fornecedorId === fornecedor.id
      )?.codigoProduto;
      const itemEncontrado = encontrarProduto(
        { ...produto, codigoProduto },
        produtosNaoCadastrados
      );
      return {
        ...itemEncontrado,
        produtoId: produto.id,
      };
    });
    setProdutos((prevState) =>
      ordenarPorDescricao([...prevState, ...produtosCadastrados])
    );
    return notification.sucessoGenericos(
      "Cadastro de produtos realizado com sucesso!"
    );
  };

  const buscarMeiosPagamento = async (pagamentosDuplicatas) => {
    if (!pagamentosDuplicatas.length) return;
    const nfePagamento = Array.from(
      new Set(pagamentosDuplicatas.map(({ tPag }) => tPag))
    );
    const result = await meioPagamentoService.getAllFiltroAvancado({
      nfePagamento,
      nonPaginated: true,
    });
    if (!result.isAxiosError) {
      if (result.data.length) {
        return pagamentosDuplicatas
          .map((pagamentoDup) => {
            const meioPag = result.data.find(
              (meio) => meio.nfePagamento === pagamentoDup.tPag
            );
            if (meioPag) {
              return {
                id: shortid.generate(),
                numeroTitulo: pagamentoDup.nDup,
                dataVencimento: formatDate.toSend(
                  addDays(new Date(pagamentoDup.dVenc), 1)
                ),
                meioPagamentoId: meioPag.id,
                valorTitulo: parseFloat(pagamentoDup.vDup),
              };
            }
            return null;
          })
          .filter((pagamento) => pagamento !== null);
      }
    } else {
      responseErros(result);
      throw new Error(result.response?.data?.message);
    }
  };

  async function finalizarImportacao() {
    try {
      await nfEntradaItensXmlValidator.validate(produtos, {
        abortEarly: false,
      });

      const nfEntradaDtoXml = new CadastroNfEntradaXml({
        ...dadosXml,
        itens: produtos,
        pagamentos: await buscarMeiosPagamento(dadosXml.pagamentos),
        fornecedor,
        transportadora,
      });
      const nfEntradaDtoTemp = {
        ...nfEntradaDto,
        ...nfEntradaDtoXml,
      };
      setNfEntradaDto(nfEntradaDtoTemp);
      history.push("/estoque/nf-entrada/create");
    } catch (err) {
      inputErros.set(err);
      responseErros(err);
      throw new Error(
        err?.errors[0] ? `${err.errors[0]} é obrigatório` : "Erro de validação"
      );
    }
  }

  const handleInitStep = () => {
    setActiveStep(0);
    setArquivoXml(null);
    setDadosXml(null);
    setProdutos([]);
    setProdutosNaoCadastrados([]);
    setFornecedor(null);
    setTransportadora(null);
    setInputErro([]);
  };

  return (
    <Minimal
      title="importação xml"
      cardAction={
        <ImportarXmlActions
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleInitStep={handleInitStep}
          dadosXML={dadosXml}
          produtosNaoCadastrados={produtosNaoCadastrados}
        />
      }
    >
      <Stepper activeStep={activeStep}>
        {steps.map(({ titulo }) => (
          <Step key={titulo}>
            <StepLabel>{titulo}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ImportarXmlStepper
        activeStep={activeStep}
        arquivoXml={arquivoXml}
        setArquivoXml={setArquivoXml}
        fornecedor={fornecedor}
        transportadora={transportadora}
        produtos={produtos}
        setProdutos={setProdutos}
        produtosNaoCadastrados={produtosNaoCadastrados}
        setProdutosNaoCadastrados={setProdutosNaoCadastrados}
        inputErros={inputErros}
      />
    </Minimal>
  );
};

export default ImportarXmlView;
