import { parcelaData } from "../../nf-saida/add-nf-saida/add-nf-saida";
import { getAtrasoDias } from "../add-contas-receber/add-contas-receber";

export const montaListaRenegociacoes = (
  listaContasReceber,
  renegociacaoTitulos,
  meioPagamento,
  condicaoPagamento,
  totalAReceber,
  insertUserId
) => {
  const titulosPagadores = listaContasReceber.reduce(
    (acc, currentItem) => {
      acc[0].push(currentItem?.numeroTitulo);
      acc[1].push(currentItem?.pagadorId);
      acc[2].push(currentItem?.id);
      return acc;
    },
    [[], [], []]
  );
  const bodyMask = {
    status: "ABERTO",
    origem: "RENEGOCIAÇÃO",
    bancoCaixaId: meioPagamento?.bancoCaixaId,
    dataLancamento: new Date().toISOString(),
    meioPagamento,
    meioPagamentoId: meioPagamento.id,
    pagamentos: [],
    observacaoList: [
      {
        observacao: `DUPLICATA PROVENIENTE DE RENEGOCIAÇÃO: ${titulosPagadores[0]}`,
        createdAt: new Date().toISOString(),
        insertUserId,
      },
    ],
    pago: false,
  };
  const listaRenegociacoesTemp = [];
  for (let i = 1; i <= renegociacaoTitulos.quantidadeParcelas; i++) {
    const valor =
      totalAReceber.receber / renegociacaoTitulos.quantidadeParcelas;
    const valorFormatado = Math.floor(valor * 100) / 100;
    const restoDivisao =
      totalAReceber.receber -
      valorFormatado * renegociacaoTitulos.quantidadeParcelas;
    const calculoValorConta =
      i === renegociacaoTitulos.quantidadeParcelas
        ? valorFormatado + parseFloat(restoDivisao.toFixed(2))
        : valorFormatado;
    const body = {
      ...bodyMask,
      dataVencimento: parcelaData(
        i,
        renegociacaoTitulos.dataVencimento,
        condicaoPagamento?.periodo
      ),
      contasId: titulosPagadores[2],
      valor: calculoValorConta,
      valorTotal: calculoValorConta,
      saldoReceber: calculoValorConta,
    };
    listaRenegociacoesTemp.push(body);
  }
  return {
    pagadores: titulosPagadores[1],
    lista: listaRenegociacoesTemp,
  };
};

function calculaFloor(valor, casasDecimais = 1) {
  return (
    Math.floor(valor * Math.pow(10, casasDecimais)) /
    Math.pow(10, casasDecimais)
  );
}

export function calculaMultaJurosBaixa(
  contaReceber,
  renegociacaoTitulos,
  dataPagamento = new Date()
) {
  let [multa, juros, subtotal] = [0, 0, 0];
  const atraso = getAtrasoDias(contaReceber?.dataVencimento, dataPagamento);
  subtotal = contaReceber?.saldoReceber ?? 0;
  if (atraso <= renegociacaoTitulos?.carencia) {
    return subtotal;
  }
  multa =
    atraso > 0 && renegociacaoTitulos?.multaPorcentagem
      ? calculaFloor(
          contaReceber?.saldoReceber * renegociacaoTitulos.multaPorcentagem,
          2
        )
      : 0;
  juros =
    atraso > 0 && renegociacaoTitulos?.jurosPorcentagem
      ? calculaFloor(
          contaReceber?.saldoReceber *
            renegociacaoTitulos.jurosPorcentagem *
            atraso,
          2
        )
      : 0;
  return multa + juros + subtotal;
}

export function calculaTotalAReceber(
  listaContasReceber,
  dataPagamento,
  baixaLote
) {
  let [multa, juros, subtotal] = [0, 0, 0];
  for (const contaPagar of listaContasReceber) {
    const atraso = getAtrasoDias(contaPagar?.dataVencimento, dataPagamento);
    subtotal += contaPagar?.saldoReceber ?? 0;
    if (atraso > baixaLote?.carencia) {
      multa +=
        atraso > 0 && baixaLote?.multaPorcentagem
          ? contaPagar?.saldoReceber * baixaLote.multaPorcentagem
          : 0;
      juros +=
        atraso > 0 && baixaLote?.jurosPorcentagem
          ? contaPagar?.saldoReceber * baixaLote.jurosPorcentagem * atraso
          : 0;
    }
  }
  const receber = subtotal + multa + juros;
  return { multa, juros, subtotal, receber };
}

export const montaListaContasABaixar = (
  listaContasReceber,
  meioPagamento,
  baixaLote,
  renegociacao = false
) => {
  const contasReceber = listaContasReceber.map((conta) => {
    const total = calculaMultaJurosBaixa(
      conta,
      baixaLote,
      baixaLote?.dataPagamento ?? new Date().toISOString()
    );
    const pagamento = {
      contasReceberId: conta?.id,
      dataPagamento: baixaLote?.dataPagamento ?? new Date().toISOString(),
      bancoCaixaId: baixaLote?.bancoCaixaId ?? meioPagamento?.bancoCaixaId,
      pagadorId: conta?.pagadorId,
      meioPagamento,
      tipoBaixa: renegociacao ? "BAIXA_RENEGOCIACAO" : "BAIXA_NORMAL",
      meioPagamentoId: meioPagamento.id,
      saldoDevedor: total,
      valor: total,
    };
    return {
      ...conta,
      status: "BAIXADO",
      pagamentos: [...conta.pagamentos, pagamento],
    };
  });
  return contasReceber;
};
