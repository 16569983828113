import SiafLiteApiRepository from "../../external/siafLiteApi";

class BancosCaixasResponsaveisService {
  constructor() {
    this.urlBase = "/financeiro/bancos-caixas-responsaveis";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }
}

export default BancosCaixasResponsaveisService;
