const constantes = {
  modalidadeFrete: [
    {
      value: "mfContaEmitenteOumfContaRemetente",
      label: "Contratação do frete por conta do remetente (CIF)",
    },
    {
      value: "mfContaDestinatario",
      label: "Contratação do frete por conta do destinatário (FOB)",
    },
    {
      value: "mfContaTerceiros",
      label: "Contratação do frete por conta de terceiros",
    },
    {
      value: "mfProprioContaRemente",
      label: "Transporte próprio por conta do remetente",
    },
    {
      value: "mfProprioContaDestinatario",
      label: "Transporte próprio por conta do destinatário",
    },
    { value: "mfSemFrete", label: "Sem ocorrência de transporte" },
  ],
  estados: [
    {
      id: 1,
      cod: "12",
      nome: "Acre",
      sigla: "AC",
    },
    {
      id: 2,
      cod: "27",
      nome: "Alagoas",
      sigla: "AL",
    },
    {
      id: 3,
      cod: "13",
      nome: "Amazonas",
      sigla: "AM",
    },
    {
      id: 4,
      cod: "16",
      nome: "Amapá",
      sigla: "AP",
    },
    {
      id: 5,
      cod: "29",
      nome: "Bahia",
      sigla: "BA",
    },
    {
      id: 6,
      cod: "23",
      nome: "Ceará",
      sigla: "CE",
    },
    {
      id: 7,
      cod: "53",
      nome: "Distrito Federal",
      sigla: "DF",
    },
    {
      id: 8,
      cod: "32",
      nome: "Espirito Santo",
      sigla: "ES",
    },
    {
      id: 9,
      cod: "52",
      nome: "Goiás",
      sigla: "GO",
    },
    {
      id: 10,
      cod: "21",
      nome: "Maranhão",
      sigla: "MA",
    },
    {
      id: 11,
      cod: "31",
      nome: "Minas Gerais",
      sigla: "MG",
    },
    {
      id: 12,
      cod: "50",
      nome: "Mato Grosso do Sul",
      sigla: "MS",
    },
    {
      id: 13,
      cod: "51",
      nome: "Mato Grosso",
      sigla: "MT",
    },
    {
      id: 14,
      cod: "15",
      nome: "Pará",
      sigla: "PA",
    },
    {
      id: 15,
      cod: "25",
      nome: "Paraíba",
      sigla: "PB",
    },
    {
      id: 16,
      cod: "26",
      nome: "Pernambuco",
      sigla: "PE",
    },
    {
      id: 17,
      cod: "22",
      nome: "Piauí",
      sigla: "PI",
    },
    {
      id: 18,
      cod: "41",
      nome: "Paraná",
      sigla: "PR",
    },
    {
      id: 19,
      cod: "33",
      nome: "Rio de Janeiro",
      sigla: "RJ",
    },
    {
      id: 20,
      cod: "24",
      nome: "Rio Grande do Norte",
      sigla: "RN",
    },
    {
      id: 21,
      cod: "11",
      nome: "Rondônia",
      sigla: "RO",
    },
    {
      id: 22,
      cod: "14",
      nome: "Roraima",
      sigla: "RR",
    },
    {
      id: 23,
      cod: "43",
      nome: "Rio Grande do Sul",
      sigla: "RS",
    },
    {
      id: 24,
      cod: "42",
      nome: "Santa Catarina",
      sigla: "SC",
    },
    {
      id: 25,
      cod: "28",
      nome: "Sergipe",
      sigla: "SE",
    },
    {
      id: 26,
      cod: "35",
      nome: "São Paulo",
      sigla: "SP",
    },
    {
      id: 27,
      cod: "17",
      nome: "Tocantins",
      sigla: "TO",
    },
  ],
};

export default constantes;
