import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import {
  OperacaoFiscalService,
  PessoaService,
  CondicoesPagamentoService,
  MeioPagamentoService,
} from "../../../../services";
import { InputErros } from "../../../../helpers";
import {
  formatDate,
  responseErros,
  timeoutBuscaAutocomplete,
} from "../../../../utils";
import { format } from "date-fns";

const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const condicaoPagamentoService = new CondicoesPagamentoService();
const meioPagamentoService = new MeioPagamentoService();

const FormFiltroAvancado = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRefCliente = useRef();
  const inputRefVendedor = useRef();
  const inputRefProfissional = useRef();
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [profissionalList, setProfissionalList] = useState([]);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const listaStatusOrcPed = [
    {
      label: "Aberto",
      value: "ABERTO",
      cor: "#4FBB53",
    },
    {
      label: "Fechado",
      value: "FECHADO",
      cor: "#004780",
    },
    {
      label: "Parcialmente Fechado",
      value: "PARCIALMENTE_FECHADO",
      cor: "#EA9D2B",
    },
    {
      label: "Cancelado",
      value: "CANCELADO",
      cor: "#DC3545",
    },
  ];

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.id]: event.target.value || null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value || null,
    }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.nomeRazaoSocial ?? value.descricao : undefined,
    }));
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.value ?? null,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("De", "Até")] = format(date, "dd/MM/yyyy");
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Até", "De")] = format(date, "dd/MM/yyyy");
      }
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  useEffect(() => {
    buscarOperacoesFiscais();
    buscarCondicoesPagamento();
    buscarMeiosPagamento();
  }, []);

  const buscarOperacoesFiscais = () => {
    const filtrosOperacoesFiscais = {
      ativado: true,
      tipoOperacao: "SAIDA",
      restritiva: true,
      porIntervalo: [],
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
    };
    operacaoFiscalService
      .getAllFiltroAvancado(filtrosOperacoesFiscais)
      .then((result) => {
        if (!result.isAxiosError) {
          setOperacaoFiscalList(result.data.rows);
        } else {
          responseErros(result);
        }
      });
  };

  const buscarClientes = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarProfissional = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 5,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setProfissionalList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const buscarCondicoesPagamento = () => {
    condicaoPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    meioPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const onInputChangePessoa = (event, tiposPessoaId) => {
    const inputRefMap = {
      1: {
        inputRef: inputRefCliente,
        buscar: buscarClientes,
      },
      4: {
        inputRef: inputRefVendedor,
        buscar: buscarVendedores,
      },
      5: {
        inputRef: inputRefProfissional,
        buscar: buscarProfissional,
      },
    };
    const { inputRef, buscar } = inputRefMap[tiposPessoaId] || {};
    if (
      event.key === "Enter" &&
      inputRef?.current === document.activeElement &&
      event.target?.value?.length > 2
    ) {
      setLoadingAutoComplete(true);
      const filtros = {
        nomeRazaoSocial: event.target.value,
        apelidoFantasia: event.target.value,
      };
      timeoutBuscaAutocomplete(buscar?.(filtros));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="operacaoFiscalId"
              name="Operação-Fiscal"
              options={operacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operação Fiscal"
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete(
                  "operacaoFiscalId",
                  newValue,
                  "Operação-Fiscal"
                );
              }}
              value={valueAutocomplete(operacaoFiscalList, "operacaoFiscalId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="numeroDocumento"
            name="Número-Documento"
            label="Nº Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("numeroDocumento")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="serieDocumento"
            name="Série-Documento"
            label="Série Documento"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("serieDocumento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="clienteId"
              name="Cliente"
              options={clienteList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 1)}
                  inputRef={inputRefCliente}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("clienteId", value, "Cliente")
              }
              value={valueAutocomplete(clienteList, "clienteId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="vendedorId"
              name="Vendedor"
              options={vendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedor"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 4)}
                  inputRef={inputRefVendedor}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("vendedorId", value, "Vendedor")
              }
              value={valueAutocomplete(vendedorList, "vendedorId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="pessoaProficionalId"
              name="Profissional"
              options={profissionalList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.nomeRazaoSocial
                  ? `${option.id} - ${option.nomeRazaoSocial}`
                  : ""
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Profissional"
                  variant="outlined"
                  onKeyDown={(e) => onInputChangePessoa(e, 5)}
                  inputRef={inputRefProfissional}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "pessoaProficionalId",
                  value,
                  "Profissional"
                )
              }
              value={valueAutocomplete(profissionalList, "pessoaProficionalId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="condicaoPagamentoId"
              name="Condição-de-Pagamento"
              options={condicaoPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Condição de Pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "condicaoPagamentoId",
                  value,
                  "Condição-de-Pagamento"
                )
              }
              value={valueAutocomplete(
                condicaoPagamentoList,
                "condicaoPagamentoId"
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamentoId"
              name="Meio-de-Pagamento"
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meios de pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "meioPagamentoId",
                  value,
                  "Meio-de-Pagamento"
                )
              }
              value={valueAutocomplete(meioPagamentoList, "meioPagamentoId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStart"
            name="Data-Emissão-De"
            label="Data Emissão De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoStart")}
            onChange={(date) =>
              onChangeDate("dataEmissaoStart", date, "Data-Emissão-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEnd"
            name="Data-Emissão-Até"
            label="Data Emissão Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoEnd")}
            onChange={(date) =>
              onChangeDate("dataEmissaoEnd", date, "Data-Emissão-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            id="status"
            name="status"
            label="Status"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeSelect(event, "Status")}
            value={valueInput("status")}
          >
            {listaStatusOrcPed.map((status, index) => (
              <MenuItem key={index} value={status.value}>
                <i
                  className="ph-fill ph-circle"
                  style={{ color: `${status.cor}` }}
                ></i>
                &nbsp;{status.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
