import { useState } from "react";
import { RelatoriosLayout } from "../../../layouts";
import {
  FormFiltroAvancado,
  FormFiltroAvancadoComissaoVendedores,
  FormFiltroAvancadoContador,
  FormFiltroAvancadoNfSaida,
} from "./components";
import {
  NfSaidaService,
  OrcamentosService,
  PedidosService,
} from "../../../services";
import {
  PrintOrcamentoResumido,
  PrintNfSaidaResumido,
  PrintNfSaidaDetalhado,
  PrintPedidoResumido,
  PrintComissaoVendedores,
  PrintNfSaidaResumidoContador,
} from "../layouts";
import { responseErros } from "../../../utils";
import { PermisoesHelper } from "../../../helpers";
const orcamentosService = new OrcamentosService();
const pedidosService = new PedidosService();
const nfSaidaService = new NfSaidaService();

const RelatoriosFaturamento = () => {
  const permissoesHelper = new PermisoesHelper();
  const [loading, setLoading] = useState(false);
  const [filtrosAplicadosImpressao, setFiltrosAplicadosImpressao] = useState(
    {}
  );
  const [filtrosAvancados, setFiltrosAvancados] = useState({});
  const [dadosRelatorio, setDadosRelatorio] = useState({});

  const subgruposRelatorios = [
    {
      subgrupo: "Orçamento",
      propriedades: [
        {
          name: "300 - Orçamento Resumido",
          layoutImpressao: PrintOrcamentoResumido,
          permissao: permissoesHelper.temPermisao(
            "relatorio-orcamento-resumido"
          ),
          funcaoBuscarDadosRelatorio: buscarOrcamentoResumido,
          formulario: FormFiltroAvancado,
        },
      ],
    },
    {
      subgrupo: "Pedido",
      propriedades: [
        {
          name: "400 - Pedido Resumido",
          layoutImpressao: PrintPedidoResumido,
          permissao: permissoesHelper.temPermisao("relatorio-pedido-resumido"),
          funcaoBuscarDadosRelatorio: buscarPedidoResumido,
          formulario: FormFiltroAvancado,
        },
      ],
    },
    {
      subgrupo: "NF Saída",
      propriedades: [
        {
          name: "500 - NF Saída Resumido",
          layoutImpressao: PrintNfSaidaResumido,
          permissao: permissoesHelper.temPermisao(
            "relatorio-nf-saida-resumido"
          ),
          funcaoBuscarDadosRelatorio: buscarNfSaidaResumido,
          formulario: FormFiltroAvancadoNfSaida,
        },
        {
          name: "501 - NF Saída Detalhado",
          layoutImpressao: PrintNfSaidaDetalhado,
          permissao: permissoesHelper.temPermisao(
            "relatorio-nf-saida-detalhado"
          ),
          funcaoBuscarDadosRelatorio: buscarNfSaidaDetalhado,
          formulario: FormFiltroAvancadoNfSaida,
        },
        {
          name: "502 - Comissão de Vendedores",
          layoutImpressao: PrintComissaoVendedores,
          permissao: permissoesHelper.temPermisao(
            "relatorio-comissao-vendedores"
          ),
          funcaoBuscarDadosRelatorio: buscarComissaoVendedores,
          formulario: FormFiltroAvancadoComissaoVendedores,
        },
        {
          name: "503 - NF Saída (Contador)",
          layoutImpressao: PrintNfSaidaResumidoContador,
          permissao: permissoesHelper.temPermisao(
            "relatorio-nf-saida-contador"
          ),
          funcaoBuscarDadosRelatorio: buscarNfSaidaResumidoContador,
          formulario: FormFiltroAvancadoContador,
        },
      ],
    },
  ];

  async function buscarOrcamentoResumido(filtros) {
    setLoading(true);
    const result = await orcamentosService.relatorioResulmido(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarPedidoResumido(filtros) {
    setLoading(true);
    const result = await pedidosService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarNfSaidaResumido(filtros) {
    setLoading(true);
    const result = await nfSaidaService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarNfSaidaDetalhado(filtros) {
    setLoading(true);
    const result = await nfSaidaService.getRelatorioDetalhado(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarComissaoVendedores(filtros) {
    setLoading(true);
    const result = await nfSaidaService.getRelatorioComissaoVendedores(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarNfSaidaResumidoContador(filtros) {
    setLoading(true);
    const result = await nfSaidaService.getRelatorioResumidoContador(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  return (
    <RelatoriosLayout
      title="Relatórios de Vendas"
      subgruposRelatorios={subgruposRelatorios}
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      dadosRelatorioResumido={dadosRelatorio}
      setFiltrosAplicadosImpressao={setFiltrosAplicadosImpressao}
      filtrosAvancados={filtrosAvancados}
      setFiltrosAvancados={setFiltrosAvancados}
      loading={loading}
    />
  );
};

export default RelatoriosFaturamento;
