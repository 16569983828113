import SiafLiteApiRepository from "../../external/siafLiteApi";

class TipoContribuinteClienteService {
  constructor() {
    this.urlBase = "/integracao/tipo-contribuinte-cliente";
  }

  getAll() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }
}

export default TipoContribuinteClienteService;
