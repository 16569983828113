export class OperacaoFiscal {
  ativado = null;
  tipoOperacao = null;
  descricao = null;
  modeloDocumento = null;
  movimentacaoEstoque = null;
  movimentacaoFinanceira = null;
  naoGerarComissao = null;
  disponivelPdv = null;
  serieDocumento = null;
  presencaConsumidor = null;
  finalidade = null;
  planoFinancerioId = null;
  centroCustoId = null;
  naturezaOperacao = null;
  informacaoComplementar = null;
  informacaoFiscal = null;

  constructor(operacaoFiscal) {
    this.ativado = operacaoFiscal.ativado;
    this.tipoOperacao = operacaoFiscal.tipoOperacao;
    this.descricao = operacaoFiscal.descricao;
    this.modeloDocumento = operacaoFiscal.modeloDocumento;
    this.movimentacaoEstoque = operacaoFiscal.movimentacaoEstoque;
    this.movimentacaoFinanceira = operacaoFiscal.movimentacaoFinanceira;
    this.naoGerarComissao = operacaoFiscal.naoGerarComissao;
    this.disponivelPdv = operacaoFiscal.disponivelPdv;
    this.serieDocumento = operacaoFiscal?.serieDocumento
      ? operacaoFiscal.serieDocumento.toUpperCase().replace(/[^a-zA-Z0-9]/g, "")
      : null;
    this.presencaConsumidor = operacaoFiscal.presencaConsumidor;
    this.finalidade = operacaoFiscal.finalidade;
    this.planoFinancerioId = operacaoFiscal.planoFinancerioId;
    this.centroCustoId = operacaoFiscal.centroCustoId;
    this.naturezaOperacao = operacaoFiscal.naturezaOperacao;
    this.informacaoComplementar = operacaoFiscal.informacaoComplementar;
    this.informacaoFiscal = operacaoFiscal.informacaoFiscal;
  }
}
