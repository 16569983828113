import { Popper as PopperMui } from "@mui/material";

const Popper = ({
  id,
  children,
  className,
  open,
  anchorEl,
  container,
  elevation,
  disablePortal,
  slots,
  sx,
  placement,
  transition,
  ...rest
}) => {
  return (
    <PopperMui
      id={id}
      className={className}
      open={open}
      anchorEl={anchorEl}
      container={container}
      disablePortal={disablePortal ? true : false}
      slots={slots}
      sx={sx}
      elevation={elevation}
      transition={transition ? true : false}
      placement={placement}
      {...rest}
    >
      {children}
    </PopperMui>
  );
};

export default Popper;
