import { Tooltip, Button } from "../../../components";

const ButtonMenu = ({ caixaAberto, onClickCadastrarCaixa }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      {!caixaAberto ? (
        <Tooltip title="Abrir Caixa" arrow>
          <Button
            onClick={() => onClickCadastrarCaixa(true)}
            variant="outlined"
            color="success"
          >
            abrir caixa
            <i
              className="ph-duotone ph-shopping-bag-open"
              style={{ marginLeft: 10, fontSize: 22, color: "#4FBB4C" }}
            ></i>
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Fechar Caixa" arrow>
          <Button
            onClick={() => onClickCadastrarCaixa(false)}
            color="error"
            variant="outlined"
          >
            fechar caixa
            <i
              className="ph-duotone ph-shopping-bag"
              style={{ marginLeft: 10, fontSize: 22, color: "#DC354F" }}
            ></i>
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default ButtonMenu;
