import "../modelo-nfe.scss";

const RodapeNfe = ({ data, limiteItensPrimeiraPagina, totalItens }) => {
  return (
    <footer
      id={totalItens < limiteItensPrimeiraPagina ? "rodape" : "danfe_rodape"}
      style={{ position: "absolute", bottom: 0, width: "100%" }}
    >
      <div className="danfe_titulo_externo">Dados adicionais</div>
      <table className="danfe_tabelas">
        <tr style={{ height: "70px" }}>
          <td className="danfe_celula_bordas" width="70%">
            <p className="danfe_celula_titulo">Observa&ccedil;&otilde;es</p>
            <span>
              {data?.informacoes?.obsCont
                ? `${data?.informacoes.obsCont?.$?.xCampo} :  ${data?.informacoes?.obsCont?.xTexto}`
                : ""}
              {data?.informacoes?.infCpl ?? ""}
              &nbsp;
              <br />
            </span>
          </td>
          <td className="danfe_celula_bordas">
            <p className="danfe_celula_titulo">Reservado ao fisco</p>
            <p className="danfe_celula_valor">
              &nbsp;
              <br />
            </p>
          </td>
        </tr>
      </table>
      <p id="rodape_legenda">Adsoft - SIAF LITE</p>
    </footer>
  );
};

export default RodapeNfe;
