import { useState, createContext, useContext } from "react";

const CacheContext = createContext({});

export const CacheContextProvider = ({ children }) => {
  const [cacheDto, setCacheDto] = useState([]);

  return (
    <CacheContext.Provider value={{ cacheDto, setCacheDto }}>
      {children}
    </CacheContext.Provider>
  );
};

export const useCacheContext = () => useContext(CacheContext);
