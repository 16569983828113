import SiafLiteApiRepository from "../../external/siafLiteApi";

class PerfilsUsuariosService {
  constructor() {
    this.urlBase = "/painel-controle/perfis-usuarios";
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }
}

export default PerfilsUsuariosService;
