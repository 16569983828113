export class CadastroEmpresa {
  ativado = true;
  fantasia = null;
  razaoSocial = null;
  inscricaoEstadualIsento = null;
  inscricaoEstadual = null;
  cnae = null;
  regimeTributario = null;
  email = null;
  celular = null;
  cnpjCpf = null;
  enderecoCep = null;
  enderecoLogradouro = null;
  enderecoLogradouroNumero = null;
  enderecoComplemento = null;
  cidade = null;
  enderecoBairro = null;
  contadorAutorizarDadosXmlNfe = null;
  contadorAutorizarDownloadXmlNfe = null;
  naturezaSocial = null;
  suspenderAtividade = null;
  usuarioPadrao = null;

  constructor(cadastroEmpresa) {
    Object.assign(this, cadastroEmpresa);
    this.fantasia = cadastroEmpresa.fantasia;
    this.razaoSocial = cadastroEmpresa.razaoSocial;
    this.inscricaoEstadualIsento = cadastroEmpresa.inscricaoEstadualIsento;
    this.inscricaoEstadual = cadastroEmpresa.inscricaoEstadual
      ? cadastroEmpresa.inscricaoEstadual.replace(/\D/g, "")
      : null;
    this.cnae = cadastroEmpresa.cnae;
    this.regimeTributario = cadastroEmpresa.regimeTributario;
    this.email = cadastroEmpresa.email;
    this.celular = cadastroEmpresa.celular;
    this.cnpjCpf = cadastroEmpresa.cnpjCpf;
    this.enderecoCep = cadastroEmpresa.enderecoCep;
    this.enderecoLogradouro = cadastroEmpresa.enderecoLogradouro;
    this.enderecoLogradouroNumero = cadastroEmpresa.enderecoLogradouroNumero;
    this.enderecoComplemento = cadastroEmpresa.enderecoComplemento;
    this.cidade = cadastroEmpresa.cidade;
    this.enderecoBairro = cadastroEmpresa.enderecoBairro;
    this.contadorAutorizarDadosXmlNfe =
      cadastroEmpresa.contadorAutorizarDadosXmlNfe;
    this.contadorAutorizarDownloadXmlNfe =
      cadastroEmpresa.contadorAutorizarDownloadXmlNfe;
    this.naturezaSocial = cadastroEmpresa.naturezaSocial;
    this.suspenderAtividade = cadastroEmpresa.suspenderAtividade;
    this.usuarioPadrao = new UsuarioPadrao(cadastroEmpresa.usuarioPadrao);
  }

  verificarSenhaForte(empresa, senhaScore) {
    if (empresa?.senha && senhaScore < 2) return false;
    return true;
  }
}

class UsuarioPadrao {
  nome = null;
  email = null;
  senha = null;
  inscricaoEstadual = null;
  confirmarSenha = null;

  constructor(usuarioPadrao) {
    this.nome = usuarioPadrao.nome;
    this.email = usuarioPadrao.email;
    this.senha = usuarioPadrao.senha;
    this.inscricaoEstadual = usuarioPadrao.inscricaoEstadual;
    this.confirmarSenha = usuarioPadrao.confirmarSenha;
  }
}
