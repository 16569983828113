import { formatCnpj, formatCpf } from "../../../../../../../utils";
import "../modelo-nfe.scss";

const DestinatarioRemetenteNfe = ({ data }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">Destinatário/Remetente</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td>
              <table className="danfe_tabelas">
                <tbody>
                  <tr>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Nome / Razão Social</p>
                      <p className="danfe_celula_valor">
                        {data?.nomeRazaoSocialDestinatario}
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">CNPJ/CPF</p>
                      <p className="danfe_celula_valor valor_negrito">
                        {data?.cnpjCpfDestinatario?.length === 11
                          ? formatCpf(data?.cnpjCpfDestinatario)
                          : formatCnpj(data?.cnpjCpfDestinatario)}
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Inscrição Estadual</p>
                      <p className="danfe_celula_valor">
                        {data?.ieDestinatario}
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Data emissão</p>
                      <p className="danfe_celula_valor valor_negrito">
                        {data?.dataHora}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Endereço</p>
                      <p className="danfe_celula_valor">
                        {data?.enderecoDestinatario?.xLgr}
                        {data?.enderecoDestinatario?.nro}
                        {data?.enderecoDestinatario?.xCpl}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Bairro</p>
                      <p className="danfe_celula_valor">
                        {data?.enderecoDestinatario?.xBairro}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">CEP</p>
                      <p className="danfe_celula_valor">
                        {data?.enderecoDestinatario?.CEP}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Data saída</p>
                      <p className="danfe_celula_valor">
                        {data?.dataSaida}&nbsp;
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Município</p>
                      <p className="danfe_celula_valor">
                        {data?.enderecoDestinatario?.xMun}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">UF</p>
                      <p className="danfe_celula_valor">
                        {data?.enderecoDestinatario?.UF}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Fone/Fax</p>
                      <p className="danfe_celula_valor">
                        {data?.enderecoDestinatario?.fone}&nbsp;
                      </p>
                    </td>
                    <td className="danfe_celula_bordas">
                      <p className="danfe_celula_titulo">Hora saída</p>
                      <p className="danfe_celula_valor">
                        {data?.dataSaidaHora}&nbsp;
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DestinatarioRemetenteNfe;
