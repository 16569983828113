import * as Yup from "yup";

const planoFinanceiroValidator = Yup.object().shape({
  ativado: Yup.boolean().nullable(),
  descricao: Yup.string().required("Descrição"),
  natureza: Yup.string().required("Natureza"),
  classificacaoId: Yup.number().nullable(),
  idPai: Yup.number().nullable(),
});

export default planoFinanceiroValidator;
