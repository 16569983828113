import { useState } from "react";
import { format } from "date-fns";
import { IconButton, Grid, Typography } from "@mui/material";
import { formatDate } from "../../utils";
import "./usuario-log-style.css";

const UsuarioLog = ({
  createDate,
  editDate,
  userCreatedLog,
  userEditedLog,
  onClickLog,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    onClickLog();
    setAnchorEl(!anchorEl?.nodeName ? event.currentTarget : null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="mx-3">
      <IconButton
        className="icon"
        color="default"
        onClick={handleMenuOpen}
        //onMouseOver={handleMenuOpen}
        onMouseLeave={handleMenuClose}
      >
        <i className="ph-bold ph-clock-clockwise" style={{ fontSize: 20 }}></i>
      </IconButton>
      {anchorEl ? (
        <div className="userLogMenu">
          <div className="logsContainer">
            <Grid className="userLogContainer">
              <div className="createIndicator" />
              <Typography className="logText">
                Criado por <strong>{userCreatedLog?.nome}&nbsp;</strong>em&nbsp;
                {format(
                  formatDate.received(createDate),
                  "dd/MM/yyyy 'às' HH:mm"
                )}
              </Typography>
            </Grid>
            {editDate != createDate && (
              <Grid className="userLogContainer">
                <div className="updateIndicator" />
                <Typography className="logText">
                  Última atualização feita por
                  <strong>&nbsp;{userEditedLog?.nome}</strong> em
                  {format(
                    formatDate.received(editDate),
                    "dd/MM/yyyy 'às' HH:mm"
                  )}
                </Typography>
              </Grid>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UsuarioLog;
