import { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "../../../../components";
import { PerfilsUsuariosService } from "../../../../services";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const [perfils, setPerfils] = useState([]);
  const perfilsUsuariosService = new PerfilsUsuariosService();

  useEffect(() => {
    buscarPerfils();
  }, []);

  const buscarPerfils = () => {
    const filtros = {
      nonPaginated: true,
    };
    perfilsUsuariosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setPerfils(result.data);
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="email"
          name="email"
          label="E-mail"
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("email")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="nome"
          name="nome"
          label="Nome"
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("nome")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="pctPerfilUsuarioId"
          name="pctPerfilUsuarioId"
          options={perfils}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => (option.nome ? option.nome : "")}
          renderInput={(params) => (
            <TextField {...params} label="Perfil" variant="outlined" />
          )}
          onChange={(e, value) =>
            onChangeAutocomplete("pctPerfilUsuarioId", value)
          }
          value={valueAutocomplete(perfils, "pctPerfilUsuarioId")}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
