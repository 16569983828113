import SiafLiteApiRepository from "../../external/siafLiteApi";

class BancoService {
  constructor() {
    this.urlBase = "/financeiro/bancos";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }
}

export default BancoService;
