import * as Yup from "yup";

const meioPagamentoValidator = Yup.object().shape({
  descricao: Yup.string().required("Descrição"),
  baixaAutomatica: Yup.boolean().nullable(),
  bancoCaixaId: Yup.number().required("Conta"),
  cartaoCreditoDebito: Yup.boolean().nullable(),
  gerarCheque: Yup.boolean().nullable(),
  conciliacaoAutomatica: Yup.boolean().nullable(),
  disponivelPdv: Yup.boolean().nullable(),
  gerarBoleto: Yup.boolean().nullable(),
  nfePagamento: Yup.string().required("Nfe Pagamento"),
  ativado: Yup.boolean().nullable(),
});

export default meioPagamentoValidator;
