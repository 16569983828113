export class PlanoFinanceiro {
  descricao = null;
  ativado = null;
  natureza = null;
  classificacaoId = null;
  idPai = null;

  constructor(planoFinanceiro) {
    this.descricao = planoFinanceiro.descricao;
    this.ativado = planoFinanceiro.ativado;
    this.natureza = planoFinanceiro.natureza;
    this.classificacaoId = planoFinanceiro.classificacaoId;
    this.idPai = planoFinanceiro.idPai;
  }

  setIdPai(id) {
    this.idPai = id;
  }

  setCodigoPai(planosFinanceirosList) {
    const pai = planosFinanceirosList.find((plano) => plano.id === this.idPai);
    this.codigoPai = pai.codigo;
  }
}
