import { useRef } from "react";
import {
  Grid,
  Select,
  MenuItem,
  CurrencyInput,
  TextField,
  Autocomplete,
  FormControl,
} from "../../../../../components";
import constantes from "../../resources/constantes";

const NfSaidaTransporte = ({
  id,
  nfSaida,
  transportadoraList,
  transportadoraSelecionada,
  setTransportadoraSelecionada,
  frete,
  unformatPrice,
  setFrete,
  inputErros,
  handleInputChange,
  onChangeAutocomplete,
  onSearchChangePessoas,
  buscarDadosTransportadora,
}) => {
  const inputRef = useRef();

  const onChangeAutocompleteTransportadora = (newValue) => {
    onChangeAutocomplete("transportadoraId", newValue);
    setTransportadoraSelecionada(newValue);
    buscarDadosTransportadora(newValue?.id);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="transportadoraId"
              name="transportadoraId"
              options={transportadoraList}
              autoHighlight
              disabled={id || !nfSaida?.operacaoFiscalId ? true : false}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              getOptionLabel={(option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.nomeRazaoSocial}`;
                return option ? descricaoProduto : "";
              }}
              filterSelectedOptions
              onChange={(_, newValue) =>
                onChangeAutocompleteTransportadora(newValue)
              }
              value={transportadoraSelecionada}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label="Transportadora"
                  onKeyDown={(e) => onSearchChangePessoas(e, inputRef, 3)}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            id="modalidadeFrete"
            name="modalidadeFrete"
            margin="normal"
            label="Modalidade Frete"
            error={inputErros.get("modalidadeFrete")}
            variant={"outlined"}
            disabled={id || !nfSaida?.operacaoFiscalId ? true : false}
            value={nfSaida?.modalidadeFrete ?? "mfSemFrete"}
            onChange={(event) => handleInputChange(event)}
            required
          >
            {constantes.modalidadeFrete.map((modalidade) => (
              <MenuItem key={modalidade.value} value={modalidade.value}>
                {modalidade.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="qtdVolumes"
            name="qtdVolumes"
            label="QTD Volumes"
            type="number"
            disabled={id || !nfSaida?.operacaoFiscalId ? true : false}
            variant={"outlined"}
            value={nfSaida?.qtdVolumes}
            onChange={(event) => handleInputChange(event)}
          />
        </Grid>
        <Grid item xs={4}>
          <CurrencyInput
            id="frete"
            name="frete"
            label="Frete"
            margin="none"
            disabled={id || !nfSaida?.operacaoFiscalId ? true : false}
            variant={"outlined"}
            value={frete}
            onBlur={(e) => setFrete(unformatPrice(e.target.value))}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            label="Frete Compõe Total da Nota?"
            id="freteCompoeNota"
            name="freteCompoeNota"
            margin="none"
            disabled={id || !nfSaida?.operacaoFiscalId ? true : false}
            variant={"outlined"}
            value={nfSaida?.freteCompoeNota ?? false}
            required
            fullWidth
            onChange={(event) => handleInputChange(event)}
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

export default NfSaidaTransporte;
