import { TextareaAutosize as TextareaAutosizeMui } from "@mui/material";

const TextareaAutosize = ({
  ariaLabel,
  autoFocus,
  className,
  defaultValue,
  disabled,
  id,
  maxRows,
  minRows,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyUp,
  placeholder,
  readOnly,
  required,
  value,
  sx,
  style,
}) => {
  return (
    <TextareaAutosizeMui
      aria-label={ariaLabel}
      autoFocus={autoFocus}
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      maxRows={maxRows}
      minRows={minRows}
      maxLength={maxLength}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      value={value}
      sx={sx}
      style={style}
    />
  );
};

export default TextareaAutosize;
