import * as Yup from "yup";

const vendedorValidator = Yup.object().shape({
  dataAdmisao: Yup.date().nullable(),
  dataDemisao: Yup.date().nullable(),
  carteiraTrabalho: Yup.number().required("Carteira Trabalho"),
  comisaoTotal: Yup.number().nullable(),
  comisaoAvista: Yup.number().nullable(),
  comisaoAprazo: Yup.number().nullable(),
});

export default vendedorValidator;
