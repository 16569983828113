import { formatPrice } from "../../../../../../../utils";
import "../modelo-nfe-espelho.scss";

const ImpostoNfeEspelho = ({ data }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">Cálculo do imposto</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Base de cálculo do ICMS</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.baseIcmsTotal, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do ICMS</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.valorIcmsTotal, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">
                Base de cálculo do ICMS Subst.
              </p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.baseIcmsSt, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do ICMS Subst.</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.valorIcmsSt, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor Imposto Import.</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.vII, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do PIS</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.vPIS, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor total dos produtos</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.totalMercadoria, false) ?? "0.00"}
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do frete</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.frete, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do seguro</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.vSeg, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Desconto</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.desconto, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Despesas acessórias</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.despesas, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do IPI</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.valorIpiTotal, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do COFINS</p>
              <p className="danfe_celula_valor">
                {formatPrice(data?.vCOFINS, false) ?? "0.00"}
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor total da nota</p>
              <p className="danfe_celula_valor valor_negrito">
                {formatPrice(data?.totalVenda, false) ?? "0.00"}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ImpostoNfeEspelho;
