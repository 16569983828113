import SiafLiteApiRepository from "../../external/siafLiteApi";

class ContaReceberObservacoesService {
  constructor() {
    this.urlBase = "/financeiro/contas-receber-observacoes";
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }
}

export default ContaReceberObservacoesService;
