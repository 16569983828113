import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "../../../../components";
import { GrupoProdutosService } from "../../../../services";
import { InputErros } from "../../../../helpers";
import { formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";

const grupoProdutosService = new GrupoProdutosService();

const FormFiltroAvancadoInventario = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const [grupoProdutoList, setGrupoProdutoList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const listaTipos = [
    {
      label: "Preço de Compra",
      value: "precoCompra",
    },
    {
      label: "Preço de Custo",
      value: "precoCusto",
    },
  ];

  useEffect(() => {
    buscarGruposProduto();
  }, []);

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    if (name !== "Ordenação") {
      setFiltrosAplicadosImpressao((prevState) => ({
        ...prevState,
        [name]: event.target.value ?? null,
      }));
    }
  };

  const onChangeCheckbox = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.checked ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.checked
        ? `${event.target.checked ? "Sim" : "Não"}`
        : null,
    }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.descricao : undefined,
    }));
  };

  const onChangeInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value ?? undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  const buscarGruposProduto = () => {
    const filtros = {
      nonPaginated: true,
    };
    grupoProdutosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setGrupoProdutoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataReferencia"
            name="dataReferencia"
            label="Data Referência"
            format="dd/MM/yyyy"
            value={valueDate("dataReferencia")}
            onChange={(date) =>
              onChangeDate("dataReferencia", date, "Data-Referência")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Tipo Preço"
            id="tipoPreco"
            name="tipoPreco"
            variant="outlined"
            value={valueInput("tipoPreco")}
            onChange={(event) => onChangeSelect(event, "Tipo")}
          >
            <MenuItem value={null}>
              <p></p>
            </MenuItem>
            {listaTipos.map((tipo) => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="grupoProdutoId"
              name="grupoProdutoId"
              options={grupoProdutoList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grupo de Produto"
                  variant="outlined"
                  error={inputErros.get("grupoProdutoId")}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete(
                  "grupoProdutoId",
                  newValue,
                  "Grupo de Produtos"
                );
              }}
              value={valueAutocomplete(grupoProdutoList, "grupoProdutoId")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Ordenar Por"
            id="ordenacao"
            name="ordenacao"
            variant="outlined"
            margin="normal"
            value={valueInput("ordenacao")}
            onChange={(event) => onChangeSelect(event, "Ordenação")}
          >
            <MenuItem value={null}>
              <p></p>
            </MenuItem>
            <MenuItem value="id">Código</MenuItem>
            <MenuItem value="descricao">Descrição</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <TextField
            id="numeroPagina"
            name="numeroPagina"
            label="Nº da Página"
            variant="outlined"
            type="number"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("numeroPagina")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="Ignorar Estoque Zerado"
            labelPlacement="start"
            control={
              <Checkbox
                name="ignorarEstoqueZero"
                checked={
                  filtrosAvancados?.ignorarEstoqueZero
                    ? filtrosAvancados.ignorarEstoqueZero
                    : false
                }
                onChange={(event) =>
                  onChangeCheckbox(event, "Ignorar-Estoque-Zerado")
                }
                color="primary"
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiltroAvancadoInventario;
