export class CadastrarTransportadoraXml {
  constructor(transportadora, listaEstados) {
    if (!transportadora) {
      return false;
    }
    this.nomeRazaoSocial = transportadora.xNome;
    this.naturezaSocial = "juridica";
    this.cnpjCpf = transportadora.CNPJ || "";
    this.inscricaoEstadual = transportadora.IE || "";
    this.idTiposPessoa = [3];
    this.setInformacoesEstado(transportadora, listaEstados);
  }

  setInformacoesEstado(transportadora, listaEstados) {
    if (listaEstados.length) {
      const estado = listaEstados.find(
        ({ sigla }) => sigla === transportadora.UF
      );
      this.transportadora = {
        localEmbarque: estado?.sigla
          ? `${transportadora.xMun} - ${estado?.sigla}, ${transportadora.xEnder}`
          : "",
        ufEmbarque: estado?.id,
      };
    }
  }
}
