import {
  Autocomplete,
  Grid,
  TextField,
  Tooltip,
  TextFielDecimalNumbers,
  InputAdornment,
} from "../../../../components";
import { value } from "../../../../helpers";

export default function ModeloTributacaoIpi({
  situacaoTributariaIpiList,
  onChangeAutocomplete,
  grupoModeloTributacao,
  handleInputChange,
  enquadramentoLegalList,
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            id="situacaoTributariaIpiId"
            name="situacaoTributariaIpiId"
            options={situacaoTributariaIpiList}
            autoHighlight
            getOptionLabel={(option) =>
              option ? `${option?.codigo} - ${option?.descricao}` : ""
            }
            getOptionSelected={(option, value) =>
              option?.descricao === value?.descricao
            }
            noOptionsText="Sem opções"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Situação Tributaria - IPI"
                variant="outlined"
                margin="normal"
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocomplete("situacaoTributariaIpiId", newValue);
            }}
            value={value.autoComplete(
              situacaoTributariaIpiList,
              grupoModeloTributacao?.situacaoTributariaIpiId
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFielDecimalNumbers
            id="aliquotaIpi"
            name="aliquotaIpi"
            label="Alíquota"
            margin="normal"
            variant="outlined"
            fullWidth
            value={grupoModeloTributacao?.aliquotaIpi ?? ""}
            onBlur={handleInputChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFielDecimalNumbers
            id="excecaoTipi"
            name="excecaoTipi"
            label="Exceção TIPI"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    placement="top"
                    title={
                      <div className="modelo-div-title">
                        <span className="modelo-span">
                          Código da Regra de Exceção da TIPI (Tabela de
                          Incidência do Imposto sobre Produtos
                          Industrializados).
                        </span>
                        <br />
                        <br />
                        <span className="modelo-span">
                          Ao utilizar a regra de exceção o item informado
                          constará uma tributação da alíquota do IPI
                          diferenciada para o grupo de NCM a qual pertence.
                        </span>
                      </div>
                    }
                    arrow
                  >
                    <i
                      className="ph-fill ph-info"
                      style={{ fontSize: 22, marginRight: 10 }}
                    ></i>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            value={grupoModeloTributacao?.excecaoTipi ?? ""}
            onBlur={handleInputChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="d-flex align-items-center">
        <Grid item xs={6}>
          <Autocomplete
            id="enquadramentoLegalId"
            name="enquadramentoLegalId"
            options={enquadramentoLegalList}
            autoHighlight
            getOptionLabel={(option) =>
              option ? `${option?.codigo} - ${option?.descricao}` : ""
            }
            getOptionSelected={(option, value) =>
              option?.descricao === value?.descricao
            }
            noOptionsText="Sem opções"
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Enquadramento Legal"
                variant="outlined"
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocomplete("enquadramentoLegalId", newValue);
            }}
            value={value.autoComplete(
              enquadramentoLegalList,
              grupoModeloTributacao?.enquadramentoLegalId
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFielDecimalNumbers
            id="classeEnquadramento"
            name="classeEnquadramento"
            label="Classe de Enquadramento"
            margin="normal"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    placement="top"
                    title={
                      <div className="modelo-div-title">
                        <span className="modelo-span">
                          A informação de Classe de Enquadramento do IPI, quando
                          aplicável, deve ser informada utilizando a codificação
                          prevista nos Atos Normativos editados pela Receita
                          Federal.
                        </span>
                      </div>
                    }
                    arrow
                  >
                    <i
                      className="ph-fill ph-info"
                      style={{ fontSize: 22, marginRight: 10 }}
                    ></i>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            value={grupoModeloTributacao?.classeEnquadramento ?? ""}
            onBlur={handleInputChange}
          />
        </Grid>
      </Grid>
    </>
  );
}
