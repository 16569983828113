import * as Yup from "yup";

const nfEntradaXmlValidator = Yup.object().shape({
  fornecedor: Yup.object().shape({
    nomeRazaoSocial: Yup.string().required(),
    apelidoFantasia: Yup.string().nullable(),
    naturezaSocial: Yup.string().required(),
    cnpjCpf: Yup.string().required(),
    inscricaoEstadual: Yup.string().nullable(),
    endereco: Yup.object()
      .shape({
        cep: Yup.string().required(),
        logradouro: Yup.string().required(),
        numero: Yup.string().required(),
        bairro: Yup.string().required(),
        cidadeId: Yup.number().required(),
        pessoaId: Yup.number().required(),
      })
      .nullable(),
  }),
  itens: Yup.array().of(
    Yup.object().shape({
      tipo: Yup.string().required(),
      codigoBarra: Yup.string().nullable(),
      descricao: Yup.string().required(),
      unidadeComercialCompraId: Yup.number().nullable(),
      conversorEntrada: Yup.number().nullable(),
      fracionavel: Yup.string().nullable(),
      precoCompra: Yup.number().nullable(),
      grupoProdutoId: Yup.number().required("Grupo de Produto"),
      grupoTributacaoId: Yup.number().required("Grupo tributário"),
      generoItemId: Yup.number().nullable(),
      tipoItemId: Yup.number().nullable(),
      origemMercadoriaId: Yup.number().required("Origem Mercadoria Id"),
      ncmId: Yup.number().required("NCM ID"),
      ncmCodigo: Yup.string()
        .min(8, "NCM deve ter pelo menos 8 caracteres")
        .required("NCM Código"),
      cestId: Yup.number().nullable(),
      cfopId: Yup.number().required("CFOP"),
      informacaoAdicionalNfe: Yup.string().nullable(),
    })
  ),
});

export default nfEntradaXmlValidator;
