import { useState, useEffect } from "react";
import { GrupoPessoasService } from "../../../../services";
import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import { constantes } from "../resources";
import { value } from "../../../../helpers";
import { cacheApi, responseErros } from "../../../../utils";
import { filtrosUrl } from "../../../../helpers";

export default function FormPrestadorServico({
  prestadorServicoFormRef,
  prestadorServico,
  setPrestadorServico,
  habilitado,
  inputErros,
  cacheDto,
  setCacheDto,
}) {
  const [listaGrupoPessoa, setListaGrupoPessoa] = useState([]);
  const grupoPessoasService = new GrupoPessoasService();

  useEffect(() => {
    buscaGruposPessoas();
  }, []);

  const buscaGruposPessoas = async () => {
    const filtros = {
      ativado: true,
      tipoPessoa: 5,
      restritiva: true,
    };
    const label = filtrosUrl.getUrl(filtros, grupoPessoasService.urlBase);
    const cacheBody = {
      apiFunction: grupoPessoasService.getAllFiltroAvancado(filtros),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(label, cacheBody);
    if (!res.isAxiosError) {
      setListaGrupoPessoa(res.data.rows);
    } else {
      responseErros(res);
    }
  };

  const handleChangeInput = (event) => {
    setPrestadorServico({
      ...prestadorServico,
      [event.target.name]: event.target.value ? event.target.value : null,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setPrestadorServico({
      ...prestadorServico,
      [name]: value ? value.id : null,
    });
  };

  return (
    <form ref={prestadorServicoFormRef}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Select
            label="Profissão"
            id="profissao"
            name="profissao"
            error={inputErros.get("profissao")}
            value={prestadorServico?.profissao || ""}
            onChange={handleChangeInput}
          >
            {constantes.profissoesList.map((profissao) => (
              <MenuItem key={profissao.value} value={profissao.label}>
                {profissao.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="grupoPessoaId"
              name="grupoPessoaId"
              disabled={habilitado ? false : true}
              options={listaGrupoPessoa}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              filterSelectedOptions
              onChange={(_, value) => {
                handleChangeAutocomplete("grupoPessoaId", value);
              }}
              value={value.autoComplete(
                listaGrupoPessoa,
                prestadorServico.grupoPessoaId
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={habilitado ? "outlined" : "filled"}
                  label="Grupos de Pessoas"
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
