import "./inicio.style.css";
import { AppBar, Button, Grid, Paper, Toolbar } from "../../components";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { history } from "../../services";

const Inicio = () => {
  const caracteristicas = [
    { icone: "ph-bold ph-user-circle", descricao: "Cadastro Simplificado" },
    { icone: "ph-bold ph-piggy-bank", descricao: "Controle de Caixa" },
    { icone: "ph-bold ph-scroll", descricao: "Notas Fiscais" },
    { icone: "ph-bold ph-shopping-cart", descricao: "Controle de Estoque" },
    { icone: "ph-bold ph-calculator", descricao: "Gestão Financeira" },
    { icone: "ph-bold ph-chart-line-up", descricao: "Analise Resultados" },
  ];

  return (
    <body className="landing-page">
      <AppBar position="static" color="white" className="topBarInicio">
        <Toolbar className="estilo-toolbar">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Paper className="estilo-paper">
                <strong style={{ color: "#2196F3" }}>SIAF LITE</strong>
              </Paper>
            </Grid>
            <Grid item xs={8} className="d-flex justify-content-center">
              <Button
                variant="outlined"
                color="padrao"
                className="data-top"
                disabled
              >
                <strong>
                  {format(new Date(), "EE - dd", { locale: ptBR })}
                </strong>
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                sx={{ borderRadius: "0.7em" }}
                onClick={() => history.push("/loading")}
                fullWidth
              >
                <strong>Entrar</strong>
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className="content">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className="info-inicio-box">
              <div className="textos-box">
                <h1 className="titulo-box">
                  Simplificando, <br />
                  Impulsionando, <br />
                  Ampliando, <br />
                  Focando no Seu Negócio
                </h1>
                <h5 className="subtitulo-box">
                  Mude agora o jeito que você gerencia sua empresa:
                </h5>
              </div>
              <Grid container spacing={2}>
                {caracteristicas.map((caracteristica, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <div className="caracteristica">
                      <i
                        className={`${caracteristica.icone} mx-3`}
                        style={{ color: "#0c2f6e", fontSize: "1.3em" }}
                      ></i>
                      <strong className="paragrafo-box">
                        {caracteristica.descricao}
                      </strong>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <Button
                onClick={() => history.push("/cadastros/empresa")}
                className="botao-box"
                variant="contained"
              >
                <strong>Criar minha conta</strong>
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://siaflite-blob-bfesffcjhagbc4f6.z01.azurefd.net/imagem-publica/imagem-landing.jpg"
              alt="imagem inicial"
              className="landing-image1"
            />
          </Grid>
        </Grid>
      </div>
    </body>
  );
};

export default Inicio;
