import { Button as ButtonMui } from "@mui/material";

const Button = ({
  id,
  children,
  className,
  classes,
  color,
  disabled,
  disableElevation,
  disableFocusRipple,
  disableRipple,
  startIcon,
  endIcon,
  fullWidth,
  href,
  size,
  variant,
  sx,
  style,
  type,
  form,
  onClick,
  onFocus,
  onBlur,
  ariaHaspopup,
  ariaExpanded,
  ariaControls,
}) => {
  return (
    <ButtonMui
      id={id}
      className={className}
      classes={classes}
      color={color ?? "primary"}
      disabled={disabled ?? false}
      disableElevation={disableElevation ?? false}
      disableFocusRipple={disableFocusRipple ?? false}
      disableRipple={disableRipple ?? false}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      href={href}
      size={size ?? "medium"}
      variant={variant}
      sx={sx}
      style={style}
      type={type}
      form={form}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
    </ButtonMui>
  );
};

export default Button;
