import axios from "axios";

function createAxiosConfig(baseURL, headers) {
  const instance = axios.create({
    baseURL,
    headers,
  });
  return instance;
}

export default createAxiosConfig;
