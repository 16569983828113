import ReceitaApiRepository from "../../external/receitaApi";

class ReceitaCnpjCpfService {
  constructor() {
    this.urlBase = "https://brasilapi.com.br/api/cnpj/v1";
  }

  buscarCaptcha(tipo) {
    return new ReceitaApiRepository(`${this.urlBase}/captcha/${tipo}`).get();
  }

  buscarCnpj(cnpj) {
    return new ReceitaApiRepository(`${this.urlBase}/${cnpj}`).get();
  }

  buscarCnpjCpf(captcha, tipo, cnpjCpf, dataNascimento, guid) {
    let body = {
      captcha,
      cnpj: cnpjCpf,
      guid,
    };
    if (tipo == "cpf") {
      body = {
        captcha,
        dataNascimento,
        cpf: cnpjCpf,
        guid,
      };
    }
    return new ReceitaApiRepository(`${this.urlBase}/${tipo}`).post(body);
  }
}

export default ReceitaCnpjCpfService;
