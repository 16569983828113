import { createRef } from "react";
import { useDropzone } from "react-dropzone";
import {
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Tooltip,
  Typography,
} from "../../../../../../components";
const iconeXML =
  require("../../../../../../../src/assets/images/iconXML.svg").default;

const SelecionarArquivoXmlStep = ({
  arquivoSelecionado,
  xmlFile,
  handleXmlChange,
  initXmlClose,
  handleImportarXml,
}) => {
  const xmlUpload = createRef();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "text/xml",
    onDrop: (acceptedFiles) => {
      handleXmlChange(acceptedFiles);
    },
  });

  return (
    <DialogContent>
      <Grid
        container
        spacing={2}
        className="d-flex justify-content-center mb-3"
      >
        <img src={iconeXML} width={"130px"} alt="Ícone XML" />
      </Grid>
      <Grid container spacing={2} className="d-flex justify-content-center">
        {arquivoSelecionado ? (
          <>
            <Grid item xs={12} className="d-flex justify-content-center">
              <Tooltip title={xmlFile?.name} placement="top">
                <label htmlFor="contained-button-file" {...getRootProps()}>
                  <input
                    {...getInputProps()}
                    type="file"
                    id="contained-button-file"
                    ref={xmlUpload}
                    style={{ display: "none" }}
                    onChange={(event) => handleXmlChange(event)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#4FBB53" }}
                    size="large"
                    onClick={() => xmlUpload.current.click()}
                  >
                    Arquivo Selecionado
                  </Button>
                </label>
              </Tooltip>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center">
              <Typography>{xmlFile?.name}</Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={5}>
            <Tooltip
              title="Arraste ou clique para carregar XML"
              placement="right"
            >
              <label htmlFor="contained-button-file" {...getRootProps()}>
                <input
                  {...getInputProps()}
                  type="file"
                  id="contained-button-file"
                  ref={xmlUpload}
                  style={{ display: "none" }}
                  onChange={(event) => handleXmlChange(event)}
                />
                {isDragActive ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ backgroundColor: "#1768aa" }}
                      size="large"
                      onClick={() => xmlUpload.current.click()}
                    >
                      Solte o arquivo aqui
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => xmlUpload.current.click()}
                  >
                    Selecionar Arquivo XML
                  </Button>
                )}
              </label>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <DialogActions className="d-flex align-items-center justify-content-center mb-3">
        <Button variant="contained" color="padrao" onClick={initXmlClose}>
          Fechar
        </Button>
        <Button variant="contained" color="primary" onClick={handleImportarXml}>
          Importar
        </Button>
      </DialogActions>
    </DialogContent>
  );
};

export default SelecionarArquivoXmlStep;
