import { differenceInYears } from "date-fns";

export function calcularIdade(dateOfBirth) {
  if (!dateOfBirth) return "";
  const parsedDateOfBirth = new Date(dateOfBirth);
  const age = differenceInYears(new Date(), parsedDateOfBirth);
  return age;
}

export function setInputValuePessoa(pessoa) {
  const idMap = {
    nomeRazaoSocial: "nomeRazaoSocial",
    apelidoFantasia: "apelidoFantasia",
    dataAbertura: "dataAberturaNascimento",
    dataNascimento: "dataAberturaNascimento",
    idade: "idade",
    inscricaoEstadual: "inscricaoEstadual",
    inscricaoEstadualRural: "inscricaoEstadualRural",
    cnpjCpf: "cnpjCpf",
    rg: "registroGeral",
    contato: "contato",
    email: "email",
    telefone: "telefone",
    celular: "celular",
    observacao: "observacao",
  };

  for (const key in pessoa) {
    if (pessoa[key] !== null && idMap[key]) {
      const elemento = document.getElementById(idMap[key]);
      if (elemento) {
        elemento.value =
          elemento.type == "number"
            ? pessoa[key].replace(/[^0-9]/g, "")
            : pessoa[key];
      }
    }
  }
}
