import { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Loading,
} from "../../../components";
import {
  notification,
  ClassificacaoFinanceiraService,
} from "../../../services";
import { InputErros, PermisoesHelper } from "../../../helpers";
import { responseErros } from "../../../utils";
import classificacaoFinanceiraValidator from "./middlewares/classificacao-financeira.validator";
import { ClassificacaoFinanceiraEntity } from "../entities";

const AddClassificacaoFinanceira = ({
  id,
  handleCloseDialog,
  openDialog,
  buscaListaClassificacaoFinanceira,
}) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("classificacoes-financeiras-visualizar");
  const [loading, setLoading] = useState(false);
  const [classificacaoFinanceira, setClassificacaoFinanceira] = useState({
    ativado: true,
  });
  const classificacaoFinanceiraService = new ClassificacaoFinanceiraService();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    if (openDialog && id) {
      buscaClassificacaoFinanceira();
    }
  }, [openDialog]);

  const buscaClassificacaoFinanceira = async () => {
    setLoading(true);
    const { data } = await classificacaoFinanceiraService.getById(id);
    setClassificacaoFinanceira(data);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    e.persist();
    setClassificacaoFinanceira((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    classificacaoFinanceiraValidator
      .validate(classificacaoFinanceira, { abortEarly: false })
      .then(async () => {
        const classificacaoFinanceiraEntity = new ClassificacaoFinanceiraEntity(
          classificacaoFinanceira
        );
        if (id) {
          const response = await classificacaoFinanceiraService.atualizar(
            id,
            classificacaoFinanceiraEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            handleCloseDialog();
            buscaListaClassificacaoFinanceira();
          } else {
            responseErros(response);
          }
        } else {
          const response = await classificacaoFinanceiraService.cadastrar(
            classificacaoFinanceiraEntity
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            handleCloseDialog();
            buscaListaClassificacaoFinanceira();
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  return (
    <Container>
      <Card className="p-3">
        <CardHeader
          title={
            classificacaoFinanceira?.id
              ? "Editar Classificacao"
              : "Cadastrar Classificacao"
          }
          action={
            <FormControlLabel
              control={
                <Switch
                  checked={classificacaoFinanceira?.ativado ?? false}
                  onChange={() =>
                    setClassificacaoFinanceira((prevState) => ({
                      ...prevState,
                      ativado: !classificacaoFinanceira?.ativado,
                    }))
                  }
                  name="ativado"
                  color="primary"
                />
              }
              label={
                classificacaoFinanceira?.ativado ? "Ativado" : "Desativado"
              }
            />
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="nome"
                name="nome"
                label="Nome"
                variant="outlined"
                margin="normal"
                value={classificacaoFinanceira?.nome ?? ""}
                onChange={(e) => {
                  setInputErro([]);
                  handleInputChange(e);
                }}
                fullWidth
                required
                error={inputErros.get("nome")}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Salvar
          </Button>
          <Button
            color="padrao"
            variant="contained"
            onClick={handleCloseDialog}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

export default AddClassificacaoFinanceira;
