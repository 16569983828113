import JSEncrypt from "jsencrypt";
import store from "../config/store";

const encryptData = (value) => {
  const jsEncrypt = new JSEncrypt();
  if (typeof value == "object") {
    let dataString = "";
    const objValues = Object.values(value);
    for (const objValue of objValues) {
      const lastObjValue = objValues.slice(-1);
      dataString += objValue == lastObjValue ? objValue : `${objValue}:`;
    }
    jsEncrypt.setPublicKey(store.publicKey);
    return jsEncrypt.encrypt(dataString);
  } else {
    jsEncrypt.setPublicKey(store.publicKey);
    return jsEncrypt.encrypt(value);
  }
};

export default encryptData;
