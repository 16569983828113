class WhatsappService {
  constructor() {
    this.urlBase = "https://api.whatsapp.com/send?";
  }

  send(phone, text) {
    return `${this.urlBase}phone=+55${phone}&text=${text}`;
  }
}

export default WhatsappService;
