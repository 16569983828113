import { StorageHelper } from "./index";
import { history } from "../services";

class LinkTelasHelper {
  constructor() {
    this.storageHelper = new StorageHelper();
  }

  set(body) {
    const url = window.location.pathname;
    const objeto = {
      body,
      url,
    };
    this.storageHelper.setSession("linkTelas", JSON.stringify(objeto));
  }

  get() {
    const url = window.location.pathname;
    const session = this.storageHelper.getSession("linkTelas");
    if (session) {
      const link = JSON.parse(session);
      if (link.url == url) {
        this.storageHelper.removeSession("linkTelas");
        return link.body;
      }
    }
    return null;
  }

  redirecionar() {
    const session = this.storageHelper.getSession("linkTelas");
    if (session) {
      const link = JSON.parse(session);
      history.push(link.url);
    }
  }
}

export default LinkTelasHelper;
