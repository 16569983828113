import { CircularProgress as CircularProgressMui } from "@mui/material";

const CircularProgress = ({
  classes,
  color,
  disableShrink,
  size,
  sx,
  thickness,
  value,
  variant,
  className,
  ref,
}) => {
  return (
    <CircularProgressMui
      className={className}
      classes={classes}
      color={color}
      disableShrink={disableShrink}
      size={size}
      sx={sx}
      thickness={thickness}
      value={value}
      variant={variant}
      ref={ref}
    />
  );
};

export default CircularProgress;
