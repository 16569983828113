import { useState, useEffect } from "react";
import {
  FormGroup,
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  DatePicker,
  TextFielDecimalNumbers,
} from "../../../../components";
import {
  BancosCaixasService,
  GrupoPessoasService,
  UsuarioService,
} from "../../../../services";
import { value } from "../../../../helpers";
import { Checkbox } from "@mui/material";
import { cacheApi, responseErros, unformatPrice } from "../../../../utils";
import { filtrosUrl } from "../../../../helpers";

export default function FormColaborador({
  colaboradorFormRef,
  colaborador,
  setColaborador,
  habilitado,
  inputErros,
  cacheDto,
  setCacheDto,
}) {
  const [listaGrupoPessoa, setListaGrupoPessoa] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [contas, setContas] = useState([]);
  const grupoPessoasService = new GrupoPessoasService();
  const usuarioService = new UsuarioService();
  const bancosCaixasService = new BancosCaixasService();

  useEffect(() => {
    buscaGruposPessoas();
    buscarUsuarios();
    buscaContas();
  }, []);

  async function buscaContas() {
    bancosCaixasService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setContas(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  }

  const buscaGruposPessoas = async () => {
    const filtros = {
      ativado: true,
      tipoPessoa: 4,
      restritiva: true,
    };
    const label = filtrosUrl.getUrl(filtros, grupoPessoasService.urlBase);
    const cacheBody = {
      apiFunction: grupoPessoasService.getAllFiltroAvancado(filtros),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(label, cacheBody);
    if (!res.isAxiosError) {
      setListaGrupoPessoa(res.data.rows);
    } else {
      responseErros(res);
    }
  };

  const buscarUsuarios = function () {
    usuarioService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setListaUsuarios(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const handleCheckBox = (event) => {
    const colaboradorTemp = {
      ...colaborador,
      [event.target.name]: event.target.checked,
    };
    if (!event.target.checked) {
      colaboradorTemp.comissaoVista = null;
      colaboradorTemp.comissaoPrazo = null;
    }
    setColaborador(colaboradorTemp);
  };

  const handleChangeAutocomplete = (name, value) => {
    setColaborador({
      ...colaborador,
      [name]: value ? value.id : null,
    });
  };

  const handleChangeData = (prop, data) => {
    if (!data) {
      data = "Invalid Date";
    }
    setColaborador({
      ...colaborador,
      [prop]: data != "Invalid Date" ? data : null,
    });
  };

  const handleChangeInput = (event) => {
    setColaborador({
      ...colaborador,
      [event.target.name]: event.target.value ? event.target.value : null,
    });
  };
  const handleChangeCurrencyInput = (event) => {
    const valor = unformatPrice(event.target.value);
    setColaborador({
      ...colaborador,
      [event.target.name]: valor ? Number(valor) : null,
    });
  };

  return (
    <form ref={colaboradorFormRef}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <TextField
            label="Função"
            name="funcao"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            margin="normal"
            fullWidth
            onChange={handleChangeInput}
            value={colaborador.funcao ?? ""}
            error={inputErros.get("funcao")}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            label="Data Admissao"
            name="dataAdmissao"
            format="dd/MM/yyyy"
            margin="normal"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            value={colaborador?.dataAdmissao ? colaborador.dataAdmissao : null}
            onChange={(data) => handleChangeData("dataAdmissao", data)}
            error={inputErros.get("dataAdmissao")}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            label="Data Demissão"
            name="dataDemissao"
            format="dd/MM/yyyy"
            margin="normal"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            value={colaborador?.dataDemissao ? colaborador.dataDemissao : null}
            onChange={(data) => handleChangeData("dataDemissao", data)}
          />
        </Grid>
        <Grid item xs={2}>
          <FormGroup className="align-items-center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={colaborador.vendedor ?? false}
                  onClick={handleCheckBox}
                  color="primary"
                  value={colaborador?.vendedor}
                  name="vendedor"
                />
              }
              label="Vendedor"
            />
          </FormGroup>
        </Grid>
        {colaborador?.vendedor && (
          <Grid item xs={3}>
            <TextFielDecimalNumbers
              name="descontoMaximo"
              label="Desconto máximo"
              margin="normal"
              disabled={!colaborador.vendedor}
              value={colaborador.descontoMaximo ?? ""}
              onBlur={handleChangeCurrencyInput}
              error={inputErros.get("descontoMaximo")}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="grupoPessoaId"
              name="grupoPessoaId"
              disabled={habilitado ? false : true}
              options={listaGrupoPessoa}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              filterSelectedOptions
              onChange={(_, value) => {
                handleChangeAutocomplete("grupoPessoaId", value);
              }}
              value={value.autoComplete(
                listaGrupoPessoa,
                colaborador?.grupoPessoaId
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={habilitado ? "outlined" : "filled"}
                  label="Grupos de Pessoas"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="bancoCaixaId"
              name="bancoCaixaId"
              options={contas}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option?.nome ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Banco Caixa"
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  error={inputErros.get("bancoCaixaId")}
                />
              )}
              onChange={(e, value) =>
                handleChangeAutocomplete("bancoCaixaId", value)
              }
              value={value.autoComplete(contas, colaborador?.bancoCaixaId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="usuarioId"
              name="usuarioId"
              options={listaUsuarios}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option?.nome ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Usuários"
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  error={inputErros.get("usuarioId")}
                />
              )}
              onChange={(e, value) =>
                handleChangeAutocomplete("usuarioId", value)
              }
              value={value.autoComplete(listaUsuarios, colaborador?.usuarioId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextFielDecimalNumbers
            label="Comissão - A Prazo"
            name="comissaoPrazo"
            disabled={!colaborador.vendedor}
            variant={colaborador.vendedor ? "outlined" : "filled"}
            margin="normal"
            fullWidth
            onBlur={handleChangeCurrencyInput}
            value={colaborador.comissaoPrazo ?? ""}
            error={inputErros.get("comissaoPrazo")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFielDecimalNumbers
            label="Comissão - A Vista"
            name="comissaoVista"
            disabled={!colaborador.vendedor}
            variant={colaborador.vendedor ? "outlined" : "filled"}
            margin="normal"
            fullWidth
            onBlur={handleChangeCurrencyInput}
            value={colaborador.comissaoVista ?? ""}
            error={inputErros.get("comissaoVista")}
          />
        </Grid>
      </Grid>
    </form>
  );
}
