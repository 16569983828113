import { formatCnpj, formatCpf } from "../../../../../../../utils";
import constantes from "../../../../resources/constantes";
import "../modelo-nfe-espelho.scss";

const TransporteNfeEspelho = ({ data }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">
        Transportador/Volumes transportados
      </h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td className="danfe_celula_bordas" colSpan="3">
              <p className="danfe_celula_titulo">Razão Social</p>
              <p className="danfe_celula_valor">
                {data?.transportadoraNome}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Frete por conta</p>
              <p className="danfe_celula_valor">
                {
                  constantes.modalidadeFrete.find(
                    (modalidade) => modalidade.value === data?.modalidadeFrete
                  )?.label
                }
                &nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Código ANTT</p>
              <p className="danfe_celula_valor">{data?.codigoANTT}&nbsp;</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Placa do veículo</p>
              <p className="danfe_celula_valor">
                {data?.transportadora?.veicTransp?.placa}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">UF</p>
              <p className="danfe_celula_valor">
                {data?.transportadoraEnderecoUf}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">CNPJ/CPF</p>
              <p className="danfe_celula_valor">
                {data?.transportadoraCnpjCpf?.length === 11
                  ? formatCpf(data?.transportadoraCnpjCpf)
                  : formatCnpj(data?.transportadoraCnpjCpf)}
                &nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas" colSpan="3">
              <p className="danfe_celula_titulo">Endereço</p>
              <p className="danfe_celula_valor">
                {data?.transportadoraEnderecoRua}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas" colSpan="3">
              <p className="danfe_celula_titulo">Município</p>
              <p className="danfe_celula_valor">
                {data?.transportadoraEnderecoMunicipio}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">UF</p>
              <p className="danfe_celula_valor">
                {data?.transportadoraEnderecoUf}&nbsp;
              </p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Inscrição Estadual</p>
              <p className="danfe_celula_valor">
                {data?.transportadoraIe}&nbsp;
              </p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Quantidade</p>
              <p className="danfe_celula_valor">{data?.qtdVolumes}&nbsp;</p>
            </td>
            <td className="danfe_celula_bordas" colSpan="2">
              <p className="danfe_celula_titulo">Espécie</p>
              <p className="danfe_celula_valor">{data?.volume?.esp}&nbsp;</p>
            </td>
            <td className="danfe_celula_bordas" colSpan="2">
              <p className="danfe_celula_titulo">Marca</p>
              <p className="danfe_celula_valor">{data?.volume?.marca}&nbsp;</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Numeração</p>
              <p className="danfe_celula_valor">{data?.volume?.nVol}&nbsp;</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Peso bruto</p>
              <p className="danfe_celula_valor">{data?.volume?.pesoB}&nbsp;</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Peso líquido</p>
              <p className="danfe_celula_valor">{data?.volume?.pesoL}&nbsp;</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TransporteNfeEspelho;
