import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Button,
  CardActions,
  Dialog,
  Autocomplete,
} from "../../../../../components";

const CampoPersonalizadoDialog = ({
  openCampoPersonalizadoDialog,
  setOpenCampoPersonalizadoDialog,
  onInputChangeCamposPersonalizados,
  handleAdicionarCampoPersonalizado,
  campoPersonalizado,
  setCampoPersonalizado,
  tiposCampo,
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      PaperComponent="div"
      maxWidth="xs"
      open={openCampoPersonalizadoDialog}
    >
      <Card className="p-3">
        <CardHeader title="Adicionar Campo Personalizado" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="tipo"
                name="tipo"
                className="mt-3"
                options={tiposCampo}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo campo"
                    required
                    variant="outlined"
                  />
                )}
                onChange={(e, value) => {
                  setCampoPersonalizado({
                    ...campoPersonalizado,
                    tipo: value,
                  });
                }}
                value={campoPersonalizado.tipo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="label"
                name="label"
                label="Descrição"
                variant="outlined"
                margin="normal"
                fullWidth
                value={campoPersonalizado.label || ""}
                onChange={(event) => onInputChangeCamposPersonalizados(event)}
                required
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAdicionarCampoPersonalizado}
          >
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            ></i>
            Adicionar
          </Button>
          <Button
            color="default"
            variant="contained"
            onClick={() => setOpenCampoPersonalizadoDialog(false)}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Cancelar
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default CampoPersonalizadoDialog;
