export const listaStatus = [
  {
    id: 1,
    label: "Aberto",
    value: "ABERTO",
    cor: "#4FBB53",
  },
  {
    id: 2,
    label: "Baixado Parcial",
    value: "BAIXADO_PARCIAL",
    cor: "#EA9D2B",
  },
  {
    id: 3,
    label: "Baixado",
    value: "BAIXADO",
    cor: "#004780",
  },
  {
    id: 4,
    label: "Cancelado",
    value: "CANCELADO",
    cor: "#DC3545",
  },
];

export const listaTiposContasPagar = [
  {
    label: "Título normal",
    value: "TITULO_NORMAL",
  },
  {
    label: "Nota de débito",
    value: "NOTA_DEBITO",
  },
];

export const listaTiposContasReceber = [
  {
    label: "Título Normal",
    value: "TITULO_NORMAL",
  },
  {
    label: "Nota de Crédito",
    value: "NOTA_CREDITO",
  },
];
