import { NcmService, notification } from "../../../../services";

const ncmService = new NcmService();
export function editarCelulaNcm(params, event, dadosXML, setDadosXML) {
  if (event.key === "Enter") {
    const indiceItem = dadosXML.itens.findIndex(
      (item) => item.id === params.row.id
    );
    const itens = [...dadosXML.itens];
    if (event.target.value?.length < 3)
      return notification.alertaGenericos(
        "Insira ao menos os 3 primeiros números."
      );
    ncmService.getAll({ codigo: event.target.value }).then((result) => {
      if (!result.isAxiosError && result.data.count) {
        const ncm = result.data.rows.find((ncm) =>
          ncm.codigo.startsWith(event.target.value)
        );
        itens[indiceItem].ncmCodigo = ncm.codigo;
        itens[indiceItem].ncmId = ncm.id;
        setDadosXML((prevState) => ({ ...prevState, itens }));
      } else {
        itens[indiceItem].ncmCodigo = "";
        itens[indiceItem].ncmId = null;
        setDadosXML((prevState) => ({ ...prevState, itens }));
        return notification.alertaGenericos("NCM não encontrado");
      }
    });
  }
}
