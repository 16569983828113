import { Grid, TextareaAutosize } from "../../../../../components";

const PedidosCampos = ({ pedido, handleChangeInput }) => {
  return (
    <>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12} className="mb-3">
          <span className="fw-bold fw-bolder">OBSERVAÇÕES</span>
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize
            id="informacaoAdicional"
            name="informacaoAdicional"
            className="textRolagem bg-transparent text-dark"
            disabled={pedido.status !== "ABERTO"}
            label="Observacao"
            variant="outlined"
            maxLength={550}
            value={pedido?.informacaoAdicional}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PedidosCampos;
