import {
  CardHeader,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Loading,
  MaskedInput,
  IconButton,
  Button,
} from "../../../../../components";
import { useEffect, useState } from "react";
import { OrcamentosService, WhatsappService } from "../../../../../services";
import { responseErros } from "../../../../../utils";
import orcamentoPdfValidator from "../../middlewares/pdf-orcamento.validator";
import { InputErros } from "../../../../../helpers";

const EnvioWhatsappDialog = ({ open, setOpen, data }) => {
  const [loading, setLoading] = useState(false);
  const [celular, setCelular] = useState("");
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const orcamentosService = new OrcamentosService();
  const whatsappService = new WhatsappService();

  useEffect(() => {
    if (open && data.cliente?.id) setCelular(data.cliente?.celular);
  }, [open]);

  const handleInputChange = (event) => {
    setCelular(event.target.value);
  };

  function handleCloseDialog() {
    setCelular("");
    setOpen(false);
  }

  const aspectRatioLogoEmpresa = () => {
    const img = document.getElementById("whats");
    const aspectRatio = (img.naturalWidth || 1) / (img.naturalHeight || 1);
    return aspectRatio <= 1.5 ? 100 : 20;
  };

  async function handleSubmit() {
    setLoading(true);
    orcamentoPdfValidator
      .validate(data, { abortEarly: false })
      .then(async () => {
        const aspectRatio = aspectRatioLogoEmpresa();
        const result = await orcamentosService.pdf({
          ...data,
          aspectRatio,
        });
        if (!result.isAxiosError) {
          if (result.data) {
            const urlWhatsapp = whatsappService.send(
              celular,
              `Segue Orçamento: ${result.data}`
            );
            window.open(urlWhatsapp, "_blank");
            handleCloseDialog();
            setLoading(false);
          }
        } else {
          setLoading(false);
          responseErros(result);
        }
      })
      .catch((err) => {
        inputErros.set(err);
        setLoading(false);
      });
  }

  return (
    <>
      <img
        id="whats"
        className="d-none me-3"
        src={data?.imagemLogo}
        alt="logo da empresa"
      />
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={() => handleCloseDialog()}
      >
        <CardHeader title="Envio Whatsapp" className="m-2" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MaskedInput
                id="celular"
                name="celular"
                label="Celular"
                mask="(99) 9 9999-9999"
                onChange={handleInputChange}
                margin="none"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <IconButton disabled={true}>
                      <i
                        className="ph-fill ph-device-mobile"
                        style={{ fontSize: 20 }}
                      ></i>
                    </IconButton>
                  ),
                }}
                value={celular ? celular : ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Enviar Orçamento
          </Button>
        </DialogActions>
        <Loading loading={loading} />
      </Dialog>
    </>
  );
};

export default EnvioWhatsappDialog;
