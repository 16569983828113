import { useState } from "react";
import {
  Grid,
  Button,
  CardHeader,
  Dialog,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
  Loading,
} from "../../../../components";
import { ContasReceberService, notification } from "../../../../services";
import { responseErros, voltar } from "../../../../utils";
import contasReceberValidator from "../middlewares/add-contas-receber.validator";
import desdobrarTituloValidator from "../middlewares/desdobrarTitulo.validator";
import { useImportContext } from "../../../../contexts/import.context";

const DesdobrarTitulo = ({
  contaReceber,
  meioPagamento,
  dadosPagamentoList,
  observacaoList,
  inputErros,
  setInputErro,
}) => {
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [openDesdobrarTitulo, setOpenDesdobrarTitulo] = useState(false);
  const [desdobramentoTitulo, setDesdobramentoTitulo] = useState({
    tipoDesdobramento: "QUANTIDADE",
  });
  const contasReceberValidatorService = contasReceberValidator(meioPagamento);
  const contasReceberService = new ContasReceberService();

  const handleCloseDesdobrarDialog = () => {
    setOpenDesdobrarTitulo(false);
  };

  const handleChange = (event) => {
    setDesdobramentoTitulo((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOpenDesdobrarDialog = () => {
    const body = {
      ...contaReceber,
      observacaoList,
      bancoCaixaId: meioPagamento?.bancoCaixaId || contaReceber?.bancoCaixaId,
      pagamentos: dadosPagamentoList,
    };
    setInputErro([]);
    contasReceberValidatorService
      .validate(body, { abortEarly: false })
      .then(() => {
        setOpenDesdobrarTitulo(true);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const validarDesdobramentoTitulo = () => {
    setInputErro([]);
    desdobrarTituloValidator
      .validate(desdobramentoTitulo, { abortEarly: false })
      .then(() => {
        desdobrarTitulo();
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const desdobrarTitulo = async () => {
    const body = {
      desdobramentoTitulo,
      contaReceber: {
        ...contaReceber,
        observacaoList,
        bancoCaixaId: meioPagamento?.bancoCaixaId || contaReceber?.bancoCaixaId,
        pagamentos: dadosPagamentoList,
      },
    };
    setLoading(true);
    const res = await contasReceberService.desdobrarTitulo(body);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.cadastroSucesso();
      initialCollapses();
      voltar();
    } else {
      responseErros(res);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        className="mt-3 mb-2"
        color="primary"
        aria-controls={openDesdobrarTitulo ? "split-button-menu" : undefined}
        aria-expanded={openDesdobrarTitulo ? "true" : undefined}
        aria-haspopup="menu"
        onClick={handleOpenDesdobrarDialog}
      >
        Desdobrar título
      </Button>
      <Dialog fullWidth maxWidth={"sm"} open={openDesdobrarTitulo}>
        <CardHeader title="Desdobrar Título" className="m-2" />
        <DialogContent>
          <Grid container spacing={2}>
            <span className="px-4">Tipo de desdobramento</span>
            <DialogActions>
              <RadioGroup
                aria-labelledby="tipoDesdobramento"
                name="tipoDesdobramento"
                value={desdobramentoTitulo?.tipoDesdobramento ?? ""}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="QUANTIDADE"
                  control={<Radio />}
                  label="Desdobrar criando outras parcelas com mesmo valor"
                />
                <FormControlLabel
                  value="VALOR"
                  control={<Radio />}
                  label="Desdobrar dividindo valor total em parcelas"
                />
              </RadioGroup>
            </DialogActions>
            <Grid
              container
              xs={11}
              className="d-flex"
              style={{ padding: "0 24px" }}
            >
              <Grid item xs={6}>
                <TextField
                  id="qtdParcelas"
                  name="qtdParcelas"
                  label="Quantidade de parcelas"
                  variant="outlined"
                  margin="normal"
                  required
                  type="number"
                  onChange={handleChange}
                  value={desdobramentoTitulo?.qtdParcelas ?? ""}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="intervaloDias"
                  name="intervaloDias"
                  label="Intervalo de dias"
                  variant="outlined"
                  margin="normal"
                  required
                  type="number"
                  style={{ marginLeft: 20 }}
                  onChange={handleChange}
                  value={desdobramentoTitulo?.intervaloDias ?? ""}
                  fullWidth
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={validarDesdobramentoTitulo}
              >
                <i
                  className="ph-fill ph-check-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Confirmar
              </Button>
              <Button
                color="padrao"
                variant="contained"
                onClick={handleCloseDesdobrarDialog}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Grid>
          <Loading loading={loading} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DesdobrarTitulo;
