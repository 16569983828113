import createAxiosConfig from "../config/createAxiosConfig";
import store from "../config/store";
import StorageHelper from "../helpers/StorageHelper";
const headers = {
  "Content-Type": "application/json",
};
const axiosConfig = createAxiosConfig(store.baseUrlNfe, headers);
const storageHelper = new StorageHelper();

class SiafLiteNfeBaseRepository {
  constructor(url) {
    this.url = url;
  }

  async post(body) {
    await gerarAuthorization();
    return axiosConfig
      .post(this.url, body)
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        return erro;
      });
  }

  async getFile() {
    await gerarAuthorization();
    return axiosConfig
      .get(this.url, {
        responseType: "blob",
      })
      .then(function (reponse) {
        return reponse;
      })
      .catch(function (erro) {
        return erro;
      });
  }
}

async function gerarAuthorization() {
  axiosConfig.interceptors.request.use(
    async (config) => {
      const localStorageToken = storageHelper.getLocal("token");
      if (localStorageToken) {
        config.headers.Authorization = `Bearer ${localStorageToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
}

export default SiafLiteNfeBaseRepository;
