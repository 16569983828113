import { history, notification } from "../services";
import isEqual from "lodash/isEqual";

const voltar = async (formState = {}, reload) => {
  if (formState?.inicial && formState?.final) {
    if (!isEqual(formState?.inicial, formState?.final)) {
      const confirmacaoAlerta = await notification.confirmacaoGenericos({
        title: "Existem informações lançadas/alteradas.",
        text: "Deseja sair sem salvar?",
        icon: "warning",
        confirmButtonText: "SIM",
        cancelButtonText: "NÃO",
        dangerMode: true,
        showCancelButton: true,
      });
      if (confirmacaoAlerta.isDismissed) {
        return false;
      }
      return reload ? location.reload() : voltePaginaAnterior();
    } else {
      return reload ? location.reload() : voltePaginaAnterior();
    }
  } else {
    voltePaginaAnterior();
  }
};

const voltePaginaAnterior = () => {
  const url = window.location.pathname;
  const temNumero = /\/\d+/;
  let novaUrl = "/dashboard";

  if (temNumero.test(url) && !url.includes("empresas")) {
    novaUrl = url.replace(temNumero, "");
  } else if (url.endsWith("/create")) {
    novaUrl = url.slice(0, -7);
  }
  history.push(novaUrl);
};

export default voltar;
