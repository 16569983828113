import * as Yup from "yup";

const contaValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  tipoConta: Yup.string().required("Tipo Conta"),
  dataSaldoInicial: Yup.date().nullable(),
  saldoInicial: Yup.number().nullable(),
  aberturaAutomatica: Yup.boolean().required("Abertura Automática"),
  bancoId: Yup.number().nullable(),
  agencia: Yup.string().nullable(),
  conta: Yup.string().nullable(),
  chavePix: Yup.string().nullable(),
  nomeGerente: Yup.string().nullable(),
  telefoneGerente: Yup.string().nullable(),
  emailGerente: Yup.string().nullable(),
  ativado: Yup.boolean().nullable(),
  responsaveisList: Yup.array()
    .of(
      Yup.object().shape({
        usuarioId: Yup.number(),
      })
    )
    .min(1, "Lista de Responsáveis")
    .required("Lista de Responsáveis"),
});

export default contaValidator;
