import SiafLiteApiRepository from "../../external/siafLiteApi";

class CestService {
  constructor() {
    this.urlBase = "/fiscal/cest";
  }

  getAll(filtros) {
    return new SiafLiteApiRepository(this.urlBase).get(filtros);
  }
}

export default CestService;
