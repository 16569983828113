import { useState } from "react";
import { value } from "../../../../../helpers";
import grupoProdutosTributarioXmlValidator from "../../../middlewares/grupo-produtos-tributario-xml.validator";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Tooltip,
} from "../../../../../components";

export default function GrupoProdutoTributarioXml({
  gruposProdutos,
  gruposTributarios,
  setProdutos,
  inputErros,
}) {
  const [grupoProdutoTributario, setGrupoProdutoTributario] = useState({});

  const submitGruposIdsProdutos = async (event) => {
    event.preventDefault();
    grupoProdutosTributarioXmlValidator
      .validate(grupoProdutoTributario, { abortEarly: false })
      .then(async () => {
        setProdutos((prevState) =>
          prevState.map((produto) => ({
            ...produto,
            grupoTributacaoId: produto?.grupoTributacaoId
              ? produto.grupoTributacaoId
              : grupoProdutoTributario?.grupoTributacaoId,
            grupoProdutoId: produto?.grupoProdutoId
              ? produto.grupoProdutoId
              : grupoProdutoTributario?.grupoProdutoId,
          }))
        );
        inputErros.limpar();
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const handleChangeAutocomplete = (name, value) => {
    setGrupoProdutoTributario({
      ...grupoProdutoTributario,
      [name]: value?.id,
    });
  };

  return (
    <form onSubmit={submitGruposIdsProdutos}>
      <Grid container spacing={2} alignItems="center" className="mt-3">
        <Grid item xs={4}>
          <Autocomplete
            id="grupoProdutoId"
            name="grupoProdutoId"
            options={gruposProdutos}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) =>
              option.descricao ? option.descricao : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grupo de Produto"
                variant="outlined"
                error={inputErros.get("grupoProdutoId")}
              />
            )}
            onChange={(_, value) => {
              handleChangeAutocomplete("grupoProdutoId", value);
            }}
            value={value.autoComplete(
              gruposProdutos,
              grupoProdutoTributario?.grupoProdutoId
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="grupoTributacaoId"
            name="grupoTributacaoId"
            options={gruposTributarios}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) => (option.nome ? option.nome : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grupo Tributário"
                variant="outlined"
                error={inputErros.get("grupoTributacaoId")}
              />
            )}
            onChange={(_, value) => {
              handleChangeAutocomplete("grupoTributacaoId", value);
            }}
            value={value.autoComplete(
              gruposTributarios,
              grupoProdutoTributario?.grupoTributacaoId
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            title="Clique aqui para confirmar seleção."
            placement="right"
          >
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="success"
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              type="submit"
            >
              <i
                className="ph-bold ph-plus"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}
