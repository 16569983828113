import {
  DialogContent,
  Grid,
  Typography,
  Button,
  DialogActions,
} from "../../../../../../components";
import { formatCnpj } from "../../../../../../utils";

const FornecedorXmlStep = ({
  fornecedor,
  enderecoFornecedor,
  handleCloseDialog,
  handleAvancarStep,
  handleCadastrarFornecedor,
}) => {
  const acoesFornecedorStep = () => {
    const botaoProximo = (
      <Button variant="contained" onClick={handleAvancarStep}>
        Próximo
        <i
          className="ph-fill ph-caret-right"
          style={{ color: "#fff", fontSize: 18 }}
        ></i>
      </Button>
    );
    const botaoCadastrar = (
      <Button
        variant="contained"
        color="primary"
        onClick={handleCadastrarFornecedor}
      >
        Cadastrar
      </Button>
    );
    return fornecedor?.id ? botaoProximo : botaoCadastrar;
  };

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={4}>
            <Typography variant="overline">
              Nome do Fornecedor:
              <h5 style={{ fontWeight: 800, margin: 0 }}>
                {fornecedor.nomeRazaoSocial}
              </h5>
              <br />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="overline">
              Natureza Social:
              <h5 style={{ fontWeight: 800, margin: 0 }}>
                {fornecedor.naturezaSocial}
              </h5>
              <br />
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="overline">
              CNPJ:
              <h5 style={{ fontWeight: 800, margin: 0 }}>
                {formatCnpj(fornecedor.cnpjCpf)}
              </h5>
            </Typography>
          </Grid>
        </Grid>
        {enderecoFornecedor && (
          <Grid container spacing={2} className="mb-2">
            <Grid item xs={12}>
              <Typography variant="overline">
                Endereço:
                <h5 style={{ fontWeight: 800, margin: 0 }}>
                  {enderecoFornecedor.logradouro}
                </h5>
              </Typography>
              <Typography variant="overline">
                Nº {enderecoFornecedor.numero},&nbsp;
                {enderecoFornecedor.bairro}, &nbsp;
                {enderecoFornecedor.cidade}-{enderecoFornecedor.estado}, &nbsp;
                {enderecoFornecedor.cep} &nbsp;
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className="d-flex align-items-center justify-content-end mb-3">
        <Button variant="contained" onClick={handleCloseDialog}>
          Fechar
        </Button>
        {acoesFornecedorStep()}
      </DialogActions>
    </>
  );
};

export default FornecedorXmlStep;
