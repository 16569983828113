import * as Yup from "yup";
import documentoFreteListaNfeValidator from "./documento-frete-lista-nfe.validator";

const documentoFreteValidator = Yup.object().shape({
  dataEmissao: Yup.date().required("Data Emissão é obrigatória"),
  dataSaida: Yup.date().nullable(),
  dataChegada: Yup.date().nullable(),
  serieDocumento: Yup.number().required(
    "Série do Documento de Frete é obrigatória"
  ),
  numeroDocumento: Yup.number().required(
    "Número do Documento de Frete é obrigatório"
  ),
  cfopId: Yup.number().required("CFOP é obrigatório"),
  especie: Yup.number().required("Espécie é obrigatória"),
  modalidadeFrete: Yup.string().required("Modalidade Frete é obrigatória"),
  modeloDocumento: Yup.string().required("Modelo de Documento é obrigatório"),
  tipoFrete: Yup.string().required("Tipo Frete é obrigatório"),
  chave: Yup.string()
    .max(44, "A chave deve ter no máximo 44 caracteres")
    .required("Chave do Documento de Frete é obrigatória"),
  naturezaFrete: Yup.string().nullable(),
  transportadorId: Yup.number().nullable(),
  ufId: Yup.string().nullable(),
  rntrc: Yup.string().nullable(),
  movimentacaoFinanceira: Yup.boolean().nullable(),
  // planoFinanceiroId: Yup.number().when("movimentacaoFinanceira", {
  //   is: true,
  //   then: Yup.number().required(
  //     "Plano Financeiro é obrigatório quando há movimentação financeira"
  //   ),
  //   otherwise: Yup.number().nullable(),
  // }),
  // centroCustoId: Yup.number().when("movimentacaoFinanceira", {
  //   is: true,
  //   then: Yup.number().required(
  //     "Centro de Custo é obrigatório quando há movimentação financeira"
  //   ),
  //   otherwise: Yup.number().nullable(),
  // }),
  valorFrete: Yup.number().required("Valor do Frete é obrigatório"),
  valorSeguro: Yup.number().required("Valor do Seguro é obrigatório"),
  valorMercadoria: Yup.number().required(
    "Valor Mercadoria do Frete é obrigatório"
  ),
  pesoBrutoTotal: Yup.number().required("Peso Bruto do Frete é obrigatório"),
  listaNfe: documentoFreteListaNfeValidator,
  pagamentos: Yup.array().min(1, "Incluir ao menos 1 pagamento"),
});

export default documentoFreteValidator;
