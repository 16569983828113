import { useEffect, useState } from "react";
import {
  Button,
  CardHeader,
  DataGrid,
  Dialog,
  DialogContent,
  Grid,
  Loading,
} from "../../../../components";
import { NfSaidaService } from "../../../../services";
import { responseErros } from "../../../../utils";

function ListagemSequencia({ openListaSequencia, setOpenListaSequencia }) {
  const [listaSequenciaNf, setListaSequenciaNf] = useState([]);
  const [loading, setLoading] = useState(true);
  const nfSaidaService = new NfSaidaService();
  const colunas = [
    {
      field: "identificador",
      headerName: "Série",
      flex: 100,
      filtrar: true,
      valueGetter: (params) => params.value.split("#")[1],
    },
    {
      field: "sequencia",
      headerName: "Sequência",
      sortable: false,
      flex: 100,
      filtrar: true,
    },
  ];

  useEffect(() => {
    buscaSequenciaNf();
  }, []);

  const buscaSequenciaNf = async () => {
    const filtros = {
      order: "desc",
      orderBy: "sequencia",
      identificador: "FAT_NF_SAIDA_SERIE",
      restritiva: true,
    };
    nfSaidaService.getAllAutoSequencia(filtros).then((result) => {
      setLoading(false);
      if (!result.isAxiosError) {
        setListaSequenciaNf(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={openListaSequencia}
      onClose={() => setOpenListaSequencia(false)}
    >
      <DialogContent>
        <CardHeader
          title="Sequências"
          action={
            <Button
              onClick={() => setOpenListaSequencia(!openListaSequencia)}
              variant="contained"
              color="padrao"
            >
              fechar
            </Button>
          }
        />
        <Grid container spacing={4}>
          <Grid className="mt-2" item xs={12}>
            <DataGrid
              rows={listaSequenciaNf}
              columns={colunas}
              rowCount={listaSequenciaNf?.length}
              setOpenGridDialog={setOpenListaSequencia}
              disableAcoes
              disableColumnMenu
              disableSelectionOnClick
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Loading loading={loading} />
    </Dialog>
  );
}

export default ListagemSequencia;
