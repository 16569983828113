import { notification } from "../../../../services";
import { calcularValorAPagar } from "../add-contas-receber";

export class Pagamento {
  constructor(pagamento, pagamentoList, contaReceber) {
    Object.assign(this, pagamento);
    this.meioPagamentoId =
      pagamento?.meioPagamentoId ?? contaReceber.meioPagamentoId;
    this.tipoBaixa = pagamento?.tipoBaixa || "BAIXA_NORMAL";
    this.bancoCaixaId = pagamento?.bancoCaixaId ?? contaReceber.bancoCaixaId;
    this.saldoDevedor = Number(
      calcularValorAPagar(pagamento, pagamentoList, contaReceber).toFixed(2)
    );
    this.valor = Number(pagamento?.valor);
    this.pagadorId = contaReceber.pagadorId;
  }

  validaValor() {
    if (this.valor <= 0) {
      notification.alertaGenericos("Valor a pagar deve ser maior que zero");
      return false;
    }
    if (
      parseFloat(this.valor.toFixed(2)) >
      parseFloat(this.saldoDevedor.toFixed(2))
    ) {
      notification.alertaGenericos(
        "Valor a pagar não pode ser maior que o total"
      );
      return false;
    }
    return true;
  }

  totalCalculado() {
    const valorFormat = (valor) => parseFloat(valor) || 0;
    const total = (
      valorFormat(this.valor) -
      valorFormat(this.multa) -
      valorFormat(this.juros)
    ).toFixed(2);
    return total;
  }
}
