import { MenuList as MenuListMui } from "@mui/material";

const MenuList = ({
  children,
  autoFocus,
  autoFocusItem,
  disabledItemsFocusable,
  disableListWrap,
  variant,
  className,
}) => {
  return (
    <MenuListMui
      autoFocus={autoFocus ? true : false}
      autoFocusItem={autoFocusItem ? true : false}
      variant={variant ? variant : "selectedMenu"}
      disabledItemsFocusable={disabledItemsFocusable ? true : false}
      disableListWrap={disableListWrap ? true : false}
      className={className}
    >
      {children}
    </MenuListMui>
  );
};

export default MenuList;
