import SiafLiteApiRepository from "../../external/siafLiteApi";
import CepPromise from "cep-promise";

class EnderecoService {
  constructor() {
    this.urlBase = "/integracao/enderecos";
  }

  getEstados() {
    return new SiafLiteApiRepository(`${this.urlBase}/estados`).get();
  }

  getCidadeById(id) {
    return new SiafLiteApiRepository(`${this.urlBase}/cidade/${id}`).get();
  }

  getCidades(ufid) {
    return new SiafLiteApiRepository(`${this.urlBase}/cidades/${ufid}`).get();
  }

  async buscarEndereco(cep) {
    try {
      const endereco = await CepPromise(cep);
      const {
        city: cidade,
        street: logradouro,
        neighborhood: bairro,
        state: uf,
      } = endereco;
      return { cidade, logradouro, bairro, uf };
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  }

  getByCep(cep) {
    return new SiafLiteApiRepository(`${this.urlBase}/cep/${cep}`).get();
  }

  deleteEnderecoPessoa(id) {
    return new SiafLiteApiRepository(`${this.urlBase}`).delete(id);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }
}

export default EnderecoService;
