import { AppBar as AppBarMui } from "@mui/material";

const AppBar = ({
  children,
  classes,
  className,
  color,
  elevation,
  enableColorOnDark,
  position,
  sx,
  ...rest
}) => {
  return (
    <AppBarMui
      position={position ?? "static"}
      classes={classes}
      color={color ?? "primary"}
      enableColorOnDark={enableColorOnDark}
      elevation={elevation}
      className={className}
      sx={sx}
      {...rest}
    >
      <div>{children}</div>
    </AppBarMui>
  );
};

export default AppBar;
