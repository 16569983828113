import * as Yup from "yup";

const colaboradorValidator = Yup.object().shape({
  dataAdmissao: Yup.date().nullable(),
  funcao: Yup.string().nullable(),
  vendedor: Yup.boolean().nullable(),
  dataDemissao: Yup.date().nullable(),
  usuarioId: Yup.number().nullable(),
  bancoCaixaId: Yup.number().nullable(),
  comissaoVista: Yup.number().nullable(),
  comissaoPrazo: Yup.number().nullable(),
  grupoPessoas: Yup.array()
    .of(Yup.object({ id: Yup.number().required() }))
    .nullable(),
});

export default colaboradorValidator;
