import SiafLiteApiRepository from "../../external/siafLiteApi";

class ClienteService {
  constructor() {
    this.urlBase = "/integracao/clientes";
  }

  async getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }
}

export default ClienteService;
