import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  Grid,
} from "../../../../../components";
import { formatDate } from "../../../../../utils";
import { history } from "../../../../../services";
import { useImportContext } from "../../../../../contexts/import.context";

const UsoConsumoDialog = ({
  openUsoConsumoDialog,
  setOpenUsoConsumoDialog,
  setOpenImportarXMLDialog,
}) => {
  const { setNfEntradaDto } = useImportContext();

  const handleCloseDialog = () => {
    setOpenUsoConsumoDialog(false);
  };

  const handleUsoConsumoManual = () => {
    setNfEntradaDto({
      dataChegada: formatDate.toSend(new Date()),
      usoConsumoManual: true,
      usoConsumo: true,
    });
    history.push("/estoque/nf-entrada/create");
  };

  const handleUsoConsumoImportacao = () => {
    setOpenImportarXMLDialog(true);
    handleCloseDialog();
    setNfEntradaDto({
      usoConsumoImportado: true,
      usoConsumo: true,
    });
  };

  return (
    <Dialog maxWidth={"sm"} fullWidth open={openUsoConsumoDialog}>
      <CardHeader
        title="Nota de Uso e Consumo"
        className="m-2"
        action={
          <Button variant="outlined" onClick={handleCloseDialog}>
            fechar
          </Button>
        }
      />
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              sx={{ height: 100 }}
              fullWidth
              disableElevation
              onClick={handleUsoConsumoManual}
            >
              MANUAL
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="padrao"
              size="large"
              variant="contained"
              sx={{ height: 100 }}
              fullWidth
              disableElevation
              onClick={handleUsoConsumoImportacao}
            >
              VIA IMPORTAÇÃO
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UsoConsumoDialog;
