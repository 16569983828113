import { CardContent as CardContentMui } from "@mui/material";

const CardContent = ({
  children,
  classes,
  component,
  sx,
  className,
  style,
}) => {
  return (
    <CardContentMui
      classes={classes}
      style={style}
      component={component}
      sx={{
        ...sx,
        "&:last-child": {
          paddingBottom: "24px !important",
        },
        "& h5": {
          color: "#333",
          fontSize: "14px",
        },
      }}
      className={className}
    >
      {children}
    </CardContentMui>
  );
};

export default CardContent;
