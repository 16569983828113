import { useRef, useState } from "react";
import {
  CardHeader,
  CardActions,
  CardContent,
  Dialog,
  Button,
  Grid,
  TextField,
  DataGrid,
  IconButton,
  Typography,
} from "../../../../../components";
import { NfSaidaService, notification } from "../../../../../services";
import { formatDate, responseErros } from "../../../../../utils";
import { format } from "date-fns";
import { useReactToPrint } from "react-to-print";
import { ModeloCartaCorrecao } from "../modelos-impressao";

const CartaCorrecaoDialog = ({
  openCartaCorrecaoDialog,
  setOpenCartaCorrecaoDialog,
  id,
  setLoading,
  nfSaida,
  clienteSelecionado,
  enderecoCliente,
}) => {
  const componentRef = useRef(null);
  const [correcao, setCorrecao] = useState("");
  const [dadosImpressao, setDadosImpressao] = useState({});
  const nfSaidaService = new NfSaidaService();
  const colunas = [
    { field: "status", headerName: "Status", flex: 200 },
    { field: "motivo", headerName: "Motivo", flex: 500 },
    { field: "protocolo", headerName: "Protocolo", flex: 350 },
    {
      field: "createdAt",
      headerName: "Data Criação",
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
      flex: 250,
    },
    {
      field: "opcoes",
      headerName: "Opções",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              const cartaSelecionada = nfSaida.nfSaidaCartasCorrecao.find(
                (carta) => carta.id === params.id
              );
              setDadosImpressao({
                ...nfSaida,
                cliente: { ...clienteSelecionado, enderecoCliente },
                correcao: cartaSelecionada,
              });
              setTimeout(() => {
                handlePrint();
              }, 1000);
            }}
          >
            <i className="ph-fill ph-printer" style={{ fontSize: 18 }}></i>
          </IconButton>
        );
      },
    },
  ];

  const onChageCorrecao = (value) => {
    setCorrecao(value);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
            size: auto;
            margin: 8mm;
        }
    `,
  });

  const onSubmit = (event) => {
    event.preventDefault();
    enviarCartaCorrecao();
  };

  const enviarCartaCorrecao = async () => {
    const body = {
      correcao,
    };
    if (correcao?.length <= 14) {
      return notification.alertaGenericos("Quantidade mínima de 15 caracteres");
    }
    setLoading(true);
    const response = await nfSaidaService.cartaCorrecao(id, body);
    setLoading(false);
    if (!response.isAxiosError) {
      notification.cadastroSucesso();
      setCorrecao("");
      setOpenCartaCorrecaoDialog(false);
    } else {
      responseErros(response);
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={openCartaCorrecaoDialog}>
        <CardHeader title="CARTA DE CORREÇÃO" />
        <form onSubmit={(event) => onSubmit(event)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="correcao"
                  name="correcao"
                  label="Correção"
                  variant="outlined"
                  margin="normal"
                  onChange={(event) => onChageCorrecao(event.target.value)}
                  value={correcao}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className="d-flex justify-content-between">
                <Typography style={{ color: "#DC3545" }}>
                  Quantidade mínima de 15 caracteres
                </Typography>
                <Typography>
                  N° de caracteres: {correcao?.length || 0}
                </Typography>
              </Grid>
              <Grid item xs={12} className="mt-4">
                <DataGrid
                  rows={nfSaida.nfSaidaCartasCorrecao}
                  columns={colunas}
                  rowCount={nfSaida.nfSaidaCartasCorrecao?.length}
                  disableAcoes
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" type="submit">
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              SALVAR
            </Button>
            <Button
              variant="contained"
              color="padrao"
              onClick={() => setOpenCartaCorrecaoDialog(false)}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Fechar
            </Button>
          </CardActions>
        </form>
      </Dialog>
      <div className="d-none">
        <div ref={componentRef}>
          <ModeloCartaCorrecao data={dadosImpressao} />
        </div>
      </div>
    </>
  );
};

export default CartaCorrecaoDialog;
