import React from "react";
import Cropper from "cropperjs";
import PropTypes from "prop-types";
import { Button } from "../../components";
import { ModalActions, ModalContainer, ModalCustom } from "../../styles/shared";
import "cropperjs/dist/cropper.min.css";
import "./imageCropperStyles.css";

class ImageCropper extends React.Component {
  constructor() {
    super();
    this.state = {
      imageDestination: "",
    };
    this.imageElement = React.createRef();
  }

  handleSave() {
    const { imageDestination } = this.state;
    this.props.onSave(imageDestination);
  }

  handleHide() {
    this.props.onHide();
  }

  componentDidMount() {
    const cropper = new Cropper(this.imageElement.current, {
      zoomable: true,
      scalable: false,
      autoCropArea: 1,
      background: false,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        this.setState({ imageDestination: canvas.toDataURL("image/png") });
      },
    });
  }

  render() {
    return (
      <>
        <img
          ref={this.imageElement}
          src={this.props.image}
          className="imgContainer"
          alt="Source"
        />
        <ModalActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="error"
            variant="contained"
            onClick={() => this.handleHide()}
          >
            Cancelar
          </Button>
          <Button
            style={{ marginLeft: 16 }}
            color="primary"
            variant="contained"
            onClick={() => this.handleSave()}
          >
            Cortar imagem
          </Button>
        </ModalActions>
      </>
    );
  }
}

ImageCropper.propTypes = {
  image: PropTypes.string.isRequired,
};

const ImagemCropper = ({
  image,
  width,
  height,
  onSave,
  onHide,
  aspectRatio,
  autoCropArea,
  background,
}) => {
  const imageLogo = new Image();
  imageLogo.src = image;
  // const imageLogoWidth = imageLogo.width > 700  ? imageLogo.width / 2.2 : imageLogo.width
  // const imageLogoHeght = imageLogo.height > 500 ? imageLogo.height / 2.2 : imageLogo.height
  return (
    <ModalCustom open title="Cortar imagem">
      <ModalContainer
        style={{ background: "none", border: "none", width: 450, height: 450 }}
      >
        <ImageCropper
          image={image}
          width={width}
          height={height}
          aspectRatio={aspectRatio}
          onSave={onSave}
          onHide={onHide}
          autoCropArea={autoCropArea}
          background={background}
        />
      </ModalContainer>
    </ModalCustom>
  );
};

ImagemCropper.propTypes = {
  image: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ImagemCropper;
