const formatDate = {
  toSend: (data) => {
    return data?.toISOString();
  },
  received: (data) => {
    return data ? new Date(data) : new Date();
  },
};

export default formatDate;
