import SiafLiteApiRepository from "../../external/siafLiteApi";

class NotificacaoService {
  constructor() {
    this.urlBase = "/notificacao";
  }

  async getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  async marcarTodasComoLida() {
    return new SiafLiteApiRepository(`${this.urlBase}/marcar-como-lida`).get();
  }

  async marcarComoLida(id) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/marcar-como-lida/${id}`
    ).get();
  }
}

export default NotificacaoService;
