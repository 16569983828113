import { useState } from "react";
import "../modelo-carta-correcao.scss";

const CabecalhoNfe = ({ empresa }) => {
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };
  const infoTdClass =
    aspectRatio <= 1.5
      ? "danfe_celula_borda_topo"
      : "danfe_celula_borda_canto_esquerdo";
  const logoImage = (
    <img
      className={
        aspectRatio <= 1.5
          ? "danfe_logo_empresa_cce"
          : "danfe_logo_empresa_ret_cce"
      }
      src={empresa?.imagemLogo}
      alt="Logo da Empresa"
      onLoad={aspectLogo}
    />
  );
  return (
    <table className="danfe_tabelas" id="cabecalho">
      <tr>
        <td
          rowSpan="3"
          className={`${infoTdClass} h-100 d-flex align-items-center text-center`}
        >
          {logoImage}
        </td>
        <td rowSpan="5">
          <div className="h-100 d-flex flex-column justify-content-center text-center">
            <h3 className="danfe_nome_empresa mb-2">
              CARTA DE CORREÇÃO ELETRÔNICA
            </h3>
            <p className="mb-2">
              Não possui valor fiscal. Simples representação do evento indicado
              abaixo.
            </p>
            <p className="mb-2">
              CONSULTE A AUTENTICIDADE NO SITE DA SEFAZ AUTORIZADORA.
            </p>
          </div>
        </td>
      </tr>
    </table>
  );
};

export default CabecalhoNfe;
