import { MenuItem as MenuItemMui } from "@mui/material";

export default function MenuItem({
  onClick,
  autoFocus,
  children,
  classes,
  component,
  dense,
  disabled,
  disableGutters,
  divider,
  focusVisibleClassName,
  selected,
  sx,
  className,
  value,
  ...rest
}) {
  return (
    <MenuItemMui
      onClick={onClick}
      autoFocus={autoFocus ?? false}
      classes={classes}
      component={component}
      disabled={disabled ?? false}
      dense={dense ?? false}
      focusVisibleClassName={focusVisibleClassName}
      selected={selected ?? false}
      disableGutters={disableGutters ?? false}
      divider={divider ?? false}
      sx={sx}
      className={className}
      value={value}
      {...rest}
    >
      {children}
    </MenuItemMui>
  );
}
