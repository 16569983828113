import { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Typography,
} from "../../../../../components";
import {
  notification,
  CaixaService,
  ContasReceberService,
  NfSaidaService,
  history,
} from "../../../../../services";
import { responseErros } from "../../../../../utils";
import { NfSaidaCancelamento } from "../../../entities";

const CancelamentoDialog = ({
  open,
  setOpen,
  setLoading,
  id,
  operacaoFiscal,
  nfSaida,
}) => {
  const [justificativa, setJustificativa] = useState(null);
  const caixaService = new CaixaService();
  const contasReceberService = new ContasReceberService();
  const nfSaidaService = new NfSaidaService();

  const onSubmit = (event) => {
    event.preventDefault();
    validarCancelamento();
  };

  const validarCancelamento = async () => {
    if (justificativa?.length < 15) {
      notification.alertaGenericos(
        "justificativa mínima deve ser de 15 caracteres"
      );
      return;
    }
    setLoading(true);
    const contaVinculada = await contasReceberService.getAll({
      nfSaidaId: id,
      operacaoFiscal,
    });
    setLoading(false);
    if (contaVinculada.data.count > 0) {
      if (contaVinculada.data.rows[0].status !== "ABERTO") {
        const confirmacaoAlerta = await notification.confirmacao(
          "Confirmação!",
          "Constatamos título(s) já baixado(s). Deseja cancelar mesmo assim?"
        );
        if (confirmacaoAlerta.isConfirmed) {
          const caixaAberto = await caixaService.caixaAberto(
            contaVinculada.data.rows[0].dataLancamento,
            contaVinculada.data.rows[0].bancoCaixaId
          );
          if (caixaAberto.data) {
            cancelarDfe();
          } else {
            notification.alertaGenericos(
              "Alteração não permitida, Duplicata vinculada a caixa fechado"
            );
          }
        }
      } else {
        cancelarDfe();
      }
    } else {
      cancelarDfe();
    }
  };

  const cancelarDfe = async () => {
    setLoading(true);
    const nfSaidaCancelar = new NfSaidaCancelamento(
      nfSaida,
      justificativa,
      operacaoFiscal
    );
    const response = await nfSaidaService.cancelar(id, nfSaidaCancelar);
    setLoading(false);
    if (!response.isAxiosError) {
      notification.alteracaoSucesso();
      history.push("/faturamento/nf-saida");
      return;
    }
    if (response.status != 400) {
      responseErros(response);
    }
  };

  return (
    <Dialog disableEscapeKeyDown maxWidth="md" fullWidth open={open}>
      <form onSubmit={(event) => onSubmit(event)}>
        <DialogContent>
          <CardHeader title="Digite a justificativa para o cancelamento" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="justificativa"
                  name="justificativa"
                  label="Justificativa"
                  variant="outlined"
                  margin="normal"
                  onChange={(event) => setJustificativa(event.target.value)}
                  value={justificativa}
                  required
                  fullWidth
                />
                <Grid item xs={12} className="d-flex justify-content-between">
                  <Typography style={{ color: "#DC3545" }}>
                    Quantidade mínima de 15 caracteres
                  </Typography>
                  <Typography>
                    N° de caracteres: {justificativa?.length || 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" type="submit">
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Cancelar DF-e
            </Button>
            <Button
              variant="contained"
              color="padrao"
              onClick={() => setOpen(false)}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Fechar
            </Button>
          </CardActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CancelamentoDialog;
