export default {
  root: { height: "50px !important " },
  track: {
    border: "none",
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "2px solid #f34",
  },
};
