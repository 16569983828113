import { useState } from "react";
import {
  Button,
  CardHeader,
  Dialog,
  DialogContent,
  DialogActions,
  DataGrid,
  Grid,
} from "../../../../../components";
import { formatPrice } from "../../../../../utils";
import { notification } from "../../../../../services";
import shortid from "shortid";

const NotasCreditoDialog = ({
  open,
  setOpen,
  nfSaida,
  setNfSaida,
  nfSaidaEntity,
  listaNotasCredito,
  setListaNotasCredito,
  totalVenda,
  notasCredito,
  setNotasCredito,
  meioPagamentoCredito,
  nfSaidaPagamentos,
  setNfSaidaPagamentos,
  setGerarPagamentos,
}) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [totalNotasSelecionadas, setTotalNotasSelecionadas] = useState(0);
  const colunas = [
    {
      field: "numeroTitulo",
      headerName: "Nº Título",
      flex: 300,
      filtrar: true,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 400,
      valueGetter: (params) =>
        params.value == "TITULO_NORMAL" ? "Título normal" : "Nota de crédito",
    },
    {
      field: "saldoReceber",
      headerName: "Saldo",
      flex: 400,
      sortable: false,
      valueGetter: (params) => formatPrice(params.value),
    },
  ];

  const gerarParcelasCredito = () => {
    if (
      !meioPagamentoCredito ||
      !meioPagamentoCredito?.condicoesPagamento[0]?.id
    ) {
      setOpen(false);
      return notification.alertaGenericos(
        "Deve ser cadastrado um meio de pagamento e uma condição de pagamento para notas de crédito."
      );
    }
    const condicaoPagamentoId = meioPagamentoCredito?.condicoesPagamento[0]?.id;
    let contador = null;
    let numeroTitulo = 0;
    const pagamentosNf = [...nfSaidaPagamentos];
    for (const notaCredito of notasCredito) {
      if (contador == null) {
        contador = totalVenda - notaCredito.saldoReceber;
      } else {
        contador -= notaCredito.saldoReceber;
      }
      const parcelaGerada = {
        id: shortid.generate(),
        numeroTitulo,
        condicaoPagamentoId,
        meioPagamentoId: meioPagamentoCredito?.id,
        meioPagamentoSelecionado: meioPagamentoCredito,
        valorTitulo:
          contador > 0
            ? notaCredito.saldoReceber
            : notaCredito.saldoReceber + contador,
        dataVencimento: new Date(),
      };
      numeroTitulo++;
      if (parcelaGerada.valorTitulo) {
        pagamentosNf.push(parcelaGerada);
        setNfSaidaPagamentos((prevState) => [...prevState, parcelaGerada]);
        nfSaidaEntity.setChangePagamentos([
          ...nfSaidaPagamentos,
          parcelaGerada,
        ]);
        setNfSaida(nfSaidaEntity);
      }
    }
    const totalParcelas = pagamentosNf.reduce((acumulador, objetoAtual) => {
      return acumulador + parseFloat(objetoAtual.valorTitulo ?? 0);
    }, 0);
    setGerarPagamentos({
      valorTitulo: nfSaida?.totalVenda - parseFloat(totalParcelas ?? 0),
    });
    nfSaidaEntity.setNotasCredito(notasCredito);
    setNfSaida(nfSaidaEntity);
    filtrarNotasCredito();
    setOpen(false);
    notification.sucessoGenericos("Parcelas geradas com sucesso");
  };

  const filtrarNotasCredito = () => {
    const notasCreditoIds = new Set(notasCredito.map((notaNf) => notaNf.id));
    const listaNotasCreditoFiltrada = listaNotasCredito.filter(
      (notaCredito) => !notasCreditoIds.has(notaCredito.id)
    );
    setListaNotasCredito(listaNotasCreditoFiltrada);
  };

  const handleSelecaoCredito = (newRowSelectionModel) => {
    const notasSelecionadas = newRowSelectionModel.map((rowId) =>
      listaNotasCredito.find((row) => row.id === rowId)
    );
    const totalNotasSelecionadasTemp = notasSelecionadas.reduce(
      (total, conta) => total + (conta.saldoReceber || 0),
      0
    );
    if (
      totalNotasSelecionadas <= totalVenda ||
      totalNotasSelecionadasTemp <= totalVenda
    ) {
      setTotalNotasSelecionadas(totalNotasSelecionadasTemp);
      setSelectionModel(newRowSelectionModel);
      setNotasCredito(notasSelecionadas);
    } else {
      return notification.alertaGenericos(
        "As notas selecionadas ultrapassam o valor do saldo a pagar"
      );
    }
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={open}>
      <CardHeader title="Notas de Crédito" className="m-2" />
      <DialogContent>
        <DataGrid
          checkboxSelection
          selectionModel={selectionModel}
          onRowSelectionModelChange={handleSelecaoCredito}
          rows={listaNotasCredito}
          columns={colunas}
          rowCount={listaNotasCredito?.length}
          disableAcoes
        />
        <Grid
          container
          xs={12}
          fullWidth
          className="d-flex align-items-center justify-content-end mt-3"
        >
          <Grid
            item
            xs={5}
            className="d-flex align-items-center justify-content-end"
          >
            <span className="me-2">Saldo a pagar:</span>
            <span className="badge bg-secondary bg-gradient bg-gradient text-wrap p-2">
              {totalVenda - totalNotasSelecionadas > 0
                ? formatPrice(totalVenda - totalNotasSelecionadas)
                : "R$ 0,00"}
            </span>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="m-3">
        <Button
          onClick={() => setOpen(false)}
          color="padrao"
          variant="contained"
        >
          Cancelar
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={gerarParcelasCredito}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotasCreditoDialog;
