import { Modal as ModalMui } from "@mui/material";

const Modal = ({
  children,
  className,
  open,
  classes,
  closeAfterTransition,
  component,
  components,
  componentsProps,
  container,
  disableAutoFocus,
  disableEnforceFocus,
  disableEscapeKeyDown,
  disablePortal,
  disableRestoreFocus,
  disableScrollLock,
  hideBackdrop,
  keepMounted,
  onBackdropClick,
  onClose,
  slotProps,
  slots,
  sx,
}) => {
  return (
    <ModalMui
      className={className}
      open={open}
      classes={classes}
      closeAfterTransition={closeAfterTransition ? true : false}
      component={component}
      components={components}
      componentsProps={componentsProps}
      container={container}
      disableAutoFocus={disableAutoFocus ? true : false}
      disableEnforceFocus={disableEnforceFocus ? true : false}
      disableEscapeKeyDown={disableEscapeKeyDown ? true : false}
      disablePortal={disablePortal ? true : false}
      disableRestoreFocus={disableRestoreFocus ? true : false}
      disableScrollLock={disableScrollLock}
      hideBackdrop={hideBackdrop ? true : false}
      keepMounted={keepMounted ? true : false}
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      slotProps={slotProps}
      slots={slots}
      sx={sx}
    >
      {children}
    </ModalMui>
  );
};

export default Modal;
