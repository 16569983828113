import { InputAdornment as InputAdornmentMui } from "@mui/material";

const InputAdornment = ({
  children,
  className,
  classes,
  position,
  disablePointerEvents,
  disableTypography,
  sx,
  variant,
}) => {
  return (
    <InputAdornmentMui
      className={className}
      classes={classes}
      position={position}
      disablePointerEvents={disablePointerEvents}
      disableTypography={disableTypography}
      sx={sx}
      variant={variant}
    >
      {children}
    </InputAdornmentMui>
  );
};

export default InputAdornment;
