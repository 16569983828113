import shortid from "shortid";

export class DocumentoFreteNfeEntity {
  serieNfe = null;
  numeroNfe = null;
  valorNfe = null;
  pesoNfe = null;
  chaveNfe = null;
  id = undefined;

  constructor(nfe) {
    this.id = nfe.id;
    if (!this.id) {
      this.id = shortid.generate();
    }
    this.serieNfe = nfe.serieNfe;
    this.numeroNfe = nfe.numeroNfe;
    this.valorNfe = nfe.valorNfe;
    this.pesoNfe = nfe.pesoNfe;
    this.chaveNfe = nfe.chaveNfe;
  }
}
