import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "../../components";

const ImpressoraAdd = ({ descricaoBotao, LayoutImpressao }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
            size: auto;
            margin: 8mm;
        }
    `,
  });

  return (
    <>
      <div className="d-none">
        <div ref={componentRef}>{LayoutImpressao}</div>
      </div>
      {descricaoBotao ? (
        <Button
          id="impressoraAdd"
          className="my-3"
          variant="outlined"
          color="primary"
          size="small"
          onClick={handlePrint}
        >
          {descricaoBotao}
        </Button>
      ) : (
        <Button onClick={handlePrint} size="small" sx={{ borderRadius: 50 }}>
          <i className="ph-fill ph-printer" style={{ fontSize: 18 }}></i>
        </Button>
      )}
    </>
  );
};

export default ImpressoraAdd;
