import { constantes } from "../../nf-entrada/add-nf-entrada/resources";
import {
  CadastrarFornecedorXml,
  CadastrarTransportadoraXml,
} from "../entities";
import { getPagamentoDuplicataXml } from "../importar-xml";
export class FormatNfe {
  constructor(body) {
    const modalidadeFrete = constantes.modalidadeFrete.find(
      (modalidade) => modalidade.codigo === Number(body.modFrete)
    );
    this.modelo = body.mod;
    this.serie = body.serie;
    this.numeroNota = body.nNF;
    this.dataEmissao = new Date(body.dhEmi);
    this.dataChegada = new Date(body.dhRecbto);
    this.finalidade = body.finNFe;
    this.modalidadeFrete = modalidadeFrete?.value || "mfSemFrete";
    this.nfeChave = body.chNFe;
    this.subtotalDesconto = parseFloat(body.vDesc);
    this.subtotalDespesas = parseFloat(body.vOutro);
    this.frete = parseFloat(body.vFrete);
    this.informacoes = body.informacoes?.infCpl;
    this.itens = body.itens;
    this.pagamentos = getPagamentoDuplicataXml(
      body.pagamentos,
      body.duplicatas,
      body.dhEmi
    );
    this.fornecedor = new CadastrarFornecedorXml(body.fornecedor);
    this.transportadora =
      body?.transporta &&
      new CadastrarTransportadoraXml(body.transporta, constantes.estados);
    this.nfEntradaTributos = {
      baseIpi: undefined,
      valorIpi: body.vIPI,
      baseIcms: body?.vBC,
      baseIcmsSt: body?.vBCST,
      valorIcms: body.vICMS,
      valorIcmsSt: body?.vST,
      valorCofins: body.vCOFINS,
      valorCofinsSt: body?.vCOFINS,
      valorPis: body.vPIS,
      valorPisSt: body?.vPIS_ST,
      baseFcp: body?.vBCFCP,
      baseFcpSt: body?.vBCFCPST,
      valorFcp: body.vFCP,
      valorFcpSt: body.vFCPST,
    };
  }
}
