import "../modelo-nfe-espelho.scss";

const ReciboNfeEspelho = ({ data }) => {
  return (
    <div>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td>
              <table className="danfe_tabelas">
                <tbody>
                  <tr>
                    <td className="danfe_celula_bordas" colSpan="2">
                      <p className="danfe_canhoto_texto">
                        RECEBEMOS DE {data?.nomeRazaoSocialEmitente} OS PRODUTOS
                        CONSTANTES DA NOTA FISCAL INDICADA AO LADO
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="danfe_canhoto_bordas">
                      <p className="danfe_celula_titulo">Data de recebimento</p>
                      <p className="danfe_celula_valor">&nbsp;</p>
                    </td>
                    <td className="danfe_canhoto_bordas">
                      <p className="danfe_celula_titulo">
                        Identificação e assinatura do recebedor
                      </p>
                      <p className="danfe_celula_valor">&nbsp;</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>&nbsp;</td>
            <td>
              <table className="danfe_tabelas" style={{ height: "20px" }}>
                <tbody>
                  <tr>
                    <td className="danfe_celula_bordas" align="center">
                      <strong>NF-e</strong>
                      <h2 className="danfe_cabecalho_numero">
                        Nº {data?.numeroDocumento}
                      </h2>
                      <strong>Série {data?.serieDocumento}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="danfe_linha_tracejada"></div>
    </div>
  );
};

export default ReciboNfeEspelho;
