import * as Yup from "yup";

const servicoValidator = Yup.object().shape({
  codigo: Yup.string().required(),
  descricao: Yup.string().required(),
  precoVenda: Yup.number().required(),
  valorComissaoVendedor: Yup.number().nullable(),
  percentualComissaoVendedor: Yup.number().nullable(),
  codigoServicoLeiId: Yup.number().required(),
});

export default servicoValidator;
