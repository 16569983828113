import { Switch as SwitchMui } from "@mui/material";

const Switch = ({
  id,
  children,
  checked,
  defaultChecked,
  className,
  name,
  classes,
  color,
  disabled,
  disableRipple,
  edge,
  inputProps,
  inputRef,
  onChange,
  onClick,
  required,
  size,
  sx,
  value,
}) => {
  return (
    <SwitchMui
      id={id}
      checked={checked}
      defaultChecked={defaultChecked}
      name={name}
      className={className}
      classes={classes}
      color={color}
      disabled={disabled}
      disableRipple={disableRipple}
      edge={edge}
      inputProps={inputProps}
      inputRef={inputRef}
      onChange={onChange}
      onClick={onClick}
      required={required}
      size={size}
      sx={sx}
      value={value}
    >
      {children}
    </SwitchMui>
  );
};

export default Switch;
