import { useState, useEffect } from "react";
import { format } from "date-fns";
import { ListLayout } from "../../../layouts";
import { DataGrid, Grid, Typography, Tooltip, Icon } from "../../../components";
import { responseErros, formatPrice, formatDate } from "../../../utils";
import {
  BaixaLotePagar,
  ButtonMenu,
  FiltroCustom,
  FormFiltroAvancado,
  RenegociacaoTitulos,
} from "./components";
import {
  history,
  notification,
  PessoaService,
  ContasPagarService,
  BancosCaixasService,
  MeioPagamentoService,
  CondicoesPagamentoService,
  ColaboradorService,
  SessionService,
} from "../../../services";
import { calculaTotalAPagar } from "./listContaPagar";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";

const ListContasPagar = () => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("contas-pagar-visualizar");
  const [loading, setLoading] = useState(false);
  const [contasPagar, setContasPagar] = useState([]);
  const [baixaLote, setBaixaLote] = useState({});
  const [totalAPagar, setTotalAPagar] = useState({
    multa: 0,
    juros: 0,
    subtotal: 0,
    pagar: 0,
  });
  const [rowCount, setRowCount] = useState(null);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [listaPessoasFiltro, setListaPessoasFiltro] = useState([]);
  const [pessoaFiltro, setPessoaFiltro] = useState(null);
  const [buscaFiltro, setBuscaFiltro] = useState("");
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [contasPagarSelecionadas, setContasPagarSelecionadas] = useState([]);
  const [renderBaixaLote, setRenderBaixaLote] = useState(false);
  const [renderRenegociacaoTitulos, setRenderRenegociacaoTitulos] =
    useState(false);
  const [renegociacaoTitulos, setRenegociacaoTitulos] = useState({});
  const [listaCondicoesPagamento, setListaCondicoesPagamento] = useState([]);
  const [listaBancosCaixas, setListaBancosCaixas] = useState([]);
  const [listaMeiosPagamento, setListaMeiosPagamento] = useState([]);
  // const [parametrosFiscais, setParametrosFiscais] = useState({});
  const status = [
    {
      id: 1,
      nome: "Aberto",
      color: "#4FBB53",
    },
    {
      id: 2,
      nome: "Baixado",
      color: "#004780",
    },
    {
      id: 3,
      nome: "Baixado Parcial",
      color: "#EA9D2B",
    },
    {
      id: 4,
      nome: "Cancelado",
      color: "#DC3545",
    },
    {
      id: 5,
      nome: "Renegociado",
      color: "#70309F",
    },
  ];
  const colunas = [
    {
      field: "status",
      headerName: "Status",
      flex: 50,
      renderCell: (params) => {
        switch (params.value) {
          case "BAIXADO":
            return (
              <Tooltip title="Baixado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#004780", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "BAIXADO_PARCIAL":
            return (
              <Tooltip title="Baixado Parcial" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#EA9D2B", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "CANCELADO":
            return (
              <Tooltip title="Cancelado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#DC3545", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "RENEGOCIADO":
            return (
              <Tooltip title="Renegociado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#70309F", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          default:
            return (
              <Tooltip title="Aberto" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#4FBB53", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
        }
      },
    },
    {
      field: "numeroTitulo",
      headerName: "Nº Título",
      flex: 230,
      filtrar: true,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 250,
      valueGetter: (params) =>
        params.value == "TITULO_NORMAL" ? "Título normal" : "Nota de débito",
    },
    {
      field: "pessoaId",
      headerName: "Fornecedor",
      flex: 400,
      sortable: false,
      filtroPessoa: true,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 320,
      sortable: false,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "saldoPagar",
      headerName: "Saldo a pagar",
      flex: 320,
      sortable: false,
      valueGetter: (params) =>
        params.value > 0 ? formatPrice(params.value) : "R$ 0,00",
    },
    {
      field: "dataVencimento",
      headerName: "Data de Vencimento",
      flex: 250,
      valueGetter: (params) =>
        format(formatDate.received(params?.value), "dd/MM/yyyy"),
    },
  ];
  const contasPagarService = new ContasPagarService();
  const bancosCaixasService = new BancosCaixasService();
  const meioPagamentoService = new MeioPagamentoService();
  const condicoesPagamentoService = new CondicoesPagamentoService();
  const pessoaService = new PessoaService();
  const colaboradorService = new ColaboradorService();
  const sessionService = new SessionService();

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscaContasPagar(filtros);
    buscarMeiosPagamento();
    buscarCondicoesPagamento();
  }, []);

  const retornoTopo = () => {
    const ref = document.getElementById("topo-contas-pagar");
    setTimeout(
      () =>
        ref.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      100
    );
  };

  async function buscarPessoaVendedor(filtros) {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const dadosUsuario = sessionService.getUsuario();
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === dadosUsuario.id
      );
      const permissaoCaixaColaborador = await permissoesHelper.temPermisao(
        "utilizar-caixa-colaborador"
      );
      if (colaboradorVendedorUsuario && permissaoCaixaColaborador) {
        buscarBancosCaixas(colaboradorVendedorUsuario?.bancoCaixaId);
      } else {
        buscarBancosCaixas();
      }
    } else {
      responseErros(result);
    }
  }

  const buscaContasPagar = async function (filter = {}) {
    setLoading(true);
    const result = await contasPagarService.getAll(filter);
    if (!result.isAxiosError) {
      setContasPagar(result.data.rows);
      await buscarRelacionamentosContaPagar(result.data.rows);
      setRowCount(result.data.count);
      buscarPessoaVendedor();
      // buscarJurosMulta();
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  // const buscarJurosMulta = () => {
  //   configuracoesService.get().then((result) => {
  //     if (!result.isAxiosError) {
  //       const data = result.data.rows[0];
  //       const parametrosFiscaisTemp = {
  //         jurosPorcentagem: data?.jurosPorcentagem
  //           ? data.jurosPorcentagem / 100
  //           : null,
  //         multaPorcentagem: data?.multaPorcentagem
  //           ? data.multaPorcentagem / 100
  //           : null,
  //         carencia: data?.carencia ?? 0,
  //       };
  //       setParametrosFiscais(parametrosFiscaisTemp);
  //       setBaixaLote((prevState) => ({
  //         ...prevState,
  //         ...parametrosFiscaisTemp,
  //       }));
  //       setRenegociacaoTitulos((prevState) => ({
  //         ...prevState,
  //         ...parametrosFiscaisTemp,
  //       }));
  //     }
  //   });
  // };

  const buscarRelacionamentosContaPagar = async (listaContasPagar) => {
    const idsMeiosPagamento = [];
    const idsPessoas = [];
    for (const objeto of listaContasPagar) {
      idsMeiosPagamento.push(objeto.meioPagamentoId);
      idsPessoas.push(objeto.pessoaId);
    }
    const idsUnicosPessoas = [...new Set(idsPessoas)];
    await buscarPessoas({}, idsUnicosPessoas);
  };

  const buscarBancosCaixas = async function (id) {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    if (id) filtros.id = id;
    const result = await bancosCaixasService.getAll(filtros);
    if (!result.isAxiosError) {
      setListaBancosCaixas(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarMeiosPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaMeiosPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCondicoesPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    condicoesPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaCondicoesPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = async (filtros, listaIds) => {
    let filtro = { ativado: true, attributes: ["id", "nomeRazaoSocial"] };
    if (listaIds?.length) {
      filtro = {
        ...filtro,
        id: listaIds,
        nonPaginated: true,
      };
    } else {
      filtro = { ...filtro, ...filtros, limite: 15 };
    }
    const result = await pessoaService.getAllFiltroAvancadoSimplificada(filtro);
    if (!result.isAxiosError) {
      const listaPessoas = result.data;
      if (listaIds?.length) {
        setListaPessoas(listaPessoas);
      } else setListaPessoasFiltro(listaPessoas?.rows);
    } else {
      responseErros(result);
    }
  };

  const handleSelecaoContas = (newRowSelectionModel) => {
    const listaContasPagar = newRowSelectionModel
      .map((rowId) => contasPagar.find((row) => row.id === rowId))
      .filter((conta) => conta?.status !== "BAIXADO");
    const totais = calculaTotalAPagar(
      listaContasPagar,
      baixaLote.dataPagamento ?? new Date(),
      baixaLote
    );
    setTotalAPagar(totais);
    setContasPagarSelecionadas(listaContasPagar);
    setLinhasSelecionadas(newRowSelectionModel);
  };

  const onClickCadastrarContaPagar = function () {
    history.push("/financeiro/contas-pagar/create");
  };

  const onClickEditContaPagar = function (id) {
    history.push(`/financeiro/contas-pagar/${id}`);
  };

  const onClickDeleteContaPagar = async function (id) {
    const contaSelecionada = contasPagar.find((item) => item.id === id);
    if (contaSelecionada.origem === "MANUAL") {
      const retornoAlerta = await notification.confirmacao(
        "Excluir!",
        "Tem certeza que deseja excluir essa conta a pagar?"
      );
      if (retornoAlerta.isConfirmed) {
        if (contaSelecionada.status === "ABERTO") {
          setLoading(true);
          const result = await contasPagarService.deletar(id);
          if (!result.isAxiosError) {
            buscaContasPagar();
            notification.deletadoSucesso();
          } else {
            setLoading(false);
            responseErros(result);
          }
        } else
          notification.alertaGenericos(
            "Só é permitido excluir títulos que se encontram em aberto."
          );
      }
    } else
      notification.erroCadastroApi(
        "Só é permitido excluir títulos que foram inseridos manualmente."
      );
  };

  const handlePesquisaAvancada = async (filter) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataLancamentoStart &&
      filtrosAvancadosTemp.dataLancamentoEnd
    ) {
      porIntervalo.push({
        coluna: "dataLancamento",
        de: filtrosAvancadosTemp.dataLancamentoStart,
        ate: filtrosAvancadosTemp.dataLancamentoEnd,
      });
    }
    if (
      filtrosAvancadosTemp.dataVencimentoStart &&
      filtrosAvancadosTemp.dataVencimentoEnd
    ) {
      porIntervalo.push({
        coluna: "dataVencimento",
        de: filtrosAvancadosTemp.dataVencimentoStart,
        ate: filtrosAvancadosTemp.dataVencimentoEnd,
      });
    }
    delete filtrosAvancadosTemp.dataLancamentoStart;
    delete filtrosAvancadosTemp.dataLancamentoEnd;
    delete filtrosAvancadosTemp.dataVencimentoStart;
    delete filtrosAvancadosTemp.dataVencimentoEnd;
    const result = await contasPagarService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setContasPagar(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosContaPagar(result.data.rows);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const filtrarListaPorPessoa = (value) => {
    if (value) {
      buscaContasPagar({ pessoaId: value.id });
      setBuscaFiltro("");
    } else {
      buscaContasPagar();
      setListaPessoasFiltro([]);
    }
  };

  const sendServerDatagrid = (props) => {
    const { filtros, tipoFiltro } = props;
    if (tipoFiltro === "simples") {
      buscaContasPagar(filtros);
    } else {
      handlePesquisaAvancada(filtros);
    }
  };

  return (
    <>
      <div id="topo-contas-pagar"></div>
      <ListLayout
        title="Contas a Pagar"
        loading={loading}
        onClickCadastrar={onClickCadastrarContaPagar}
        permissaoButton={permissoesHelper.temPermisao("contas-pagar-cadastrar")}
        ButtonMenu={
          <ButtonMenu
            renderBaixaLote={renderBaixaLote}
            setRenderBaixaLote={setRenderBaixaLote}
            renderRenegociacaoTitulos={renderRenegociacaoTitulos}
            setRenderRenegociacaoTitulos={setRenderRenegociacaoTitulos}
          />
        }
      >
        <Grid container spacing={2}>
          {status.map(({ id, color, nome }) => (
            <Grid item xs={2} className="d-flex align-items-center" key={id}>
              <i
                className="ph-fill ph-circle"
                style={{ color, fontSize: 18 }}
              ></i>
              <Typography className="m-1">{nome}</Typography>
            </Grid>
          ))}
        </Grid>
        <DataGrid
          checkboxSelection={renderBaixaLote || renderRenegociacaoTitulos}
          onRowSelectionModelChange={handleSelecaoContas}
          selectionModel={linhasSelecionadas}
          rows={contasPagar}
          columns={colunas}
          onClickEdit={onClickEditContaPagar}
          onClickDelete={onClickDeleteContaPagar}
          visualizarShow={permissoesHelper.temPermisao(
            "contas-pagar-visualizar"
          )}
          editShow={permissoesHelper.temPermisao("contas-pagar-editar")}
          deleteShow={permissoesHelper.temPermisao("contas-pagar-excluir")}
          paginationMode={"server"}
          rowCount={rowCount}
          sendServer={(props) => sendServerDatagrid(props)}
          FormFiltroAvancado={FormFiltroAvancado}
          disableRowSelectionOnClick
          FiltroCustom={
            <FiltroCustom
              buscaFiltro={buscaFiltro}
              setBuscaFiltro={setBuscaFiltro}
              listaPessoasFiltro={listaPessoasFiltro}
              setListaPessoasFiltro={setListaPessoasFiltro}
              pessoaFiltro={pessoaFiltro}
              setPessoaFiltro={setPessoaFiltro}
              buscarPessoas={buscarPessoas}
              filtrarListaPorPessoa={filtrarListaPorPessoa}
              sendServer={sendServerDatagrid}
              columns={colunas}
            />
          }
        />
      </ListLayout>
      <BaixaLotePagar
        renderBaixaLote={renderBaixaLote}
        setRenderBaixaLote={setRenderBaixaLote}
        listaContasPagar={contasPagarSelecionadas}
        setListaContasPagar={setContasPagarSelecionadas}
        buscaContasPagar={buscaContasPagar}
        listaMeiosPagamento={listaMeiosPagamento}
        listaBancosCaixas={listaBancosCaixas}
        baixaLote={baixaLote}
        setBaixaLote={setBaixaLote}
        totalAPagar={totalAPagar}
        setTotalAPagar={setTotalAPagar}
        retornoTopo={retornoTopo}
      />
      <RenegociacaoTitulos
        renderRenegociacaoTitulos={renderRenegociacaoTitulos}
        setRenderRenegociacaoTitulos={setRenderRenegociacaoTitulos}
        renegociacaoTitulos={renegociacaoTitulos}
        setRenegociacaoTitulos={setRenegociacaoTitulos}
        calculaTotalAPagar={calculaTotalAPagar}
        buscaContasPagar={buscaContasPagar}
        listaCondicoesPagamento={listaCondicoesPagamento}
        listaContasPagar={contasPagarSelecionadas}
        setListaContasPagar={setContasPagarSelecionadas}
        listaPessoas={listaPessoas}
        totalAPagar={totalAPagar}
        retornoTopo={retornoTopo}
        setLoading={setLoading}
        setLinhasSelecionadas={setLinhasSelecionadas}
        setTotalAPagar={setTotalAPagar}
        listaMeiosPagamento={listaMeiosPagamento}
      />
    </>
  );
};

export default ListContasPagar;
