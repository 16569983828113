import { DialogTitle as DialogTitleMui } from "@mui/material";

const DialogTitle = ({ children, className, classes, sx }) => {
  return (
    <DialogTitleMui className={className} classes={classes} sx={sx}>
      {children}
    </DialogTitleMui>
  );
};

export default DialogTitle;
