import SiafLiteApiRepository from "../../external/siafLiteApi";

class ParametroService {
  constructor() {
    this.urlBase = "/integracao/parametros";
  }

  async getAll() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }
}

export default ParametroService;
