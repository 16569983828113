import * as Yup from "yup";

const cadastrarEmpresaValidator = Yup.object().shape({
  fantasia: Yup.string().required("Nome fantasia"),
  razaoSocial: Yup.string().required("Razão social"),
  cnpjCpf: Yup.string().required("CNPJ/CPF"),
  inscricaoEstadualIsento: Yup.boolean().required(),
  inscricaoEstadual: Yup.string().when(
    ["inscricaoEstadualIsento", "naturezaSocial"],
    {
      is: (isento, natureza) => isento || natureza === "fisica",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Inscrição estadual"),
    }
  ),
  cnae: Yup.string().required("CNAE"),
  regimeTributario: Yup.string().required(),
  email: Yup.string().email().required("E-mail"),
  celular: Yup.string().required("Celular"),
  enderecoCep: Yup.string().required("CEP"),
  enderecoLogradouro: Yup.string().required("Logradouro"),
  enderecoLogradouroNumero: Yup.number().required("Número"),
  enderecoComplemento: Yup.string().nullable(),
  cidade: Yup.string().required("Cidade"),
  enderecoBairro: Yup.string().required("Bairro"),
  contadorAutorizarDadosXmlNfe: Yup.boolean().required(),
  contadorAutorizarDownloadXmlNfe: Yup.boolean().required(),
  naturezaSocial: Yup.string(),
  suspenderAtividade: Yup.boolean().required(),
  usuarioPadrao: Yup.object()
    .shape({
      nome: Yup.string().required("Nome"),
      email: Yup.string().required("E-mail"),
      senha: Yup.string()
        .required("Senha")
        .min(6, "Senha deve ter entre 6 e 20 caracteres")
        .max(20, "Senha deve ter entre 6 e 20 caracteres"),
      confirmarSenha: Yup.string().when("senha", (senha, field) => {
        return senha
          ? field
              .required("Confirmar nova senha")
              .oneOf(
                [Yup.ref("senha")],
                "Confirmar senha deve ser igual a senha"
              )
          : field;
      }),
    })
    .required(),
});

export default cadastrarEmpresaValidator;
