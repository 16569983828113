const constantes = {
  tipoEmissaoList: [
    { id: 1, nome: "1- Emissão normal (não em contigência)" },
    {
      id: 2,
      nome: "2- Contigência FS-IA, com impressão do DANFE em formulário de",
    },
    {
      id: 3,
      nome: "3- Contigência SCAN (Sistema de Contigência do Ambiente Nacional)",
    },
    {
      id: 4,
      nome: "4- Contigência DPEC (Declaração Prévia da Emissão em Contigência)",
    },
    {
      id: 5,
      nome: "5- Contigência FS-DA, com impressão do DANFE em formulário de",
    },
    {
      id: 6,
      nome: "6- Contigência SVC-AN (SEFAZ Virtual de Contigência do AN)",
    },
    {
      id: 7,
      nome: "7- Contigência SVC-RS (SEFAZ Virtual de Contigência do RS)",
    },
    {
      id: 9,
      nome: "9- Contigência off-line da NFC-e (as demais opções de contigência)",
    },
  ],
  tipoEmissaoNfceList: [
    { id: 1, nome: "1- Emissão normal (não em contigência)" },
    {
      id: 9,
      nome: "9- Contigência off-line da NFC-e (as demais opções de contigência)",
    },
  ],
};

export default constantes;
