import { useState, useEffect } from "react";
import { SessionService } from "../../../../../../services";
import QRCode from "react-qr-code";
import "./modelo-nfce-contingencia.css";
import shortid from "shortid";

const ModeloNfceContingencia = ({ data }) => {
  const sessionService = new SessionService();
  const [empresa, setEmpresa] = useState(null);
  const pagamentosList = [
    { cod: "01", desc: "Dinheiro" },
    { cod: "02", desc: "Cheque" },
    { cod: "03", desc: "Cartão de Crédito" },
    { cod: "04", desc: "Cartão de Débito" },
    { cod: "05", desc: "Crédito Loja" },
    { cod: "10", desc: "Vale Alimentação" },
    { cod: "11", desc: "Vale Refeição" },
    { cod: "12", desc: "Vale Presente" },
    { cod: "13", desc: "Vale Combustível" },
    { cod: "15", desc: "Boleto Bancário" },
    { cod: "16", desc: "Depósito Bancário" },
    { cod: "17", desc: "PIX" },
    { cod: "18", desc: "Transferência bancária, Carteira Digital" },
    { cod: "19", desc: "Programa de fidelidade, Cashback, Crédito Virtual" },
    { cod: "90", desc: "Sem pagamento" },
    { cod: "99", desc: "Outros" },
  ];
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };

  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  return empresa && data ? (
    <div style={{ textAlign: "center" }}>
      <div id="conteudo_nfce">
        {empresa?.imagemLogo ? (
          <img
            src={empresa.imagemLogo}
            alt="Logo Empresa"
            onLoad={aspectLogo}
            style={{
              width: "100px",
              height: aspectRatio <= 1.5 ? "100px" : "20px",
              margin: "10px 0 10px",
            }}
          />
        ) : null}
        <div style={{ textAlign: "center" }}>
          <h6 style={{ textTransform: "uppercase" }}>
            CNPJ: {data?.cnpjCpfEmitente} {data?.nomeRazaoSocialEmitente}
          </h6>
          <h6 style={{ textTransform: "uppercase" }}>
            {data?.enderecoEmitente?.xLgr}, {data?.enderecoEmitente?.nro}
            {data?.enderecoEmitente?.xBairro} - {data?.enderecoEmitente?.xMun}
            {data?.enderecoEmitente?.UF} {data?.enderecoEmitente?.CEP}
          </h6>
          <h6>
            {data?.enderecoEmitente?.fone
              ? `Fone: ${data.enderecoEmitente.fone}`
              : ``}
            {data?.ieEmitente ? `I.E.: ${data.ieEmitente}` : ``}
          </h6>
        </div>
        <div className="tipoEmissao">
          {data?.ambiente == "1"
            ? "DANFE NFC-e - Documento Auxiliar da Nota Fiscal de Consumidor Eletrônica"
            : "EMITIDA EM AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL"}
        </div>
        <div className="tipoEmissao">
          EMITIDA EM CONTINGÊNCIA - Pendente de Autorização
        </div>
        <div>
          <table className="table">
            <thead>
              <tr className="itensBorder">
                <th className="headerText" style={{ width: 10 }}>
                  CÓD
                </th>
                <th className="headerText">DESC</th>
                <th className="headerText">QTD</th>
                <th className="headerText">UN</th>
                <th className="headerText">UNIT</th>
                <th className="headerText">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {data?.itens?.length
                ? data.itens.map((item) => {
                    return (
                      <>
                        <tr
                          style={{
                            border: 0,
                            borderColor: "white",
                          }}
                          key={shortid.generate()}
                        >
                          <td
                            className="itensText"
                            style={{ width: 35, textAlign: "center" }}
                          >
                            {item.prod.cProd}
                          </td>
                          <td className="itensText descProd" colSpan={5}>
                            <p className="descProd">{item.prod.xProd}</p>
                          </td>
                        </tr>
                        <tr className="itensBorder">
                          <td className="itensText" style={{ width: 35 }}></td>
                          <td className="itensText" colSpan={1}></td>
                          <td className="itensText" colSpan={1}>
                            {parseFloat(item.prod.qCom).toFixed(2)}
                          </td>
                          <td className="itensText" colSpan={1}>
                            {item.prod.uCom}
                          </td>
                          <td className="itensText" colSpan={1}>
                            {parseFloat(item.prod.vUnCom).toFixed(2)}
                          </td>
                          <td className="itensText" colSpan={1}>
                            {(
                              parseFloat(item.prod.qCom) *
                              parseFloat(item.prod.vUnCom)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <div style={{ width: "100%", padding: "0.5rem 0.5rem" }}>
          <div className="listagemValores">
            <h6>QTD. TOTAL DE ITENS</h6>
            <h6 className="listaValor">{data?.itens?.length}</h6>
          </div>
          <div className="listagemValores">
            <h6>VALOR TOTAL R$</h6>
            <h6 className="listaValor">
              {parseFloat(data?.totalVenda).toFixed(2)}
            </h6>
          </div>
          <div className="listagemValores">
            <h6>Descontos R$</h6>
            <h6 className="listaValor">
              - {parseFloat(data?.descontos).toFixed(2)}
            </h6>
          </div>
          <div className="listagemValores">
            <h6>VALOR A PAGAR R$</h6>
            <h6 className="listaValor">
              {(
                parseFloat(data?.totalVenda) - parseFloat(data?.descontos)
              ).toFixed(2)}
            </h6>
          </div>
          <div className="listagemValores">
            <h6>FORMA DE PAGAMENTO</h6>
            <h6 className="listaValor">Valor Pago</h6>
          </div>
          {data?.pagamentos?.length
            ? data.pagamentos.map((pag) => {
                const pagamento = pagamentosList.find(
                  (item) => item.cod === pag?.detPag?.tPag
                );
                const descricao = pagamento ? pagamento.desc : "";
                return (
                  <div className="listagemValores" key={shortid.generate()}>
                    <h6>{descricao}</h6>
                    <h6 className="listaValor">
                      {parseFloat(pag?.detPag?.vPag).toFixed(2)}
                    </h6>
                  </div>
                );
              })
            : null}
          <div className="listagemValores">
            <h6>Troco R$</h6>
            <h6 className="listaValor">
              {(
                parseFloat(data?.totalVenda) -
                parseFloat(data?.descontos) -
                parseFloat(
                  data?.pagamentos.reduce((acumulador, objetoAtual) => {
                    return (
                      acumulador + parseFloat(objetoAtual?.detPag?.vPag ?? 0)
                    );
                  }, 0)
                )
              ).toFixed(2)}
            </h6>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <h6 className="infoQrCode" style={{ padding: "2px 0" }}>
            Consulte pela Chave de Acesso em
          </h6>
          <h6>{data?.urlChave}</h6>
          <h6 className="infoQrCode" style={{ padding: "2px 0" }}>
            {data?.nfeChave}
          </h6>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            margin: "15px",
          }}
        >
          <div style={{ width: "175px", height: "auto" }}>
            <QRCode value={data?.qrCode} style={{ height: "fit-content" }} />
          </div>
          <div>
            <div className="infoQrCode" style={{ textAlign: "center" }}>
              <h6>{data?.nomeRazaoSocialDestinatario}</h6>
              <h6>CONSUMIDOR CPF: {data?.cnpjCpfDestinatario}</h6>
              <h6>PESSOA FISICA - DENTRO</h6>
              <h6>
                {data?.enderecoDestinatario?.xLgr}&nbsp;
                {data?.enderecoDestinatario?.nro}
              </h6>
              <h6>{data?.enderecoDestinatario?.xMun}</h6>
              <h6>NFC-e nº {data?.numeroNota}</h6>
              <h6>Série {data?.serie}</h6>
              <h6>{data?.dataHora}</h6>
              <h6>Protocolo de Autorização:</h6>
              <h6>{data?.protocolo}</h6>
              <h6>Data de Autorização {data?.dataHora}</h6>
              {data?.ambiente === "1" ? (
                <h6>
                  DANFE NFC-e - Documento Auxiliar da Nota Fiscal de Consumidor
                  Eletrônica
                </h6>
              ) : (
                <h6>
                  DOCUMENTO AUXILIAR DA NOTA FISCAL DE CONSUMIDOR ELETRÔNICA
                </h6>
              )}
            </div>
          </div>
        </div>
        {data?.status === "CANCELADA" && (
          <span id="danfe_cancelada">CANCELADA</span>
        )}
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <h6>{data?.informacoes?.infCpl || ""}</h6>
        </div>
        <div style={{ textAlign: "center" }}>
          <hr></hr>
          <h6>NOTA GERADA PELO SIAF LITE | Adsoft Gestão Empresarial</h6>
          <hr></hr>
        </div>
      </div>
    </div>
  ) : null;
};
export default ModeloNfceContingencia;
