import * as Yup from "yup";

const tabelaPrecoValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  tipoTabela: Yup.string().nullable(),
  aplicarDescontoEm: Yup.string().required("Tipo de desconto"),
  condicaoPagamentoId: Yup.number().nullable(),
  descontoTabela: Yup.string().required("Desconto tabela"),
});

export default tabelaPrecoValidator;
