import { ClickAwayListener as ClickAwayMui } from "@mui/material";
import { forwardRef } from "react";

const ClickAwayListener = forwardRef(
  (
    { children, onClickAway, disableReactTree, mouseEvent, touchEvent },
    ref
  ) => {
    return (
      <ClickAwayMui
        ref={ref}
        onClickAway={onClickAway}
        disableReactTree={disableReactTree ? true : false}
        mouseEvent={mouseEvent ? mouseEvent : "onClick"}
        touchEvent={touchEvent ? touchEvent : "onTouchEnd"}
      >
        {children}
      </ClickAwayMui>
    );
  }
);

export default ClickAwayListener;
