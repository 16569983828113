export class Pedido {
  orcamentoIds = null;
  orcamentoId = null;
  dataEmissao = null;
  operacaoFiscalId = null;
  serieDocumento = null;
  pessoaVendedorId = null;
  pessoaClienteId = null;
  pessoaProficionalId = null;
  pedidoEnderecoCliente = null;
  entrada = null;
  meioPagamentoEntradaId = null;
  quantidadeParcelas = null;
  desconto = null;
  acrescimo = null;
  despesas = null;
  informacaoAdicional = null;
  itens = null;
  pagamentos = null;
  vendedorObrigatorio = null;

  constructor(pedido) {
    this.orcamentoIds = pedido.orcamentoIds;
    this.orcamentoId = pedido.orcamentoId;
    this.dataEmissao = pedido.dataEmissao;
    this.operacaoFiscalId = pedido.operacaoFiscalId;
    this.serieDocumento = pedido.serieDocumento;
    this.pessoaVendedorId = pedido.pessoaVendedorId;
    this.pessoaClienteId = pedido.pessoaClienteId;
    this.pessoaProficionalId = pedido.pessoaProficionalId;
    this.pedidoEnderecoCliente = pedido.pedidoEnderecoCliente;
    this.entrada = pedido.entrada;
    this.meioPagamentoEntradaId = pedido.meioPagamentoEntradaId;
    this.quantidadeParcelas = pedido.quantidadeParcelas;
    this.desconto = pedido.desconto;
    this.acrescimo = pedido.acrescimo;
    this.despesas = pedido.despesas;
    this.informacaoAdicional = pedido.informacaoAdicional;
    this.itens = pedido.itens?.length
      ? pedido.itens?.map((item) => new Item(item))
      : [];
    this.pagamentos = pedido.pagamentos?.length
      ? pedido.pagamentos.map((pagamento) => new Pagameto(pagamento))
      : [];
    this.vendedorObrigatorio = pedido.vendedorObrigatorio;
  }
}

class Item {
  produtoId = null;
  unidade = null;
  quantidade = null;
  codigo = null;
  valorUnitario = null;
  ncm = null;
  tributosIcmsOrigem = null;

  constructor(itens) {
    this.produtoId = itens.produtoId;
    this.unidade = itens.unidade;
    this.quantidade = itens.quantidade;
    this.codigo = itens.codigo;
    this.valorUnitario = itens.valorUnitario;
    this.ncm = itens.ncm;
    this.tributosIcmsOrigem = itens.tributosIcmsOrigem;
  }
}

class Pagameto {
  meioPagamentoId = null;
  condicaoPagamentoId = null;
  valorTitulo = null;
  vencimento = null;

  constructor(pagamentos) {
    this.meioPagamentoId = pagamentos.meioPagamentoId;
    this.condicaoPagamentoId = pagamentos.condicaoPagamentoId;
    this.valorTitulo = pagamentos.valorTitulo;
    this.vencimento = pagamentos.vencimento;
  }
}
