import { useState, useEffect } from "react";
import {
  MarcasService,
  ProdutosService,
  notification,
} from "../../../../services/";
import {
  Autocomplete,
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CurrencyInput,
  FormControl,
  Grid,
  Loading,
  Typography,
  TextField,
} from "../../../../components";
import { responseErros, unformatPrice } from "../../../../utils";

const marcasService = new MarcasService();
const produtosService = new ProdutosService();

const PesquisaAvancada = ({ setProdutos, handleCloseDialog, loading }) => {
  let timeoutBuscaMarca = null;
  const [filtroAvancado, setFiltroAvancado] = useState(null);
  const [marcaList, setMarcaList] = useState([]);
  const [marca, setMarca] = useState(null);

  useEffect(() => {
    buscarMarcas();
  }, []);

  const buscarMarcas = (filtros) => {
    marcasService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setMarcaList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltroAvancado((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    e.persist();
    setFiltroAvancado((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const onInputChangeMarcas = (query) => {
    if (timeoutBuscaMarca) {
      clearTimeout(timeoutBuscaMarca);
    }
    timeoutBuscaMarca = setTimeout(() => {
      setMarcaList([]);
      if (query.toString().length >= 1) {
        const filtros = {
          nome: query,
          ativado: true,
        };
        buscarMarcas(filtros);
      } else {
        buscarMarcas();
      }
    }, 1000);
  };

  useEffect(() => {
    if (marca) {
      setFiltroAvancado((prevState) => ({
        ...prevState,
        marcaId: marca.id,
      }));
    }
  }, [marca]);

  const validateFields = () => {
    let error = false;
    let message = "";
    if (
      !filtroAvancado?.marcaId &&
      (!filtroAvancado?.precoVendaStart ||
        filtroAvancado?.precoVendaStart == "") &&
      (!filtroAvancado?.precoVendaEnd || filtroAvancado?.precoVendaEnd == "") &&
      (!filtroAvancado?.estoqueStart || filtroAvancado?.estoqueStart == "") &&
      (!filtroAvancado?.estoqueEnd || filtroAvancado?.estoqueEnd == "")
    ) {
      error = true;
      message = "É necessário informar ao menos 1 campo para filtrar";
      return { error, message };
    }
    if (
      (filtroAvancado?.precoVendaStart && !filtroAvancado?.precoVendaEnd) ||
      (filtroAvancado?.precoVendaEnd && !filtroAvancado?.precoVendaStart) ||
      (filtroAvancado?.precoVendaStart &&
        filtroAvancado?.precoVendaEnd == "") ||
      (filtroAvancado?.precoVendaEnd && filtroAvancado?.precoVendaStart == "")
    ) {
      error = true;
      message = "É necessário informar preço inicial e final para filtrar";
      return { error, message };
    }
    if (
      (filtroAvancado?.estoqueStart && !filtroAvancado?.estoqueEnd) ||
      (filtroAvancado?.estoqueEnd && !filtroAvancado?.estoqueStart) ||
      (filtroAvancado?.estoqueStart && filtroAvancado?.estoqueEnd == "") ||
      (filtroAvancado?.estoqueEnd && filtroAvancado?.estoqueStart == "")
    ) {
      error = true;
      message = "É necessário informar estoque inicial e final para filtrar";
      return { error, message };
    }
    return { error, message };
  };

  const handleSubmit = async () => {
    const { error, message } = validateFields();
    if (error) return notification.alertaGenericos(message);
    const porIntervalo = [];
    if (filtroAvancado.precoVendaStart && filtroAvancado.precoVendaEnd) {
      porIntervalo.push({
        coluna: "precoVenda",
        de: parseFloat(filtroAvancado.precoVendaStart),
        ate: parseFloat(filtroAvancado.precoVendaEnd),
      });
    }
    if (filtroAvancado.estoqueStart && filtroAvancado.estoqueEnd) {
      porIntervalo.push({
        coluna: "estoque",
        de: parseFloat(filtroAvancado.estoqueStart),
        ate: parseFloat(filtroAvancado.estoqueEnd),
      });
    }
    delete filtroAvancado.precoVendaStart;
    delete filtroAvancado.precoVendaEnd;
    delete filtroAvancado.estoqueStart;
    delete filtroAvancado.estoqueEnd;
    const result = await produtosService.getAllFiltroAvancado({
      ...filtroAvancado,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setProdutos(result.data.rows);
    } else {
      responseErros(result);
    }
    clearFiltros();
    handleCloseDialog(false);
  };

  const clearFiltros = () => {
    setFiltroAvancado({});
    setMarcaList([]);
    setMarca({});
  };

  return (
    <Container>
      <Card className="p-2">
        <CardHeader title={"Pesquisa de produtos avançada"} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <Autocomplete
                  id="marcaId"
                  name="marcaId"
                  options={marcaList}
                  noOptionsText="Sem opções"
                  autoHighlight
                  getOptionLabel={(option) => option.nome ?? ""}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Marca" variant="outlined" />
                  )}
                  onInputChange={(e, value) => onInputChangeMarcas(value)}
                  onChange={(e, value) => {
                    setMarca(value);
                    setMarcaList([]);
                  }}
                  value={marca}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Preço de venda</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CurrencyInput
                id="precoVendaStart"
                name="precoVendaStart"
                label="De"
                value={filtroAvancado?.precoVendaStart || ""}
                onBlur={handleInputBlur}
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput
                id="precoVendaEnd"
                name="precoVendaEnd"
                label="Até"
                value={filtroAvancado?.precoVendaEnd || ""}
                onBlur={handleInputBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>Estoque</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="estoqueStart"
                name="estoqueStart"
                label="De"
                variant="outlined"
                margin="normal"
                value={filtroAvancado?.estoqueStart || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="estoqueEnd"
                name="estoqueEnd"
                label="Até"
                variant="outlined"
                margin="normal"
                value={filtroAvancado?.estoqueEnd || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            ></i>
            Adicionar
          </Button>
          <Button
            variant="contained"
            color="padrao"
            onClick={handleCloseDialog}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

export default PesquisaAvancada;
