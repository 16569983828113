import { Paper as PaperMui } from "@mui/material";

const Paper = ({
  id,
  children,
  classes,
  component,
  elevation,
  square,
  sx,
  variant,
  className,
  ...rest
}) => {
  return (
    <PaperMui
      id={id}
      classes={classes}
      component={component}
      elevation={elevation ? elevation : 1}
      square={square ? true : false}
      sx={sx}
      variant={variant ? variant : "elevation"}
      className={className}
      {...rest}
    >
      {children}
    </PaperMui>
  );
};

export default Paper;
