import { Card, CardHeader, CardContent } from "../../../components";

const DashboardCard = ({ title, ActionIcon, children }) => {
  return (
    <Card className="p-2 rounded-4">
      <CardHeader
        title={title}
        action={ActionIcon && <div>{ActionIcon}</div>}
      />
      <CardContent
        style={{ textTransform: "none" }}
        className="d-flex flex-column align-items-center"
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
