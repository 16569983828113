import { useState } from "react";
import { format } from "date-fns";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import {
  DocumentoFreteService,
  history,
  notification,
} from "../../../services";
import { formatDate, formatPrice, responseErros } from "../../../utils";
import { FormFiltroAvancado } from "./components";
import { useEffect } from "react";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";

const ListDocumentoFreteView = () => {
  const [loading, setLoading] = useState(false);
  const [documentosFrete, setDocumentosFrete] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const documentoFreteService = new DocumentoFreteService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("documentos-frete-visualizar");

  const colunas = [
    {
      field: "serieDocumento",
      headerName: "Série",
      flex: 50,
    },
    {
      field: "numeroDocumento",
      headerName: "Número do Doc",
      flex: 150,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      flex: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      headerName: "Total Frete",
      flex: 150,
      valueGetter: (params) =>
        formatPrice(
          parseFloat(params.row?.valorFrete || 0) +
            parseFloat(params.row?.valorSeguro || 0)
        ),
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarDocumentosFrete(filtros);
  }, []);

  const buscarDocumentosFrete = async (filtros) => {
    setLoading(true);
    const result = await documentoFreteService.getAll(filtros);
    if (!result.isAxiosError) {
      setDocumentosFrete(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filtros);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarPesquisaAvancada = async (filtros) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
    };
    if (
      filtrosAvancadosTemp.dataEmissaoStart &&
      filtrosAvancadosTemp.dataEmissaoEnd
    ) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataEmissaoStart,
        ate: filtrosAvancadosTemp.dataEmissaoEnd,
      });
    }
    if (filtrosAvancadosTemp.totalStart && filtrosAvancadosTemp.totalEnd) {
      porIntervalo.push({
        coluna: "totalVenda",
        de: parseFloat(filtrosAvancadosTemp.totalStart),
        ate: parseFloat(filtrosAvancadosTemp.totalEnd),
      });
    }
    delete filtrosAvancadosTemp.dataEmissaoStart;
    delete filtrosAvancadosTemp.dataEmissaoEnd;
    delete filtrosAvancadosTemp.totalStart;
    delete filtrosAvancadosTemp.totalEnd;
    const result = await documentoFreteService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setDocumentosFrete(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filtros);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarDocumentosFrete(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const onClickCadastrarDocumentoFrete = () => {
    history.push("/estoque/documentos-frete/create");
  };

  const onClickEditarDocumentoFrete = (id) => {
    history.push(`/estoque/documentos-frete/${id}`);
  };

  const onClickDeletarDocumentoFrete = async (id) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir documento?"
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await documentoFreteService.deletar(id);
      if (!result.isAxiosError) {
        buscarDocumentosFrete();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  return (
    <ListLayout
      title="Documentos de Frete"
      loading={loading}
      onClickCadastrar={onClickCadastrarDocumentoFrete}
      permissaoButton={permissoesHelper.temPermisao(
        "documentos-frete-cadastrar"
      )}
    >
      <DataGrid
        rows={documentosFrete}
        columns={colunas}
        onClickEdit={onClickEditarDocumentoFrete}
        onClickDelete={onClickDeletarDocumentoFrete}
        visualizarShow={permissoesHelper.temPermisao(
          "documentos-frete-visualizar"
        )}
        editShow={permissoesHelper.temPermisao("documentos-frete-editar")}
        deleteShow={permissoesHelper.temPermisao("documentos-frete-excluir")}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={sendServerDatagrid}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListDocumentoFreteView;
