import { useEffect, useState } from "react";
import shortid from "shortid";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Typography,
  Autocomplete,
  CurrencyInput,
  DataGrid,
} from "../../../../../components";
import { notification } from "../../../../../services";
import { value } from "../../../../../helpers";
import { formatDate, formatPrice, unformatPrice } from "../../../../../utils";
import { addMonths, addDays } from "date-fns";

const PedidosPagamentos = ({
  id,
  pedido,
  listaPedidosPagamentos,
  setListaPedidosPagamentos,
  condicaoPagamentoList,
  meioPagamentoList,
  mediaQuery,
}) => {
  const [inputError, setInputError] = useState(null);
  const [condicaoPagParcelaList, setCondicaoPagParcelaList] = useState([]);
  const [condicaoSelecionada, setCondicaoSelecionada] = useState([]);
  const [gerarPagamentos, setGerarPagamentos] = useState({});

  const colunas = [
    {
      field: "condicaoPagamentoId",
      headerName: "Condição de Pagamento",
      flex: 250,
      valueGetter: (params) =>
        value.autoComplete(condicaoPagamentoList, params.value).descricao,
    },
    {
      field: "meioPagamentoId",
      headerName: "Meio de Pagamento",
      flex: 300,
      sortable: false,
      valueGetter: (params) =>
        value.autoComplete(meioPagamentoList, params.value).descricao,
    },
    {
      field: "valorTitulo",
      headerName: "Valor do Título",
      flex: 300,
      sortable: false,
      valueGetter: (params) =>
        params.value > 0 ? formatPrice(params.value) : "R$ 0,00",
    },
    {
      field: "vencimento",
      headerName: "Vencimento",
      flex: 200,
      type: "date",
      editable: !pedido.id ? true : false,
      sortable: false,
      valueGetter: (params) => {
        return formatDate.received(params.value);
      },
    },
  ];

  useEffect(() => {
    if (pedido?.totalPedido != pedido?.totalPagamentos) {
      setGerarPagamentos({
        valorTitulo:
          parseFloat(pedido?.totalPedido || 0) -
          parseFloat(pedido?.totalPagamentos || 0),
      });
    }
  }, []);

  const onChangeAutocomplete = (name, value) => {
    const pedidoPagamentoTemp = {
      ...gerarPagamentos,
      [name]: value ? value.id : null,
    };
    if (name === "condicaoPagamentoId") {
      setCondicaoSelecionada(value);
      pedidoPagamentoTemp.quantidadeParcelas = value?.parcelas;
    }
    setGerarPagamentos(pedidoPagamentoTemp);
  };

  const handleChangeInput = (event) => {
    const pedidoPagamentoTemp = {
      ...gerarPagamentos,
      [event.target.name]: unformatPrice(event.target.value) || null,
    };
    setGerarPagamentos(pedidoPagamentoTemp);
  };

  const validarGerarParcela = () => {
    if (!gerarPagamentos?.meioPagamentoId) {
      setInputError("meioPagamentoId");
      notification.erroValidacao("Meio de pagamento");
      return false;
    }
    if (!gerarPagamentos?.condicaoPagamentoId) {
      setInputError("condicaoPagamentoId");
      notification.erroValidacao("Condição de pagamento");
      return false;
    }
    if (!gerarPagamentos?.quantidadeParcelas) {
      setInputError("quantidadeParcelas");
      notification.erroValidacao("Quantidade de Parcelas");
      return false;
    }
    if (!gerarPagamentos?.valorTitulo) {
      setInputError("valorTitulo");
      notification.erroValidacao("Valor do título");
      return false;
    }
    if (
      parseFloat(pedido?.totalPagamentos || 0) +
        parseFloat(gerarPagamentos.valorTitulo) >
      pedido.totalPedido
    ) {
      notification.alertaGenericos("Valor inserido ultrapassa total do pedido");
      return false;
    }
    return true;
  };

  const handleGerarParcelas = () => {
    const validarParcela = validarGerarParcela();
    if (!validarParcela) return;

    const valorParcela =
      gerarPagamentos.valorTitulo / gerarPagamentos.quantidadeParcelas;
    const valorFormatado = Math.floor(valorParcela * 100) / 100;
    const restoDivisao =
      gerarPagamentos.valorTitulo -
      valorFormatado * gerarPagamentos.quantidadeParcelas;
    for (
      let contador = 1;
      contador <= gerarPagamentos?.quantidadeParcelas;
      contador++
    ) {
      const valorTitulo =
        contador === gerarPagamentos.quantidadeParcelas
          ? valorFormatado + parseFloat(restoDivisao.toFixed(2))
          : valorFormatado;
      const parcelaGerada = {
        id: shortid.generate(),
        condicaoPagamentoId: gerarPagamentos?.condicaoPagamentoId,
        meioPagamentoId: gerarPagamentos?.meioPagamentoId,
        vencimento: parcelaData(
          contador,
          pedido?.dataEmissao,
          condicaoSelecionada?.periodo
        ),
        valorTitulo,
      };
      setListaPedidosPagamentos((prevState) => [...prevState, parcelaGerada]);
    }
    setGerarPagamentos({
      valorTitulo:
        pedido?.totalPedido -
        parseFloat(pedido?.totalPagamentos ?? 0) -
        parseFloat(gerarPagamentos?.valorTitulo ?? 0),
    });
    notification.sucessoGenericos("Parcelas geradas com sucesso");
  };

  const parcelaData = (indice, data, intervaloDias) => {
    const dias = indice * (intervaloDias ?? 1);
    const meses = Math.floor(dias / 30);
    const diasRestantes = dias % 30;
    const dataAtualizada = addMonths(new Date(data), meses);
    const dataFinal = addDays(dataAtualizada, diasRestantes);
    return dataFinal;
  };

  const onSubmitParcelas = (event) => {
    event.preventDefault();
    handleGerarParcelas();
  };

  const onClickDeletePagamentos = async (idParcela) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir essa parcela?`
    );
    if (retornoAlerta.isConfirmed) {
      const parcelaADeletar = listaPedidosPagamentos.find(
        ({ id }) => id == idParcela
      );
      const data = listaPedidosPagamentos.filter(
        (item) => item.id !== idParcela
      );
      setGerarPagamentos({
        ...gerarPagamentos,
        valorTitulo:
          gerarPagamentos?.valorTitulo + parcelaADeletar?.valorTitulo,
      });
      setListaPedidosPagamentos(data);
    }
  };

  const onChangeMeioPagamento = (id, value) => {
    setInputError(null);
    const { condicoesPagamento } = value ?? [];
    const condicaoPagamentoId = condicoesPagamento?.[0]?.id ?? null;
    const quantidadeParcelas = condicoesPagamento?.[0]?.parcelas ?? null;
    setGerarPagamentos({
      ...gerarPagamentos,
      [id]: value?.id ?? null,
      condicaoPagamentoId,
      quantidadeParcelas,
    });
    if (condicoesPagamento?.length > 0) {
      setCondicaoSelecionada(condicoesPagamento[0]);
    }
    setCondicaoPagParcelaList(condicoesPagamento ?? []);
  };

  return (
    <>
      {mediaQuery ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                id="meioPagamentoId"
                name="meioPagamentoId"
                disabled={id && pedido.status !== "ABERTO"}
                options={meioPagamentoList}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meio de Pagamento"
                    variant="outlined"
                    error={inputError == "meioPagamentoId" ? true : false}
                    required
                  />
                )}
                onChange={(_, newValue) =>
                  onChangeMeioPagamento("meioPagamentoId", newValue)
                }
                value={value.autoComplete(
                  meioPagamentoList,
                  gerarPagamentos?.meioPagamentoId
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                required
                id="condicaoPagamentoId"
                name="condicaoDePagamento"
                disabled={id && pedido.status !== "ABERTO"}
                options={condicaoPagParcelaList}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                getOptionSelected={(option, value) =>
                  option?.descricao === value?.descricao
                }
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Condição de Pagamento"
                    variant="outlined"
                    error={inputError == "condicaoPagamentoId" ? true : false}
                    required
                  />
                )}
                onChange={(e, newValue) => {
                  e.persist();
                  onChangeAutocomplete("condicaoPagamentoId", newValue);
                }}
                value={value.autoComplete(
                  condicaoPagParcelaList,
                  gerarPagamentos?.condicaoPagamentoId
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CurrencyInput
              id="valorTitulo"
              name="valorTitulo"
              label="Valor do Título (R$)"
              inputVariant="outlined"
              required
              disabled={id && pedido.status !== "ABERTO"}
              error={inputError == "valorTitulo" ? true : false}
              value={gerarPagamentos?.valorTitulo ?? ""}
              onChange={(event) => {
                setInputError(null);
                handleChangeInput(event);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} fullWidth>
            <Button
              fullWidth
              className="my-2"
              size="large"
              variant="contained"
              disabled={
                pedido.status === "CANCELADO" || pedido.status === "BAIXADO"
              }
              color="primary"
              onClick={onSubmitParcelas}
            >
              <i
                className="ph-fill ph-plus-circle"
                style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
              ></i>
              Gerar Parcelas
            </Button>
          </Grid>
          <Grid container xs={12} fullWidth className="my-4">
            <Typography variant="overline">
              <h4 style={{ fontWeight: 800, margin: 0 }}>PARCELAS</h4>
            </Typography>
          </Grid>
          <Grid container xs={12} fullWidth>
            <DataGrid
              rows={listaPedidosPagamentos}
              columns={colunas}
              rowCount={listaPedidosPagamentos.length}
              deleteShow={!pedido?.id ? true : false}
              sortModel={[{ field: "condicaoPagamentoId", sort: "desc" }]}
              onClickDelete={onClickDeletePagamentos}
            />
          </Grid>
          <Grid
            container
            xs={12}
            fullWidth
            className="d-flex align-items-center justify-content-end mt-3"
          >
            <Grid
              item
              xs={12}
              className="d-flex align-items-center justify-content-end"
            >
              <span className="me-2">Saldo a pagar:</span>
              <span className="badge bg-secondary bg-gradient bg-gradient text-wrap p-2">
                {formatPrice(
                  (pedido?.totalPedido || 0) - (pedido?.totalPagamentos || 0)
                ) || "R$ 0,00"}
              </span>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid container spacing={2} xs={4} className="d-block">
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <Autocomplete
                  id="meioPagamentoId"
                  name="meioPagamentoId"
                  disabled={id && pedido.status !== "ABERTO"}
                  options={meioPagamentoList}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.descricao ? option.descricao : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Meio de Pagamento"
                      variant="outlined"
                      error={inputError == "meioPagamentoId" ? true : false}
                      required
                    />
                  )}
                  onChange={(_, newValue) =>
                    onChangeMeioPagamento("meioPagamentoId", newValue)
                  }
                  value={value.autoComplete(
                    meioPagamentoList,
                    gerarPagamentos?.meioPagamentoId
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  required
                  id="condicaoPagamentoId"
                  name="condicaoDePagamento"
                  disabled={id && pedido.status !== "ABERTO"}
                  options={condicaoPagParcelaList}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.descricao ? option.descricao : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.descricao === value?.descricao
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Condição de Pagamento"
                      variant="outlined"
                      error={inputError == "condicaoPagamentoId" ? true : false}
                      required
                    />
                  )}
                  onChange={(e, newValue) => {
                    e.persist();
                    onChangeAutocomplete("condicaoPagamentoId", newValue);
                  }}
                  value={value.autoComplete(
                    condicaoPagParcelaList,
                    gerarPagamentos?.condicaoPagamentoId
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <CurrencyInput
                id="valorTitulo"
                name="valorTitulo"
                label="Valor do Título (R$)"
                inputVariant="outlined"
                required
                disabled={id && pedido.status !== "ABERTO"}
                error={inputError == "valorTitulo" ? true : false}
                value={gerarPagamentos?.valorTitulo ?? ""}
                onChange={(event) => {
                  setInputError(null);
                  handleChangeInput(event);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} fullWidth>
              <Button
                fullWidth
                className="mt-3 mb-3"
                size="large"
                variant="contained"
                disabled={id && pedido.status !== "ABERTO"}
                color="primary"
                onClick={onSubmitParcelas}
              >
                <i
                  className="ph-fill ph-plus-circle"
                  style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
                ></i>
                Gerar Parcelas
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            xs={1}
            fullWidth
            className="d-flex justify-content-center"
          >
            <hr className="vr" />
          </Grid>
          <Grid container xs={7} className="d-block mt-3">
            <Grid container xs={12} fullWidth>
              <Typography variant="overline">
                <h4 style={{ fontWeight: 800, margin: 0 }}>PARCELAS</h4>
              </Typography>
            </Grid>
            <Grid container xs={12} fullWidth className="mt-4">
              <DataGrid
                rows={listaPedidosPagamentos}
                columns={colunas}
                rowCount={listaPedidosPagamentos.length}
                deleteShow={!pedido?.id ? true : false}
                sortModel={[{ field: "condicaoPagamentoId", sort: "desc" }]}
                onClickDelete={onClickDeletePagamentos}
              />
            </Grid>
            <Grid
              container
              xs={12}
              fullWidth
              className="d-flex align-items-center justify-content-end mt-3"
            >
              <Grid
                item
                xs={5}
                className="d-flex align-items-center justify-content-end"
              >
                <span className="me-2">Saldo a pagar:</span>
                <span className="badge bg-secondary bg-gradient bg-gradient text-wrap p-2">
                  {formatPrice(
                    (pedido?.totalPedido || 0) - (pedido?.totalPagamentos || 0)
                  ) || "R$ 0,00"}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PedidosPagamentos;
