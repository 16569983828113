import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  CardActions,
  Dialog,
  DialogContent,
  CurrencyInput,
  TextFielDecimalNumbers,
} from "../../../../../components";
import { unformatPrice } from "../../../../../utils";

const ComissaoVendedorDialog = ({
  openComissaoDialog,
  setOpenComissaoDialog,
  itemValorComissaoVendedor,
  setItemValorComissaoVendedor,
  itemPercentualComissaoVendedor,
  setItemPercentualComissaoVendedor,
  itemTotalComissaoVendedor,
  setItemTotalComissaoVendedor,
  listaPedidosProdutos,
  handleSalvarComissao,
  itemId,
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      PaperComponent="div"
      open={openComissaoDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader title="Comissão do Vendedor" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CurrencyInput
                  id="valorComissaoVendedor"
                  name="valorComissaoVendedor"
                  label="Comissão (R$)"
                  variant="outlined"
                  margin="normal"
                  value={itemValorComissaoVendedor || ""}
                  onBlur={(e) => {
                    const valor = unformatPrice(e.target.value);
                    setItemValorComissaoVendedor(valor);
                    const item = listaPedidosProdutos.find(
                      (x) => x.id === itemId
                    );
                    if (item) {
                      const percentualComissaoVendedor =
                        (valor * 100) / item.valor;
                      setItemPercentualComissaoVendedor(
                        percentualComissaoVendedor
                      );
                      const totalComissaoVendedor = valor * item.quantidade;
                      setItemTotalComissaoVendedor(totalComissaoVendedor);
                    }
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextFielDecimalNumbers
                  id="percentualComissaoVendedor"
                  name="percentualComissaoVendedor"
                  label="Comissão (%)"
                  variant="outlined"
                  margin="normal"
                  value={itemPercentualComissaoVendedor || ""}
                  onBlur={(e) => {
                    const valor = unformatPrice(e.target.value);
                    setItemPercentualComissaoVendedor(valor);
                    const item = listaPedidosProdutos.find(
                      (x) => x.id === itemId
                    );
                    if (item) {
                      const valorComissaoVendedor = item.valor * (valor / 100);
                      setItemValorComissaoVendedor(valorComissaoVendedor);
                      const totalComissaoVendedor =
                        valorComissaoVendedor * item.quantidade;
                      setItemTotalComissaoVendedor(totalComissaoVendedor);
                    }
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CurrencyInput
                  id="totalComissaoVendedor"
                  name="totalComissaoVendedor"
                  label="Total Comissão Vendedor R$)"
                  variant="outlined"
                  margin="normal"
                  value={itemTotalComissaoVendedor || ""}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSalvarComissao}
            >
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Salvar
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={() => setOpenComissaoDialog(false)}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default ComissaoVendedorDialog;
