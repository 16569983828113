import React from "react";
import "./relatorios-dialog.css";
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Button,
  ImpressoraRelatorios,
} from "../../components";

export default function RelatoriosDialog({ open, handleClose, content }) {
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar className="appbar-fullscreen">
        <Toolbar>
          <div className="container">
            <img
              src="https://siaflite-blob-bfesffcjhagbc4f6.z01.azurefd.net/imagem-publica/logo-lite-horizontal.svg"
              className="logo-report-bar"
            />
          </div>
          <ImpressoraRelatorios
            layoutImpressao={React.cloneElement(content, { print: true })}
          />
          <Button
            variant="outlined"
            className="m-3"
            color="padrao"
            onClick={handleClose}
          >
            <i
              className="ph ph-x"
              style={{ color: "#fff", paddingRight: "0.7em" }}
            />{" "}
            <Typography variant="h6" className="title-fullscreen" color="white">
              Fechar
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ overflowY: "auto", height: "calc(100vh - 65px)" }}>
        {content}
      </div>
    </Dialog>
  );
}
