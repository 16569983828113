export async function cacheApi(label, cacheBody) {
  const dataCache = cacheBody.dto.cacheDto;
  if (dataCache?.length) {
    const cachedValid = dataCache.find(
      (cache) =>
        cache.descricao === label &&
        timeDifference(cache.data, cacheBody.timeMinutes)
    );
    if (cachedValid) return cachedValid.dados;
  }
  const result = await cacheBody.apiFunction;
  cacheBody.dto.setCacheDto([
    ...dataCache.filter((cache) => cache.descricao !== label),
    {
      descricao: label,
      data: new Date().toISOString(),
      dados: result,
    },
  ]);
  return result;
}

function timeDifference(timeData, minutesDifference = 25) {
  const parsedDate = new Date(Date.parse(timeData));
  const today = new Date();
  const timeDiff = parsedDate.getTime() - today.getTime();
  const minutesDiff = timeDiff / (60 * 1000);
  const isToday =
    parsedDate.getFullYear() === today.getFullYear() &&
    parsedDate.getMonth() === today.getMonth() &&
    parsedDate.getDate() === today.getDate();
  if (isToday && Math.abs(minutesDiff) <= minutesDifference) return true;
  return false;
}
