export default class FormatCfopHelper {
  cfopComPontoMilhar = (cfop) => {
    if (!cfop) return cfop;
    const cfopString = [...cfop.toString()].map((num, index) => {
      return index === 1 && index !== cfop.length - 1 ? "." + num : num;
    });
    return cfopString.join("");
  };

  contraPartida = (cfop) => {
    const mapeamentoSt = {
      5405: "1403",
      5403: "1403",
      6405: "2403",
    };
    const prefixo = cfop ? cfop.substring(0, 1) : "";
    const novoPrefixo = {
      5: "1",
      6: "2",
      3: "7",
    }[prefixo];
    const cfopSt = mapeamentoSt[cfop];
    if (cfopSt) {
      return cfopSt;
    } else if (novoPrefixo) {
      cfop = novoPrefixo + cfop.substring(1);
      return cfop;
    }
    return cfop;
  };
}
