import { Toolbar as ToolbarMui } from "@mui/material";

const Toolbar = ({
  children,
  classes,
  className,
  component,
  disableGutters,
  sx,
}) => {
  return (
    <ToolbarMui
      className={className}
      classes={classes}
      component={component}
      disableGutters={disableGutters}
      sx={sx}
    >
      {children}
    </ToolbarMui>
  );
};

export default Toolbar;
