import { Fab as FabMui } from "@mui/material";

const Fab = ({
  children,
  className,
  classes,
  color,
  component,
  disabled,
  disableFocusRipple,
  disableRipple,
  href,
  size,
  sx,
  variant,
  onClick,
  ariaLabel,
}) => {
  return (
    <FabMui
      className={className}
      classes={classes}
      color={color}
      component={component}
      disabled={disabled}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      href={href}
      size={size}
      sx={sx}
      variant={variant}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
    </FabMui>
  );
};

export default Fab;
