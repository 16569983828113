const actions = {
  setMensagens: (mensagens) => {
    const mensagemNaoLida = mensagens.filter(
      (mensagem) => mensagem.visualizado == false
    );
    return {
      type: "SET_MENSAGENS",
      mensagens,
      quantidadeMensagemNaoLida: mensagemNaoLida.length,
    };
  },
};

export default actions;
