import {
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Icon,
} from "../../../../../components";
import { voltar } from "../../../../../utils";

const ErrosDfeDialog = ({
  open,
  setOpen,
  errosDfe,
  onClickEmitirNfSaidaPendenteContingencia,
}) => {
  return (
    <Dialog disableEscapeKeyDown maxWidth="md" fullWidth open={open}>
      <form>
        <DialogContent>
          <CardHeader
            title="Erros Dfe"
            action={
              <>
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{
                      color: errosDfe?.status === 400 ? "#EA9D2B" : "#DC3545",
                    }}
                  ></i>
                </Icon>
              </>
            }
          />
          <CardContent>
            <Grid container className="mt-3">
              <Typography>{errosDfe?.mensagem}</Typography>
            </Grid>
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              variant="contained"
              color="padrao"
              onClick={() => {
                setOpen(false);
                onClickEmitirNfSaidaPendenteContingencia();
              }}
            >
              Emitir em Contingência
            </Button>
            <Button
              variant="contained"
              color="padrao"
              onClick={() => {
                setOpen(false);
                voltar();
              }}
            >
              Fechar
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginLeft: 10 }}
              ></i>
            </Button>
          </CardActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default ErrosDfeDialog;
