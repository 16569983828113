import {
  Autocomplete,
  CurrencyInput,
  Grid,
  TextField,
} from "../../../../../components";
import { value } from "../../../../../helpers";

const NfEntradaFrete = ({ nfEntrada, transportadoraList }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Autocomplete
            id="transportadoraId"
            name="transportadoraId"
            disabled
            options={transportadoraList}
            getOptionLabel={(option) => option.nomeRazaoSocial ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Transportadora"
                variant="filled"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            value={value.autoComplete(
              transportadoraList,
              nfEntrada?.transportadoraId
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="valorFrete"
            name="valorFrete"
            label="Valor do Frete"
            margin="normal"
            variant="filled"
            disabled
            value={String(nfEntrada?.frete) || ""}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NfEntradaFrete;
