import { setInputErros, getInputErros } from "../utils";

class InputErros {
  constructor(inputErro, setInputErro) {
    this.inputErro = inputErro;
    this.setInputErro = setInputErro;
  }

  get(name) {
    return getInputErros(this.inputErro, name);
  }

  set(err) {
    setInputErros(this.setInputErro, err);
  }

  limpar() {
    this.setInputErro([]);
  }

  gerar(nomesInputs, mensagem) {
    const erro = {
      errors: [mensagem],
      inner: [],
    };
    if (Array.isArray(nomesInputs)) {
      for (const nomeInput of nomesInputs) {
        erro.inner.push({
          path: nomeInput,
        });
      }
    } else {
      erro.inner.push({
        path: nomesInputs,
      });
    }
    this.set(erro);
  }
}

export default InputErros;
