import { useEffect, useState } from "react";
import { Minimal } from "../../../layouts";
import { PessoaService, notification } from "../../../services";
import {
  Grid,
  TextField,
  FormControl,
  Autocomplete,
  DatePicker,
  Loading,
  Button,
} from "../../../components";
import { formatDate, responseErros } from "../../../utils";

const ContadorNfEntradaView = () => {
  const pessoaService = new PessoaService();
  const [contadorNfEntrada, setContadorNfEntrada] = useState({});
  const [fornecedorList, setFornecedorList] = useState([]);
  const [clienteList, setClienteList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscarClientes();
    buscarFornecedores();
  }, []);

  const buscarFornecedores = () => {
    setLoading(true);
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 2,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setFornecedorList(result.data);
      } else {
        setLoading(false);
        responseErros(result);
      }
    });
    setLoading(false);
  };

  const buscarClientes = () => {
    setLoading(true);
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data);
      } else {
        setLoading(false);
        responseErros(result);
      }
    });
    setLoading(false);
  };

  const onChangeInput = (event) => {
    setContadorNfEntrada({
      ...contadorNfEntrada,
      [event.target.name]: event.target.value || null,
    });
  };

  const onChangeAutocomplete = (name, value) => {
    setContadorNfEntrada({
      ...contadorNfEntrada,
      [name]: value ? value.id : undefined,
    });
  };

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === contadorNfEntrada[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const onChangeDate = (name, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setContadorNfEntrada({
      ...contadorNfEntrada,
      [name]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    });
  };

  const valueInput = (valueName) => {
    return contadorNfEntrada[valueName] ? contadorNfEntrada[valueName] : "";
  };

  const valueDate = (valueName) => {
    return contadorNfEntrada[valueName] ? contadorNfEntrada[valueName] : null;
  };

  const submitRelatorioReceberContador = () => {
    if (!contadorNfEntrada?.clienteId) {
      notification.alertaGenericos("Insira um cliente válido");
    }
  };

  return (
    <Minimal
      title="Contador - NF Entrada"
      cardAction={
        <Button
          onClick={submitRelatorioReceberContador}
          variant="contained"
          color="primary"
        >
          Gerar Relatório
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="clienteId"
              name="clienteId"
              options={clienteList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option?.nomeRazaoSocial ? option.nomeRazaoSocial : ""
              }
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              renderInput={(params) => (
                <TextField {...params} label="Cliente" variant="outlined" />
              )}
              onChange={(_, value) => onChangeAutocomplete("clienteId", value)}
              value={valueAutocomplete(clienteList, "fornecedorId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      {contadorNfEntrada?.clienteId && (
        <>
          <hr className="mt-3" />
          <Grid container spacing={2} className="mt-2">
            <Grid item xs={3}>
              <TextField
                id="numeroNota"
                name="numeroNota"
                label="Nº Nota"
                variant="outlined"
                fullWidth
                onChange={(event) => onChangeInput(event)}
                value={valueInput("numeroNota")}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="fornecedorId"
                  name="fornecedorId"
                  options={fornecedorList}
                  noOptionsText="Sem opções"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option?.nomeRazaoSocial ? option.nomeRazaoSocial : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.nomeRazaoSocial === value?.nomeRazaoSocial
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fornecedor"
                      variant="outlined"
                    />
                  )}
                  onChange={(_, value) =>
                    onChangeAutocomplete("fornecedorId", value)
                  }
                  value={valueAutocomplete(fornecedorList, "fornecedorId")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                id="dataEmissaoStart"
                name="dataEmissaoStart"
                label="Data Emissão De"
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoStart")}
                onChange={(date) => onChangeDate("dataEmissaoStart", date)}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                id="dataEmissaoEnd"
                name="dataEmissaoEnd"
                label="Data Emissão Até"
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoEnd")}
                onChange={(date) => onChangeDate("dataEmissaoEnd", date)}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Loading loading={loading} />
    </Minimal>
  );
};
export default ContadorNfEntradaView;
