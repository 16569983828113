import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import {
  history,
  ContasReceberService,
  PessoaService,
  notification,
  BancosCaixasService,
  ConfiguracoesService,
  CondicoesPagamentoService,
  MeioPagamentoService,
  ColaboradorService,
  SessionService,
} from "../../../services";
import { DataGrid, Grid, Typography, Tooltip, Icon } from "../../../components";
import { formatDate, responseErros, formatPrice } from "../../../utils";
import { format } from "date-fns";
import {
  BaixaLoteReceber,
  ButtonMenu,
  FiltroCustom,
  FormFiltroAvancado,
  RenegociacaoTitulos,
} from "./components";
import { useImportContext } from "../../../contexts/import.context";
import {
  calculaMultaJurosBaixa,
  calculaTotalAReceber,
} from "./list-contas-receber";
import { filtrosUrl, PermisoesHelper } from "../../../helpers";

const ListContasReceber = () => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("contas-receber-visualizar");
  const { setContaReceberDto } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [contasReceber, setContasReceber] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [listaPessoasFiltro, setListaPessoasFiltro] = useState([]);
  const [pessoaFiltro, setPessoaFiltro] = useState(null);
  const [buscaFiltro, setBuscaFiltro] = useState("");
  const [baixaLote, setBaixaLote] = useState({});
  const [rowCount, setRowCount] = useState(null);
  const [renderBaixaLote, setRenderBaixaLote] = useState(false);
  const [renegociacaoTitulos, setRenegociacaoTitulos] = useState({});
  const [renderRenegociacaoTitulos, setRenderRenegociacaoTitulos] =
    useState(false);
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [contasReceberSelecionadas, setContasReceberSelecionadas] = useState(
    []
  );
  const [listaBancosCaixas, setListaBancosCaixas] = useState([]);
  const [listaCondicoesPagamento, setListaCondicoesPagamento] = useState([]);
  const [listaMeiosPagamento, setListaMeiosPagamento] = useState([]);
  const [totalAReceber, setTotalAReceber] = useState({
    multa: 0,
    juros: 0,
    subtotal: 0,
    receber: 0,
  });
  const [parametrosFiscais, setParametrosFiscais] = useState({});
  const bancosCaixasService = new BancosCaixasService();
  const contasReceberService = new ContasReceberService();
  const pessoaService = new PessoaService();
  const configuracoesService = new ConfiguracoesService();
  const condicoesPagamentoService = new CondicoesPagamentoService();
  const meioPagamentoService = new MeioPagamentoService();
  const colaboradorService = new ColaboradorService();
  const sessionService = new SessionService();
  const status = [
    {
      id: 1,
      nome: "Aberto",
      color: "#4FBB53",
    },
    {
      id: 2,
      nome: "Baixado",
      color: "#004780",
    },
    {
      id: 3,
      nome: "Baixado Parcial",
      color: "#EA9D2B",
    },
    {
      id: 4,
      nome: "Cancelado",
      color: "#DC3545",
    },
  ];
  const buttonSelectOpcoes = ["Nota de Crédito"];
  const colunas = [
    {
      field: "status",
      headerName: "Status",
      flex: 50,
      renderCell: (params) => {
        switch (params.value) {
          case "BAIXADO":
            return (
              <Tooltip title="Baixado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#004780", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "BAIXADO_PARCIAL":
            return (
              <Tooltip title="Baixado Parcial" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#EA9D2B", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "CANCELADO":
            return (
              <Tooltip title="Cancelado" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#DC3545", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          default:
            return (
              <Tooltip title="Aberto" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#4FBB53", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
        }
      },
    },
    {
      field: "numeroTitulo",
      headerName: "Nº Título",
      flex: 230,
      filtrar: true,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 250,
      valueGetter: (params) =>
        params.value == "TITULO_NORMAL" ? "Título normal" : "Nota de crédito",
    },
    {
      field: "pagadorId",
      headerName: "Cliente",
      flex: 400,
      sortable: false,
      filtroPessoa: true,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 320,
      sortable: false,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "saldoReceber",
      headerName: "Saldo a receber",
      flex: 320,
      sortable: false,
      valueGetter: (params) =>
        params.value > 0 && params.row.status !== "CANCELADO"
          ? formatPrice(calculaMultaJurosBaixa(params.row, parametrosFiscais))
          : "R$ 0,00",
    },
    {
      field: "dataVencimento",
      headerName: "Data de Vencimento",
      flex: 250,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarContasReceber(filtros);
    buscarCondicoesPagamento();
    buscarMeiosPagamento();
  }, []);

  async function buscarPessoaVendedor(filtros) {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const dadosUsuario = sessionService.getUsuario();
    const permissaoCaixaColaborador = await permissoesHelper.temPermisao(
      "utilizar-caixa-colaborador"
    );
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === dadosUsuario.id
      );
      if (colaboradorVendedorUsuario && permissaoCaixaColaborador) {
        buscarBancosCaixas(colaboradorVendedorUsuario?.bancoCaixaId);
      } else {
        buscarBancosCaixas();
      }
    } else {
      responseErros(result);
    }
  }

  const buscarContasReceber = async (filtro = {}) => {
    setLoading(true);
    const result = await contasReceberService.getAll(filtro);
    if (!result.isAxiosError) {
      setContasReceber(result.data.rows);
      setRowCount(result.data.count);
      await buscarRelacionamentosContaReceber(result.data.rows);
      buscarPessoaVendedor();
      buscarJurosMulta();
      filtrosUrl.set(filtro);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarCondicoesPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    condicoesPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaCondicoesPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaMeiosPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarRelacionamentosContaReceber = async (listaContasReceber) => {
    const idsMeiosPagamento = [];
    const idsPessoas = [];
    for (const objeto of listaContasReceber) {
      if (objeto.pagadorId) idsPessoas.push(objeto.pagadorId);
      idsMeiosPagamento.push(objeto.meioPagamentoId);
    }
    const idsUnicosPessoas = [...new Set(idsPessoas)];
    await buscarPessoas({}, idsUnicosPessoas);
  };

  const buscarPessoas = async (filtros, listaIds) => {
    let filtro = { ativado: true, attributes: ["id", "nomeRazaoSocial"] };
    if (listaIds?.length) {
      filtro = {
        ...filtro,
        id: listaIds,
        nonPaginated: true,
      };
    } else {
      filtro = { ...filtro, ...filtros, limite: 15 };
    }
    const result = await pessoaService.getAllFiltroAvancadoSimplificada(filtro);
    if (!result.isAxiosError) {
      const listaPessoas = result.data;
      if (listaIds?.length) {
        setListaPessoas(listaPessoas);
      } else setListaPessoasFiltro(listaPessoas?.rows);
    } else {
      responseErros(result);
    }
  };

  const buscarBancosCaixas = async function (id) {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    if (id) filtros.id = id;
    const result = await bancosCaixasService.getAll(filtros);
    if (!result.isAxiosError) {
      setListaBancosCaixas(result.data);
    } else {
      responseErros(result);
    }
  };

  const onClickCadastrarContasReceber = (descricao) => {
    switch (descricao) {
      case "Nota de Crédito":
        setContaReceberDto((prevState) => ({
          ...prevState,
          tipo: "NOTA_CREDITO",
        }));
        history.push("/financeiro/contas-receber/create");
        break;
      default:
        history.push("/financeiro/contas-receber/create");
        break;
    }
  };

  const onClickEditContaReceber = (idContaReceber) => {
    history.push(`/financeiro/contas-receber/${idContaReceber}`);
  };

  const onClickDeleteContaReceber = async (contaReceberId) => {
    const contaSelecionada = contasReceber.find(
      (item) => item.id === contaReceberId
    );
    if (contaSelecionada.origem === "MANUAL") {
      const retornoAlerta = await notification.confirmacao(
        "Excluir!",
        "Tem certeza que deseja excluir esta conta a receber?"
      );
      if (retornoAlerta.isConfirmed) {
        if (contaSelecionada.status === "ABERTO") {
          setLoading(true);
          const result = await contasReceberService.deletar(contaReceberId);
          if (!result.isAxiosError) {
            buscarContasReceber();
            notification.deletadoSucesso();
          } else {
            setLoading(false);
            responseErros(result);
          }
        } else
          notification.alertaGenericos(
            "Só é permitido excluir títulos que se encontram em aberto."
          );
      }
    } else
      notification.alertaGenericos(
        "Só é permitido excluir títulos que foram inseridos manualmente."
      );
  };

  const buscarJurosMulta = () => {
    configuracoesService.get().then((result) => {
      if (!result.isAxiosError) {
        const data = result.data.rows[0];
        const parametrosFiscaisTemp = {
          jurosPorcentagem: data?.jurosPorcentagem
            ? data.jurosPorcentagem / 100
            : null,
          multaPorcentagem: data?.multaPorcentagem
            ? data.multaPorcentagem / 100
            : null,
          carencia: data?.carencia ?? 0,
        };
        setParametrosFiscais(parametrosFiscaisTemp);
        setBaixaLote((prevState) => ({
          ...prevState,
          ...parametrosFiscaisTemp,
        }));
        setRenegociacaoTitulos((prevState) => ({
          ...prevState,
          ...parametrosFiscaisTemp,
        }));
      }
    });
  };

  const buscarPesquisaAvancada = async (filter) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataLancamentoStart &&
      filtrosAvancadosTemp.dataLancamentoEnd
    ) {
      porIntervalo.push({
        coluna: "dataLancamento",
        de: filtrosAvancadosTemp.dataLancamentoStart,
        ate: filtrosAvancadosTemp.dataLancamentoEnd,
      });
    }
    if (
      filtrosAvancadosTemp.dataVencimentoStart &&
      filtrosAvancadosTemp.dataVencimentoEnd
    ) {
      porIntervalo.push({
        coluna: "dataVencimento",
        de: filtrosAvancadosTemp.dataVencimentoStart,
        ate: filtrosAvancadosTemp.dataVencimentoEnd,
      });
    }
    delete filtrosAvancadosTemp.dataLancamentoStart;
    delete filtrosAvancadosTemp.dataLancamentoEnd;
    delete filtrosAvancadosTemp.dataNegociacaoStart;
    delete filtrosAvancadosTemp.dataVencimentoStart;
    delete filtrosAvancadosTemp.dataVencimentoEnd;
    const result = await contasReceberService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setContasReceber(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosContaReceber(result.data.rows);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const filtrarListaPorPessoa = (value) => {
    if (value) {
      buscarContasReceber({ pagadorId: value.id });
      setBuscaFiltro("");
    } else {
      buscarContasReceber();
      setListaPessoasFiltro([]);
    }
  };

  const sendServerDatagrid = (props) => {
    const { filtros, tipoFiltro } = props;
    if (tipoFiltro === "simples") {
      buscarContasReceber(filtros);
    } else {
      buscarPesquisaAvancada(filtros);
    }
  };

  const retornoTopo = () => {
    const ref = document.getElementById("topo-contas-receber");
    setTimeout(
      () =>
        ref.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      100
    );
  };

  const handleSelecaoContas = (newRowSelectionModel) => {
    const listaContasReceber = newRowSelectionModel
      .map((rowId) => contasReceber.find((row) => row.id === rowId))
      .filter((conta) => conta?.status !== "BAIXADO");
    const totais = calculaTotalAReceber(
      listaContasReceber,
      baixaLote.dataPagamento ?? new Date(),
      baixaLote
    );
    setTotalAReceber(totais);
    setContasReceberSelecionadas(listaContasReceber);
    setLinhasSelecionadas(newRowSelectionModel);
  };

  return (
    <>
      <div id="topo-contas-receber"></div>
      <ListLayout
        title="Contas a Receber"
        loading={loading}
        buttonSelectOpcoes={buttonSelectOpcoes}
        onClickCadastrar={onClickCadastrarContasReceber}
        permissaoButton={permissoesHelper.temPermisao(
          "contas-receber-cadastrar"
        )}
        ButtonMenu={
          <ButtonMenu
            renderBaixaLote={renderBaixaLote}
            setRenderBaixaLote={setRenderBaixaLote}
            renderRenegociacaoTitulos={renderRenegociacaoTitulos}
            setRenderRenegociacaoTitulos={setRenderRenegociacaoTitulos}
          />
        }
      >
        <Grid container spacing={2}>
          {status.map(({ id, color, nome }) => (
            <Grid item xs={2} className="d-flex align-items-center" key={id}>
              <i
                className="ph-fill ph-circle"
                style={{ color, fontSize: 18 }}
              ></i>
              <Typography className="m-1">{nome}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          style={{
            marginBottom:
              renderBaixaLote || renderRenegociacaoTitulos ? 150 : 0,
          }}
        >
          <DataGrid
            checkboxSelection={renderBaixaLote || renderRenegociacaoTitulos}
            onRowSelectionModelChange={handleSelecaoContas}
            selectionModel={linhasSelecionadas}
            rows={contasReceber}
            columns={colunas}
            rowCount={rowCount}
            onClickEdit={onClickEditContaReceber}
            onClickDelete={onClickDeleteContaReceber}
            visualizarShow={permissoesHelper.temPermisao(
              "contas-receber-visualizar"
            )}
            editShow={permissoesHelper.temPermisao("contas-receber-editar")}
            deleteShow={permissoesHelper.temPermisao("contas-receber-excluir")}
            paginationMode={"server"}
            sendServer={(props) => sendServerDatagrid(props)}
            FormFiltroAvancado={FormFiltroAvancado}
            disableRowSelectionOnClick
            FiltroCustom={
              <FiltroCustom
                buscaFiltro={buscaFiltro}
                setBuscaFiltro={setBuscaFiltro}
                listaPessoasFiltro={listaPessoasFiltro}
                setListaPessoasFiltro={setListaPessoasFiltro}
                pessoaFiltro={pessoaFiltro}
                setPessoaFiltro={setPessoaFiltro}
                buscarPessoas={buscarPessoas}
                filtrarListaPorPessoa={filtrarListaPorPessoa}
                sendServer={sendServerDatagrid}
                columns={colunas}
              />
            }
          />
        </Grid>
      </ListLayout>
      <BaixaLoteReceber
        renderBaixaLote={renderBaixaLote}
        setRenderBaixaLote={setRenderBaixaLote}
        listaContasReceber={contasReceberSelecionadas}
        setListaContasReceber={setContasReceberSelecionadas}
        buscarContasReceber={buscarContasReceber}
        listaMeiosPagamento={listaMeiosPagamento}
        listaBancosCaixas={listaBancosCaixas}
        baixaLote={baixaLote}
        setBaixaLote={setBaixaLote}
        totalAReceber={totalAReceber}
        calculaTotalAReceber={calculaTotalAReceber}
        retornoTopo={retornoTopo}
      />
      <RenegociacaoTitulos
        renderRenegociacaoTitulos={renderRenegociacaoTitulos}
        setRenderRenegociacaoTitulos={setRenderRenegociacaoTitulos}
        renegociacaoTitulos={renegociacaoTitulos}
        setRenegociacaoTitulos={setRenegociacaoTitulos}
        calculaTotalAReceber={calculaTotalAReceber}
        buscarContasReceber={buscarContasReceber}
        listaCondicoesPagamento={listaCondicoesPagamento}
        listaContasReceber={contasReceberSelecionadas}
        setListaContasReceber={setContasReceberSelecionadas}
        listaPessoas={listaPessoas}
        totalAReceber={totalAReceber}
        retornoTopo={retornoTopo}
        setLoading={setLoading}
        setLinhasSelecionadas={setLinhasSelecionadas}
        setTotalAReceber={setTotalAReceber}
        listaMeiosPagamento={listaMeiosPagamento}
      />
    </>
  );
};

export default ListContasReceber;
