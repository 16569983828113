export class UsuarioEntity {
  nome = null;
  senha = undefined;
  confirmarSenha = undefined;

  constructor(usuario) {
    this.nome = usuario.nome;
    if (usuario.senha) {
      this.senha = usuario.senha;
      this.confirmarSenha = usuario.confirmarSenha;
    }
  }
}
