import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  FormControl,
  TextField,
  Grid,
  Card,
  CardContent,
  Switch,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import { CfopService, OperacaoFiscalService } from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";
import "../../configuracoes.style.css";

const ConfiguracaoComprasTab = ({
  configuracao,
  onChangeAutocomplete,
  onChangeSwitch,
}) => {
  const inputRefCfop = useRef();
  const operacaoFiscalService = new OperacaoFiscalService();
  const cfopService = new CfopService();
  const [inputErro, setInputErro] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [cfopList, setCfopList] = useState([]);
  const [loadingAutoCompleteCfop, setLoadingAutoCompleteCfop] = useState(false);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscarOperacaoFiscal();
    buscarCfops({}, configuracao?.cfopComprasPadraoId);
  }, []);

  const buscarOperacaoFiscal = () => {
    const filtros = {
      ativado: true,
      tipoOperacao: "ENTRADA",
      restritiva: true,
      finalidade: ["NAO_SE_APLICA", "NFE_NORMAL"],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCfops = async (filtros, id) => {
    const filtro = {
      ...filtros,
      limite: 20,
    };
    if (id) {
      filtro.id = id;
    }
    const result = await cfopService.getAll(filtro);
    setLoadingAutoCompleteCfop(false);
    if (!result.isAxiosError) {
      setCfopList(result.data.rows);
      return result.data.rows;
    } else {
      responseErros(result);
    }
  };

  const onInputChangeCfop = (event) => {
    if (
      event.key === "Enter" &&
      inputRefCfop.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          descricao: eventValue,
        };
        setLoadingAutoCompleteCfop(true);
        timeoutBuscaAutocomplete(buscarCfops(filtros));
      }
    }
  };

  return (
    <Card className="p-2">
      <CardContent>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            COM001 - Operação fiscal padrão.
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                id="operacaoFiscalComprasPadraoId"
                name="operacaoFiscalComprasPadraoId"
                options={operacaoFiscalList}
                noOptionsText="Sem opções"
                autoHighlight
                disableClearable
                getOptionLabel={(option) => (option ? option.descricao : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operação Fiscal"
                    variant="outlined"
                    error={inputErros.get("operacaoFiscalComprasPadraoId")}
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete(
                    "operacaoFiscalComprasPadraoId",
                    newValue
                  );
                }}
                value={value.autoComplete(
                  operacaoFiscalList,
                  configuracao?.operacaoFiscalComprasPadraoId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            COM002 - Permitir precificar produto ao lançar NF de Entrada.
          </Grid>
          <Grid item xs={4}>
            <Switch
              id="precificacaoNfEntrada"
              name="precificacaoNfEntrada"
              checked={
                configuracao?.precificacaoNfEntrada
                  ? configuracao?.precificacaoNfEntrada
                  : false
              }
              onChange={onChangeSwitch}
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            COM003 - CFOP padrão ao lançar produto em NF de Entrada.
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                id="cfopComprasPadraoId"
                name="cfopComprasPadraoId"
                options={cfopList}
                noOptionsText="Digite e pressione Enter"
                autoHighlight
                getOptionLabel={(option) => {
                  const optionDescricao = option?.descricao?.replace(".", "");
                  return optionDescricao ? optionDescricao : "";
                }}
                filterSelectedOptions
                loading={loadingAutoCompleteCfop}
                loadingText="Carregando"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="CFOP"
                    variant="outlined"
                    error={inputErros.get("cfopComprasPadraoId")}
                    onKeyDown={onInputChangeCfop}
                    inputRef={inputRefCfop}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete("cfopComprasPadraoId", newValue);
                }}
                value={value.autoComplete(
                  cfopList,
                  configuracao?.cfopComprasPadraoId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ConfiguracaoComprasTab;
