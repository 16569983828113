import { NotificacaoService } from "../services";
import { StorageHelper } from "../helpers";
import { responseErros } from "../utils";
const storageHelper = new StorageHelper();
const notificacaoService = new NotificacaoService();

const buscarNotificacao = (setMensagens, usuarioId) => {
  const filter = {
    orderBy: "createdAt",
    pctUsuarioId: usuarioId,
    order: "desc",
    limite: 15,
  };
  notificacaoService.getAll(filter).then((res) => {
    if (!res.isAxiosError) {
      storageHelper.setSession("notificacao", JSON.stringify(res.data.rows));
      setMensagens(res.data.rows);
    } else {
      responseErros(res);
    }
  });
};

export default buscarNotificacao;
