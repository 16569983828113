import {
  Button,
  Loading,
  MenuCustom,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "../../components";
import "./flex.css";

const FlexLayout = ({
  id,
  formAction,
  onClickSalvar,
  loading,
  title,
  codigo,
  ButtonMenu,
  actions,
  children,
  nonSubmit,
}) => {
  return (
    <Container container>
      <MenuCustom codigo={codigo} ButtonMenu={ButtonMenu} />
      <Card className="p-2">
        {(title || actions) && <CardHeader title={title} action={actions} />}
        <CardContent className="rolagem-flex-layout">{children}</CardContent>
        {!nonSubmit ? (
          <CardActions>
            {id ? (
              <Button
                type={formAction ? "submit" : "button"}
                form={formAction ? "form" : undefined}
                onClick={(event) => !formAction && onClickSalvar(event)}
                variant="contained"
                className="button-add-flex-layout"
                color="primary"
              >
                Atualizar
              </Button>
            ) : (
              <Button
                type={formAction ? "submit" : "button"}
                form={formAction ? "form" : undefined}
                onClick={(event) => !formAction && onClickSalvar(event)}
                variant="contained"
                className="button-add-flex-layout"
              >
                SALVAR
              </Button>
            )}
          </CardActions>
        ) : (
          <></>
        )}
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

export default FlexLayout;
