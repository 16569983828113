import { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Box } from "@mui/material";

const SelecionarArquivoXmlStep = ({ arquivoXml, setArquivoXml }) => {
  const xmlUpload = useRef(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "text/xml",
    onDrop: (acceptedFiles) => {
      handleXmlChange(acceptedFiles);
    },
  });

  function handleXmlChange(event) {
    if (event.target) {
      setArquivoXml(event.target.files[0]);
    } else {
      setArquivoXml(event[0]);
    }
  }

  return (
    <Box
      {...getRootProps()}
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1em",
        border:
          isDragActive || arquivoXml
            ? "2px dashed grey"
            : "2px solid transparent",
        borderRadius: 2,
        padding: 2,
        backgroundColor: isDragActive || arquivoXml ? "#e3f2fd" : "#fafafa",
        ":hover": { backgroundColor: "#e3f2fd", border: "2px dashed grey" },
      }}
      onClick={() => xmlUpload.current.click()}
    >
      <input
        {...getInputProps()}
        type="file"
        style={{ display: "none" }}
        ref={xmlUpload}
        onChange={(event) => handleXmlChange(event.target.files)}
      />
      <h4 style={{ fontWeight: 800, margin: 0 }}>
        {arquivoXml
          ? "Arquivo Selecionado!"
          : "Arraste e solte ou Clique aqui para importar arquivo XML"}
      </h4>
      {arquivoXml?.name && (
        <div className="mt-2">
          <Typography>{arquivoXml.name}</Typography>
        </div>
      )}
    </Box>
  );
};

export default SelecionarArquivoXmlStep;
