import { useRef, useState } from "react";
import {
  TextField,
  Grid,
  Dialog,
  CardHeader,
  Card,
  CardContent,
  FormControl,
  Autocomplete,
  DialogActions,
  Button,
} from "../../../../components";
import { notification } from "../../../../services";
import { timeoutBuscaAutocomplete } from "../../../../utils";

const ConversaoEntradaDialog = ({
  buscarProdutos,
  listaProdutos,
  setListaProdutos,
  converterEntrada,
  setConverterEntrada,
  produtoSelecionado,
  dadosXML,
  setDadosXML,
  setProdutosConvertidos,
  loadingAutocomplete,
  setLoadingAutocomplete,
  setTemProdutosNaoCadastrados,
}) => {
  const inputRef = useRef();
  const [produtoTroca, setProdutoTroca] = useState(null);
  const [quantidadeTroca, setQuantidadeTroca] = useState(null);

  const handleVincularProduto = (event) => {
    event.preventDefault();
    if (!quantidadeTroca || quantidadeTroca <= 0) {
      return notification.alertaGenericos(
        "Insira uma quantidade no item de conversão."
      );
    }
    if (!produtoTroca) {
      return notification.alertaGenericos(
        "Insira uma quantidade no item de conversão."
      );
    }
    const index = dadosXML.itens?.findIndex(
      (item) => item.id === produtoSelecionado.id
    );
    const produtosXmlTemp = [
      ...dadosXML.itens.map((item, _index) => {
        if (_index === index) {
          setProdutosConvertidos((prevState) => [
            ...prevState,
            { ...item, produtoIndice: index },
          ]);
          const precoCompra = parseFloat(
            (item.precoCompra / quantidadeTroca).toFixed(2)
          );
          const quantidade = quantidadeTroca * item.quantidade;
          const produtoTemp = {
            ...item,
            ...produtoTroca,
            id: produtoTroca.id,
            produtoId: produtoTroca.id,
            produto: produtoTroca.descricao,
            quantidade,
            precoCompra,
            subtotal: quantidade * precoCompra,
            produtoCadastrado: true,
            produtoConvertido: true,
          };
          return produtoTemp;
        } else {
          return item;
        }
      }),
    ];
    setListaProdutos([]);
    setTemProdutosNaoCadastrados(
      produtosXmlTemp.some((produto) => produto.produtoCadastrado == false)
    );
    setProdutoTroca(null);
    setQuantidadeTroca(null);
    setDadosXML((prevState) => ({
      ...prevState,
      itens: produtosXmlTemp,
    }));
    setConverterEntrada(false);
  };

  const handleChangeProduto = (value) => {
    setProdutoTroca(value);
    document.getElementById("quantidadeTroca").focus();
  };

  const onInputChangeProdutos = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value.includes(",")
        ? event.target?.value.split(",")[0]
        : event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          codigoBarra: eventValue,
          descricao: eventValue,
        };
        setLoadingAutocomplete(true);
        timeoutBuscaAutocomplete(buscarProdutos(filtros));
      }
    }
  };

  return (
    <Dialog
      open={converterEntrada}
      disableEscapeKeyDown
      disableClickEventBubbling
      fullWidth
      maxWidth="md"
    >
      <Card className="p-3">
        <CardHeader title="Conversão de Entrada" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id="produtoTroca"
                  name="produtoTroca"
                  options={listaProdutos}
                  autoHighlight
                  loading={loadingAutocomplete}
                  loadingText="Carregando"
                  noOptionsText="Digite e pressione Enter"
                  getOptionLabel={(option) =>
                    option ? `${option?.codigo} - ${option?.descricao}` : ""
                  }
                  filterSelectedOptions
                  onChange={(_, newValue) => handleChangeProduto(newValue)}
                  value={produtoTroca}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={"outlined"}
                      label="Buscar produto"
                      onKeyDown={onInputChangeProdutos}
                      inputRef={inputRef}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="quantidadeTroca"
                name="quantidadeTroca"
                label="Quantidade"
                variant={"outlined"}
                type="number"
                InputProps={
                  produtoTroca?.fracionavel
                    ? { inputProps: { min: 0.01, step: 0.01 } }
                    : { inputProps: { min: 1, step: 1 } }
                }
                value={quantidadeTroca}
                onChange={(e) => {
                  let quantidade = e.target.value;
                  if (quantidade <= 0) quantidade = "";
                  if (produtoTroca?.fracionavel) {
                    quantidade = Number(quantidade);
                  } else {
                    quantidade = Math.trunc(Number(quantidade));
                  }
                  setQuantidadeTroca(quantidade);
                }}
                onKeyDown={(event) =>
                  event.key === "Enter" ? handleVincularProduto(event) : null
                }
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogActions>
        <Button variant="contained" onClick={() => setConverterEntrada(false)}>
          Fechar
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleVincularProduto}
        >
          <i
            className="ph-fill ph-git-diff"
            style={{ fontSize: 22, marginRight: 10, color: "white" }}
          ></i>
          Trocar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConversaoEntradaDialog;
