import { Accordion as AccordionMui } from "@mui/material";

const Accordion = ({
  children,
  classes,
  className,
  defaultExpanded,
  disabled,
  disableGutters,
  expanded,
  onChange,
  square,
  sx,
  ref,
  TransitionComponent,
  TransitionProps,
}) => {
  return (
    <AccordionMui
      classes={classes}
      className={className}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
      disableGutters={disableGutters}
      expanded={expanded}
      onChange={onChange}
      square={square}
      sx={sx}
      ref={ref}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
    >
      {children}
    </AccordionMui>
  );
};

export default Accordion;
