import * as Yup from "yup";

const nfSaidaItemUpdateImposto = Yup.object().shape({
  ncmCodigo: Yup.string().required("Ncm Código"),
  ncmId: Yup.string().required("Ncm"),
  tributosIcmsCsosn: Yup.string().required("CSO"),
  cfop: Yup.string().required("Cfop"),
});

export default nfSaidaItemUpdateImposto;
