import nfSaidaTotalTributos from "./calcular-tributos-nf-saida";

class NfSaidaItemImpostos {
  constructor(imposto, item, nfSaida) {
    this.calculaIcms =
      nfSaida?.documentoDevolucaoFornecedor ?? imposto?.calculaIcms;
    this.calculaIcmsSt =
      nfSaida?.documentoDevolucaoFornecedor ?? imposto?.calculaIcmsSt;
    this.modeloTributacaoId = imposto.modeloTributacaoId;
    this.tributosIcmsOrigem = item.tributosIcmsOrigem;
    this.tributosIcmsCsosn = imposto.csoSimplesNacional;
    this.cfop = imposto.cfop?.codigo;
    this.tributosIcmsBaseCalculoValor = imposto?.reducaoBase
      ? item?.subtotal - item?.subtotal * (imposto?.reducaoBase / 100)
      : item?.subtotal;
    this.tributosIcmsAliquota =
      item?.tributosIcmsAliquota || imposto?.aliquotaIcms;
    this.tributosIcmsValor = nfSaidaTotalTributos.calculaValorIcms({
      ...item,
      ...imposto,
      aliquotaIcms: item?.tributosIcmsAliquota || imposto?.aliquotaIcms,
    });
    this.tributosIpiCst = imposto?.situacaoTributariaIpiId;
    this.tributosIpiEnquadramento = imposto?.enquadramentoLegalId;
    this.tributosIpiBaseCalculoValor = item?.subtotal;
    this.tributosIpiAliquota = item?.tributosIpiAliquota || imposto.aliquotaIpi;
    this.tributosIpiValor =
      item?.subtotal * ((imposto?.aliquotaIpi || 0) / 100);
  }
}

export default NfSaidaItemImpostos;
