import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
  Loading,
  DataGrid,
} from "../../../../components";
import { value } from "../../../../helpers";
import {
  formatDate,
  formatPrice,
  responseErros,
  voltar,
} from "../../../../utils";
import { format } from "date-fns";
import {
  ContasPagarPagamentosService,
  notification,
} from "../../../../services";
import Swal from "sweetalert2";
import { useImportContext } from "../../../../contexts/import.context";

const RetirarBaixaDialog = ({
  id,
  open,
  setOpen,
  getAtrasoDias,
  contaList,
  contaPagar,
  pagamento,
  recebedorList,
  meioPagamentoList,
  dadosPagamentoList,
}) => {
  const { initialCollapses } = useImportContext();
  const [listaBaixas, setListaBaixas] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [baixasSelecionadas, setBaixasSelecionadas] = useState([]);
  const [loading, setLoading] = useState(false);
  const contasPagarPagamentoService = new ContasPagarPagamentosService();

  const dadosPagamentoColunas = [
    {
      field: "dataPagamento",
      headerName: "Data Pagamento",
      flex: 475,
      valueGetter: (params) => {
        return format(formatDate.received(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "horaPagamento",
      headerName: "Hora Pagamento",
      flex: 400,
      valueGetter: (params) => {
        return format(formatDate.received(params.value), "HH:mm");
      },
    },
    {
      field: "recebedorId",
      headerName: "Responsável",
      flex: 500,
      valueGetter: (params) => {
        const recebedor = value.autoComplete(recebedorList, params.value);
        if (recebedor) return recebedor.nomeRazaoSocial;
        return params.value;
      },
    },
    {
      field: "bancoCaixaId",
      headerName: "Banco/Caixa",
      flex: 400,
      valueGetter: (params) => {
        const conta = contaList.find((item) => item.id == params.value);
        if (!conta) return params.value;
        return conta.nome;
      },
    },
    {
      field: "atrasoDias",
      headerName: "Atraso",
      flex: 350,
      valueGetter: () => {
        return getAtrasoDias(
          contaPagar?.dataVencimento,
          pagamento?.dataPagamento
        );
      },
    },
    {
      field: "meioPagamentoId",
      headerName: "Meio Pagamento",
      flex: 600,
      valueGetter: (params) => {
        const meioPagamento = meioPagamentoList.find(
          (meio) => meio.id == params.value
        );
        if (!meioPagamento) return params.value;
        return meioPagamento.descricao;
      },
    },
    {
      field: "tipoBaixa",
      headerName: "Tipo de Baixa",
      flex: 600,
      renderCell: (params) => {
        switch (params.value) {
          case "BAIXA_RENEGOCIACAO":
            return "Baixa por Renegociação";
          default:
            return "Baixa Normal";
        }
      },
    },
    {
      field: "valor",
      headerName: "Valor Pago",
      flex: 450,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
  ];

  const cancelarPagamentos = async () => {
    if (baixasSelecionadas?.length) {
      const retornoAlerta = await notification.confirmacaoGenericos({
        title: "Excluir!",
        text: "Tem certeza que deseja retirar as baixas?",
        icon: "warning",
        confirmButtonText: "EXCLUIR",
        cancelButtonText: "CANCELAR",
        showCancelButton: true,
      });
      if (retornoAlerta.isConfirmed) {
        setLoading(true);
        const res = await contasPagarPagamentoService.removerPagamentos(
          id,
          baixasSelecionadas
        );
        setLoading(false);
        if (!res.isAxiosError) {
          const retornoAlerta = await Swal.fire({
            title: "Baixas removidas com sucesso!",
            icon: "success",
            confirmButtonText: "OK",
          });
          if (retornoAlerta.isConfirmed) {
            initialCollapses();
            voltar();
          }
        } else {
          responseErros(res);
        }
      }
    } else {
      notification.alertaGenericos("Selecione pelo menos uma baixa");
    }
  };

  const handleSelecaoBaixas = (newRowSelectionModel) => {
    const notasSelecionadas = newRowSelectionModel.map((rowId) =>
      dadosPagamentoList.find((row) => row.id === rowId)
    );
    setBaixasSelecionadas(notasSelecionadas);
    setSelectionModel(newRowSelectionModel);
  };

  const onClose = () => {
    setSelectionModel([]);
    setBaixasSelecionadas([]);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const baixasReais = dadosPagamentoList.filter(
        (item) => parseFloat(item.valor) > 0
      );
      setListaBaixas(baixasReais);
    }
  }, [open, contaPagar]);

  return (
    <Dialog disableEscapeKeyDown maxWidth="xl" fullWidth open={open}>
      <DialogContent>
        <CardHeader title="Retirar Baixa" />
        <CardContent>
          <Grid container spacing={3}>
            <DataGrid
              checkboxSelection
              selectionModel={selectionModel}
              onRowSelectionModelChange={handleSelecaoBaixas}
              rows={listaBaixas}
              columns={dadosPagamentoColunas}
              rowCount={listaBaixas?.length}
              disableAcoes
            />
          </Grid>
        </CardContent>
        <CardActions className="d-flex justify-content-end">
          <Button variant="contained" color="padrao" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={cancelarPagamentos}
          >
            retirar baixa
          </Button>
        </CardActions>
      </DialogContent>
      <Loading loading={loading} />
    </Dialog>
  );
};

export default RetirarBaixaDialog;
