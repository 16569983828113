import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
} from "../../../../components";
import { TiposPessoasService } from "../../../../services";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const [listaTiposPessoa, setListaTiposPessoa] = useState([]);
  const tiposPessoasService = new TiposPessoasService();

  useEffect(() => {
    buscaTipoPessoa();
  }, []);

  const buscaTipoPessoa = async () => {
    const res = await tiposPessoasService.getAll();
    if (!res.isAxiosError) {
      const filterCredenciadora = res.data.filter(
        (objeto) => objeto.descricao !== "Credenciadora"
      );
      setListaTiposPessoa(filterCredenciadora);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          id="ativado"
          name="ativado"
          value={valueInput("ativado")}
          label="Status"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value="">
            <p></p>
          </MenuItem>
          <MenuItem value={"true"}>Ativado</MenuItem>
          <MenuItem value={"false"}>Desativado</MenuItem>
          <MenuItem value={"-"}>Ambos</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="nomeRazaoSocial"
          name="nomeRazaoSocial"
          label="Nome / Razão Social"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("nomeRazaoSocial")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="naturezaSocial"
          name="naturezaSocial"
          value={valueInput("naturezaSocial")}
          label="Natureza Social"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value="">
            <p></p>
          </MenuItem>
          <MenuItem value={"fisica"}>Física</MenuItem>
          <MenuItem value={"juridica"}>Jurídica</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="cnpjCpf"
          name="cnpjCpf"
          label="CNPJ / CPF"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("cnpjCpf")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          name="email"
          label="E-mail"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("email")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="tiposPessoaId"
          name="tiposPessoaId"
          options={listaTiposPessoa}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) =>
            option.descricao ? option.descricao : ""
          }
          renderInput={(params) => (
            <TextField {...params} label="Tipos de Pessoa" variant="outlined" />
          )}
          onChange={(e, value) => onChangeAutocomplete("tiposPessoaId", value)}
          value={valueAutocomplete(listaTiposPessoa, "tiposPessoaId")}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
