export function totalSuprimento(listaCaixa) {
  if (listaCaixa?.length) {
    const total = listaCaixa.reduce((acumulador, elem) => {
      if (elem.categoriaMovimentacao === "SUPRIMENTO") {
        return acumulador + parseFloat(elem.valor);
      } else return 0;
    }, 0);
    return total;
  }
}

export function totalSangria(listaCaixa) {
  if (listaCaixa?.length) {
    const total = listaCaixa.reduce((acumulador, elem) => {
      if (elem.categoriaMovimentacao === "SANGRIA") {
        return acumulador + parseFloat(elem.valor);
      } else return 0;
    }, 0);
    return total;
  }
}
