import { useState, useEffect } from "react";
import shortid from "shortid";
import {
  Autocomplete,
  CurrencyInput,
  Grid,
  TextField,
  FormControl,
  Paper,
  Tabs,
  Tab,
  TabPanel,
  TextFielDecimalNumbers,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  notification,
  ServicoService,
  CodigoServicoService,
} from "../../../services";
import { InputErros } from "../../../helpers";
import { userLogs, responseErros, unformatPrice } from "../../../utils";
import { CamposPersonalizados, CampoPersonalizadoDialog } from "./components";
import servicoValidator from "./middlewares/servico.validator";

const servicoService = new ServicoService();
const codigoServicoService = new CodigoServicoService();

const AddServico = ({ match }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [initialServicoState, setInitialServicoState] = useState();
  const [servico, setServico] = useState();
  const [inputErro, setInputErro] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [listaCodigoServico, setListaCodigoServico] = useState([]);
  const [codigoServico, setCodigoServico] = useState(null);
  const [listaCamposPersonalizados, setListaCamposPersonalizados] = useState(
    []
  );
  const [campoPersonalizado, setCampoPersonalizado] = useState({});
  const [openCampoPersonalizadoDialog, setOpenCampoPersonalizadoDialog] =
    useState(false);
  const [tamanhoGrid, setTamanhoGrid] = useState(6);
  const inputErros = new InputErros(inputErro, setInputErro);

  const tiposCampo = [
    {
      id: 1,
      type: "date",
      descricao: "Data",
    },
    {
      id: 2,
      type: "number",
      descricao: "Número",
    },
    {
      id: 3,
      type: "string",
      descricao: "Letras e Número",
    },
    {
      id: 4,
      type: "money",
      descricao: "Monetário",
    },
  ];

  useEffect(() => {
    buscaCodigosServicos();
    if (!id) return;
    buscaServico();
  }, []);

  const buscaServico = async () => {
    setLoading(true);
    const result = await servicoService.getById(id);
    if (!result.isAxiosError) {
      setInitialServicoState(result.data);
      setServico(result.data);
      setCodigoServico(result.data.codigoServicoLei);
      setListaCamposPersonalizados(result.data?.servicoCamposPersonalizados);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(servico);
    setUserLog(logs);
  }

  const buscaCodigosServicos = async function () {
    const result = await codigoServicoService.getAll();
    if (!result.isAxiosError) {
      setListaCodigoServico(result.data);
    } else {
      responseErros(result);
    }
  };

  const handleInputChange = (e) => {
    e.persist();
    setServico((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    e.persist();
    setServico((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const onInputChangeCamposPersonalizados = (event) => {
    setCampoPersonalizado({
      ...campoPersonalizado,
      [event.target.name]: event.target.value,
    });
  };

  const handleAdicionarCampoPersonalizado = () => {
    if (!campoPersonalizado?.label || !campoPersonalizado?.tipo) {
      return notification.erroValidacao(
        "Preencher tipo e Descrição de campo personalizado"
      );
    }
    const quantidadeCampos = listaCamposPersonalizados.length + 1;
    if (quantidadeCampos < 3) {
      setTamanhoGrid(6);
    } else if (quantidadeCampos % 3 == 0) {
      setTamanhoGrid(4);
    } else {
      setTamanhoGrid(6);
    }
    setListaCamposPersonalizados((itens) => [
      ...itens,
      {
        id: shortid.generate(),
        label: campoPersonalizado.label,
        type: campoPersonalizado.tipo.type,
      },
    ]);
    setCampoPersonalizado({});
    setOpenCampoPersonalizadoDialog(false);
  };

  const onClickDeleteCampoPersonalizado = (idCampo) => {
    const data = listaCamposPersonalizados.filter(
      (item) => item.id !== idCampo
    );
    setListaCamposPersonalizados(data);
  };

  useEffect(() => {
    setServico((prevState) => ({
      ...prevState,
      codigoServicoLeiId: codigoServico?.id,
      camposPersonalizados: listaCamposPersonalizados,
    }));
  }, [codigoServico, listaCamposPersonalizados]);

  const handleSubmit = async () => {
    setLoading(true);
    servicoValidator
      .validate(servico, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await servicoService.atualizar(id, servico);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
          } else {
            responseErros(response);
          }
        } else {
          const response = await servicoService.cadastrar(servico);
          setLoading(false);
          if (!response.isAxiosError) {
            handleCancelar();
            notification.cadastroSucesso();
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setServico(initialServicoState);
    setCodigoServico(null);
    setCampoPersonalizado({});
    setListaCamposPersonalizados([]);
  };

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <AddLayout
      title={servico?.id ? "Editar Serviço" : "Cadastrar Serviço"}
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={servico?.id ? userLog : null}
      onClickLog={onClickLog}
    >
      <Paper>
        <Tabs
          className="mt-2 mb-4"
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="DADOS" {...tabProps(0)} />
          <Tab label="DETALHES" {...tabProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={abaValue} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="codigo"
              name="codigo"
              label="Código"
              variant="outlined"
              margin="normal"
              value={servico?.codigo ? servico.codigo : ""}
              onChange={(e) => {
                setInputErro([]);
                handleInputChange(e);
              }}
              fullWidth
              required
              error={inputErros.get("codigo")}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="descricao"
              name="descricao"
              label="Descrição"
              variant="outlined"
              margin="normal"
              value={servico?.descricao ? servico.descricao : ""}
              onChange={(e) => {
                setInputErro([]);
                handleInputChange(e);
              }}
              fullWidth
              required
              error={inputErros.get("descricao")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CurrencyInput
              id="precoVenda"
              name="precoVenda"
              label="Preço de Venda"
              variant="outlined"
              margin="normal"
              fullWidth
              value={servico?.precoVenda ? servico.precoVenda : ""}
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={4}>
            <CurrencyInput
              id="valorComissaoVendedor"
              name="valorComissaoVendedor"
              label="Valor de Comissão do Vendedor"
              variant="outlined"
              margin="normal"
              fullWidth
              value={
                servico?.valorComissaoVendedor
                  ? servico.valorComissaoVendedor
                  : ""
              }
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFielDecimalNumbers
              id="percentualComissaoVendedor"
              name="percentualComissaoVendedor"
              label="Percentual de Comissão do Vendedor"
              variant="outlined"
              margin="normal"
              fullWidth
              value={
                servico?.percentualComissaoVendedor
                  ? servico.percentualComissaoVendedor
                  : ""
              }
              onBlur={handleInputBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                required
                id="codigoServico"
                name="codigoServico"
                options={listaCodigoServico}
                autoHighlight
                getOptionLabel={(option) =>
                  option?.codigo && option?.descricao
                    ? `${option.codigo} - ${option.descricao}`
                    : ""
                }
                getOptionSelected={(option, value) =>
                  option?.descricao === value?.descricao
                }
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Código de Serviço Lei nº 116/03"
                    variant="outlined"
                  />
                )}
                onChange={(e, newValue) => {
                  e.persist();
                  setCodigoServico(newValue);
                }}
                value={codigoServico}
              />
            </FormControl>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={abaValue} index={1}>
        <CamposPersonalizados
          listaCamposPersonalizados={listaCamposPersonalizados}
          setListaCamposPersonalizados={setListaCamposPersonalizados}
          setOpenCampoPersonalizadoDialog={setOpenCampoPersonalizadoDialog}
          onClickDeleteCampoPersonalizado={onClickDeleteCampoPersonalizado}
          tamanhoGrid={tamanhoGrid}
        />
      </TabPanel>
      <CampoPersonalizadoDialog
        openCampoPersonalizadoDialog={openCampoPersonalizadoDialog}
        setOpenCampoPersonalizadoDialog={setOpenCampoPersonalizadoDialog}
        onInputChangeCamposPersonalizados={onInputChangeCamposPersonalizados}
        handleAdicionarCampoPersonalizado={handleAdicionarCampoPersonalizado}
        campoPersonalizado={campoPersonalizado}
        setCampoPersonalizado={setCampoPersonalizado}
        tiposCampo={tiposCampo}
      />
    </AddLayout>
  );
};

export default AddServico;
