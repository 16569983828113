import { FormControl as FormControlMui } from "@mui/material";

const FormControl = ({
  className,
  children,
  classes,
  color,
  component,
  disabled,
  error,
  focused,
  fullWidth,
  hiddenLabel,
  margin,
  required,
  size,
  sx,
  variant,
}) => {
  return (
    <FormControlMui
      className={className}
      classes={classes}
      color={color}
      component={component}
      disabled={disabled ?? false}
      error={error ?? false}
      focused={focused ?? false}
      fullWidth={fullWidth ?? false}
      hiddenLabel={hiddenLabel ?? false}
      margin={margin ?? "none"}
      required={required}
      size={size}
      sx={sx}
      variant={variant}
    >
      {children}
    </FormControlMui>
  );
};

export default FormControl;
