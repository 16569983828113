import { Grid, Select, MenuItem, TextField } from "../../../../components";

const FormFiltroAvancado = ({ valueInput, onChangeInput }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="nome"
          name="nome"
          label="Nome"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("nome")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Tipo de desconto"
          id="aplicarDescontoEm"
          name="aplicarDescontoEm"
          value={valueInput("aplicarDescontoEm")}
          fullWidth
          onChange={(event) => onChangeInput(event)}
        >
          <MenuItem value={"Valor"}>Valor</MenuItem>
          <MenuItem value={"Percentual"}>Percentual</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="descontoTabela"
          name="descontoTabela"
          label="Desconto"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("descontoTabela")}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
