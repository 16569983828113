import React from "react";
import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormGroup,
  TextFielDecimalNumbers,
  CircularProgress,
} from "../../../../components";
import { constantes } from "../resources";
import { value } from "../../../../helpers";

export default function ModeloTributacaoIcms({
  icmsId,
  cfopRef,
  grupoModeloTributacao,
  onChangeAutocomplete,
  cfopList,
  csoSimplesList,
  tipoDesoneracaoList,
  onSearchChangeCfop,
  inputErros,
  handleInputChange,
  handleCheckBox,
  handleCheckBoxDesonerado,
  loadingAutoComplete,
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8} className="mt-3">
          <Select
            label="CSO - SIMPLES NACIONAL"
            id="csoSimplesNacional"
            name="csoSimplesNacional"
            required
            disabled={!grupoModeloTributacao?.grupoTributarioId ? true : false}
            variant={
              !grupoModeloTributacao?.grupoTributarioId ? "filled" : "outlined"
            }
            error={inputErros.get("csoSimplesNacional")}
            value={grupoModeloTributacao?.csoSimplesNacional}
            onChange={handleInputChange}
          >
            {csoSimplesList.map((cso) => (
              <MenuItem key={cso.codigo} value={cso.codigo}>
                {cso.codigo} - {cso.descricao}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="cfopId"
              name="cfopId"
              options={cfopList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option ? `${option?.descricao}` : ""
              }
              disabled={
                !grupoModeloTributacao?.grupoTributarioId ? true : false
              }
              filterSelectedOptions
              filterOptions={(options, state) =>
                options.filter(
                  (item) =>
                    item.codigo.includes(state.inputValue) ||
                    item.descricao.includes(state.inputValue.toUpperCase())
                )
              }
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"CFOP"}
                  required
                  error={inputErros.get("cfopId")}
                  variant={
                    !grupoModeloTributacao?.grupoTributarioId
                      ? "filled"
                      : "outlined"
                  }
                  onKeyDown={(e) => onSearchChangeCfop(e, "cfopId")}
                  inputRef={cfopRef}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <React.Fragment>
                          {loadingAutoComplete ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      </>
                    ),
                  }}
                />
              )}
              onChange={(__, newValue) => {
                onChangeAutocomplete("cfopId", newValue);
              }}
              value={value.autoComplete(
                cfopList,
                grupoModeloTributacao?.cfopId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.calculaIcms ?? false}
                  disabled={
                    !grupoModeloTributacao?.grupoTributarioId ? true : false
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.calculaIcms}
                  name="calculaIcms"
                />
              }
              label="Calcula ICMS"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.calculaFcp ?? false}
                  disabled={
                    !grupoModeloTributacao?.grupoTributarioId ? true : false
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.calculaFcp}
                  name="calculaFcp"
                />
              }
              label="Calcula FCP"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          <TextFielDecimalNumbers
            id="aliquotaFcp"
            name="aliquotaFcp"
            label="Alíquota FCP"
            disabled={
              !grupoModeloTributacao?.calculaFcp ||
              !grupoModeloTributacao?.grupoTributarioId
                ? true
                : false
            }
            variant={
              !grupoModeloTributacao?.calculaFcp ||
              !grupoModeloTributacao?.grupoTributarioId
                ? "filled"
                : "outlined"
            }
            margin="normal"
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaFcp ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFielDecimalNumbers
            id="aliquotaSimples"
            name="aliquotaSimples"
            label="Alíquota Simples"
            disabled={!grupoModeloTributacao?.grupoTributarioId ? true : false}
            variant={
              !grupoModeloTributacao?.grupoTributarioId ? "filled" : "outlined"
            }
            margin="normal"
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaSimples ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="reducaoBase"
            name="reducaoBase"
            label="Redução da Base"
            disabled={
              !grupoModeloTributacao?.calculaIcms ||
              !grupoModeloTributacao?.grupoTributarioId
                ? true
                : false
            }
            variant={
              !grupoModeloTributacao?.calculaIcms ||
              !grupoModeloTributacao?.grupoTributarioId
                ? "filled"
                : "outlined"
            }
            margin="normal"
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.reducaoBase ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="aliquotaIcms"
            name="aliquotaIcms"
            label="Alíquota ICMS"
            variant={
              !grupoModeloTributacao?.calculaIcms ||
              !grupoModeloTributacao?.grupoTributarioId
                ? "filled"
                : "outlined"
            }
            disabled={
              !grupoModeloTributacao?.calculaIcms ||
              !grupoModeloTributacao?.grupoTributarioId
                ? true
                : false
            }
            margin="normal"
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaIcms ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="aliquotaIcmsCredito"
            name="aliquotaIcmsCredito"
            label="Alíquota ICMS Crédito"
            disabled={!grupoModeloTributacao?.grupoTributarioId ? true : false}
            variant={
              !grupoModeloTributacao?.grupoTributarioId ? "filled" : "outlined"
            }
            margin="normal"
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaIcmsCredito ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="aliquotaIcmsDiferido"
            name="aliquotaIcmsDiferido"
            label="Alíquota ICMS Diferido"
            disabled={
              !grupoModeloTributacao?.calculaIcms ||
              !grupoModeloTributacao?.grupoTributarioId
                ? true
                : false
            }
            variant={
              !grupoModeloTributacao?.calculaIcms ||
              !grupoModeloTributacao?.grupoTributarioId
                ? "filled"
                : "outlined"
            }
            margin="normal"
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaIcmsDiferido ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-3">
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.ipiNaBase ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcms ||
                    !grupoModeloTributacao?.grupoTributarioId
                      ? true
                      : false
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.ipiNaBase}
                  name="ipiNaBase"
                />
              }
              label="IPI na base"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.freteNaBase ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcms ||
                    !grupoModeloTributacao?.grupoTributarioId
                      ? true
                      : false
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.freteNaBase}
                  name="freteNaBase"
                />
              }
              label="Frete na base"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.seguroNaBase ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcms ||
                    !grupoModeloTributacao?.grupoTributarioId
                      ? true
                      : false
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.seguroNaBase}
                  name="seguroNaBase"
                />
              }
              label="Seguro na base"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.despesaNaBase ?? false}
                  onClick={handleCheckBox}
                  disabled={
                    !grupoModeloTributacao?.calculaIcms ||
                    !grupoModeloTributacao?.grupoTributarioId
                      ? true
                      : false
                  }
                  color="primary"
                  value={grupoModeloTributacao?.despesaNaBase}
                  name="despesaNaBase"
                />
              }
              label="Despesa na base"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.descontoNaBase ?? false}
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.descontoNaBase}
                  disabled={
                    !grupoModeloTributacao?.calculaIcms ||
                    !grupoModeloTributacao?.grupoTributarioId
                      ? true
                      : false
                  }
                  name="descontoNaBase"
                />
              }
              label="Desconto na base"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.acrescimoNaBase ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcms ||
                    !grupoModeloTributacao?.grupoTributarioId
                      ? true
                      : false
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.acrescimoNaBase}
                  name="acrescimoNaBase"
                />
              }
              label="Acréscimo na base"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-4">
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.calculaIcmsSt ?? false}
                  disabled={
                    icmsId || !grupoModeloTributacao?.grupoTributarioId
                      ? true
                      : false
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.calculaIcmsSt}
                  name="calculaIcmsSt"
                />
              }
              label="Calcula Substituição"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4} className="mt-3">
          <Select
            label="Modalidade Base de Cálculo"
            id="modalidadeBaseCalculo"
            name="modalidadeBaseCalculo"
            variant={
              (grupoModeloTributacao?.calculaIcmsSt && !icmsId) ||
              grupoModeloTributacao?.grupoTributarioId
                ? "outlined"
                : "filled"
            }
            disabled={
              !grupoModeloTributacao?.calculaIcmsSt ||
              !grupoModeloTributacao?.grupoTributarioId ||
              icmsId
                ? true
                : false
            }
            value={grupoModeloTributacao?.modalidadeBaseCalculo}
            onChange={handleInputChange}
          >
            {constantes.modalidadeBaseCalculoList.map((cso) => (
              <MenuItem key={cso.value} value={cso.value}>
                {cso.value}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.desonerado ?? false}
                  onClick={handleCheckBoxDesonerado}
                  disabled={
                    !grupoModeloTributacao?.grupoTributarioId ? true : false
                  }
                  color="primary"
                  value={grupoModeloTributacao?.desonerado}
                  name="desonerado"
                />
              }
              label="Desonerado"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={4} className="mt-3">
          <Select
            id="motivoDesoneracao"
            name="motivoDesoneracao"
            variant={
              grupoModeloTributacao?.desonerado ||
              grupoModeloTributacao?.grupoTributarioId
                ? "outlined"
                : "filled"
            }
            disabled={
              !grupoModeloTributacao?.desonerado ||
              !grupoModeloTributacao?.grupoTributarioId
                ? true
                : false
            }
            value={grupoModeloTributacao?.motivoDesoneracao}
            onChange={handleInputChange}
          >
            {tipoDesoneracaoList.map((cso) => (
              <MenuItem key={cso.codigo} value={cso.codigo}>
                {cso.descricao}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="reducaoBaseSt"
            name="reducaoBaseSt"
            label="Redução da Base ST"
            margin="normal"
            variant={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? "outlined"
                : "filled"
            }
            disabled={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? false
                : true
            }
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.reducaoBaseSt ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFielDecimalNumbers
            id="aliquotaIcmsDebito"
            name="aliquotaIcmsDebito"
            label="Alíquota ICMS Débito"
            margin="normal"
            variant={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? "outlined"
                : "filled"
            }
            disabled={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? false
                : true
            }
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaIcmsDebito ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFielDecimalNumbers
            id="aliquotaMva"
            name="aliquotaMva"
            label="Alíquota MVA"
            margin="normal"
            variant={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? "outlined"
                : "filled"
            }
            disabled={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? false
                : true
            }
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaMva ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFielDecimalNumbers
            id="aliquotaSt"
            name="aliquotaSt"
            label="Alíquota ST"
            margin="normal"
            variant={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? "outlined"
                : "filled"
            }
            disabled={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[0].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? false
                : true
            }
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.aliquotaSt ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="valorDePauta"
            name="valorDePauta"
            label="Valor de Pauta"
            margin="normal"
            variant={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[1].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? "outlined"
                : "filled"
            }
            disabled={
              grupoModeloTributacao?.modalidadeBaseCalculo ==
                constantes.modalidadeBaseCalculoList[1].label ||
              grupoModeloTributacao?.grupoTributarioId
                ? false
                : true
            }
            onBlur={handleInputChange}
            value={grupoModeloTributacao?.valorDePauta ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.ipiNaBaseSt ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcmsSt ||
                    !grupoModeloTributacao?.grupoTributarioId
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.ipiNaBaseSt}
                  name="ipiNaBaseSt"
                />
              }
              label="IPI na base ST"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.freteNaBaseSt ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcmsSt ||
                    !grupoModeloTributacao?.grupoTributarioId
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.freteNaBaseSt}
                  name="freteNaBaseSt"
                />
              }
              label="Frete na Base ST"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.seguroNaBaseSt ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcmsSt ||
                    !grupoModeloTributacao?.grupoTributarioId
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.seguroNaBaseSt}
                  name="seguroNaBaseSt"
                />
              }
              label="Seguro na base ST"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.despesaNaBaseSt ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcmsSt ||
                    !grupoModeloTributacao?.grupoTributarioId
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.despesaNaBaseSt}
                  name="despesaNaBaseSt"
                />
              }
              label="Despesa na base ST"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.descontoNaBaseSt ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcmsSt ||
                    !grupoModeloTributacao?.grupoTributarioId
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.descontoNaBaseSt}
                  name="descontoNaBaseSt"
                />
              }
              label="Desconto na base ST"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={grupoModeloTributacao?.acrescimoNaBaseSt ?? false}
                  disabled={
                    !grupoModeloTributacao?.calculaIcmsSt ||
                    !grupoModeloTributacao?.grupoTributarioId
                  }
                  onClick={handleCheckBox}
                  color="primary"
                  value={grupoModeloTributacao?.acrescimoNaBaseSt}
                  name="acrescimoNaBaseSt"
                />
              }
              label="Acréscimo na base ST"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12}>
          <TextField
            id="infoComplementar"
            name="infoComplementar"
            label="Informação Complementar"
            variant={
              !grupoModeloTributacao?.grupoTributarioId ? "filled" : "outlined"
            }
            disabled={!grupoModeloTributacao?.grupoTributarioId ? true : false}
            margin="normal"
            onChange={handleInputChange}
            value={grupoModeloTributacao?.infoComplementar ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
