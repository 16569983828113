import * as Yup from "yup";

const usuarioUpdateValidator = Yup.object()
  .shape({
    ativado: Yup.boolean().nullable(),
    padrao: Yup.boolean().nullable(),
    nome: Yup.string().required("Nome é obrigatório"),
    email: Yup.string()
      .email("E-mail inválido")
      .required("E-mail é obrigatório"),
    pctPerfilUsuarioId: Yup.number().required("Perfil é obrigatório"),
  })
  .test(
    "disableUserDefault",
    "Não é possível desativar usuário principal",
    (value) => !(value.padrao && value.ativado === false)
  );

export default usuarioUpdateValidator;
