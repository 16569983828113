import { notification } from "../services";

const setInputErros = (setInputErro, err) => {
  if (err) {
    setInputErro(err.inner.map((item) => item.path));
    err.errors && notification.erroValidacao(err.errors[0]);
  } else {
    setInputErro([]);
  }
};

export default setInputErros;
