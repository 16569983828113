import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
} from "../../../../components";
import {
  OperacaoFiscalService,
  PessoaService,
  MeioPagamentoService,
} from "../../../../services";
import { InputErros } from "../../../../helpers";
import {
  formatDate,
  responseErros,
  timeoutBuscaAutocomplete,
} from "../../../../utils";
import { format } from "date-fns";

const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const meioPagamentoService = new MeioPagamentoService();

const FormFiltroAvancadoNfEntrada = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRef = useRef();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [fornecedorList, setFornecedorList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.id]: event.target.value || null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value || null,
    }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.nomeRazaoSocial ?? value.descricao : undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("De", "Até")] = format(date, "dd/MM/yyyy");
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Até", "De")] = format(date, "dd/MM/yyyy");
      }
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  useEffect(() => {
    buscarOperacoesFiscais();
    buscarMeiosPagamento();
  }, []);

  const buscarOperacoesFiscais = () => {
    const filtrosOperacoesFiscais = {
      ativado: true,
      tipoOperacao: "ENTRADA",
      restritiva: true,
      porIntervalo: [],
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
    };
    operacaoFiscalService
      .getAllFiltroAvancado(filtrosOperacoesFiscais)
      .then((result) => {
        if (!result.isAxiosError) {
          setOperacaoFiscalList(result.data.rows);
        } else {
          responseErros(result);
        }
      });
  };

  const buscarMeiosPagamento = () => {
    meioPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarFornecedores = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 2,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setFornecedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  const onInputChangeFornecedor = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      if (event.target?.value?.length >= 2) {
        const filtros = {
          nomeRazaoSocial: event.target.value,
          apelidoFantasia: event.target.value,
        };
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(buscarFornecedores(filtros));
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="numeroNota"
            name="Número-nota"
            type="number"
            label="Nº Nota"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("numeroNota")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="serie"
            name="Série"
            type="number"
            label="Série"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChangeInput}
            value={valueInput("serie")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="fornecedorId"
              name="Fornecedor"
              options={fornecedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fornecedor"
                  variant="outlined"
                  onKeyDown={onInputChangeFornecedor}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("fornecedorId", value, "Fornecedor")
              }
              value={valueAutocomplete(fornecedorList, "fornecedorId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="operacaoFiscalId"
              name="Operação-Fiscal"
              options={operacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operação Fiscal"
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete(
                  "operacaoFiscalId",
                  newValue,
                  "Operação-Fiscal"
                );
              }}
              value={valueAutocomplete(operacaoFiscalList, "operacaoFiscalId")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamentoId"
              name="Meio-de-Pagamento"
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meios de pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "meioPagamentoId",
                  value,
                  "Meio-de-Pagamento"
                )
              }
              value={valueAutocomplete(meioPagamentoList, "meioPagamentoId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStart"
            name="Data-Emissão-De"
            label="Data Emissão De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoStart")}
            onChange={(date) =>
              onChangeDate("dataEmissaoStart", date, "Data-Emissão-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEnd"
            name="Data-Emissão-Até"
            label="Data Emissão Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoEnd")}
            onChange={(date) =>
              onChangeDate("dataEmissaoEnd", date, "Data-Emissão-Até")
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataChegadaStart"
            name="Data-Chegada-De"
            label="Data Chegada De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataChegadaStart")}
            onChange={(date) =>
              onChangeDate("dataChegadaStart", date, "Data-Chegada-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataChegadaEnd"
            name="Data-Chegada-Até"
            label="Data Chegada Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataChegadaEnd")}
            onChange={(date) =>
              onChangeDate("dataChegadaEnd", date, "Data-Chegada-Até")
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoNfEntrada;
