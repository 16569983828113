import * as Yup from "yup";

const grupoPessoasValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  tipoPessoa: Yup.number().required("Tipo pessoa"),
  ativado: Yup.boolean().required(),
  tabelaPrecoId: Yup.number().nullable(),
});

export default grupoPessoasValidator;
