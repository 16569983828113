import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Autocomplete,
} from "../../../../components";
import { EnderecoService, GrupoPessoasService } from "../../../../services";
import { value } from "../../../../helpers";
import { cacheApi, responseErros } from "../../../../utils";
import { filtrosUrl } from "../../../../helpers";

export default function FormTransportadora({
  transportadoraFormRef,
  transportadora,
  setTransportadora,
  habilitado,
  inputErros,
  cacheDto,
  setCacheDto,
}) {
  const [listaGrupoPessoa, setListaGrupoPessoa] = useState([]);
  const [listaEstados, setListaEstados] = useState([]);
  const grupoPessoasService = new GrupoPessoasService();
  const enderecoService = new EnderecoService();

  useEffect(() => {
    buscaGruposPessoas();
    buscaEstados();
  }, []);

  const buscaGruposPessoas = async () => {
    const filtros = {
      ativado: true,
      tipoPessoa: 3,
      restritiva: true,
    };
    const label = filtrosUrl.getUrl(filtros, grupoPessoasService.urlBase);
    const cacheBody = {
      apiFunction: grupoPessoasService.getAllFiltroAvancado(filtros),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(label, cacheBody);
    if (!res.isAxiosError) {
      setListaGrupoPessoa(res.data.rows);
    } else {
      responseErros(res);
    }
  };

  const buscaEstados = async () => {
    const cacheBody = {
      timeMinutes: 60,
      apiFunction: enderecoService.getEstados(),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(`${enderecoService.urlBase}/estados`, cacheBody);
    if (!res.isAxiosError) {
      setListaEstados(res.data);
    } else {
      responseErros(res);
    }
  };

  const handleChangeInput = (event) => {
    setTransportadora({
      ...transportadora,
      [event.target.name]: event.target.value,
    });
  };

  const handleUfList = (name, value) => {
    const transportadoraTemp = {
      ...transportadora,
      [name]: value ? value.id : null,
    };
    setTransportadora(transportadoraTemp);
  };

  return (
    <form ref={transportadoraFormRef}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControl margin="normal" fullWidth>
            <Autocomplete
              id="ufEmbarque"
              name="ufEmbarque"
              options={listaEstados}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.sigla ? option.sigla : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="UF"
                  variant={"outlined"}
                  value={
                    transportadora?.ufEmbarque ? transportadora.ufEmbarque : ""
                  }
                  error={inputErros.get("ufEmbarque")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                handleUfList("ufEmbarque", newValue);
              }}
              value={value.autoComplete(
                listaEstados,
                transportadora?.ufEmbarque
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Local Embarque"
            name="localEmbarque"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            margin="normal"
            fullWidth
            value={
              transportadora.localEmbarque ? transportadora.localEmbarque : ""
            }
            onChange={handleChangeInput}
            error={inputErros.get("localEmbarque")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="RNTC"
            name="rntc"
            type="number"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            margin="normal"
            fullWidth
            value={transportadora.rntc ? transportadora.rntc : ""}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="grupoPessoaId"
              name="grupoPessoaId"
              options={listaGrupoPessoa}
              noOptionsText="Sem opções"
              autoHighlight
              disabled={habilitado ? false : true}
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              filterSelectedOptions
              onChange={(_, value) => {
                handleUfList("grupoPessoaId", value);
              }}
              value={value.autoComplete(
                listaGrupoPessoa,
                transportadora?.grupoPessoaId
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={habilitado ? "outlined" : "filled"}
                  label="Grupos de Pessoas"
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
