import { CabecalhoMainPrint, Grid } from "../../../../components";
import { formatPrice } from "../../../../utils";
import "./table-print.css";

const PrintEstoqueFinanceiro = ({
  tituloRelatorio,
  relatoriosTotais,
  filtrosAplicadosImpressao,
  print,
}) => {
  return (
    <div className={!print ? "report-container" : "print-container"}>
      <CabecalhoMainPrint
        data={{
          tituloRelatorio,
          filtrosAplicadosImpressao,
          print,
        }}
      />
      <Grid container spacing={2} sx={{ fontSize: "13px", p: "3em" }}>
        <Grid item xs={12}>
          <table className="table table-borderless">
            <thead className="table-primary">
              <tr>
                <th width="34%" className="text-center">
                  Total Produtos
                </th>
                <th className="text-center">Total Produtos Saldo Positivo</th>
                <th width="33%" className="text-center">
                  Total Estoque
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {relatoriosTotais.totalProdutos}
                </td>
                <td className="text-center">
                  {relatoriosTotais.totalProdutosEstoquePositivo}
                </td>
                <td className="text-center">{relatoriosTotais.totalEstoque}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <table className="table table-borderless">
            <thead className="table-primary">
              <tr>
                <th className="text-center">Estoque x Preço Compra</th>
                <th className="text-center">Estoque x Preco Custo</th>
                <th className="text-center">Estoque x Preco Venda</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {formatPrice(relatoriosTotais.totalCompra, false)}
                </td>
                <td className="text-center">
                  {formatPrice(relatoriosTotais.totalCusto, false)}
                </td>
                <td className="text-center">
                  {formatPrice(relatoriosTotais.totalVenda, false)}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrintEstoqueFinanceiro;
