import { Grow as GrowMui } from "@mui/material";

const Grow = ({
  children,
  className,
  addEndListener,
  appear,
  easing,
  timeout,
  style,
  scr,
  ...rest
}) => {
  return (
    <GrowMui
      className={className}
      addEndListener={addEndListener}
      timeout={timeout}
      appear={appear}
      easing={easing}
      style={style}
      scr={scr}
      {...rest}
    >
      <div>{children}</div>
    </GrowMui>
  );
};

export default Grow;
