import { useEffect, useState } from "react";
import { Grid, DataGrid } from "../../../../components";
import {
  ConversaoEntradaDialog,
  EditarProdutoDialog,
  VincularProdutoDialog,
} from "../dialogs";
import {
  GrupoProdutosService,
  GruposTributarioService,
  UnidadeComercialService,
} from "../../../../services";
import { responseErros } from "../../../../utils";
import { value } from "../../../../helpers";
import {
  ActionsProdutosGridXml,
  GrupoProdutoTributarioXml,
} from "./components-steps";
import { erroCelulaGridProdutos } from "../../importar-xml";

const gruposTributarioService = new GruposTributarioService();
const grupoProdutosService = new GrupoProdutosService();
const unidadeComercialService = new UnidadeComercialService();

const ProdutosXmlStep = ({ produtos, setProdutos, inputErros }) => {
  const [openConverterEntradaDialog, setOpenConverterEntradaDialog] =
    useState(false);
  const [openVincularProdutoDialog, setOpenVincularProdutoDialog] =
    useState(false);
  const [openEditarProdutoDialog, setOpenEditarProdutoDialog] = useState(false);
  const [gruposTributarios, setGruposTributarios] = useState([]);
  const [gruposProdutos, setGruposProdutos] = useState([]);
  const [listaUnidadesComerciais, setListaUnidadesComerciais] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [produtosConvertidos, setProdutosConvertidos] = useState([]);
  const colunasProdutos = [
    {
      field: "produto",
      headerName: "Produto",
      sortable: false,
      flex: 350,
    },
    {
      field: "ncm",
      headerName: "NCM",
      sortable: false,
      flex: 100,
      valueGetter: (params) => params.value || params.row?.ncmCodigo,
      cellClassName: (params) => erroCelulaGridProdutos(params, inputErros),
    },
    {
      field: "cfop",
      headerName: "CFOP",
      sortable: false,
      flex: 90,
      cellClassName: (params) => erroCelulaGridProdutos(params, inputErros),
    },
    {
      field: "grupoProdutoId",
      headerName: "Grupo Produto",
      sortable: false,
      flex: 200,
      valueGetter: (params) =>
        value.autoComplete(gruposProdutos, params.value)?.descricao,
      cellClassName: (params) => erroCelulaGridProdutos(params, inputErros),
    },
    {
      field: "grupoTributacaoId",
      headerName: "Grupo Tributário",
      sortable: false,
      flex: 250,
      valueGetter: (params) =>
        value.autoComplete(gruposTributarios, params.value)?.nome,
      cellClassName: (params) => erroCelulaGridProdutos(params, inputErros),
    },
    {
      field: "unidadeComercialVendaId",
      headerName: "Unid. Venda",
      sortable: false,
      flex: 185,
      valueGetter: (params) =>
        value.autoComplete(listaUnidadesComerciais, params.value)?.descricao,
    },
    {
      field: "acoes",
      sortable: false,
      flex: 150,
      headerAlign: "center",
      disableClickEventBubbling: true,
      renderHeader: () => {
        return (
          <div>
            <i className="ph-fill ph-note-pencil" style={{ fontSize: 20 }}></i>
          </div>
        );
      },
      renderCell: (params) => (
        <ActionsProdutosGridXml
          params={params}
          handleOpenActions={handleOpenActions}
        />
      ),
    },
  ];

  const handleOpenActions = (produto, tipo, produtoIndice) => {
    setProdutoSelecionado(produto);
    if (tipo === "CONVERTER") {
      if (produtoIndice !== null && produto?.produtoConvertido) {
        desfazerConversao(produtoIndice);
      }
      setOpenConverterEntradaDialog(true);
    }
    if (tipo === "VINCULAR") {
      setOpenVincularProdutoDialog(true);
    }
    if (tipo === "EDITAR") {
      setOpenEditarProdutoDialog(true);
    }
  };

  const desfazerConversao = (produtoIndice) => {
    const produtoConvertido = produtosConvertidos.find(
      (produto) => produto.produtoIndice === produtoIndice
    );
    if (produtoConvertido) {
      const produtosXmlTemp = produtos.map((item, _index) => {
        if (_index === produtoIndice) {
          return produtoConvertido;
        } else {
          return item;
        }
      });
      setProdutos(produtosXmlTemp);
      setProdutosConvertidos(
        produtosConvertidos.filter(
          (produto) => produto.id !== produtoConvertido.id
        )
      );
    }
  };

  useEffect(() => {
    buscarGruposTributario();
    buscarGruposProdutos();
    buscarUnidadesComerciais();
  }, []);

  const buscarGruposTributario = () => {
    gruposTributarioService
      .getAll({
        ativado: true,
        nonPaginated: true,
      })
      .then((result) => {
        if (!result.isAxiosError) {
          if (result.data) {
            setGruposTributarios(result.data);
          }
        } else {
          responseErros(result);
        }
      });
  };

  const buscarGruposProdutos = () => {
    grupoProdutosService
      .getAll({
        ativado: true,
        nonPaginated: true,
      })
      .then((result) => {
        if (!result.isAxiosError) {
          setGruposProdutos(result.data);
        } else {
          responseErros(result);
        }
      });
  };

  const buscarUnidadesComerciais = () => {
    unidadeComercialService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setListaUnidadesComerciais(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  return (
    <>
      <GrupoProdutoTributarioXml
        gruposProdutos={gruposProdutos}
        gruposTributarios={gruposTributarios}
        setProdutos={setProdutos}
        inputErros={inputErros}
      />
      <Grid container spacing={2} sx={{ pl: 2, mt: 3 }}>
        <DataGrid
          rows={produtos || []}
          columns={colunasProdutos}
          rowCount={produtos?.length || 0}
          disableAcoes
        />
      </Grid>
      <ConversaoEntradaDialog
        produtos={produtos}
        setProdutos={setProdutos}
        produtoSelecionado={produtoSelecionado}
        openConverterEntradaDialog={openConverterEntradaDialog}
        setOpenConverterEntradaDialog={setOpenConverterEntradaDialog}
        setProdutosConvertidos={setProdutosConvertidos}
      />
      <VincularProdutoDialog
        produtos={produtos}
        setProdutos={setProdutos}
        produtoSelecionado={produtoSelecionado}
        openVincularProdutoDialog={openVincularProdutoDialog}
        setOpenVincularProdutoDialog={setOpenVincularProdutoDialog}
      />
      <EditarProdutoDialog
        produtos={produtos}
        setProdutos={setProdutos}
        produtoSelecionado={produtoSelecionado}
        setProdutoSelecionado={setProdutoSelecionado}
        gruposProdutos={gruposProdutos}
        gruposTributarios={gruposTributarios}
        listaUnidadesComerciais={listaUnidadesComerciais}
        openEditarProdutoDialog={openEditarProdutoDialog}
        setOpenEditarProdutoDialog={setOpenEditarProdutoDialog}
        inputErros={inputErros}
      />
    </>
  );
};

export default ProdutosXmlStep;
