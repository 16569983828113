import SiafLiteApiRepository from "../../external/siafLiteApi";

class ContasReceberPagamentosService {
  constructor() {
    this.urlBase = "/financeiro/contas-receber-pagamentos";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  removerPagamentos(id, body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/remover-pagamentos-contas-receber`
    ).post(body);
  }
}

export default ContasReceberPagamentosService;
