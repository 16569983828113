import * as Yup from "yup";

const condicoesPagamentoValidator = Yup.object().shape({
  descricao: Yup.string().required("Descrição"),
  juros: Yup.string().nullable(),
  periodo: Yup.string().nullable(),
  carencia: Yup.string().nullable(),
  parcelas: Yup.string().required("Parcelas"),
  entrada: Yup.boolean().nullable(),
  ativado: Yup.boolean().nullable(),
  meiosPagamentos: Yup.array().test(
    "is-not-empty",
    "Vincular meio de pagamento",
    (value) => value?.length > 0
  ),
});

export default condicoesPagamentoValidator;
