import * as Yup from "yup";

const nfEntradaItemXmlObjectValidator = Yup.object().shape({
  tipo: Yup.string().required(),
  codigoBarra: Yup.string().nullable(),
  descricao: Yup.string().required("Descrição"),
  produto: Yup.string().required("Descrição"),
  unidadeComercialCompraId: Yup.number().nullable(),
  unidadeComercialVendaId: Yup.number().required("Unidade Comercial de Venda"),
  conversorEntrada: Yup.number().nullable(),
  fracionavel: Yup.string().nullable(),
  precoCompra: Yup.number().nullable(),
  grupoProdutoId: Yup.number().required("Grupo de Produto"),
  grupoTributacaoId: Yup.number().required("Grupo tributário"),
  generoItemId: Yup.number().nullable(),
  tipoItemId: Yup.number().nullable(),
  origemMercadoriaId: Yup.number().required("Origem Mercadoria Id"),
  tributosIcmsOrigem: Yup.number().required("Tributos ICMS Origem"),
  ncmId: Yup.number().required("NCM ID"),
  ncmCodigo: Yup.string()
    .min(8, "NCM deve ter pelo menos 8 caracteres")
    .required("NCM Código"),
  ncm: Yup.string()
    .min(8, "NCM deve ter pelo menos 8 caracteres")
    .required("NCM Código"),
  cestId: Yup.number().nullable(),
  cfopId: Yup.number().nullable().required("CFOP"),
  cfop: Yup.string()
    .min(4, "CFOP deve ter pelo menos 4 caracteres")
    .nullable()
    .required("CFOP"),
  informacaoAdicionalNfe: Yup.string().nullable(),
});

const nfEntradaItensXmlValidator = Yup.array()
  .of(nfEntradaItemXmlObjectValidator)
  .min(1, "Adicione ao menos um item")
  .required("Adicione ao menos um item");

export default nfEntradaItensXmlValidator;
