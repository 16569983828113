import { TablePrintResumidoComponent } from "../../../../components";
import { formatPrice } from "../../../../utils";

const PrintComissaoVendedores = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Vendedor",
      field: "nome",
    },
    {
      header: "Total vendas a Prazo",
      field: "totalNotaPrazo",
      fieldTotal: "totalNotasPrazo",
      render: ({ totalNotaPrazo }) => formatPrice(totalNotaPrazo) || "R$0,00",
    },
    {
      header: "Total comissão a Prazo",
      field: "totalNotaPrazoComissao",
      fieldTotal: "totalComissaoPrazo",
      render: ({ totalNotaPrazoComissao }) =>
        formatPrice(totalNotaPrazoComissao) || "R$0,00",
    },
    {
      header: "Total vendas a Vista",
      field: "totalNotaVista",
      fieldTotal: "totalNotasVista",
      render: ({ totalNotaVista }) => formatPrice(totalNotaVista) || "R$0,00",
    },
    {
      header: "Total comissão a Vista",
      field: "totalNotaVistaComissao",
      fieldTotal: "totalComissaoVista",
      render: ({ totalNotaVistaComissao }) =>
        formatPrice(totalNotaVistaComissao) || "R$0,00",
    },
  ];
  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.comissoes}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={30}
    />
  );
};

export default PrintComissaoVendedores;
