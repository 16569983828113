import { useEffect, useRef, useState } from "react";
import { Minimal } from "../../../layouts";
import {
  Autocomplete,
  Button,
  DatePicker,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "../../../components";
import { InputErros, value } from "../../../helpers";
import { PessoaService, notification } from "../../../services";
import { formatDate, responseErros } from "../../../utils";

const ContadorContasReceberView = () => {
  const inputCliente = useRef();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [relatorioContadorContaReceber, setRelatorioContadorContaReceber] =
    useState({});
  const [listaClientes, setListaClientes] = useState([]);
  const [pagadorList, setPagadorList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const pessoaService = new PessoaService();

  const listaStatus = [
    {
      label: "Aberto",
      value: "ABERTO",
    },
    {
      label: "Baixado Parcial",
      value: "BAIXADO_PARCIAL",
    },
    {
      label: "Baixado",
      value: "BAIXADO",
    },
  ];
  const listaTipos = [
    {
      label: "Título Normal",
      value: "TITULO_NORMAL",
    },
    {
      label: "Nota de Crédito",
      value: "NOTA_CREDITO",
    },
  ];

  const onChangeAutocomplete = (name, value) => {
    setRelatorioContadorContaReceber({
      ...relatorioContadorContaReceber,
      [name]: value ? value.id : undefined,
    });
  };

  const onChangeSelect = (event) => {
    setRelatorioContadorContaReceber({
      ...relatorioContadorContaReceber,
      [event.target.name]: event.target.value ?? null,
    });
  };

  const onChangeDate = (date, name) => {
    if (!date) {
      date = "Invalid Date";
    }
    setRelatorioContadorContaReceber({
      ...relatorioContadorContaReceber,
      [name]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    });
  };

  const valueInput = (valueName) => {
    return relatorioContadorContaReceber[valueName]
      ? relatorioContadorContaReceber[valueName]
      : "";
  };

  const valueDate = (valueName) => {
    return relatorioContadorContaReceber[valueName]
      ? relatorioContadorContaReceber[valueName]
      : null;
  };

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === relatorioContadorContaReceber[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const onSearchChangePessoas = (event) => {
    if (
      event.key === "Enter" &&
      inputCliente.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      setLoadingAutoComplete(true);
      buscarPessoas(filtros, 1);
    }
  };

  const buscarPessoas = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoComplete(false);
      if (!res.isAxiosError) {
        setListaClientes(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarPagador = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setPagadorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    buscarPessoas({}, 1);
    buscarPagador();
  }, []);

  const submitRelatorioReceberContador = () => {
    if (!relatorioContadorContaReceber?.clienteId) {
      notification.alertaGenericos("Insira um cliente válido");
    }
  };

  return (
    <Minimal
      title="Contador - Contas Receber"
      cardAction={
        <Button
          onClick={submitRelatorioReceberContador}
          variant="contained"
          color="primary"
        >
          Gerar Relatório
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="clienteId"
              name="clienteId"
              options={listaClientes}
              loading={loadingAutoComplete}
              autoHighlight
              noOptionsText={
                document.getElementById("clienteId")?.value.length >= 1
                  ? "Nenhum cliente encontrado"
                  : "Digite e pressione Enter"
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Clientes"
                  margin="normal"
                  required
                  onKeyDown={(e) => onSearchChangePessoas(e)}
                  inputRef={inputCliente}
                  variant="outlined"
                  error={inputErros.get("clienteId")}
                />
              )}
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              filterSelectedOptions
              onChange={(e, newValue) => {
                onChangeAutocomplete("clienteId", newValue);
              }}
              value={value.autoComplete(
                listaClientes,
                relatorioContadorContaReceber.clienteId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      {relatorioContadorContaReceber.clienteId && (
        <>
          <hr className="mt-3" />
          <Grid item xs={12} className="mt-2">
            <span className="fw-bold fw-bolder">FILTROS</span>
          </Grid>
          <Grid container spacing={2} className="mt-2">
            <Grid item xs={12}>
              <Autocomplete
                id="pessoaClienteId"
                name="Pagador"
                options={pagadorList}
                noOptionsText="Sem opções"
                autoHighlight
                margin="normal"
                getOptionLabel={(option) =>
                  option.nomeRazaoSocial ? option.nomeRazaoSocial : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pagador"
                    variant="outlined"
                    margin="normal"
                  />
                )}
                onChange={(e, value) =>
                  onChangeAutocomplete("pessoaClienteId", value, "Pagador")
                }
                value={valueAutocomplete(pagadorList, "pessoaClienteId")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                id="dataEmissaoStartLancamento"
                name="Data-Emissão-de-Lançamento"
                label="Lançamento Inicio"
                margin
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoStartLancamento")}
                onChange={(date) =>
                  onChangeDate(
                    "dataEmissaoStartLancamento",
                    date,
                    "Data-de-Lançamento-Inicial"
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                id="dataEmissaoEndLancamento"
                name="Data-Emissão-até-Lançamento"
                label="Lançamento Final"
                margin
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoEndLancamento")}
                onChange={(date) =>
                  onChangeDate(
                    "dataEmissaoEndLancamento",
                    date,
                    "Data-de-Lançamento-Final"
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                id="dataEmissaoStartVencimento"
                name="Data-Emissão-de-Vencimento"
                label="Vencimento Inicio"
                margin
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoStartVencimento")}
                onChange={(date) =>
                  onChangeDate(
                    "dataEmissaoStartVencimento",
                    date,
                    "Data-de-Vencimento-Inicial"
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                id="dataEmissaoEndVencimento"
                name="Data-Emissão-até-Vencimento"
                label="Vencimento Final"
                margin
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoEndVencimento")}
                onChange={(date) =>
                  onChangeDate(
                    "dataEmissaoEndVencimento",
                    date,
                    "Data-de-Vencimento-Final"
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                id="dataEmissaoStartPagamento"
                name="Data-Emissão-de-Pagamento"
                label="Pagamento Inicio"
                margin
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoStartPagamento")}
                onChange={(date) =>
                  onChangeDate(
                    "dataEmissaoStartPagamento",
                    date,
                    "Data-de-Pagamento-Inicial"
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                id="dataEmissaoEndPagamento"
                name="Data-Emissão-até-Pagamento"
                label="Pagamento Final"
                margin
                format="dd/MM/yyyy"
                value={valueDate("dataEmissaoEndPagamento")}
                onChange={(date) =>
                  onChangeDate(
                    "dataEmissaoEndPagamento",
                    date,
                    "Data-de-Pagamento-Final"
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Select
                label="Status"
                id="status"
                name="status"
                variant="outlined"
                margin="normal"
                value={valueInput("status")}
                onChange={(event) => onChangeSelect(event, "Status")}
              >
                <MenuItem value="">
                  <p></p>
                </MenuItem>
                {listaStatus.map((status, index) => (
                  <MenuItem key={index} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Tipo"
                id="tipo"
                name="tipo"
                margin="normal"
                variant="outlined"
                value={valueInput("tipo")}
                onChange={(event) => onChangeSelect(event, "Tipo")}
              >
                <MenuItem value="">
                  <p></p>
                </MenuItem>
                {listaTipos.map((tipo, index) => (
                  <MenuItem key={index} value={tipo.value}>
                    {tipo.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </>
      )}
    </Minimal>
  );
};

export default ContadorContasReceberView;
