import SiafLiteApiRepository from "../../external/siafLiteApi";

class SituacaoTributariaIpiService {
  constructor() {
    this.urlBase = "/fiscal/situacoes-tributarias-ipi";
  }

  getAll() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }
}

export default SituacaoTributariaIpiService;
