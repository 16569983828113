import * as Yup from "yup";

const contaPagarValidator = Yup.object().shape({
  status: Yup.string().nullable(),
  numeroTitulo: Yup.string().nullable(),
  origem: Yup.string().nullable(),
  pessoaId: Yup.number().required("Recebedor"),
  meioPagamentoId: Yup.number().required("Meio de Pagamento"),
  dataCompetencia: Yup.date(),
  dataVencimento: Yup.date().required("Data Vencimento"),
  //planoFinanceiroId: Yup.number().nullable(),
  //centroCustoId: Yup.number().nullable(),
  historico: Yup.string().nullable(),
  pagamentosParciais: Yup.string().nullable(),
  valor: Yup.number().required("Valor"),
  acrescimo: Yup.string().nullable(),
  desconto: Yup.string().nullable(),
  observacaoList: Yup.array(),
  pagamentos: Yup.array()
    .of(
      Yup.object({
        dataPagamento: Yup.date().required("Data de pagamento"),
        recebedorId: Yup.number().required("Recebedor"),
        meioPagamentoId: Yup.number().required("Meio de pagamento"),
        atraso: Yup.number().nullable(),
        multa: Yup.string().nullable(),
        juros: Yup.string().nullable(),
        desconto: Yup.number().nullable(),
        valorTitulo: Yup.number().required("Valor do Título"),
        valor: Yup.number().required("Valor Pago"),
      })
    )
    .nullable(),
});

export default contaPagarValidator;
