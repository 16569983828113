import { useState, useEffect } from "react";
import {
  CardContent,
  Grid,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  InputAdornment,
} from "../../../components";
import PasswordStrengthBar from "react-password-strength-bar";
import { notification, MeuUsuarioService } from "../../../services";
import { InputErros, value } from "../../../helpers";
import { responseErros, voltar } from "../../../utils";
import { meuUsuarioValidator } from "./middlewares";
import { FlexLayout } from "../../../layouts";
import { useImportContext } from "../../../contexts/import.context";

const MeuUsuarioView = () => {
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [meuUsuario, setMeuUsuario] = useState();
  const [senhaScore, setSenhaScore] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const meuUsuarioService = new MeuUsuarioService();

  useEffect(() => {
    buscaMeuUsuario();
  }, []);

  const buscaMeuUsuario = async () => {
    setLoading(true);
    const result = await meuUsuarioService.get();
    if (!result.isAxiosError) {
      if (result.data) {
        setMeuUsuario({
          id: result.data.id,
          nome: result.data.nome,
        });
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleUserInputChange = (e) => {
    if (e.target.name == "senha" || e.target.name == "confirmarSenha") {
      return setMeuUsuario((prevState) => ({
        ...prevState,
        [e.target.name]: String(e.target.value).trim(),
      }));
    }
    setMeuUsuario((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const senhaForte = () => {
    if (meuUsuario?.senha && senhaScore < 2) {
      inputErros.gerar("senha");
      notification.alertaGenericos("Senha fraca");
      return false;
    }
    return true;
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmitUsuario = (indiceSelecionado) => {
    meuUsuarioValidator
      .validate(meuUsuario, { abortEarly: false })
      .then(async () => {
        inputErros.set(null);
        if (senhaForte()) atualizarUsuario(indiceSelecionado);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const atualizarUsuario = async () => {
    setLoading(true);
    const result = await meuUsuarioService.atualizar(meuUsuario.id, meuUsuario);
    if (!result.isAxiosError) {
      notification.alertaApi(
        "Para que as alterações tenham efeito é necessário fazer login novamente."
      );
      initialCollapses();
      voltar();
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  return (
    <FlexLayout
      id={"meuUsuario"}
      onClickSalvar={handleSubmitUsuario}
      loading={loading}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CardHeader title="Meu usuário" />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="px-3">
          <Grid item xs={5}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              variant="outlined"
              value={value.text(meuUsuario?.nome)}
              onChange={handleUserInputChange}
              fullWidth
              required
              error={inputErros.get("nome")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="px-3">
          <Grid item xs={5}>
            <TextField
              id="senha"
              name="senha"
              label="Senha"
              variant="outlined"
              onChange={handleUserInputChange}
              fullWidth
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <i
                          className="ph-fill ph-eye-slash"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      ) : (
                        <i
                          className="ph-fill ph-eye"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={meuUsuario?.senha ? meuUsuario.senha : ""}
              error={inputErros.get("senha")}
            />
            {senhaScore < 2 ? (
              <Typography
                className="mt-4"
                style={{ color: "#E53935", fontSize: 10 }}
              >
                Deve ter de 6 a 20 caracteres e no mínimo razoável
              </Typography>
            ) : (
              ""
            )}
            <PasswordStrengthBar
              scoreWords={[
                "senha fraca",
                "senha fraca",
                "senha razoável",
                "senha boa",
                "senha forte",
              ]}
              shortScoreWord={"senha curta"}
              password={meuUsuario?.senha}
              onChangeScore={(score) => {
                setSenhaScore(score);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="px-3">
          <Grid item xs={5}>
            <TextField
              id="confirmarSenha"
              name="confirmarSenha"
              label="Confirmar senha"
              variant="outlined"
              onChange={handleUserInputChange}
              fullWidth
              type={showPassword ? "text" : "password"}
              autocomplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <i
                          className="ph-fill ph-eye-slash"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      ) : (
                        <i
                          className="ph-fill ph-eye"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={
                meuUsuario?.confirmarSenha ? meuUsuario.confirmarSenha : ""
              }
              error={inputErros.get("confirmarSenha")}
            />
          </Grid>
        </Grid>
      </CardContent>
    </FlexLayout>
  );
};

export default MeuUsuarioView;
