import { useState } from "react";
import {
  Autocomplete,
  Button,
  DatePicker,
  Grid,
  TextField,
} from "../../../../components";
import {
  CaixaService,
  ContasReceberService,
  notification,
} from "../../../../services";
import { formatDate, responseErros } from "../../../../utils";
import { InputErros, value } from "../../../../helpers";
import ResumoTotalContas from "./resumo-total-contas.component";
import { montaListaContasABaixar } from "../list-contas-receber";
import contasReceberValidator from "../middlewares/list-contas-receber.validator";
import { useImportContext } from "../../../../contexts/import.context";

const BaixaLoteReceber = ({
  renderBaixaLote,
  setRenderBaixaLote,
  listaContasReceber,
  buscarContasReceber,
  setListaContasReceber,
  listaMeiosPagamento,
  listaBancosCaixas,
  baixaLote,
  setBaixaLote,
  totalAReceber,
  calculaTotalAReceber,
  retornoTopo,
}) => {
  const contasReceberService = new ContasReceberService();
  const caixaService = new CaixaService();
  const { openCollapse } = useImportContext();
  const [meioPagamento, setMeioPagamento] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const listaContasReceberValidator = contasReceberValidator();

  const cadastrarCaixa = async (bancoCaixaId) => {
    const body = {
      data: new Date(),
      bancoCaixaId,
      aberto: true,
    };
    const result = await caixaService.cadastrar(body);
    if (result.isAxiosError) {
      responseErros(result);
    }
  };

  const cadastrarPagamentos = async (event) => {
    if (listaContasReceber.length > 0) {
      const listaContasABaixar = montaListaContasABaixar(
        listaContasReceber,
        meioPagamento,
        baixaLote
      );
      event.preventDefault();
      listaContasReceberValidator
        .validate(listaContasABaixar, { abortEarly: false })
        .then(async () => {
          await cadastrarCaixa(
            baixaLote?.bancoCaixaId ?? meioPagamento?.bancoCaixaId
          );
          const res = await contasReceberService.baixaEmLote(
            listaContasABaixar
          );
          if (!res.isAxiosError) {
            return sucessoBaixa(event);
          } else responseErros(res);
        })
        .catch((err) => {
          inputErros.set(err);
        });
    } else {
      return erroBaixa();
    }
  };

  function sucessoBaixa(event) {
    buscarContasReceber();
    setListaContasReceber([]);
    setRenderBaixaLote(false);
    event?.current?.submit();
    return notification.sucessoGenericos("Contas baixadas com sucesso!");
  }

  function erroBaixa() {
    setRenderBaixaLote(false);
    return notification.alertaGenericos(
      "Selecione no mínimo uma conta a baixar"
    );
  }

  function sairBaixa() {
    setRenderBaixaLote(false);
    retornoTopo();
    setBaixaLote({
      jurosPorcentagem: baixaLote?.jurosPorcentagem,
      multaPorcentagem: baixaLote?.multaPorcentagem,
    });
  }

  const onChangeAutocomplete = (name, value) => {
    setBaixaLote({
      ...baixaLote,
      [name]: value ? value.id : undefined,
    });
  };

  const onDateChange = (date, fieldName) => {
    if (!date) {
      date = "Invalid Date";
    }
    setBaixaLote({
      ...baixaLote,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    });
    calculaTotalAReceber(listaContasReceber, date);
  };

  return (
    <>
      {renderBaixaLote && (
        <div
          className="p-3 position-fixed bottom-0 bg-white d-flex flex-row flex-wrap"
          style={{
            width: openCollapse ? "calc(100vw - 292px)" : "calc(100vw - 77px)",
            transition: "width 400ms ease",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <DatePicker
                id="dataPagamento"
                name="dataPagamento"
                label="Data de Pagamento"
                format="dd/MM/yyyy"
                maxDate={new Date()}
                variant="outlined"
                margin
                required
                error={inputErro.indexOf("dataPagamento") != -1 ? true : false}
                fullWidth
                value={
                  baixaLote?.dataPagamento ? baixaLote.dataPagamento : null
                }
                onChange={(date) => onDateChange(date, "dataPagamento")}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                id="meioPagamentoId"
                name="meioPagamentoId"
                required
                options={listaMeiosPagamento}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meio de Pagamento"
                    variant="outlined"
                    margin="normal"
                    required
                    error={inputErros.get("meioPagamentoId")}
                  />
                )}
                onChange={(_, newValue) => setMeioPagamento(newValue)}
                value={meioPagamento}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                id="bancoCaixaId"
                name="bancoCaixaId"
                options={listaBancosCaixas}
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                getOptionSelected={(option, value) =>
                  option.nome === value.nome
                }
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Banco/Caixa"
                    variant="outlined"
                    margin="normal"
                    error={inputErros.get("bancoCaixaId")}
                  />
                )}
                onChange={(_, newValue) => {
                  onChangeAutocomplete("bancoCaixaId", newValue);
                }}
                value={value.autoComplete(
                  listaBancosCaixas,
                  baixaLote?.bancoCaixaId
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                id="baixarTitulos"
                name="baixarTitulos"
                variant="contained"
                color="success"
                onClick={(e) => cadastrarPagamentos(e)}
                sx={{ p: "0.9rem", mt: "1rem" }}
                fullWidth
              >
                <i
                  className="ph-fill ph-currency-dollar"
                  style={{ fontSize: 22, marginRight: 10, color: "white" }}
                ></i>
                baixar
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="padrao"
                sx={{ p: "1rem", mt: "1rem" }}
                onClick={sairBaixa}
                fullWidth
              >
                <i className="ph-fill ph-x-circle" style={{ fontSize: 22 }}></i>
              </Button>
            </Grid>
          </Grid>
          <ResumoTotalContas totalAReceber={totalAReceber} />
        </div>
      )}
    </>
  );
};

export default BaixaLoteReceber;
