import { Grid, Select, MenuItem, TextField } from "../../../../components";

const FormFiltroAvancado = ({ valueInput, onChangeInput }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          id="ativado"
          name="ativado"
          value={valueInput("ativado")}
          label="Status"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value={"true"}>Ativado</MenuItem>
          <MenuItem value={"false"}>Desativado</MenuItem>
          <MenuItem value={"-"}>Ambos</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Select
          id="tipoOperacao"
          name="tipoOperacao"
          label="Tipo de Operação"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("tipoOperacao")}
        >
          <MenuItem value={"ENTRADA"}>Entrada</MenuItem>
          <MenuItem value={"SAIDA"}>Saída</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="descricao"
          name="descricao"
          label="Descrição"
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("descricao")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="modeloDocumento"
          name="modeloDocumento"
          label="Modelo documento"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("modeloDocumento")}
        >
          <MenuItem value={"01"}>01 - Nota Fiscal</MenuItem>
          <MenuItem value={"1B"}>1B - Nota Fiscal Avulsa</MenuItem>
          <MenuItem value={"02"}>
            02 - Nota Fiscal de Venda a Consumidor
          </MenuItem>
          <MenuItem value={"04"}>04 - Nota Fiscal de Produtor</MenuItem>
          <MenuItem value={"55"}>55 - Nota Fiscal Eletrônica (NF-e)</MenuItem>
          <MenuItem value={"65"}>
            65 - Nota Fiscal Eletrônica ao Consumidor
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="serieDocumento"
          name="serieDocumento"
          label="Série Documento"
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("serieDocumento")}
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="presencaConsumidor"
          name="presencaConsumidor"
          label="Presença Consumidor"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("presencaConsumidor")}
        >
          <MenuItem value={"NAO_SE_APLICA"}>Não se aplica</MenuItem>
          <MenuItem value={"PRESENCIAL"}>Presencial</MenuItem>
          <MenuItem value={"INTERNET"}>Internet</MenuItem>
          <MenuItem value={"TELEATENDIMENTO"}>Teleatendimento</MenuItem>
          <MenuItem value={"NFCE_ENTREGA_DOMICILIO"}>
            NFC-e entrega Domicilio
          </MenuItem>
          <MenuItem value={"PRESENCIAL_FORA_ESTABELECIMENTO"}>
            Presencial fora Estabelecimento
          </MenuItem>
          <MenuItem value={"OUTROS"}>Outros</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Select
          id="finalidade"
          name="finalidade"
          label="Finalidade"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("finalidade")}
        >
          <MenuItem value={"NAO_SE_APLICA"}>0 - NÃO SE APLICA</MenuItem>
          <MenuItem value={"NFE_NORMAL"}>1 - NFE NORMAL</MenuItem>
          <MenuItem value={"NFE_COMPLEMENTAR"}>2 - NFE COMPLEMENTAR</MenuItem>
          <MenuItem value={"NFE_AJUSTE"}>3 - NFE DE AJUSTE</MenuItem>
          <MenuItem value={"DEVOLUCAO"}>4 - DEVOLUÇÃO DE MERCADORIA</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
