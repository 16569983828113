import SiafLiteApiRepository from "../../external/siafLiteApi";
import encrypt from "../../utils/encrypt";

class MeuUsuarioService {
  constructor() {
    this.urlBase = "/painel-controle/meu-usuario";
  }

  get() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }

  atualizar(id, usuario) {
    if (usuario.senha) {
      const senha = encrypt(usuario.senha);
      const confirmarSenha = encrypt(usuario.confirmarSenha);
      const body = { ...usuario, senha, confirmarSenha };
      return new SiafLiteApiRepository(this.urlBase).put(id, body);
    }
    return new SiafLiteApiRepository(this.urlBase).put(id, usuario);
  }

  validarToken() {
    return new SiafLiteApiRepository(`${this.urlBase}/validar-token`).get();
  }
}

export default MeuUsuarioService;
