import * as Yup from "yup";

const modeloGradeValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  variacaoModeloGrade: Yup.array(
    Yup.object().shape({
      descricao: Yup.string().required("Variação"),
      valorVariacaoModeloGrade: Yup.array()
        .min(1, "Valor de variação")
        .required("Valor de variação"),
    })
  ),
});

export default modeloGradeValidator;
