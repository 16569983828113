import SiafLiteApiRepository from "../../external/siafLiteApi";

class PedidosService {
  constructor() {
    this.urlBase = "/faturamento/pedidos";
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAllFiltroAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  cancelar(id) {
    return new SiafLiteApiRepository(`${this.urlBase}/${id}/cancelar`).get();
  }

  getRelatorioResumido(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/resumido`
    ).patch(body);
  }

  getRelatorioTotais() {
    return new SiafLiteApiRepository(`${this.urlBase}/relatorios/totais`).get();
  }
}

export default PedidosService;
