import { Table as TableMui } from "@mui/material";

const Table = ({
  className,
  children,
  classes,
  component,
  padding,
  ref,
  size,
  stickyHeader,
  sx,
}) => {
  return (
    <TableMui
      className={className}
      classes={classes}
      component={component}
      padding={padding}
      ref={ref}
      size={size}
      stickyHeader={stickyHeader}
      sx={sx}
    >
      {children}
    </TableMui>
  );
};

export default Table;
