export class ProdutoFornecedores {
  id = null;
  codigoProduto = null;
  quantidadeEmbalagem = null;

  constructor(fornecedor) {
    this.id = fornecedor.id;
    this.codigoProduto = fornecedor.codigoProduto;
    this.quantidadeEmbalagem = fornecedor.quantidadeEmbalagem;
  }
}
