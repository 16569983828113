class StorageHelper {
  getLocal(key) {
    return localStorage.getItem(key);
  }

  setLocal(key, value) {
    return localStorage.setItem(key, value);
  }

  removeLocal(key) {
    return localStorage.removeItem(key);
  }

  getSession(key) {
    return sessionStorage.getItem(key);
  }

  setSession(key, value) {
    return sessionStorage.setItem(key, value);
  }

  removeSession(key) {
    return sessionStorage.removeItem(key);
  }
}

export default StorageHelper;
