export class GrupoPessoas {
  nome = null;
  tipoPessoa = null;
  ativado = null;
  tabelaPrecoId = null;

  constructor(grupoPessoa) {
    this.nome = grupoPessoa.nome;
    this.tipoPessoa = grupoPessoa.tipoPessoa;
    this.ativado = grupoPessoa.ativado;
    this.tabelaPrecoId = grupoPessoa.tabelaPrecoId;
  }
}
