import { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Loading,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import { ContadorService, notification } from "../../../../services";
import { responseErros } from "../../../../utils";

const ConviteContadorDialog = ({ empresa, open, setOpen }) => {
  const [email, setEmail] = useState(empresa?.emailContabil);
  const [loading, setLoading] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const contadorService = new ContadorService();

  const onChangeInput = (event) => {
    setEmail(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setInputErro([]);
    setEmail(empresa?.emailContabil);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const res = await contadorService.enviarEmailContador(empresa?.id, email);
    if (!res.isAxiosError) {
      notification.sucessoGenericos("Convite enviado com sucesso!");
      handleClose();
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <CardHeader title="Cadastro Contador" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="emailContador"
                  name="emailContador"
                  type="email"
                  label="E-mail Contador"
                  variant="outlined"
                  required
                  onChange={onChangeInput}
                  value={value.text(email)}
                  error={inputErros.get("emailContador")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" type="submit">
              <i
                className="ph ph-envelope-simple"
                style={{ fontSize: 22, marginRight: 10, color: "white" }}
              ></i>
              Enviar
            </Button>
            <Button variant="contained" color="padrao" onClick={handleClose}>
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Fechar
            </Button>
          </CardActions>
        </DialogContent>
      </form>
      <Loading loading={loading} />
    </Dialog>
  );
};

export default ConviteContadorDialog;
