import {
  Button,
  CurrencyInput,
  DataGrid,
  Grid,
  TextField,
} from "../../../../components";
import { useState } from "react";
import { unformatPrice } from "../../../../utils";
import { notification } from "../../../../services";
import { documentoFreteNfeValidator } from "../middlewares";
import { InputErros } from "../../../../helpers";
import { DocumentoFreteNfeEntity } from "../../entities";

export default function DocumentoFreteNfe({
  documentoFreteId,
  listaNfe,
  setListaNfe,
}) {
  const [nfe, setNfe] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const colunas = [
    {
      field: "serieNfe",
      headerName: "Série",
      width: 100,
    },
    {
      field: "numeroNfe",
      headerName: "Número",
      flex: 100,
      filtrar: true,
    },
    {
      field: "valorNfe",
      headerName: "Valor Total",
      width: 250,
      sortable: false,
      valueGetter: (params) => params.value,
    },
    {
      field: "pesoNfe",
      headerName: "Peso Total",
      width: 250,
      sortable: false,
      valueGetter: (params) => params.value,
    },
    {
      field: "chaveNfe",
      headerName: "Chave NF-e",
      flex: 350,
      sortable: false,
      valueGetter: (params) => params.value,
    },
  ];

  const handleInputBlur = (e) => {
    const nfeTemp = {
      ...nfe,
      [e.target.name]: unformatPrice(e.target.value),
    };
    setNfe(nfeTemp);
  };

  const handleInputChange = (e) => {
    const nfeTemp = {
      ...nfe,
      [e.target.name]: e.target.value,
    };
    setNfe(nfeTemp);
  };

  const handleRemoverProduto = async (id) => {
    const data = listaNfe.filter((item) => item.id !== id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir essa linha?`
    );
    if (retornoAlerta.isConfirmed) {
      setListaNfe(data);
    }
  };

  const handleEditarProduto = async (id) => {
    const nfeTemp = listaNfe.find((item) => item.id === id);
    setNfe({
      ...nfeTemp,
      edicao: true,
    });
  };

  const onClickAdicionarNfe = () => {
    inputErros.set(null);
    documentoFreteNfeValidator
      .validate(nfe, { abortEarly: false })
      .then(() => {
        adicionarNfe();
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const adicionarNfe = () => {
    const documentoFreteNfeEntity = new DocumentoFreteNfeEntity(nfe);
    if (nfe?.edicao) {
      setListaNfe((prevState) =>
        prevState.map((obj) => {
          if (documentoFreteNfeEntity.id == obj.id) {
            return documentoFreteNfeEntity;
          }
          return obj;
        })
      );
      setNfe({});
    } else {
      setListaNfe((prevState) => [...prevState, documentoFreteNfeEntity]);
      setNfe({});
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            id="serieNfe"
            name="serieNfe"
            label="Série NF-e"
            margin="normal"
            variant="outlined"
            type="number"
            onChange={handleInputChange}
            value={nfe.serieNfe ?? ""}
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            error={inputErros.get("serieNfe")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="numeroNfe"
            name="numeroNfe"
            label="Número NF-e"
            margin="normal"
            variant="outlined"
            type="number"
            onChange={handleInputChange}
            value={nfe.numeroNfe ?? ""}
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            error={inputErros.get("numeroNfe")}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorNfe"
            name="valorNfe"
            label="Valor NF-e (R$)"
            variant="outlined"
            required
            value={nfe?.valorNfe ? nfe.valorNfe : ""}
            onBlur={handleInputBlur}
            fullWidth
            error={inputErros.get("valorNfe")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="pesoNfe"
            name="pesoNfe"
            label="Peso NF-e (Kg)"
            margin="normal"
            variant="outlined"
            type="number"
            required
            InputProps={{ inputProps: { min: 0 } }}
            value={nfe?.pesoNfe ? nfe.pesoNfe : ""}
            onChange={handleInputChange}
            fullWidth
            error={inputErros.get("pesoNfe")}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="chaveNfe"
            name="chaveNfe"
            label="Chave NF-e"
            margin="normal"
            variant="outlined"
            value={nfe?.chaveNfe ? nfe.chaveNfe : ""}
            InputProps={{ inputProps: { min: 0, maxLength: 44 } }}
            onChange={handleInputChange}
            fullWidth
            error={inputErros.get("chaveNfe")}
          />
        </Grid>
        <Grid item xs={1}>
          {nfe?.edicao ? (
            <Button
              id="buttonAdicionar"
              variant="contained"
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
                mt: "2em",
              }}
              onClick={onClickAdicionarNfe}
            >
              <i
                className="ph-bold ph-arrows-clockwise"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          ) : (
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="success"
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
                mt: "2em",
              }}
              onClick={onClickAdicionarNfe}
            >
              <i
                className="ph-bold ph-plus"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid style={{ marginTop: 32, marginBottom: 32 }} item xs={12}>
          <DataGrid
            style={{ zIndex: 1 }}
            rows={listaNfe}
            columns={colunas}
            rowCount={listaNfe.length}
            deleteShow={!documentoFreteId}
            editShow={!documentoFreteId}
            onClickEdit={handleEditarProduto}
            onClickDelete={handleRemoverProduto}
          />
        </Grid>
      </Grid>
    </div>
  );
}
