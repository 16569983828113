import { TableCell as TableCellMui } from "@mui/material";

const TableCell = ({
  align,
  className,
  children,
  classes,
  component,
  padding,
  ref,
  scope,
  size,
  sortDirection,
  sx,
  variant,
}) => {
  return (
    <TableCellMui
      align={align}
      className={className}
      classes={classes}
      component={component}
      padding={padding}
      ref={ref}
      scope={scope}
      size={size}
      sortDirection={sortDirection}
      sx={sx}
      variant={variant}
    >
      {children}
    </TableCellMui>
  );
};

export default TableCell;
