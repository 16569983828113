import * as Yup from "yup";

const pedidosValidator = Yup.object().shape({
  orcamentoId: Yup.number().nullable(),
  operacaoFiscalId: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required("Operação Fiscal"),
  pessoaClienteId: Yup.number().nullable(),
  pessoaVendedorId: Yup.number().when("vendedorObrigatorio", {
    is: true,
    then: Yup.number().required("Vendedor"),
    otherwise: Yup.number().nullable(),
  }),
  pessoaProficionalId: Yup.number().nullable(),
  informacaoAdicional: Yup.string().nullable(),
  itens: Yup.array().min(1, "Incluir ao menos 1 produto"),
  pagamentos: Yup.array().min(1, "Incluir ao menos 1 pagamento"),
  pedidoEnderecoCliente: Yup.object().nullable(),
  vendedorObrigatorio: Yup.boolean().nullable(),
});

export default pedidosValidator;
