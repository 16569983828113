import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  Select,
  MenuItem,
  TextField,
} from "../../../../components";
import {
  MeioPagamentoService,
  BancosCaixasService,
  PessoaService,
} from "../../../../services";
import { responseErros } from "../../../../utils";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueDate,
  onChangeDate,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const [bancosCaixaList, setBancosCaixaList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [recebedorList, setRecebedorList] = useState([]);
  const pessoaService = new PessoaService();
  const meioPagamentoService = new MeioPagamentoService();
  const bancosCaixasService = new BancosCaixasService();
  const listaStatus = [
    {
      label: "Aberto",
      value: "ABERTO",
      cor: "#4FBB53",
    },
    {
      label: "Baixado Parcial",
      value: "BAIXADO_PARCIAL",
      cor: "#EA9D2B",
    },
    {
      label: "Baixado",
      value: "BAIXADO",
      cor: "#004780",
    },
    {
      label: "Cancelado",
      value: "CANCELADO",
      cor: "#DC3545",
    },
  ];

  useEffect(() => {
    buscarContas();
    buscarRecebedor();
    buscaMeiosPagamento();
  }, []);

  const buscarRecebedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((res) => {
      if (!res.isAxiosError) {
        setRecebedorList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarContas = () => {
    const filtros = {
      nonPaginated: true,
    };
    bancosCaixasService.getAll(filtros).then((res) => {
      if (!res.isAxiosError) {
        setBancosCaixaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscaMeiosPagamento = () => {
    const filtros = {
      nonPaginated: true,
    };
    meioPagamentoService.getAll(filtros).then((res) => {
      if (!res.isAxiosError) {
        setMeioPagamentoList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="numeroTitulo"
          name="numeroTitulo"
          label="Nº Título"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("numeroTitulo")}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Status"
          id="status"
          name="status"
          value={valueInput("status")}
          onChange={(event) => onChangeInput(event)}
        >
          {listaStatus.map((status, index) => (
            <MenuItem key={index} value={status.value}>
              <i
                className="ph-fill ph-circle"
                style={{ color: `${status.cor}` }}
              ></i>
              &nbsp;{status.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Select
          id="tipo"
          name="tipo"
          value={valueInput("tipo")}
          label="Tipo"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value={"TITULO_NORMAL"}>Título Normal</MenuItem>
          <MenuItem value={"NOTA_DEBITO"}>Nota Débito</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="pessoaId"
          name="pessoaId"
          options={recebedorList}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) =>
            option.nomeRazaoSocial ? option.nomeRazaoSocial : ""
          }
          renderInput={(params) => (
            <TextField {...params} label="Recebedor" variant="outlined" />
          )}
          onChange={(e, value) => onChangeAutocomplete("pessoaId", value)}
          value={valueAutocomplete(recebedorList, "pessoaId")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="bancoCaixaId"
          name="bancoCaixaId"
          options={bancosCaixaList}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => (option.nome ? option.nome : "")}
          renderInput={(params) => (
            <TextField {...params} label="Conta" variant="outlined" />
          )}
          onChange={(e, value) => onChangeAutocomplete("bancoCaixaId", value)}
          value={valueAutocomplete(bancosCaixaList, "bancoCaixaId")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="meioPagamentoId"
          name="meioPagamentoId"
          options={meioPagamentoList}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) =>
            option.descricao ? option.descricao : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Meio de Pagamento"
              variant="outlined"
            />
          )}
          onChange={(e, value) =>
            onChangeAutocomplete("meioPagamentoId", value)
          }
          value={valueAutocomplete(meioPagamentoList, "meioPagamentoId")}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataLancamentoStart"
          name="dataLancamentoStart"
          label="Data Lançamento De"
          format="dd/MM/yyyy"
          value={valueDate("dataLancamentoStart")}
          onChange={(date) => onChangeDate("dataLancamentoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataLancamentoEnd"
          name="dataLancamentoEnd"
          label="Data Lançamento Até"
          format="dd/MM/yyyy"
          value={valueDate("dataLancamentoEnd")}
          onChange={(date) => onChangeDate("dataLancamentoEnd", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataVencimentoStart"
          name="dataVencimentoStart"
          label="Data Vencimento De"
          format="dd/MM/yyyy"
          margin
          value={valueDate("dataVencimentoStart")}
          onChange={(date) => onChangeDate("dataVencimentoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataVencimentoEnd"
          name="dataVencimentoEnd"
          label="Data Vencimento Até"
          format="dd/MM/yyyy"
          margin
          value={valueDate("dataVencimentoEnd")}
          onChange={(date) => onChangeDate("dataVencimentoEnd", date)}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
