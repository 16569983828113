import { dicionario } from "./resources/constantes";

export function montarPermissoesCadastro(modulos) {
  const permissoesModulos = {};
  for (const modulo of modulos) {
    const { nome: secao, filhos } = modulo;
    const elementos = filhos?.length ? filhos : [modulo];
    if (!permissoesModulos[secao]) {
      permissoesModulos[secao] = {};
    }
    for (const { nome: subSecao, permisaoModulo } of elementos) {
      if (!permissoesModulos[secao][subSecao]) {
        permissoesModulos[secao][subSecao] = {};
      }
      for (const { id, nome } of permisaoModulo) {
        permissoesModulos[secao][subSecao][nome] = {
          pctPermissoesModuloId: id,
          habilitado: false,
        };
      }
    }
  }
  return permissoesModulos;
}

export function montarPermissoesEdicao(permissoesModulo, permissoesUsuario) {
  return Object.keys(permissoesModulo).reduce((acc, secao) => {
    acc[secao] = Object.keys(permissoesModulo[secao]).reduce(
      (subAcc, subSecao) => {
        subAcc[subSecao] = Object.keys(
          permissoesModulo[secao][subSecao]
        ).reduce((permAcc, nomePermissao) => {
          const permissao = permissoesModulo[secao][subSecao][nomePermissao];
          const permissaoUsuario =
            permissoesUsuario?.length &&
            permissoesUsuario.find((permissaoUsuario) => {
              if (permissaoUsuario?.permissaoModulo?.nome === nomePermissao) {
                return permissaoUsuario;
              }
            });
          if (permissaoUsuario?.habilitado) {
            permAcc[nomePermissao] = {
              ...permissao,
              habilitado: permissaoUsuario.habilitado,
            };
          } else {
            permAcc[nomePermissao] = permissao;
          }
          return permAcc;
        }, {});
        return subAcc;
      },
      {}
    );
    return acc;
  }, {});
}

export function descreverPermissao(nomePermissao, subSecao) {
  if (nomePermissao === "dashboard") return "PERMITE VISUALIZAR DASHBOARD";
  for (const acaoPermissao of Object.keys(dicionario)) {
    if (nomePermissao.endsWith(acaoPermissao) && dicionario[acaoPermissao]) {
      return `${dicionario[acaoPermissao]} ${dicionario[subSecao] || subSecao}`
        .replace(/[-]/g, " ")
        .toUpperCase();
    }
  }
  return nomePermissao.replace(/[-]/g, " ").toUpperCase();
}

export function getPalavraTraduzida(palavra) {
  return dicionario[palavra]
    ? `${dicionario[palavra]}`.toUpperCase()
    : palavra.replace(/[-]/g, " ").toUpperCase();
}
