import { createRef, useState, useCallback } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Loading,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import { useDropzone } from "react-dropzone";
import { responseErros } from "../../../../utils";
import { notification } from "../../../../services";
import CertificadoDigitalService from "../../../../services/api/certificado-digital.service";

const CertificadoDigitalDialog = ({
  empresa,
  openEnviarCertificadoDialog,
  setOpenEnviarCertificadoDialog,
}) => {
  const certificadoUpload = createRef();
  const [arquivoSelecionado, setArquivoSelecionado] = useState(false);
  const [certificado, setCertificado] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    handleCertificadoChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [loading, setLoading] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const certificadoDigitalService = new CertificadoDigitalService();
  const inputErros = new InputErros(inputErro, setInputErro);

  const onChangeInput = (event) => {
    setCertificado({
      ...certificado,
      [event.target.name]: event.target.value || null,
    });
  };

  const handleCertificadoChange = (event) => {
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files[0];
    } else if (event.target) {
      files = event.target.files[0];
    }
    setCertificado({
      ...certificado,
      files,
    });
    setArquivoSelecionado(true);
  };

  const handleClose = () => {
    setOpenEnviarCertificadoDialog(false);
    setInputErro([]);
    setCertificado({});
    setArquivoSelecionado(false);
    setShowPassword(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("files", certificado.files);
    formData.append("senha", certificado.senha);
    const res = await certificadoDigitalService.enviar(
      formData,
      empresa.cnpjCpf
    );
    if (!res.isAxiosError) {
      notification.sucessoGenericos("Certificado enviado com sucesso!");
      handleClose();
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth
      open={openEnviarCertificadoDialog}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <CardHeader title="Certificado Digital" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {arquivoSelecionado ? (
                  <>
                    <Tooltip title={certificado.files?.name} placement="top">
                      <label
                        htmlFor="contained-button-file"
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          accept="application/x-pkcs12"
                          type="file"
                          id="contained-button-file"
                          ref={certificadoUpload}
                          style={{ display: "none" }}
                          onChange={handleCertificadoChange}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: "#1768aa" }}
                          size="large"
                          sx={{ px: "7em" }}
                          fullWidth
                          onClick={() => certificadoUpload.current.click()}
                        >
                          Arquivo Selecionado
                        </Button>
                      </label>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip
                    title="Arraste ou clique aqui para carregar certificado"
                    placement="top"
                  >
                    <label htmlFor="contained-button-file" {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        accept=".pfx,.p12"
                        type="file"
                        id="contained-button-file"
                        ref={certificadoUpload}
                        style={{ display: "none" }}
                        onChange={handleCertificadoChange}
                      />
                      {isDragActive ? (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: "#1768aa" }}
                          size="large"
                          fullWidth
                          sx={{ px: "7em" }}
                          onClick={() => certificadoUpload.current.click()}
                        >
                          Solte o arquivo aqui
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          sx={{ px: "4em" }}
                          onClick={() => certificadoUpload.current.click()}
                        >
                          Selecionar certificado (.pfx)
                        </Button>
                      )}
                    </label>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: "1em" }}>
              <Grid item xs={12}>
                <TextField
                  id="senha"
                  name="senha"
                  type={showPassword ? "text" : "password"}
                  label="Senha"
                  variant="outlined"
                  required
                  onChange={onChangeInput}
                  value={value.text(certificado.senha)}
                  error={inputErros.get("senha")}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={
                              showPassword
                                ? "ph-fill ph-eye"
                                : "ph-fill ph-eye-slash"
                            }
                            style={{ fontSize: 22, marginRight: 10 }}
                          ></i>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={arquivoSelecionado ? false : true}
            >
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Enviar
            </Button>
            <Button
              variant="contained"
              color="padrao"
              onClick={() => {
                setCertificado({});
                setArquivoSelecionado(false);
                setOpenEnviarCertificadoDialog(false);
              }}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Fechar
            </Button>
          </CardActions>
        </DialogContent>
      </form>
      <Loading loading={loading} />
    </Dialog>
  );
};

export default CertificadoDigitalDialog;
