import * as Yup from "yup";

const regraDescontoValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  dataInicial: Yup.date().required("Data inicial"),
  dataFinal: Yup.date().required("Data final"),
  diasSemana: Yup.array().min(1, "Dia da semana").required("Dia da semana"),
});

export default regraDescontoValidator;
