import SiafLiteApiRepository from "../../external/siafLiteApi";

class ContadorService {
  constructor() {
    this.urlBase = "/painel-controle/contador";
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  enviarEmailContador(empresaId, email) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${empresaId}/convite-contador`
    ).post({
      email,
    });
  }
}

export default ContadorService;
