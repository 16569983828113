import { Menu as MenuMui } from "@mui/material";

const Menu = ({
  children = [],
  className,
  open,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  autoFocus,
  classes,
  disableAutoFocusItem,
  MenuListProps,
  onClose,
  PopoverClasses,
  sx,
  transitionDuration,
  TransitionProps,
  variant,
}) => {
  return (
    <MenuMui
      className={className}
      open={open ? true : false}
      anchorEl={anchorEl}
      autoFocus={autoFocus ? true : false}
      classes={classes}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      disableAutoFocusItem={disableAutoFocusItem ? true : false}
      MenuListProps={MenuListProps}
      onClose={onClose}
      PopoverClasses={PopoverClasses}
      sx={sx}
      transitionDuration={transitionDuration}
      TransitionProps={TransitionProps}
      variant={variant || "selectedMenu"}
    >
      {children}
    </MenuMui>
  );
};

export default Menu;
