import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  Select,
  MenuItem,
  TextareaAutosize,
  //Autocomplete,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import "./text-area.scss";
import {
  notification,
  OperacaoFiscalService,
  //PlanosFinanceirosService,
  //CentroCustoService,
} from "../../../services";
import { userLogs, responseErros, voltar } from "../../../utils";
import operacaoFiscalValidator from "./middlewares/operacao-fiscal.validator";
import { InputErros, value, PermisoesHelper } from "../../../helpers";
import shortid from "shortid";
import { useImportContext } from "../../../contexts/import.context";
import { OperacaoFiscal } from "./entities";

const AtivadoComponent = ({ operacaoFiscal, setOperacaoFiscal }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={operacaoFiscal?.ativado ? operacaoFiscal.ativado : false}
          onChange={() =>
            setOperacaoFiscal((operacao) => {
              return {
                ...operacao,
                ativado: !operacaoFiscal?.ativado,
              };
            })
          }
          name="situacao"
          color="primary"
        />
      }
      label={operacaoFiscal?.ativado ? "Ativado" : "Desativado"}
    />
  );
};

const AddOperacaoFiscal = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("operacoes-fiscais-visualizar");
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [initialOperacaoFiscalState, setInitialOperacaoFiscalState] =
    useState(null);
  const [operacaoFiscal, setOperacaoFiscal] = useState({
    tipoOperacao: "SAIDA",
    ativado: true,
  });
  //const [planosFinaceirosList, setPlanosFinaceirosList] = useState([]);
  //const [centroCustoList, setCentroCustoList] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const operacaoFiscalService = new OperacaoFiscalService();
  //const planosFinanceirosService = new PlanosFinanceirosService();
  //const centroCustoService = new CentroCustoService();

  const menuItem = {
    65: [
      <MenuItem key={shortid.generate()} value={"NFE_NORMAL"}>
        1 - NFE NORMAL
      </MenuItem>,
    ],
    55: [
      <MenuItem key={shortid.generate()} value={"NFE_NORMAL"}>
        1 - NFE NORMAL
      </MenuItem>,
      <MenuItem key={shortid.generate()} value={"NFE_COMPLEMENTAR"}>
        2 - NFE COMPLEMENTAR
      </MenuItem>,
      <MenuItem key={shortid.generate()} value={"NFE_AJUSTE"}>
        3 - NFE DE AJUSTE
      </MenuItem>,
      <MenuItem key={shortid.generate()} value={"DEVOLUCAO"}>
        4 - DEVOLUÇÃO DE MERCADORIA
      </MenuItem>,
    ],
    0: [
      <MenuItem key={shortid.generate()} value={"NAO_SE_APLICA"}>
        0 - NÃO SE APLICA
      </MenuItem>,
      <MenuItem key={shortid.generate()} value={"NFE_NORMAL"}>
        1 - NFE NORMAL
      </MenuItem>,
      <MenuItem key={shortid.generate()} value={"NFE_COMPLEMENTAR"}>
        2 - NFE COMPLEMENTAR
      </MenuItem>,
      <MenuItem key={shortid.generate()} value={"NFE_AJUSTE"}>
        3 - NFE DE AJUSTE
      </MenuItem>,
      <MenuItem key={shortid.generate()} value={"DEVOLUCAO"}>
        4 - DEVOLUÇÃO DE MERCADORIA
      </MenuItem>,
    ],
  };

  const selectedMenu = (modalidade) => {
    if (modalidade === "65") {
      return menuItem[65];
    }
    if (modalidade === "55") {
      return menuItem[55];
    }
    return menuItem[0];
  };

  async function buscarDadosIniciais() {
    await buscaOperacaoFiscal();
    //buscarCentroCusto();
    //buscarPlanosFinaceiros();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  /* const buscarCentroCusto = () => {
    const filtro = {
      nonPaginated: true,
    };
    centroCustoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setCentroCustoList(result.data);
      } else {
        responseErros(result);
      }
    });
  }; */

  /* const buscarPlanosFinaceiros = () => {
    const filtro = {
      nonPaginated: true,
    };
    planosFinanceirosService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setPlanosFinaceirosList(result.data);
      } else {
        responseErros(result);
      }
    });
  }; */

  const buscaOperacaoFiscal = async () => {
    if (!id) return;
    setLoading(true);
    const { data } = await operacaoFiscalService.getById(id);
    if (!data) {
      initialCollapses();
      voltar();
      return;
    }
    setInitialOperacaoFiscalState(data);
    setOperacaoFiscal(data);
    setLoading(false);
  };

  async function onClickLog() {
    if ("userCreatedLog" in userLog) return;
    const logs = await userLogs(operacaoFiscal);
    setUserLog(logs);
  }

  const handleInputChange = (event) => {
    const value = event.target.value;
    const operacaoFiscalTemp = {
      ...operacaoFiscal,
      [event.target.name]: value,
    };
    if (event.target.name === "serieDocumento") {
      operacaoFiscalTemp.serieDocumento = value
        ?.toUpperCase()
        .replace(/[^a-zA-Z0-9]/g, "");
    }
    if (event.target.name === "modeloDocumento" && value === "65") {
      operacaoFiscalTemp.presencaConsumidor = "PRESENCIAL";
      operacaoFiscalTemp.finalidade = "NFE_NORMAL";
    }
    setOperacaoFiscal(operacaoFiscalTemp);
  };

  const handleCancelar = () => {
    setOperacaoFiscal(initialOperacaoFiscalState);
  };

  /* const onChangeAutocomplete = (name, value) => {
    setOperacaoFiscal({
      ...operacaoFiscal,
      [name]: value ? value.id : undefined,
    });
  }; */

  const onChangeCheckbox = (event) => {
    const operacaoFiscalTemp = {
      ...operacaoFiscal,
      [event.target.name]: event.target.checked,
    };
    if (
      event.target.name === "movimentacaoFinanceira" &&
      !event.target.checked
    ) {
      operacaoFiscalTemp.planoFinancerioId = null;
      operacaoFiscalTemp.centroCustoId = null;
    }
    setOperacaoFiscal(operacaoFiscalTemp);
  };

  const handleSubmit = async (indiceSelecionado) => {
    const operacaoFiscalDto = new OperacaoFiscal(operacaoFiscal);
    setLoading(true);
    operacaoFiscalValidator
      .validate(operacaoFiscalDto, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await operacaoFiscalService.atualizar(
            id,
            operacaoFiscalDto
          );
          setLoading(false);
          if (!response.isAxiosError) {
            buscaOperacaoFiscal();
            notification.alteracaoSucesso();
            initialCollapses();
            voltar();
          } else responseErros(response);
        } else {
          const response = await operacaoFiscalService.cadastrar(
            operacaoFiscalDto
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              initialCollapses();
              voltar();
              return;
            }
            if (indiceSelecionado !== 0) handleCancelar();
          } else responseErros(response);
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  return (
    <AddLayout
      id={id}
      title={
        operacaoFiscal?.id
          ? "Editar operação fiscal"
          : "Cadastrar operação fiscal"
      }
      showUpdate={
        permissoesHelper.temPermisao("operacoes-fiscais-editar")
      }
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={operacaoFiscal?.id ? userLog : null}
      onClickLog={onClickLog}
      actions={
        <AtivadoComponent
          operacaoFiscal={operacaoFiscal}
          setOperacaoFiscal={setOperacaoFiscal}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Select
            id="tipoOperacao"
            name="tipoOperacao"
            label="Tipo de Operação"
            value={value.text(operacaoFiscal?.tipoOperacao)}
            onChange={(event) => handleInputChange(event)}
          >
            <MenuItem value={"ENTRADA"}>Entrada</MenuItem>
            <MenuItem value={"SAIDA"}>Saída</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            variant="outlined"
            value={operacaoFiscal?.descricao ? operacaoFiscal.descricao : ""}
            onChange={(e) => {
              setInputErro([]);
              handleInputChange(e);
            }}
            fullWidth
            required
            error={inputErros.get("descricao")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="naturezaOperacao"
            name="naturezaOperacao"
            label="Natureza da Operação"
            variant="outlined"
            required
            error={inputErros.get("naturezaOperacao")}
            InputProps={{ inputProps: { maxLength: 60 } }}
            value={value.text(operacaoFiscal?.naturezaOperacao)}
            onChange={(event) => handleInputChange(event)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={3}>
          <Select
            id="modeloDocumento"
            name="modeloDocumento"
            label="Modelo Documento"
            value={value.text(operacaoFiscal?.modeloDocumento)}
            onChange={(event) => handleInputChange(event)}
            required
            error={inputErros.get("modeloDocumento")}
          >
            <MenuItem value={"01"}>01 - Nota Fiscal</MenuItem>
            <MenuItem value={"55"}>55 - Nota Fiscal Eletrônica (NF-e)</MenuItem>
            <MenuItem value={"65"}>
              65 - Nota Fiscal Eletrônica ao Consumidor
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          {operacaoFiscal.modeloDocumento !== "65" ? (
            <Select
              id="presencaConsumidor"
              name="presencaConsumidor"
              label="Presença consumidor"
              value={value.text(operacaoFiscal?.presencaConsumidor)}
              onChange={(event) => handleInputChange(event)}
              required
              error={inputErros.get("presencaConsumidor")}
            >
              <MenuItem value={"NAO_SE_APLICA"}>Não se aplica</MenuItem>
              <MenuItem value={"PRESENCIAL"}>Presencial</MenuItem>
              <MenuItem value={"INTERNET"}>Internet</MenuItem>
              <MenuItem value={"TELEATENDIMENTO"}>Teleatendimento</MenuItem>
              <MenuItem value={"NFCE_ENTREGA_DOMICILIO"}>
                NFC-e entrega Domicilio
              </MenuItem>
              <MenuItem value={"PRESENCIAL_FORA_ESTABELECIMENTO"}>
                Presencial fora Estabelecimento
              </MenuItem>
              <MenuItem value={"OUTROS"}>Outros</MenuItem>
            </Select>
          ) : (
            <Select
              id="presencaConsumidor"
              name="presencaConsumidor"
              label="Presença consumidor"
              value={value.text(operacaoFiscal?.presencaConsumidor)}
              onChange={(event) => handleInputChange(event)}
            >
              <MenuItem value={"PRESENCIAL"}>Presencial</MenuItem>
            </Select>
          )}
        </Grid>
        <Grid item xs={3}>
          <Select
            label="Finalidade"
            id="finalidade"
            name="finalidade"
            value={value.text(operacaoFiscal?.finalidade)}
            onChange={(event) => handleInputChange(event)}
            required
            error={inputErros.get("finalidade")}
          >
            {selectedMenu(operacaoFiscal.modeloDocumento)}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="serieDocumento"
            name="serieDocumento"
            label="Série Documento"
            variant="outlined"
            value={value.text(operacaoFiscal?.serieDocumento)}
            onChange={handleInputChange}
            fullWidth
            InputProps={{
              inputProps: {
                maxLength: 3,
                style: { textTransform: "uppercase" },
              },
            }}
            required={operacaoFiscal?.tipoOperacao === "SAIDA" ? true : false}
            error={inputErros.get("serieDocumento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={3} className="d-flex align-items-center">
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  operacaoFiscal?.naoGerarComissao
                    ? operacaoFiscal.naoGerarComissao
                    : false
                }
                value={operacaoFiscal?.naoGerarComissao}
                onChange={(event) => onChangeCheckbox(event)}
                name="naoGerarComissao"
                id="naoGerarComissao"
                color="primary"
              />
            }
            label="Não Gerar Comissão"
          />
        </Grid>
        <Grid item xs={3} className="d-flex align-items-center">
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  operacaoFiscal?.movimentacaoEstoque
                    ? operacaoFiscal.movimentacaoEstoque
                    : false
                }
                value={operacaoFiscal?.movimentacaoEstoque}
                onChange={(event) => onChangeCheckbox(event)}
                name="movimentacaoEstoque"
                id="movimentacaoEstoque"
                color="primary"
              />
            }
            label="Movimentação Estoque"
          />
        </Grid>
        <Grid item xs={3} className="d-flex align-items-center">
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  operacaoFiscal?.movimentacaoFinanceira
                    ? operacaoFiscal.movimentacaoFinanceira
                    : false
                }
                value={operacaoFiscal?.movimentacaoFinanceira}
                onChange={(event) => onChangeCheckbox(event)}
                name="movimentacaoFinanceira"
                id="movimentacaoFinanceira"
                color="primary"
              />
            }
            label="Movimentação Financeira"
          />
        </Grid>
        {/* <Grid item xs={3} className="d-flex align-items-center">
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  (operacaoFiscal?.modeloDocumento === "65" ||
                    operacaoFiscal?.modeloDocumento === "01") &&
                  operacaoFiscal?.finalidade === "NFE_NORMAL"
                    ? false
                    : true
                }
                checked={
                  operacaoFiscal?.disponivelPdv
                    ? operacaoFiscal.disponivelPdv
                    : false
                }
                value={operacaoFiscal?.disponivelPdv}
                onChange={(event) => onChangeCheckbox(event)}
                name="disponivelPdv"
                id="disponivelPdv"
                color="primary"
              />
            }
            label="Disponível no PDV"
          />
        </Grid> */}
      </Grid>
      {/*<Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <Autocomplete
            id="planoFinancerioId"
            name="planoFinancerioId"
            options={planosFinaceirosList}
            noOptionsText="Sem opções"
            getOptionLabel={(option) => (option ? option?.descricao : "")}
            autoHighlight
            disabled={!operacaoFiscal?.movimentacaoFinanceira ? true : false}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Plano Financeiro"}
                variant={
                  !operacaoFiscal?.movimentacaoFinanceira
                    ? "filled"
                    : "outlined"
                }
              />
            )}
            onChange={(e, value) =>
              onChangeAutocomplete("planoFinancerioId", value)
            }
            value={value.autoComplete(
              planosFinaceirosList,
              operacaoFiscal?.planoFinancerioId
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="centroCustoId"
            name="centroCustoId"
            options={centroCustoList}
            noOptionsText="Sem opções"
            autoHighlight
            disabled={!operacaoFiscal?.movimentacaoFinanceira ? true : false}
            getOptionLabel={(option) => (option ? option?.descricao : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Centro de Custo"}
                variant={
                  !operacaoFiscal?.movimentacaoFinanceira
                    ? "filled"
                    : "outlined"
                }
              />
            )}
            onChange={(e, value) =>
              onChangeAutocomplete("centroCustoId", value)
            }
            value={value.autoComplete(
              centroCustoList,
              operacaoFiscal?.centroCustoId
            )}
          />
        </Grid>
      </Grid> */}
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <p style={{ color: "#757575" }}>Info. Complementar</p>
          <TextareaAutosize
            className="textRolagem bg-transparent"
            id="informacaoComplementar"
            name="informacaoComplementar"
            variant="outlined"
            maxLength={300}
            value={value.text(operacaoFiscal?.informacaoComplementar)}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <p style={{ color: "#757575" }}>Info. Fiscal</p>
          <TextareaAutosize
            id="informacaoFiscal"
            name="informacaoFiscal"
            className="textRolagem bg-transparent"
            variant="outlined"
            maxLength={300}
            value={value.text(operacaoFiscal?.informacaoFiscal)}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </AddLayout>
  );
};

export default AddOperacaoFiscal;
