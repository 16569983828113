import { useState, useEffect } from "react";
import { GrupoPessoasService } from "../../../../services";
import {
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  CurrencyInput,
} from "../../../../components";
import { value } from "../../../../helpers";
import { responseErros, unformatPrice } from "../../../../utils";

export default function FormFornecedor({
  fornecedorFormRef,
  fornecedor,
  setFornecedor,
  habilitado,
}) {
  const [listaGrupoPessoa, setListaGrupoPessoa] = useState([]);
  const grupoPessoasService = new GrupoPessoasService();

  useEffect(() => {
    buscaGruposPessoas();
  }, []);

  const buscaGruposPessoas = async () => {
    const filtros = {
      ativado: true,
      tipoPessoa: 2,
      restritiva: true,
    };
    const res = await grupoPessoasService.getAllFiltroAvancado(filtros);
    if (!res.isAxiosError) {
      setListaGrupoPessoa(res.data.rows);
    } else {
      responseErros(res);
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    setFornecedor({
      ...fornecedor,
      [name]: value ? value.id : null,
    });
  };

  const handleInputBlur = (e) => {
    setFornecedor((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  return (
    <form ref={fornecedorFormRef}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <CurrencyInput
            label="Limite de compra"
            name="limiteCompra"
            margin="normal"
            disabled={habilitado ? false : true}
            variant={habilitado ? "outlined" : "filled"}
            onBlur={handleInputBlur}
            value={fornecedor.limiteCompra ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="grupoPessoaId"
              name="grupoPessoaId"
              disabled={habilitado ? false : true}
              options={listaGrupoPessoa}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              filterSelectedOptions
              onChange={(_, value) => {
                handleChangeAutocomplete("grupoPessoaId", value);
              }}
              value={value.autoComplete(
                listaGrupoPessoa,
                fornecedor?.grupoPessoaId
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={habilitado ? "outlined" : "filled"}
                  label="Grupos de Pessoas"
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
