import { useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
} from "../../../../components";
import { PessoaService } from "../../../../services";
import { formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";

const pessoaService = new PessoaService();

const FormFiltroAvancadoComissaoVendedores = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const inputRef = useRef();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [vendedorList, setVendedorList] = useState([]);
  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.nomeRazaoSocial ?? value.descricao : undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("De", "Até")] = format(date, "dd/MM/yyyy");
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Até", "De")] = format(date, "dd/MM/yyyy");
      }
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
    setLoadingAutoComplete(false);
  };

  useEffect(() => {
    buscarVendedores();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="vendedorId"
              name="Vendedor"
              options={vendedorList}
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Vendedor"
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("vendedorId", value, "Vendedor")
              }
              value={valueAutocomplete(vendedorList, "vendedorId")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStart"
            name="Data-Emissão-De"
            label="Data Emissão De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoStart")}
            onChange={(date) =>
              onChangeDate("dataEmissaoStart", date, "Data-Emissão-De")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEnd"
            name="Data-Emissão-Até"
            label="Data Emissão Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoEnd")}
            onChange={(date) =>
              onChangeDate("dataEmissaoEnd", date, "Data-Emissão-Até")
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoComissaoVendedores;
