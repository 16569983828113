import { useState } from "react";
import { Autocomplete as AutocompleteMui } from "@mui/material";

const Autocomplete = ({
  options,
  renderInput,
  autoComplete,
  autoHighlight,
  autoSelect,
  blurOnSelect,
  ChipProps,
  classes,
  clearIcon,
  clearOnBlur,
  clearOnEscape,
  clearText,
  closeText,
  componentsProps,
  defaultValue,
  disableClearable,
  disableCloseOnSelect,
  disabled,
  getOptionDisabled,
  disabledItemsFocusable,
  disableListWrap,
  disablePortal,
  filterOptions,
  filterSelectedOptions,
  forcePopupIcon,
  freeSolo,
  fullWidth,
  getLimitTagsText,
  getOptionLabel,
  groupBy,
  handleHomeEndKeys,
  id,
  includeInputInList,
  inputValue,
  limitTags,
  ListboxComponent,
  ListboxProps,
  loading,
  loadingText,
  multiple,
  noOptionsText,
  onChange,
  onHighlightChange,
  onInputChange,
  openOnFocus,
  openText,
  PaperComponent,
  PopperComponent,
  popupIcon,
  readOnly,
  renderGroup,
  renderOption,
  renderTags,
  selectOnFocus,
  size,
  slotProps,
  sx,
  value,
  name,
  className,
  ref,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  return (
    <AutocompleteMui
      options={options}
      renderInput={renderInput}
      autoComplete={autoComplete ?? false}
      autoHighlight={autoHighlight ?? false}
      autoSelect={autoSelect ?? false}
      blurOnSelect={blurOnSelect ?? false}
      ChipProps={ChipProps}
      classes={classes}
      clearIcon={clearIcon}
      clearOnBlur={clearOnBlur}
      clearOnEscape={clearOnEscape ?? false}
      clearText={clearText ?? "Clear"}
      closeText={closeText ?? "Close"}
      componentsProps={componentsProps}
      defaultValue={multiple || defaultValue ? defaultValue ?? [] : null}
      disableClearable={disableClearable ?? false}
      disableCloseOnSelect={disableCloseOnSelect ?? false}
      disabled={disabled ?? false}
      getOptionDisabled={getOptionDisabled}
      disabledItemsFocusable={disabledItemsFocusable ?? false}
      disableListWrap={disableListWrap ?? false}
      disablePortal={disablePortal ?? false}
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions ?? false}
      forcePopupIcon={forcePopupIcon ?? "auto"}
      freeSolo={freeSolo ?? false}
      fullWidth={fullWidth ?? true}
      getLimitTagsText={getLimitTagsText}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      handleHomeEndKeys={handleHomeEndKeys ? handleHomeEndKeys : !freeSolo}
      id={id}
      includeInputInList={includeInputInList ?? false}
      inputValue={inputValue}
      limitTags={limitTags ?? -1}
      ListboxComponent={ListboxComponent ?? "ul"}
      ListboxProps={ListboxProps}
      loading={loading ?? false}
      loadingText={loadingText ?? "Carregando..."}
      multiple={multiple ?? false}
      noOptionsText={noOptionsText ?? "Sem opções"}
      onChange={onChange}
      onHighlightChange={onHighlightChange}
      onInputChange={onInputChange}
      onOpen={handleOpen}
      open={open}
      onClose={() => setOpen(false)}
      openOnFocus={openOnFocus ?? false}
      openText={openText ?? "Abrir"}
      PaperComponent={PaperComponent}
      PopperComponent={PopperComponent}
      popupIcon={popupIcon}
      readOnly={readOnly ?? false}
      renderGroup={renderGroup}
      renderOption={renderOption}
      renderTags={renderTags}
      selectOnFocus={selectOnFocus ? selectOnFocus : !freeSolo}
      size={size ?? "medium"}
      slotProps={slotProps ?? {}}
      sx={sx}
      value={value || null}
      name={name}
      className={className}
      ref={ref}
    />
  );
};

export default Autocomplete;
