import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { IconButton } from "../../components";

const ImpressoraList = ({ MainPrintLayout }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
            @page {
              size: auto;
              margin: 8mm;
            }
          `,
  });

  return (
    <>
      <div className="d-none">
        <div ref={componentRef}>{MainPrintLayout}</div>
      </div>
      <IconButton onClick={handlePrint}>
        <i className="ph-fill ph-file-pdf" style={{ fontSize: 18 }}></i>
      </IconButton>
    </>
  );
};

export default ImpressoraList;
