import { format } from "date-fns";
import { TablePrintResumidoComponent } from "../../../../components";
import { formatDate, formatNomes, formatPrice } from "../../../../utils";

const PrintNfEntradaResumido = ({
  tituloRelatorio,
  relatoriosTotais,
  filtrosAplicadosImpressao,
  print,
}) => {
  const columns = [
    {
      header: "Doc.",
      render: ({ serie, numeroNota }) =>
        `${serie.substring(0, 3)}/${numeroNota}`,
    },
    {
      header: "Fornecedor",
      field: "fornecedor",
      render: ({ fornecedor }) =>
        fornecedor && formatNomes(fornecedor.substring(0, 30)),
    },
    {
      header: "Data Emi.",
      render: ({ dataEmissao }) =>
        format(formatDate.received(dataEmissao), "dd/MM/yyyy"),
    },
    {
      header: "Data Cheg.",
      render: ({ dataChegada }) =>
        format(formatDate.received(dataChegada), "dd/MM/yyyy"),
    },
    {
      header: "Valor Merc.",
      field: "valorMercadoria",
      render: ({ valorMercadoria }) => formatPrice(valorMercadoria, false),
      fieldTotal: "totalValorMecadoria",
    },
    {
      header: "Frete",
      field: "frete",
      render: ({ frete }) => formatPrice(frete, false),
      fieldTotal: "totalFrete",
    },
    {
      header: "Acrésc.",
      field: "acrescimos",
      render: ({ acrescimos }) => formatPrice(acrescimos, false),
      fieldTotal: "totalAcrescimos",
    },
    {
      header: "Despe.",
      field: "despesas",
      render: ({ despesas }) => formatPrice(despesas, false),
      fieldTotal: "totalDespesas",
    },
    {
      header: "Desc.",
      field: "descontos",
      render: ({ descontos }) => formatPrice(descontos, false),
      fieldTotal: "totalDescontos",
    },
    {
      header: "Total",
      field: "total",
      render: ({ total }) => formatPrice(total, false),
      fieldTotal: "total",
    },
  ];

  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.nfEntrada}
      totais={relatoriosTotais.totalizadores}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={31}
    />
  );
};

export default PrintNfEntradaResumido;
