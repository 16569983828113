import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

import { Loading } from "../../../components";

require("highcharts/modules/full-screen")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

export default function BarChart({ loading, data, options }) {
  const chartOptions = {};

  _.merge(
    chartOptions,
    {
      title: "",
      chart: {
        marginTop: 20,
        marginBottom: 80,
        backgroundColor: null,
        style: {
          fontFamily: "'Unica One', sans-serif",
        },
        height: 300,
        events: {
          render: function () {
            const chart = this;
            chart.renderTo.style.width = "100%";
          },
        },
        plotBorderColor: "#606063",
      },
      credits: {
        enabled: false,
      },
      legend: {
        // itemDistance: 80,
        itemStyle: {
          color: "#505053",
        },
        itemHoverStyle: {
          color: "#505053",
        },
        itemHiddenStyle: {
          color: "#606063",
        },
        title: {
          style: {
            color: "#505053",
          },
        },
      },
      plotOptions: {
        candlestick: {
          lineColor: "#2196F3",
        },
      },
      series: [],
      xAxis: {
        title: {
          text: "",
          style: {
            color: "#505053",
          },
        },
        categories: [],
        crosshair: true,
        gridLineColor: "#ccc",
        labels: {
          style: {
            color: "#505053",
          },
        },
        lineColor: "#999",
        minorGridLineColor: "#505053",
        tickColor: "#2196F3",
      },
      yAxis: {
        title: {
          text: "",
          style: {
            color: "#505053",
          },
        },
        gridLineColor: "#ccc",
        labels: {
          // format: 'R$ {value}',
          style: {
            color: "#505053",
          },
        },
        lineColor: "#2196F3",
        minorGridLineColor: "#505053",
        tickColor: "#2196F3",
      },
    },
    options
  );

  chartOptions.series = loading || data === null ? [] : [...data.series];
  chartOptions.xAxis.categories =
    loading || data === null ? [] : [...data.categories];

  return (
    <>
      {loading || data === null ? (
        <Loading loading={loading} />
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          style={{ margin: 20, height: 150, width: "100%" }}
        />
      )}
    </>
  );
}
