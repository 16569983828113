import { useState } from "react";
import {
  Grid,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "../../../../components";
import { notification, NfSaidaService, history } from "../../../../services";
import { value } from "../../../../helpers";
import { responseErros } from "../../../../utils";
import { useImportContext } from "../../../../contexts/import.context";

const NfeComplementarDialog = ({
  openNfeComplementarDialog,
  setOpenNfeComplementarDialog,
  setLoading,
  setNfSaida,
  nfSaida,
}) => {
  const { setNfSaidaDto } = useImportContext();
  const [nfeComplementar, setNfeComplementar] = useState({});
  const nfSaidaService = new NfSaidaService();

  const buscarNfSaida = async (event) => {
    event.preventDefault();
    setLoading(true);
    const filter = {
      numeroDocumento: nfeComplementar.numeroDocumento,
      serieDocumento: nfeComplementar.serieDocumento,
      porIntervalo: [],
      restritiva: true,
    };
    const result = await nfSaidaService.getAllFiltroAvancado(filter);
    if (!result.isAxiosError) {
      if (result.data.rows.length) {
        const documento = result.data.rows.find(
          (documentoItem) =>
            documentoItem.numeroDocumento == filter.numeroDocumento &&
            documentoItem.serieDocumento == filter.serieDocumento &&
            documentoItem.chaveAcesso != null
        );
        if (!documento) {
          notification.alertaGenericos("Documento sem chave de acesso");
        } else {
          setNfSaida({
            ...documento,
            finalidade: nfSaida.finalidade,
          });
        }
      } else {
        notification.alertaGenericos("Nenhum documento encontrado");
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleNfeComplementar = () => {
    delete nfSaida.operacaoFiscalId;
    setNfSaidaDto({
      ...nfSaida,
      dataEmissao: null,
      numeroDocumento: null,
      serieDocumento: null,
      chaveAcesso: null,
      chaveAcessoReferencia: nfSaida.chaveAcesso,
      nfSaidaItens: [],
      nfSaidaPagamentos: [],
      documentoImportado: true,
    });
    setOpenNfeComplementarDialog(false);
    history.push("/faturamento/nf-saida/create");
  };

  const handleInputChange = (event) => {
    setNfeComplementar((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Dialog fullWidth maxWidth="md" open={openNfeComplementarDialog}>
      <DialogContent>
        <CardHeader
          title={
            nfSaida.finalidade == "finalNfeComplementar"
              ? "NF-e Complementar"
              : "NF-e de Ajuste"
          }
        />
        <form onSubmit={(event) => buscarNfSaida(event)}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                id="serieDocumento"
                name="serieDocumento"
                label="Número de Série"
                variant="outlined"
                required
                type={"number"}
                onChange={(event) => handleInputChange(event)}
                value={value.text(nfeComplementar?.serieDocumento)}
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="numeroDocumento"
                name="numeroDocumento"
                label="Número da NF-e"
                required
                variant="outlined"
                type="number"
                onChange={(event) => handleInputChange(event)}
                value={value.text(nfeComplementar?.numeroDocumento)}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} className="d-flex justify-content-center">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                <i
                  className="ph-bold ph-magnifying-glass"
                  style={{ color: "#fff", fontSize: 18, marginRight: 8 }}
                ></i>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12}>
            <TextField
              id="chaveAcesso"
              name="chaveAcesso"
              variant="outlined"
              disabled
              label="Chave de Acesso"
              value={value.text(nfSaida?.chaveAcesso)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="m-3">
        <Button
          variant="contained"
          color="padrao"
          onClick={() => setOpenNfeComplementarDialog(false)}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleNfeComplementar()}
        >
          Avançar
          <i
            className="ph-fill ph-caret-right"
            style={{ color: "#ffff", fontSize: 18 }}
          ></i>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NfeComplementarDialog;
