import { TextField as MuiTextField } from "@mui/material";

export default function Select({
  id,
  autoFocus,
  children,
  name,
  label,
  variant,
  margin,
  value,
  error,
  style,
  disabled,
  type,
  className,
  onChange,
  fullWidth,
  InputLabelProps,
  InputProps,
  inputProps,
  required,
  onFocus,
  onBlur,
  size,
  sx,
  placeholder,
  ...rest
}) {
  return (
    <MuiTextField
      id={id}
      name={name}
      label={label}
      autoFocus={autoFocus ?? false}
      className={className}
      disabled={disabled ?? false}
      type={type}
      variant={variant || "outlined"}
      margin={margin || "none"}
      value={value}
      onChange={onChange}
      select={true}
      error={error}
      style={style}
      size={size}
      sx={sx}
      onFocus={onFocus}
      onBlur={onBlur}
      fullWidth={fullWidth ?? true}
      inputProps={inputProps}
      InputLabelProps={{
        shrink: value !== null || value !== undefined ? true : false,
        ...InputLabelProps,
      }}
      InputProps={InputProps}
      required={required}
      placeholder={placeholder}
      {...rest}
    >
      {children}
    </MuiTextField>
  );
}
