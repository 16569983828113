import { useState } from "react";
import {
  MenuItem,
  MenuList,
  Grow,
  Popper,
  ClickAwayListener,
  Paper,
  ButtonGroup,
  Button,
  Loading,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
} from "../../components";
import "./add.css";
import { useImportContext } from "../../contexts/import.context";
import { voltar } from "../../utils";
import Mousetrap from "mousetrap";

const AddLayout = ({
  id,
  showUpdate,
  formSubmit,
  onClickSalvar,
  onClickLog,
  loading,
  title,
  userLog,
  ButtonMenu,
  actions,
  isFullscreenDialog,
  children,
  actionsTotalizadores,
  submitSimples,
  formState,
  resetFormFunction,
}) => {
  const opcoes = ["SALVAR E ADICIONAR NOVO"];
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { initialDtos, initialCollapses } = useImportContext();

  const handleClick = () => {
    onClickSalvar(0);
    setOpen(false);
  };

  const handleSubmit = (e) => {
    if (formSubmit) {
      document.getElementById("form-button")?.click();
      return;
    }
    id ? onClickSalvar(e) : handleClick();
  };

  const handleMenuClick = () => {
    onClickSalvar(1);
    setOpen(false);
  };

  const handleToggle = (event) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const voltarInitial = async () => {
    initialDtos();
    initialCollapses();
    resetFormFunction?.();
    await voltar(formState);
  };

  const atalhosAddLayout = [
    {
      keys: ["alt+left"],
      action: async (e) => {
        e.preventDefault();
        await voltar(formState);
        return false;
      },
    },
    {
      keys: ["f5"],
      action: async (e) => {
        e.preventDefault();
        await voltar(formState, true);
        return false;
      },
    },
  ];

  atalhosAddLayout.map(({ keys, action }) => Mousetrap.bind(keys, action));

  Mousetrap.stopCallback = function () {
    return false;
  };

  return (
    <Container container className={`${isFullscreenDialog ? "p-4" : ""}`}>
      <Card>
        <CardHeader
          title={title}
          action={
            <Grid container spacing={2} alignItems="center" className="m-3">
              {actions}
              {ButtonMenu}
            </Grid>
          }
          botaoVoltar={!isFullscreenDialog}
          onClickVoltar={voltarInitial}
          userLog={userLog}
          onClickLog={onClickLog}
        />
        <CardContent className="rolagem">{children}</CardContent>
        <CardActions>
          {id ? (
            showUpdate ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                className="button-add"
                color="primary"
              >
                Atualizar
              </Button>
            ) : (
              <Grid sx={{ width: "5em" }} />
            )
          ) : (
            <ButtonGroup variant="contained" color="primary">
              <Button onClick={handleSubmit} className="button-add">
                SALVAR
              </Button>
              {!submitSimples && (
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <i
                    className="ph-fill ph-caret-down"
                    style={{ color: "#fff", fontSize: 15 }}
                  ></i>
                </Button>
              )}
            </ButtonGroup>
          )}
          <Popper
            open={open}
            anchorEl={anchorEl}
            sx={{ zIndex: 500 }}
            placement="top"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem>
                      {opcoes.map((option) => (
                        <MenuItem key={option} onClick={handleMenuClick}>
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {actionsTotalizadores}
        </CardActions>
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

export default AddLayout;
