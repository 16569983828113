import {
  Grid,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  Checkbox,
  MaskedInput,
} from "../../../../components";

const CadastroBasico = ({
  empresa,
  inputErros,
  handleInputChange,
  handleIeIsento,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <MaskedInput
            id="cnpjCpf"
            name="cnpjCpf"
            label={empresa.naturezaSocial === "juridica" ? "CNPJ" : "CPF"}
            mask={
              empresa.naturezaSocial === "juridica"
                ? "99.999.999/9999-99"
                : "999.999.999-99"
            }
            variant="filled"
            value={empresa?.cnpjCpf ? empresa.cnpjCpf : ""}
            onChange={(e) => {
              handleInputChange(e);
            }}
            required
            disabled
            error={inputErros.get("cnpjCpf")}
          />
        </Grid>
        {empresa.naturezaSocial === "juridica" ? (
          <Grid item xs={4}>
            <TextField
              id="incricao-estadual"
              name="inscricaoEstadual"
              label={"Inscrição Estadual (IE)"}
              variant={empresa?.inscricaoEstadualIsento ? "filled" : "outlined"}
              margin="normal"
              value={
                empresa?.inscricaoEstadual && !empresa?.inscricaoEstadualIsento
                  ? empresa.inscricaoEstadual
                  : ""
              }
              onChange={(e) => {
                handleInputChange(e);
              }}
              type="number"
              fullWidth
              required={!empresa?.inscricaoEstadualIsento}
              disabled={empresa?.inscricaoEstadualIsento}
              error={inputErros.get("inscricaoEstadual")}
            />
          </Grid>
        ) : (
          <Grid item xs={4}>
            <TextField
              id="registro-geral"
              name="registroGeral"
              label="Registro Geral (RG)"
              variant="outlined"
              margin="normal"
              value={empresa?.registroGeral ? empresa.registroGeral : ""}
              onChange={(e) => {
                handleInputChange(e);
              }}
              fullWidth
              error={inputErros.get("registroGeral")}
            />
          </Grid>
        )}
        {empresa.naturezaSocial === "juridica" ? (
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={empresa?.inscricaoEstadualIsento}
                  onChange={handleIeIsento}
                  name="inscricaoEstadualIsento"
                  color="primary"
                  sx={{ m: "1.5em" }}
                />
              }
              label="IE Isento"
              required
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="cnae"
            name="cnae"
            label="CNAE Principal"
            variant="outlined"
            margin="normal"
            value={empresa?.cnae ? empresa.cnae : ""}
            onChange={(e) => {
              handleInputChange(e);
            }}
            InputProps={{
              inputProps: { min: 0, maxLength: 7 },
            }}
            fullWidth
            required
            error={inputErros.get("cnae")}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            id="regime-tributario"
            name="regimeTributario"
            label="Regime Tributário"
            margin="normal"
            value={empresa?.regimeTributario ? empresa.regimeTributario : "mei"}
            onChange={handleInputChange}
            required
          >
            <MenuItem value={"SimplesNacional"}>Simples Nacional</MenuItem>
            <MenuItem value={"SimplesNacionalExcessoSublimite"}>
              Simples Nacional Excesso Sublimite
            </MenuItem>
            <MenuItem value={"RegimeNormal"}>Regime Normal</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="email"
            name="email"
            label="E-mail"
            variant="outlined"
            margin="normal"
            value={empresa?.email ? empresa.email : ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <MaskedInput
            id="telefone"
            name="telefone"
            label="Telefone"
            mask="(99) 9999-9999"
            value={empresa?.telefone ? empresa.telefone : ""}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={3}>
          <MaskedInput
            id="celular"
            name="celular"
            label="Celular"
            mask="(99) 9 9999-9999"
            value={empresa?.celular ? empresa.celular : ""}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CadastroBasico;
