import { RelatoriosDialog } from "../../../components";

const VisualizarRelatorioDialog = ({
  setAbrirDialogoVisualizarRelatorio,
  abrirDialogoVisualizarRelatorio,
  propriedadeRelatorio,
  filtrosAplicadosImpressao,
  dadosRelatorioResumido,
  setOpenFiltroAvancadoDialog,
}) => {
  const handleClose = () => {
    setAbrirDialogoVisualizarRelatorio(false);
    setOpenFiltroAvancadoDialog(true);
  };

  return (
    <>
      <RelatoriosDialog
        open={abrirDialogoVisualizarRelatorio}
        handleClose={handleClose}
        tituloRelatorio={propriedadeRelatorio.name}
        filtrosAplicadosImpressao={filtrosAplicadosImpressao}
        relatoriosTotais={dadosRelatorioResumido}
        content={
          <propriedadeRelatorio.layoutImpressao
            tituloRelatorio={propriedadeRelatorio.name}
            filtrosAplicadosImpressao={filtrosAplicadosImpressao}
            relatoriosTotais={dadosRelatorioResumido}
          />
        }
      />
    </>
  );
};

export default VisualizarRelatorioDialog;
