/* eslint-disable no-undef */
const packageJson = require("../../package.json");

export default {
  baseUrl: process.env.REACT_APP_URL_SIAF_LITE_API,
  baseUrlNfe: process.env.REACT_APP_URL_SIAF_LITE_NFE,
  baseUrlApiConsultaCpfCnpj: process.env.REACT_APP_URL_API_CONSULTA_CPF_CNPJ,
  notificationSocketUrl: process.env.REACT_APP_NOTIFICACAO_SOCKET_URL,
  keyRecaptcha: process.env.REACT_APP_KEY_RECAPTCHA,
  enviroment: process.env.REACT_APP_ENVIROMENT,
  urlPdv: process.env.REACT_APP_URL_PDV,
  version: packageJson.version,
  publicKey: `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDlOJu6TyygqxfWT7eLtGDwajtN
  FOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76
  xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4
  gwQco1KRMDSmXSMkDwIDAQAB
  -----END PUBLIC KEY-----`,
};
