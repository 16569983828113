import * as Yup from "yup";

const grupoTributarioModeloTributacaoValidator = Yup.object().shape({
  tipoOperacao: Yup.string().required("Tipo Operação"),
  estadosId: Yup.array().min(1, "UF Destino").required("UF Destino"),
  operacoesFiscalId: Yup.array()
    .min(1, "Operação Fiscal")
    .required("Operação Fiscal"),
  grupoTributarioId: Yup.number().required("Grupo Tributario"),
  csoSimplesNacional: Yup.string().required("CSO"),
  cfopId: Yup.number().required("CFOP"),
});

export default grupoTributarioModeloTributacaoValidator;
