import { Checkbox as CheckboxMui } from "@mui/material";

const Checkbox = ({
  children,
  checked,
  defaultChecked,
  checkedIcon,
  className,
  classes,
  color,
  disabled,
  disableRipple,
  icon,
  indeterminate,
  indeterminateIcon,
  inputProps,
  name,
  onChange,
  onClick,
  required,
  size,
  sx,
  value,
}) => {
  return (
    <CheckboxMui
      checked={checked}
      checkedIcon={checkedIcon}
      defaultChecked={defaultChecked}
      className={className}
      classes={classes}
      color={color}
      disabled={disabled}
      disableRipple={disableRipple}
      icon={icon}
      indeterminate={indeterminate}
      indeterminateIcon={indeterminateIcon}
      inputProps={inputProps}
      name={name}
      onChange={onChange}
      onClick={onClick}
      required={required}
      size={size}
      sx={sx}
      value={value}
    >
      {children}
    </CheckboxMui>
  );
};

export default Checkbox;
