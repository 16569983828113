import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Autocomplete,
  FormControl,
  FormControlLabel,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  IconButton,
  Button,
  DataGrid,
  MaskedInput,
  Loading,
} from "../../../../components";
import {
  EnderecoService,
  notification,
  PessoaService,
} from "../../../../services";
import { enderecoValidator } from "../middlewares";
import { value } from "../../../../helpers";
import { cacheApi, responseErros } from "../../../../utils";
import shortid from "shortid";

export default function Enderecos(props) {
  const colunas = [
    { field: "descricao", headerName: "Descrição", sortable: false, flex: 500 },
    { field: "cep", headerName: "CEP", sortable: false, flex: 500 },
    {
      field: "logradouro",
      headerName: "Endereço",
      sortable: false,
      flex: 1000,
    },
    { field: "numero", headerName: "Número", sortable: false, flex: 500 },
    { field: "bairro", headerName: "Bairro", sortable: false, flex: 500 },
    {
      field: "cidade",
      headerName: "Cidade",
      sortable: false,
      flex: 500,
      valueGetter: (params) => params.row.cidade.nome,
    },
    {
      field: "padrao",
      headerName: "Padrão",
      sortable: false,
      flex: 500,
      valueGetter: (params) => (params.value == true ? "Sim" : "Não"),
    },
  ];
  const [loading, setLoading] = useState(false);
  const enderecoService = new EnderecoService();
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [ufId, setUfId] = useState(null);
  const [cidadeId, setCidadeId] = useState(null);
  const [endereco, setEndereco] = useState({});
  const pessoaService = new PessoaService();

  useEffect(() => {
    buscaEnderecos();
    buscaEstados();
  }, []);

  const buscaEstados = async () => {
    const cacheBody = {
      timeMinutes: 60,
      apiFunction: enderecoService.getEstados(),
      dto: { cacheDto: props.cacheDto, setCacheDto: props.setCacheDto },
    };
    const res = await cacheApi(`${enderecoService.urlBase}/estados`, cacheBody);
    if (!res.isAxiosError) {
      setEstados(res.data);
    } else {
      responseErros(res);
    }
  };

  const buscaEnderecos = () => {
    if (props.id) {
      pessoaService.getEnderecos(props.id).then((res) => {
        if (!res.isAxiosError) {
          props.setEnderecosLista(res.data);
        } else {
          responseErros(res);
        }
      });
    }
  };

  const changeCidadesByEstadoId = async (id) => {
    if (id) {
      enderecoService.getCidades(id).then((response) => {
        if (!response.isAxiosError) {
          if (!response.data) {
            notification.erroValidacao("Preencha um estado");
          } else {
            setCidades(response.data);
          }
        } else {
          responseErros(response);
        }
      });
    }
  };

  const handleEstadoUfInput = async (e, newValue) => {
    e.persist();
    setUfId(newValue?.id || null);
    if (newValue?.id) {
      changeCidadesByEstadoId(newValue.id);
    }
  };

  const handleCidadeInput = (e, newValue) => {
    e.persist();
    setCidadeId(newValue?.id || null);
  };

  const handleClose = () => {
    props.setOpenEndereco(false);
  };

  const onClickEditEnderecos = (id) => {
    const enderecoBusca = props.enderecosLista.find((item) => item.id === id);
    if (enderecoBusca) {
      if (props.id) {
        changeCidadesByEstadoId(enderecoBusca.cidade.estadoId);
        setCidadeId(enderecoBusca.cidade.id);
        setUfId(enderecoBusca.cidade.estadoId);
        setEndereco({
          id: enderecoBusca.id,
          cep: enderecoBusca.cep.toString(),
          logradouro: enderecoBusca.logradouro,
          numero: enderecoBusca.numero,
          bairro: enderecoBusca.bairro,
          complemento: enderecoBusca.complemento,
          descricao: enderecoBusca?.descricao,
          padrao: enderecoBusca.padrao,
        });
      } else {
        changeCidadesByEstadoId(enderecoBusca?.estadoId);
        setEndereco(enderecoBusca);
        setCidadeId(enderecoBusca?.cidadeId);
        setUfId(enderecoBusca?.estadoId);
      }
      props.setOpenEndereco(true);
    }
  };

  const onClickDeleteEndereco = async (id) => {
    const endereco = props.enderecosLista.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir o endereço: ${endereco.descricao}`
    );
    if (retornoAlerta.isConfirmed) {
      if (props.id) {
        setLoading(true);
        const res = await enderecoService.deleteEnderecoPessoa(endereco.id);
        if (!res.isAxiosError) {
          buscaEnderecos();
          notification.deletadoSucesso();
        } else {
          responseErros(res);
        }
        setLoading(false);
      } else {
        props.setEnderecosLista((prevState) => {
          return prevState.filter((endereco) => endereco.id != id);
        });
      }
    }
  };

  const handleClickOpen = async () => {
    setEndereco({});
    setCidadeId(null);
    setUfId(null);
    setCidades([]);
    props.setOpenEndereco(true);
  };

  const handleChangeInput = (event) => {
    setEndereco({
      ...endereco,
      [event.target.name]: event.target.value ? event.target.value : undefined,
    });
  };

  const handleCompletarEndereco = async () => {
    setLoading(true);
    const res = await enderecoService.getByCep(endereco.cep.slice(0, 9));
    if (!res.isAxiosError) {
      setUfId(res.data.estado?.id);
      changeCidadesByEstadoId(res.data.estado?.id);
      setCidadeId(res.data.id);
      setEndereco({
        ...endereco,
        estadoId: res.data.estado?.id,
        bairro: res.data.bairro,
        logradouro: res.data.logradouro,
        cidade: res.data.nome,
        estado: res.data.estado?.sigla,
      });
    } else if (res.response.data.errors.length >= 5) {
      setEndereco({});
      setCidadeId(null);
      setUfId(null);
      setCidades([]);
      notification.alertaGenericos("CEP não encontrado.");
    } else responseErros(res);
    setLoading(false);
  };

  const onClickSalvar = (event) => {
    event.preventDefault();
    if (endereco.id) {
      atualizar();
    } else {
      salvar();
    }
  };

  const atualizar = async () => {
    const body = {
      ...endereco,
      cep: endereco.cep.replace(/[^0-9]/g, ""),
      cidadeId,
    };
    enderecoValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        if (props.id) {
          body.pessoaId = props.id;
          setLoading(true);
          const res = await enderecoService.atualizar(endereco.id, body);
          if (!res.isAxiosError) {
            notification.alteracaoSucesso();
            buscaEnderecos();
            props.setOpenEndereco(false);
          } else {
            responseErros(res);
          }
          setLoading(false);
        } else {
          const cidadeSelecionada = cidades.find(
            (cidade) => cidade.id == cidadeId
          );
          props.setEnderecosLista((prevState) =>
            prevState.map((state) => {
              if (state.id == endereco.id) {
                return {
                  ...body,
                  cidade: cidadeSelecionada.nome,
                };
              }
              return state;
            })
          );
          props.setOpenEndereco(false);
        }
      })
      .catch((err) => {
        props.inputErros?.set(err);
      });
  };

  const salvar = async () => {
    const body = {
      ...endereco,
      cep: endereco.cep.replace(/[^0-9]/g, ""),
      cidadeId,
    };
    enderecoValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        if (props.id) {
          body.pessoaId = props.id;
          setLoading(true);
          const res = await pessoaService.cadastrarEndereco(props.id, body);
          if (!res.isAxiosError) {
            notification.cadastroSucesso();
            buscaEnderecos();
            props.setOpenEndereco(false);
          } else {
            responseErros(res);
          }
          setLoading(false);
        } else {
          const cidadeSelecionada = cidades.find(
            (cidade) => cidade.id == cidadeId
          );
          body.id = shortid.generate();
          const enderecosListaTemp = [
            ...props.enderecosLista,
            {
              ...body,
              cidade: cidadeSelecionada.nome,
            },
          ];
          props.setEnderecosLista(enderecosListaTemp);
          props.setOpenEndereco(false);
        }
      })
      .catch((err) => {
        props.inputErros?.set(err);
      });
  };

  const handleChangeSwitch = (event) => {
    setEndereco({
      ...endereco,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-3">
          <span className="fw-bold fw-bolder">ENDEREÇOS</span>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={props.enderecosLista}
            columns={colunas}
            onClickEdit={(id) => {
              onClickEditEnderecos(id);
            }}
            noFilter
            editShow={true}
            deleteShow={true}
            rowCount={props.enderecosLista.length}
            onClickDelete={(id) => onClickDeleteEndereco(id)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => handleClickOpen()}
            color="primary"
            variant="contained"
          >
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            ></i>{" "}
            Adicionar
          </Button>
        </Grid>
      </Grid>
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth="md"
          open={props.openEndereco}
          onClose={handleClose}
        >
          <form onSubmit={(event) => onClickSalvar(event)}>
            <DialogTitle>Cadastrar Endereço</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <MaskedInput
                    mask="99999-999"
                    id="cep"
                    name="cep"
                    label="CEP"
                    required
                    error={props.inputErros?.get("cep")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Pesquisar CEP"
                            onClick={() => handleCompletarEndereco()}
                          >
                            <i
                              className="ph-bold ph-magnifying-glass"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={endereco.cep ? endereco.cep : ""}
                    onInput={handleChangeInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="logradouro"
                    name="logradouro"
                    label="Endereço"
                    variant="outlined"
                    error={props.inputErros?.get("logradouro")}
                    margin="normal"
                    type="text"
                    value={endereco.logradouro ? endereco.logradouro : ""}
                    onChange={(event) => handleChangeInput(event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="numero"
                    name="numero"
                    label="Número"
                    variant="outlined"
                    error={props.inputErros?.get("numero")}
                    value={endereco.numero ? endereco.numero : ""}
                    onChange={(event) => handleChangeInput(event)}
                    margin="normal"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="bairro"
                    name="bairro"
                    label="Bairro"
                    variant="outlined"
                    required
                    margin="normal"
                    error={props.inputErros?.get("bairro")}
                    value={endereco.bairro ? endereco.bairro : ""}
                    onChange={(event) => handleChangeInput(event)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    variant="outlined"
                    margin="normal"
                    value={endereco.complemento ? endereco.complemento : ""}
                    onChange={(event) => handleChangeInput(event)}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <Autocomplete
                      required
                      id="estado"
                      name="estado"
                      options={estados}
                      noOptionsText="Sem opções"
                      autoHighlight
                      getOptionLabel={(option) =>
                        option.sigla && option.nome
                          ? `${option.sigla} - ${option.nome}`
                          : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="UF"
                          required
                          variant="outlined"
                          error={props.inputErros?.get("bairro")}
                        />
                      )}
                      onChange={(event, newValue) =>
                        handleEstadoUfInput(event, newValue)
                      }
                      value={value.autoComplete(estados, ufId)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="outlined" margin="normal" fullWidth>
                    <Autocomplete
                      required
                      id="cidade"
                      name="cidade"
                      options={cidades}
                      noOptionsText="Sem opções"
                      autoHighlight
                      getOptionLabel={(option) =>
                        option.nome ? option.nome : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cidade"
                          required
                          variant="outlined"
                          error={props.inputErros?.get("cidade")}
                        />
                      )}
                      onChange={(event, newValue) => {
                        handleCidadeInput(event, newValue);
                      }}
                      value={value.autoComplete(cidades, cidadeId)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    variant="outlined"
                    margin="normal"
                    value={endereco?.descricao ? endereco.descricao : ""}
                    onChange={(event) => handleChangeInput(event)}
                    placeholder="APTO, Casa, Trabalho..."
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    label="Padrão"
                    control={
                      <Switch
                        id="padrao"
                        name="padrao"
                        checked={endereco.padrao ? true : false}
                        onChange={handleChangeSwitch}
                        color="primary"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="d-flex flex-row justify-content-start m-3">
              <Button type="submit" variant="contained" color="primary">
                <i
                  className="ph-fill ph-check-circle"
                  style={{ color: "white", fontSize: 22, marginRight: 10 }}
                ></i>
                Salvar
              </Button>
              <Button onClick={() => handleClose()} variant="contained">
                <i
                  className="ph-fill ph-x-circle"
                  style={{ color: "white", fontSize: 22, marginRight: 10 }}
                ></i>
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
      <Loading loading={loading} />
    </>
  );
}
