import { ButtonGroup as ButtonGroupMui } from "@mui/material";

const ButtonGroup = ({
  children,
  className,
  classes,
  color,
  disabled,
  disableElevation,
  disableFocusRipple,
  disableRipple,
  fullWidth,
  size,
  variant,
  sx,
  onClick,
  onFocus,
  onBlur,
  ...rest
}) => {
  return (
    <ButtonGroupMui
      className={className}
      classes={classes}
      color={color}
      disabled={disabled}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      sx={sx}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      {...rest}
    >
      {children}
    </ButtonGroupMui>
  );
};

export default ButtonGroup;
