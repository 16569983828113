import { useState, useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  TextField,
  Grid,
  Card,
  CardContent,
  TextFielDecimalNumbers,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import { BancosCaixasService } from "../../../../services";
import { responseErros } from "../../../../utils";
import "../../configuracoes.style.css";

const ConfiguracaoFinanceiroTab = ({
  configuracao,
  onBlur,
  onChange,
  onChangeAutocomplete,
}) => {
  const bancosCaixasService = new BancosCaixasService();
  const [inputErro, setInputErro] = useState([]);
  const [listaContas, setListaContas] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscarContas();
  }, []);

  const buscarContas = async function () {
    const filtros = {
      nonPaginated: true,
    };
    const result = await bancosCaixasService.getAll(filtros);
    if (!result.isAxiosError) {
      setListaContas(result.data);
    } else {
      responseErros(result);
    }
  };

  return (
    <Card className="p-2">
      <CardContent>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            FIN001 - Conta/Caixa padrão do sistema.
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                id="bancoCaixaId"
                name="bancoCaixaId"
                noOptionsText="Sem opções"
                options={listaContas}
                autoHighlight
                disableClearable
                getOptionLabel={(option) =>
                  option?.nome ? `${option.id}` + ` - ` + `${option.nome}` : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Banco/Caixa"
                    variant="outlined"
                    error={inputErros.get("bancoCaixaId")}
                  />
                )}
                onChange={(e, value) =>
                  onChangeAutocomplete("bancoCaixaId", value)
                }
                value={value.autoComplete(
                  listaContas,
                  configuracao?.bancoCaixaId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            FIN002 - Percentual de juros.
          </Grid>
          <Grid item xs={3}>
            <TextFielDecimalNumbers
              id="jurosPorcentagem"
              name="jurosPorcentagem"
              label="Juros (%)"
              variant="outlined"
              value={configuracao?.jurosPorcentagem ?? 0}
              onBlur={(event) => onBlur(event)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            FIN003 - Percentual de multa.
          </Grid>
          <Grid item xs={3}>
            <TextFielDecimalNumbers
              id="multaPorcentagem"
              name="multaPorcentagem"
              label="Multa (%)"
              variant="outlined"
              value={configuracao?.multaPorcentagem ?? 0}
              onBlur={(event) => onBlur(event)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={9}>
            FIN004 - Carência (em dias) para contabilização de juros/multa.
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="carencia"
              name="carencia"
              label="Carência"
              type="number"
              variant="outlined"
              margin="normal"
              inputProps={{ min: 0 }}
              value={configuracao?.carencia ?? 0}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ConfiguracaoFinanceiroTab;
