export class ConfiguracoesEntity {
  bancoCaixaId = null;
  jurosPorcentagem = null;
  multaPorcentagem = null;
  carencia = null;
  obrigatoriedadeCnpjCpf = null;
  vendaEstoqueNegativo = null;
  margemCalculo = "INVERSA";
  precificacaoNfEntrada = null;
  operacaoFiscalPdvPadraoId = null;
  operacaoFiscalPdvSecundariaId = null;
  impressaoDiretaPdv = false;
  operacaoFiscalComprasPadraoId = null;
  cfopComprasPadraoId = null;
  operacaoFiscalVendasPadraoId = null;
  meioPagamentoInicial = null;
  pagamentosPadroes = [];
  visualizarImpressaoEmissao = null;

  constructor(configuracoes) {
    this.bancoCaixaId = configuracoes.bancoCaixaId;
    this.jurosPorcentagem = configuracoes.jurosPorcentagem;
    this.multaPorcentagem = configuracoes.multaPorcentagem;
    this.carencia = configuracoes.carencia;
    this.obrigatoriedadeCnpjCpf = configuracoes.obrigatoriedadeCnpjCpf;
    this.vendaEstoqueNegativo = configuracoes.vendaEstoqueNegativo;
    this.precificacaoNfEntrada = configuracoes.precificacaoNfEntrada;
    this.operacaoFiscalPdvPadraoId = configuracoes.operacaoFiscalPdvPadraoId;
    this.impressaoDiretaPdv = configuracoes.impressaoDiretaPdv || false;
    this.operacaoFiscalPdvSecundariaId =
      configuracoes.operacaoFiscalPdvSecundariaId;
    this.operacaoFiscalComprasPadraoId =
      configuracoes.operacaoFiscalComprasPadraoId;
    this.cfopComprasPadraoId = configuracoes.cfopComprasPadraoId;
    this.operacaoFiscalVendasPadraoId =
      configuracoes.operacaoFiscalVendasPadraoId;
    this.meioPagamentoInicial = configuracoes.meioPagamentoInicial;
    if (configuracoes.margemCalculo) {
      this.margemCalculo = configuracoes.margemCalculo;
    }
    this.visualizarImpressaoEmissao = configuracoes.visualizarImpressaoEmissao;
  }

  setPagamentosPadroes(listaPagamentoPadrao) {
    if (listaPagamentoPadrao?.length) {
      this.pagamentosPadroes = listaPagamentoPadrao.map((item) => item.id);
    }
  }
}
