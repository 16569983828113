import {
  Grid,
  Select,
  MenuItem,
  TextField,
  DatePicker,
} from "../../../../components";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueDate,
  onChangeDate,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="codigo"
            name="codigo"
            label="Código"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeInput(event)}
            value={valueInput("codigo")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="nome"
            name="nome"
            label="Nome"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={(event) => onChangeInput(event)}
            value={valueInput("nome")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            id="situacao"
            name="situacao"
            value={valueInput("situacao")}
            label="Situação"
            fullWidth
            onChange={(event) => onChangeInput(event)}
          >
            <MenuItem value="">
              <p></p>
            </MenuItem>
            <MenuItem value={"true"}>Ativado</MenuItem>
            <MenuItem value={"false"}>Desativado</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataInicialStart"
            name="dataInicialStart"
            label="Data Inicial De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataInicialStart")}
            onChange={(date) => onChangeDate("dataInicialStart", date)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataInicialEnd"
            name="dataInicialEnd"
            label="Data Inicial Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataInicialEnd")}
            onChange={(date) => onChangeDate("dataInicialEnd", date)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataFinalStart"
            name="dataFinalStart"
            label="Data Final De"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataFinalStart")}
            onChange={(date) => onChangeDate("dataFinalStart", date)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataFinalEnd"
            name="dataFinalEnd"
            label="Data Final Até"
            margin
            format="dd/MM/yyyy"
            value={valueDate("dataFinalEnd")}
            onChange={(date) => onChangeDate("dataFinalEnd", date)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
