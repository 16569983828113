import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Loading,
  MenuCustom,
  Grid,
} from "../../components";

const Minimal = ({ children, loading, ButtonMenu, title, cardAction }) => {
  return (
    <Container>
      {ButtonMenu && (
        <MenuCustom
          className="d-flex justify-content-end"
          ButtonMenu={ButtonMenu}
        />
      )}
      <Card
        className="p-2"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <CardHeader title={title} botaoVoltar={!ButtonMenu} />
        <CardContent className="rolagem">{children}</CardContent>
        {cardAction && (
          <Grid className="d-flex justify-content-end gap-2 mx-3 mb-2">
            {cardAction}
          </Grid>
        )}
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
