import "./footer-totalizadores-detalhado.scss";

export default function FooterTotalizadoresRelatorioCaixa({ totalizadores }) {
  return (
    <footer className="footer-relatorios">
      <table className="table table-borderless table-sm">
        <thead className="table-primary">
          <tr>
            <th colSpan={2} className="p-2">
              Movimento de Caixa - Por meio de pagamento
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="footer-legenda-bold">Meio Pagamento</td>
            <td width="40" className="footer-valor-bold">
              Valor
            </td>
          </tr>
          {totalizadores.map((total, index) => {
            return (
              <tr key={`totalizador-${index}`}>
                <td
                  className={
                    index + 1 === totalizadores.length
                      ? "footer-legenda-bold"
                      : "footer-legenda"
                  }
                >
                  {total.descricao.toUpperCase()}
                </td>
                <td
                  width="40"
                  className={
                    index + 1 === totalizadores.length
                      ? "footer-valor-bold"
                      : "footer-valor"
                  }
                >
                  {parseFloat(total.total).toFixed(2)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </footer>
  );
}
