import {
  TransportadoraXmlStep,
  ProdutosXmlStep,
  FornecedorXmlStep,
  SelecionarArquivoXmlStep,
} from "./steps";

const ImportarXmlStepper = ({
  activeStep,
  arquivoXml,
  setArquivoXml,
  fornecedor,
  transportadora,
  produtos,
  setProdutos,
  produtosNaoCadastrados,
  setProdutosNaoCadastrados,
  inputErros,
}) => {
  switch (activeStep) {
    case 1:
      return <FornecedorXmlStep fornecedor={fornecedor} />;
    case 2:
      return <TransportadoraXmlStep transportadora={transportadora} />;
    case 3:
      return (
        <ProdutosXmlStep
          produtos={produtosNaoCadastrados}
          setProdutos={setProdutosNaoCadastrados}
          inputErros={inputErros}
        />
      );
    case 4:
      return (
        <ProdutosXmlStep
          produtos={produtos}
          setProdutos={setProdutos}
          inputErros={inputErros}
        />
      );
    default:
      return (
        <SelecionarArquivoXmlStep
          arquivoXml={arquivoXml}
          setArquivoXml={setArquivoXml}
        />
      );
  }
};

export default ImportarXmlStepper;
