import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
} from "../../../../components";
import { formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";
import { value } from "../../../../helpers";
import { BancosCaixasService } from "../../../../services";

const FormFiltroAvancadoCaixa = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const bancosCaixasService = new BancosCaixasService();
  const [contaLista, setContaLista] = useState([]);

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.descricao : undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  const buscaContas = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    bancosCaixasService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        if (result.data?.rows) {
          setContaLista(result.data?.rows);
        }
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    buscaContas({});
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DatePicker
          id="dataReferencia"
          name="Data-Referência"
          label="Data Referência"
          format="dd/MM/yyyy"
          value={valueDate("dataReferencia")}
          onChange={(date) =>
            onChangeDate("dataReferencia", date, "Data-Referência")
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="bancoCaixaId"
          name="Banco-Caixa"
          options={contaLista}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => (option.nome ? option.nome : "")}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              label="Conta"
              margin="normal"
              variant="outlined"
            />
          )}
          onChange={(_e, value) =>
            onChangeAutocomplete("bancoCaixaId", value, "Banco-Caixa")
          }
          value={value.autoComplete(contaLista, filtrosAvancados?.bancoCaixaId)}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancadoCaixa;
