import * as Yup from "yup";
import nfSaidaPagamentosValidator from "./nf-saida-pagamentos.validator";
import nfSaidaItensValidator from "./nf-saida-itens.validator";

const nfSaidaValidator = (operacaoFiscal, meioPagamento) => {
  return Yup.object().shape({
    serieDocumento: Yup.mixed().when(
      [
        "importacaoDocumento",
        "documentoDevolucaoCliente",
        "documentoDevolucaoFornecedor",
      ],
      {
        is: (devolucaoCliente, devolucaoFornecedor) =>
          devolucaoCliente || devolucaoFornecedor,
        then: Yup.mixed().required("Série Documento"),
        otherwise: Yup.mixed().nullable(),
      }
    ),
    orcamentoImport: Yup.boolean().nullable(),
    orcamentoIds: Yup.array().when("orcamentoImport", {
      is: true,
      then: Yup.array().of(Yup.number()).min(1, "Orçamento ID Importação"),
      otherwise: Yup.array().nullable(),
    }),
    pedidoImport: Yup.boolean().nullable(),
    pedidoIds: Yup.array().when("pedidoImport", {
      is: true,
      then: Yup.array().of(Yup.number()).min(1, "Pedido ID Importação"),
      otherwise: Yup.array().nullable(),
    }),
    dataEmissao: Yup.date().required("Data Emissão"),
    operacaoFiscalId: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .required("Operação Fiscal"),
    operacaoFiscal: Yup.object()
      .shape({
        movimentacaoFinanceira: Yup.boolean().required(),
      })
      .required(),
    clienteId:
      operacaoFiscal?.modeloDocumento === "55" &&
      operacaoFiscal?.finalidade !== "DEVOLUCAO"
        ? Yup.number().required("Cliente")
        : Yup.number().nullable(),
    clienteEmail: Yup.string().nullable(),
    clienteNome:
      operacaoFiscal?.modeloDocumento === "55" &&
      operacaoFiscal?.finalidade !== "DEVOLUCAO"
        ? Yup.string().required("Cliente Nome")
        : Yup.string().nullable(),
    clienteCnpjCpf:
      operacaoFiscal?.modeloDocumento === "55" &&
      operacaoFiscal?.finalidade !== "DEVOLUCAO"
        ? Yup.string().required("Cliente Cnpj / Cpf")
        : Yup.string().nullable(),
    prestadorServicoId: Yup.number().nullable(),
    vendedorId: Yup.number().when("vendedorObrigatorio", {
      is: true,
      then: Yup.number().nullable().required("Vendedor"),
      otherwise: Yup.number().nullable(),
    }),
    vendedorObrigatorio: Yup.boolean().nullable(),
    modalidadeFrete: Yup.string().nullable(),
    transportadoraId: Yup.number().nullable(),
    transportadoraNome: Yup.string().when(
      ["transportadoraId", "documentoDevolucaoFornecedor"],
      {
        is: (transportadoraId, documentoDevolucaoFornecedor) =>
          transportadoraId && documentoDevolucaoFornecedor,
        then: Yup.string().required("Nome Transportadora").nullable(),
        otherwise: Yup.string().nullable(),
      }
    ),
    transportadoraCnpjCpf: Yup.string().when(
      ["transportadoraId", "documentoDevolucaoFornecedor"],
      {
        is: (transportadoraId, documentoDevolucaoFornecedor) =>
          transportadoraId && documentoDevolucaoFornecedor,
        then: Yup.string().required("Cnpj/Cpf Transportadora").nullable(),
        otherwise: Yup.string().nullable(),
      }
    ),
    transportadoraIe: Yup.string().nullable(),
    transportadoraEnderecoRua: Yup.string().when(
      ["transportadoraId", "documentoDevolucaoFornecedor"],
      {
        is: (transportadoraId, documentoDevolucaoFornecedor) =>
          transportadoraId && documentoDevolucaoFornecedor,
        then: Yup.string().required("Rua Transportadora").nullable(),
        otherwise: Yup.string().nullable(),
      }
    ),
    transportadoraEnderecoMunicipio: Yup.string().when(
      ["transportadoraId", "documentoDevolucaoFornecedor"],
      {
        is: (transportadoraId, documentoDevolucaoFornecedor) =>
          transportadoraId && documentoDevolucaoFornecedor,
        then: Yup.string().required("Municipio Transportadora").nullable(),
        otherwise: Yup.string().nullable(),
      }
    ),
    transportadoraEnderecoUf: Yup.string().when(
      ["transportadoraId", "documentoDevolucaoFornecedor"],
      {
        is: (transportadoraId, documentoDevolucaoFornecedor) =>
          transportadoraId && documentoDevolucaoFornecedor,
        then: Yup.string().required("UF Transportadora").nullable(),
        otherwise: Yup.string().nullable(),
      }
    ),
    qtdVolumes: Yup.number().nullable(),
    nfSaidaEnderecoCliente: Yup.object().nullable(),
    freteCompoeNota: Yup.boolean().nullable(),
    informacoesAdicionais: Yup.string().nullable(),
    informacoesFisco: Yup.string().nullable(),
    desconto: Yup.number().nullable(),
    acrescimo: Yup.number().nullable(),
    despesas: Yup.number().nullable(),
    frete: Yup.number().nullable(),
    quantidadeParcelas: Yup.number().nullable(),
    importacaoDocumento: Yup.boolean().nullable(),
    documentoDevolucaoCliente: Yup.boolean().nullable(),
    documentoDevolucaoFornecedor: Yup.boolean().nullable(),
    nfSaidaImportadaId: Yup.number().when("importacaoDocumento", {
      is: true,
      then: Yup.number().required("NF Saida ID Importação "),
      otherwise: Yup.number().nullable(),
    }),
    nfSaidaItens: nfSaidaItensValidator(operacaoFiscal),
    nfSaidaPagamentos: nfSaidaPagamentosValidator(meioPagamento),
    totalVenda: Yup.number().required("Total venda"),
  });
};

export default nfSaidaValidator;
