import { notification } from "../../../../services";
import { calcularValorAPagar } from "../add-conta-pagar";

export class Pagamento {
  constructor(pagamento, pagamentoList, contaPagar) {
    Object.assign(this, pagamento);
    this.meioPagamentoId =
      pagamento?.meioPagamentoId ?? contaPagar.meioPagamentoId;
    this.bancoCaixaId = pagamento?.bancoCaixaId ?? contaPagar.bancoCaixaId;
    this.tipoBaixa = pagamento?.tipoBaixa || "BAIXA_NORMAL";
    this.valorTitulo = Number(
      calcularValorAPagar(pagamento, pagamentoList, contaPagar).toFixed(2)
    );
    this.valor = Number(pagamento?.valor);
    this.recebedorId = contaPagar.pessoaId;
    this.pessoaId = contaPagar.pessoaId;
  }

  validaValor() {
    if (this.valor <= 0) {
      notification.alertaGenericos("Valor a pagar deve ser maior que zero");
      return false;
    }
    if (
      parseFloat(this.valor.toFixed(2)) >
      parseFloat(this.valorTitulo.toFixed(2))
    ) {
      notification.alertaGenericos(
        "Valor a pagar não pode ser maior que o total"
      );
      return false;
    }
    return true;
  }
}
