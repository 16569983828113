import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Loading,
  MaskedInput,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "../../components";
import { InputErros, StorageHelper } from "../../helpers";
import {
  CadastrosService,
  NotificacaoService,
  SessionService,
  history,
  notification,
} from "../../services";
import { cadastrarContadorValidator } from "./middlewares";
import PasswordStrengthBar from "react-password-strength-bar";
import "./cadastro-style.scss";
import { encrypt, responseErros } from "../../utils";
import { CadastroEmpresa as CadastroContador } from "./entities";

const CadastrarContadorView = () => {
  const [loading, setLoading] = useState(false);
  const [contador, setContador] = useState({
    naturezaSocial: "juridica",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [senhaScore, setSenhaScore] = useState();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const cadastrosService = new CadastrosService();
  const notificacaoService = new NotificacaoService();
  const sessionService = new SessionService();
  const storageHelper = new StorageHelper();

  const images = {
    logo: require("../../assets/images/icon-lite-contador.svg"),
  };

  useEffect(() => {
    verificaToken();
  }, []);

  const verificaToken = () => {
    const token = storageHelper.getLocal("token");
    if (!token) {
      return history.push("/cadastros/contador");
    }
    notificacaoService.getAll().then((result) => {
      if (!result.isAxiosError) {
        return history.push("/loading");
      }
      return sessionService.deslogar();
    });
  };

  const handleInputChange = (e) => {
    const empresaTemp = {
      ...contador,
      [e.target.name]: e.target.value,
    };
    setContador(empresaTemp);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleUserInputChange = (e, name) => {
    const value = e.target.value;
    const usuarioTemp = {
      ...contador,
      [e.target.name]: value,
    };
    if (e.target.name == "senha" || e.target.name == "confirmarSenha") {
      usuarioTemp[name] = String(value).trim();
    }
    setContador(usuarioTemp);
  };

  const buscarCnpjCadastrado = async (cnpjCpf) => {
    if (cnpjCpf) {
      const result = await cadastrosService.validarContadorCadastrado(cnpjCpf);
      if (!result.isAxiosError) {
        if (result.data) {
          inputErros.gerar("cnpjCpf");
          notification.alertaGenericos("Cnpj já cadastrado");
          setContador((prevState) => ({
            ...prevState,
            cnpjCpf: "",
          }));
        } else inputErros.set(null);
      } else responseErros(result);
    }
  };

  const buscarUsuarioCadastrado = async (email) => {
    if (email && email?.length > 8) {
      const result = await cadastrosService.validarUsuarioCadastrado(email);
      if (!result.isAxiosError) {
        if (result.data) {
          inputErros.gerar("email");
          notification.alertaGenericos("Usuário já cadastrado");
          setContador((prevState) => ({
            ...prevState,
            email: "",
          }));
          return true;
        } else {
          inputErros.set(null);
        }
      } else responseErros(result);
    }
  };

  const validarSenha = (cadastroContador, contador) => {
    const isStrong = cadastroContador?.verificarSenhaForte(
      contador,
      senhaScore
    );
    if (!isStrong) {
      inputErros.gerar("senha");
      notification.alertaGenericos("Senha fraca");
    }
    return isStrong;
  };

  const handleSubmit = () => {
    const cadastroContador = new CadastroContador(contador);
    const isPasswordValid = validarSenha(cadastroContador, contador);
    if (!isPasswordValid) return;
    cadastrarContadorValidator
      .validate(contador, { abortEarly: false })
      .then(async () => {
        inputErros.set(null);
        setLoading(true);
        const result = await cadastrosService.cadastrarContador({
          ...contador,
          senha: encrypt(contador.senha),
        });
        if (!result.isAxiosError) {
          notification.cadastroSucesso();
          history.push("/");
        } else {
          responseErros(result);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  return (
    <Grid container className="bg-cadastro-contador">
      {/* <img className="position-fixed line" src={images.line.default} /> */}
      <img
        className="position-fixed line-elipse-contador"
        src={images.logo.default}
      />
      <Container container className="h-100-vh p-3 zindex-modal">
        <Card className="p-2">
          <CardHeader
            title="Cadastro do Contador"
            action={<img src={images.logo.default} width={"25px"} />}
          />
          <CardContent
            style={{
              overflowY: "scroll",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Select
                  label="Natureza Social"
                  name="naturezaSocial"
                  id="naturezaSocial"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={contador.naturezaSocial}
                  InputLabelProps={{ shrink: true }}
                  error={inputErros.get("naturezaSocial")}
                  required
                >
                  <MenuItem value={"fisica"}>Física</MenuItem>
                  <MenuItem value={"juridica"}>Jurídica</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="nome"
                  name="nome"
                  label="Nome"
                  variant="outlined"
                  value={contador?.nome ? contador?.nome : ""}
                  onChange={handleUserInputChange}
                  fullWidth
                  required
                  error={inputErros.get("nome")}
                />
              </Grid>
              <Grid item xs={3}>
                <MaskedInput
                  id="cnpjCpf"
                  label={
                    contador.naturezaSocial === "juridica" ? "CNPJ" : "CPF"
                  }
                  name="cnpjCpf"
                  variant="outlined"
                  margin="none"
                  mask={
                    contador.naturezaSocial === "juridica"
                      ? "99.999.999/9999-99"
                      : "999.999.999-99"
                  }
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={contador?.cnpjCpf ? contador.cnpjCpf : ""}
                  onBlur={() => buscarCnpjCadastrado(contador?.cnpjCpf)}
                  required
                  error={inputErros.get("cnpjCpf")}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="email"
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  value={contador?.email}
                  onChange={(e) => handleUserInputChange(e, "email")}
                  onBlur={() => buscarUsuarioCadastrado(contador?.email)}
                  fullWidth
                  required
                  error={inputErros.get("email")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="senha"
                  name="senha"
                  label="Senha"
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => handleUserInputChange(e, "senha")}
                  fullWidth
                  required
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <i
                              className="ph-fill ph-eye-slash"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          ) : (
                            <i
                              className="ph-fill ph-eye"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={contador?.senha ? contador.senha : ""}
                  error={inputErros.get("senha")}
                />
                {senhaScore < 2 ? (
                  <Typography className="passwordErrorInput">
                    Deve ter de 6 a 20 caracteres e no mínimo razoável
                  </Typography>
                ) : (
                  ""
                )}
                <PasswordStrengthBar
                  scoreWords={[
                    "senha fraca",
                    "senha fraca",
                    "senha razoável",
                    "senha boa",
                    "senha forte",
                  ]}
                  shortScoreWord={"senha curta"}
                  password={contador?.senha}
                  onChangeScore={(score) => {
                    setSenhaScore(score);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="confirmarSenha"
                  name="confirmarSenha"
                  label="Confirmar senha"
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => handleUserInputChange(e, "confirmarSenha")}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  autocomplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <i
                              className="ph-fill ph-eye-slash"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          ) : (
                            <i
                              className="ph-fill ph-eye"
                              style={{ color: "#494C62", fontSize: 18 }}
                            ></i>
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={
                    contador?.confirmarSenha ? contador.confirmarSenha : ""
                  }
                  error={inputErros.get("confirmarSenha")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className="justify-content-end">
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              className="button-submit-contador"
            >
              Cadastrar
            </Button>
          </CardActions>
        </Card>
        <Loading loading={loading} />
      </Container>
    </Grid>
  );
};

export default CadastrarContadorView;
