import { Badge as BadgeMui } from "@mui/material";

const Badge = ({
  children,
  badgeContent,
  color,
  component,
  invisible,
  max,
  overlap,
  anchorOrigin,
  showZero,
  sx,
  variant,
}) => {
  return (
    <BadgeMui
      badgeContent={badgeContent}
      color={color}
      component={component}
      invisible={invisible}
      max={max}
      overlap={overlap}
      anchorOrigin={anchorOrigin}
      showZero={showZero}
      sx={sx}
      variant={variant}
    >
      {children}
    </BadgeMui>
  );
};

export default Badge;
