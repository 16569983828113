import { Divider as DividerMui } from "@mui/material";

const Divider = ({
  absolute,
  children,
  classes,
  component,
  flexItem,
  light,
  orientation,
  sx,
  textAlign,
  variant,
  ref,
  className,
}) => {
  return (
    <DividerMui
      absolute={absolute ?? false}
      classes={classes}
      component={component}
      flexItem={flexItem ?? false}
      light={light ?? false}
      orientation={orientation ?? "horizontal"}
      sx={sx}
      textAlign={textAlign ?? "center"}
      variant={variant ?? "temporary"}
      ref={ref}
      className={className}
    >
      {children}
    </DividerMui>
  );
};

export default Divider;
