import { Slide as SlideMui } from "@mui/material";

const Slide = ({
  className,
  children,
  ref,
  addEndListener,
  appear,
  container,
  direction,
  easing,
  timeout,
  ...rest
}) => {
  return (
    <SlideMui
      className={className}
      ref={ref}
      addEndListener={addEndListener}
      appear={appear}
      container={container}
      direction={direction}
      easing={easing}
      timeout={timeout}
      {...rest}
    >
      {children}
    </SlideMui>
  );
};

export default Slide;
