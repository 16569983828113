import * as Yup from "yup";

const pessoaValidator = (requiredCpfCnpj) => {
  return Yup.object().shape({
    avatar: Yup.string().nullable(),
    naturezaSocial: Yup.string().required("Natureza Social"),
    nomeRazaoSocial: Yup.string().required("Nome"),
    apelidoFantasia: Yup.string().nullable(),
    dataAbertura: Yup.date().when("naturezaSocial", {
      is: "juridica",
      then: Yup.date("Data de abertura").max(new Date()).nullable(),
      otherwise: Yup.date("Data de abertura").nullable(),
    }),
    dataNascimento: Yup.date().when("naturezaSocial", {
      is: "fisica",
      then: Yup.date("Data de nascimento").max(new Date()).nullable(),
      otherwise: Yup.date("Data de nascimento").nullable(),
    }),
    cnpjCpf: Yup.string().when("naturezaSocial", {
      is: "fisica",
      then: requiredCpfCnpj
        ? Yup.string("CPF").required("CPF")
        : Yup.string("CPF"),
      otherwise: requiredCpfCnpj
        ? Yup.string("CNPJ").required("CNPJ")
        : Yup.string("CNPJ"),
    }),
    inscricaoEstadual: Yup.string().nullable(),
    inscricaoEstadualRural: Yup.string().nullable(),
    rg: Yup.string().nullable(),
    contato: Yup.string().nullable(),
    email: Yup.string().nullable(),
    telefone: Yup.number("Telefone")
      .nullable()
      .test(
        "tamStringTelefone",
        "Telefone Válido",
        (value) => !value || value?.toString().length == 10
      ),
    celular: Yup.number("Celular")
      .nullable()
      .test(
        "tamStringCelular",
        "Celular Válido",
        (value) => !value || value?.toString().length == 11
      ),
    tiposPessoas: Yup.array()
      .min(1, "Tipos de Pessoa")
      .required("Tipos de Pessoa"),
    observacao: Yup.string().nullable(),
  });
};

export default pessoaValidator;
