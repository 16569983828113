import { Button } from "@mui/material";

const ButtonMenu = ({ setOpenBaixarXmlDialog }) => {
  const onClickBaixarXml = () => {
    setOpenBaixarXmlDialog(true);
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      {/* <Button
        id="emitirNfLote"
        name="emitirNfLote"
        variant="outlined"
        color="primary"
        onClick={emitirNota}
      >
        Emitir em lote
      </Button> */}
      <Button
        id="baixarXml"
        name="baixarXml"
        className="ms-3"
        variant="outlined"
        color="primary"
        onClick={onClickBaixarXml}
      >
        Baixar XML
      </Button>
    </div>
  );
};

export default ButtonMenu;
