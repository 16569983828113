import * as Yup from "yup";

const transportadoraValidator = Yup.object().shape({
  ufEmbarque: Yup.number().nullable(),
  localEmbarque: Yup.string().nullable(),
  rntc: Yup.number().nullable(),
  gruposPessoas: Yup.array()
    .of(Yup.object({ id: Yup.number().required() }))
    .nullable(),
});

export default transportadoraValidator;
