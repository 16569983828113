import { useEffect } from "react";
import { Router } from "react-router-dom";
import { history, notification, SessionService } from "./services";
import { CacheContextProvider } from "./contexts/cache.context";
import "react-perfect-scrollbar/dist/css/styles.css";
import Routes from "./routes";
import { connect } from "react-redux";
import store from "./config/store";
import authActions from "./store/modules/notificacao/actions";
import { io } from "socket.io-client";
import { buscarNotificacao } from "./utils";

const App = ({ setMensagens }) => {
  const sessionService = new SessionService();

  useEffect(() => {
    if (store.enviroment == "production" || store.enviroment == "releasing") {
      const socket = io(store.notificationSocketUrl);
      socket.on("message", (data) => {
        const usuario = sessionService.getUsuario();
        if (data.pctUsuarioId == usuario.id) {
          buscarNotificacao(setMensagens, usuario.id);
          if (data.tipo == "ERRO") {
            notification.erroCadastroApi(data.titulo);
          } else {
            notification.alertaApi(data.descricao);
          }
        }
      });
    }
  }, []);

  return (
    <CacheContextProvider>
      <Router history={history}>
        <Routes />
      </Router>
    </CacheContextProvider>
  );
};

const mapStateToProps = (state) => ({
  mensagens: state.notificacao.mensagens,
});

const mapDispatchToProps = (dispatch) => ({
  setMensagens: (mensagens) => dispatch(authActions.setMensagens(mensagens)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
