import "../modelo-nfe.scss";

const ImpostoNfe = ({ totais }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">Cálculo do imposto</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Base de cálculo do ICMS</p>
              <p className="danfe_celula_valor">{totais?.vBC ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do ICMS</p>
              <p className="danfe_celula_valor">{totais?.vICMS ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">
                Base de cálculo do ICMS Subst.
              </p>
              <p className="danfe_celula_valor">{totais?.vBCST ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do ICMS Subst.</p>
              <p className="danfe_celula_valor">{totais?.vST ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor Imposto Import.</p>
              <p className="danfe_celula_valor">{totais?.vII ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do PIS</p>
              <p className="danfe_celula_valor">{totais?.vPIS ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor total dos produtos</p>
              <p className="danfe_celula_valor">{totais?.vProd ?? "0.00"}</p>
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do frete</p>
              <p className="danfe_celula_valor">{totais?.vFrete ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do seguro</p>
              <p className="danfe_celula_valor">{totais?.vSeg ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Desconto</p>
              <p className="danfe_celula_valor">{totais?.vDesc ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Despesas acessórias</p>
              <p className="danfe_celula_valor">{totais?.vOutro ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do IPI</p>
              <p className="danfe_celula_valor">{totais?.vIPI ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor do COFINS</p>
              <p className="danfe_celula_valor">{totais?.vCOFINS ?? "0.00"}</p>
            </td>
            <td className="danfe_celula_bordas">
              <p className="danfe_celula_titulo">Valor total da nota</p>
              <p className="danfe_celula_valor valor_negrito">
                {totais?.vNF ?? "0.00"}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ImpostoNfe;
