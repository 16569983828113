import * as Yup from "yup";

const nfEntradaItemObjectValidator = Yup.object().shape({
  produtoId: Yup.number().nullable(),
  produto: Yup.string().required("Descrição"),
  ordem: Yup.number().required("Ordem"),
  unidade: Yup.string().optional().nullable(),
  cfopId: Yup.number().required("CFOP"),
  cfop: Yup.string().required("CFOP"),
  codigo: Yup.string().optional().nullable(),
  ncm: Yup.string()
    .min(8, "NCM deve ter pelo menos 8 caracteres")
    .required("NCM"),
  grupoTributacaoId: Yup.number().optional().nullable(),
  tributosIcmsOrigem: Yup.number().required("Tributos ICMS Origem"),
  quantidade: Yup.number().required("Quantidade"),
  precoCompra: Yup.number().required("Preço Compra"),
  desconto: Yup.number().optional().nullable(),
  acrescimo: Yup.number().optional().nullable(),
  despesas: Yup.number().optional().nullable(),
  subtotal: Yup.number().required("Subtotal"),
});

const nfEntradaItensValidator = Yup.array()
  .of(nfEntradaItemObjectValidator)
  .min(1, "Adicione ao menos um item")
  .required("Adicione ao menos um item");

export default nfEntradaItensValidator;
