export const editarQuantidadeItemDevolucao = (
  params,
  event,
  setListaItensGrid,
  setListaItensSelecionados
) => {
  if (event.key === "Enter") {
    const quantidade =
      event.target.value > params.value ? params.value : event.target.value;
    const desconto = (params.row.desconto / params.row.quantidade) * quantidade;
    const acrescimo =
      (params.row.acrescimo / params.row.quantidade) * quantidade;
    const atualizarItem = (item) => ({
      ...item,
      quantidade,
      subtotal: params.row.valor * quantidade,
      desconto,
      acrescimo,
    });
    setListaItensGrid((prevState) =>
      prevState.map((obj) =>
        obj.id == params.row.id ? atualizarItem(obj) : obj
      )
    );
    setListaItensSelecionados((prevState) =>
      prevState.map((obj) =>
        obj.id == params.row.id ? atualizarItem(obj) : obj
      )
    );
  }
};
