import {
  Button,
  CardHeader,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "../../../../components";

function HistoricoPagamentos({
  dadosPagamentoList,
  dadosPagamentoColunas,
  openHistorico,
  handleHistorico,
}) {
  return (
    <Dialog maxWidth={"xl"} fullWidth open={openHistorico}>
      <DialogContent>
        <CardHeader
          title="Histórico de Baixas "
          action={
            <Button
              onClick={handleHistorico}
              variant="contained"
              color="padrao"
            >
              fechar
            </Button>
          }
        />
        <Grid container spacing={4}>
          <Grid className="mt-2" item xs={12}>
            <DataGrid
              rows={dadosPagamentoList}
              columns={dadosPagamentoColunas}
              rowCount={dadosPagamentoList.length}
              disableAcoes
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default HistoricoPagamentos;
