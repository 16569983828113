import { RadioGroup as MuiRadioGroup } from "@mui/material";

const RadioGroup = ({
  id,
  name,
  children,
  label,
  value,
  onChange,
  onBlur,
  row,
  defaultValue,
  disabled,
}) => {
  return (
    <MuiRadioGroup
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      row={row}
      defaultValue={defaultValue}
      disabled={disabled}
      component="div"
    >
      {children}
    </MuiRadioGroup>
  );
};

export default RadioGroup;
