import "../modelo-carta-correcao.scss";
import Barcode from "jsbarcode";

const NfeInfo = ({ data }) => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">NOTA FISCAL ELETRÔNICA</h3>
      <table className="danfe_tabelas">
        <tbody>
          <tr>
            <td className="danfe_celula_bordas" colSpan="1">
              <p className="danfe_celula_titulo">Modelo</p>
              <p className="danfe_celula_valor">{data?.modelo}</p>
            </td>
            <td className="danfe_celula_bordas" colSpan="1">
              <p className="danfe_celula_titulo">Série</p>
              <p className="danfe_celula_valor valor_negrito">{data?.serie}</p>
            </td>
            <td className="danfe_celula_bordas" colSpan="2">
              <p className="danfe_celula_titulo">Número</p>
              <p className="danfe_celula_valor">{data?.numeroDocumento}</p>
            </td>
            <td className="danfe_celula_bordas" colSpan="2">
              <p className="danfe_celula_titulo">Data da Emissão</p>
              <p className="danfe_celula_valor">{data?.dataHora}</p>
            </td>
            <td
              className="danfe_celula_bordas barra_chave"
              colSpan="4"
              rowSpan="2"
            >
              <svg
                ref={(node) => {
                  if (node && data?.chaveAcesso) {
                    Barcode(node, data.chaveAcesso, {
                      format: "CODE128",
                      width: 1,
                      height: 45,
                      displayValue: false,
                    });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="danfe_celula_bordas" colSpan="6">
              <p className="danfe_celula_titulo">Chave de acesso</p>
              <p className="danfe_celula_valor">{data?.chaveAcesso}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NfeInfo;
