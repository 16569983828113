import { ListItemIcon as ListItemIconMui } from "@mui/material";

const ListItemIcon = ({ children, classes, sx, ref, style, className }) => {
  return (
    <ListItemIconMui
      classes={classes}
      ref={ref}
      style={style}
      sx={sx}
      className={className}
    >
      {children}
    </ListItemIconMui>
  );
};

export default ListItemIcon;
