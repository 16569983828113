import SiafLiteApiRepository from "../../external/siafLiteApi";

class CfopService {
  constructor() {
    this.urlBase = "/fiscal/cfop";
  }

  getAll(filtros) {
    return new SiafLiteApiRepository(this.urlBase).get(filtros);
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAllAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }
}

export default CfopService;
