import * as Yup from "yup";

const contasReceberValidator = (meioPagamento) => {
  return Yup.object().shape({
    status: Yup.string().nullable(),
    nfSaidaId: Yup.number().nullable(),
    tipo: Yup.string().nullable(),
    gerarFluxoCaixa: Yup.boolean().nullable(),
    baixaAposInclusao: Yup.boolean().nullable(),
    numeroTitulo: Yup.string().nullable(),
    origem: Yup.string().nullable(),
    pagadorId: !meioPagamento?.baixaAutomatica
      ? Yup.number().required("Pagador")
      : Yup.number().nullable(),
    bancoCaixaId: Yup.number().required("Conta"),
    dataLancamento: Yup.date().required("Data Lançamento"),
    dataVencimento: Yup.date().required("Data Vencimento"),
    planoFinanceiroId: Yup.number().nullable(),
    centroCustoId: Yup.number().nullable(),
    valor: Yup.number().required("Valor"),
    valorTotal: Yup.number().nullable(),
    meioPagamentoId: Yup.number().required("Meio de Pagamento"),
    acrescimo: Yup.number().nullable(),
    desconto: Yup.number().nullable(),
    historico: Yup.string().nullable(),
    pagamentos: Yup.array().of(
      Yup.object({
        dataPagamento: Yup.date().nullable(),
        pagadorId: Yup.number().nullable(),
        bancoCaixaId: Yup.number().nullable(),
        meioPagamentoId: Yup.number().nullable(),
        atraso: Yup.number().nullable(),
        multa: Yup.string().nullable(),
        juros: Yup.string().nullable(),
        desconto: Yup.number().nullable(),
        saldoDevedor: Yup.number().required("Valor do Título"),
        valor: Yup.number().required("Valor Pago"),
      }).nullable()
    ),
    observacaoList: Yup.array().nullable(),
  });
};

export default contasReceberValidator;
