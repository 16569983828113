import { format } from "date-fns";
import {
  TablePrintDetalhadoComponent,
  Typography,
} from "../../../../components";
import { formatDate, formatPrice } from "../../../../utils";

const PrintEstoqueDetalhado = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Serie",
      field: "serieNota",
    },
    {
      header: "Nº Doc.",
      field: "numeroNota",
    },
    {
      header: "Tipo",
      field: "tipo",
      render: ({ tipo }) => tipo.toUpperCase(),
    },
    {
      header: "Qtd.",
      field: "quantidade",
      align: "center",
    },
    {
      header: "Data",
      field: "data",
      render: ({ data }) => format(formatDate.received(data), "dd/MM/yyyy"),
      fieldTotal: "totalEntrada",
      renderTotal: ({ totalEntrada }) => `entrada: ${totalEntrada}`,
    },
    {
      header: "Subtotal",
      field: "subtotal",
      render: ({ subtotal }) => formatPrice(subtotal, false),
      fieldTotal: "totalSaida",
      renderTotal: ({ totalSaida }) => `saida: ${totalSaida}`,
      align: "end",
    },
  ];
  return (
    <TablePrintDetalhadoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.movimentacao}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      headerRelatorio={headerRelatorio}
    />
  );
};

function headerRelatorio(produto) {
  return (
    <div>
      Produto:
      <Typography variant="h4" className="mb-1 text-uppercase font-weight-bold">
        {produto.codigo} - {produto.descricao}
      </Typography>
    </div>
  );
}

export default PrintEstoqueDetalhado;
