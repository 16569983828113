import { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "../../../../components";
import { NfSaidaService, notification } from "../../../../services";
import { responseErros } from "../../../../utils";
import { autoSequenciaSerieValidator } from "../../middlewares";
import { InputErros } from "../../../../helpers";
import { AutoSequenciaSerieEntity } from "../../entities";
import ListagemSequencia from "../dialogComponents/list-sequencia.component";

const ConfiguracaoGeralTab = ({ setLoading }) => {
  const [autoSequeciaSerie, setAutoSequeciaSerie] = useState({});
  const [openListaSequencia, setOpenListaSequencia] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const nfSaidaService = new NfSaidaService();

  const onChange = (event) => {
    setAutoSequeciaSerie((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onClickAlterar = () => {
    setInputErro([]);
    autoSequenciaSerieValidator
      .validate(autoSequeciaSerie, { abortEarly: false })
      .then(() => {
        alterar();
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  async function alterar() {
    setLoading(true);
    const autoSequenciaSerieEntity = new AutoSequenciaSerieEntity(
      autoSequeciaSerie
    );
    const result = await nfSaidaService.setCodigoManual(
      autoSequenciaSerieEntity
    );
    setLoading(false);
    if (!result.isAxiosError) {
      notification.alteracaoSucesso();
      setAutoSequeciaSerie({});
    } else {
      responseErros(result);
    }
  }

  return (
    <Card className="p-2">
      <CardContent>
        <Grid container spacing={2} className="config-card">
          <Grid item xs={6}>
            GER001 - Alterar sequencia de série em Nf de Saída.
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="serie"
              name="serie"
              label="Série"
              variant="outlined"
              margin="normal"
              error={inputErros.get("serie")}
              type="number"
              onChange={onChange}
              value={autoSequeciaSerie?.serie ? autoSequeciaSerie.serie : ""}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="sequencia"
              name="sequencia"
              label="Sequencia"
              variant="outlined"
              margin="normal"
              type="number"
              onChange={onChange}
              error={inputErros.get("sequencia")}
              value={
                autoSequeciaSerie?.sequencia ? autoSequeciaSerie.sequencia : ""
              }
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              className="mt-2"
              variant="contained"
              color="primary"
              fullWidth
              onClick={onClickAlterar}
            >
              Alterar
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Visualizar Sequências">
              <IconButton
                style={{
                  padding: 10,
                  marginTop: 7,
                  backgroundColor: "#E5E5E5",
                }}
                onClick={() => setOpenListaSequencia(!openListaSequencia)}
              >
                <i className="ph-fill ph-eye" style={{ fontSize: 20 }}></i>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
      <ListagemSequencia
        openListaSequencia={openListaSequencia}
        setOpenListaSequencia={setOpenListaSequencia}
      />
    </Card>
  );
};
export default ConfiguracaoGeralTab;
