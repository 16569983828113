import { ListItem as ListItemMui } from "@mui/material";

const ListItem = ({
  alignItems,
  children,
  classes,
  component,
  components,
  componentsProps,
  dense,
  disableGutters,
  disablePadding,
  divider,
  secondaryAction,
  slotProps,
  slots,
  style,
  sx,
  visibility,
  ref,
  className,
  onClick,
}) => {
  return (
    <ListItemMui
      alignItems={alignItems}
      classes={classes}
      component={component}
      components={components}
      componentsProps={componentsProps}
      dense={dense}
      disableGutters={disableGutters}
      disablePadding={disablePadding}
      divider={divider}
      secondaryAction={secondaryAction}
      slotProps={slotProps}
      slots={slots}
      visibility={visibility}
      ref={ref}
      style={style}
      sx={sx}
      className={className}
      onClick={onClick}
    >
      {children}
    </ListItemMui>
  );
};

export default ListItem;
