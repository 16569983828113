export default class NfSaidaPagamento {
  meioPagamentoId = null;
  condicaoPagamentoId = null;
  dataVencimento = null;
  valorTitulo = null;
  numeroTitulo = null;
  numeroAutorizacao = null;
  meioPagamentoSelecionado = null;

  constructor(nfSaidaPagamento) {
    this.meioPagamentoId = nfSaidaPagamento.meioPagamentoId;
    this.condicaoPagamentoId = nfSaidaPagamento.condicaoPagamentoId;
    this.dataVencimento = nfSaidaPagamento.dataVencimento;
    this.valorTitulo = nfSaidaPagamento.valorTitulo;
    this.numeroTitulo = nfSaidaPagamento.numeroTitulo;
    this.numeroAutorizacao = nfSaidaPagamento.numeroAutorizacao;
    this.meioPagamentoSelecionado = nfSaidaPagamento.meioPagamentoSelecionado;
  }
}
