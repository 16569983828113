import { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Grid,
  MaskedInput,
} from "../../../../../components";
import { formatCnpj, formatCpf } from "../../../../../utils";
import { InputErros, ValidarCpfCnpjHelper } from "../../../../../helpers";
import { notification } from "../../../../../services";

const validarCpfCnpjHelper = new ValidarCpfCnpjHelper();

const IdentificarClienteDialog = ({
  open,
  setOpen,
  setClienteSelecionado,
  setNfSaida,
  nfSaidaEntity,
  setClienteSemCadastro,
}) => {
  const [nomeRazaoSocial, SetNomeRazaoSocial] = useState("");
  const [cnpjCpf, SetCnpjCpf] = useState(null);
  const [labelCpfCnpj, setLabelCpfCnpj] = useState("CPF / CNPJ");
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const handleNomeChange = (e) => {
    SetNomeRazaoSocial(e.target.value);
  };

  const handleCpfCnpjChange = (e) => {
    if (e.target.value.length > 14) return;
    SetCnpjCpf(e.target.value);
    if (e.target.value.length > 11) {
      setLabelCpfCnpj("CNPJ");
    } else if (e.target.value.length > 0) {
      setLabelCpfCnpj("CPF");
    }
  };

  async function verificarDocumento() {
    if (cnpjCpf.length != 11 && cnpjCpf.length != 14) {
      inputErros.gerar("cnpjCpf");
      throw new Error(`Documento inválido`);
    }
    const pessoaFisica = cnpjCpf.length == 11;
    const documentoValido = pessoaFisica
      ? validarCpfCnpjHelper.cpf(cnpjCpf, true)
      : validarCpfCnpjHelper.cnpj(cnpjCpf, true);
    if (!documentoValido) {
      inputErros.gerar("cnpjCpf");
      throw new Error(`${pessoaFisica ? "CPF" : "CNPJ"} inválido`);
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await verificarDocumento();
    } catch (error) {
      return notification.alertaGenericos(error.message);
    }
    setClienteSelecionado({
      nomeRazaoSocial,
      cnpjCpf,
    });
    nfSaidaEntity.setIdentificarCliente({ nomeRazaoSocial, cnpjCpf });
    setNfSaida(nfSaidaEntity);
    setClienteSemCadastro(true);
    setOpen(false);
  };

  return (
    <Dialog disableEscapeKeyDown maxWidth="md" fullWidth open={open}>
      <form onSubmit={(event) => onSubmit(event)}>
        <DialogContent>
          <CardHeader title="Identificar cliente" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  id="nomeRazaoSocial"
                  name="nomeRazaoSocial"
                  label="Nome / Razão Social"
                  variant="outlined"
                  margin="normal"
                  value={nomeRazaoSocial}
                  onChange={handleNomeChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <MaskedInput
                  id="cnpjCpf"
                  label={labelCpfCnpj}
                  name="cnpjCpf"
                  margin="normal"
                  value={
                    labelCpfCnpj === "CPF"
                      ? formatCpf(cnpjCpf)
                      : formatCnpj(cnpjCpf)
                  }
                  onChange={handleCpfCnpjChange}
                  InputLabelProps={{ shrink: true }}
                  error={inputErros.get("cnpjCpf")}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" type="submit">
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Salvar
            </Button>
            <Button
              variant="contained"
              color="padrao"
              onClick={() => setOpen(false)}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Fechar
            </Button>
          </CardActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default IdentificarClienteDialog;
