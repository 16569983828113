import * as Yup from "yup";

const usuarioValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  senha: Yup.string()
    .required("Senha")
    .min(6, "Senha deve ter entre 6 e 20 characteres")
    .max(20, "Senha deve ter entre 6 e 20 characteres"),
  confirmarSenha: Yup.string().when("senha", (senha, field) => {
    return senha
      ? field
          .required("Confirmar nova senha obrigatório")
          .oneOf([Yup.ref("senha")], "Confirmar senha deve ser igual a senha")
      : field;
  }),
});

export default usuarioValidator;
