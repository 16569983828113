import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
  Loading,
  Autocomplete,
  TextField,
  DatePicker,
  CurrencyInput,
} from "../../../components";
import { InputErros, value } from "../../../helpers";
import { formatDate, responseErros, unformatPrice } from "../../../utils";
import {
  BancosCaixasService,
  CaixaService,
  MeioPagamentoService,
  notification,
} from "../../../services";
import { tranferenciaSaldocaixaValidator } from "../middlewares";

const caixaService = new CaixaService();
const bancosCaixasService = new BancosCaixasService();
const meioPagamentoService = new MeioPagamentoService();

const TranferenciaSaldoDialog = ({
  open,
  setOpen,
  contasUsuario,
  transferenciaCaixa,
  setTransferenciaCaixa,
  setTotaisCaixa,
  setFormCaixa,
}) => {
  const [listaCaixas, setListaCaixas] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    if (open) {
      buscaContas();
      buscarMeiosPagamento();
    }
  }, [open]);

  const buscaContas = () => {
    const filtro = {
      nonPaginated: true,
    };
    bancosCaixasService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaCaixas(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
      baixaAutomatica: true,
      restritiva: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onChangeDate = (name, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    const formCaixaTemp = {
      ...transferenciaCaixa,
      [name]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    };
    setTransferenciaCaixa(formCaixaTemp);
  };

  const handleChangeInput = (event) => {
    setTransferenciaCaixa({
      ...transferenciaCaixa,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeAutocomplete = (name, value) => {
    setTransferenciaCaixa({
      ...transferenciaCaixa,
      [name]: value ? value.id : undefined,
    });
  };

  const onBlurInput = (event) => {
    const tranferenciaTemp = {
      ...transferenciaCaixa,
      [event.target.name]: unformatPrice(event.target.value),
    };
    setTransferenciaCaixa(tranferenciaTemp);
  };

  const onClose = () => {
    setOpen(false);
    setTotaisCaixa({});
    setFormCaixa({ data: new Date() });
    setTransferenciaCaixa({ dataMovimentacao: new Date() });
  };

  const submitTranferenciaSaldo = async () => {
    tranferenciaSaldocaixaValidator
      .validate(transferenciaCaixa, { abortEarly: false })
      .then(async () => {
        tranferirSaldo();
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const tranferirSaldo = async () => {
    setLoading(true);
    const formatSubmit = formatSubmitTranferencia(transferenciaCaixa);
    const result = await caixaService.tranferirSaldo(formatSubmit);
    if (!result.isAxiosError) {
      notification.alteracaoSucesso();
    } else {
      notification.alertaGenericos(result.response.data?.message);
    }
    onClose();
    setLoading(false);
  };

  function formatSubmitTranferencia(tranferenciaCaixa) {
    const body = {
      dataMovimentacao: tranferenciaCaixa.dataMovimentacao,
      meioPagamentoId: tranferenciaCaixa.meioPagamentoId,
      observacao: tranferenciaCaixa.observacao,
      valor: tranferenciaCaixa.valor,
    };
    const saidaObj = {
      ...body,
      caixaId: tranferenciaCaixa.bancoCaixaOrigem,
      tipo: "SAIDA",
    };
    const entradaObj = {
      ...body,
      caixaId: tranferenciaCaixa.bancoCaixaDestino,
      tipo: "ENTRADA",
    };
    return {
      bancoCaixaOrigem: tranferenciaCaixa.bancoCaixaOrigem,
      bancoCaixaDestino: tranferenciaCaixa.bancoCaixaDestino,
      dataMovimentacao: tranferenciaCaixa.dataMovimentacao,
      valor: tranferenciaCaixa.valor,
      transferencia: [saidaObj, entradaObj],
    };
  }

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <CardHeader title="Transferência de saldo" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Autocomplete
                id="bancoCaixaOrigem"
                name="bancoCaixaOrigem"
                options={contasUsuario || []}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Conta Origem"
                    margin="normal"
                    required
                    variant="outlined"
                    error={inputErros.get("bancoCaixaOrigem")}
                  />
                )}
                onChange={(e, newValue) => {
                  onChangeAutocomplete("bancoCaixaOrigem", newValue);
                }}
                value={value.autoComplete(
                  contasUsuario,
                  transferenciaCaixa.bancoCaixaOrigem
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="bancoCaixaDestino"
                name="bancoCaixaDestino"
                options={listaCaixas}
                noOptionsText="Sem opções"
                disabled={!transferenciaCaixa.bancoCaixaOrigem ? true : false}
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Conta Destino"
                    margin="normal"
                    required
                    variant="outlined"
                    error={inputErros.get("bancoCaixaDestino")}
                  />
                )}
                onChange={(e, newValue) => {
                  onChangeAutocomplete("bancoCaixaDestino", newValue);
                }}
                value={value.autoComplete(
                  listaCaixas,
                  transferenciaCaixa.bancoCaixaDestino
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <DatePicker
                id="dataMovimentacao"
                name="dataMovimentacao"
                label="Data"
                format="dd/MM/yyyy"
                margin
                required
                error={inputErros.get("dataMovimentacao")}
                value={value.date(transferenciaCaixa.dataMovimentacao)}
                onChange={(date) => onChangeDate("dataMovimentacao", date)}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                required
                id="meioPagamentoId"
                name="meioPagamentoId"
                options={meioPagamentoList}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option ? option.descricao : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meio de Pagamento"
                    variant="outlined"
                    margin="normal"
                    error={inputErros.get("meioPagamentoId")}
                    required
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete("meioPagamentoId", newValue);
                }}
                value={value.autoComplete(
                  meioPagamentoList,
                  transferenciaCaixa?.meioPagamentoId
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <CurrencyInput
                id="valor"
                name="valor"
                label="Valor a transferir"
                required
                value={transferenciaCaixa.valor ?? ""}
                error={inputErros.get("valor")}
                onBlur={onBlurInput}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="observacao"
                name="observacao"
                label="Observação"
                margin="normal"
                fullWidth
                variant="outlined"
                onChange={handleChangeInput}
                error={inputErros.get("observacao")}
                value={transferenciaCaixa.observacao}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className="d-flex justify-content-end">
          <Button variant="contained" color="padrao" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={submitTranferenciaSaldo}
          >
            Enviar
          </Button>
        </CardActions>
      </DialogContent>
      <Loading loading={loading} />
    </Dialog>
  );
};

export default TranferenciaSaldoDialog;
