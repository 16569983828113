import shortid from "shortid";

export class GrupoTributarioModeloTributacao {
  modeloTributacaoId = null;
  grupoTributarioId = null;
  tipoOperacao = null;
  calculaFcp = false;
  calculaIcms = false;
  calculaIcmsSt = false;
  csoSimplesNacional = null;
  acrescimoNaBase = false;
  acrescimoNaBaseSt = false;
  aliquotaFcp = null;
  aliquotaIcms = null;
  aliquotaIcmsCredito = null;
  aliquotaIcmsDebito = null;
  aliquotaIcmsDiferido = null;
  aliquotaMva = null;
  aliquotaSimples = null;
  aliquotaSt = null;
  descontoNaBase = false;
  descontoNaBaseSt = false;
  desonerado = false;
  despesaNaBase = false;
  despesaNaBaseSt = false;
  cfopId = null;
  freteNaBase = false;
  freteNaBaseSt = false;
  infoComplementar = null;
  ipiNaBase = false;
  ipiNaBaseSt = false;
  modalidadeBaseCalculo = null;
  motivoDesoneracao = null;
  redespesaNaBaseSt = null;
  reducaoBase = null;
  reducaoBaseSt = null;
  seguroNaBase = false;
  seguroNaBaseSt = false;
  situacaoPisId = null;
  aliquotaPis = null;
  situacaoCofinsId = null;
  aliquotaCofins = null;
  situacaoPisStId = null;
  aliquotaPisSt = null;
  situacaoCofinsStId = null;
  aliquotaCofinsSt = null;
  situacaoTributariaIpiId = null;
  aliquotaIpi = null;
  excecaoTipi = null;
  enquadramentoLegalId = null;
  classeEnquadramento = null;
  valorDePauta = null;

  constructor(grupoModeloTributacao) {
    Object.assign(this, grupoModeloTributacao);
    this.operacoesFiscalId = this.operacoesFiscalId.map(({ id }) => id);
    this.estadosId = this.estadosId.map(({ id }) => id);
  }

  adicionaRegraModeloNaoCadastrado(listaGrupoModeloTributacao) {
    const tributacao = {
      id: shortid.generate(),
      modeloTributacaoId: shortid.generate(),
      ...this,
      operacoesFiscais: this.operacoesFiscalId.map((id) => ({
        fisOperacaoFiscalId: id,
        id: shortid.generate(),
      })),
      estados: this.estadosId.map((id) => ({
        estadoId: id,
        id: shortid.generate(),
      })),
    };

    return [...listaGrupoModeloTributacao, tributacao];
  }

  adicionaRegraModeloNaoCadastradoIcmsId(listaGrupoModeloTributacao) {
    const index = listaGrupoModeloTributacao.findIndex(
      (item) => item.id === this.id
    );
    return listaGrupoModeloTributacao.map((item, _index) => {
      if (_index === index) {
        return {
          ...item,
          ...this,
          operacoesFiscalId: this.operacoesFiscalId.map((id) => ({
            operacoesFiscalId: id,
          })),
          estadosId: this.estadosId.map((id) => ({ estadosId: id })),
          operacoesFiscais: this.operacoesFiscalId.map((id) => ({
            fisOperacaoFiscalId: id,
            id: shortid.generate(),
          })),
          estados: this.estadosId.map((id) => ({
            estadoId: id,
            id: shortid.generate(),
          })),
        };
      }
      return item;
    });
  }

  adicionaModeloTributacaoId(modeloTributacaoId) {
    this.modeloTributacaoId = modeloTributacaoId;
  }
}
