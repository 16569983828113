import { useState } from "react";
import { TextField as TextFieldMui } from "@mui/material";

const TextField = ({
  id,
  autoFocus,
  name,
  label,
  variant,
  obj,
  margin,
  value,
  error,
  style,
  disabled,
  type,
  className,
  onChange,
  fullWidth,
  InputLabelProps,
  InputProps,
  inputProps,
  required,
  onFocus,
  onBlur,
  onKeyDown,
  inputRef,
  size,
  sx,
  placeholder,
  autoComplete,
  ...rest
}) => {
  const [foco, setFoco] = useState(false);
  const [inputValue, setInputValue] = useState(false);
  let objValue = obj ? Object.keys(obj).length > 2 : false;

  return (
    <TextFieldMui
      id={id}
      name={name}
      label={label}
      autoFocus={autoFocus ?? false}
      className={className}
      disabled={disabled ?? false}
      type={type}
      variant={variant || "outlined"}
      margin={margin ?? "none"}
      value={value}
      error={error}
      style={style}
      size={size}
      sx={sx}
      inputRef={inputRef}
      onChange={(e) => {
        setInputValue(e.target.value && e.target.value !== "" ? true : false);
        if (onChange) {
          onChange(e);
        }
      }}
      onFocus={(e) => {
        setFoco(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e) => {
        setFoco(false);
        if (onBlur) {
          onBlur(e);
        }
      }}
      onKeyDown={onKeyDown}
      autoComplete={autoComplete}
      fullWidth={fullWidth || true}
      inputProps={inputProps}
      InputLabelProps={{
        shrink:
          objValue ||
          inputValue ||
          foco ||
          value !== null ||
          value !== undefined,
        ...InputLabelProps,
      }}
      InputProps={InputProps}
      required={required}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default TextField;
