import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  showCloseButton: true,
  position: "top-end",
});

const ToastFechar = Swal.mixin({
  toast: true,
  showConfirmButton: false,
  position: "top-end",
  showCloseButton: true,
});

class Notification {
  cadastroSucesso() {
    Toast.fire({
      icon: "success",
      background: "#7FFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title: "Cadastro realizado com sucesso",
    });
  }

  alteracaoSucesso() {
    Toast.fire({
      icon: "success",
      background: "#7FFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title: "Alteração enviada com sucesso",
    });
  }

  deletadoSucesso() {
    Toast.fire({
      icon: "success",
      background: "#7FFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title: "Removido com sucesso",
    });
  }

  erroValidacao(campo) {
    Toast.fire({
      icon: "warning",
      background: "#FFFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title: `${campo} é obrigatório`,
    });
  }

  erroCadastroApi(title) {
    ToastFechar.fire({
      icon: "error",
      background: "#FF6347",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title,
    });
  }

  alertaApi(title) {
    ToastFechar.fire({
      icon: "warning",
      background: "#FFFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title,
    });
  }

  alertaApiFecharAuto(title) {
    Toast.fire({
      icon: "warning",
      background: "#FFFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title,
    });
  }

  alertaGenericos(title) {
    Toast.fire({
      icon: "warning",
      background: "#FFFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title,
    });
  }

  sucessoGenericos(title) {
    Toast.fire({
      icon: "success",
      background: "#7FFF00",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title,
    });
  }

  erroInternoServidor() {
    Toast.fire({
      icon: "error",
      background: "#FF6347",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title: "Erro no servidor. Tente novamente mais tarde.",
    });
  }

  erroGenericos(title) {
    Toast.fire({
      icon: "error",
      background: "#FF6347",
      iconColor: "#1C1C1C",
      color: "#1C1C1C",
      customClass: {
        closeButton: "text-secondary",
      },
      title,
    });
  }

  async confirmacao(title, text) {
    return await Swal.fire({
      title,
      text,
      icon: "warning",
      confirmButtonText: "SIM",
      cancelButtonText: "NÃO",
      showCancelButton: true,
    });
  }

  async confirmacaoSelect(confirmacao) {
    return await Swal.fire({
      title: confirmacao?.title,
      input: "select",
      inputOptions: confirmacao?.inputOptions,
      inputPlaceholder: "Selecione uma opção",
      showCancelButton: true,
    });
  }

  async confirmacaoGenericos(confirmacao) {
    return await Swal.fire({
      title: confirmacao?.title,
      text: confirmacao?.text,
      icon: confirmacao?.icon,
      confirmButtonText: confirmacao?.confirmButtonText,
      cancelButtonText: confirmacao?.cancelButtonText,
      showCancelButton: confirmacao?.showCancelButton,
      allowEscapeKey: false,
      dangerMode: confirmacao?.dangerMode || false,
    });
  }
}

export default new Notification();
