import {
  Grid,
  Card,
  CardContent,
  Switch,
  Select,
  MenuItem,
} from "../../../../components";
import "../../configuracoes.style.css";

const ConfiguracaoCadastroTab = ({
  configuracao,
  onChangeSwitch,
  onChange,
}) => {
  return (
    <Card className="p-2">
      <CardContent>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            CAD001 - Em pessoas, campo CPF/CNPJ é obrigatório.
          </Grid>
          <Grid item xs={4}>
            <Switch
              id="obrigatoriedadeCnpjCpf"
              name="obrigatoriedadeCnpjCpf"
              checked={
                configuracao?.obrigatoriedadeCnpjCpf
                  ? configuracao?.obrigatoriedadeCnpjCpf
                  : false
              }
              onChange={onChangeSwitch}
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            CAD002 - Modalidade de Cálculo Padrão de Precificação de Produtos.
          </Grid>
          <Grid item xs={4}>
            <Select
              label="Tipo de margem"
              id="margemCalculo"
              name="margemCalculo"
              margin="normal"
              value={configuracao.margemCalculo}
              required
              onChange={onChange}
            >
              <MenuItem value={"INVERSA"}>Margem Inversa</MenuItem>
              <MenuItem value={"CONVENCIONAL"}>Margem Convencional</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ConfiguracaoCadastroTab;
