import { Icon as IconMui } from "@mui/material";

const Icon = ({
  baseClassName,
  className,
  children,
  classes,
  color,
  component,
  fontSize,
  ref,
  sx,
}) => {
  return (
    <IconMui
      baseClassName={baseClassName}
      className={className}
      classes={classes}
      color={color}
      component={component}
      fontSize={fontSize}
      ref={ref}
      sx={sx}
    >
      {children}
    </IconMui>
  );
};

export default Icon;
