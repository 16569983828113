import React, { useState, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  DataGrid,
  CircularProgress,
} from "../../../../components";
import { ProdutosService, notification } from "../../../../services";
import { responseErros, formatPrice } from "../../../../utils";

const ProdutosSimilaresDialog = ({
  produtosSimilares,
  setProdutosSimilares,
  id,
}) => {
  const inputRef = useRef();
  const produtoService = new ProdutosService();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);

  const produtosColunas = [
    {
      field: "codigo",
      headerName: "Cod.",
      flex: 280,
    },
    {
      field: "descricao",
      headerName: "Produtos",
      flex: 700,
    },
    {
      field: "estoqueAtual",
      headerName: "Estoque",
      flex: 350,
    },
    {
      field: "precoCompra",
      headerName: "Preço Compra",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "precoVenda",
      headerName: "Preço Venda",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
  ];

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      limite: 20,
      ativado: true,
    };
    produtoService.getAll(filtro).then((result) => {
      setLoadingAutoComplete(false);
      if (!result.isAxiosError) {
        setListaProdutos(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const handleChangeProdutoSelecionado = (e, value) => {
    setProdutoSelecionado(value);
    document.getElementById("buttonAdicionar").focus();
  };

  const onSearchChangeProdutos = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        let filtros = {
          descricao: eventValue,
          restritiva: true,
        };
        if (id) {
          filtros = { ...filtros, id: `~neq~${this.id}` };
        }
        setLoadingAutoComplete(true);
        buscarProdutos(filtros);
      }
    }
  };

  const handleAdicionarProduto = () => {
    if (!produtoSelecionado) {
      return notification.alertaGenericos(
        "Selecione um produto para adicionar"
      );
    }
    const produtoInserido = produtosSimilares.find(
      (item) => item.id == produtoSelecionado.id
    );
    if (produtoInserido) {
      notification.alertaGenericos("Produto já inserido");
    } else {
      setProdutosSimilares((prevState) => [...prevState, produtoSelecionado]);
    }
    setProdutoSelecionado(null);
    setListaProdutos([]);
    document.getElementById("produtoId").focus();
  };

  const handleRemoverProduto = async (id) => {
    const data = produtosSimilares.filter((item) => item.id !== id);
    const produtoRemovido = produtosSimilares.find(
      (item) => item.id === id
    ).descricao;
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${
        produtoRemovido
          ? produtoRemovido
          : "este produto da lista de produtos similares"
      }?`
    );
    if (retornoAlerta.isConfirmed) {
      setProdutosSimilares(data);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Autocomplete
            id="produtoId"
            name="produtoId"
            options={listaProdutos}
            autoHighlight
            getOptionLabel={(option) => option?.descricao ?? ""}
            filterSelectedOptions
            onChange={handleChangeProdutoSelecionado}
            value={produtoSelecionado}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Buscar produto"
                onKeyDown={onSearchChangeProdutos}
                inputRef={inputRef}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <React.Fragment>
                        {loadingAutoComplete ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            id="buttonAdicionar"
            size="large"
            variant="contained"
            color="info"
            onClick={handleAdicionarProduto}
          >
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            ></i>
            Adicionar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: "1px" }}>
        <Grid item xs={12}>
          <DataGrid
            rows={produtosSimilares}
            columns={produtosColunas}
            rowCount={produtosSimilares.length}
            deleteShow
            onClickDelete={handleRemoverProduto}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProdutosSimilaresDialog;
