import { useEffect, useState } from "react";
import { Menu, MenuItem, ListItemIcon } from "../../../../components";
import HistoricoPagamentos from "./historico-pagamentos.component";
import { formatDate, formatPrice } from "../../../../utils";
import { format } from "date-fns";
import { getAtrasoDias } from "../add-conta-pagar";
import shortid from "shortid";

const MenuAddActions = ({
  anchorEl,
  setAnchorEl,
  conta,
  pagamento,
  dadosPagamentoList,
  listaMeiosPagamentos,
  listaContas,
  recebedorList,
  setReciboData,
  handlePrint,
}) => {
  const [openHistorico, setOpenHistorico] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";
  const dadosPagamentoColunas = [
    {
      field: "dataPagamento",
      headerName: "Data Pagamento",
      flex: 475,
      valueGetter: (params) => {
        return format(formatDate.received(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "horaPagamento",
      headerName: "Hora Pagamento",
      flex: 400,
      valueGetter: (params) => {
        return format(formatDate.received(params.value), "HH:mm");
      },
    },
    {
      field: "bancoCaixaId",
      headerName: "Banco/Caixa",
      flex: 400,
      valueGetter: (params) => {
        const conta = listaContas.find((item) => item.id == params.value);
        if (!conta) return params.value;
        return conta.nome;
      },
    },
    {
      field: "atrasoDias",
      headerName: "Atraso",
      flex: 350,
      valueGetter: () => {
        return getAtrasoDias(conta?.dataVencimento, pagamento?.dataPagamento);
      },
    },
    {
      field: "meioPagamentoId",
      headerName: "Meio Pagamento",
      flex: 600,
      valueGetter: (params) => {
        const meioPagamento = listaMeiosPagamentos.find(
          (meio) => meio.id == params.value
        );
        if (!meioPagamento) return params.value;
        return meioPagamento.descricao;
      },
    },
    {
      field: "tipoBaixa",
      headerName: "Tipo de Baixa",
      flex: 600,
      renderCell: (params) => {
        switch (params.value) {
          case "BAIXA_RENEGOCIACAO":
            return "Baixa por Renegociação";
          default:
            return "Baixa Normal";
        }
      },
    },
    {
      field: "valor",
      headerName: "Valor Baixa",
      flex: 450,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
  ];

  useEffect(() => {
    const fornecedor = recebedorList.find((item) => item.id == conta.pessoaId);
    setReciboData({
      fornecedor,
      titulo: conta,
      pagamentos: dadosPagamentoList,
    });
  }, [conta, recebedorList]);

  const handleAddActionsClose = () => {
    setAnchorEl(null);
  };

  const handleHistorico = () => {
    setOpenHistorico(!openHistorico);
    handleAddActionsClose();
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        elevation={1}
        getContentAnchorEl={null}
        id={menuId}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        autoFocus={false}
        open={isMenuOpen}
        onClose={handleAddActionsClose}
        className="m-3"
      >
        {[
          <MenuItem
            className="mb-1"
            onClick={handleHistorico}
            disabled={
              conta?.status !== "BAIXADO_PARCIAL" && conta?.status !== "BAIXADO"
            }
            sx={{ height: 20, borderRadius: 5 }}
            key={shortid.generate()}
          >
            <ListItemIcon style={{ minWidth: 42 }}>
              <i className="ph ph-currency-dollar" style={{ fontSize: 16 }}></i>
            </ListItemIcon>
            <span style={{ fontSize: 12 }}>Histórico Pagamentos</span>
          </MenuItem>,
          <MenuItem
            className="mb-1 mt-3"
            onClick={handlePrint}
            disabled={
              conta?.status !== "BAIXADO_PARCIAL" && conta?.status !== "BAIXADO"
            }
            sx={{ height: 20, borderRadius: 5 }}
            key={shortid.generate()}
          >
            <ListItemIcon style={{ minWidth: 42 }}>
              <i className="ph ph-printer" style={{ fontSize: 16 }}></i>
            </ListItemIcon>
            <span style={{ fontSize: 12 }}>Imprimir Recibo</span>
          </MenuItem>,
        ]}
      </Menu>
      <HistoricoPagamentos
        dadosPagamentoList={dadosPagamentoList}
        dadosPagamentoColunas={dadosPagamentoColunas}
        openHistorico={openHistorico}
        handleHistorico={handleHistorico}
      />
    </>
  );
};

export default MenuAddActions;
