import { IconButton } from "@mui/material";

const IconButtonCustom = ({
  id,
  ref,
  className,
  edge,
  color,
  size,
  disabled,
  onClick,
  style,
  ariaLabel,
  ariaControls,
  ariaHaspopup,
  ariaExpanded,
  onMouseOver,
  onMouseLeave,
  children,
  sx,
}) => {
  return (
    <IconButton
      id={id}
      ref={ref}
      className={className}
      edge={edge}
      color={color}
      disabled={disabled}
      size={size}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-haspopup={ariaHaspopup}
      aria-expanded={ariaExpanded}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={style}
      sx={sx}
    >
      {children}
    </IconButton>
  );
};

export default IconButtonCustom;
