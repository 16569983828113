import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemIcon,
  Typography,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Grid,
} from "../../components";

const AcordeonComponent = (props) => {
  const [listChildren, setListChildren] = useState([]);
  useEffect(() => {
    if (props.items.length) {
      const lista = props.items.filter((item) => item.idPai === props.item.id);
      setListChildren(lista);
    }
  }, [props.items]);

  const handleExpandChange = (idItem, isExpanded) => {
    const expandedTemp = { ...props.expanded };
    expandedTemp[idItem] = isExpanded;
    props.setExpanded(expandedTemp);
  };

  return (
    <Accordion
      className="shadow"
      onChange={(event, isExpanded) =>
        handleExpandChange(props.item.id, isExpanded)
      }
      expanded={props.expanded[props.item.id] ? true : false}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={
          <i
            className="ph-fill ph-caret-down"
            style={{ color: "#494C62", fontSize: 18 }}
          ></i>
        }
        aria-label="Expand"
        aria-controls={`panel${props.item.id}a-content`}
        id={`panel${props.item.id}a-header`}
      >
        <Grid container className="d-flex align-items-center">
          <Grid item xs={11}>
            <Typography
              style={{ paddingLeft: `${props.nivel}px` }}
              className={`pt-1 w-100`}
            >
              {`${props.item.codigo} - ${props.item.descricao}`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ListItemIcon>
              {props.editShow && (
                <IconButton
                  sx={{ width: "45px" }}
                  onFocus={(event) => event.stopPropagation()}
                  onClick={(event) => {
                    event.stopPropagation();
                    props.handleEditItem(props.item.id);
                  }}
                >
                  <Tooltip title="Editar" arrow placement="right">
                    <i
                      className="ph-fill ph-pencil"
                      style={{
                        fontSize: 21,
                      }}
                    ></i>
                  </Tooltip>
                </IconButton>
              )}

              {props.deleteShow && (
                <IconButton
                  sx={{ width: "45px" }}
                  onFocus={(event) => event.stopPropagation()}
                  onClick={(event) => {
                    event.stopPropagation();
                    props.handleDeleteItem(props.item.id);
                  }}
                >
                  <Tooltip title="Excluir" arrow placement="right">
                    <i
                      className="ph-fill ph-trash"
                      style={{
                        fontSize: 21,
                      }}
                    ></i>
                  </Tooltip>
                </IconButton>
              )}
            </ListItemIcon>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className="d-flex flex-column p-0">
        {listChildren.length > 0 &&
          listChildren.map((item) => {
            return (
              <AcordeonComponent
                key={item.id}
                item={item}
                items={props.items}
                nivel={props.nivel + 10}
                handleEditItem={props.handleEditItem}
                handleDeleteItem={props.handleDeleteItem}
                expanded={props.expanded}
                setExpanded={props.setExpanded}
                editShow={props.editShow}
                deleteShow={props.deleteShow}
              />
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

const AcordeonListComponent = (props) => {
  const [acordeonList, setAcordeonList] = useState([]);

  useEffect(() => {
    if (props.items.length) {
      const lista = props.items.filter(
        (item) => item.idPai === 0 || item.idPai === null
      );
      setAcordeonList(lista);
    }
  }, [props.items]);

  return (
    <>
      {acordeonList.length ? (
        acordeonList.map((item) => {
          return (
            <AcordeonComponent
              key={item.id}
              item={item}
              nivel={0}
              items={props.items}
              handleEditItem={props.handleEditItem}
              handleDeleteItem={props.handleDeleteItem}
              expanded={props.expanded}
              setExpanded={props.setExpanded}
              editShow={props.editShow}
              deleteShow={props.deleteShow}
            />
          );
        })
      ) : (
        <Card className="p-2">
          <CardContent>Nenhum Item Cadastrado</CardContent>
        </Card>
      )}
    </>
  );
};

export default AcordeonListComponent;
