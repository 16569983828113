import { useState } from "react";
import {
  Autocomplete,
  Button,
  DatePicker,
  Grid,
  TextField,
} from "../../../../components";
import {
  CaixaService,
  ContasReceberService,
  SessionService,
  notification,
} from "../../../../services";
import { formatDate, responseErros } from "../../../../utils";
import { InputErros } from "../../../../helpers";
import ResumoTotalContas from "./resumo-total-contas.component";
import RenegociacaoTitulosDialog from "./renegociacao-titulos-dialog.component";
import {
  montaListaContasABaixar,
  montaListaRenegociacoes,
} from "../list-contas-receber";
import listaContasReceberValidator from "../middlewares/list-contas-receber.validator";
import { useImportContext } from "../../../../contexts/import.context";

const RenegociacaoTitulos = ({
  renderRenegociacaoTitulos,
  setRenderRenegociacaoTitulos,
  renegociacaoTitulos,
  setRenegociacaoTitulos,
  calculaTotalAReceber,
  buscarContasReceber,
  listaCondicoesPagamento,
  listaContasReceber,
  setListaContasReceber,
  listaPessoas,
  totalAReceber,
  retornoTopo,
  setLoading,
  setLinhasSelecionadas,
  setTotalAReceber,
  listaMeiosPagamento,
}) => {
  const contasReceberService = new ContasReceberService();
  const caixaService = new CaixaService();
  const sessionService = new SessionService();
  const { openCollapse } = useImportContext();
  const [meioPagamento, setMeioPagamento] = useState(null);
  const [condicaoPagamento, setCondicaoPagamento] = useState(null);
  const [listaMeiosCondicao, setListaMeiosCondicao] = useState([]);
  const [listaRenegociacoes, setListaRenegociacoes] = useState([]);
  const [pagadoresIguais, setPagadoresIguais] = useState(false);
  const [pagadorList, setPagadorList] = useState([]);
  const [pagadorId, setPagadorId] = useState(null);
  const [openDialogRenegociacao, setOpenDialogRenegociacao] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const insertUserId = sessionService.getUsuario().id;
  const listaContasReceberValidatorService =
    listaContasReceberValidator(meioPagamento);

  const cadastrarCaixa = async (bancoCaixaId) => {
    const body = {
      data: new Date(),
      bancoCaixaId,
      aberto: true,
    };
    const result = await caixaService.cadastrar(body);
    if (result.isAxiosError) {
      responseErros(result);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event, listaRenegociacoes) => {
    event.preventDefault();
    setLoading(true);
    const body = listaRenegociacoes.map((conta) => {
      return { ...conta, pagadorId };
    });
    if (!listaContasReceber.length) return erroBaixa();
    const caixaCadastrado = await cadastrarCaixa(meioPagamento?.bancoCaixaId);
    if (!caixaCadastrado) {
      return;
    }
    if (body?.length) {
      listaContasReceberValidatorService
        .validate(body, { abortEarly: false })
        .then(async () => {
          const res = await contasReceberService.cadastrarLista(body);
          setLoading(false);
          if (!res.isAxiosError) {
            salvarContasReceber();
          } else {
            responseErros(res);
          }
        })
        .catch((err) => {
          sairRenegociacaoTitulos();
          inputErros.set(err);
        });
    }
  };

  const salvarContasReceber = async () => {
    const listaContasABaixar = montaListaContasABaixar(
      listaContasReceber,
      meioPagamento,
      renegociacaoTitulos,
      true
    );
    if (listaContasReceber?.length) {
      listaContasReceberValidatorService
        .validate(listaContasABaixar, { abortEarly: false })
        .then(async () => {
          const res = await contasReceberService.baixaEmLote(
            listaContasABaixar
          );
          if (!res.isAxiosError) {
            sairRenegociacaoTitulos();
            buscarContasReceber();
            return notification.sucessoGenericos(
              "Renegociação realizada com sucesso!"
            );
          } else {
            responseErros(res);
          }
        })
        .catch((err) => {
          sairRenegociacaoTitulos();
          inputErros.set(err);
        });
    } else {
      erroBaixa();
    }
  };

  const handleOpenRenegociacao = async () => {
    if (!listaContasReceber.length) return erroBaixa();
    const body = montaListaRenegociacoes(
      listaContasReceber,
      renegociacaoTitulos,
      meioPagamento,
      condicaoPagamento,
      totalAReceber,
      insertUserId
    );
    if (body.pagadores?.length) {
      const pagadoresIguais = body.pagadores.every((id) => {
        return id === body.pagadores[0];
      });
      if (!pagadoresIguais) {
        const pagadoresList = listaPessoas.filter((pessoa) =>
          body.pagadores.includes(pessoa.id)
        );
        setPagadorList(pagadoresList);
      } else {
        setPagadorList(listaPessoas);
        setPagadorId(body.pagadores[0]);
      }
      setPagadoresIguais(pagadoresIguais);
    }
    setListaRenegociacoes(body.lista);
    setOpenDialogRenegociacao(true);
  };

  function erroBaixa() {
    sairRenegociacaoTitulos();
    return notification.alertaGenericos(
      "Selecione no mínimo uma conta a baixar"
    );
  }

  function sairRenegociacaoTitulos() {
    setLinhasSelecionadas([]);
    setTotalAReceber({
      multa: 0,
      juros: 0,
      subtotal: 0,
      receber: 0,
    });
    setRenderRenegociacaoTitulos(false);
    setOpenDialogRenegociacao(false);
    setCondicaoPagamento(null);
    setMeioPagamento(null);
    setRenegociacaoTitulos({});
    setListaContasReceber([]);
    setPagadorId(null);
    setPagadorList(listaPessoas);
    retornoTopo();
  }

  const onDateChange = (date, fieldName) => {
    if (!date) {
      date = "Invalid Date";
    }
    const renegociacaoTemp = {
      ...renegociacaoTitulos,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    };
    setRenegociacaoTitulos(renegociacaoTemp);
    calculaTotalAReceber(listaContasReceber, date);
  };

  const onChangeCondicao = (value) => {
    setRenegociacaoTitulos({
      ...renegociacaoTitulos,
      quantidadeParcelas: value?.parcelas,
    });
    if (value?.meiosPagamentos) {
      setListaMeiosCondicao(value?.meiosPagamentos);
      setMeioPagamento(value?.meiosPagamentos[0]);
    }
    setCondicaoPagamento(value);
  };

  return (
    <>
      {renderRenegociacaoTitulos && (
        <div
          className="p-3 position-fixed bottom-0 bg-white d-flex flex-row flex-wrap"
          style={{
            width: openCollapse ? "calc(100vw - 292px)" : "calc(100vw - 77px)",
            transition: "width 400ms ease",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Autocomplete
                required
                id="condicaoPagamentoRenegociacao"
                name="condicaoPagamentoRenegociacao"
                options={listaCondicoesPagamento}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                getOptionSelected={(option, value) =>
                  option?.descricao === value?.descricao
                }
                disableClearable
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Condição de Pagamento"
                    variant="outlined"
                    margin="normal"
                    required
                    error={inputErros.get("condicaoPagamentoRenegociacao")}
                  />
                )}
                onChange={(_, newValue) => onChangeCondicao(newValue)}
                value={condicaoPagamento}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                id="meioPagamentoRenegociacao"
                name="meioPagamentoRenegociacao"
                required
                options={listaMeiosCondicao}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meio de Pagamento"
                    variant="outlined"
                    margin="normal"
                    required
                    error={inputErros.get("meioPagamentoRenegociacao")}
                  />
                )}
                onChange={(_, newValue) => setMeioPagamento(newValue)}
                value={meioPagamento}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                id="dataVencimento"
                name="dataVencimento"
                label="Data de Vencimento"
                format="dd/MM/yyyy"
                variant="outlined"
                margin
                required
                error={inputErro.indexOf("dataPagamento") != -1 ? true : false}
                fullWidth
                value={
                  renegociacaoTitulos?.dataVencimento
                    ? renegociacaoTitulos.dataVencimento
                    : null
                }
                onChange={(date) => onDateChange(date, "dataVencimento")}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                id="baixarTitulos"
                name="baixarTitulos"
                variant="contained"
                color="success"
                sx={{ p: "0.9rem", mt: "1rem" }}
                disabled={
                  !meioPagamento ||
                  !condicaoPagamento ||
                  !renegociacaoTitulos?.dataVencimento
                }
                onClick={handleOpenRenegociacao}
                fullWidth
              >
                <i
                  className="ph-fill ph-currency-dollar"
                  style={{ fontSize: 22, marginRight: 10, color: "white" }}
                ></i>
                Renegociar
              </Button>
            </Grid>
          </Grid>
          <RenegociacaoTitulosDialog
            openDialogRenegociacao={openDialogRenegociacao}
            setOpenDialogRenegociacao={setOpenDialogRenegociacao}
            listaRenegociacoes={listaRenegociacoes}
            setListaRenegociacoes={setListaRenegociacoes}
            handleSubmit={handleSubmit}
            sairRenegociacaoTitulos={sairRenegociacaoTitulos}
            listaMeiosPagamento={listaMeiosPagamento}
            pagadoresIguais={pagadoresIguais}
            pagadorList={pagadorList}
            pagadorId={pagadorId}
            setPagadorId={setPagadorId}
          />
          <ResumoTotalContas totalAReceber={totalAReceber} />
        </div>
      )}
    </>
  );
};

export default RenegociacaoTitulos;
