export const constantes = {
  naturezaFreteList: [
    "Operações de vendas, com ônus suportado pelo estabelecimento",
    "Operações de vendas, com ônus suportado pelo adquirinte",
    "Operações de compras (bens para revenda, matérias-primas e outros)",
    "Transferência de produtos acabados entre estabelecimentos da mesma empresa",
    "Transferência de produtos em elaboração entre estabelecimentos da pessoa jurídica",
  ],
  tipoFreteList: [
    "CT-e normal",
    "CT-e de complemento de valores",
    "CT-e de anulação de valores",
    "CT-e substituto",
    "Não se aplica",
  ],
  modalidadeFreteList: [
    "Contratação do frete por conta do remetente (CIF)",
    "Contratação do frete por conta do destinatário (FOB)",
    "Contratação do frete por conta de terceiros",
    "Transporte próprio por conta do remetente",
    "Transporte próprio por conta do destinatário",
    "Sem ocorrência de transporte",
  ],
};
