import {
  Autocomplete,
  Grid,
  TextFielDecimalNumbers,
  TextField,
} from "../../../../../../components";
import { formatPrice } from "../../../../../../utils";
import { nfSaidaTotalTributos } from "../../../../entities";

const ImpostoProdutoIpiTab = ({
  produto,
  produtoEditado,
  situacaoIpiList,
  enquadramentoLegalList,
  onChangeAutocompleteCodigo,
  onChangeInput,
  impostoValueFormat,
  nfSaida,
}) => {
  return (
    <>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={6}>
          <Autocomplete
            id="situacaoTributariaIpiId"
            name="situacaoTributariaIpiId"
            options={situacaoIpiList}
            autoHighlight
            getOptionLabel={(option) =>
              option ? `${option?.codigo} - ${option?.descricao}` : ""
            }
            getOptionSelected={(option, value) =>
              option?.descricao === value?.descricao
            }
            noOptionsText="Sem opções"
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Situação Tributaria - IPI"
                variant="outlined"
                margin="normal"
                disabled={!nfSaida?.documentoDevolucaoFornecedor}
                fullWidth
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocompleteCodigo("tributosIpiCst", newValue);
            }}
            value={impostoValueFormat(situacaoIpiList, "tributosIpiCst")}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="enquadramentoLegalId"
            name="enquadramentoLegalId"
            options={enquadramentoLegalList}
            autoHighlight
            getOptionLabel={(option) =>
              option ? `${option?.codigo} - ${option?.descricao}` : ""
            }
            getOptionSelected={(option, value) =>
              option?.descricao === value?.descricao
            }
            noOptionsText="Sem opções"
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enquadramento Legal"
                variant="outlined"
                margin="normal"
                disabled={!nfSaida?.documentoDevolucaoFornecedor}
                fullWidth
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocompleteCodigo("tributosIpiEnquadramento", newValue);
            }}
            value={impostoValueFormat(
              enquadramentoLegalList,
              "tributosIpiEnquadramento"
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="baseIpi"
            name="baseIpi"
            label="Base de IPI"
            variant="outlined"
            margin="normal"
            disabled={true}
            value={formatPrice(
              nfSaidaTotalTributos.calculaBaseIpi(produto, nfSaida)
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextFielDecimalNumbers
            id="tributosIpiAliquota"
            name="tributosIpiAliquota"
            label="Alíquota IPI"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.tributosIpiAliquota
                ? produtoEditado.tributosIpiAliquota
                : produto?.tributosIpiAliquota
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="valorIpi"
            name="valorIpi"
            label="Valor de IPI"
            variant="outlined"
            margin="normal"
            disabled={true}
            value={formatPrice(
              nfSaidaTotalTributos.calculaValorIpi(
                { ...produto, ...produtoEditado },
                nfSaida
              )
            )}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ImpostoProdutoIpiTab;
