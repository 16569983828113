import * as Yup from "yup";

const configuracoesValidator = Yup.object().shape({
  bancoCaixaId: Yup.number().nullable(),
  jurosPorcentagem: Yup.number().nullable(),
  multaPorcentagem: Yup.number().nullable(),
  obrigatoriedadeCnpjCpf: Yup.boolean().nullable(),
  vendaEstoqueNegativo: Yup.boolean().nullable(),
  impressaoDiretaPdv: Yup.boolean().nullable(),
  visualizarImpressaoEmissao: Yup.boolean().nullable(),
  margemCalculo: Yup.string().required("Tipo de margem"),
  precificacaoNfEntrada: Yup.boolean().nullable(),
  operacaoFiscalPdvPadraoId: Yup.number().nullable(),
  operacaoFiscalPdvSecundariaId: Yup.number().nullable(),
  operacaoFiscalComprasPadraoId: Yup.number().nullable(),
  operacaoFiscalVendasPadraoId: Yup.number().nullable(),
  cfopComprasPadraoId: Yup.number().nullable(),
  meioPagamentoInicial: Yup.number().nullable(),
  pagamentosPadroes: Yup.array().max(
    5,
    "Pagamentos Padrões com tamanho menor que 5"
  ),
});

export default configuracoesValidator;
