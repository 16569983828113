import { useState, useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  TextField,
  Grid,
  Card,
  CardContent,
  Switch,
} from "../../../../components";
import { InputErros, value } from "../../../../helpers";
import { OperacaoFiscalService } from "../../../../services";
import { responseErros } from "../../../../utils";
import "../../configuracoes.style.css";

const ConfiguracaoVendaTab = ({
  configuracao,
  onChangeAutocomplete,
  onChangeSwitch,
}) => {
  const operacaoFiscalService = new OperacaoFiscalService();
  const [inputErro, setInputErro] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscarOperacaoFiscal();
  }, []);

  const buscarOperacaoFiscal = () => {
    const filtros = {
      ativado: true,
      restritiva: true,
    };
    operacaoFiscalService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <Card className="p-2">
      <CardContent>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            VEN001 - Operação fiscal padrão.
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                id="operacaoFiscalVendasPadraoId"
                name="operacaoFiscalVendasPadraoId"
                options={operacaoFiscalList}
                noOptionsText="Sem opções"
                autoHighlight
                disableClearable
                getOptionLabel={(option) => (option ? option.descricao : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operação Fiscal"
                    variant="outlined"
                    error={inputErros.get("operacaoFiscalVendasPadraoId")}
                  />
                )}
                onChange={(_, newValue) => {
                  setInputErro([]);
                  onChangeAutocomplete(
                    "operacaoFiscalVendasPadraoId",
                    newValue
                  );
                }}
                value={value.autoComplete(
                  operacaoFiscalList,
                  configuracao?.operacaoFiscalVendasPadraoId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            VEN002 - Permitir a venda de produtos com estoque negativo.
          </Grid>
          <Grid item xs={4}>
            <Switch
              id="vendaEstoqueNegativo"
              name="vendaEstoqueNegativo"
              checked={
                configuracao?.vendaEstoqueNegativo
                  ? configuracao?.vendaEstoqueNegativo
                  : false
              }
              onChange={onChangeSwitch}
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"config-card"}>
          <Grid item xs={8}>
            VEN003 - Visualizar impressão ao emitir nota.
          </Grid>
          <Grid item xs={4}>
            <Switch
              id="visualizarImpressaoEmissao"
              name="visualizarImpressaoEmissao"
              checked={
                configuracao?.visualizarImpressaoEmissao
                  ? configuracao?.visualizarImpressaoEmissao
                  : false
              }
              onChange={onChangeSwitch}
              color="primary"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ConfiguracaoVendaTab;
