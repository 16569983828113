import { useRef, useEffect } from "react";

function Form({ id, onSubmit, resetForm, children }) {
  const formRef = useRef(null);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.reset();
  }, [resetForm]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formEntries = Object.fromEntries(formData.entries());
    onSubmit(formEntries);
  };

  return (
    <form
      id={id ? id : "form"}
      ref={formRef}
      noValidate
      onSubmit={handleSubmit}
    >
      {children}
    </form>
  );
}

export default Form;
