import {
  Grid,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField,
} from "../../../../components";
import { value } from "../../../../helpers";

const DocumentoFreteFinanceiro = ({
  documentoFrete,
  setDocumentoFrete,
  planosFinanceirosList,
  centroCustoList,
}) => {
  const onChangeCheckbox = (e) => {
    const documentoFreteTemp = {
      ...documentoFrete,
      [e.target.name]: e.target.checked,
    };
    if (e.target.name === "movimentacaoFinanceira" && !e.target.checked) {
      documentoFreteTemp.planoFinanceiroId = null;
      documentoFreteTemp.centroCustoId = null;
    }
    setDocumentoFrete(documentoFreteTemp);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} className="d-flex align-items-center">
        <FormControlLabel
          control={
            <Checkbox
              checked={documentoFrete?.movimentacaoFinanceira || false}
              value={documentoFrete?.movimentacaoFinanceira}
              onChange={onChangeCheckbox}
              name="movimentacaoFinanceira"
              id="movimentacaoFinanceira"
              color="primary"
            />
          }
          label="Movimentação Financeira"
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          id="planoFinanceiroId"
          name="planoFinanceiroId"
          options={planosFinanceirosList}
          noOptionsText="Sem opções"
          getOptionLabel={(option) => (option ? option?.descricao : "")}
          autoHighlight
          disabled={!documentoFrete?.movimentacaoFinanceira}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Plano Financeiro"}
              margin="normal"
              variant={
                !documentoFrete?.movimentacaoFinanceira ? "filled" : "outlined"
              }
            />
          )}
          onChange={(e, { id }) => {
            e.persist();
            setDocumentoFrete((prevState) => ({
              ...prevState,
              planoFinanceiroId: id,
            }));
          }}
          value={value.autoComplete(
            planosFinanceirosList,
            documentoFrete?.planoFinanceiroId
          )}
        />
      </Grid>
      <Grid item xs={5}>
        <Autocomplete
          id="centroCustoId"
          name="centroCustoId"
          options={centroCustoList}
          noOptionsText="Sem opções"
          autoHighlight
          disabled={!documentoFrete?.movimentacaoFinanceira}
          getOptionLabel={(option) => (option ? option?.descricao : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Centro de Custo"}
              margin="normal"
              variant={
                !documentoFrete?.movimentacaoFinanceira ? "filled" : "outlined"
              }
            />
          )}
          onChange={(e, { id }) => {
            e.persist();
            setDocumentoFrete((prevState) => ({
              ...prevState,
              centroCustoId: id,
            }));
          }}
          value={value.autoComplete(
            centroCustoList,
            documentoFrete?.centroCustoId
          )}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentoFreteFinanceiro;
