import * as Yup from "yup";

const fornecedorValidator = Yup.object().shape({
  limiteCompra: Yup.number().nullable(),
  gruposPessoas: Yup.array()
    .of(Yup.object({ id: Yup.number().required() }))
    .nullable(),
});

export default fornecedorValidator;
