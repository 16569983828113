import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "../../../../components";
import {
  PessoaService,
  MeioPagamentoService,
  BancosCaixasService,
} from "../../../../services";
import { formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";
import { listaStatus, listaTiposContasReceber } from "./entities/constantes";

const pessoaService = new PessoaService();
const bancosCaixasService = new BancosCaixasService();
const meioPagamentoService = new MeioPagamentoService();

const FormFiltroAvancadoContasReceber = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const [clienteList, setClienteList] = useState([]);
  const [contasList, setContasList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [listaStatusFiltrado, setListaStatusFiltrado] = useState(listaStatus);

  useEffect(() => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      status: [],
      tipo: "TITULO_NORMAL",
    });
  }, []);

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.value ?? null,
    }));
  };

  function resetarFiltrosDeStatus() {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp.status;
      return prevStateTemp;
    });
    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp["STATUS"];
      return prevStateTemp;
    });
    setListaStatusFiltrado(listaStatus);
  }

  const isStatusParcial = (status) =>
    status.value !== "BAIXADO_PARCIAL" &&
    (filtrosAvancados.status?.length || 0) <= 1 &&
    (status.value === "ABERTO" || status.value === "BAIXADO");

  const onChangeMultipleStatus = (valores = []) => {
    if (!valores.length) {
      resetarFiltrosDeStatus();
      return;
    }
    const addValorParcial = valores.every((status) => isStatusParcial(status));
    const updatedValores = addValorParcial
      ? [
          ...valores,
          {
            id: 2,
            label: "Baixado Parcial",
            value: "BAIXADO_PARCIAL",
            cor: "#EA9D2B",
          },
        ]
      : valores;
    updateFilters(updatedValores);
    updateListaStatus(updatedValores);
  };

  const updateFilters = (valores) => {
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      status: valores,
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      STATUS: valores.map((valor) => {
        return (
          <div key={valor.id} className="d-flex align-items-center">
            <i
              className="ph-fill ph-circle"
              style={{ color: `${valor.cor}`, fontSize: 10 }}
            ></i>
            {valor.label}
          </div>
        );
      }),
    }));
  };

  const updateListaStatus = (valores) => {
    const listaTemp = listaStatus.filter(
      (status) => !valores.some((updateStatus) => updateStatus.id === status.id)
    );
    setListaStatusFiltrado(listaTemp);
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value
        ? value.nomeRazaoSocial ?? value.descricao ?? value.nome
        : undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      if (
        id.includes("Start") &&
        (!filtroAvancado[id.replace("Start", "End")] ||
          filtroAvancado[id.replace("Start", "End")] === "")
      ) {
        filtroAvancado[id.replace("Start", "End")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Inicial", "Final")] = format(
          date,
          "dd/MM/yyyy"
        );
      }
      if (
        id.includes("End") &&
        (!filtroAvancado[id.replace("End", "Start")] ||
          filtroAvancado[id.replace("End", "Start")] === "")
      ) {
        filtroAvancado[id.replace("End", "Start")] = formatDate.toSend(date);
        filtroImpressao[name.replace("Final", "Inicial")] = format(
          date,
          "dd/MM/yyyy"
        );
      }
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  useEffect(() => {
    buscarClientes();
    buscarMeiosPagamento();
    buscarContas();
  }, []);

  const buscarClientes = () => {
    const filtros = {
      nonPaginated: true,
      tiposPessoaId: 1,
    };
    pessoaService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setClienteList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    meioPagamentoService.getAll({ ativado: true }).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarContas = () => {
    const filtro = {
      nonPaginated: true,
    };
    bancosCaixasService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setContasList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="pessoaClienteId"
            name="Pagador"
            options={clienteList}
            noOptionsText="Sem opções"
            autoHighlight
            margin="normal"
            getOptionLabel={(option) =>
              option.nomeRazaoSocial ? option.nomeRazaoSocial : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pagador"
                variant="outlined"
                margin="normal"
              />
            )}
            onChange={(e, value) =>
              onChangeAutocomplete("pessoaClienteId", value, "Pagador")
            }
            value={valueAutocomplete(clienteList, "pessoaClienteId")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamentoId"
              name="Meio-de-Pagamento"
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.id && option.descricao
                  ? `${option.id} - ${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meios de pagamento"
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete(
                  "meioPagamentoId",
                  value,
                  "Meio-de-Pagamento"
                )
              }
              value={valueAutocomplete(meioPagamentoList, "meioPagamentoId")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="contas"
              name="Conta"
              options={contasList}
              autoHighlight
              getOptionLabel={(option) => option.nome}
              getOptionSelected={(option, value) =>
                option?.nome === value?.nome
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField {...params} label="Conta" variant="outlined" />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("contas", value, "Conta")
              }
              value={valueAutocomplete(contasList, "contas")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStartLancamento"
            name="Data-Emissão-de-Lançamento"
            label="Lançamento Inicio"
            margin
            fullWidth
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoStartLancamento")}
            onChange={(date) =>
              onChangeDate(
                "dataEmissaoStartLancamento",
                date,
                "Data-de-Lançamento-Inicial"
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEndLancamento"
            name="Data-Emissão-até-Lançamento"
            label="Lançamento Final"
            margin
            fullWidth
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoEndLancamento")}
            onChange={(date) =>
              onChangeDate(
                "dataEmissaoEndLancamento",
                date,
                "Data-de-Lançamento-Final"
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStartVencimento"
            name="Data-Emissão-de-Vencimento"
            label="Vencimento Inicio"
            margin
            fullWidth
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoStartVencimento")}
            onChange={(date) =>
              onChangeDate(
                "dataEmissaoStartVencimento",
                date,
                "Data-de-Vencimento-Inicial"
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEndVencimento"
            name="Data-Emissão-até-Vencimento"
            label="Vencimento Final"
            margin
            fullWidth
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoEndVencimento")}
            onChange={(date) =>
              onChangeDate(
                "dataEmissaoEndVencimento",
                date,
                "Data-de-Vencimento-Final"
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoStartPagamento"
            name="Data-Emissão-de-Pagamento"
            label="Pagamento Inicio"
            margin
            fullWidth
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoStartPagamento")}
            onChange={(date) =>
              onChangeDate(
                "dataEmissaoStartPagamento",
                date,
                "Data-de-Pagamento-Inicial"
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            id="dataEmissaoEndPagamento"
            name="Data-Emissão-até-Pagamento"
            label="Pagamento Final"
            margin
            fullWidth
            format="dd/MM/yyyy"
            value={valueDate("dataEmissaoEndPagamento")}
            onChange={(date) =>
              onChangeDate(
                "dataEmissaoEndPagamento",
                date,
                "Data-de-Pagamento-Final"
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              multiple
              id="status"
              name="status"
              options={listaStatusFiltrado}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => {
                return (
                  <div>
                    <i
                      className="ph-fill ph-circle"
                      style={{ color: `${option.cor}`, marginRight: "10px" }}
                    ></i>
                    {option.label}
                  </div>
                );
              }}
              onChange={(_, newValue) => onChangeMultipleStatus(newValue)}
              value={filtrosAvancados?.status ? filtrosAvancados.status : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  label="Status"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Select
            label="Tipo"
            id="tipo"
            name="tipo"
            margin="normal"
            variant="outlined"
            value={valueInput("tipo")}
            onChange={(event) => onChangeSelect(event, "Tipo")}
          >
            {listaTiposContasReceber.map((tipo, index) => (
              <MenuItem key={index} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiltroAvancadoContasReceber;
