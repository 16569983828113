import { useState } from "react";
import Barcode from "jsbarcode";
import { formatCnpj, formatCpf } from "../../../../../../../utils";
import "../modelo-nfe-espelho.scss";

const CabecalhoNfeEspelho = ({ empresa, data, totalPaginas, paginaAtual }) => {
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };
  const logoTdClass =
    aspectRatio <= 1.5 ? "danfe_celula_borda_canto_esquerdo" : "";
  const infoTdClass =
    aspectRatio <= 1.5
      ? "danfe_celula_borda_topo"
      : "danfe_celula_borda_canto_esquerdo";
  const nomeEmpresa =
    aspectRatio <= 1.5 ? "danfe_nome_empresa_esquerdo" : "danfe_nome_empresa";
  const divEnderecoClass =
    aspectRatio <= 1.5 ? "div_endereco_empresa" : "div_endereco_ret";
  const logoImage = empresa?.imagemLogo ? (
    <img
      className={
        aspectRatio <= 1.5 ? "danfe_logo_empresa" : "danfe_logo_empresa_ret"
      }
      src={empresa.imagemLogo}
      alt="Logo da Empresa"
      onLoad={aspectLogo}
    />
  ) : null;
  return (
    <table
      className="danfe_tabelas"
      id={paginaAtual === 1 ? "danfe_cabecalho" : "cabecalho"}
    >
      <tr>
        {aspectRatio <= 1.5 ? (
          <td rowSpan="3" className={logoTdClass}>
            {logoImage}
          </td>
        ) : null}
        <td
          rowSpan="3"
          colSpan={aspectRatio >= 1.5 ? "2" : "1"}
          className={infoTdClass}
        >
          {aspectRatio >= 1.5 && <td>{logoImage}</td>}
          <h3 className={`${nomeEmpresa}`}>{empresa?.razaoSocial}</h3>
          <div className={divEnderecoClass}>
            <span>
              {empresa?.enderecoLogradouro},{empresa?.enderecoLogradouroNumero}
            </span>
            <span>
              {empresa?.enderecoBairro} -{empresa?.enderecoCep}
              <br />
            </span>
            <span>
              {empresa?.enderecoCidade} -{empresa?.enderecoUF}
              <br />
              {empresa?.telefone}
              <br />
            </span>
          </div>
        </td>
        <td
          rowSpan="3"
          className="danfe_celula_bordas"
          style={{ padding: "10px 5px 0 5px", width: "10%" }}
        >
          <p className="danfe_cabecalho_danfe">DANFE</p>
          <p className="danfe_cabecalho_danfe_texto">
            Documento Auxiliar da <br />
            Nota Fiscal Eletr&ocirc;nica
          </p>
          <div>
            <div className="danfe_cabecalho_entrada_saida">
              0-Entrada
              <br />
              1-Sa&iacute;da
            </div>
            <div className="danfe_cabecalho_entrada_saida_quadrado">
              {data?.tipoOperacao}
            </div>
          </div>
          <p className="danfe_cabecalho_numero">
            N&ordm; {data?.numeroDocumento}
          </p>
          <p className="danfe_cabecalho_danfe_texto">
            SERIE: {data?.serieDocumento}
            <br />
            P&aacute;gina: {paginaAtual} de {totalPaginas}
          </p>
        </td>
        <td className="danfe_celula_bordas barra_chave">
          <svg
            ref={(node) => {
              if (node && data?.chaveAcesso) {
                Barcode(node, data.chaveAcesso, {
                  format: "CODE128",
                  width: 1,
                  height: 45,
                  displayValue: false,
                });
              }
            }}
          />
        </td>
      </tr>
      <tr>
        <td className="danfe_celula_bordas barra_chave" align="center">
          <p className="danfe_celula_titulo">Chave de acesso</p>
          <p className="danfe_celula_valor valor_negrito">
            {data?.chaveAcesso}
          </p>
        </td>
      </tr>
      <tr>
        <td className="danfe_celula_bordas barra_chave" align="center">
          <p className="danfe_celula_titulo">
            Consulta de autenticidade no portal nacional da NF-e&nbsp;
            www.nfe.fazenda.gov.br/portal ou no site da Sefaz autorizadora
          </p>
        </td>
      </tr>
      <tr>
        <td colSpan="3" className="danfe_celula_borda_canto_esquerdo">
          <p className="danfe_celula_titulo">
            Natureza da opera&ccedil;&atilde;o
          </p>
          <p className="danfe_celula_valor">{data?.naturezaOperacao}</p>
        </td>
        <td className="danfe_celula_bordas" align="center">
          <p className="danfe_celula_titulo">
            N&uacute;mero de protocolo de autoriza&ccedil;&atilde;o de uso da
            NF-e
          </p>
          <p className="danfe_celula_valor valor_negrito">{data?.protocolo}</p>
        </td>
      </tr>
      <tr>
        <td colSpan="2" className="danfe_celula_borda_top_left_bot">
          <p className="danfe_celula_titulo">
            Inscri&ccedil;&atilde;o Estadual
          </p>
          <p className="danfe_celula_valor">{empresa?.inscricaoEstadual}</p>
        </td>
        <td className="danfe_celula_bordas">
          <p className="danfe_celula_titulo">
            Inscri&ccedil;&atilde;o Estadual ST
          </p>
          <p className="danfe_celula_valor">{empresa?.ieStEmitente}</p>
        </td>
        <td className="danfe_celula_bordas">
          <p className="danfe_celula_titulo">CNPJ</p>
          <p className="danfe_celula_valor">
            {empresa.cnpjCpf?.length === 11
              ? formatCpf(empresa.cnpjCpf)
              : formatCnpj(empresa.cnpjCpf)}
          </p>
        </td>
      </tr>
    </table>
  );
};

export default CabecalhoNfeEspelho;
