import { Step as StepMui } from "@mui/material";

const Step = ({
  className,
  children,
  active,
  classes,
  completed,
  component,
  disabled,
  expanded,
  index,
  last,
  sx,
  key,
}) => {
  return (
    <StepMui
      className={className}
      classes={classes}
      active={active}
      completed={completed}
      component={component}
      disabled={disabled}
      expanded={expanded}
      index={index}
      last={last}
      sx={sx}
      key={key}
    >
      {children}
    </StepMui>
  );
};

export default Step;
