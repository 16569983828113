import { List as ListMui } from "@mui/material";

const List = ({
  children,
  classes,
  component,
  dense,
  disablePadding,
  subheader,
  ref,
  style,
  sx,
  className,
}) => {
  return (
    <ListMui
      classes={classes}
      component={component}
      dense={dense}
      disablePadding={disablePadding}
      subheader={subheader}
      ref={ref}
      style={style}
      sx={sx}
      className={className}
    >
      {children}
    </ListMui>
  );
};

export default List;
