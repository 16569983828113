import { Grid, TextField } from "../../../../components";

const FormFiltroAvancado = ({ valueInput, onChangeInput }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="descricao"
          name="descricao"
          label="Descrição"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("descricao")}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
