import { useState, useEffect } from "react";
import { Grid } from "../../../../components";
import { SessionService } from "../../../../services";
import { value } from "../../../../helpers";
import { format } from "date-fns";
import {
  formatCep,
  formatCelular,
  formatCpf,
  formatPrice,
  formatCnpj,
  formatTelefone,
} from "../../../../utils";
import "../../../orcamentos/addOrcamento/components/mainPrintLayout.style.css";

const MainPrintLayout = ({ data }) => {
  const sessionService = new SessionService();
  const [empresa, setEmpresa] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };

  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  return (
    empresa &&
    data && (
      <>
        <Grid
          item
          md={12}
          xs={12}
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex justify-content-between align-items-center">
            {empresa?.imagemLogo ? (
              <img
                className="me-3"
                src={empresa.imagemLogo}
                alt="logo da empresa"
                onLoad={aspectLogo}
                width={100}
                height={aspectRatio <= 1.5 ? 100 : 20}
              />
            ) : null}
            <div>
              <b>{empresa.fantasia}</b>
              <br />
              cnpj - {formatCnpj(empresa.cnpj)}
              <br />
              {`${empresa.enderecoLogradouro} - ${empresa.enderecoLogradouroNumero} - ${empresa.enderecoBairro} - ${empresa.enderecoCidade}-${empresa.enderecoUF}`}
            </div>
          </div>
          <div className="me-5">
            {formatTelefone(empresa.telefone)}
            <br />
            {empresa.email}
            <br />
            {data?.vendedor ? <b>Vendedor - {data.vendedor}</b> : ""}
          </div>
        </Grid>
        <Grid item md={12} xs={12} className="mt-4">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="d-flex justify-content-between align-items-center">
                  <span className="fs-5 fw-bold">{`PEDIDO Nº ${data.numeroDocumento}`}</span>
                  <span className="fw-bold">
                    {format(
                      new Date(Date.parse(data.dataEmissao)),
                      "dd/MM/yyyy HH:MM"
                    )}
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className="d-flex justify-content-between align-items-center mt-2"
        ></Grid>
        <Grid item md={12} xs={12} className="mt-4 orcamento-print-fs-07">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="p-0">DADOS CLIENTE</th>
              </tr>
            </thead>
          </table>
        </Grid>
        <div className="flex-print-container orcamento-print-fs-07">
          <div className="container-item-50">
            <div>
              <div>
                <b className="me-2 fs-7">Cliente:</b>
                <span className="fs-7 fw-normal">
                  {data.cliente?.nomeRazaoSocial}
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">Endereço:</b>
                <span className="fs-7 fw-normal">
                  {Object.keys(data?.enderecoCliente || {})?.length ? (
                    <>
                      {data.enderecoCliente?.logradouro
                        ? `${data.enderecoCliente.logradouro},`
                        : ""}
                      {data.enderecoCliente?.numero
                        ? `${data.enderecoCliente.numero},`
                        : ""}
                      {data.enderecoCliente?.cidade
                        ? `${data.enderecoCliente.cidade} -`
                        : ""}
                      {data.enderecoCliente?.estado
                        ? `${data.enderecoCliente.estado}`
                        : ""}
                      <br></br>
                      <b className="me-2 fs-7">CEP:</b>
                      <span className="fs-7 fw-normal">
                        {formatCep(
                          String(data.enderecoCliente?.cep ?? "Não Informado")
                        )}
                      </span>
                    </>
                  ) : (
                    "Não Informado"
                  )}
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">Telefone:</b>
                <span className="fs-7 fw-normal">
                  {`${formatTelefone(data.cliente?.telefone) ?? ""} ${
                    data.cliente?.celular
                      ? " | " + formatCelular(data.cliente?.celular)
                      : ""
                  }`}
                </span>
              </div>
            </div>
          </div>
          <div className="container-item-40">
            <div>
              <div>
                <b className="me-2 fs-7">CNPJ/CPF:</b>
                <span className="fs-7 fw-normal">
                  {data.cliente?.naturezaSocial === "fisica"
                    ? formatCpf(data.cliente?.cnpjCpf)
                    : formatCnpj(data.cliente?.cnpjCpf)}
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">Estado:</b>
                <span className="fs-7 fw-normal">
                  {data.estadoCliente ?? ""}
                </span>
              </div>
              <div>
                <b className="me-2 fs-7">E-mail:</b>
                <span className="fs-7 fw-normal">{data.cliente?.email}</span>
              </div>
            </div>
          </div>
        </div>
        <Grid item md={12} xs={12} className="mt-4 orcamento-print-fs-07">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="p-0">PRODUTOS</th>
              </tr>
            </thead>
          </table>
        </Grid>
        <Grid item md={12} xs={12}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>ITEM</th>
                <th style={{ width: "40%" }}>DESCRIÇÃO</th>
                <th>UND.</th>
                <th>QTD.</th>
                <th style={{ width: "20%" }}>VR. UNIT.</th>
                <th>SUBTOTAL</th>
              </tr>
            </thead>
            <tbody>
              {data?.itens?.length &&
                data?.itens.map((item) => (
                  <tr key={item.id} className="orcamento-print-fs-07">
                    <td>{item.codigo}</td>
                    <td>{item.descricao}</td>
                    <td className="text-end">{item.unidade}</td>
                    <td className="text-center">{item.quantidade}</td>
                    <td className="text-end">
                      {typeof item.valorUnitario == "number"
                        ? item.valorUnitario.toFixed(2)
                        : 0}
                    </td>
                    <td className="text-end">
                      {typeof item.totalItem == "number"
                        ? item.totalItem.toFixed(2)
                        : 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className="mt-4 d-flex flex-column align-items-end"
        >
          <div className="d-flex">
            <b className="me-2 fs-7">VALOR TOTAL DOS PRODUTOS:</b>
            <span className="fs-6 fw-normal">
              {formatPrice(data?.totalItens) ?? "R$ 0,00"}
            </span>
          </div>
          <div className="d-flex">
            <b className="me-2 fs-7">DESCONTO:</b>
            <span className="fs-6 fw-normal">
              {formatPrice(data?.desconto) ?? "R$ 0,00"}
            </span>
          </div>
          <div className="d-flex">
            <b className="me-2 fs-7">ACRÉSCIMO:</b>
            <span className="fs-6 fw-normal">
              {formatPrice(data?.acrescimo) ?? "R$ 0,00"}
            </span>
          </div>
          <div className="d-flex">
            <b className="me-2 fs-7">DESPESAS:</b>
            <span className="fs-6 fw-normal">
              {formatPrice(data?.despesas) ?? "R$ 0,00"}
            </span>
          </div>
          <div className="d-flex">
            <b className="me-2 fs-7">TOTAL DA NOTA:</b>
            <span className="fs-6 fw-normal">
              {data.totalPedido ? formatPrice(data.totalPedido) : "R$ 0,00"}
            </span>
          </div>
        </Grid>
        <Grid item md={12} xs={12} className="mt-4 orcamento-print-fs-07">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="p-0">
                  <b>DADOS DE PAGAMENTO</b>
                </th>
              </tr>
            </thead>
          </table>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className="mt-2 d-flex justify-content-between"
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Nº TÍTULO:</th>
                <th scope="col">VENCIMENTO:</th>
                <th scope="col">VALOR:</th>
                <th scope="col">MEIO DE PAGAMENTO:</th>
              </tr>
            </thead>
            <tbody>
              {data.pagamentos &&
                data.pagamentos.length > 0 &&
                data.pagamentos.map((parcela) => (
                  <tr key={parcela.id}>
                    <td>{parcela.numeroTitulo}</td>
                    <td>
                      {new Date(
                        Date.parse(parcela.vencimento)
                      ).toLocaleDateString("pt-BR")}
                    </td>
                    <td>
                      {parcela.valorTitulo
                        ? formatPrice(parcela.valorTitulo)
                        : "R$ 0,00"}
                    </td>
                    <td>
                      {
                        value.autoComplete(
                          data.meioPagamentoList,
                          parcela.meioPagamentoId
                        )?.descricao
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Grid>
        <Grid item md={12} xs={12} className="mt-4 orcamento-print-fs-07">
          <table className="table">
            <thead style={{ background: "#E7E5E6" }}>
              <tr>
                <th className="p-0">OBSERVAÇÕES</th>
              </tr>
            </thead>
          </table>
        </Grid>
        <Grid item md={12} xs={12} className="mt-2">
          <span className="fs-6 fw-normal">{data.informacaoAdicional}</span>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className="mt-4 d-flex flex-column justify-content-center"
        >
          <div
            style={{
              width: "75%",
              borderTop: "1px solid #666",
              marginTop: 24,
              marginBottom: 8,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></div>
          <span className="fs-6 fw-normal text-center">
            Assinatura do Cliente
          </span>
        </Grid>
        <Grid item md={12} xs={12} className="mt-5 d-flex justify-content-end">
          <span className="fs-6 fw-light">
            Pedido emitido no SIAF LITE – www.siaflite.com.br
          </span>
        </Grid>
      </>
    )
  );
};

export default MainPrintLayout;
