import { UsuarioService } from "../services";
import { responseErros } from "./index";
const usuarioService = new UsuarioService();

async function userLogs(data) {
  const userLogTemp = {
    createdAt: data?.createdAt,
    updatedAt: data?.updatedAt,
  };
  const filter = {
    id: [data?.insertUserId],
    nonPaginated: true,
  };
  if (data?.updateUserId) {
    filter.id.push(data?.updateUserId);
  }
  return usuarioService.getAll(filter).then((res) => {
    if (!res.isAxiosError) {
      if (res.data?.length || res.data?.count > 0) {
        const userCreatedLog = res?.data?.find(
          (user) => user.id == data?.insertUserId
        );
        userLogTemp.userCreatedLog = userCreatedLog;
        if (data?.updateUserId) {
          const userEditedLog = res.data?.find(
            (user_1) => user_1.id == data?.updateUserId
          );
          userLogTemp.userEditedLog = userEditedLog;
        }
      }
      return userLogTemp;
    } else {
      responseErros(res);
      return null;
    }
  });
}

export default userLogs;
