import { InputBase as InputBaseMui } from "@mui/material";

const InputBase = ({
  classes,
  inputComponent,
  inputProps,
  multiline,
  rows,
  rowsMax,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
  disabled,
  readOnly,
  fullWidth,
  autoFocus,
  placeholder,
  startAdornment,
  endAdornment,
  error,
  margin,
  size,
  sx,
}) => {
  return (
    <InputBaseMui
      classes={classes}
      inputComponent={inputComponent}
      inputProps={inputProps}
      multiline={multiline}
      rows={rows}
      maxRows={rowsMax}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      readOnly={readOnly}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      placeholder={placeholder}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      error={error}
      margin={margin}
      size={size}
      sx={sx}
    />
  );
};

export default InputBase;
