import "../modelo-carta-correcao.scss";

const CondicoesUso = () => {
  return (
    <div>
      <h3 className="danfe_titulo_externo">CONDIÇÕES DE USO</h3>
      <table className="danfe_tabelas">
        <tr style={{ height: "110px" }}>
          <td className="danfe_celula_bordas">
            <p className="danfe_celula_titulo">
              A Carta de Correcao e disciplinada pelo paragrafo 1o-A do art. 7o
              do Convenio S/N, de 15 de dezembro de 1970 e pode ser utilizada
              para
              <br /> regularizacao de erro ocorrido na emissao de documento
              fiscal, desde que o erro nao esteja relacionado com:
              <br /> I - as variaveis que determinam o valor do imposto tais
              como: base de calculo, aliquota, diferenca de preco, quantidade,
              valor da operacao ou da prestacao
              <br /> II - a correcao de dados cadastrais que implique mudanca do
              remetente ou do destinatario
              <br /> III - a data de emissao ou de saida.
            </p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default CondicoesUso;
