import { useState, useEffect } from "react";
import { SessionService } from "../../../../services";
import "./relatorio-caixa-diario.css";
import { formatDate, formatPrice, formatTelefone } from "../../../../utils";
import { format } from "date-fns";
import { value } from "../../../../helpers";

const RelatorioCaixaDiario = ({ body }) => {
  const sessionService = new SessionService();
  const [empresaUsuario, setEmpresaUsuario] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(0);
  const aspectLogo = (event) => {
    setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
  };

  useEffect(() => {
    const empresa = sessionService.getEmpresa();
    const usuario = sessionService.getUsuario();
    setEmpresaUsuario({ ...empresa, usuario });
  }, []);

  return empresaUsuario && body ? (
    <div>
      <div id="relatorio_caixa_diario" style={{ padding: "0.5rem 0.5rem" }}>
        {empresaUsuario?.imagemLogo ? (
          <img
            src={empresaUsuario?.imagemLogo}
            alt="Logo Empresa"
            onLoad={aspectLogo}
            style={{
              width: "100px",
              height: aspectRatio <= 1.5 ? "100px" : "20px",
              margin: "10px 0 10px",
            }}
          />
        ) : null}
        <div>
          <h6>{empresaUsuario?.razaoSocial}</h6>
          <h6>CNPJ: {empresaUsuario?.cnpjCpf}</h6>
          <h6>
            {empresaUsuario?.enderecoLogradouro},{" "}
            {empresaUsuario?.enderecoLogradouroNumero}{" "}
            {empresaUsuario?.enderecoBairro} - {empresaUsuario?.enderecoCidade}{" "}
            - {empresaUsuario?.enderecoUF}
          </h6>
          <h6>
            {empresaUsuario?.telefone
              ? formatTelefone(empresaUsuario.telefone)
              : ""}
          </h6>
        </div>
        <div className="my-2 border-hr"></div>
        <div>
          <h6>
            PERIODO: {format(formatDate.received(body.data), "dd/MM/yyyy")}
          </h6>
          <h6>
            CAIXA:{" "}
            {value.autoComplete(body.contaLista, body.bancoCaixaId)?.nome}
          </h6>
          <h6>USUARIO: {empresaUsuario.usuario?.nome}</h6>
        </div>
        <div className="my-2 border-hr"></div>
        <h5>
          <strong>RESUMO DE VENDAS</strong>
        </h5>
        <div className="my-2 border-hr"></div>
        <div className="w-100">
          <h6 className="mb-2">
            <strong>FATURAMENTO</strong>
          </h6>
          <div className="listagemValores">
            <h6>SUPRIMENTO</h6>
            <h6 className="listaValor">{formatPrice(body.totalSuprimento)}</h6>
          </div>
          <div className="listagemValores">
            <h6>SANGRIA</h6>
            <h6 className="listaValor">{formatPrice(body.totalSangria)}</h6>
          </div>
          <div className="listagemValores">
            <h6>TOTAL ENTRADAS</h6>
            <h6 className="listaValor">{formatPrice(body.totalEntradas)}</h6>
          </div>
          <div className="listagemValores">
            <h6>TOTAL SAÍDAS</h6>
            <h6 className="listaValor">{formatPrice(body.totalSaidas)}</h6>
          </div>
        </div>
        <div className="my-2 border-hr"></div>
        <div className="w-100">
          <h6>RESUMO FINAL</h6>
          <div className="listagemValores mt-2">
            <h6>TOTAL(FAT.+SUPRI.-SANGRIA-DEV.)</h6>
            <h6 className="listaValor">{formatPrice(body.saldoDia)}</h6>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default RelatorioCaixaDiario;
