import { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  Button,
  Tooltip,
  Autocomplete,
} from "../../../components";
import { ClassificacaoFinanceiraService } from "../../../services";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import planoFinanceiroValidator from "./middlewares/plano-financeiro.validator";
import { responseErros } from "../../../utils";
import { PlanoFinanceiro } from "./entitites";

const AddPlanoFinanceiro = ({
  planoFinanceiro,
  setPlanoFinanceiro,
  planosFinanceiros,
  handleCloseDialog,
  atualizarPlanosFinanceiros,
  cadastrarPlanosFinanceiros,
}) => {
  const [inputErro, setInputErro] = useState([]);
  const [listaClassificacao, setListaClassificacao] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const classificacaoFinanceiraService = new ClassificacaoFinanceiraService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("planos-financeiros-visualizar");

  useEffect(() => {
    buscarClassificassoesFinanceiras();
    if (!planoFinanceiro.id) {
      setPlanoFinanceiro({
        ativado: true,
      });
    }
  }, []);

  const buscarClassificassoesFinanceiras = () => {
    const filtro = {
      nonPaginated: true,
    };
    classificacaoFinanceiraService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaClassificacao(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onChangeInput = (event) => {
    setPlanoFinanceiro({
      ...planoFinanceiro,
      [event.target.name]: event.target.value ? event.target.value : undefined,
    });
  };

  const onChangeAutocomplete = (name, value) => {
    setPlanoFinanceiro({
      ...planoFinanceiro,
      [name]: value ? value.id : undefined,
    });
  };

  const handleChangeSwitch = (event) => {
    setPlanoFinanceiro({
      ...planoFinanceiro,
      [event.target.name]: event.target.checked,
    });
  };

  const onSubmit = () => {
    const planoFinanceiroDto = new PlanoFinanceiro(planoFinanceiro);
    planoFinanceiroValidator
      .validate(planoFinanceiroDto, { abortEarly: false })
      .then(async () => {
        if (planoFinanceiro.id) {
          atualizarPlanosFinanceiros(planoFinanceiroDto);
        } else {
          cadastrarPlanosFinanceiros(planoFinanceiroDto);
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  return (
    <Container>
      <Card className="p-3">
        <CardHeader
          title={
            planoFinanceiro.id
              ? "Editar Plano Financeiro"
              : "Cadastrar Plano Financeiro"
          }
          action={
            <>
              <FormControlLabel
                control={
                  <Switch
                    id="ativado"
                    name="ativado"
                    checked={planoFinanceiro.ativado ? true : false}
                    onChange={handleChangeSwitch}
                    color="primary"
                  />
                }
                label={planoFinanceiro?.ativado ? "Ativado" : "Desativado"}
              />
            </>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="descricao"
                name="descricao"
                label="Descrição"
                variant="outlined"
                value={value.text(planoFinanceiro.descricao)}
                onChange={onChangeInput}
                fullWidth
                required
                error={inputErros.get("descricao")}
              />
            </Grid>
            <Tooltip
              title="Selecione o Plano Financeiro Superior (opcional)"
              arrow
              placement="top"
            >
              <Grid item xs={6}>
                <Autocomplete
                  id="idPai"
                  name="idPai"
                  options={planosFinanceiros}
                  noOptionsText="Sem opções"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.descricao ? option.descricao : ""
                  }
                  onChange={(e, newValue) =>
                    onChangeAutocomplete("idPai", newValue)
                  }
                  disabled={planoFinanceiro.id ? true : false}
                  value={value.autoComplete(
                    planosFinanceiros,
                    planoFinanceiro.idPai
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Plano Financeiro Superior"
                    />
                  )}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={6}>
              <Autocomplete
                id="classificacaoId"
                name="classificacaoId"
                options={listaClassificacao}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                onChange={(e, newValue) =>
                  onChangeAutocomplete("classificacaoId", newValue)
                }
                value={value.autoComplete(
                  listaClassificacao,
                  planoFinanceiro.classificacaoId
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Classificação"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Natureza"
                id="natureza"
                name="natureza"
                value={planoFinanceiro?.natureza || ""}
                onChange={(event) => onChangeInput(event)}
                required
                error={inputErros.get("natureza")}
              >
                <MenuItem value="credito">Crédito</MenuItem>
                <MenuItem value="debito">Débito</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Salvar
          </Button>
          <Button
            color="inherit"
            variant="contained"
            onClick={handleCloseDialog}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default AddPlanoFinanceiro;
