import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  DataGrid,
} from "../../../../components";
import {
  notification,
  ModeloGradeService,
  ProdutosService,
} from "../../../../services";
import { responseErros } from "../../../../utils";
import shortid from "shortid";
import { value } from "../../../../helpers";
import AddGradeDialog from "./add-grade-dialog.component";

const FormGradeComponent = ({
  setLoading,
  produto,
  setProduto,
  produtosGradeList,
  setProdutosGradeList,
  produtoEntity,
  //buscarProduto,
}) => {
  const [modelosGradeList, setModelosGradeList] = useState([]);
  const [modeloGrade, setModeloGrade] = useState({});
  //const [produtoGradeEdit, setProdutoGradeEdit] = useState({});
  const [openProdutoGrade, setOpenProdutoGrade] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [produtosGradeListSelecao, setProdutosGradeListSelecao] = useState([]);
  const [produtosGradeListFiltrados, setProdutosGradeListFiltrados] = useState(
    []
  );
  const produtoGradeColunas = [
    {
      field: "codigoBarra",
      headerName: "Barra",
      sortable: false,
      flex: 200,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 500,
    },
    {
      field: "estoque",
      headerName: "Estoque",
      flex: 100,
      valueGetter: (params) => {
        if (params.row?.estoqueAtual) {
          return params.row.estoqueAtual;
        }
        return 0;
      },
    },
  ];
  const modeloGradeService = new ModeloGradeService();
  const produtosService = new ProdutosService();

  useEffect(() => {
    if (produto?.id) {
      return buscarModeloGradeById(produto?.modeloGradeId);
    }
    return buscarModeloGrade();
  }, []);

  const onClickEditProdutoGrade = (id) => {
    const produto = produtosGradeList.find((pd) => pd.id === id);
    if (produto.id && !produto?.tipo) {
      const produtosList = produtosGradeList.filter(
        (prod) => prod.id === produto.id && !prod?.tipo
      );
      setActiveStep(1);
      setProdutosGradeListSelecao(produtosList);
      setProdutosGradeListFiltrados(produtosList);
      setOpenProdutoGrade(true);
    } else {
      notification.alertaGenericos(
        "Alteração permitida somente para produtos não cadastrados"
      );
    }
  };

  const buscarGradesRows = async (produtoId) => {
    const filter = {
      produtoId,
      nonPaginated: true,
    };
    const res = await produtosService.getAll(filter);
    if (!res.isAxiosError) {
      //montarProdutoGrade(res.data);
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const onClickDeleteProdutoGrade = async (idProdutoGrade) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir"
    );
    if (retornoAlerta.isConfirmed) {
      if (produto.id) {
        setLoading(true);
        const res = await produtosService.deletar(idProdutoGrade);
        if (!res.isAxiosError) {
          buscarGradesRows(produto.id);
        } else {
          setLoading(false);
          responseErros(res);
        }
      } else {
        setProdutosGradeList(
          produtosGradeList.filter(
            (produtoGrade) => produtoGrade.id != idProdutoGrade
          )
        );
      }
    }
  };

  const buscarModeloGradeById = (id) => {
    if (!id) return;
    modeloGradeService.getById(id).then((res) => {
      if (!res.isAxiosError) {
        setModelosGradeList([res.data]);
        setModeloGrade(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarModeloGrade = () => {
    const filter = {
      nonPaginated: true,
    };
    modeloGradeService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setModelosGradeList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    if (value?.id) setModeloGrade(value);
    produtoEntity.setHandleChangeAutoComplete(name, value);
    setProduto({ ...produtoEntity });
  };

  /*   const salvarGradeEdicao = async (event) => {
    event.preventDefault();
    if (produto.id) {
      setLoading(true);
      const res = await produtosService.atualizar(
        produtoGradeEdit.id,
        produtoGradeEdit
      );
      if (!res.isAxiosError) {
        buscarProduto();
        notification.alteracaoSucesso();
      } else {
        responseErros(res);
      }
      setLoading(false);
    } else {
      setProdutosGradeLinhas((produtosGrade) =>
        produtosGrade.map((produtoGrade) => {
          if (produtoGrade.id == produtoGradeEdit.id) {
            return produtoGradeEdit;
          } else {
            return produtoGrade;
          }
        })
      );
    }
    setOpenProdutoGrade(false);
    setProdutoGradeEdit({});
  }; */

  const montarGrade = async (event, modeloGrade) => {
    if (event) event.preventDefault();
    if (!produto.descricao) {
      return notification.erroValidacao("Descrição do produto");
    }
    const variacoesModeloGrade = modeloGrade.variacaoModeloGrade;
    const produtosGrade = montarProdutosGrade(variacoesModeloGrade);
    setProdutosGradeListSelecao(produtosGrade);
    setOpenProdutoGrade(true);
  };

  const montarProdutosGrade = (
    variacoesModeloGrade,
    valorVariacaoPai,
    linhas,
    index
  ) => {
    if (!linhas) {
      linhas = [];
    }
    if (!index) {
      index = 0;
    }
    variacoesModeloGrade[index].valorVariacaoModeloGrade.forEach(
      (valorVariacao) => {
        if (variacoesModeloGrade.length == index + 1) {
          let descricao = "";
          if (produto.descricao) {
            descricao = `${produto.descricao} ${valorVariacao.valor}`;
            if (valorVariacaoPai) {
              descricao += ` ${valorVariacaoPai.valor}`;
            }
          }
          const linha = {
            id: shortid.generate(),
            codigoBarra: "",
            descricao,
            [`eixo${variacoesModeloGrade[index].tipoEixo.toUpperCase()}`]:
              valorVariacao.id,
          };
          if (valorVariacaoPai) {
            linha[
              `eixo${variacoesModeloGrade[index - 1].tipoEixo.toUpperCase()}`
            ] = valorVariacaoPai.id;
          }
          const linhaValida = produtosGradeList.find(
            (prod) => prod.codigoBarra && prod.descricao === linha.descricao
          );
          if (!linhaValida?.id) {
            linhas.push(linha);
          }
        } else {
          montarProdutosGrade(
            variacoesModeloGrade,
            valorVariacao,
            linhas,
            index + 1
          );
        }
      }
    );
    return linhas;
  };

  /*   const handleChangeInputNumber = (event) => {
    setProdutoGradeEdit((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }; */

  return (
    <>
      <form
        className="mt-4"
        onSubmit={(event) => montarGrade(event, modeloGrade)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              id="modeloGradeId"
              name="modeloGradeId"
              options={modelosGradeList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Modelo Grade"
                  required
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                handleChangeAutocomplete("modeloGradeId", value)
              }
              value={value.autoComplete(
                modelosGradeList,
                produto?.modeloGradeId
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              size="large"
              className="mt-1"
              variant="contained"
              color="primary"
            >
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              ></i>
              Aplicar Grade
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2}>
        <Grid className="mt-3" item xs={12}>
          <DataGrid
            rows={produtosGradeList}
            columns={produtoGradeColunas}
            rowCount={produtosGradeList.length}
            deleteShow={produto.id ? false : true}
            editShow
            onClickEdit={onClickEditProdutoGrade}
            onClickDelete={onClickDeleteProdutoGrade}
          />
        </Grid>
      </Grid>
      <AddGradeDialog
        id={produto.id}
        open={openProdutoGrade}
        setOpen={setOpenProdutoGrade}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        produtosGradeListFiltrados={produtosGradeListFiltrados}
        setProdutosGradeListFiltrados={setProdutosGradeListFiltrados}
        produtosGradeList={produtosGradeList}
        setProdutosGradeList={setProdutosGradeList}
        produtoGradeColunas={produtoGradeColunas}
        produtosGradeListSelecao={produtosGradeListSelecao}
        setProdutosGradeListSelecao={setProdutosGradeListSelecao}
      />
    </>
  );
};

export default FormGradeComponent;
