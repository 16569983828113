import { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab, TabPanel } from "../../../../components";
import {
  FormFornecedor,
  FormCliente,
  FormTransportadora,
  FormPrestadorServico,
  FormColaborador,
} from "./index";
const CLIENTE = 0;
const FORNECEDOR = 1;
const TRANSPORTADORA = 2;
const COLABORADOR = 3;
const PRESTADOR_SERVICO = 4;
/* const CREDENCIADORA = 5; */

export default function TipoPessoas(props) {
  const [habilitado, setHabalitado] = useState({
    cliente: false,
    fornecedor: false,
    transportadora: false,
    colaborador: false,
    prestadorServico: false,
    credenciadora: false,
  });

  useEffect(() => {
    let habilitadoTemp = {
      cliente: false,
      fornecedor: false,
      transportadora: false,
      colaborador: false,
      prestadorServico: false,
      credenciadora: false,
    };
    if (props.pessoa.tiposPessoas?.length) {
      if (props.pessoa.tiposPessoas?.length) {
        const tipoPessoaId = props.pessoa.tiposPessoas.map((tipo) => tipo.id);
        if (tipoPessoaId.includes(1)) {
          habilitadoTemp.cliente = true;
        }
        if (tipoPessoaId.includes(2)) {
          habilitadoTemp.fornecedor = true;
        }
        if (tipoPessoaId.includes(3)) {
          habilitadoTemp.transportadora = true;
        }
        if (tipoPessoaId.includes(4)) {
          habilitadoTemp.colaborador = true;
        }
        if (tipoPessoaId.includes(5)) {
          habilitadoTemp.prestadorServico = true;
        }
      }
    }
    setHabalitado(habilitadoTemp);
  }, [props.pessoa.tiposPessoas]);

  const handleAbaChange = (e, value) => {
    props.setAbaValue(value);
  };

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              value={props.abaValue}
              onChange={handleAbaChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              scrollButtons="auto"
            >
              <Tab
                label="Cliente"
                disabled={habilitado.cliente ? false : true}
                {...tabProps(CLIENTE)}
              />
              <Tab
                label="Fornecedor"
                disabled={habilitado.fornecedor ? false : true}
                {...tabProps(FORNECEDOR)}
              />
              <Tab
                label="Transportadora"
                disabled={habilitado.transportadora ? false : true}
                {...tabProps(TRANSPORTADORA)}
              />
              <Tab
                label="Colaborador"
                disabled={habilitado.colaborador ? false : true}
                {...tabProps(COLABORADOR)}
              />
              <Tab
                label="Prest. Serviço"
                disabled={habilitado.prestadorServico ? false : true}
                {...tabProps(PRESTADOR_SERVICO)}
              />
              {/* <Tab
                label="Credenciadora"
                disabled={habilitado.credenciadora ? false : true}
                {...tabProps(CREDENCIADORA)}
              /> */}
            </Tabs>
          </Paper>
          <TabPanel value={props.abaValue} index={CLIENTE}>
            <FormCliente
              clienteFormRef={props.clienteFormRef}
              cliente={props.cliente}
              setCliente={props.setCliente}
              habilitado={habilitado.cliente}
              inputErros={props.inputErros}
              cacheDto={props.cacheDto}
              setCacheDto={props.setCacheDto}
            />
          </TabPanel>
          <TabPanel value={props.abaValue} index={FORNECEDOR}>
            <FormFornecedor
              fornecedorFormRef={props.fornecedorFormRef}
              fornecedor={props.fornecedor}
              setFornecedor={props.setFornecedor}
              habilitado={habilitado.fornecedor}
            />
          </TabPanel>
          <TabPanel value={props.abaValue} index={TRANSPORTADORA}>
            <FormTransportadora
              transportadoraFormRef={props.transportadoraFormRef}
              transportadora={props.transportadora}
              setTransportadora={props.setTransportadora}
              habilitado={habilitado.transportadora}
              inputErros={props.inputErros}
              cacheDto={props.cacheDto}
              setCacheDto={props.setCacheDto}
            />
          </TabPanel>
          <TabPanel value={props.abaValue} index={COLABORADOR}>
            <FormColaborador
              colaboradorFormRef={props.colaboradorFormRef}
              colaborador={props.colaborador}
              setColaborador={props.setColaborador}
              habilitado={habilitado.colaborador}
              inputErros={props.inputErros}
              cacheDto={props.cacheDto}
              setCacheDto={props.setCacheDto}
            />
          </TabPanel>
          <TabPanel value={props.abaValue} index={PRESTADOR_SERVICO}>
            <FormPrestadorServico
              prestadorServicoFormRef={props.prestadorServicoFormRef}
              prestadorServico={props.prestadorServico}
              setPrestadorServico={props.setPrestadorServico}
              habilitado={habilitado.prestadorServico}
              inputErros={props.inputErros}
              cacheDto={props.cacheDto}
              setCacheDto={props.setCacheDto}
            />
          </TabPanel>
          {/* <TabPanel value={props.abaValue} index={CREDENCIADORA}>
            <FormCredenciadora
              credenciadoraFormRef={props.credenciadoraFormRef}
              credenciadora={props.credenciadora}
              setCredenciadora={props.setCredenciadora}
              habilitado={habilitado.credenciadora}
              inputErros={props.inputErros}
            />
          </TabPanel> */}
        </Grid>
      </Grid>
    </>
  );
}
