import { Stepper as StepperMui } from "@mui/material";

const Stepper = ({
  className,
  children,
  activeStep,
  alternativeLabel,
  classes,
  component,
  connector,
  nonLinear,
  orientation,
  sx,
}) => {
  return (
    <StepperMui
      className={className}
      activeStep={activeStep}
      alternativeLabel={alternativeLabel ? true : false}
      classes={classes}
      component={component}
      connector={connector}
      nonLinear={nonLinear ? true : false}
      orientation={orientation}
      sx={sx}
    >
      {children}
    </StepperMui>
  );
};

export default Stepper;
