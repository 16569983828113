import {
  Button,
  DataGrid,
  DialogActions,
  DialogContent,
} from "../../../../components";
import { GridRowModes } from "@mui/x-data-grid";

const AddGradeStepper = ({
  activeStep,
  produtoGradeColunas,
  produtosGradeListSelecao,
  handleAvancarStep,
  handleVoltarStep,
  handleCancelarAddGrade,
  itensSelecionados,
  setItensSelecionados,
  produtosGradeListFiltrados,
  setProdutosGradeListFiltrados,
  handleFormarGrade,
  rowModesModel,
  setRowModesModel,
}) => {
  const produtoGradeColunasFiltro = [
    {
      field: "codigoBarra",
      headerName: "Barra",
      sortable: false,
      flex: 200,
      editable: true,
      valueParser: (newValue, params) => {
        const produtosGrade = produtosGradeListFiltrados.map((produto) => {
          if (produto.id === params.row.id) {
            return {
              ...params.row,
              codigoBarra: newValue ? newValue.replace(/[^\w\s]/gi, "") : "",
            };
          } else return produto;
        });
        setProdutosGradeListFiltrados(produtosGrade);
        return newValue;
      },
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 500,
    },
  ];

  const handleEditClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "codigoBarra" },
    });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  switch (activeStep) {
    case 1:
      return (
        <>
          <DialogContent>
            <DataGrid
              rows={produtosGradeListFiltrados}
              columns={produtoGradeColunasFiltro}
              rowCount={produtosGradeListFiltrados?.length}
              disableRowSelectionOnClick
              editShow
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onClickEdit={handleEditClick}
              onCellEditStop={(params, event) => {
                event.defaultMuiPrevented = true;
                setRowModesModel({
                  ...rowModesModel,
                  [params.id]: { mode: GridRowModes.View },
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelarAddGrade}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleFormarGrade}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Importar
            </Button>
          </DialogActions>
        </>
      );
    default:
      return (
        <>
          <DialogContent>
            <DataGrid
              checkboxSelection
              rows={produtosGradeListSelecao}
              columns={produtoGradeColunas}
              rowCount={produtosGradeListSelecao?.length}
              onRowSelectionModelChange={(selectedIds) => {
                setItensSelecionados(selectedIds);
              }}
              rowSelectionModel={itensSelecionados}
              disableRowSelectionOnClick
              disableAcoes
            />
            {/* <Loading loading={loading} /> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelarAddGrade}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleAvancarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Próximo
            </Button>
          </DialogActions>
        </>
      );
  }
};

export default AddGradeStepper;
