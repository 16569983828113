export class ClassificacaoFinanceiraEntity {
  ativado = true;
  nome = null;

  constructor(classificacaoFinanceira) {
    this.nome = classificacaoFinanceira.nome;
    if (classificacaoFinanceira.ativado == false) {
      this.ativado = false;
    }
  }
}
