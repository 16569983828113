import * as Yup from "yup";
import nfEntradaItensValidator from "./nf-entrada-itens.validator";
import { useImportContext } from "../../../../contexts/import.context";
import constantes from "../resources/constantes";
const nfEntradaValidator = () => {
  const { nfEntradaDto } = useImportContext();
  const modalidadeFrete = constantes.modalidadeFrete.map(
    (modalidade) => modalidade.value
  );
  return Yup.object()
    .shape({
      operacaoFiscalId: Yup.number().required("Operação Fiscal").nullable(),
      operacaoFiscal: Yup.object()
        .shape({
          modeloDocumento: Yup.string().required("Modelo do Documento"),
          movimentacaoFinanceira: Yup.boolean().nullable(),
          movimentacaoEstoque: Yup.boolean().nullable(),
          tipoOperacao: Yup.string().required("Tipo da Operação"),
          finalidade: Yup.string().required("Finalidade"),
          naoGerarComissao: Yup.boolean().nullable(),
        })
        .nullable(),
      fornecedorId: Yup.number().required("Fornecedor").nullable(),
      cnpj: Yup.string().required("CNPJ").nullable(),
      modelo: Yup.string().required("Modelo NF-e").nullable(),
      numeroNota: Yup.string().required("Número NF-e").nullable(),
      serie: Yup.string().required("Série NF-e").nullable(),
      dataEmissao: Yup.date().required("Data Emissão").nullable(),
      dataChegada: Yup.date().required("Data Chegada").nullable(),
      finalidade: Yup.string().required("Finalidade").nullable(),
      modalidadeFrete: Yup.string()
        .required("Modalidade Frete")
        .oneOf(modalidadeFrete),
      frete: Yup.number().nullable(),
      freteCompoeNota: Yup.boolean().when("frete", {
        is: (frete) => frete,
        then: Yup.boolean().required("Frete Compõe Total da Nota?"),
        otherwise: Yup.boolean().nullable(),
      }),
      nfeChave: Yup.string().nullable(),
      informacoes: Yup.string().nullable(),
      subtotalProdutos: Yup.number().nullable(),
      subtotalTributos: Yup.number().nullable(),
      subtotalDesconto: Yup.number().nullable(),
      subtotalAcrescimo: Yup.number().nullable(),
      subtotalDespesas: Yup.number().nullable(),
      entrada: Yup.number().nullable(),
      bancoCaixaId: Yup.number().nullable(),
      totalCompra: Yup.mixed().nullable(),
      totalPagamentos: Yup.mixed().nullable(),
      nfEntradaItens: !nfEntradaDto.usoConsumo
        ? nfEntradaItensValidator
        : Yup.array()
            .of(
              Yup.object().shape({
                descricao: Yup.string().nullable(),
                cfopId: Yup.mixed().nullable(),
                cfop: Yup.string().nullable(),
                codigo: Yup.string().nullable(),
                ncm: Yup.string().nullable(),
                quantidade: Yup.number().nullable(),
                precoCompra: Yup.number().nullable(),
                desconto: Yup.number().nullable(),
                acrescimo: Yup.number().nullable(),
                despesas: Yup.number().nullable(),
                subtotal: Yup.number().nullable(),
              })
            )
            .min(1, "Adicionar ao menos um pagamento")
            .required(),
      nfEntradaPagamentos: Yup.array()
        .of(
          Yup.object().shape({
            dataVencimento: Yup.date().required("Data vencimento em pagamento"),
            meioPagamentoId: Yup.number().required(
              "Meio de pagamento em pagamento"
            ),
            valorTitulo: Yup.number().required("Valor do tilulo em pagamento"),
          })
        )
        .min(1, "Adicionar ao menos um pagamento")
        .required(),
    })
    .test(
      "totalValidator",
      "É necessário atualizar valor da(s) parcela(s)",
      ({ totalPagamentos, entrada, totalCompra }) =>
        Number(totalPagamentos.toFixed(2)) +
          Number((entrada || 0).toFixed(2)) ===
        Number(totalCompra)
    );
};

export default nfEntradaValidator;
