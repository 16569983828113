import { useState, useEffect } from "react";
import {
  Autocomplete,
  Select,
  Grid,
  MenuItem,
  TextField,
} from "../../../../components";
import { BancoService } from "../../../../services";
import { responseErros } from "../../../../utils";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const [bancos, setBancos] = useState([]);
  const bancoService = new BancoService();

  useEffect(() => {
    buscaBancos();
  }, []);

  const buscaBancos = () => {
    const filtros = {
      nonPaginated: true,
    };
    bancoService.getAll(filtros).then((res) => {
      if (!res.isAxiosError) {
        setBancos(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          id="ativado"
          name="ativado"
          value={valueInput("ativado")}
          label="Status"
          onChange={(event) => onChangeInput(event)}
          fullWidth
        >
          <MenuItem value="">
            <p></p>
          </MenuItem>
          <MenuItem value={"true"}>Ativado</MenuItem>
          <MenuItem value={"false"}>Desativado</MenuItem>
          <MenuItem value={"-"}>Ambos</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="descricao"
          name="descricao"
          label="Descrição"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("descricao")}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="bancoId"
          name="bancoId"
          options={bancos}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => (option.nome ? option.nome : "")}
          renderInput={(params) => (
            <TextField {...params} label="Banco" variant="outlined" />
          )}
          onChange={(e, value) => onChangeAutocomplete("bancoId", value)}
          value={valueAutocomplete(bancos, "bancoId")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="agencia"
          name="agencia"
          label="Agência"
          fullWidth
          type="number"
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("agencia")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="conta"
          name="conta"
          label="Conta"
          type="number"
          fullWidth
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("conta")}
        />
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
