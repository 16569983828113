import { AccordionSummary as AccordionSummaryMui } from "@mui/material";

const AccordionSummary = ({
  children,
  classes,
  className,
  expandIcon,
  focusVisibleClassName,
  sx,
  ref,
}) => {
  return (
    <AccordionSummaryMui
      classes={classes}
      className={className}
      expandIcon={expandIcon}
      focusVisibleClassName={focusVisibleClassName}
      sx={sx}
      ref={ref}
    >
      {children}
    </AccordionSummaryMui>
  );
};

export default AccordionSummary;
