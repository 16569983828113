import { CssBaseline as CssBaselineMui } from "@mui/material";

const CssBaseline = ({ children, enableColorScheme }) => {
  return (
    <CssBaselineMui enableColorScheme={enableColorScheme}>
      {children}
    </CssBaselineMui>
  );
};

export default CssBaseline;
