import { formatCep } from "../../../utils";

export class CadastrarFornecedorXml {
  constructor(fornecedor) {
    this.nomeRazaoSocial = fornecedor.xNome;
    this.apelidoFantasia = fornecedor.xFant;
    this.naturezaSocial = "juridica";
    this.cnpjCpf = fornecedor.CNPJ;
    this.inscricaoEstadual = fornecedor.IE;
    this.endereco = new CadastrarEnderecoFornecedorXml(fornecedor.enderEmit);
    this.idTiposPessoa = [2];
  }
}

export class CadastrarEnderecoFornecedorXml {
  constructor(fornecedorEndereco) {
    this.cep = fornecedorEndereco?.CEP && formatCep(fornecedorEndereco?.CEP);
    this.logradouro = fornecedorEndereco?.xLgr;
    this.numero = fornecedorEndereco?.nro;
    this.bairro = fornecedorEndereco?.xBairro;
    this.cidade = fornecedorEndereco?.xMun;
    this.codigoCidade = fornecedorEndereco?.cMun;
    this.estado = fornecedorEndereco?.UF;
    this.descricao = "";
  }

  setEnderecoCadastrado(fornecedorEndereco) {
    this.cep = formatCep(`${fornecedorEndereco?.cep}`.padStart(8, "0"));
    this.logradouro = fornecedorEndereco?.logradouro;
    this.numero = fornecedorEndereco?.numero;
    this.bairro = fornecedorEndereco?.bairro;
    this.cidade = fornecedorEndereco?.cidade?.nome;
    this.codigoCidade = fornecedorEndereco?.cidade?.codigoIbge;
    this.estado = fornecedorEndereco?.cidade?.estado?.sigla;
    this.pessoaId = fornecedorEndereco?.pessoaId;
  }
}
