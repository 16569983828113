import { useEffect, useState } from "react";
import {
  Button,
  CardHeader,
  Dialog,
  DialogContent,
  DialogActions,
  DataGrid,
} from "../../../../../components";
import { formatDate, formatPrice, responseErros } from "../../../../../utils";
import {
  NfSaidaService,
  notification,
  OperacaoFiscalService,
  PessoaService,
} from "../../../../../services";
import { format } from "date-fns";

const nfSaidaService = new NfSaidaService();
const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();

const CopiarNfSaidaDialog = ({
  open,
  setOpen,
  nfSaidaEntity,
  setNfSaida,
  setNfSaidaProdutos,
}) => {
  const [nfsSaida, setNfsSaida] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [documentoSelecionadoId, setDocumentoSelecionadoId] = useState(null);
  const [rowCount, setRowCount] = useState(null);
  const colunas = [
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Número do Doc",
      width: 120,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      width: 300,
      valueGetter: (params) => {
        const opFiscal = listaOperacoesFiscais.find(
          (item) => item.id == params.value
        );
        if (!opFiscal) return params.value;
        return opFiscal.descricao;
      },
    },
    {
      field: "clienteId",
      headerName: "Cliente",
      width: 250,
      valueGetter: (params) => {
        const cliente = listaPessoas.find((item) => item.id == params.value);
        if (!cliente) return params.value;
        return cliente.nomeRazaoSocial;
      },
    },
    {
      field: "vendedorId",
      headerName: "Vendedor",
      width: 250,
      valueGetter: (params) => {
        const vendedor = listaPessoas.find((item) => item.id == params.value);
        if (!vendedor) return params.value;
        return vendedor.nomeRazaoSocial;
      },
    },
    {
      field: "nfSaidaItens",
      headerName: "Total da Venda",
      width: 250,
      valueGetter: (params) => {
        const totalNfSaida =
          params.value
            .map((item) => item.subtotal)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.frete +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalNfSaida);
      },
    },
  ];

  const buscarNfSaida = async function (filtro) {
    const result = await nfSaidaService.getAllFiltroAvancado(filtro);
    if (!result.isAxiosError) {
      setNfsSaida(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosNfsSaida(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const buscarRelacionamentosNfsSaida = (listaDocumentos) => {
    const idsPessoas = [];
    const idsOperacoesFiscais = [];
    for (const objeto of listaDocumentos) {
      idsOperacoesFiscais.push(objeto.operacaoFiscalId);
      if (objeto.clienteId) idsPessoas.push(objeto.clienteId);
    }
    const idsUnicosOperacoesFiscais = [...new Set(idsOperacoesFiscais)];
    const idsUnicosPessoas = [...new Set(idsPessoas)];
    buscarOperacoesFiscais(idsUnicosOperacoesFiscais);
    buscarPessoas(idsUnicosPessoas);
  };

  const buscarOperacoesFiscais = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        const listaPessoas = result.data;
        setListaPessoas(listaPessoas);
      } else {
        responseErros(result);
      }
    });
  };

  const sendServerDatagrid = (props) => {
    buscarNfSaida(props.filtros);
  };

  useEffect(() => {
    if (open) {
      buscarNfSaida();
    }
  }, [open]);

  function handleClose() {
    setDocumentoSelecionadoId(null);
    setOpen(false);
  }

  function copiarNfSaida() {
    if (documentoSelecionadoId) {
      const nf = nfsSaida.find((nf) => nf.id === documentoSelecionadoId);
      if (nf) {
        nfSaidaEntity.setChangeItens(null, nf.nfSaidaItens, nf);
        setNfSaida(nfSaidaEntity);
        setNfSaidaProdutos(nfSaidaEntity.nfSaidaItens);
        setOpen(false);
      }
      return;
    }
    notification.alertaGenericos("Selecione ao menos um documento para copiar");
  }

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={() => handleClose()}>
      <CardHeader title="Copiar Nf-Saida" className="m-2" />
      <DialogContent>
        <DataGrid
          checkboxSelection
          selectionModel={[documentoSelecionadoId]}
          onRowSelectionModelChange={(selectedIds) => {
            const id =
              selectedIds?.length > 1 ? [selectedIds.pop()] : selectedIds;
            setDocumentoSelecionadoId(id[0]);
          }}
          paginationMode={"server"}
          sendServer={sendServerDatagrid}
          noFilter
          rows={nfsSaida}
          columns={colunas}
          rowCount={rowCount}
          disableAcoes
        />
      </DialogContent>
      <DialogActions className="m-3">
        <Button
          onClick={() => setOpen(false)}
          color="padrao"
          variant="contained"
        >
          Cancelar
        </Button>
        <Button
          color="success"
          variant="contained"
          onClick={() => copiarNfSaida()}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopiarNfSaidaDialog;
