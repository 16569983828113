import { Box as BoxMui } from "@mui/material";

const Box = ({
  children,
  sx,
  component,
  display,
  flexWrap,
  p,
  m,
  width,
  className,
}) => {
  return (
    <BoxMui
      sx={sx}
      component={component}
      display={display}
      flexWrap={flexWrap}
      p={p}
      m={m}
      width={width}
      className={className}
    >
      {children}
    </BoxMui>
  );
};

export default Box;
