import SiafLiteApiRepository from "../../external/siafLiteApi";

class ConfiguracoesService {
  constructor() {
    this.urlBase = "/painel-controle/configuracoes";
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  get() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }
}

export default ConfiguracoesService;
