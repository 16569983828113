const FiltrosMainPrint = ({ filtrosAplicadosImpressao, pagina }) => {
  const chaves = Object.keys(filtrosAplicadosImpressao).reverse();
  return (
    <div style={{ fontSize: "8px", marginRight: "20px" }}>
      {chaves.length > 0 && (
        <p>
          {pagina ? `Página: ${pagina}` : null}
          <br />
          {!print && (
            <strong>
              FILTROS APLICADOS: <br />
            </strong>
          )}
          {chaves.reverse().map((key, index, array) => {
            return (
              filtrosAplicadosImpressao[key] &&
              key !== "numeroPagina" && (
                <span key={key}>
                  <span>{key.replace(/-/gi, " ")}: </span>
                  <span>{filtrosAplicadosImpressao[key]}</span>
                  {index !== array.length - 1 && <br />}
                </span>
              )
            );
          })}
        </p>
      )}
    </div>
  );
};

export default FiltrosMainPrint;
