import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CardHeader,
  Dialog,
} from "../../../../components";
import {
  NfSaidaService,
  OperacaoFiscalService,
  OrigemMercadoriaService,
  UnidadeComercialService,
  history,
  notification,
} from "../../../../services";
import { responseErros, formatDate, formatPrice } from "../../../../utils";
import { ImportarDocumentoStepperContent } from ".";
import { NfSaida, NfSaidaItem } from "../../entities";

const ImportarDocumentoDialog = ({
  openImportarDocumentoDialog,
  setOpenImportarDocumentoDialog,
  filtrosDialog,
  setFiltrosDialog,
  buscarNfSaida,
  listaPessoas,
  listaCondicoesVenda,
  listaMeiosPagamento,
  listaOperacoesFiscaisIniciais,
  setLoading,
  documentoSelectionModel,
  setDocumentoSelectionModel,
}) => {
  const nfSaidaService = new NfSaidaService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const unidadeComercialService = new UnidadeComercialService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const [activeStep, setActiveStep] = useState(0);
  const [nfSaida, setNfSaida] = useState([]);
  const [documentoSelecionado, setDocumentoSelecionado] = useState({});
  const [itemSelectionModel, setItemSelectionModel] = useState([]);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [unidadeList, setUnidadeList] = useState([]);
  const [origensMercadoriaList, setOrigensMercadoriaList] = useState([]);
  const [operacaoFiscal, setOperacaoFiscal] = useState(null);
  const steps = [
    "Selecionar Nota de Saída",
    "Selecionar Itens",
    "Selecionar Operação Fiscal",
  ];
  const colunas = [
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Número do Doc",
      width: 120,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      width: 300,
      valueGetter: (params) => {
        const opFiscal = listaOperacoesFiscaisIniciais.find(
          (item) => item.id == params.value
        );
        if (!opFiscal) return params.value;
        return opFiscal.descricao;
      },
    },
    {
      field: "clienteId",
      headerName: "Cliente",
      width: 250,
      valueGetter: (params) => {
        const cliente = listaPessoas.find((item) => item.id == params.value);
        if (!cliente) return params.value;
        return cliente.nomeRazaoSocial;
      },
    },
    {
      field: "vendedorId",
      headerName: "Vendedor",
      width: 250,
      valueGetter: (params) => {
        const vendedor = listaPessoas.find((item) => item.id == params.value);
        if (!vendedor) return params.value;
        return vendedor.nomeRazaoSocial;
      },
    },
    {
      field: "condicaoPagamentoId",
      headerName: "Condição de Pagamento",
      width: 250,
      valueGetter: (params) => {
        const condicaoPgto = listaCondicoesVenda.find(
          (item) => item.id == params.value
        );
        if (!condicaoPgto) return params.value;
        return condicaoPgto.descricao;
      },
    },
    {
      field: "meioPagamentoId",
      headerName: "Meio de Pagamento",
      width: 250,
      valueGetter: (params) => {
        const meioPgto = listaMeiosPagamento.find(
          (item) => item.id == params.value
        );
        if (!meioPgto) return params.value;
        return meioPgto.descricao;
      },
    },
    {
      field: "nfSaidaItens",
      headerName: "Total da Venda",
      width: 250,
      valueGetter: (params) => {
        const totalNfSaida =
          params.value
            .map((item) => item.subtotal)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.frete +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalNfSaida);
      },
    },
  ];

  const buscarOperacoesFiscaisImportarDoc = () => {
    const filtro = {
      ativado: true,
      nonPaginated: true,
      tipoOperacao: "SAIDA",
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
      movimentacaoEstoque: false,
      movimentacaoFinanceira: false,
      restritiva: true,
      porIntervalo: [],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    if (openImportarDocumentoDialog) {
      buscarOperacoesFiscaisImportarDoc();
      buscarOrigensMercadoria();
      buscarUnidadesComerciais();
      buscarNfSaidaAvancado({});
    }
  }, [openImportarDocumentoDialog]);

  const buscarNfSaidaAvancado = async (filtros) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
      modeloDocumento: "65",
      status: "ENVIADA",
    };
    if (
      filtrosAvancadosTemp.dataEmissaoStart &&
      filtrosAvancadosTemp.dataEmissaoEnd
    ) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataEmissaoStart,
        ate: filtrosAvancadosTemp.dataEmissaoEnd,
      });
    }
    delete filtrosAvancadosTemp.dataEmissaoStart;
    delete filtrosAvancadosTemp.dataEmissaoEnd;
    setLoading(true);
    const result = await nfSaidaService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    setLoading(false);
    if (!result.isAxiosError) {
      setNfSaida(result.data.rows);
    } else {
      responseErros(result);
    }
    setFiltrosDialog({});
  };

  const buscarOrigensMercadoria = async () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleImportarMercadorias = async () => {
    if (!operacaoFiscal) {
      notification.alertaGenericos("Operação fiscal deve ser informada");
      return;
    }
    setLoading(true);
    window.nfSaidaEntity = await createNfSaida(
      documentoSelecionado,
      itensSelecionados,
      operacaoFiscal
    );
    setOpenImportarDocumentoDialog(false);
    resetarDadosImportacao();
    setLoading(false);
    history.push("/faturamento/nf-saida/importar-documento");
  };

  const createNfSaida = async (documento, itens, operacaoFiscal) => {
    if (
      operacaoFiscal.modeloDocumento == "055" ||
      operacaoFiscal.modeloDocumento == "065"
    ) {
      return notification.alertaGenericos(
        "Operação fiscal sem regra de tributação associada."
      );
    }
    const nfSaidaItens = itens
      .filter((item) => itemSelectionModel.indexOf(item.id) > -1)
      .map((item, index) => {
        const nfSaidaItem = new NfSaidaItem(
          item,
          index,
          operacaoFiscal,
          origensMercadoriaList,
          unidadeList
        );
        return {
          ...nfSaidaItem,
          produto: item.produto,
          valor: parseFloat(item.valor.toFixed(2)),
          subtotal:
            parseFloat(item.valor.toFixed(2)) * parseInt(item.quantidade),
        };
      });
    const dadosNfSaida = {
      ...documento,
      dataEmissao: formatDate.toSend(new Date()),
      modalidadeFrete: "mfSemFrete",
      freteCompoeNota: false,
      id: undefined,
      numeroDocumento: undefined,
      importacaoDocumento: true,
      nfSaidaImportadaId: documento.id,
      operacaoFiscalId: operacaoFiscal.id,
      operacaoFiscal,
      serieDocumento: operacaoFiscal?.serieDocumento,
      modeloDocumento: operacaoFiscal?.modeloDocumento,
      nfSaidaItens,
      nfSaidaPagamentos: documento.nfSaidaPagamentos,
    };
    return new NfSaida(dadosNfSaida, true);
  };

  const resetarDadosImportacao = () => {
    setDocumentoSelecionado([]);
    setActiveStep(0);
  };

  const handleCancelarImportacao = () => {
    resetarDadosImportacao();
    setOpenImportarDocumentoDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      delete props.filtros.status;
      buscarNfSaida(props.filtros);
    } else {
      buscarNfSaidaAvancado(props.filtros);
    }
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={openImportarDocumentoDialog}>
      <CardHeader title="Importar Documento" className="m-2" />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <ImportarDocumentoStepperContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleImportarMercadorias={handleImportarMercadorias}
        handleCancelarImportacao={handleCancelarImportacao}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        setOperacaoFiscal={setOperacaoFiscal}
        operacaoFiscal={operacaoFiscal}
        colunas={colunas}
        nfSaida={nfSaida}
        documentoSelecionado={documentoSelecionado}
        setDocumentoSelecionado={setDocumentoSelecionado}
        setItensSelecionados={setItensSelecionados}
        buscarNfSaidaAvancado={buscarNfSaidaAvancado}
        listaClientes={listaPessoas}
        listaOperacoesFiscais={listaOperacoesFiscais}
        itemSelectionModel={itemSelectionModel}
        setItemSelectionModel={setItemSelectionModel}
        documentoSelectionModel={documentoSelectionModel}
        setDocumentoSelectionModel={setDocumentoSelectionModel}
      />
    </Dialog>
  );
};

export default ImportarDocumentoDialog;
