import { Drawer as DrawerMui } from "@mui/material";

const Drawer = ({
  anchor,
  color,
  children,
  classes,
  elevation,
  hideBackdrop,
  ModalProps,
  onClose,
  open,
  PaperProps,
  SlideProps,
  sx,
  transitionDuration,
  variant,
  ref,
  className,
}) => {
  return (
    <DrawerMui
      anchor={anchor ?? "left"}
      classes={classes}
      color={color ?? "primary"}
      elevation={elevation ?? 16}
      hideBackdrop={hideBackdrop ?? false}
      ModalProps={ModalProps}
      onClose={onClose}
      open={open ?? false}
      PaperProps={PaperProps}
      SlideProps={SlideProps}
      sx={sx}
      transitionDuration={transitionDuration}
      variant={variant ?? "temporary"}
      ref={ref}
      className={className}
    >
      {children}
    </DrawerMui>
  );
};

export default Drawer;
