import SiafLiteApiRepository from "../../external/siafLiteApi";

class VinculoPessoaService {
  constructor() {
    this.urlBase = "/integracao/vinculo-pessoa";
  }

  verificarVinculoPessoa(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }
}

export default VinculoPessoaService;
