import * as Yup from "yup";

const cadastrarContadorValidator = Yup.object().shape({
  nome: Yup.string().required("Nome"),
  senha: Yup.string().required("Senha"),
  email: Yup.string().required("Email"),
  cnpjCpf: Yup.string().required("Cnpj / Cpf"),
});

export default cadastrarContadorValidator;
