import { differenceInCalendarDays, isBefore } from "date-fns";
import formatPrice from "../../../utils/formatPrice";

export function tabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function calcularValorTotal(data) {
  const acrescimoFloat = data.acrescimo ? parseFloat(data.acrescimo) : 0;
  const descontoFloat = data.desconto ? parseFloat(data.desconto) : 0;
  const valorTotal = parseFloat(data.valor) + acrescimoFloat - descontoFloat;
  if (valorTotal >= 0) {
    return valorTotal;
  }
}

export const converterValorParaPorcetagem = (valor = 0, total = 0) => {
  return (parseFloat(valor) / parseFloat(total)) * 100;
};

export const converterPorcentagemParaValor = (porcentagem = 0, total = 0) => {
  return (parseFloat(porcentagem) / 100) * parseFloat(total);
};

export const calcularValorAPagar = (
  pagamentoAtualizado,
  pagamentosList = [],
  conta
) => {
  const pagamentosReais = pagamentosList.filter(
    (pago) => parseFloat(pago?.valor) > 0
  );
  const totalPago = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual?.valor ?? 0);
  }, 0);
  const totalMultaJuros = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return (
      acumulador +
      parseFloat(objetoAtual?.multa ?? 0) +
      parseFloat(objetoAtual?.juros ?? 0)
    );
  }, 0);
  const totalDescontos = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual?.desconto ?? 0);
  }, 0);
  return (
    parseFloat(conta?.valor ?? 0) +
    parseFloat(conta?.acrescimo ?? 0) -
    parseFloat(conta?.desconto ?? 0) +
    parseFloat(pagamentoAtualizado?.multa ?? 0) +
    parseFloat(pagamentoAtualizado?.juros ?? 0) -
    parseFloat(pagamentoAtualizado?.desconto ?? 0) -
    totalDescontos +
    totalMultaJuros -
    totalPago
  );
};

export function totalPagamentos(pagamentos) {
  if (pagamentos?.length) {
    const total = pagamentos.reduce(
      (prevVal, elem) =>
        prevVal +
        parseFloat(elem.valor) +
        parseFloat(elem.multa || 0) +
        parseFloat(elem.juros || 0) -
        parseFloat(elem.desconto || 0),
      0
    );
    return formatPrice(total);
  }
}

export function getAtrasoDias(dataVencimento, dataPagamento) {
  const date1 = dataPagamento ? new Date(dataPagamento) : new Date();
  const date2 = dataVencimento ? new Date(dataVencimento) : new Date();
  const diferencaDias = differenceInCalendarDays(date1, date2);
  return diferencaDias <= -1 || isBefore(date1, date2) ? 0 : diferencaDias;
}

export function calcularValorTotalAtualizado(data) {
  if (!data) return;
  const pagamentosReais = data.pagamentos.filter(
    (pago) => parseFloat(pago.valor) > 0
  );
  const totalPago = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual.valor ?? 0);
  }, 0);
  const totalMultaJuros = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return (
      acumulador +
      parseFloat(objetoAtual.multa ?? 0) +
      parseFloat(objetoAtual.juros ?? 0)
    );
  }, 0);
  const totalDescontos = pagamentosReais.reduce((acumulador, objetoAtual) => {
    return acumulador + parseFloat(objetoAtual.desconto ?? 0);
  }, 0);
  return (
    parseFloat(data.valor) +
    parseFloat(data.acrescimo ?? 0) -
    parseFloat(data.desconto ?? 0) +
    totalMultaJuros -
    totalDescontos -
    totalPago
  );
}

export function calcularPagamentosParciais(pagamentos) {
  if (!pagamentos) return;
  const pagamentosParciais = pagamentos.reduce((acc, obj) => {
    return acc + parseFloat(obj.valor);
  }, 0);
  return pagamentosParciais;
}
