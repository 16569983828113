import { DialogActions as DialogActionsMui } from "@mui/material";

const DialogActions = ({
  children,
  classes,
  disableSpacing,
  sx,
  className,
}) => {
  return (
    <DialogActionsMui
      className={className}
      classes={classes}
      disableSpacing={disableSpacing ?? false}
      sx={{
        ...sx,
        display: "flex",
        padding: "16px !important",
      }}
    >
      {children}
    </DialogActionsMui>
  );
};

export default DialogActions;
