import { Backdrop } from "@mui/material";
import "./loading.css";

const Loading = (props) => {
  const logo = require("../../assets/images/icon-lite-white.svg");

  return (
    <Backdrop style={{ zIndex: 2000 }} open={props.loading}>
      <div className="d-flex flex-column align-items-center">
        <img src={logo.default} id="logosiaf" className="rotate-center w-25" />
      </div>
    </Backdrop>
  );
};

export default Loading;
