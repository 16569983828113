import SiafLiteNfeRepository from "../../external/siafLiteNfe";

class CertificadoDigitalService {
  constructor() {
    this.urlBase = "/certificado-digital";
  }

  enviar(formData, cnpj) {
    return new SiafLiteNfeRepository(`${this.urlBase}/${cnpj}`).postCertificado(
      formData
    );
  }
}

export default CertificadoDigitalService;
