import { useState, useEffect } from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  DatePicker,
  Autocomplete,
  DataGrid,
  Loading,
} from "../../../../components";
import { formatPrice } from "../../../../utils";
import { InputErros, value } from "../../../../helpers";
import { notification } from "../../../../services";

const OrcamentoStepperContent = ({
  sendServer,
  activeStep,
  setActiveStep,
  filtrosDialog,
  setFiltrosDialog,
  handleCancelarImportacao,
  handleConfirmarImportacao,
  orcamentos,
  colunas,
  handleDateChange,
  setSelecionados,
  selecionados,
  setItensSelecionados,
  itensSelecionados,
  filtrarOrcamentos,
  itens,
  setItens,
  loading,
  buscarOrcamentosAvancado,
  listaClientes,
  handleInputChange,
  itensQuantidade,
}) => {
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const colunasItens = [
    {
      field: "descricao",
      headerName: "Produto",
      flex: 500,
    },
    {
      field: "valorUnitario",
      headerName: "Valor (R$)",
      flex: 300,
      valueGetter: (params) =>
        formatPrice(parseFloat(params.row.valorUnitario)),
    },
    {
      field: "saldoExportar",
      headerName: "Qtd.",
      flex: 200,
      headerAlign: "start",
      align: "start",
      type: "number",
      editable: true,
      valueParser: (newValue, params) => {
        const maxValue = itensQuantidade?.length
          ? itensQuantidade.find((item) => item.id === params.row?.id)
              ?.saldoExportar
          : params.value;
        const parsedValue = parseFloat(newValue);
        if (parsedValue > maxValue) {
          return maxValue;
        } else return parsedValue;
      },
    },
    {
      field: "totalItem",
      headerName: "Total Itens (R$)",
      flex: 300,
      valueGetter: (params) => {
        const total = params.row.valorUnitario * params.row.saldoExportar;
        return formatPrice(total);
      },
    },
  ];

  useEffect(() => {
    let listaItensAtualizada = [];
    if (itens) {
      itens.forEach((item) =>
        listaItensAtualizada.push({
          ...item,
        })
      );
    }
    setItens(listaItensAtualizada);
  }, []);

  const handleAvancarStep = () => {
    if (!selecionados?.length)
      return notification.alertaGenericos(
        "Selecione ao menos um orçamento para importar"
      );
    filtrarOrcamentos();
    setActiveStep((prevState) => prevState + 1);
  };

  const handleVoltarStep = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const onChangeAutocomplete = (name, value) => {
    setFiltrosDialog({
      ...filtrosDialog,
      [name]: value ? value.id : undefined,
    });
  };

  const onCellKeyDown = (params, event) => {
    if (event.key === "Enter") {
      const value = Number(event.target.value);
      const itensTemp = itens.map((item) => {
        if (params.row.id == item.id) {
          if (params.field == "saldoExportar")
            return {
              ...item,
              [params.field]: value ?? null,
              quantidade: value,
              totalItem: item.valorUnitario * value,
            };
        }
        return item;
      });
      setItens(itensTemp);
    }
  };

  switch (activeStep) {
    case 1:
      return (
        <>
          <DialogContent>
            <DataGrid
              checkboxSelection={true}
              rows={itens}
              columns={colunasItens}
              rowCount={itens.length}
              onRowSelectionModelChange={(event) => {
                setItensSelecionados(event);
              }}
              onCellKeyDown={onCellKeyDown}
              selectionModel={itensSelecionados}
              disableRowSelectionOnClick
              disableAcoes
            />
            <Loading loading={loading} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelarImportacao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleVoltarStep}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Anterior
            </Button>
            <Button
              onClick={handleConfirmarImportacao}
              color="primary"
              variant="contained"
            >
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Importar
            </Button>
          </DialogActions>
        </>
      );
    default:
      return (
        <>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  label="Data Inicial"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  value={
                    filtrosDialog?.dataInicial
                      ? filtrosDialog.dataInicial
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "dataInicial")}
                />
              </Grid>
              <Grid item xs={4}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  label="Data Final"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  value={
                    filtrosDialog?.dataFinal ? filtrosDialog.dataFinal : null
                  }
                  onChange={(date) => handleDateChange(date, "dataFinal")}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="pessoaClienteId"
                  name="pessoaClienteId"
                  options={listaClientes}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.nomeRazaoSocial
                      ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                      : ""
                  }
                  getOptionSelected={(option, value) =>
                    option?.nomeRazaoSocial === value?.nomeRazaoSocial
                  }
                  noOptionsText="Sem opções"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cliente"
                      variant={"outlined"}
                      error={inputErros.get("pessoaClienteId")}
                    />
                  )}
                  value={value.autoComplete(
                    listaClientes,
                    filtrosDialog?.pessoaClienteId
                  )}
                  onChange={(e, value) =>
                    onChangeAutocomplete("pessoaClienteId", value)
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-2 mb-4">
              <Grid item xs={4}>
                <TextField
                  id="numeroDocumento"
                  name="numeroDocumento"
                  label="Nº do documento"
                  variant="outlined"
                  value={
                    filtrosDialog.numeroDocumento
                      ? filtrosDialog.numeroDocumento
                      : undefined
                  }
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} className="mt-2">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => buscarOrcamentosAvancado(filtrosDialog)}
                >
                  <i
                    className="ph-bold ph-magnifying-glass"
                    style={{ color: "#fff", fontSize: 18, marginRight: 8 }}
                  ></i>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              checkboxSelection={true}
              rows={orcamentos}
              columns={colunas}
              onRowSelectionModelChange={(event) => {
                setSelecionados(event);
              }}
              paginationMode={"server"}
              sendServer={sendServer}
              disableAcoes
              noFilter
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancelarImportacao}
              color="padrao"
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => handleAvancarStep()}
              color="primary"
              variant="contained"
            >
              Próximo
              <i
                className="ph-fill ph-caret-right"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
            </Button>
          </DialogActions>
        </>
      );
  }
};

export default OrcamentoStepperContent;
