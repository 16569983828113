import { Grid, IconButton, Tooltip } from "../../../../components";
import { getPalavraTraduzida } from "../add-perfil-usuario";
import { icones } from "../resources/constantes";

export default function PermissoesActionsComponent() {
  const retornoTopo = (idElemento) => {
    if (idElemento) {
      const ref = document.getElementById(idElemento);
      setTimeout(
        () =>
          ref.scrollIntoView({
            behavior: "smooth",
            block: "start",
          }),
        100
      );
    }
  };

  return (
    <Grid container spacing={2} className="d-flex justify-content-center gap-4">
      {Object.keys(icones).map((secao, index) => {
        return (
          <Tooltip
            key={index}
            title={`PERMISSÃO ${getPalavraTraduzida(secao)}`}
            placement="top"
          >
            <IconButton
              color="inherit"
              onClick={() => {
                retornoTopo(`secao-${secao}`);
              }}
              sx={{
                backgroundColor: "#EDEEF0",
                "&:hover": { backgroundColor: "#B6BABC" },
              }}
            >
              <i
                className={icones[secao]}
                style={{ color: "#494C62", fontSize: 23 }}
              ></i>
            </IconButton>
          </Tooltip>
        );
      })}
    </Grid>
  );
}
