import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  MaskedInput,
  Button,
} from "../../../../components";
import { useState } from "react";
import ConviteContadorDialog from "./convite-contador-dialog";

const CadastroContabil = ({
  user,
  empresa,
  handleInputChange,
  handleCheckBox,
}) => {
  const [openConviteDialog, setOpenConviteDialog] = useState(false);
  return (
    <>
      <Grid container spacing={2}>
        {user?.administrador && (
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    empresa?.suspenderAtividade
                      ? empresa.suspenderAtividade
                      : false
                  }
                  onChange={(event) =>
                    handleCheckBox("suspenderAtividade", event)
                  }
                  name="suspenderAtividade"
                  color="primary"
                />
              }
              label="Suspender atividade"
              margin="normal"
              required
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  empresa?.contadorAutorizarDadosXmlNfe
                    ? empresa.contadorAutorizarDadosXmlNfe
                    : false
                }
                onChange={(event) =>
                  handleCheckBox("contadorAutorizarDadosXmlNfe", event)
                }
                name="contadorAutorizarDadosXmlNfe"
                color="primary"
              />
            }
            label="Autorizar dados para o XML da NF-e"
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  empresa?.contadorAutorizarDownloadXmlNfe
                    ? empresa.contadorAutorizarDownloadXmlNfe
                    : false
                }
                onChange={(event) =>
                  handleCheckBox("contadorAutorizarDownloadXmlNfe", event)
                }
                name="contadorAutorizarDownloadXmlNfe"
                color="primary"
              />
            }
            label="Autorizar Download de XML NF-e"
            margin="normal"
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="contadorNome"
            name="contadorNome"
            label="Nome Contador"
            variant={
              !empresa?.contadorAutorizarDadosXmlNfe ? "filled" : "outlined"
            }
            margin="normal"
            value={empresa?.contadorNome ? empresa.contadorNome : ""}
            onChange={handleInputChange}
            fullWidth
            disabled={!empresa?.contadorAutorizarDadosXmlNfe}
          />
        </Grid>
        <Grid item xs={empresa?.regimeTributario === 2 ? 2 : 3}>
          <MaskedInput
            id="contador-cpf"
            name="contadorCpf"
            label="CPF"
            variant={
              !empresa?.contadorAutorizarDadosXmlNfe ? "filled" : "outlined"
            }
            mask={"999.999.999-99"}
            value={empresa?.contadorCpf ? empresa.contadorCpf : ""}
            onChange={handleInputChange}
            disabled={!empresa?.contadorAutorizarDadosXmlNfe}
          />
        </Grid>
        <Grid item xs={empresa?.regimeTributario === 2 ? 2 : 3}>
          <MaskedInput
            id="contador-cnpj"
            name="contadorCnpj"
            label="CNPJ"
            mask={"99.999.999/9999-99"}
            variant={
              !empresa?.contadorAutorizarDadosXmlNfe ? "filled" : "outlined"
            }
            value={empresa?.contadorCnpj ? empresa.contadorCnpj : ""}
            onChange={handleInputChange}
            disabled={!empresa?.contadorAutorizarDadosXmlNfe}
          />
        </Grid>
        {empresa?.regimeTributario === 2 && (
          <Grid item xs={2}>
            <TextField
              id="contador-crc"
              name="contadorCrc"
              label="CRC"
              margin="normal"
              fullWidth
              variant={
                !empresa?.contadorAutorizarDadosXmlNfe ? "filled" : "outlined"
              }
              value={empresa?.contadorCrc ? empresa.contadorCrc : ""}
              onChange={handleInputChange}
              disabled={!empresa?.contadorAutorizarDadosXmlNfe}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="emailContabil"
            name="emailContabil"
            label="E-mail Contabil"
            variant="outlined"
            margin="normal"
            value={empresa?.emailContabil ? empresa.emailContabil : ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        {empresa?.contadorAutorizarDownloadXmlNfe && (
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 2, p: 1.8 }}
              onClick={() => setOpenConviteDialog(true)}
            >
              enviar convite
              <i
                className="ph ph-envelope-simple"
                style={{ fontSize: 22, marginLeft: 10, color: "white" }}
              ></i>
            </Button>
          </Grid>
        )}
      </Grid>
      <ConviteContadorDialog
        empresa={empresa}
        open={openConviteDialog}
        setOpen={setOpenConviteDialog}
      />
    </>
  );
};

export default CadastroContabil;
