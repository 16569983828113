import { useEffect, useState } from "react";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  Paper,
  Tabs,
  Tab,
  TabPanel,
  CardActions,
  Chip,
} from "../../../../components";
import { constantes } from "../resources";
import { InputErros, value } from "../../../../helpers";
import grupoTributarioModeloTributacaoValidator from "../middlewares/grupo-tributario-modelo-tributacao.validator";
import ModeloTributacaoIcms from "./modelo-tributacao-icms.component";
import ModeloTributacaoIpi from "./modelo-tributacao-ipi.component";
import ModeloTributacaoPis from "./modelo-tributacao-pis.component";
import "../add-modelo-tributacao.css";
import ModeloTributacaoCofins from "./modelo-tributacao-cofins.component";
import { SessionService, notification } from "../../../../services";
import { responseErros, unformatPrice } from "../../../../utils";
import { GrupoTributarioModeloTributacao } from "../../entities";

export default function ModeloTributacaoDialog({
  modeloTributacaoId,
  cfopRef,
  abaValue,
  setAbaValue,
  open,
  setOpen,
  grupoModeloTributacao,
  setGrupoModeloTributacao,
  buscarOperacoesFiscais,
  listaGrupoModeloTributacao,
  setListaGrupoModeloTributacao,
  csoSimplesList,
  tipoDesoneracaoList,
  estados,
  operacaoFiscalList,
  loadingAutoComplete,
  grupoTributarioList,
  cfopList,
  onSearchChangeCfop,
  icmsId,
  setIcmsId,
  situacaoTributariaIpiList,
  enquadramentoLegalList,
  situacaoPisList,
  situacaoCofinsList,
  buscarRegrasModelo,
  grupoTributarioModeloTributacaoService,
}) {
  const sessionService = new SessionService();
  const [inputErro, setInputErro] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    const data = sessionService.getEmpresa();
    setEmpresa(data);
  }, []);

  const handleInputChange = (e) => {
    const valor =
      e?.target?.nextSibling?.innerText === "%"
        ? parseFloat(unformatPrice(e.target.value))
        : e.target.value;
    const grupoModeloTributacaoTemp = {
      ...grupoModeloTributacao,
      [e.target.name]: valor,
    };
    if (e.target.name === "tipoOperacao") {
      grupoModeloTributacaoTemp.operacoesFiscalId = [];
      buscarOperacoesFiscais({
        tipoOperacao: e.target.value,
      });
    }
    setGrupoModeloTributacao(grupoModeloTributacaoTemp);
  };

  const handleCheckBoxDesonerado = (event) => {
    const modeloTributacaoIcmsTemp = {
      ...grupoModeloTributacao,
      [event.target.name]: event.target.checked,
    };
    setGrupoModeloTributacao(modeloTributacaoIcmsTemp);
  };

  const handleCheckBox = (event) => {
    const grupoModeloTributacaoTemp = {
      ...grupoModeloTributacao,
      [event.target.name]: event.target.checked,
    };
    handleCalculaSubstituicao(
      grupoModeloTributacaoTemp,
      constantes.calculaSubstituicao
    );
  };

  const onChangeAutocomplete = (name, value) => {
    setGrupoModeloTributacao((prevState) => ({
      ...prevState,
      [name]: value?.id ? value.id : undefined,
    }));
  };

  function handleCalculaSubstituicao(grupoModeloTributacao, chaves) {
    const grupoModeloTributacaoTemp = {
      ...grupoModeloTributacao,
    };
    if (!grupoModeloTributacaoTemp.calculaIcmsSt) {
      chaves.forEach((chave) => {
        if (grupoModeloTributacaoTemp[chave]) {
          delete grupoModeloTributacaoTemp[chave];
        }
      });
    } else {
      grupoModeloTributacaoTemp.modalidadeBaseCalculo =
        constantes.modalidadeBaseCalculoList[0].label;
    }
    return setGrupoModeloTributacao(grupoModeloTributacaoTemp);
  }

  const handleAdicionarTributacao = () => {
    grupoTributarioModeloTributacaoValidator
      .validate(grupoModeloTributacao, { abortEarly: false })
      .then(async () => {
        const dadosGrupoModeloTributacao = new GrupoTributarioModeloTributacao(
          grupoModeloTributacao
        );
        if (icmsId) {
          setListaGrupoModeloTributacao(
            dadosGrupoModeloTributacao.adicionaRegraModeloNaoCadastradoIcmsId(
              listaGrupoModeloTributacao
            )
          );
        } else {
          setListaGrupoModeloTributacao(
            dadosGrupoModeloTributacao.adicionaRegraModeloNaoCadastrado(
              listaGrupoModeloTributacao
            )
          );
        }
        handleCloseDialog();
        setInputErro([]);
        setIcmsId(null);
      })
      .catch((err) => {
        retornoTopo("topo");
        inputErros.set(err);
      });
  };

  const handleEditarTributacao = () => {
    grupoTributarioModeloTributacaoValidator
      .validate(grupoModeloTributacao, { abortEarly: false })
      .then(async () => {
        const dadosGrupoModeloTributacao = new GrupoTributarioModeloTributacao(
          grupoModeloTributacao
        );
        dadosGrupoModeloTributacao.adicionaModeloTributacaoId(
          modeloTributacaoId
        );
        if (icmsId) {
          grupoTributarioModeloTributacaoService
            .atualizar(icmsId, dadosGrupoModeloTributacao)
            .then((result) => {
              if (!result.isAxiosError) {
                notification.alteracaoSucesso();
                buscarRegrasModelo();
                setOpen(false);
              } else {
                responseErros(result);
              }
            });
        } else {
          grupoTributarioModeloTributacaoService
            .cadastrar(dadosGrupoModeloTributacao)
            .then((result) => {
              if (!result.isAxiosError) {
                notification.cadastroSucesso();
                buscarRegrasModelo();
                setOpen(false);
              } else {
                responseErros(result);
              }
            });
        }
        handleCloseDialog();
        setInputErro([]);
        setIcmsId(null);
      })
      .catch((err) => {
        retornoTopo("topo");
        inputErros.set(err);
      });
  };

  const handleCloseDialog = () => {
    setGrupoModeloTributacao({});
    setInputErro([]);
    setOpen(false);
  };

  const handleEstados = (value) => {
    const todosExcetoOrigem = estados.filter(
      (estado) => estado.sigla !== empresa.enderecoUF && estado.sigla
    );
    const valuesNomes = value.map((valor) => valor.id);
    setGrupoModeloTributacao((prevState) => ({
      ...prevState,
      estadosId: valuesNomes.includes("01") ? todosExcetoOrigem : value,
    }));
  };

  const handleRemoveTag = (indexToRemove) => {
    if (indexToRemove) {
      setGrupoModeloTributacao((prevState) => ({
        ...prevState,
        estadosId: grupoModeloTributacao?.estadosId.filter(
          (_, index) => index !== indexToRemove
        ),
      }));
    } else {
      setGrupoModeloTributacao((prevState) => ({
        ...prevState,
        estadosId: [],
      }));
    }
  };

  const retornoTopo = (id) => {
    const ref = document.getElementById(id);
    if (!ref) return;
    setTimeout(
      () =>
        ref.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      100
    );
  };

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      disableEscapeKeyDown
      aria-labelledby="grupo tributario modelo tributacao dialogo"
    >
      <div id="topo"></div>
      <Grid className="m-4">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Autocomplete
                id="grupoTributarioId"
                name="grupoTributarioId"
                options={grupoTributarioList}
                autoHighlight
                disableClearable
                getOptionLabel={(option) => (option?.nome ? option.nome : "")}
                getOptionSelected={(option, value) =>
                  option?.nome === value?.nome
                }
                disabled={icmsId ? true : false}
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grupo Tributário"
                    error={inputErros.get("grupoTributarioId")}
                    variant={icmsId ? "filled" : "outlined"}
                    margin="normal"
                    required
                  />
                )}
                onChange={(_, newValue) => {
                  onChangeAutocomplete("grupoTributarioId", newValue);
                }}
                value={value.autoComplete(
                  grupoTributarioList,
                  grupoModeloTributacao?.grupoTributarioId
                )}
              />
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                id="estadosId"
                name="estadosId"
                noOptionsText="Sem opções"
                options={estados}
                autoHighlight
                disabled={
                  !grupoModeloTributacao?.grupoTributarioId ||
                  !grupoModeloTributacao?.tipoOperacao
                    ? true
                    : false
                }
                getOptionLabel={(option) => (option?.nome ? option.nome : "")}
                onChange={(_, newValue) => handleEstados(newValue)}
                value={
                  grupoModeloTributacao?.estadosId
                    ? grupoModeloTributacao?.estadosId
                    : []
                }
                filterSelectedOptions
                filterOptions={(options) => {
                  if (options.length == 2) {
                    return options.filter(
                      (estado) =>
                        estado.sigla !== empresa.enderecoUF && estado.sigla
                    );
                  }
                  return options;
                }}
                renderTags={(value, getTagProps) => {
                  return value.length == 26
                    ? [
                        <Chip
                          key="todosExcetoOrigem"
                          label="Todos estados exceto origem"
                          onDelete={() => handleRemoveTag()}
                        />,
                      ]
                    : value.map((option, index) => (
                        <Chip
                          key={index}
                          label={option.nome}
                          {...getTagProps({ index })}
                          onDelete={() => handleRemoveTag(index)}
                        />
                      ));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="UF Destino"
                    variant={
                      !grupoModeloTributacao?.grupoTributarioId ||
                      !grupoModeloTributacao?.tipoOperacao
                        ? "filled"
                        : "outlined"
                    }
                    margin="normal"
                    error={inputErros.get("estadosId")}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Select
                label="Tipo de Operação"
                id="tipoOperacao"
                margin="normal"
                required
                disabled={
                  !grupoModeloTributacao?.grupoTributarioId ? true : false
                }
                variant={
                  !grupoModeloTributacao?.grupoTributarioId
                    ? "filled"
                    : "outlined"
                }
                name="tipoOperacao"
                value={value.text(grupoModeloTributacao?.tipoOperacao)}
                onChange={handleInputChange}
              >
                <MenuItem value={"ENTRADA"}>Entrada</MenuItem>
                <MenuItem value={"SAIDA"}>Saída</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                multiple
                id="operacoesFiscalId"
                name="operacoesFiscalId"
                noOptionsText="Sem opções"
                options={operacaoFiscalList}
                autoHighlight
                disabled={
                  !grupoModeloTributacao?.grupoTributarioId ||
                  !grupoModeloTributacao?.tipoOperacao
                    ? true
                    : false
                }
                getOptionLabel={(option) =>
                  option?.descricao ? option?.descricao : ""
                }
                onChange={(_, newValue) => {
                  setGrupoModeloTributacao((prevState) => ({
                    ...prevState,
                    operacoesFiscalId: newValue,
                  }));
                }}
                value={
                  grupoModeloTributacao?.operacoesFiscalId
                    ? grupoModeloTributacao?.operacoesFiscalId
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operação Fiscal"
                    variant={
                      !grupoModeloTributacao?.grupoTributarioId ||
                      !grupoModeloTributacao?.tipoOperacao
                        ? "filled"
                        : "outlined"
                    }
                    margin="normal"
                    error={inputErros.get("operacoesFiscalId")}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Paper className="modelo-paper">
            <Tabs
              value={abaValue}
              onChange={(e, value) => {
                e.persist();
                setAbaValue(value);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="ICMS" {...tabProps(0)} />
              <Tab label="IPI" {...tabProps(1)} />
              <Tab label="PIS" {...tabProps(2)} />
              <Tab label="COFINS" {...tabProps(3)} />
            </Tabs>
          </Paper>
          <TabPanel value={abaValue} index={0}>
            <ModeloTributacaoIcms
              icmsId={icmsId}
              cfopRef={cfopRef}
              grupoModeloTributacao={grupoModeloTributacao}
              onChangeAutocomplete={onChangeAutocomplete}
              csoSimplesList={csoSimplesList}
              tipoDesoneracaoList={tipoDesoneracaoList}
              cfopList={cfopList}
              onSearchChangeCfop={onSearchChangeCfop}
              inputErros={inputErros}
              handleInputChange={handleInputChange}
              handleCheckBox={handleCheckBox}
              handleCheckBoxDesonerado={handleCheckBoxDesonerado}
              loadingAutoComplete={loadingAutoComplete}
            />
          </TabPanel>
          <TabPanel value={abaValue} index={1}>
            <ModeloTributacaoIpi
              situacaoTributariaIpiList={situacaoTributariaIpiList}
              onChangeAutocomplete={onChangeAutocomplete}
              grupoModeloTributacao={grupoModeloTributacao}
              handleInputChange={handleInputChange}
              enquadramentoLegalList={enquadramentoLegalList}
            />
          </TabPanel>
          <TabPanel value={abaValue} index={2}>
            <ModeloTributacaoPis
              situacaoPisList={situacaoPisList}
              grupoModeloTributacao={grupoModeloTributacao}
              handleInputChange={handleInputChange}
              onChangeAutocomplete={onChangeAutocomplete}
              indicadorPisSt={grupoModeloTributacao?.calculaIcmsSt}
            />
          </TabPanel>
          <TabPanel value={abaValue} index={3}>
            <ModeloTributacaoCofins
              situacaoCofinsList={situacaoCofinsList}
              grupoModeloTributacao={grupoModeloTributacao}
              handleInputChange={handleInputChange}
              onChangeAutocomplete={onChangeAutocomplete}
              indicadorCofinsSt={grupoModeloTributacao?.calculaIcmsSt}
            />
          </TabPanel>
        </DialogContent>
        <CardActions className="modelo-actions">
          <Button
            color="primary"
            variant="contained"
            onClick={
              !modeloTributacaoId
                ? handleAdicionarTributacao
                : handleEditarTributacao
            }
          >
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10, color: "white" }}
            ></i>
            Salvar
          </Button>
          <Button
            color="padrao"
            variant="contained"
            onClick={handleCloseDialog}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Grid>
    </Dialog>
  );
}
