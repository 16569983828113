import { AccordionDetails as AccordionDetailsMui } from "@mui/material";

const AccordionDetails = ({ children, classes, className, sx, ref }) => {
  return (
    <AccordionDetailsMui
      classes={classes}
      className={className}
      sx={sx}
      ref={ref}
    >
      {children}
    </AccordionDetailsMui>
  );
};

export default AccordionDetails;
