import SiafLiteApiRepository from "../../external/siafLiteApi";
import encrypt from "../../utils/encrypt";

class UsuarioService {
  constructor() {
    this.urlBase = "/painel-controle/usuarios";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  getAllFiltroAvancado(filter) {
    return new SiafLiteApiRepository(this.urlBase).patch(filter);
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  cadastrar(data) {
    const senha = encrypt(data.senha);
    const confirmarSenha = encrypt(data.confirmarSenha);
    const body = { ...data, senha, confirmarSenha };
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  atualizar(id, data) {
    const senha = encrypt(data.senha);
    const confirmarSenha = encrypt(data.confirmarSenha);
    const body = { ...data, senha, confirmarSenha };
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }

  validarEmail(email) {
    return new SiafLiteApiRepository(`${this.urlBase}/validar-email`).patch({
      email,
    });
  }
}

export default UsuarioService;
