const getInputErros = (inputErro, name) => {
  if (Array.isArray(inputErro)) {
    for (const item of inputErro) {
      if (item === name || new RegExp(`\\[\\d+\\]\\.${name}`).test(item)) {
        return true;
      }
    }
    return false;
  }
  if (typeof inputErro === "object") {
    for (const key in inputErro) {
      if (new RegExp(`\\[\\d+\\]\\.${name}`).test(key)) {
        return inputErro[key];
      }
    }
    return false;
  }
  return inputErro.indexOf(name) != -1;
};

export default getInputErros;
