import { Grid, IconButton, Tooltip } from "../../../../../components";

export default function ActionsProdutosGridXml({ params, handleOpenActions }) {
  const indiceItem = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
  return (
    <Grid
      container
      className="d-flex justify-content-center align-items-center gap-2"
    >
      {params.row?.produtoId ? (
        <>
          <Tooltip title="Produto Cadastrado!" placement="top">
            <IconButton disabled>
              <i
                className="ph-fill ph-check-fat"
                style={{
                  fontSize: 20,
                  color: "#4FBB53",
                }}
              ></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Converter Produto" placement="top">
            <IconButton
              onClick={() =>
                handleOpenActions(params.row, "CONVERTER", indiceItem)
              }
            >
              <i
                className="ph-fill ph-git-diff"
                style={{
                  fontSize: 20,
                  color: `${
                    params.row?.produtoConvertido ? "#2196F3" : "#494C62"
                  }`,
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title="Editar Produto" placement="top">
            <IconButton onClick={() => handleOpenActions(params.row, "EDITAR")}>
              <i className="ph-fill ph-pencil" style={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vincular Produto" placement="top">
            <IconButton
              onClick={() => handleOpenActions(params.row, "VINCULAR")}
            >
              <i className="ph-bold ph-link" style={{ fontSize: 20 }}></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Converter Produto" placement="top">
            <IconButton
              onClick={() =>
                handleOpenActions(params.row, "CONVERTER", indiceItem)
              }
            >
              <i
                className="ph-fill ph-git-diff"
                style={{
                  fontSize: 20,
                  color: `${
                    params.row?.produtoConvertido ? "#2196F3" : "#494C62"
                  }`,
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </>
      )}
    </Grid>
  );
}
