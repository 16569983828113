import { useEffect, useState } from "react";
import shortid from "shortid";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Autocomplete,
  CurrencyInput,
  DataGrid,
} from "../../../../components";
import { value } from "../../../../helpers";
import {
  formatDate,
  unformatPrice,
  formatPrice,
  responseErros,
} from "../../../../utils";
import {
  CondicoesPagamentoService,
  MeioPagamentoService,
  notification,
} from "../../../../services";
import {
  calculaSubtotalPagamentos,
  calcularTotal,
  parcelaData,
} from "../../documento-frete";

const meioPagamentoService = new MeioPagamentoService();
const condicoesPagamentoService = new CondicoesPagamentoService();

const DocumentoFretePagamentos = ({
  documentoFreteId,
  documentoFrete,
  pagamentos,
  setPagamentos,
}) => {
  const [inputError, setInputError] = useState(null);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [condicaoPagParcelaList, setCondicaoPagParcelaList] = useState([]);
  const [condicaoSelecionada, setCondicaoSelecionada] = useState({});
  const [meioPagamentoSelecionado, setMeioPagamentoSelecionado] = useState({});
  const [gerarPagamentos, setGerarPagamentos] = useState({});
  const [totalFrete, setTotalFrete] = useState(0);
  const [totalPagamentos, setTotalPagamentos] = useState(0);

  const colunas = [
    {
      field: "meioPagamentoId",
      headerName: "Meio de Pagamento",
      flex: 700,
      sortable: false,
      valueGetter: (params) =>
        value.autoComplete(meioPagamentoList, params.value).descricao,
    },
    {
      field: "valorTitulo",
      headerName: "Valor do Título",
      flex: 700,
      sortable: false,
      valueGetter: (params) =>
        params.value > 0 ? formatPrice(params.value) : "R$ 0,00",
    },
    {
      field: "dataVencimento",
      headerName: "Vencimento",
      flex: 700,
      type: "date",
      editable: !documentoFreteId ? true : false,
      sortable: false,
      valueGetter: (params) => {
        return formatDate.received(params.value);
      },
    },
  ];

  useEffect(() => {
    buscarMeiosPagamento();
    buscarCondicoesPagamento();
    const totalPagamentosTemp = calculaSubtotalPagamentos(pagamentos);
    const totalFreteTemp = calcularTotal(documentoFrete);
    setTotalPagamentos(totalPagamentosTemp);
    setTotalFrete(totalFreteTemp);
    if (totalFreteTemp != totalPagamentosTemp) {
      setGerarPagamentos({
        valorTitulo:
          parseFloat(totalFreteTemp ?? 0) -
          parseFloat(totalPagamentosTemp ?? 0),
      });
    }
  }, [totalPagamentos, pagamentos]);

  const buscarMeiosPagamento = async () => {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    const result = await meioPagamentoService.getAll(filtros);
    if (!result.isAxiosError) {
      setMeioPagamentoList(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarCondicoesPagamento = async () => {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    const result = await condicoesPagamentoService.getAll(filtros);
    if (!result.isAxiosError) {
      setCondicaoPagamentoList(result.data);
    } else {
      responseErros(result);
    }
  };

  const onChangeMeioPagamento = (id, value) => {
    setInputError(null);
    const { condicoesPagamento } = value ?? [];
    const condicaoPagamentoId = condicoesPagamento?.[0]?.id ?? null;
    const quantidadeParcelas = condicoesPagamento?.[0]?.parcelas ?? null;
    setMeioPagamentoSelecionado(value);
    setGerarPagamentos({
      ...gerarPagamentos,
      [id]: value?.id ?? null,
      condicaoPagamentoId,
      quantidadeParcelas,
    });
    if (condicoesPagamento?.length > 0) {
      setCondicaoSelecionada(condicoesPagamento[0]);
    }
    setCondicaoPagParcelaList(condicoesPagamento ?? []);
  };

  const onChangeAutocomplete = (name, value) => {
    const pedidoPagamentoTemp = {
      ...gerarPagamentos,
      [name]: value ? value.id : null,
    };
    if (name === "condicaoPagamentoId") {
      setCondicaoSelecionada(value);
      pedidoPagamentoTemp.quantidadeParcelas = value?.parcelas;
    }
    setGerarPagamentos(pedidoPagamentoTemp);
  };

  const handleChangeValor = (event) => {
    const pagamentoTemp = {
      ...gerarPagamentos,
      [event.target.name]:
        parseFloat(unformatPrice(event.target.value)) || null,
    };
    setGerarPagamentos(pagamentoTemp);
  };

  function validarParcela() {
    if (!documentoFrete?.dataEmissao) {
      setInputError("dataEmissao");
      notification.erroValidacao("Data de Emissão");
      return true;
    }
    if (!gerarPagamentos?.meioPagamentoId) {
      setInputError("meioPagamentoId");
      notification.erroValidacao("Meio de pagamento");
      return true;
    }
    if (!gerarPagamentos?.condicaoPagamentoId) {
      setInputError("condicaoPagamentoId");
      notification.erroValidacao("Condição de pagamento");
      return true;
    }
    if (!gerarPagamentos?.quantidadeParcelas) {
      setInputError("quantidadeParcelas");
      notification.erroValidacao("Quantidade de Parcelas");
      return true;
    }
    if (!gerarPagamentos?.valorTitulo) {
      setInputError("valorTitulo");
      notification.erroValidacao("Valor do título");
      return true;
    }
    if (
      parseFloat(totalPagamentos ?? 0) +
        parseFloat(gerarPagamentos.valorTitulo) >
      totalFrete
    ) {
      notification.alertaGenericos(
        "Valor inserido ultrapassa total da Nota fiscal"
      );
      return true;
    }
    return false;
  }

  const handleGerarParcelas = () => {
    if (validarParcela()) return;
    const valorParcela =
      gerarPagamentos.valorTitulo / gerarPagamentos.quantidadeParcelas;
    const valorFormatado = Math.floor(valorParcela * 100) / 100;
    const restoDivisao =
      gerarPagamentos.valorTitulo -
      valorFormatado * gerarPagamentos.quantidadeParcelas;
    for (
      let contador = 1;
      contador <= gerarPagamentos?.quantidadeParcelas;
      contador++
    ) {
      const valorTitulo =
        contador === gerarPagamentos.quantidadeParcelas
          ? valorFormatado + parseFloat(restoDivisao.toFixed(2))
          : valorFormatado;
      const parcelaGerada = {
        id: shortid.generate(),
        numeroTitulo: contador,
        condicaoPagamentoId: gerarPagamentos?.condicaoPagamentoId,
        meioPagamentoId: gerarPagamentos?.meioPagamentoId,
        meioPagamentoSelecionado,
        valorTitulo,
        dataVencimento: parcelaData(
          contador,
          documentoFrete?.dataEmissao,
          condicaoSelecionada?.periodo ? condicaoSelecionada.periodo : 30
        ),
      };
      setPagamentos((prevState) => [...prevState, parcelaGerada]);
    }
    const totalPagamentosTemp = calculaSubtotalPagamentos(pagamentos);
    setGerarPagamentos({
      valorTitulo:
        totalFrete -
        parseFloat(totalPagamentosTemp ?? 0) -
        parseFloat(gerarPagamentos?.valorTitulo ?? 0),
    });
    notification.sucessoGenericos("Parcelas geradas com sucesso");
  };

  const onSubmitParcelas = (event) => {
    event.preventDefault();
    handleGerarParcelas();
  };

  const onClickDeletePagamentos = async (idParcela) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir essa parcela?`
    );
    if (retornoAlerta.isConfirmed) {
      const { valorTitulo } = pagamentos.find(({ id }) => id == idParcela);
      const data = pagamentos.filter((item) => item.id !== idParcela);
      setGerarPagamentos({
        ...gerarPagamentos,
        valorTitulo: gerarPagamentos?.valorTitulo + valorTitulo.toFixed(2) * 1,
      });
      setPagamentos(data);
    }
  };

  const onCellKeyDown = (params, event) => {
    if (event.key === "Enter") {
      setPagamentos((prevState) => {
        return prevState.map((row) => {
          if (params.row.meioPagamentoId == row.meioPagamentoId) {
            return { ...row, [params.field]: event.target.value ?? null };
          }
          return row;
        });
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid container spacing={2} xs={4} className="d-block">
          <Grid item xs={12}>
            <Autocomplete
              id="meioPagamentoId"
              name="meioPagamentoId"
              disabled={documentoFreteId}
              options={meioPagamentoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao ? option.descricao : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meio de Pagamento"
                  variant="outlined"
                  margin="normal"
                  error={inputError == "meioPagamentoId" ? true : false}
                  required
                />
              )}
              onChange={(e, newValue) => {
                onChangeMeioPagamento("meioPagamentoId", newValue);
              }}
              value={value.autoComplete(
                meioPagamentoList,
                gerarPagamentos?.meioPagamentoId
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                required
                id="condicaoPagamentoId"
                name="condicaoPagamentoId"
                disabled={documentoFreteId}
                options={condicaoPagParcelaList}
                autoHighlight
                getOptionLabel={(option) =>
                  option.descricao ? option.descricao : ""
                }
                getOptionSelected={(option, value) =>
                  option?.descricao === value?.descricao
                }
                noOptionsText="Sem opções"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Condição de Pagamento"
                    variant="outlined"
                    error={inputError == "condicaoPagamentoId" ? true : false}
                  />
                )}
                onChange={(e, newValue) => {
                  e.persist();
                  onChangeAutocomplete("condicaoPagamentoId", newValue);
                }}
                value={value.autoComplete(
                  condicaoPagamentoList,
                  gerarPagamentos?.condicaoPagamentoId
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CurrencyInput
              id="valorTitulo"
              name="valorTitulo"
              label="Valor do Título (R$)"
              inputVariant="outlined"
              margin="normal"
              required
              disabled={documentoFreteId}
              error={inputError == "valorTitulo" ? true : false}
              value={gerarPagamentos?.valorTitulo ?? ""}
              onBlur={(event) => {
                setInputError(null);
                handleChangeValor(event);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} fullWidth>
            <Button
              fullWidth
              className="mt-3 mb-3"
              size="large"
              variant="contained"
              color="primary"
              disabled={documentoFreteId}
              onClick={onSubmitParcelas}
            >
              <i
                className="ph-fill ph-plus-circle"
                style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
              ></i>
              Gerar Parcelas
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          xs={1}
          fullWidth
          className="d-flex justify-content-center"
        >
          <hr className="vr" />
        </Grid>
        <Grid container xs={7} className="d-block mt-3">
          <Grid container xs={12} fullWidth>
            <h4 style={{ fontWeight: 800, margin: 0 }}>PARCELAS</h4>
          </Grid>
          <Grid container xs={12} fullWidth className="mt-4">
            <DataGrid
              rows={pagamentos}
              columns={colunas}
              rowCount={pagamentos?.length}
              deleteShow={!documentoFreteId ? true : false}
              sortModel={[{ field: "condicaoPagamentoId", sort: "desc" }]}
              onClickDelete={onClickDeletePagamentos}
              onCellKeyDown={onCellKeyDown}
            />
            <Grid
              container
              xs={12}
              fullWidth
              className="d-flex align-items-center justify-content-end mt-3"
            >
              <Grid
                item
                xs={5}
                className="d-flex align-items-center justify-content-end"
              >
                <span className="me-2">Saldo a pagar:</span>
                <span className="badge bg-secondary bg-gradient bg-gradient text-wrap p-2">
                  {formatPrice(
                    parseFloat(totalFrete) - parseFloat(totalPagamentos)
                  ) || "R$0,00"}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentoFretePagamentos;
