import { format } from "date-fns";
import {
  Button,
  CardHeader,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "../../../../components";
import { formatPrice } from "../../../../utils";

function HistoricoMovimentacao({ open, setOpen, historicoMovimentacaoList }) {
  const colunas = [
    {
      field: "numeroNota",
      headerName: "Número Documento",
      flex: 1000,
    },
    {
      field: "serieNota",
      headerName: "Série Documento",
      flex: 1000,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1000,
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      flex: 1000,
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1000,
      valueGetter: (params) =>
        format(new Date(params.value), "dd/MM/yyyy HH:mm"),
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      flex: 1000,
      valueGetter: (params) => formatPrice(params.value),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth={"xl"} fullWidth open={open}>
      <DialogContent>
        <CardHeader
          title="Histórico de Movimentações"
          action={
            <Button onClick={handleClose} variant="contained" color="padrao">
              fechar
            </Button>
          }
        />
        <Grid container spacing={4}>
          <Grid className="mt-2" item xs={12}>
            <DataGrid
              rows={historicoMovimentacaoList}
              columns={colunas}
              rowCount={historicoMovimentacaoList?.length}
              disableAcoes
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default HistoricoMovimentacao;
