import {
  DialogContent,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Button,
  DialogActions,
  DataGrid,
} from "../../../../../../components";
import { ConversaoEntradaDialog, ProdutosVinculoGrid } from "../../";
import { editarCelulaCfop, editarCelulaNcm } from "../../../entities";

const ProdutosXmlStep = ({
  converterEntrada,
  setConverterEntrada,
  vincularProdutoExistente,
  buscarProdutos,
  listaProdutos,
  setListaProdutos,
  setVincularProdutoExistente,
  produtoSelecionado,
  setTemProdutosNaoCadastrados,
  dadosXML,
  setDadosXML,
  gruposProdutos,
  grupoProduto,
  setGrupoProduto,
  gruposTributarios,
  grupoTributario,
  setGrupoTributario,
  handleCloseDialog,
  temProdutosNaoCadastrados,
  handleSubmit,
  finalizarImportacao,
  colunasProdutos,
  loading,
  nfEntradaDto,
  setProdutosConvertidos,
  loadingAutocomplete,
  setLoadingAutocomplete,
}) => {
  return (
    <>
      <DialogContent>
        {vincularProdutoExistente && !nfEntradaDto?.usoConsumo ? (
          <Grid container spacing={2}>
            <Grid item xs={12} className="m-2">
              <ProdutosVinculoGrid
                buscarProdutos={buscarProdutos}
                listaProdutos={listaProdutos}
                setVincularProdutoExistente={setVincularProdutoExistente}
                produtoSelecionado={produtoSelecionado}
                setTemProdutosNaoCadastrados={setTemProdutosNaoCadastrados}
                dadosXML={dadosXML}
                setDadosXML={setDadosXML}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4} className="m-2">
                <FormControl fullWidth>
                  <Autocomplete
                    id="grupoProdutoId"
                    name="grupoProdutoId"
                    options={gruposProdutos}
                    noOptionsText="Sem opções"
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.descricao ? option.descricao : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grupo de Produto"
                        variant="outlined"
                      />
                    )}
                    onChange={(_, value) => setGrupoProduto(value)}
                    value={grupoProduto}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} className="m-2">
                <FormControl fullWidth>
                  <Autocomplete
                    id="grupoTributacaoId"
                    name="grupoTributacaoId"
                    options={gruposTributarios}
                    noOptionsText="Sem opções"
                    autoHighlight
                    getOptionLabel={(option) =>
                      option.nome ? option.nome : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grupo Tributário"
                        variant="outlined"
                      />
                    )}
                    onChange={(_, value) => setGrupoTributario(value)}
                    value={grupoTributario}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className="m-2">
                <DataGrid
                  rows={dadosXML.itens || []}
                  columns={colunasProdutos}
                  rowCount={dadosXML.itens?.length || 0}
                  disableAcoes
                  loading={loading}
                  onCellKeyDown={(params, event) => {
                    if (event.key === "Enter") {
                      if (params.field === "cfop") {
                        editarCelulaCfop(params, event, setDadosXML);
                      }
                      if (params.field === "ncmCodigo") {
                        editarCelulaNcm(params, event, dadosXML, setDadosXML);
                      }
                    }
                  }}
                  nonPaginated
                />
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions className="d-flex align-items-center justify-content-end mb-3">
        {vincularProdutoExistente && !nfEntradaDto?.usoConsumo ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setConverterEntrada(false);
              setVincularProdutoExistente(false);
            }}
          >
            Voltar
          </Button>
        ) : (
          <Button variant="contained" onClick={handleCloseDialog}>
            Fechar
          </Button>
        )}
        {!vincularProdutoExistente &&
          temProdutosNaoCadastrados &&
          !nfEntradaDto?.usoConsumo && (
            <Button variant="contained" color="success" onClick={handleSubmit}>
              Cadastrar Produtos
            </Button>
          )}
        {(!temProdutosNaoCadastrados || nfEntradaDto?.usoConsumoImportado) && (
          <Button variant="contained" onClick={finalizarImportacao}>
            Finalizar
          </Button>
        )}
      </DialogActions>
      <ConversaoEntradaDialog
        buscarProdutos={buscarProdutos}
        listaProdutos={listaProdutos}
        setListaProdutos={setListaProdutos}
        converterEntrada={converterEntrada}
        setConverterEntrada={setConverterEntrada}
        produtoSelecionado={produtoSelecionado}
        dadosXML={dadosXML}
        setDadosXML={setDadosXML}
        setProdutosConvertidos={setProdutosConvertidos}
        loadingAutocomplete={loadingAutocomplete}
        setLoadingAutocomplete={setLoadingAutocomplete}
        setTemProdutosNaoCadastrados={setTemProdutosNaoCadastrados}
      />
    </>
  );
};

export default ProdutosXmlStep;
