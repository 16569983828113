import locale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  DatePicker as DatePickerMui,
} from "@mui/x-date-pickers";

const DatePicker = ({
  id,
  label,
  name,
  className,
  minDate,
  maxDate,
  format,
  onChange,
  value,
  onClose,
  disabled,
  onClick,
  margin,
  variant,
  placeholder,
  shrink,
  required,
  sx,
  error,
  views,
  disableTextfield,
}) => {
  const defaultSx = {
    margin: margin ? "1rem 1rem 0.5rem auto" : "auto",
    width: "100%",
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DatePickerMui
        id={id}
        className={className}
        label={label}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        format={format}
        onChange={onChange}
        onClick={onClick}
        color="primary"
        slotProps={{
          textField: {
            error,
            required,
            variant,
            disabled: disableTextfield || disabled,
            placeholder,
            InputLabelProps: { shrink },
          },
        }}
        value={value ? new Date(value) : null}
        onClose={onClose}
        disabled={disabled}
        views={views}
        sx={{
          ...sx,
          ...defaultSx,
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
