import { useEffect, useRef, useState } from "react";
import {
  Button,
  CardHeader,
  Dialog,
  DialogContent,
  DialogActions,
} from "../../../../../components";
import {
  CestService,
  CfopService,
  NcmService,
  NfSaidaItensService,
  ProdutosService,
  notification,
} from "../../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../../utils";
import ImpostoProdutoForm from "./imposto-produto-form.component";
import { nfSaidaItemUpdateImposto } from "../../middlewares";
import { InputErros } from "../../../../../helpers";

const ImpostoProdutoDialog = ({
  open,
  setOpen,
  produto,
  setProduto,
  gruposTributacaoList,
  csoSimplesList,
  situacaoIpiList,
  enquadramentoLegalList,
  modeloTributacao,
  nfSaida,
  nfSaidaId,
  nfSaidaProdutos,
  setNfSaidaProdutos,
  nfSaidaEntity,
}) => {
  const inputRef = useRef();
  const produtosService = new ProdutosService();
  const nfSaidaItensService = new NfSaidaItensService();
  const ncmService = new NcmService();
  const cestService = new CestService();
  const cfopService = new CfopService();

  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [ncmList, setNcmList] = useState([]);
  const [cfopList, setCfopList] = useState([]);
  const [tabelaCest, setTabelaCest] = useState([]);
  const [produtoEditado, setProdutoEditado] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    buscarDadosIniciais();
  }, [open]);

  const buscarDadosIniciais = async () => {
    if (open) {
      await buscarNcms({ codigo: produto?.ncm });
      buscarCfops({ codigo: produto?.cfop });
      buscarProduto();
    }
  };

  function onClose() {
    setProduto(null);
    setProdutoEditado({});
    setOpen(false);
  }

  const buscarProduto = async () => {
    const result = await produtosService.getById(produto.produtoId);
    if (!result.isAxiosError) {
      setProdutoEditado({ ...result.data, ...produto });
    } else {
      setLoadingAutoComplete(false);
      responseErros(result);
    }
  };

  const buscarNcms = async (filtro, inputSearch) => {
    const filtros = {
      ...filtro,
      limite: 20,
    };
    const result = await ncmService.getAllAvancado(filtros);
    if (!result.isAxiosError) {
      setLoadingAutoComplete(false);
      setNcmList(result.data.rows);
      if (result.data && !inputSearch) {
        handleChangeNcm({}, result.data?.rows[0]);
      }
    } else {
      setLoadingAutoComplete(false);
      responseErros(result);
    }
  };

  const buscarCfops = async (filtro) => {
    const filtros = {
      ...filtro,
      limite: 20,
    };
    const result = await cfopService.getAllAvancado(filtros);
    if (!result.isAxiosError) {
      setLoadingAutoComplete(false);
      setCfopList(result.data.rows);
    } else {
      setLoadingAutoComplete(false);
      responseErros(result);
    }
  };

  const buscarCests = (filtros) => {
    const filtro = {
      ...filtros,
      limite: 20,
    };
    cestService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutoComplete(false);
        setTabelaCest(result.data.rows);
      } else {
        setLoadingAutoComplete(false);
        responseErros(result);
      }
    });
  };

  const onInputChangeNcm = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
        };
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(
          Promise.all([
            buscarNcms(filtros, true),
            buscarCests({ ncm: eventValue.substring(0, 4) }),
          ])
        );
      }
    }
  };

  const handleChangeNcm = (_, value) => {
    const produtoTemp = {
      ...produtoEditado,
      ncmId: value?.id,
      ncmCodigo: value?.codigo,
    };
    if (tabelaCest.length) {
      produtoTemp.cestId = tabelaCest[0]?.id;
    }
    setProdutoEditado(produtoTemp);
  };

  const handleEditarItem = async () => {
    nfSaidaItemUpdateImposto
      .validate(produtoEditado, { abortEarly: false })
      .then(async () => {
        const editionNfSaidaItem = {
          ...produtoEditado,
          ncm: produtoEditado?.ncmCodigo,
          cfop: String(produtoEditado?.cfop),
          tributosIcmsCsosn: produtoEditado?.tributosIcmsCsosn,
        };
        const result = await nfSaidaItensService.atualizar(produto.id, {
          ncm: produtoEditado?.ncmCodigo,
          cfop: String(produtoEditado?.cfop),
          tributosIcmsCsosn: produtoEditado?.tributosIcmsCsosn,
        });
        nfSaidaEntity.nfSaidaItens[produtoEditado.ordem - 1] =
          editionNfSaidaItem;
        setNfSaidaProdutos((prevState) => [
          ...prevState.map((item, index) =>
            index === produtoEditado.ordem - 1 ? editionNfSaidaItem : item
          ),
        ]);
        if (!result.isAxiosError) {
          if (produto?.ncm === produtoEditado?.ncmCodigo) {
            notification.alteracaoSucesso();
            onClose();
            return;
          }
          const res = await produtosService.atualizar(produto.produtoId, {
            ...produtoEditado,
            ncmId: produtoEditado?.ncmId,
            ncmCodigo: produtoEditado?.ncmCodigo,
          });
          if (!res.isAxiosError) {
            notification.alteracaoSucesso();
            onClose();
          } else responseErros(res);
        } else responseErros(result);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const handleEditarItemFornecedor = async () => {
    const produtoIndex = nfSaidaProdutos.findIndex(
      (obj) => obj.id == produto.id
    );
    const nfSaidaProdutosTemp = [
      ...nfSaidaProdutos.map((obj, index) => {
        if (index == produtoIndex) {
          return { ...produto, ...obj, ...produtoEditado, itemEditado: true };
        }
        return obj;
      }),
    ];
    setNfSaidaProdutos(nfSaidaProdutosTemp);
    nfSaidaEntity.setChangeItens(nfSaidaId, nfSaidaProdutosTemp);
    notification.alteracaoSucesso();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <CardHeader title="Visualizar Impostos" className="m-2" />
      <DialogContent>
        <ImpostoProdutoForm
          modeloTributacao={modeloTributacao}
          gruposTributacaoList={gruposTributacaoList}
          produto={produto}
          nfSaidaId={nfSaidaId}
          nfSaida={nfSaida}
          ncmList={ncmList}
          cfopList={cfopList}
          loadingAutoComplete={loadingAutoComplete}
          setLoadingAutoComplete={setLoadingAutoComplete}
          handleChangeNcm={handleChangeNcm}
          produtoEditado={produtoEditado}
          setProdutoEditado={setProdutoEditado}
          onInputChangeNcm={onInputChangeNcm}
          inputRef={inputRef}
          csoSimplesList={csoSimplesList}
          situacaoIpiList={situacaoIpiList}
          enquadramentoLegalList={enquadramentoLegalList}
          buscarCfops={buscarCfops}
        />
      </DialogContent>
      <DialogActions className="m-3">
        {nfSaidaId &&
        (produto?.ncm !== produtoEditado?.ncmCodigo ||
          produto?.tributosIcmsCsosn !== produtoEditado?.tributosIcmsCsosn ||
          produto?.cfop !== produtoEditado?.cfop) ? (
          <Button
            onClick={handleEditarItem}
            color="success"
            variant="contained"
          >
            Salvar
          </Button>
        ) : null}
        {!nfSaidaId && nfSaida?.documentoDevolucaoFornecedor && (
          <Button
            onClick={handleEditarItemFornecedor}
            color="success"
            variant="contained"
          >
            Salvar
          </Button>
        )}
        <Button onClick={onClose} color="primary" variant="contained">
          Sair
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImpostoProdutoDialog;
