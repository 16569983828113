import { useState, useEffect } from "react";
import {
  CardContent,
  Grid,
  TextField,
  Typography,
  CardHeader,
} from "../../components";
import PasswordStrengthBar from "react-password-strength-bar";
import { AddLayout } from "../../layouts";
import { UsuarioService, SessionService, notification } from "../../services";
import { InputErros, value } from "../../helpers";
import { responseErros } from "../../utils";
import { usuarioValidator, usuarioUpdateValidator } from "./middlewares";
import { UsuarioEntity } from "./entities";

const CentralUsuario = () => {
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState({});
  const [senhaScore, setSenhaScore] = useState();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const sessionService = new SessionService();
  const usuarioService = new UsuarioService();

  useEffect(() => {
    const usuarioSession = sessionService.getUsuario();
    setId(usuarioSession.id);
    buscaUsuario(usuarioSession.id);
  }, []);

  const buscaUsuario = async (IdUsuario) => {
    setLoading(true);
    const result = await usuarioService.getById(IdUsuario);
    if (!result.isAxiosError) {
      if (result.data) {
        setUsuario(result.data);
      }
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onChangeInput = (event) => {
    setUsuario((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const senhaForte = () => {
    if (usuario?.senha && senhaScore < 2) {
      inputErros.set({
        errors: ["Senha fraca"],
        inner: [
          {
            path: "senha",
          },
        ],
      });
      return false;
    }
    return true;
  };

  const handleSubmitUsuario = () => {
    if (senhaForte()) {
      validarUsuario(usuario.senha ? usuarioValidator : usuarioUpdateValidator);
    }
  };

  const validarUsuario = async (validator) => {
    validator
      .validate(usuario, { abortEarly: false })
      .then(async () => {
        inputErros.set(null);
        const usuarioEntity = new UsuarioEntity(usuario);
        atualizarUsuario(usuarioEntity);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const atualizarUsuario = async (usuarioEntity) => {
    setLoading(true);
    const result = await usuarioService.atualizar(id, usuarioEntity);
    if (!result.isAxiosError) {
      notification.alteracaoSucesso();
      buscaUsuario(id);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  return (
    <AddLayout id={id} onClickSalvar={handleSubmitUsuario} loading={loading}>
      <CardContent>
        <Grid container spacing={2} className="mt-3">
          <Grid item xs={6}>
            <CardHeader title={"Central do usuário"} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="email"
              name="email"
              label="E-mail"
              variant="filled"
              value={value.text(usuario?.email)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              variant="outlined"
              value={value.text(usuario?.nome)}
              onChange={(event) => onChangeInput(event)}
              fullWidth
              required
              error={inputErros.get("nome")}
            />
          </Grid>
          <Grid item xs={12} className="mt-3">
            <h5>Mudar Senha</h5>
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="password"
              id="senha"
              name="senha"
              label="Nova senha"
              margin="normal"
              variant="outlined"
              value={value.text(usuario?.senha)}
              onChange={(event) => {
                onChangeInput(event);
              }}
              fullWidth
              error={inputErros.get("senha")}
            />
            {senhaScore < 2 ? (
              <Typography
                style={{
                  marginTop: 4,
                  fontSize: 10,
                  color: "#E53935",
                }}
              >
                Deve ter de 6 a 20 caracteres e no mínimo razoável
              </Typography>
            ) : (
              ""
            )}
            {usuario?.senha && (
              <PasswordStrengthBar
                scoreWords={[
                  "senha fraca",
                  "senha fraca",
                  "senha razoável",
                  "senha boa",
                  "senha forte",
                ]}
                shortScoreWord={"senha curta"}
                password={usuario?.senha}
                onChangeScore={(score) => {
                  setSenhaScore(score);
                }}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="password"
              id="confirmarSenha"
              name="confirmarSenha"
              label="Confirmar nova senha"
              variant="outlined"
              margin="normal"
              value={value.text(usuario?.confirmarSenha)}
              onChange={(event) => {
                onChangeInput(event);
              }}
              fullWidth
              error={inputErros.get("confirmarSenha")}
            />
          </Grid>
        </Grid>
      </CardContent>
    </AddLayout>
  );
};

export default CentralUsuario;
